export class CsValidatorRegistry {

	constructor() {
	}

	hasValidator(type: string) {
		return this.validators.hasOwnProperty(type);
	}

	register(type: string, validator: any) {
		this.validators[type] = validator;
	}

	getValidator(type: string): any {
		if (this.hasValidator(type)) {
			return this.validators[type];
		}
		return null;
	}

	private validators: { [type: string]: any } = {};
}

