import { fromEvent as observableFromEvent, Observable, Subject, Subscription } from 'rxjs';
import { delay }                                                               from 'rxjs/operators';
import { Directive, ElementRef, Output, EventEmitter, HostListener }           from '@angular/core';

@Directive({
						 selector: '[clickOutside]'
					 })
export class ClickOutsideDirective {

	@Output()
	public clickOutside = new EventEmitter<MouseEvent>();

	clickOutsideSubscription: Subject<any> = new Subject();

	constructor(private _elementRef: ElementRef) {
	}

	@HostListener('document:click', ['$event', '$event.target'])
	public onClick(event: MouseEvent, targetElement: HTMLElement): void {
		if (!targetElement) {
			return;
		}

		const clickedInside = this._elementRef.nativeElement.contains(targetElement);
		if (!clickedInside) {
			this.clickOutside.emit(event);
		}
	}

	startClickOutsideListener(element: any) {
		this.globalClick = observableFromEvent(document, 'click')
			.pipe(
				delay(1))
			.subscribe((event: MouseEvent) => {
				const targetElement = <HTMLElement>event.target;
				if (!targetElement) {
					return;
				}

				const clickedInside = element.contains(targetElement);
				if (!clickedInside) {
					this.clickOutsideSubscription.next(element);
					this.globalClick.unsubscribe();
				}
			});
	}
	private globalClick: Subscription;
}
