import { Injectable, Injector }                                             from '@angular/core';
import { ConnectionPositionPair, Overlay, OverlayConfig, PositionStrategy } from '@angular/cdk/overlay';
import { PopoverRef }                                                       from './popover-ref';
import { ComponentPortal, PortalInjector }                                  from '@angular/cdk/portal';
import { PopoverComponent }                                                 from './popover.component';
import { PopoverPosition }                                                  from './popover-position.type';
import { PopoverParams }                                                    from './popover-params.model';


@Injectable({
	providedIn: 'root'
})
export class PopoverService {

	constructor(private overlay: Overlay, private injector: Injector) {
	}

	open<T>({origin, content, data, width, height, position, hasBackdrop, settings}: PopoverParams<T>): PopoverRef<T> {
		const overlayRef = this.overlay.create(this.getOverlayConfig({origin, width, height, position, hasBackdrop}));
		const popoverRef = new PopoverRef<T>(overlayRef, content, data, settings);

		const injector = this.createInjector(popoverRef, this.injector);
		overlayRef.attach(new ComponentPortal(PopoverComponent, null, injector));

		return popoverRef;
	}

	private getOverlayConfig({origin, width, height, position, hasBackdrop}): OverlayConfig {
		return new OverlayConfig({
			width,
			height,
			hasBackdrop,
			backdropClass:    'popover-backdrop',
			positionStrategy: this.getOverlayPosition(origin, position),
			scrollStrategy:   this.overlay.scrollStrategies.reposition()
		});
	}

	private getOverlayPosition(origin: HTMLElement, position: PopoverPosition = 'top'): PositionStrategy {
		const positionStrategy = this.overlay.position()
																 .flexibleConnectedTo(origin)
																 .withPositions(this.getPositions(position))
																 .withPush(false);

		return positionStrategy;
	}

	private getPositions(position: PopoverPosition = 'top'): ConnectionPositionPair[] {
		const dir = {
			top:        {
				originX:  'center',
				originY:  'top',
				overlayX: 'center',
				overlayY: 'bottom',
				offsetY:  0,
				offsetX:  0
			},
			bottom:     {
				originX:  'center',
				originY:  'bottom',
				overlayX: 'center',
				overlayY: 'top',
				offsetY:  0,
				offsetX:  0
			},
			bottomLeft: {
				originX:  'start',
				originY:  'bottom',
				overlayX: 'start',
				overlayY: 'top',
				offsetY:  0,
				offsetX:  0
			},
			right:      {
				originX:  'end',
				originY:  'center',
				overlayX: 'start',
				overlayY: 'center',
				offsetY:  0,
				offsetX:  0
			},
			left:       {
				originX:  'start',
				originY:  'center',
				overlayX: 'end',
				overlayY: 'center',
				offsetY:  0,
				offsetX:  0
			}
		};
		return Object.keys(dir)
								 .sort((a) => a === position ? -1 : 1)
								 .map(key => dir[key]);
	}

	createInjector(popoverRef: PopoverRef, injector: Injector) {
		const tokens = new WeakMap(
			[
				[PopoverRef, popoverRef]
			]);
		return new PortalInjector(injector, tokens);
	}
}
