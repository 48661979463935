import { Pipe, PipeTransform } from '@angular/core';
import { TableMenuItem }       from './table-menu.model';

@Pipe({
  name: 'tableMenuIcon',
  pure: true
})
export class TableMenuIconPipe implements PipeTransform {

  transform(value: TableMenuItem, ...args: unknown[]): unknown {
    let output = '';

    switch (value.icon.toLowerCase()) {
      case 'DELETE'.toLowerCase():
        output = 'mdi mdi-delete info-icon hover-pointer ';
        break;
      case 'EDIT'.toLowerCase():
        output = 'mdi mdi-pencil info-icon hover-pointer ';
        break;
    }
    return output;
  }

}
