import { ChangeDetectorRef, Component }  from '@angular/core';
import { ArrayUtils, isNullOrUndefined } from '@cs/core/utils';
import { DashboardPanelComponentBase }   from '@cs/components/shared';
import { CsFileDataDescribed }           from '@cs/components/viewer';

@Component({
			   selector:    'cs-dashboard-viewer',
			   templateUrl: './dashboard-viewer.component.html',
			   styleUrls:   ['./dashboard-viewer.component.scss']
		   })
export class DashboardViewerComponent extends DashboardPanelComponentBase<CsFileDataDescribed> {

	name: string;


	constructor(private changeRef: ChangeDetectorRef) {
		super();
	}

	dataChanged(value: CsFileDataDescribed) {
		throw new Error('Method not implemented.');
	}

	update(data: CsFileDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			(this.data && ArrayUtils.isEqual(this.data.data, data.data)))
			return;

		this.data = data;
	}

}
