import { Component }             from '@angular/core';
import { ReadOnlyControlWidget } from '../models/read-only-control-widget.directive';

@Component({
	selector: 'button-widget',
	template: `
				<div class="form-group">
					<button class="btn btn--lg text-no-whitespace text-truncate"
							[disabled]="readOnly"
							(click)="metaButton()">{{propertyAnnotation.description}}</button>
				</div>
						`
})
export class ButtonWidgetComponent<T> extends ReadOnlyControlWidget<T> {

	metaButton() {
		this.formAgent.publishActionRequested(this.propertyAnnotation);
	}
}
