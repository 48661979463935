import { Resolve, Routes } from '@angular/router';
import { ShellComponent }  from './shell.component';


import { Type }                from '@angular/core';
import { AuthenticationGuard } from '@cs/performance-manager/shared';


export class ShellRoutingLoader {
	static getRoutes(children: Routes,
									 resolvers: { [key: string]: Type<Resolve<any>> } = {},
									 canActivate: any[]                               = [AuthenticationGuard]) {
		return [
			{
				path:        '', component: ShellComponent,
				canActivate: canActivate,
				resolve:     resolvers,
				children:    [
					...children
				]
			}
		];
	}
}
