import { Inject }                from '@angular/core';
import { CsToastManagerService } from '@cs/components/toast-manager';
import { TranslateService }      from '@ngx-translate/core';
import { CsChartGoogleProvider } from './providers/cs-chart-google.provider';
import {
	CsChartProvider,
	CsChartLoaderSetup,
	ChartLayoutAnnotation,
	ChartItemClickEventArgs,
	ColumnDescriptions, CsChartRoles, CsTootipType
}                                from './cs-chart-provider.interface';
import { FormatProviderService } from '@cs/common';
import {
	CsDataDescribedClickEventArgs,
	DataDescribed, FormatRegisteredItem, LoggerUtil, mergeDeep, pathChecked, PropertyAnnotation, isNullOrUndefined, gv
}                                from '@cs/core';
import zipObject                 from 'lodash/zipObject';
import { isObject }              from '@datorama/akita';
import { BaseChartLegendItem }   from './base-chart-legend-item.model';

import DataObject = google.visualization.DataObject;
import DataObjectColumn = google.visualization.DataObjectColumn;
import DataObjectCell = google.visualization.DataObjectCell;
import DataObjectRow = google.visualization.DataObjectRow;
import DataTableColumnDescription = google.visualization.DataTableColumnDescription;


export class CsDataDescribedChartLoaderSetup implements CsChartLoaderSetup<CsDataDescribedClickEventArgs, google.visualization.DataTable> {

	legacySetup: CsLegacyPMChartLoaderSetup;

	constructor(private formatProvider: FormatProviderService, @Inject(CsToastManagerService) private toastManager: CsToastManagerService, @Inject(TranslateService) private i8n: TranslateService) {
		this.legacySetup = new CsLegacyPMChartLoaderSetup(this.formatProvider, toastManager, i8n);
	}

	/**
		* Set the chart provider to use for drawing charts
		*/
	createNewChartProviderInstance() {
		return new CsChartGoogleProvider(this.toastManager, this.i8n);
	}

	getMetaProperties(event: ChartItemClickEventArgs, chartProvider: CsChartProvider) {
		const {columnProperties, rowProperties} = chartProvider.getMetaProperties(event);

		return {
			row:    rowProperties,
			column: columnProperties
		};
	}

	async mapToDataTable<T = any>(dataDescribed: DataDescribed<T, ChartLayoutAnnotation>, properties: [keyof T] | Array<string>) {
		const dataObject: DataObject                  = {cols: [], rows: [], p: {}};
		const legendItems: Array<BaseChartLegendItem> = [];
		let tooltipRolePropertyName                   = null;

		if (!CsChartGoogleProvider.isReady())
			await CsChartGoogleProvider.loadDependencies();

		let columnDescriptions: ColumnDescriptions = {};


		if (pathChecked(dataDescribed, ['layout', 'columnDescriptions'], null, false))
			columnDescriptions = dataDescribed.layout.columnDescriptions;

		// fallback check
		if (this.hasTooltipRole(columnDescriptions) && gv(() => dataDescribed.layout.options.tooltip.isHtml) === false) {
			const tooltipOptions = gv(() => dataDescribed.layout.options.tooltip);
			if (tooltipOptions == null)
				dataDescribed.layout.options.tooltip = {
					isHtml: true
				};
			else
				dataDescribed.layout.options.tooltip.isHtml = true;
		}

		// check if chart should show the a  custom tooltip
		if (pathChecked(dataDescribed, ['layout', 'options', 'tooltip', 'isHtml'], false, false)) {


			// Check if if the columnDescriptions has a tooltip role. If so set the Propety to hide. This is down to exclude it from the legend
			if (this.hasTooltipRole(columnDescriptions)) {
				tooltipRolePropertyName = this.getTooltipRolePropertyName(columnDescriptions);

			} else {
				// Check if there are no column descriptions and the chart tooltip should be HTML, then use the injected TOTAL variant
				const field        = dataDescribed.dataAnnotation.fields[0];
				const fieldTooltip = `${field.id}_tooltip`;
				const toolTipField = new PropertyAnnotation({
																																																	visible: false,
																																																	id:      fieldTooltip,
																																																	label:   'total_injected',
																																																	type:    'String'
																																																});

				// Injecting the default total tooltip
				dataDescribed.dataAnnotation.fields.splice(1, 0, toolTipField as any);

				const columnInjectedDescriptions = {
					[`${field.id}`]: {
						roles: {
							tooltip: fieldTooltip
						}
					}
				} as ColumnDescriptions;

				columnDescriptions = mergeDeep(columnDescriptions, columnInjectedDescriptions);
				const data         = dataDescribed.data as unknown as any[];

				for (const row of data) {
					row[fieldTooltip] = CsTootipType.Default;
				}
			}
		}

		if (properties === null && !pathChecked(dataDescribed, ['layout', 'properties'], null, false) && pathChecked(dataDescribed,
																																																																																																															['dataAnnotation'], null,
																																																																																																															false))
			properties = dataDescribed.dataAnnotation.fields
																													.filter(value => value.visible !== false)
																													.map(value => value.id) as [keyof T];
		else if (properties === null && pathChecked(dataDescribed, ['layout', 'properties'], null, false))
			properties = dataDescribed.layout.properties;
		else
			// On the Data Entry module the chart setup CsLegacyPMChartLoaderSetup is reusing to CsDataDescribedChartLoaderSetup (because of Dashboard module)
			return await this.legacySetup.mapToDataTable(dataDescribed) as any;


		let colIndex = 0;

		for (const key of properties) {
			const dataAnno = dataDescribed.dataAnnotation.fields.find(f => f.id === key);

			if (isNullOrUndefined(dataAnno)) {
				LoggerUtil.error(`${key} is not found in the provided dataAnnotations, please check if id: ${key} exists`);
				continue;
			}

			this.addColumn(dataAnno.id.toString(), dataAnno.label, dataAnno.type, dataObject, dataAnno);
			// when piechart don't add the column as legend but use the row also check if not a tooltip property
			if (dataDescribed.layout.chartType !== 'PieChart' && tooltipRolePropertyName !== dataAnno.id) {
				this.addLegend(dataAnno.id.toString(), dataAnno.label, colIndex, dataDescribed, legendItems, columnDescriptions);
				// Increment the col index
				colIndex++;
			}

			if (columnDescriptions != null && columnDescriptions.hasOwnProperty(dataAnno.id)) {
				const roles = columnDescriptions[dataAnno.id.toString()].roles;
				for (const role of Object.keys(roles)) {

					const roleAnnotationId = roles[role];

					const roleAnnotation = dataDescribed.dataAnnotation.fields.find(item => item.id === roleAnnotationId);
					this.addRoleColumn(roleAnnotation.id.toString(), roleAnnotation.label, roleAnnotation.type, role as CsChartRoles, dataObject);
					// Increment the col index to match the google internal index
					colIndex++;
				}
			}
		}

		const dataList: any[] = <any>dataDescribed.data;

		for (const dataItem of dataList) {
			this.addRow(dataItem, dataObject, dataDescribed, columnDescriptions, legendItems);
		}

		const tooltip = dataObject.cols.find(x => x['role'] === 'tooltip');
		if (tooltip) {
			for (const row of dataObject.rows) {
				switch (row.p[tooltip.id].toLowerCase()) {
					case CsTootipType.Total:
						this.generateTotalTooltipHtml(row, dataObject.cols, dataDescribed.layout);
						break;
					case CsTootipType.Default:
						this.generateTotalTooltipHtml(row, dataObject.cols, dataDescribed.layout, false);
						break;
				}
			}
		}

		return {dataTable: new google.visualization.DataTable(dataObject), legendItems: legendItems};
	}

	hasTooltipRole(columnDescriptions: ColumnDescriptions): string | boolean {
		for (const colDesc of Object.keys(columnDescriptions)) {
			const item = columnDescriptions[colDesc];
			if (item.roles.tooltip)
				return colDesc;
		}
		return false;
	}

	addColumn(id: string, label: string, type: string, dataTable: DataObject, p = {}, pattern?) {
		const convertedType                      = this.parseTypes(type);
		const header: DataTableColumnDescription = {id: id, label: label, type: convertedType, pattern: pattern, p: p};
		dataTable.cols.push(header as DataObjectColumn);
	}

	addRoleColumn(id: string, label: string, type: string, role: CsChartRoles, dataTable: DataObject, pattern?) {
		const convertedType = this.parseTypes(type);
		let col             = dataTable.cols.find(x => x.id === id && !x.hasOwnProperty('role'));
		if (!col) {
			const header: DataTableColumnDescription = {id: id, label: label, type: convertedType, pattern: pattern, role: role};
			dataTable.cols.push(header as DataObjectColumn);
			col = header as DataObjectColumn;
		}

		col['role'] = role;
		if (role === 'tooltip')
			col.p = {'html': true};

	}

	addRow(dataItem: any, dataObject: google.visualization.DataObject, dataDescribed: DataDescribed<any, ChartLayoutAnnotation>,
								columnDescriptions: ColumnDescriptions, legendItems: Array<BaseChartLegendItem>) {
		const row: DataObjectRow = {c: [], p: {...dataItem}};
		for (let colIndex = 0; colIndex < dataObject.cols.length; colIndex++) {
			const header   = dataObject.cols[colIndex];
			const dataAnno = dataDescribed.dataAnnotation.fields.find(f => f.id === header.id);
			let cellValue  = dataItem[header.id];

			let cell: DataObjectCell;
			// Created for the Coldfusion Guys. Option to provide a value with fomat as a cell value
			if (isObject(cellValue) && cellValue.hasOwnProperty('v')) {
				cell = cellValue;
			} else {
				// If the data-type contains Date / DateTime then convert it to a date
				if (dataAnno.type.toLowerCase()
																.indexOf('date') > -1) {
					cellValue = new Date(cellValue);
				}

				cell = {
					v: cellValue
				};

				if (dataAnno.lookup) {
					cell.f = dataDescribed.resolveValueWithLookup(cellValue, dataAnno.lookup);
				}

				if (dataAnno.format) {
					cell.f = this.formatProvider.format(cellValue, new FormatRegisteredItem(dataAnno.type, dataAnno.format));
				} else {
					cell.f = this.formatProvider.formatByDataType(cellValue, dataAnno.type);
				}
			}
			row.c.push(cell);

			// when piechart thant we only want the the label property
			if (dataDescribed.layout.chartType === 'PieChart' && header.id.toLowerCase() === 'label')
				this.addLegend(colIndex.toString(), cellValue, colIndex, dataDescribed, legendItems, columnDescriptions);
		}

		dataObject.rows.push(row);
	}

	private parseTypes(type: string) {
		let result = 'string';
		switch (type.toLowerCase()) {
			case 'int':
			case 'int16':
			case 'int32':
			case 'int64':
			case 'decimal':
			case 'float':
				result = 'number';
				break;
			case 'boolean':
				result = 'boolean';
		}
		return result;
	}

	private generateTotalTooltipHtml(row: DataObjectRow, header: DataObjectColumn[], layout: ChartLayoutAnnotation, showTotal = true) {
		// create container
		let total              = 0;
		const tooltipContainer = document.createElement('div');
		tooltipContainer.classList.add('google-visualization-tooltip');

		const ul = document.createElement('ul');
		ul.classList.add('google-visualization-tooltip-item-list');

		for (let i = 1; i < header.length; i++) {
			if (isNullOrUndefined(header[i]['role'])) {
				const head  = header[i];
				const value = isNullOrUndefined(row.c[i].f)
																		? row.c[i].v
																		: row.c[i].f;
				const label = head.label + ':';
				let color   = head.p.color
					|| layout.options.colors[ul.childElementCount]
					|| '00000';

				// Check if starts with #
				if (color != null && !color.startsWith('#', 0))
					color = `#${color}`;

				let calculations = null;

				if (isNullOrUndefined(value))
					continue;

				if (layout.tooltipCalculations != null && layout.tooltipCalculations.hasOwnProperty(head.id)) {
					calculations = layout.tooltipCalculations[head.id];
				}

				const labelTooltip = document.createElement('li');
				labelTooltip.classList.add('google-visualization-tooltip-item');

				const spanLabel = document.createElement('span');
				spanLabel.classList.add('google-visualization-tooltip-label');
				spanLabel.textContent = label;

				const spanValue = document.createElement('span');
				spanValue.classList.add('google-visualization-tooltip-value');
				spanValue.textContent = value;
				if (isNullOrUndefined(calculations)) {
					total += parseInt(value.replace(/,/g, ''));
				} else {
					if (calculations.hasOwnProperty('calculation') && calculations.calculation === 'none')
						total += 0;
					// This could implement for explample a multiply property { multiply: 1000 } making the value multiply by 1000 before adding it to the total
				}

				const icon            = document.createElement('div');
				icon.style.background = color;

				icon.classList.add('google-visualization-tooltip-square');

				labelTooltip.appendChild(icon);
				labelTooltip.appendChild(spanLabel);
				labelTooltip.appendChild(spanValue);

				ul.appendChild(labelTooltip);
			}
		}

		const labelTooltip = document.createElement('li');
		labelTooltip.classList.add('google-visualization-tooltip-item');
		labelTooltip.classList.add('google-visualization-tooltip-header');
		labelTooltip.innerHTML = this.getTooltipLabel(row, header, layout);

		const spanValue = document.createElement('span');
		spanValue.classList.add('google-visualization-tooltip-value');
		spanValue.textContent = total.toLocaleString();

		if (showTotal)
			labelTooltip.appendChild(spanValue);

		ul.insertBefore(labelTooltip, ul.firstChild);

		tooltipContainer.appendChild(ul);
		row.c[1].f = tooltipContainer.innerHTML;
	}

	patchLegend<T>(legendItems: Array<BaseChartLegendItem>, chartOptions: {
																	colors: Array<string>,
																	series: {
																		[key: string]: {
																			type: 'line' | 'bars'
																		}
																	},
																	legend: {
																		position: string
																	}
																	seriesType: string
																},
																dataDescribed: DataDescribed<T, ChartLayoutAnnotation>
	) {

		if (chartOptions.legend.position === 'none')
			return;

		if (dataDescribed.layout.chartType !== 'PieChart') {
			// remove the X Axis
			legendItems.shift();
		}

		for (let i = 0; i < legendItems.length; i++) {
			const legendItem = legendItems[i];
			const color      = chartOptions.colors[i].startsWith('#', 0)
																						? chartOptions.colors[i]
																						: `#${chartOptions.colors[i]}`;
			const shape      = this.getShape(chartOptions, i, dataDescribed.layout.chartType, dataDescribed.layout.options.seriesType);

			legendItem.shape = shape as 'line' | 'bars' | 'circle';
			legendItem.color = color;
		}

		// when piechart hide the legend so it can use the real width and the chart will be rendered smaller
		if (dataDescribed.layout.chartType === 'PieChart')
			chartOptions.legend.position = 'none';

		return legendItems;
	}

	private getShape(chartOptions: {
																			colors: Array<string>;
																			series: {
																				[p: string]: {
																					type: 'line' | 'bars'
																				}
																			};
																			legend: {
																				position: string
																			};
																			seriesType: string
																		},
																		index: number,
																		chartType: string,
																		seriesType: 'line' | 'bars' | 'circle'): 'line' | 'bars' | 'circle' {
		let shape: 'line' | 'bars' | 'circle' = 'circle';

		// check if serie explicit set parameters
		if (chartOptions.series != null && chartOptions.series[index] != null)
			shape = chartOptions.series[index].type;
		// If not explicit specified, than check the seriesType
		else if (seriesType)
			shape = seriesType;
		// Last options is to check the chart type
		else if (chartType) {
			switch (chartType.toLowerCase()) {
				case 'columnchart':
					shape = 'bars';
					break;
				case 'linechart':
					shape = 'line';
					break;
			}

		}

		return shape;
	}

	private addLegend(id: string, label: string, colIndex: number, dataDescribed: DataDescribed<any, ChartLayoutAnnotation>,
																			legendItems: BaseChartLegendItem[], columnDescriptions: ColumnDescriptions) {

		const legendOptions = columnDescriptions && columnDescriptions.hasOwnProperty(id)
																								? columnDescriptions[id.toString()]
																								: {};


		legendItems.push({
																				description: '',
																				label:       label,
																				shape:       null,
																				color:       null,
																				column:      colIndex,
																				isFiltered:  false,
																				canFilter:   dataDescribed.layout.chartType !== 'PieChart' && (legendOptions.hasOwnProperty('canFilter')
																																																																																			? legendOptions['canFilter']
																																																																																			: true), // check for overrides
																				id:          id
																			});
	}

	private getTooltipLabel(row: google.visualization.DataObjectRow, header: google.visualization.DataObjectColumn[],
																									layout: ChartLayoutAnnotation) {


		if (layout && layout.options && layout.options.tooltip && layout.options.tooltip.format) {
			const labelFormat = layout.options.tooltip.format;
			if (labelFormat) {
				const label = this.formatProvider.format(row.c[0].v, new FormatRegisteredItem(null, labelFormat));
				return label;
			}
		}

		return isNullOrUndefined(row.c[0].f)
									? row.c[0].v
									: row.c[0].f;
	}

	getTooltipRolePropertyName(columnDescriptions: ColumnDescriptions): string | null {
		for (const colDesc of Object.keys(columnDescriptions)) {
			const item = columnDescriptions[colDesc];
			if (item.roles.tooltip)
				return colDesc;
		}
		return null;
	}

}

export class CsLegacyPMChartLoaderSetup implements CsChartLoaderSetup {

	constructor(private formatProvider: FormatProviderService, private toastManager: CsToastManagerService, private i8n: TranslateService) {
	}


	/**
		* Set the chart provider to use for drawing charts
		*/
	createNewChartProviderInstance() {
		return new CsChartGoogleProvider(this.toastManager, this.i8n);
	}

	getMetaProperties(event: ChartItemClickEventArgs, chartProvider: CsChartProvider) {
		const {columnProperties, rowProperties} = chartProvider.getMetaProperties(event);

		return {
			row:    rowProperties,
			column: columnProperties
		};
	}

	async mapToDataTable<T = any>(data: any) {
		if (!data || !data.data || !data.series) {
			return;
		}

		if (!CsChartGoogleProvider.isReady())
			await CsChartGoogleProvider.loadDependencies();


		const mappedData = this.mapData(data, data.series);

		const hasData = this.hasData(mappedData);

		return {
			dataTable: hasData
														? mappedData
														: null, legendItems: null
		};
	}

	/**
		* This function maps the given data to a google chart multi dimensional array

		*/
	private mapData(chartData, series) {
		const dataColumns = [];
		const mappings    = [];
		const tooltipProp = chartData.resolveTooltipProp || 'label';
		const xAxisProp   = chartData.resolveXAxisProp || 'labelMin';

		// Create empty mappings arrays based on the given axis members
		// Also create a list of id's to match data to a axis row
		for (const o of chartData.mainAxisMembers) {
			dataColumns.push(o.id);
			const tooltipVal = o.hasOwnProperty(tooltipProp)
																						? o[tooltipProp]
																						: 'label';
			const xAxisVal   = o.hasOwnProperty(xAxisProp)
																						? o[xAxisProp]
																						: 'labelMin';
			mappings.push([xAxisVal, tooltipVal]);
		}

		// Added the first column for the labels on the axis
		const header: any[] = [{label: 'Column', id: 'column', type: 'string'}, {type: 'string', role: 'tooltip', p: {'html': true}}];

		// Loop over the series and create a header for that serie
		for (let i = 0; i < chartData.series.length; i++) {
			const data      = series[i] || {label: 'Value ' + (i + 1)};
			// Add header to the header array
			const head: any = {label: data.label, id: data.presetName, type: 'number'};
			if (!isNullOrUndefined(data.preset) && data.preset.color)
				head.color = '#' + data.preset.color;

			head.format = !isNullOrUndefined(data.preset) && data.preset.format
																	? data.preset.format
																	: '{0:N2}';

			header.push(head);
			// Create a object by zipping two arrays where the first array contains the keys and the second the values
			// they are merged based on index
			const zippedData = [];
			if (!isNullOrUndefined(chartData.data.series[i])) {
				chartData.data.series[i].forEach(item => {
					zippedData.push(zipObject(chartData.data.columns, item));
				});
			}

			// get the property name for the key
			const colName   = chartData.data.columns[0];
			// get the property name for the value
			const valueName = chartData.data.columns[1];

			// loop over the given axises and fill the axis row with data
			for (let o = 0; o < chartData.mainAxisMembers.length; o++) {
				// get the axis
				const axisMem = chartData.mainAxisMembers[o];
				// get the axis row
				const row     = mappings[o];
				// Find the matching key for the Axis row and, if found, add the value to the row
				const found   = zippedData.find(x => x[colName] === axisMem.id);
				const value   = !isNullOrUndefined(found)
																				? found[valueName]
																				: null;

				row.push(value);

			}

		}

		this.addTooltips(mappings, header);

		// add headers to the mappings
		mappings.splice(0, 0, header);

		return mappings;
	}

	private hasData(data) {
		let hasData = false;

		data.forEach(x => {
			const nonEmptyValues = x.filter((value, key) => {
				return key > 0 && value > -1;
			});

			if (nonEmptyValues.length > 0) {
				hasData = true;
				return;
			}
		});

		return hasData;
	}

	private generateTooltipHtml(row: any[], header: any[]) {
		// create container
		const tooltipContainer = document.createElement('div');
		tooltipContainer.classList.add('google-visualization-tooltip');

		const ul = document.createElement('ul');
		ul.classList.add('google-visualization-tooltip-item-list');
		const labelTooltip = document.createElement('li');
		labelTooltip.classList.add('google-visualization-tooltip-item');
		labelTooltip.classList.add('google-visualization-tooltip-header');
		labelTooltip.innerHTML = row[1];

		ul.appendChild(labelTooltip);

		for (let i = 2; i < header.length; i++) {
			const head  = header[i];
			const value = this.formatProvider.format(row[i],
																																												new FormatRegisteredItem(null, head.format));
			const label = head.label + ':';
			const color = head.color;

			if (isNullOrUndefined(value))
				continue;

			const labelTooltip = document.createElement('li');
			labelTooltip.classList.add('google-visualization-tooltip-item');

			const spanLabel = document.createElement('span');
			spanLabel.classList.add('google-visualization-tooltip-label');
			spanLabel.textContent = label;

			const spanValue = document.createElement('span');
			spanValue.classList.add('google-visualization-tooltip-value');
			spanValue.textContent = value;

			const icon = document.createElement('div');

			if (!isNullOrUndefined(color))
				icon.style.backgroundColor = color;

			icon.classList.add('google-visualization-tooltip-square');

			labelTooltip.appendChild(icon);
			labelTooltip.appendChild(spanLabel);
			labelTooltip.appendChild(spanValue);

			ul.appendChild(labelTooltip);
		}
		tooltipContainer.appendChild(ul);
		return tooltipContainer.innerHTML;
	}

	private addTooltips(mappings: any[][], header: any[]) {
		for (const row of mappings) {
			row[1] = this.generateTooltipHtml(row, header);
		}
	}

}
