<div class="updates_container">

  <ng-container *ngFor="let item of data?.items">

    <div class="update-item">
      <i class="update-item--icon {{item.type}} {{item.icon}}"
         matTooltip="{{item.iconDescription | translate}}"></i>
      <div class="update-item--icon-connector"></div>
      <div class="update-item--content">
        <div class="update-item-labels">
          <span class="update-item-text-container">
          <ng-container *ngFor="let part of item.label">
            <span class="update-item--label {{part.type}}"
                  [class.update-item-has-link]="part.link"
                  [innerHTML]="part.text"
                  (click)="navigateToLink(part)"
            ></span>&nbsp;
          </ng-container>
            </span>
        </div>
        <div class="update-item--description">
          {{item.timestamp | format:'{0:M}'}}
        </div>
      </div>
    </div>

  </ng-container>

</div>

