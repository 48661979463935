import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService }  from '@ngx-translate/core';


@Component({
  selector: 'upload-form',
  templateUrl: './upload-form.component.html'
})
export default class UploadFormComponent {
  @Input() imgSrc: string;
  @Input() buttonText: string = 'Upload file';
  // @Input() loadingText: string = 'Uploading...';
  @Input() inProgress: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  @Input() commentText: string = '';
  @Output() onCommentTextChange = new EventEmitter<string>();
  @Output() onFilesDropped = new EventEmitter<File[]>();
  @Output() upload = new EventEmitter<void>();
  @Output() uploadComplete = new EventEmitter<{ files: File[], comment: string }>();

  @ViewChild('fileInput', { static: false }) fileInput: ElementRef;

	constructor(

		private l8n: TranslateService
  ) {}

  isDragging: boolean = false;
  droppedFiles: File[] = [];
  uploadCompleted: boolean = false;
	uploadText$: Observable<string>;
	commentsOptionalText$: Observable<string>;
	commentsPlaceholderText$: Observable<string>;
	maxFileSizeText$: Observable<string>;
	uploadFilesText$: Observable<string>;
	uploadButtonText$: Observable<string>;
	closeButtonText$: Observable<string>;


	ngOnInit() {

		// Fetch the translated text for the button
		this.uploadText$          = this.l8n.get('CLICK_TO_UPLOAD_TEXT');
		this.commentsOptionalText$    = this.l8n.get('ADD_COMMENTS_OPTIONAL_TEXT');
		this.commentsPlaceholderText$ = this.l8n.get('ADD_COMMENT_TEXT');
		this.maxFileSizeText$         = this.l8n.get('MAX_FILE_SIZE_TEXT');
		this.uploadFilesText$         = this.l8n.get('UPLOAD_FILES_TEXT');
		this.uploadButtonText$        = this.l8n.get('UPLOAD_BUTTON_TEXT');
		this.closeButtonText$         = this.l8n.get('CLOSE_BUTTON_TEXT');

		// this.l8n.get('LOADING_TEXT').subscribe((res: string) => {
		// 	this.loadingText = res;
		// });
  }

  handleDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragging = true;
  }

  handleDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
  }

  handleDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragging = false;
    const files = Array.from(event.dataTransfer?.files || []);
    this.droppedFiles = files;
    this.onFilesDropped.emit(files);
  }

  handleFileSelect(event: Event) {
    const input = event.target as HTMLInputElement;
    const files = Array.from(input.files || []);
    this.droppedFiles = files;
    this.onFilesDropped.emit(files);
  }

  triggerFileInput() {
    this.fileInput.nativeElement.click();
  }

  handleCommentTextChange(event: Event) {
    this.commentText = (event.target as HTMLInputElement).value;
    this.onCommentTextChange.emit(this.commentText);
  }

  uploadFiles() {
    this.upload.emit();
    this.uploadCompleted = true;
  }

  buttonClicked() {
    if (this.uploadCompleted) {
      this.uploadComplete.emit({ files: this.droppedFiles, comment: this.commentText });
    } else {
      this.uploadFiles();
    }
  }

  getButtonText(): Observable<string> {

		console.log('Button text')
		console.log(this.uploadCompleted ? this.closeButtonText$ : this.uploadButtonText$)
		return this.uploadCompleted ? this.closeButtonText$ : this.uploadButtonText$;
  }
}
