import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';  // Import FormsModule
import UploadFormComponent from './upload-form.component';
import { ComponentTranslationLoaderService, CsCommonPipesModule } from '@cs/common';



@NgModule({
  declarations: [
    UploadFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
		CsCommonPipesModule
  ],
  exports: [
    UploadFormComponent
  ]
})
export class UploadFormModule {
	constructor(loader: ComponentTranslationLoaderService) {
		loader.registerComponentModule('upload-form');
	}
 }
