<div class="pm-detail-panel-container cs-panel-header">
  <div class="cs-panel-header--header">
    <h2>{{label}}</h2>
    <div class="flex flex-align-items-center">
      <button *ngIf="isCollapsable" class="btn btn-sm btn--icon-and-text btn-quaternary mr-2" (click)="toggleCollapsedState()">
        <i style="font-size:unset" class="mdi mdi-arrow-collapse mr-1 mr-1"
           ng-class="{'mdi-arrow-collapse': !isCollapsed, 'mdi-arrow-open': isCollapsed}"></i>
        <span>{{(isCollapsed ? 'OPEN' : 'COLLAPSE') | translate}}</span>
      </button>
      <button class="btn btn-sm btn--icon-and-text btn-quaternary" (click)="closePanel()">
        <i style="font-size:unset" class="mdi mdi-close mr-1 mr-1"></i>
        <span>{{'CLOSE' | translate}}</span>
      </button>
    </div>
  </div>

  <div class="cs-panel-header--content" *ngIf="!isCollapsed">
    <ng-content></ng-content>
  </div>
</div>
