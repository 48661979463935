export class PropertyDataNode {
  public enabled: boolean;
  public remark: string;
  public intValue: number;
  public decimalValue: number;
  public textValue: string;
  public dataValue: string;

  public constructor(data) {
    this.enabled      = data.enabled;
    this.remark       = data.remark;
    this.intValue     = data.intValue;
    this.decimalValue = data.decimalValue;
    this.textValue    = data.textValue;
    this.dataValue    = data.dataValue;
  }

  public processChildren(children) {
  }

  public getData(key: string = null) {
    if (key == null && this.dataValue) {
      return this.dataValue;
    }

    if (key != null && this.dataValue) {
      return this.dataValue[key];
    }

    return;
  }
}
