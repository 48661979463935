<div #formWidthRuler class="form-width-ruler"></div>
<form autocomplete="{{hasAutoComplete}}">
	<div class="d-flex "
		 [class.flex-row]="form?.layout.layout.groupOrientation !== 'vertical'"
		 [class.flex-column]="form?.layout.layout.groupOrientation === 'vertical'"
		 [class.flex-wrap]="wrapFieldSetContainers"
		 [class.flex-justify-content-center]="alignCenter"
		 [class.flex-align-items-stretch]="alignStretch"
	>
		<ng-container *ngFor="let fieldSet of form?.layout.fieldSets; let index = index; let isLast = last;
    let isEven = even;">

			<div class="nxt-form-fieldset-container"
				 [class.read-only-as-text]="form.layout.layout.readOnlyAsText"
				 [class.read-only]="form.layout.layout.readOnly"
				 [ngStyle]="getComponentStyle(index)">
				<h2 class="nxt-form-fieldset-header">{{fieldSet.label}}</h2>
				<div class="nxt-form-fieldset"
					 [class.horizontal]="form?.layout.layout.orientation === 'horizontal'"
					 [class.vertical]="form?.layout.layout.orientation !== 'horizontal'">
					<ng-container *ngFor="let widgetCollection of fieldSet.widgetCollections">
						<ng-container *ngIf="widgetCollection.include">
							<pm-form-widget-collection class="cs-form-collection"
													   [collection]="widgetCollection">
							</pm-form-widget-collection>
						</ng-container>
					</ng-container>
				</div>
			</div>
			<div id="{{index+1}}" *ngIf="!isLast" class="nxt-form-fieldset-spacer"></div>
		</ng-container>

	</div>
</form>
