import { PropertyAnnotation } from '@cs/core';

export class PanelOption {
	id: string | number | symbol;
	propertyAnnotation: PropertyAnnotation<any>;

	constructor(init: PanelOption) {
		init.id                 = init.id;
		init.propertyAnnotation = init.propertyAnnotation;
	}
}
