import { Injectable }         from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface DateEntryStateState {

}

export function createInitialLoginState(): DateEntryStateState {
	return {};
}

@Injectable()
@StoreConfig({name: 'DataEntryState'})
export class DataEntryStateStore extends Store<DateEntryStateState> {

	constructor() {
		super(createInitialLoginState());
	}

}

