import { PropertyAnnotation } from '@cs/core';

export class DashboardListGroupRowItem {
  value: string;
  id: string;
}

export class DashboardListGroupRowHeader {
  value: string;
  id: string;
  originalHeader: PropertyAnnotation<any>;
  width: string;
}

export class DashboardListGroupRow {
  id: string;
  items: Array<DashboardListGroupRowItem> = [];
}

export class DashboardListGroup {
  label: string;
  id: string;
  headers: Array<DashboardListGroupRowHeader> = [];
  rows: Array<DashboardListGroupRow>          = [];
  isTruncated                                 = false;
}
