<ng-container *ngTemplateOutlet="updateScreen"></ng-container>

<!--	Update Screen	-->
<ng-template #updateScreen>

	<ng-container>

		<div class="update-dialog__content">

			<div class="update-dialog__select-repo">


				<ng-container *ngTemplateOutlet="selectableBranchesList"></ng-container>

				<ng-container *ngIf="parentRepositories.length > 1">
					<ng-container *ngTemplateOutlet="selectedRepositoryList"></ng-container>
				</ng-container>
			</div>


			<div class="update-dialog__commit-div">

				<h2 class="update-dialog__light-font update-dialog__align-right">Commit Message</h2>

				<div class="update-dialog__commit-div-content">

					<div *ngIf="selectedBranch && parentRepositories.length == 1" class="update-dialog__branch-info">
						<div class="update-dialog__detail-div">

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">Selected
									branch:</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">{{selectedBranch.branchName}}</h3>
							</div>

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">Commit date:</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">{{GetFormattedDate(selectedBranch.commitDate)}}</h3>
							</div>

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">Commit
									difference:</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">{{selectedBranch.commitDifference}}</h3>
							</div>

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">------</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">------</h3>
							</div>


							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">Commits
									behind:</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">{{selectedBranch.commitsBehind}}</h3>
							</div>

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">Commits
									ahead:</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">{{selectedBranch.commitsAhead}}</h3>
							</div>

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">------</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">------</h3>
							</div>

							<div class="update-dialog__detail-line">
								<h3 class="update-dialog__detail-line__left update-dialog__light-font">
									Commits message:
								</h3>
								<h3 class="update-dialog__detail-line__right update-dialog__light-font">{{selectedBranch.commitMessage}}</h3>
							</div>
						</div>
					</div>


					<div class="update-dialog__commit-message-div">

						<div class="update-dialog__textarea-div">
							<textarea class="update-dialog__textarea" [(ngModel)]="commitMessage"
									  id="commitMessageField"></textarea>
						</div>


						<div class="update-button-holder__content">

							<button class="update-button-holder__button btn btn-md dialog--cancel" (click)="onNoClick()"
									tabindex="1">
								Cancel
							</button>

							<button [disabled]="!HasValidUpdateRequirements()"
									class="update-button-holder__button btn btn--primary btn-md"
									(click)="CheckoutSubmoduleEvent(createRepositoryMap())"
									tabindex="2">
								Commit
							</button>
						</div>

						<div class="update-button-holder__content">

							<div class="update-button-holder__left">
								<input type="checkbox" class="btn-checkbox repository-checkbox"
									   [(ngModel)]="ignoreWarnings">
								<label class="update-dialog__light-font">Ignore warnings</label>
							</div>

							<div class="update-button-holder__right">
								<input type="checkbox" class="btn-checkbox repository-checkbox"
									   [(ngModel)]="commitAndPush">
								<label class="update-dialog__light-font">Commit and Push</label>
							</div>
						</div>

					</div>
				</div>

			</div>

		</div>

		<div *ngIf="featureBranchesStore.length < 1">
			<h2>Can't upgrade or downgrade</h2>
		</div>

	</ng-container>

</ng-template>
<!--	Update Screen	-->


<ng-template #selectableBranchesList>
	<div class="update-dialog__selector-div">

		<label class="update-dialog__light-font" for="branch-selector">
			Branches
		</label>


		<div class="update-dialog__toggle-button-group">

			<mat-button-toggle-group [value]="activeFilter" (change)="filterBranchList($event.value)">

				<ng-container *ngFor="let branchFilter of branchFilters">
					<mat-button-toggle [disabled]="!isBranchFilterDisabled(branchFilter)"
									   class="update-dialog__toggle-button"
									   value="{{branchFilter}}">
						{{branchFilter}}
					</mat-button-toggle>
				</ng-container>

			</mat-button-toggle-group>

		</div>

		<div class="update-dialog__selector-table">

			<ng-container *ngIf="featureBranches.length == 0">
				<h3>No branches found</h3>
			</ng-container>

			<ng-container *ngIf="featureBranches.length > 0">

				<table class="repository_table" id="branch-selector">
					<tbody>

					<tr *ngFor="let branch of featureBranches let i = index"
						[class.repository_table__selected-row]="i === selectedBranchRowIndex"
						[class.repository_table__clickable-row]="i !== selectedBranchRowIndex"
						(click)="selectBranchRow(i, branch)">

						<td class="repository_table__cell update-dialog__repository_table__cell repository_table__heavy-font repository_table__align-center">{{branch.branchName}}</td>
					</tr>

					</tbody>
				</table>

			</ng-container>

		</div>

	</div>
</ng-template>

<ng-template #selectedRepositoryList>

	<div class="update-dialog__selected-list">

		<h2 class="update-dialog__light-font update-dialog__align-left">Selected Repositories</h2>

		<div class="update-dialog__selector-table" style="max-height: 542.75px">


			<table class="repository_table">

				<tbody>

				<table>

					<tbody>
					<ng-container *ngFor="let repository of getRepositoryData(); let y = index;">

						<tr *ngIf="y < 1 || getRepositoryData()[y - 1].name != repository.name">
							<td class="repository_table__cell update-dialog__repository_table__cell repository_table__heavy-font repository_table__align-center">
								{{repository.name}}
							</td>
						</tr>


						<!--	spaghettilicious feature	-->
						<tr>

							<td *ngIf="getTableCell(repository,selectedBranch) === 1"
								class="repository_table__cell update-dialog__repository_table__cell repository_table__light-font repository_table__align-center btn--green"
								[matTooltip]="'Submodule already checked out current branch'">
								{{ repository.branch }}
							</td>

							<td *ngIf="getTableCell(repository,selectedBranch) === 2"
								class="repository_table__cell update-dialog__repository_table__cell repository_table__light-font repository_table__align-center btn--yellow"
								[matTooltip]="'Pending commit(s) detected'"
								(click)="ShowCommitInfo(repository)">
								{{ repository.branch }}
							</td>

							<td *ngIf="getTableCell(repository,selectedBranch) === 3"
								class="repository_table__cell update-dialog__repository_table__cell repository_table__light-font repository_table__align-center btn--primary"
								[matTooltip]="'Submodule already on this branch'">
								{{ repository.branch }}
							</td>

							<td *ngIf="getTableCell(repository,selectedBranch) === 4"
								class="repository_table__cell update-dialog__repository_table__cell repository_table__light-font repository_table__align-center">
								{{ repository.branch }}
							</td>

						</tr>

					</ng-container>

					</tbody>

				</table>


				</tbody>

			</table>

		</div>

	</div>

</ng-template>
