import { ConnectedOverlayPositionChange, OverlayRef }         from '@angular/cdk/overlay';
import { ChangeDetectorRef, HostListener, OnInit, Directive } from '@angular/core';
import { isNullOrUndefined }                                  from '@cs/core';
import { generateQuickGuid, LoggerUtil }                      from '@cs/core';

export class CsPopoverOverlayRef {
  /**
   * Random id used for identification and lookup
   */
  readonly guid    = generateQuickGuid();
  /**
   * Flag indicating the mouse is over the popover
   */
  hasMouseOver     = false;
  /**
   * Flag indicating the popover is open
   */
  isOpen           = false;
  /**
   * Flag indicating the mouse is over the popover host
   */
  hasMouseOverHost = false;

  constructor(private readonly overlay: OverlayRef) {
  }

  close() {
    this.overlay.dispose();
  }

  isVisible() {
    return this.overlay && this.overlay.overlayElement;
  }

  getPosition() {
    return this.overlay.overlayElement.getBoundingClientRect();
  }

  getOverlay() {
    return this.overlay;
  }
}

@Directive()
export abstract class CsPopoverOverlayBase {

  abstract overlayRef: CsPopoverOverlayRef;

  abstract cdr: ChangeDetectorRef;

  /**
   * Direction of the popover tooltip
   */
  setDirection: 'south' | 'north' | 'west' | 'east' = 'south';

  /**
   * Event handler for entering the popover.
   * This will set all corresponding flags indicating that the mouse has left the host and is now hovering over the popover.
   * @param event the mouse event
   */
  @HostListener('mouseenter', ['$event'])
  @HostListener('focusin', ['$event'])
  @HostListener('mousemove', ['$event'])
  mouseover(event: MouseEvent) {
    this.overlayRef.hasMouseOver     = true;
    this.overlayRef.hasMouseOverHost = false;
  }

  /**
   * Event handler for leaving the popover. Adds a delay so the check can verify if the mouse is
   * back on the popover host and stops the closing of the popover.
   * @param event the mouse event
   */
  @HostListener('mouseleave', ['$event'])
  @HostListener('focusout', ['$event'])
  mouseleave(event: MouseEvent) {
    this.overlayRef.hasMouseOver = false;
    window.setTimeout(() => {
      if (this.overlayRef.hasMouseOver || this.overlayRef.hasMouseOverHost)
        return;

      this.closePopover();

    }, 300);
  }

  /**
   * This will close the popover and removes it from the DOM. Also it sets the corresponding flags for the registered popover reference
   */
  protected closePopover() {
    this.overlayRef.close();
    this.overlayRef.isOpen           = false;
    this.overlayRef.hasMouseOverHost = false;
    this.overlayRef.hasMouseOver     = false;
  }

  /**
   * Check the the current position used to render the popover. When the popover doesn't fit inside the viewport it will use one
   * of the other preferred positions provided in the service. When the position changes it need to update the tooltip css orientation.
   */
  private setupPositioning() {
    if (isNullOrUndefined(this.overlayRef.getOverlay().getConfig().positionStrategy)) {
      LoggerUtil.warn('No overlay found');
      return;
    }

    // Done like this because of the private nature of the observable
    this.overlayRef.getOverlay().getConfig().positionStrategy['positionChanges'].subscribe(
      (value: ConnectedOverlayPositionChange) => {
        if (value.connectionPair.originY === 'top' && value.connectionPair.originX === 'center')
          this.setDirection = 'south';
        else if (value.connectionPair.originY === 'bottom' && value.connectionPair.originX === 'center')
          this.setDirection = 'north';
        else if (value.connectionPair.originY === 'center' && value.connectionPair.originX === 'start')
          this.setDirection = 'west';
        else if (value.connectionPair.originY === 'center' && value.connectionPair.originX === 'end')
          this.setDirection = 'east';

        this.cdr.detectChanges();
      });
  }

  /**
   * Call this to setup all logic for a popover.
   * - smart positioning based on viewport position
   */
  initPopover(): void {
    this.setupPositioning();
  }
}
