import {TableHeader} from './table-header.model';

export class TableHeaderRowLayout {

  /**
   * Collection of all the headers in the table
   */
  headers: TableHeader[] = [];
  /**
   * Identification for the header row
   */
  id: string;

  constructor(id: string) {
    this.id = id;
  }


}
