import {
	Component, EventEmitter, forwardRef, Input, OnInit, Output, ViewChild
}                                                               from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isNullOrUndefined }                                    from '@cs/core';
import { IDownloadRequestEventArgs }                            from '../../form-generator.models';

@Component({
	selector:    'cs-file-select',
	templateUrl: './file-select.component.html',
	providers:   [
		{
			provide:     NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CsFileSelect),
			multi:       true
		}
	]
})
export class CsFileSelect implements OnInit, ControlValueAccessor {

	/**
	 * Emits event when user clicks the download link

	 */
	@Output() fileDownloadRequest = new EventEmitter<IDownloadRequestEventArgs>();

	@Input() accept = '';

	@ViewChild('fileInput') fileInput;


	operation: string = 'keep';
	disabled          = false;
	hasExisting       = false;

	/**
	 * User selected file
	 */
	file: File;
	/**
	 * Identifier that is passed through the form generator when download link is clicked
	 */
	fileLinkIdentifier: string;

	/**
	 * Returns operations that can be performed on the file depending on the state

	 */
	get fileOperations() {

		if (this.hasExisting) {
			return [
				{key: 'keep', value: 'Keep'},
				{key: 'upload', value: 'Replace'},
				{key: 'remove', value: 'Remove'}
			];
		} else {
			return [
				{key: 'upload', value: 'New'},
				{key: 'none', value: 'None'}
			];
		}

	}

	private propagateChange: any   = (obj: any) => {
	};
	private onTouchedCallback: any = (obj: any) => {
	};
	private validateFn: any        = (fc: any) => {
	};

	constructor() {
	}

	ngOnInit() {
	}

	onFileOperationChange(operation: string) {
		this.file = null;
		switch (operation) {
			case 'remove':
				this.propagateChange('remove');
				break;
			default:
				this.propagateChange(this.fileLinkIdentifier);
				break;
		}
		this.operation = operation;
	}

	/**
	 * Handle user select of a new file
	 */
	onFileSelectChange(event: any) {
		const files = event.target.files as FileList;
		if (!isNullOrUndefined(files) && files.length) {
			this.file = files[0];
			this.propagateChange(this.file);
		} else {
			this.file = null;
		}
	}

	/**
	 * Clear the Input type=file element, to allow uploading of the same file
	 */
	clearInputElementValue() {
		this.fileInput.nativeElement.value = '';
	}

	/**
	 * User clicked download link, forward the request
	 */
	fileDownloadLinkClicked(fileLinkIdentifier: string) {
		// event.stopImmediatePropagation();
		// event.cancelBubble = true;

		this.fileDownloadRequest.emit({identifier: fileLinkIdentifier});
	}

	/**
	 * Control value set by external Form Control (reset entire state, according to Reactive Form paradigm)
	 */
	writeValue(data: string) {
		if (!isNullOrUndefined(data)) {
			this.fileLinkIdentifier = '' + data;
		}
		this.file        = null;
		this.hasExisting = !isNullOrUndefined(this.fileLinkIdentifier) && this.fileLinkIdentifier.length > 0;
		this.operation   = this.fileOperations[0].key;
	}

	/**
	 * From control state set by external form builder
	 */
	setDisabledState(isDisabled: boolean) {
		console.log('setDisabledState:' + isDisabled);
		this.disabled = isDisabled;
	}

	/**
	 * Callback by which we let the external world know this form control value has changed.
	 */
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	validate(fc: FormControl) {
		return this.validateFn(fc);
	}

}
