import { Component }                                            from '@angular/core';
import { DashboardPanelComponentBase }                          from '@cs/components/shared';
import { ArrayUtils, FormatRegisteredItem, TableDataDescribed } from '@cs/core';
import { FormatProviderService }                                from '@cs/common';
import {
	DashboardListGroup,
	DashboardListGroupRowHeader,
	DashboardListGroupRowItem,
	DashboardListGroupRow
}                                                               from './dashboard-list-grouped-items.models';


@Component({
			   selector:    'cs-dashboard-list-grouped-items',
			   templateUrl: './dashboard-list-grouped-items.component.html'
		   })
export class DashboardListGroupedItemsComponent extends DashboardPanelComponentBase<TableDataDescribed<any>> {

	name: string;

	groupItems: Array<DashboardListGroup> = [];

	get data(): TableDataDescribed<any> {
		return this._data;
	}

	set data(value: TableDataDescribed<any>) {
		this._data      = value;
		this.groupItems = this.parseDataDescribed(value);
	}

	constructor(private format: FormatProviderService) {
		super();
	}

	update(data: TableDataDescribed<any>): void {
		if (this._data && ArrayUtils.isEqual(this._data, data))
			return;

		this.data = data;
	}


	dataChanged(value: TableDataDescribed<any>) {
	}

	parseDataDescribed(data: TableDataDescribed<any>) {

		const out = [];

		const groups = this.groupDataRows(data);

		groups.forEach((item, key) => {
			const listGroup   = new DashboardListGroup();
			listGroup.label   = key;
			listGroup.headers = data.dataAnnotation
									.fields
									.filter(value => value.visible !== false
										&& !data.dataGroups.some(dataGroup => dataGroup.groupBy === value.id))
									.map(value => {
										const lgrh          = new DashboardListGroupRowHeader();
										lgrh.value          = value.label;
										lgrh.originalHeader = value;
										lgrh.id             = value.id.toString();
										return lgrh;
									});

			listGroup.rows = item.map(value => {
				const row = new DashboardListGroupRow();
				row.items = listGroup.headers.map(value1 => {
					const cell = new DashboardListGroupRowItem();
					cell.value = this.format.format(value[value1.id], new FormatRegisteredItem(null, value1.originalHeader.format));
					return cell;
				});
				return row;
			});

			out.push(listGroup);
		});

		return out;

	}

	/**
	 * Detects if the header is truncated and if so add an tooltip
	 */
	detectTruncatedField($event: MouseEvent, item: {
		isTruncated: boolean;
	}) {
		const element = $event.currentTarget as HTMLElement;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}

		item.isTruncated = isEllipsisActive(element);

	}
	private groupDataRows(data: TableDataDescribed<any>): Map<string, any[]> {

		const set     = new Map<string, any[]>();
		const dataSet = data.data as any[];

		for (const group of data.dataGroups) {
			for (const row of dataSet) {
				const filterValue = row[group.groupBy];

				if (filterValue == null)
					continue;

				if (!set.has(filterValue)) {
					set.set(filterValue, [row]);
				} else {
					const found = set.get(filterValue);
					found.push(row);
				}
			}

		}

		return set;

	}
}
