import { Component, Inject, OnInit }                       from '@angular/core';
import { DashboardPanelComponentBase }                     from '@cs/components/shared';
import { DashboardUpdates, DashboardUpdatesItemLabelPart } from './dashboard-updates.models';
import { TranslateService }                                from '@ngx-translate/core';
import { ArrayUtils }                                      from '@cs/core';
import { CsToastManagerService }                           from '@cs/components/toast-manager';

@Component({
			   selector:    'cs-dashboard-updates',
			   templateUrl: './dashboard-updates.component.html'
		   })
export class DashboardUpdatesComponent extends DashboardPanelComponentBase<DashboardUpdates> implements OnInit {

	name: string;

	get data(): DashboardUpdates {
		return this._data;
	}

	set data(value: DashboardUpdates) {
		this._data = new DashboardUpdates(value);
	}

	constructor(@Inject(CsToastManagerService) private readonly toasts: CsToastManagerService,
				private readonly i8n: TranslateService) {
		super();
	}

	ngOnInit() {
	}


	update(data: DashboardUpdates): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

	navigateToLink(item: DashboardUpdatesItemLabelPart) {
		if (!item.link)
			return;

		this.toasts.show({
							 type:    'alert',
							 content: this.i8n.instant('NOT_IMPLEMENTED_YET')
						 });
	}

	dataChanged(value: DashboardUpdates) {

	}
}
