import { IConfigService }    from '@cs/performance-manager/shared';
import { Observable }        from 'rxjs';
import { Result }            from '@cs/core';
import { DownloadsCategory } from './models/downloads-category';
import { DownloadsItem }     from './models/downloads-item';
import { HttpResponse }      from '@angular/common/http';

export abstract class DownloadsConfigService implements IConfigService {
  moduleName = 'downloads';

  showNews: boolean;

  abstract getDownloadItems(maxItems?: number): Observable<Result<Array<DownloadsItem>>>;

  abstract getFile(selectedFileId: number): Observable<Result<HttpResponse<Blob>>>;

}
