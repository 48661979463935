import { EventEmitter, Injectable } from '@angular/core';
import { SpinnerChangedEventArgs }  from '@cs/components/spinner';

import { GridOptions }       from '../classes/grid-options';
import { RowButton }         from '../classes/row-button';
import { RowClickEventArgs } from '../event-args/row-clicked.event-args';
import { IProperty }         from '@cs/core';
import { GridDataCell }      from '../models/grid-data-cell.model';
import { GridDataRow }       from '../models/grid-data-row.model';
import { GridGroup }         from '../models/grid-group.model';
import { GridSheet }         from '../models/grid-sheet.model';
import {
	CellEditedEventArgs,
	SheetActionEventArgs,
	CellActionClickEventArgs,
	CellClickEventArgs,
	RowButtonClickEventArgs
}                            from '../event-args';

@Injectable()
export class CsDataGridParentService {

	cellInputChanged: (cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, $event: Event) => void;
	selectionChanged: (cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, $event: MouseEvent) => void;
	checkForTabPress: (cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, index: number, $event: KeyboardEvent) => void;
	updateRowValues: (cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, changeEvent: SpinnerChangedEventArgs) => void;
	rowSelected: (rowClickEventArgs: RowClickEventArgs) => void;
	rowButtonClicked: (button: RowButton, row: GridDataRow, group: GridGroup, sheet: GridSheet) => void;
	_resolvedMemberList: Map<string, Array<IProperty>>;
	options: GridOptions;
	onCellsEdited: EventEmitter<CellEditedEventArgs>;
	onSheetActionClicked: EventEmitter<SheetActionEventArgs>;
	requestingCellActionOnClick: EventEmitter<CellActionClickEventArgs>;
	onNavigationRequested: EventEmitter<CellClickEventArgs>;
	onRowAdded: EventEmitter<RowClickEventArgs>;
	onRowSelected: EventEmitter<RowClickEventArgs>;
	onRowButtonClicked: EventEmitter<RowButtonClickEventArgs>;
	findCellTdById: (id: string) => any;
}
