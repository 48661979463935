import { Directive, ElementRef, Input, AfterViewInit, HostBinding, OnChanges } from '@angular/core';
import { Observable }                                                          from 'rxjs';
import { ComponentChanges, whenChanging, generateQuickGuid }                   from '@cs/core';

@Directive({
	selector: '[csLoader]',
	exportAs: 'csLoaderRef'
})
export class LoaderDirective implements AfterViewInit, OnChanges {
	get injectIntoElementRef(): Element {
		if (!this._injectIntoElementRef) {
			const element = this._elementRef.nativeElement as HTMLElement;
			const found   = element.querySelector(this.injectIntoElement);
			if (found)
				this._injectIntoElementRef = found;
			else
				throw new Error(`${this.injectIntoElement} not found`);
		}
		return this._injectIntoElementRef;
	}

	@Input() csLoader: boolean;
	@Input() offset                                 = 0;
	@Input() showAtLeastFor                         = 0;
	@Input() loaderClass                            = 'loader';
	@Input() injectIntoElement: string              = null;
	@Input() loaderAdditionalClasses: Array<string> = [];
	@Input() loaderContainerClasses: Array<string>  = ['extend-right'];
	@Input() elementClasses: Array<string>          = [];
	@Input() disableOnSuccess                       = false;
	@Input() disabledIsHandledAlready               = false;
	@Input() removeLoaderBody                       = false;

	loaderName: string;
	private loadingCss = 'is-loading';
	private pendingRemoval: number;
	private loaderContainer: HTMLDivElement;
	private _injectIntoElementRef: Element;

	get containerElement() {
		return this.injectIntoElement ? this.injectIntoElementRef : this._elementRef.nativeElement;
	}

	constructor(private _elementRef: ElementRef) {
		this.loaderName = generateQuickGuid();
	}

	ngAfterViewInit(): void {
		this.init();
	}

	startLoading() {
		const element: HTMLElement = this.containerElement;
		if (element && !element.classList.contains(this.loadingCss)) {
			element.classList.add(this.loadingCss);
			// disable a button
			element.setAttribute('disabled', `${true}`);
		}
	}

	stopLoading() {
		const element: HTMLElement = this.containerElement;
		if (this.pendingRemoval != null) {
			clearTimeout(this.pendingRemoval);
		}
		this.pendingRemoval = window.setTimeout(() => {
			if (element && element.classList.contains(this.loadingCss)) {
				element.classList.remove(this.loadingCss);
				// disable a button
				element.removeAttribute('disabled');
			}
			if (this.removeLoaderBody)
				this.loaderContainer.remove();
			this.pendingRemoval = null;
		}, this.showAtLeastFor);
	}


	/**
	 * Detect the type of element and setup the styling and logic
	 */
	private init() {
		const element: HTMLElement = this.containerElement;
		switch (element.tagName) {
			case 'BUTTON':
			case 'A':
				this.setupLoaderForButton();
				break;
			case 'DIV':
				this.setupLoaderForDiv();
				break;
		}
	}


	/**
	 * Bootstrap element with the Button loader logic
	 */
	private setupLoaderForButton() {
		const element: HTMLElement = this.containerElement;
		this.elementClasses.push('cs-loader-btn');
		// add button loader class to the element
		this.elementClasses.forEach(value => element.classList.add(value));

		// create container for the loader
		this.loaderContainer = document.createElement('div');
		this.loaderContainer.classList.add('loader-container');
		this.loaderContainer.classList.add('extend-right');

		this.loaderContainer.id = this.loaderName;
		const loader            = document.createElement('div');
		loader.classList.add(this.loaderClass);

		if (this.loaderAdditionalClasses.length > 0)
			this.loaderAdditionalClasses.forEach(value => loader.classList.add(value));

		element.appendChild(this.loaderContainer);
		this.loaderContainer.appendChild(loader);
	}

	/**
	 * Bootstrap element with the Div loader logic
	 */
	private setupLoaderForDiv() {
		const element: HTMLElement = this.containerElement;
		this.elementClasses.push('div-loader');
		this.elementClasses.forEach(value => element.classList.add(value));

		// create container for the loader
		this.loaderContainer = document.createElement('div');
		this.loaderContainer.classList.add('loader-container');
		this.loaderContainerClasses.forEach(value => this.loaderContainer.classList.add(value));

		this.loaderContainer.id = this.loaderName;
		const loader            = document.createElement('div');
		loader.classList.add(this.loaderClass);

		if (this.loaderAdditionalClasses.length > 0)
			this.loaderAdditionalClasses.forEach(value => loader.classList.add(value));

		element.appendChild(this.loaderContainer);
		this.loaderContainer.appendChild(loader);
	}

	ngOnChanges(changes: ComponentChanges<LoaderDirective>): void {
		const element: HTMLElement = this.containerElement;

		whenChanging(changes.csLoader, true).execute(value => {
			value.currentValue ? this.startLoading() : this.stopLoading();
			if (!value.firstChange && this.disableOnSuccess && !value.currentValue) {
				element.setAttribute('disabled', `${true}`);
			}
		});

		whenChanging(changes.disableOnSuccess, true).execute(value => {
			if (!value.firstChange && !value.currentValue) {
				element.removeAttribute('disabled');
			}
		});
	}
}
