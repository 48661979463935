import {
	AnimationTriggerMetadata,
	trigger,
	state,
	transition,
	style,
	animate
} from '@angular/animations';

export const toastAnimations: {
	readonly fadeToast: AnimationTriggerMetadata;
} = {
	fadeToast: trigger('fadeAnimation', [
		state('void', style({ opacity: 0, transform: 'translateY(100%)' })), // Initial state, before the toast appears
		state('default', style({ opacity: 1, transform: 'translateY(0)' })), // Default, fully visible state
		state('closing', style({ opacity: 0, transform: 'translateY(100%)' })), // State during closing animation

		// Transition from void (hidden) to default (visible)
		transition('void => default', [
			style({ opacity: 0, transform: 'translateY(100%)' }),
			animate('{{ fadeIn }}ms ease-out', style({ opacity: 1, transform: 'translateY(0)' }))
		]),

		// Transition from default (visible) to closing (disappearing)
		transition('default => closing', [
			animate('{{ fadeOut }}ms ease-in', style({ opacity: 0, transform: 'translateY(100%)' }))
		])
	])
};


export type ToastAnimationState = 'default' | 'closing';
