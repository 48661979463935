<div class="login-form-container">
	<form enctype="application/x-www-form-urlencoded"
		  (keyup.enter)="login()">
		<div class="form-group">
			<i class="input-icon mdi mdi-account"></i>
			<input class="form-control icon-input"
				   type="text"
				   name="username"
				   placeholder="{{'USERNAME' | translate | capitalize}}"
				   (keydown)="resetErrorState()"
				   [(ngModel)]="loginModel.username"
				   required="">
		</div>
		<div class="form-group">
			<i class="input-icon mdi mdi-key-variant"></i>
			<input class="form-control"
				   type="password"
				   name="password"
				   placeholder="{{'PASSWORD' | translate | capitalize}}"
				   (keydown)="resetErrorState()"
				   [(ngModel)]="loginModel.password"
				   required="">
		</div>


		<!--<div class="remember-container">
			<label class="checkbox-container">

				<input type="checkbox"
					   class="form-control"
					   name="remember_me"
					   [(ngModel)]="rememberMe"
					   autocomplete="off">
				<span class="checkmark"></span>
				{{'REMEMBER_USERNAME' | translate | capitalize}}
			</label>
		</div>-->

		<div matTooltip="Invalid username or password"
			 matTooltipPosition="above"
			 [matTooltipDisabled]="!($isErrorState | async)">
			<button class="btn btn--primary btn--lg"
					[csLoader]="$inProgress | async"
					[disableOnSuccess]="$turnOnHandler | async"
					[loaderAdditionalClasses]="['loader--white']"
					(click)="login()">
				{{'LOGIN' | translate | capitalize}}
			</button>
		</div>
	</form>
	<p class="card-text login-footer-text">
		{{'IF_YOU_HAVE_PROBLEMS_LOGGING_IN' | translate | capitalize}}
		<a href="mailto:{{loginConfig.supportEmail}}"
		   class="link">{{loginConfig.supportEmail}}</a>
	</p>
	<div class="login-form--bottom-bar">
		<a *ngIf="showResetPassword" [routerLink]="loginConfig.RECOVER_URL" class="link font-weight-bold">
			{{'FORGOT_PASSWORD' | translate | capitalize}}</a>
	</div>

</div>
