import { Component, Inject, Input, OnInit }           from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { isNullOrUndefined } from '@cs/core';
import { Thumbnail }         from './models/thumbnail.model';

export interface ImageSliderData {
  thumbnails: Array<Thumbnail>;
  rootUrl: string;
}

@Component({
  selector:    'cs-image-slider',
  templateUrl: './image-slider.component.html',
  styleUrls:   ['./image-slider.component.scss'],
  animations:  [
    trigger('move', [
      state('in', style({transform: 'translateX(0)'})),
      transition('void => left', [
        style({transform: 'translateX(100%)'}),
        animate('200ms ease-in')
      ]),
      transition('left => void', [
        animate('200ms ease-out', style({transform: 'translateX(0)'}))
      ]),
      transition('void => right', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in')
      ]),
      transition('right => void', [
        animate('200ms ease-out', style({transform: 'translateX(0)'}))
      ])
    ])
  ]
})
export class ImageSliderComponent implements OnInit {

  @Input() thumbnails: Array<Thumbnail>;

  public state = 'void';
  currentThumb: Thumbnail;


  constructor(public dialogRef: MatDialogRef<ImageSliderComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ImageSliderData) {
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.data) && !isNullOrUndefined(this.data.thumbnails)) {
      this.thumbnails = this.data.thumbnails.concat();
      if (this.thumbnails.length > 0)
        this.currentThumb = this.thumbnails[0];
    }
  }

  getFullUrl(url: string) {
    return this.data.rootUrl + url;
  }

  imageRotate(arr, reverse) {
    if (reverse) {
      this.currentThumb = arr.pop();
      arr.unshift(this.currentThumb);
    } else {
      this.currentThumb = arr.shift();
      arr.push(this.currentThumb);
    }
    return arr;
  }

  moveLeft() {
    this.state = 'right';
    this.imageRotate(this.thumbnails, true);
  }

  moveRight() {

    this.state = 'left';
    this.imageRotate(this.thumbnails, false);
  }

  onFinish($event) {
    this.state = 'void';
  }

  onCloseClick(): void {
    this.dialogRef.close(null);
  }
}
