<div class="popover-container"
     [class.has-tip]="settings.hasTip"
     [ngClass]="settings.class"
     [ngStyle]="_transformOrigin"
     [@transformContainer]="_panelAnimationState"
     (@transformContainer.start)="_onAnimationStart($event)"
     (@transformContainer.done)="_onAnimationDone($event)">
  <div class="cs-animation-content">
    <ng-container [ngSwitch]="renderMethod">

      <div *ngSwitchCase="'text'" [innerHTML]="content"></div>

      <ng-container *ngSwitchCase="'template'">
        <ng-container *ngTemplateOutlet="content; context: context"></ng-container>
      </ng-container>

      <ng-container *ngSwitchCase="'component'">
        <ng-container *ngComponentOutlet="content"></ng-container>
      </ng-container>

    </ng-container>
  </div>
</div>
