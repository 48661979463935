import { CsFormGeneratorDataSource }    from '@cs/components/form-generator';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { IConfigService }               from '@cs/performance-manager/shared';
import { OtpModel }                     from './model/otp.model';
import { Observable }                   from 'rxjs';
import { UserLocationInfo }             from './model/user-location-info';
import { UserPersonalProfile }          from './model/user-personal-profile';

export abstract class UserProfileConfigService implements IConfigService {
	moduleName = 'user-profile';

	abstract hasPlatformPasswordRecoveryAvailableForCurrentAccount(options?: CsHttpRequestOptions): Observable<Result<boolean>>;

	abstract getPersonalInfo(): Observable<Result<CsFormGeneratorDataSource>>;

	abstract getLocationInfo(): Observable<Result<CsFormGeneratorDataSource>>;

	abstract getAccountInfo(options?: CsHttpRequestOptions): Observable<Result<CsFormGeneratorDataSource>>;

	abstract updateLocationInfo(data: UserLocationInfo): Observable<Result<any>>;

	abstract updatePersonalInfo(data: UserPersonalProfile): Observable<Result<any>>;

	abstract getMultiFactorAuthenticationSetup(): Observable<Result<OtpModel>>;

	abstract verifyOtp(otpCode: string, options?: CsHttpRequestOptions): Observable<Result<any>>;

	abstract deleteOtp(options?: CsHttpRequestOptions): Observable<Result<any>>;
}
