import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                from '@angular/material/dialog';
import { BranchDto, Commit, RepositoryDto }             from '@cs/performance-manager/git-graph';

import { BehaviorSubject } from 'rxjs';

import { isNullOrUndefined } from '@cs/core/utils';

import {
	UpdateRepositoryOptions,
	UpdateRepositoryRequest,
	UpdateRepositoryType
}                                        from './models/submodule-update-dialog.models';
import { SubmoduleUpdateServiceService } from './services/submodule-update-service.service';

@Component({
			   selector:    'dialog-update-submodule',
			   templateUrl: './submodule-update-dialog.component.html',
			   styleUrls:   ['./submodule-update-dialog.component.scss']
		   })
export class SubmoduleUpdateDialogComponent implements OnInit {

	// Update dialog settings
	type: UpdateRepositoryType = UpdateRepositoryType.warning;
	allowSameBranch            = false;

	// State
	commitInfo: boolean;
	updateFinished: boolean;
	commitFinished: boolean;

	selectedRepository: RepositoryDto;

	// Update event stuff
	afterUpdate: any;
	inputValue = '';

	// Values for updating
	parentRepositories: RepositoryDto[] = [];
	childRepository: RepositoryDto      = undefined;

	unPushedCommits: Commit[]         = [];
	featureBranchesStore: BranchDto[] = [];

	// Load messages
	loadMessage  = '';
	errorMessage = '';

	submoduleCommitErrors: Map<RepositoryDto, {
		isSuccessFull: boolean,
		message: string
	}> = new Map<RepositoryDto, {
		isSuccessFull: boolean,
		message: string
	}>();

	pushCommitErrors: Map<RepositoryDto, {
		isSuccessFull: boolean,
		message: string
	}> = new Map<RepositoryDto, {
		isSuccessFull: boolean,
		message: string
	}>();


	isUpdating                          = false;
	requests: UpdateRepositoryRequest[] = [];

	requestInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

	constructor(public dialogRef: MatDialogRef<SubmoduleUpdateDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: UpdateRepositoryOptions,
				@Inject(ChangeDetectorRef) private changeDetector: ChangeDetectorRef,
				@Inject(SubmoduleUpdateServiceService) private submoduleUpdateService: SubmoduleUpdateServiceService
	) {
	}

	async ngOnInit() {
		this.setInitialParameters();

		await this.LoadRepositoryBranchesEvent();

		if (!isNullOrUndefined(this.data.type)) this.type = this.data.type;
	}

	setInitialParameters() {
		this.childRepository    = this.data.childRepository;
		this.parentRepositories = this.data.parentRepositories;
		this.afterUpdate        = this.data.afterUpdateEvent;
		this.allowSameBranch    = this.data.allowSameBranch;
	}

	public async LoadRepositoryBranchesEvent() {
		this.loadMessage = 'Loading Branches Data';

		this.requestInProgress$ = new BehaviorSubject<boolean>(false);

		console.log('Lenght');
		console.log(this.parentRepositories.length > 1);

		const result = await this.submoduleUpdateService.LoadRepositoryBranchesAsync(this.childRepository, this.parentRepositories.length > 1, this.requestInProgress$);

		result.forEach(branchDto => {
			if (this.allowSameBranch
				? true
				: branchDto.commitHash != this.childRepository.hashOrBranch) {
				if (!this.featureBranchesStore.some(br => br.branchName == branchDto.branchName) && branchDto.branchName != 'HEAD') this.featureBranchesStore.push(branchDto);
			}
		});

		this.changeDetector.markForCheck();
	}

	public async PushAndCommit(data: {
		repositoryList: Map<string, RepositoryDto[]>,
		selectedBranch: BranchDto,
		commitMessage: string
	}) {
		this.requests = [];

		this.loadMessage = 'Updating Repositories';
		this.isUpdating  = true;

		await this.CheckoutAndPushSubmoduleMapAsync(data.repositoryList, data.selectedBranch, data.commitMessage);

		this.updateFinished = true;
		this.commitFinished = true;

		this.isUpdating = false;

		await this.ReadRepositoriesEvent();

		this.afterUpdate();
	}

	public async PushEvent(repositories: Map<string, RepositoryDto[]>) {
		this.requests = [];

		this.loadMessage = 'Pushing Commit';
		this.isUpdating  = true;

		await this.PushRepositoryMapAsync(repositories);

		this.isUpdating     = false;
		this.updateFinished = true;

		await this.ReadRepositoriesEvent();

		this.afterUpdate();
	}

	public async CheckoutSubmoduleEvent(data: {
		repositoryList: Map<string, RepositoryDto[]>,
		selectedBranch: BranchDto,
		commitMessage: string
	}) {
		this.requests = [];

		this.loadMessage = 'Updating Submodule';
		this.isUpdating  = true;

		await this.CheckoutSubmoduleMapAsync(data.repositoryList, data.selectedBranch, data.commitMessage);

		this.isUpdating     = false;
		this.commitFinished = true;

		await this.ReadRepositoriesEvent();

		this.afterUpdate();
	}

	public async ReadRepositoriesEvent() {

		this.loadMessage = 'Reading Repositories Event';

		this.requestInProgress$ = new BehaviorSubject<boolean>(false);

		const readRepositories: RepositoryDto[] = await this.submoduleUpdateService.ReadRepositoriesAsync(this.parentRepositories, this.requestInProgress$);

		this.parentRepositories.forEach((parentRepo, parentIndex) => {

			console.log('Resulting Repositories');

			console.log(readRepositories);

			const matchingRepo = readRepositories.find(newRepo => newRepo.name === parentRepo.name && newRepo.branch === parentRepo.branch);

			if (matchingRepo) {
				matchingRepo.errorList = parentRepo.errorList;

				parentRepo.subModules          = matchingRepo.subModules;
				parentRepo.hashOrBranch        = matchingRepo.hashOrBranch;
				parentRepo.unPushedCommitCount = matchingRepo.unPushedCommitCount;

				this.parentRepositories[parentIndex] = parentRepo;
			}

		});

		this.afterUpdate();

		this.changeDetector.markForCheck();
	}

	public async RemoveCommitEvent(repository: RepositoryDto) {
		this.requests = [];

		this.loadMessage = 'Removing Commits';

		const updateRequest = new UpdateRepositoryRequest();
		this.requests.push(updateRequest);

		this.isUpdating = true;

		repository.hashOrBranch = await this.submoduleUpdateService.RemoveCommitAsync(repository, updateRequest);

		await this.GetUnpushedCommitsEvent(repository);

		this.isUpdating = false;

		await this.ReadRepositoriesEvent();
	}

	public async GetUnpushedCommitsEvent(repository: RepositoryDto) {
		this.requests = [];

		this.loadMessage = 'Loading Commits';

		const updateRequest = new UpdateRepositoryRequest();
		this.requests.push(updateRequest);

		this.isUpdating = true;

		this.unPushedCommits = await this.submoduleUpdateService.GetUnpushedCommitsAsync(repository, updateRequest);

		this.isUpdating = false;
	}

	// Map Service Logic
	async PushRepositoryMapAsync(repositories: Map<string, RepositoryDto[]>) {

		const promises = Array.from(repositories.values())
							  .map(async (repositoryList) => {
								  const updateRequest    = new UpdateRepositoryRequest();
								  updateRequest.maxSteps = repositoryList.length;

								  this.requests.push(updateRequest);

								  await this.PushRepositoryArrayAsync(repositoryList, updateRequest);

								  updateRequest.message = 'Pushing changes done on ' + repositoryList[0].name + ' | ' + repositoryList[0].branch;
							  });

		await Promise.all(promises);
	}

	async PushRepositoryArrayAsync(repositoryList: RepositoryDto[], updateRequest: UpdateRepositoryRequest) {
		for (const repository of repositoryList) {
			try {
				if (repository.unPushedCommitCount > 0) {
					await this.submoduleUpdateService.PushRepositoryAsync(repository, updateRequest, this.pushCommitErrors);
				} else {
					this.pushCommitErrors.set(repository, {isSuccessFull: false, message: 'No commits to push'});
				}
			} catch (e) {
				console.log(e);
			}
		}
	}

	async CheckoutAndPushSubmoduleMapAsync(repositories: Map<string, RepositoryDto[]>, selectedBranch: BranchDto, commitMessage: string) {

		const promises = Array.from(repositories.values())
							  .map(async (repositoryList) => {
								  const updateRequest    = new UpdateRepositoryRequest();
								  updateRequest.maxSteps = repositoryList.length * 3;

								  this.requests.push(updateRequest);

								  await this.CheckoutSubmoduleListAsync(repositoryList, selectedBranch, commitMessage, updateRequest);

								  await this.PushRepositoryArrayAsync(repositoryList, updateRequest);

								  updateRequest.message = 'Pushing changes done on ' + repositoryList[0].name + ' | ' + repositoryList[0].branch;
							  });

		await Promise.all(promises);
	}


	async CheckoutSubmoduleMapAsync(repositories: Map<string, RepositoryDto[]>, selectedBranch: BranchDto, commitMessage: string) {

		const promises = Array.from(repositories.values())
							  .map(async (repositoryList) => {
								  const updateRequest = new UpdateRepositoryRequest();

								  updateRequest.maxSteps = repositoryList.length * 2;

								  this.requests.push(updateRequest);

								  await this.CheckoutSubmoduleListAsync(repositoryList, selectedBranch, commitMessage, updateRequest);

								  updateRequest.message = 'Checkout Submodule done on ' + repositoryList[0].name + ' | ' + repositoryList[0].branch;
							  });

		await Promise.all(promises);
	}

	async CheckoutSubmoduleListAsync(repositoryList: RepositoryDto[], selectedBranch: BranchDto, commitMessage: string, updateRequest: UpdateRepositoryRequest) {
		for (const repository of repositoryList) {
			try {
				if (!repository.subModules.some(s => s.hashOrBranch.trim() === selectedBranch.commitHash.trim())) {
					await this.submoduleUpdateService.CheckoutSubmoduleAsync(repository, this.childRepository, selectedBranch, updateRequest, this.submoduleCommitErrors);

					const commitResult = await this.submoduleUpdateService.CommitRepositoryAsync(repository, commitMessage, updateRequest, this.submoduleCommitErrors);

					this.unPushedCommits = [...commitResult];
				} else {
					this.submoduleCommitErrors.set(repository, {isSuccessFull: false, message: 'Repository is already on this branch'});
				}
			} catch (e) {
				console.log(e);
			}
		}
	}

	async showCommitInfo(repository: RepositoryDto) {
		this.selectedRepository = repository;

		this.commitInfo = true;

		await this.GetUnpushedCommitsEvent(repository);
	}

	onNoClick() {
		this.dialogRef.close();
	}

}
