import { IConfigService }                                                                     from '@cs/performance-manager/shared';
import { Observable, Subject }                                                                from 'rxjs';
import {
	DashboardGridData, DashboardPanel, MessageBusMessage
}                                                                                             from '@cs/components/dashboard';
import {
	FilterBarDataSource,
	FilterBarResultParams
}                                                                                             from '@cs/components/filter-and-compare-bar';
import { CsHttpRequestOptions, HTTP_METHOD, Result, SelectionTargetResult, ValidationResult } from '@cs/core';
import { HttpResponse }                                                                       from '@angular/common/http';
import { ActivatedRoute }                                                                     from '@angular/router';

export abstract class DashboardLiteConfigService implements IConfigService {

	moduleName = 'dashboard';

	private readonly _messageBus: Subject<MessageBusMessage<any>> = new Subject();


	abstract getDashboardData(selection: { [p: string]: any },
														params?: { [p: string]: any }): Observable<Result<DashboardGridData>>;

	abstract downloadFile(panel: string,
												selection: FilterBarResultParams,
												subPanelName?: string,
												csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<HttpResponse<Blob>>>;

	abstract navigateTo(selectionTargetResult: SelectionTargetResult, activeRoute: ActivatedRoute): Promise<boolean>;

	abstract triggerHttpRequest<I = any, T = any>(method: HTTP_METHOD, endpoint: string, body: I, queryParams?: { [p: string]: any }): Observable<HttpResponse<T>>;

	abstract metaButtonClicked(panelName: string,
														 dashboardName: string,
														 selection: { [p: string]: any },
														 csHttpRequestOptions?: CsHttpRequestOptions) ;
}


export abstract class DashboardWithDetailsConfigService extends DashboardLiteConfigService {
	/**
	 * Trigger if the dashboard should wait for the @Link(FilterNavbarService) to get the data
	 */
	initWithData = false;

	abstract getPanelDetails(selection: { [p: string]: any }, panelName: string): Observable<Result<DashboardGridData>>;

}

export abstract class DashboardConfigService extends DashboardWithDetailsConfigService {


	/**
	 * Trigger if the dashboard should wait for the @Link(FilterNavbarService) to get the data
	 */
	initWithData = false;

	abstract getPanel(panelName: string,
										selection: { [p: string]: any },
										subPanelName?: string,
										csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<DashboardPanel>>;

	abstract getFilterAndCompareBarData(params?: { [key: string]: any },
																			name?: string,
																			isComparison?: boolean,
																			paramsMain?: { [key: string]: any },
																			trigger?: string): Observable<Result<FilterBarDataSource<FilterBarResultParams>>>;

}


export abstract class DashboardConfigServiceWithChangeNotify extends DashboardWithDetailsConfigService {

	abstract notifyChangeToServer(panelName: string,
																data: { [p: string]: any },
																selection?: { [p: string]: any },
																subPanelName?: string,
																csHttpRequestOptions?: CsHttpRequestOptions): Observable<Result<ValidationResult[]>>;

}

export function isDashboardConfigServiceWithChangeNotify(x: DashboardLiteConfigService): x is DashboardConfigServiceWithChangeNotify {
	return (x as unknown as DashboardConfigServiceWithChangeNotify).notifyChangeToServer !== undefined;
}
