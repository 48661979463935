import { ArrayUtils, convertKeysToFnv32a, Dictionary }                                         from '@cs/core/utils';
import { DataLookup }                                                                          from '../structure/data-structure-lookup';
import { DataStructureRow }                                                                    from '../structure/data-structure-row';
import {
	DataDescribed,
	DataDescribedArray,
	DataDescribedGroup,
	getKeys,
	KeyValuePair,
	LayoutAnnotation
}                                                                                              from '../../data-described';
import {
	DataConverter,
	DATA_CONSTANTS
}                                                                                              from './data-converter-base.model';
import { DataStructure }                                                                       from '../structure/data-structure';
import { DataKey }                                                                             from './data-key.model';
import { DataStructureColumn, DataStructureGroup, DataStructureGroups, IDataConverterOptions } from '../structure';
import { HeaderDefinitionResult }                                                              from './header-definition-result';
import { DataFieldDefinition }                                                                 from './data-field-definition';

export abstract class DataDescribedConverter<T, TLayout = LayoutAnnotation<T>, TMetaData = any>
	extends DataConverter<DataDescribedArray<T, TLayout, TMetaData>> {

	protected convert(data: DataDescribedArray<T, TLayout, TMetaData>,
					  options: IDataConverterOptions): DataStructure {

		return super.convert(data instanceof DataDescribed
							 ? data
							 : new DataDescribed<T, TLayout, TMetaData, Array<T>>(data), options);
	}

	protected getData(data: DataDescribedArray<T, TLayout, TMetaData>, structure: Readonly<DataStructure>): Array<DataStructureRow> {
		const columnKeysLookup = this.getColumnKeyByDepth(structure, data);
		const rowKeyBase       = structure.groupOrderRows;

		return data.data.map(row => {
			const rowKeys = getKeys<T>(row, rowKeyBase);
			const rowKey  = convertKeysToFnv32a(rowKeys);
			const cells   = new Map<string, DataStructureColumn>();

			for (const field of data.dataAnnotation.fields) {
				// Default behaviour
				const key = field.id.toString();
				let value = null;


				value = row[key];


				const columnKeyLookupResult = columnKeysLookup.get(key);
				const cellKeys              = columnKeyLookupResult.key;

				cells.set(columnKeyLookupResult.structureKey, new DataStructureColumn({
																						  id:    columnKeyLookupResult.structureKey,
																						  value: value,
																						  keys:  cellKeys
																					  }));

			}

			return new DataStructureRow({columns: cells, id: rowKey, keys: rowKeys});
		});
	}

	protected getRowGroupOrder(data: DataDescribedArray<T, TLayout, TMetaData>): Array<string> {
		const groupOrder = [];

		if (data.dataAnnotation.dataGroups == null)
			return groupOrder;

		for (const group of data.dataAnnotation.dataGroups) {
			if (group.id != null) {
				groupOrder.push(group.id);
			}
		}

		return groupOrder;
	}

	protected getColumnGroupOrder(data: DataDescribedArray<T, TLayout, TMetaData>): Array<string> {
		const groupOrder = [];

		function getDepth(children: DataDescribedGroup[], index: number): number {
			const currentDepth = ++index;
			// loop over the first array
			for (const group of children) {

				if (group.children) {
					return getDepth(group.children, currentDepth);
				} else {
					return currentDepth;
				}
			}
		}

		if (data.dataAnnotation.groups && data.dataAnnotation.groups.length > 0) {
			const groups = data.dataAnnotation.groups;

			let deepest = 0;
			// loop over the first array
			for (const group of groups) {
				const depth = getDepth(group.children, 1);
				deepest     = depth > deepest
							  ? depth
							  : deepest;
			}

			for (let i = deepest; i > 0; i--) {
				groupOrder.push('IdGroup' + i);
			}
		}

		groupOrder.push('IdHeader');

		return groupOrder;
	}

	protected createHeaderDefinitions(data: DataDescribedArray<T, TLayout, TMetaData>,
									  structure: DataStructure): HeaderDefinitionResult {

		let fieldOrderCounter = 0;

		if (structure.options.useFieldAsValueAutoGroupColumn && structure.groupOrderRows.length > 0) {
			const fieldIdAutoGroupColumn = structure.options.fieldToUseAsValueAutoGroupColumn;
			if (!fieldIdAutoGroupColumn) {
				structure.fieldIdAutoGroupColumn = data.dataAnnotation.fields[structure.groupOrderRows.length].id.toString();
			}
		}

		const headerStructure: DataStructureGroups = {};

		const createGroupHeader = (id: string,
								   value: string,
								   label: string,
								   groupRoot: Dictionary,
								   parentPath: string[],
								   index: number): DataStructureGroup => {
			const result      = groupRoot;
			const headerGroup = new DataStructureGroup({
														   type:       'DataGridHeader',
														   levelKey:   id,
														   levelValue: value,
														   children:   {}
													   }).setIndex(index);

			result[convertKeysToFnv32a(headerGroup.key)] = headerGroup;

			// Adding the labels for the header groups to the lookups
			const lookup = structure.findOrCreateEmptyLookup(headerGroup.levelKey.toString());
			lookup.values.push({value: label, key: headerGroup.levelValue});

			return headerGroup;
		};

		function createHeaderStructureGroup(groupRow: DataDescribedGroup[], currentGroup: {}, children: Array<DataDescribedGroup>,
											depth: number) {
			const columnKey = structure.groupOrderColumns[depth];
			for (let index = 0; index < groupRow.length; index++) {
				const topitem   = groupRow[index];
				const fieldName = topitem.id;
				// const colKey    = {[columnKey]: fieldName};
				const path      = [];

				const currentHeaderGroup = createGroupHeader(columnKey, fieldName, topitem.label, currentGroup, path, index);


				let leftOverChildren = [];

				const foundChildren = topitem.children || [];

				if (foundChildren.length > 0)
					leftOverChildren = createHeaderStructureGroup(foundChildren, currentHeaderGroup.children, leftOverChildren, depth + 1);

				children = leftOverChildren;


			}
			return children;
		}

		if (data.dataAnnotation.groups) {
			const currentGroup                      = headerStructure;
			let children: Array<DataDescribedGroup> = [];
			const topItems                          = data.dataAnnotation.groups.filter(value => {
				const result = value.groupId == null;
				if (!result)
					children.push(value);

				return result;
			});

			children = createHeaderStructureGroup(topItems, currentGroup, children, 0);
		}


		DataStructureGroups.createParentReferences(headerStructure);

		const headerEndNodeLevelName = ArrayUtils.getLastElement(structure.groupOrderColumns);
		const structureGroups        = DataStructure.getHeadStructureLastNodes(headerStructure);

		const endNodeValues: Array<KeyValuePair<any, any>> = [];

		const fieldDefinitions = data.dataAnnotation.fields
									 .map(field => {

										 const columnKeyLookupResult = field.groupId
																	   ? structureGroups.find(
												 value => value.levelValue === field.groupId)
																	   : null;
										 const header                = new DataFieldDefinition({
																								   visible:    field.visible,
																								   levelKey:   headerEndNodeLevelName,
																								   levelValue: field.id.toString(),
																								   // TODO: Add to DataTypeLookup dataType:     field.type,
																								   label:             field.label,
																								   index:             field.order || fieldOrderCounter++,
																								   groupStructureKey: columnKeyLookupResult
																													  ? columnKeyLookupResult.structureKey
																													  : null
																							   });

										 endNodeValues.push({key: field.id.toString(), value: field.label});

										 // When there is a lookup add it to the DataGridStructure
										 if (field.lookup) {
											 const foundLookup = data.getLookup(field.lookup);

											 if (foundLookup == null)
												 throw new Error('No lookup found for ' + field.lookup);

											 const headerKeys = convertKeysToFnv32a(header.keys);

											 structure.lookups.set(headerKeys,
																   new DataLookup<unknown, unknown>({
																										id:     headerKeys,
																										values: foundLookup.values
																									}));
										 }

										 return header;
									 });

		// Add the header values to the lookup, this lookup is named the levelKey
		structure.lookups.set(headerEndNodeLevelName, new DataLookup<unknown, unknown>({
																						   id:     headerEndNodeLevelName,
																						   values: endNodeValues
																					   }));
// The headers containing view information
		const headerDefinitions = {
			[headerEndNodeLevelName]: fieldDefinitions
		};

		return {headerDefinitions: headerDefinitions, headerStructure: headerStructure};

	}

	//#region Data Format helpers
	private getColumnKeyByDepth(structure: Readonly<DataStructure>,
								data: Readonly<DataDescribedArray<T, TLayout, TMetaData>>) {


		const headerKeyBase = structure.groupOrderColumns;

		const columnKeysLookup                         = new Map<string, {
			key: DataKey,
			keyFnv: string,
			structureKey: string
		}>();
		// create groups, wrapping in a array is because of future multiple groups
		const groups: Array<Array<DataDescribedGroup>> = this.getFlattenChildrenByDepth(data.dataAnnotation.groups,
																						[], 0, null);


		for (const field of data.dataAnnotation.fields) {
			const keys = {};

			const depth = headerKeyBase.length - 1;

			const colGroupKey = headerKeyBase[depth];

			// assume the header is the root
			keys[colGroupKey] = field.id;

			const structureKey = convertKeysToFnv32a(keys);

			if (field.groupId) {
				const headerLevelGroups = structure.headerStructureLastNodes;
				const group             = headerLevelGroups.find(value => value.levelValue === field.id);
				Object.assign(keys, group.fullPathKey);
			}


			columnKeysLookup.set(field.id.toString(), {
				key:          keys, keyFnv: convertKeysToFnv32a(keys),
				structureKey: structureKey
			});
		}

		// add autogroup column key info
		const autoGroupColumnInfo = this.calculateAutoGroupColumnKeys(headerKeyBase);
		const rootAutoGroupColumn = ArrayUtils.getLastElement(autoGroupColumnInfo);

		columnKeysLookup.set(DATA_CONSTANTS.AUTO_GROUP_COLUMN_ID,
							 {
								 key:          rootAutoGroupColumn.keys,
								 keyFnv:       rootAutoGroupColumn.id,
								 structureKey: convertKeysToFnv32a(rootAutoGroupColumn.keys)
							 });

		return columnKeysLookup;
	}

	private getFlattenChildrenByDepth(items: DataDescribedGroup[],
									  root: DataDescribedGroup[][],
									  depth: number,
									  groupId: string) {

		const headers: DataDescribedGroup[] = [];

		for (const item of items) {
			if (item.children)
				this.getFlattenChildrenByDepth(item.children, root, depth + 1, item.id);

			item.groupId = groupId;
			headers.push(item);

		}
		// if already has data merge the array
		root[depth] = root[depth]
					  ? root[depth].concat(headers)
					  : headers;
		return root;
	}

//#endregion
}


