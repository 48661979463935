import { Injectable }         from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';
import { LoginUserProfile }   from '../models/login-user-profile';

export interface AuthenticationState {
  userProfile: LoginUserProfile;
  isImpersonated: boolean;
}

export function createInitialAuthenticationState(): AuthenticationState {
  return {
    userProfile:    null,
    isImpersonated: false
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'authentication'})
export class AuthenticationStore extends Store<AuthenticationState> {

  constructor() {
    super(createInitialAuthenticationState());
  }

}

