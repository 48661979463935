import { FormControl }        from '@angular/forms';
import { WidgetLayout }       from '@cs/core';
import { ValidationResult }   from '@cs/core';
import { PropertyAnnotation } from '@cs/core';
import { Directive }          from '@angular/core';

@Directive()
export abstract class WidgetBase<T extends PropertyAnnotation<any>> {

	/**
	 * Indicates that the widget shows a disabled version of the widget instead of a Label Widget
	 */
	static hasReadOnlyState = false;

	propertyAnnotation: T;
	control: FormControl;
	errorMessages: ValidationResult[] = [];
	layout: WidgetLayout;
	id                                = '';
	name                              = '';
	widgetType;
}
