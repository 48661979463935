import { getPropertyOf }     from '@cs/core/utils';
import { LayoutOrientation } from './layout-orientation.model';

export class WidgetCollection {
	/**
	 * Id of the @Link(FormFieldset) where the widget should render, if not provided it will be the first/default one
	 */
	fieldSetId?: string;
	/**
	 * Identification of the @link(Widget)
	 */
	id: string;
	/**
	 * Name to show in the UI, is used when the form has an @Link(LayoutOrientation) Horizontal
	 */
	label: string;
	/**
	 * Render direction of the form elements
	 */
	orientation = LayoutOrientation.Vertical;

	/**
	 * Flag to show the collections label
	 */
	showLabel = true;
	/**
	 * Flag for showing the group
	 */
	include: boolean;

	constructor(init: Partial<WidgetCollection>) {
		this.id          = getPropertyOf(init, 'id');
		this.label       = getPropertyOf(init, 'label');
		this.orientation = getPropertyOf(init, 'orientation', LayoutOrientation.Vertical);
		this.fieldSetId  = getPropertyOf(init, 'fieldSetId', 'default');
		this.showLabel   = getPropertyOf(init, 'showLabel', true);
		this.include     = getPropertyOf(init, 'include', true);
	}
}
