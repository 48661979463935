import { BaseInput } from './base-input';

export class TextboxWithToggleInput extends BaseInput {
  public toggleValue = false;
  public constructor(data: any) {
    super(data);
  }

  public onToggleChange(e) {
    this.data.enabled = this.toggleValue;
  }
}
