import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

/**
 * Avatar component.
 */
@Component({
  selector:        'cs-avatar',
  templateUrl:     './avatar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsAvatarComponent {
  private _fullName: string;
  initials = '';

  /**
   * user image url
   */
  @Input() imageUrl: string; // ngModel.

  /**
   * user full name
   */
  @Input() set fullName(fn: string) {
    this._fullName = fn;
    if (fn) {
      const letters: string[] = fn.trimLeft().trimRight().split(/\b\s+(?!$)/).map(value => value.trim()[0]);

      this.initials = letters.length > 2 ? letters.slice(0, 1).join('') : letters.join('');
    } else
      this.initials = '??';
  }

  get fullName() {
    return this._fullName;
  }

}
