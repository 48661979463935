import {
	AfterViewInit,
	Component,
	EventEmitter,
	Input,
	OnChanges,
	OnInit,
	Output,
	ViewChild
} from '@angular/core';

import { CsOption }     from './option.model';
import { CsOptionList } from './option-list';

@Component({
			   selector:    'cs-combobox-scroller',
			   templateUrl: './combobox-scroller.component.html'
		   })

export class CsComboboxScrollerComponent implements AfterViewInit, OnChanges, OnInit {
	@Input() filterEnabled: boolean;
	@Input() left: number;
	@Input() multiple: boolean;
	@Input() notFoundMsg: string;
	@Input() optionList: CsOptionList;
	@Input() top: number;
	@Input() width: number;

	@Output() close         = new EventEmitter<boolean>();
	@Output() optionClicked = new EventEmitter<CsOption>();
	@Output() filterClick   = new EventEmitter<null>();
	@Output() filterInput   = new EventEmitter<string>();
	@Output() filterKeydown = new EventEmitter<any>();

	@ViewChild('filterInput') filterInputElement: any;
	@ViewChild('optionsList') optionsListElement: any;
	@ViewChild('innerOptionsList') innerOptionsListElement: any;

	ngOnInit() {
		this.optionsReset();
	}

	ngOnChanges(changes: any) {
		if (changes.hasOwnProperty('optionList')) {
			this.optionsReset();
		}
	}

	ngAfterViewInit() {
		// this.moveHighlightedIntoView();
		if (this.filterEnabled)
			this.filterInputElement.nativeElement.focus();
	}

	onFilterClick(event: any) {
		event.stopImmediatePropagation();
		event.cancelBubble = true;
		// note: do NOT call preventDefault() because that will break the clear-field x (cross)

		this.filterClick.emit(null);
	}

	onFilterInput(event: any) {
		this.filterInput.emit(event.target.value);
	}

	onFilterKeydown(event: any) {
		event.cancelBubble = true;
		this.filterKeydown.emit(event);
	}

	onOptionsWheel(event: any) {
		this.handleOptionsWheel(event);
	}

	onOptionMouseover(option: CsOption) {
		this.optionList.highlightOption(option);
	}

	onOptionClick(option: CsOption, event: any) {
		event.stopImmediatePropagation();
		event.preventDefault();
		event.cancelBubble = true;
		this.optionClicked.emit(option);

	}

	/**
	 * Clears filter input
	 */
	clearFilterInput() {
		if (this.filterEnabled) {
			this.filterInputElement.nativeElement.value = '';
		}
	}

	/**
	 * Filter list with last known value. Call this after deselecting options.
	 */
	applyFilterInput() {
		if (this.filterEnabled)
			this.optionList.filter(this.filterInputElement.nativeElement.value);
	}

	moveHighlightedIntoView() {
		const list       = this.innerOptionsListElement.nativeElement;
		const listHeight = list.offsetHeight;

		const itemIndex = this.optionList.getHighlightedIndex();

		if (itemIndex > -1) {
			const item = list.children[itemIndex];

			// For some reason it is highlighted but not found in the items so just shortcut it
			if (item == null)
				return;

			const itemHeight = item.offsetHeight;

			const itemTop    = itemIndex * itemHeight;
			const itemBottom = itemTop + itemHeight;

			const viewTop    = list.scrollTop;
			const viewBottom = viewTop + listHeight;

			if (itemBottom > viewBottom) {
				list.scrollTop = itemBottom - listHeight;
			} else if (itemTop < viewTop) {
				list.scrollTop = itemTop;
			}
		}
	}


	/**
	 * Detects if the item is truncated and if so add an tooltip
	 */
	detectTruncatedField($event: MouseEvent, option: CsOption) {
		const element = $event.currentTarget as HTMLElement;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}

		option.isTruncated = isEllipsisActive(element);

	}

	private optionsReset() {
		this.optionList.resetFilter();
		this.optionList.highlight();
	}

	private handleOptionsWheel(e: any) {
		const div      = this.optionsListElement.nativeElement;
		const atTop    = div.scrollTop === 0;
		const atBottom = div.offsetHeight + div.scrollTop === div.scrollHeight;

		if (atTop && e.deltaY < 0) {
			e.preventDefault();
		} else if (atBottom && e.deltaY > 0) {
			e.preventDefault();
		}
	}
}
