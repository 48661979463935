import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule }                        from '@angular/common';

import { DashboardRoutingModule }                               from './dashboard-routing.module';
import { DashboardComponent }                                   from './dashboard.component';
import { CsApplicationSettings }                                from '@cs/common';
import { DashboardConfigService }                               from './dashboard-config.service';
import { IConfigService }                                       from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                  from '@cs/performance-manager/shared';
import { HttpClient }                                           from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService }   from '@ngx-translate/core';
import { LoggerUtil }                                           from '@cs/core/utils';
import { DashboardModule }                                      from '@cs/components/dashboard';
import { ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';
import { DashboardPopupModule }                                 from './dashboard-popup/dashboard-popup.module';
import { DialogBasicModule }                                    from '@cs/performance-manager/shared';

export function dashBoardTranslationLoader(client: HttpClient,
										   pmAppSettings: CsApplicationSettings,
										   service: IConfigService,
										   componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [DashboardComponent],
			  imports:      [
				  CommonModule,
				  DashboardModule,
				  DashboardPopupModule,
				  DialogBasicModule,
				  TranslateModule.forChild({
											   loader:  {
												   provide:    TranslateLoader,
												   useFactory: dashBoardTranslationLoader,
												   deps:       [HttpClient, CsApplicationSettings, DashboardConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  DashboardRoutingModule
			  ]
		  })
export class PmDashboardModule {
	static forRoot(moduleConfig: Type<PmDashboardModule>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<PmDashboardModule> {
		return {
			ngModule:  PmDashboardModule,
			providers: [
				{
					provide:  DashboardConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	static forChild(moduleConfig: Type<PmDashboardModule>,
					appSetting: CsApplicationSettings): ModuleWithProviders<PmDashboardModule> {
		return {
			ngModule:  PmDashboardModule,
			providers: [
				{
					provide:  DashboardConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use(culture.getCulture());
	}


}
