import { SortItemType } from '../enums/data-grid.enum';
import { isUndefined }  from '@cs/core';

export class DataGridSortItem {
  isDefaultSortOrder: boolean;
  sortBy: SortItemType;
  key: string;
  columnIndex: number;
  sortOrder: 'desc' | 'asc';

  constructor(initial?: Partial<DataGridSortItem>) {
    if (initial === null)
      this.setDefaults();
    else if (!isUndefined(initial))
      Object.assign(this, initial);
  }

  setDefaults() {
    this.isDefaultSortOrder = false;
    this.sortBy             = SortItemType.DisplayValue;
    this.columnIndex        = 0;
    this.sortOrder          = 'desc';
  }
}
