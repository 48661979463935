import { BranchDto, Commit, RepositoryDto } from '@cs/performance-manager/git-graph';
import { Observable }                       from 'rxjs';

import { Result }               from '@cs/core/generate';
import { CsHttpRequestOptions } from '@cs/core/http';

import { IConfigService } from '@cs/performance-manager/shared';

export abstract class SubmoduleUpdateDialogConfigService implements IConfigService {

	moduleName: string = 'submodule-update-dialog';

	public abstract GetRepositoryBranches(dto: BranchRequestDto, options?: CsHttpRequestOptions): Observable<Result<BranchDto[]>>;

	public abstract CheckoutSubmodule(repositoryIdentifier: string, repositoryReference: string, submoduleIdentifier: string, submoduleReference: string, options?: CsHttpRequestOptions);

	public abstract CommitFiles(repositoryIdentifier: string, commitMessage: string, options?: CsHttpRequestOptions);

	public abstract UnPushedCommits(repositoryIdentifier: string, hashOrBranch: string, options?: CsHttpRequestOptions): Observable<Result<Commit[]>>;

	public abstract RemoveUnpushedCommits(repositoryIdentifier: string, oldReference: string, options?: CsHttpRequestOptions): Observable<Result<string>>;

	public abstract PushRepository(repositoryIdentifier: string, repositoryReference: string, options?: CsHttpRequestOptions);

	public abstract ReadRepositories(repositoryIdentifiers: Array<string>, options?: CsHttpRequestOptions): Observable<Result<RepositoryDto[]>>;
}


export interface BranchRequestDto {
	repositoryIdentifier: string;
	commitHash: string;
	skipCommitDifference: boolean;
}
