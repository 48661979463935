import { BaseNode }               from './base-node';
import { PropertyTypeNode }       from './property-type-node';
import { PropertyDataNode }       from './property-data-node';
import { OpeningHoursInput }      from '../input-types/opening-hours-input';
import { CheckboxInput }          from '../input-types/checkbox-input';
import { EnumFreeTextInput }      from '../input-types/enum-free-text-input';
import { MapsInput }              from '../input-types/maps-input';
import { YesNoInput }             from '../input-types/yes-no-input';
import { IntegerInput }           from '../input-types/integer-input';
import { IntegerWithEnumInput }   from '../input-types/integer-with-enum-input';
import { DecimalInput }           from '../input-types/decimal-input';
import { DecimalWithEnumInput }   from '../input-types/decimal-with-enum-input';
import { EnumInput }              from '../input-types/enum-input';
import { LongTextInput }          from '../input-types/long-text-input';
import { TextboxInput }           from '../input-types/textbox-input';
import { TextboxWithToggleInput } from '../input-types/textbox-with-toggle-input';
import { TextboxEmailInput }      from '../input-types/textbox-email-input';
import { TextboxUrlInput }        from '../input-types/textbox-url-input';
import { DateInput }              from '../input-types/date-input';
import { MonthInput }             from '../input-types/month-input';
import { QuarterInput }           from '../input-types/quarter-input';
import { YearInput }              from '../input-types/year-input';
import { TimezoneInput }          from '../input-types/timezone-input';
import { FileInput }              from '../input-types/file-input';

export class PropertyNode extends BaseNode {
  public nodeType = 'PropertyNode';
  public key: string;
  public displayFormat: string;
  public displayFormatNull: string;
  public validationRegex: string;
  public maxLength: string;
  public config: any;
  public propertyType: PropertyTypeNode;
  public data: PropertyDataNode;
  public value: string | number | boolean;
  public displayValue: string;
  public input;

  public constructor(data) {
    super(data);
    this.key               = data.key;
    this.displayFormat     = data.displayFormat;
    this.displayFormatNull = data.displayFormatNull;
    this.validationRegex   = data.validationRegex;
    this.maxLength         = data.maxLength;
    this.config            = data.config;
    this.propertyType      = new PropertyTypeNode(data.propertyType);

    this.setData(new PropertyDataNode(data.data));

    this.value        = this.getValue();
    this.displayValue = this.getDisplayValue();
    this.input        = this.createInput();
  }

  private createInput() {
    const inputData = {
      node:        this,
      controlName: ''
    };

    switch (this.propertyType.name) {
      case 'OpeningHours':
        inputData.controlName = 'OpeningHoursInput';
        return new OpeningHoursInput(inputData);
      case 'GeoLatLong':
        inputData.controlName = 'MapsInput';
        return new MapsInput(inputData);
      case 'Bit':
        inputData.controlName = 'YesNoInput';
        return new YesNoInput(inputData);
      case 'BitAvailable':
        inputData.controlName = 'CheckboxInput';
        return new CheckboxInput(inputData);
      case 'Int':
        inputData.controlName = 'IntegerInput';
        return new IntegerInput(inputData);
      case 'IntWithEnum':
        inputData.controlName = 'IntegerWithEnumInput';
        return new IntegerWithEnumInput(inputData);
      case 'Decimal':
        inputData.controlName = 'DecimalInput';
        return new DecimalInput(inputData);
      case 'DecimalWithEnum':
        inputData.controlName = 'DecimalWithEnumInput';
        return new DecimalWithEnumInput(inputData);
      case 'Enum':
        inputData.controlName = 'EnumInput';
        return new EnumInput(inputData);
      case 'EnumFreeText':
        inputData.controlName = 'EnumFreeTextInput';
        return new EnumFreeTextInput(inputData);
      case 'LongText':
        inputData.controlName = 'LongTextInput';
        return new LongTextInput(inputData);
      case 'ShortText':
        inputData.controlName = 'TextboxInput';
        return new TextboxInput(inputData);
      case 'ShortTextToggle':
        inputData.controlName = 'TextboxWithToggleInput';
        return new TextboxWithToggleInput(inputData);
      case 'TextEmail':
        inputData.controlName = 'TextboxEmailInput';
        return new TextboxEmailInput(inputData);
      case 'TextUrl':
        inputData.controlName = 'TextboxUrlInput';
        return new TextboxUrlInput(inputData);
      case 'DateDay':
        inputData.controlName = 'DateInput';
        return new DateInput(inputData);
      case 'DateMonth':
        inputData.controlName = 'MonthInput';
        return new MonthInput(inputData);
      case 'DateQuarter':
        inputData.controlName = 'QuarterInput';
        return new QuarterInput(inputData);
      case 'DateYear':
        inputData.controlName = 'YearInput';
        return new YearInput(inputData);
      case 'TimeZone':
        inputData.controlName = 'TimezoneInput';
        return new TimezoneInput(inputData);
      case 'File':
        inputData.controlName = 'FileInput';
        return new FileInput(inputData);
      default:
        inputData.controlName = 'TextboxInput';
        return new TextboxInput(inputData);
    }
  }

  public setData(data: PropertyDataNode) {
    this.data         = data;
    this.value        = this.getValue();
    this.displayValue = this.getDisplayValue();
  }

  public setValue(value) {
    if (this.propertyType.useIntValue) {
      this.data.intValue = value;
    }

    if (this.propertyType.useDecimalValue) {
      this.data.decimalValue = value;
    }

    if (this.propertyType.useDataValue) {
      this.data.dataValue = value;
    }

    if (this.propertyType.useToggle) {
      this.data.enabled = value;
    }

    this.value        = value;
    this.displayValue = this.getDisplayValue();
  }

  private getValue(): string | number | boolean {
    let value = null;
    if (this.propertyType.useIntValue) {
      value = this.data.intValue;
    }

    if (this.propertyType.useDecimalValue) {
      value = this.data.decimalValue;
    }

    if (this.propertyType.useDataValue) {
      value = this.data.dataValue;
    }

    if (this.propertyType.useToggle) {
      value = this.data.enabled;
    }

    if (this.propertyType.useTextValue) {
      value = this.data.textValue;
    }

    return value;
  }

  private getDisplayValue(): string {
    let displayValue = this.getValue();

    if (this.propertyType.useToggle) {
      displayValue = this.data.enabled === true ? 'Yes' : 'No';
    }

    if (displayValue.toString().length === 0) {
      return this.displayFormatNull;
    }

    return this.displayFormat.replace('{Value}', displayValue.toString());
  }

  public getConfig(key: string = null) {
    if (key == null && this.config) {
      return this.config;
    }

    if (key != null && this.config) {
      return this.config[key];
    }

    return;
  }

  public processChildren(children) {
  }
}
