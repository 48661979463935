import { OnInit, ViewContainerRef } from '@angular/core';
import {
  Component, Input
}                                   from '@angular/core';

@Component({
  selector: 'cs-columnHeaderTemplateLoader',
  template: ``
})
export class ColumnHeaderTemplateLoader implements OnInit {

  @Input() column: any;

  constructor(public viewContainer: ViewContainerRef) {
  }

  ngOnInit() {
    const view = this.viewContainer.createEmbeddedView(this.column.headerTemplate, {
      '\$implicit': this.column
    });
  }
}

