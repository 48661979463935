<div class="pm-dashboard-container popup"
	 [class.single-panel-dashboard]="data?.panels?.length === 1"
	 [class.single-panel-dashboard--has-subfilter-items]="mainbarDataSource?.subFilterItems?.length > 0"
	 [class.single-panel-dashboard--has-last-refresh]="data?.latestRefresh != null"
>
	<div class="full-width flex flex-row mb-3"
		 *ngIf="data?.panels?.length>1">

		<div class="flex-grow-1" *ngIf="mainbarDataSource?.subFilterItems?.length > 0">
			<div class="badge badge-secondary flex flex-row justify-content-between align-items-center mb-0"
				 *ngFor="let filter of mainbarDataSource?.subFilterItems">
				<label class="mr-1 ml-1">{{filter.label}}</label>
				<!--<i class="mdi mdi-close-circle" *ngIf="filter.isDismissible"></i>-->
			</div>
		</div>

		<div class="flex-pull-right last-refresh-align mr-2" *ngIf="data?.latestRefresh != null">
			<small><span class="f-body-s text-muted mr-2">{{'LAST_REFRESH' | translate}}</span>
				<span class="f-body-s font-weight-bold">{{data?.latestRefresh  | format:'{0:dd MMM yyyy H:mm}:iddate'}}</span>
			</small>
		</div>
		<div class="flex-pull-right" matTooltip="{{'CLOSE' | translate}}">
			<i (click)="close()" class="mdi mdi-close-circle hover-pointer mat-anim-cubic"></i>
		</div>
	</div>
	<div class="full-height full-width"
		 [csLoader]="isLoadingPage"
		 [injectIntoElement]="'.dashboard_main'"
		 [removeLoaderBody]="true"
		 [loaderClass]="'loader-lg'"
		 [loaderContainerClasses]="['loader-container', 'fade']">
		<cs-dashboard [data]="data"
					  #dashboard
					  class="flex flex-grow-1 full-height"
					  [contextObject]="popupData.filterData"
					  (downloadButtonClicked)="onDownloadButtonClicked($event)"
					  (dashboardEntityClicked)="onDashboardEntryClick($event)"
					  (panelOptionSelected)="onPanelOptionSelected($event)"
					  (notifyChangesToServer)="onNotifyChangesToServer($event)"
					  (applicationTriggerRequested)="onApplicationTriggerRequested($event)"
					  (showDetailsButtonClicked)="onDashboardEntryClick($event)">
			<ng-container contentTop *ngIf="data?.panels?.length === 1">
				<div class="full-width flex flex-row panel-content__top-bar" >

					<div class="flex-grow-1 panel-content__top-bar__subfilters"
						 #panelContentTopBar
						 *ngIf="mainbarDataSource?.subFilterItems?.length > 0">
						<div class="badge badge-secondary flex flex-row justify-content-between align-items-center mb-0"
							 *ngFor="let filter of mainbarDataSource?.subFilterItems">
							<label class="mr-1 ml-1">{{filter.label}}</label>
							<!--<i class="mdi mdi-close-circle" *ngIf="filter.isDismissible"></i>-->
						</div>
						<div class="badge badge-secondary flex flex-row justify-content-between align-items-center mb-0"
							 *ngIf="hiddenBadges?.length > 0"
							 [matTooltip]="hiddenBadgesText"
						>
							<label class="mr-1 ml-1">{{hiddenBadges?.length}}+</label>
							<!--<i class="mdi mdi-close-circle" *ngIf="filter.isDismissible"></i>-->
						</div>
					</div>

					<div class="flex-pull-right last-refresh-align" *ngIf="data?.latestRefresh != null">
						<small><span class="f-body-s text-muted mr-2">{{'LAST_REFRESH' | translate}}</span>
							<span class="f-body-s font-weight-bold">{{data?.latestRefresh  | format:'{0:dd MMM yyyy H:mm}:iddate'}}</span>
						</small>
					</div>
				</div>
			</ng-container>
			<ng-container headerRight *ngIf="data?.panels?.length === 1">
				<div class="flex flex-align-items-center ml-2" matTooltip="{{'CLOSE' | translate}}">
					<i (click)="close()"
					   class="mdi mdi-close-circle hover-pointer mat-anim-cubic panel-info-icon mdi-18px"></i>
				</div>
			</ng-container>
		</cs-dashboard>
	</div>
</div>
