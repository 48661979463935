import { animate, state, style, transition, trigger } from '@angular/animations';

export function collapse(triggerName: string) {
	return trigger(triggerName, [
		state('false', style({height: '*', opacity: 1})),
		state('true', style({height: 0, opacity: 0, padding: 0, pointerEvents: 'none', minHeight: 0})),
		transition('true <=> false', [
			animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)')])
	]);

}
