import {
	ChangeDetectorRef,
	ComponentFactoryResolver,
	ComponentRef,
	Directive,
	EventEmitter, Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	ViewContainerRef
}                                         from '@angular/core';
import { PortalInjector }                 from '@angular/cdk/portal';
import { ComponentChanges, whenChanging } from '@cs/core';


export class ComponentLoaderDataContext<T = any, TParentContext = any> {
	data: T;
	parentContext?: TParentContext;

	constructor(param: { data: T; parentContext?: TParentContext }) {
		this.data          = param.data;
		this.parentContext = param.parentContext;
	}

}

@Directive({
						 selector: '[csComponentLoader]'
					 })
export class CsComponentLoaderDirective<TData, TParentContext> implements OnChanges,
																																					OnInit,
																																					OnDestroy {

	@Input('csComponentLoader') component: any;
	@Input() componentData: TData;
	@Input() parentContext: TParentContext;
	// @Input() componentPanel: any;
	// @Input() componentHeight: string;

	@Output() componentInstantiated = new EventEmitter<any>();

	constructor(private cdr: ChangeDetectorRef,
							private container: ViewContainerRef,
							private resolver: ComponentFactoryResolver,
							private injector: Injector) {
	}

	ngOnInit() {

	}

	ngOnChanges(changes: ComponentChanges<CsComponentLoaderDirective<TData, TParentContext>>) {
		whenChanging(changes.component, true)
			.execute(value => {
				this.loadNewComponent();
			});

	}

	ngOnDestroy(): void {
		if (this.ref) {
			this.ref.destroy();
		}
	}

	private ref: ComponentRef<any>;

	private loadNewComponent() {
		this.container.clear();
		const componentFactory = this.resolver.resolveComponentFactory(this.component);
		const tokens           = new WeakMap(
			[
				[
					ComponentLoaderDataContext, new ComponentLoaderDataContext({
																																			 data:          this.componentData,
																																			 parentContext: this.parentContext
																																		 })
				]
			]);

		this.ref = this.container.createComponent(componentFactory, 0, new PortalInjector(this.injector, tokens));
		this.componentInstantiated.emit(this.ref.instance);
		this.cdr.detectChanges();
	}

}
