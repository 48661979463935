<div class="wizard-step-icon-container"
	 [class.is-active]="state === 'isActive'"
	 [class.is-disabled]="state === 'isDisabled'"
	 [class.is-checked]="state === 'isChecked'"
	 [class.is-warning]="state === 'isWarning'"
	 [class.is-error]="state === 'isError'"
>
	<div class="wizard-step-icon--container">

		<ng-container *ngIf="state === 'isChecked'">
			<i class="{{'check-circle-outline' | mdiIcon:'lg'}}"></i>
		</ng-container>
		<ng-container *ngIf="state === 'isWarning'">
			<i class="{{'alert-circle-outline' | mdiIcon:'lg'}}"></i>
		</ng-container>
		<ng-container *ngIf="state === 'isError'">
			<i class="{{'alert-outline' | mdiIcon:'lg'}}"></i>
		</ng-container>
		<ng-container *ngIf="state === 'isActive' || state === 'isDisabled'">
			<i class="{{icon | mdiIcon:'lg'}}"></i>
		</ng-container>

		<div class="wizard-step-icon__halo"></div>
	</div>
	<div class="wizard-step-icon--connector" *ngIf="showConnector"></div>
</div>
