import { FormSettings }                 from './form-settings.model';
import { FormAnnotation }               from './form-annotation.model';
import { FormLayoutFieldSets }          from './form-layout-field-sets.model';
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';


export class FormLayout<T> {
	/**
	 * Identification of the form
	 */
	id: string;
	/**
	 * Name to show in the UI
	 */
	label: string;
	/**
	 * Layout settings for the form
	 */
	layout: FormSettings;

	fieldSets: FormLayoutFieldSets<T>[] = [];

	constructor(init: Partial<FormAnnotation<T>>) {
		this.id     = getPropertyOf(init, 'id');
		this.label  = getPropertyOf(init, 'label');
		this.layout = hasPropertyOf(init, 'layout') ? new FormSettings(init.layout) : new FormSettings({});
	}
}
