import { BaseNode }  from './base-node';
import { GroupNode } from './group-node';

export class SectionNode extends BaseNode {
  public nodeType = 'SectionNode';

  public constructor(data) {
    super(data, 'groups');
  }

  public processChildren(children) {
    for (let child of children) {
      this.children.push(this.createChild(GroupNode, child));
    }
  }
}
