/**
	* Created by alex on 28-6-2017.
	*/

export enum GridType {
	Add  = 'Add',
	Fill = 'Fill'
}

export enum GridItemType {
	Sheet       = 'Sheet',
	Group       = 'Group',
	Column      = 'Column',
	Row         = 'Row',
	Cell        = 'Cell',
	ColumnGroup = 'ColumnGroup',
}

export enum GridActions {
	SetDataGridSortItem   = 'SetDataGridSortItem',
	SetCalculationOptions = 'SetCalculationOptions',
	SetUiState            = 'SetUiState',
	SetFormatting         = 'SetFormatting',
	SetLookup             = 'SetLookup',
	ChangeCss             = 'ChangeCss',
	RemoveCss             = 'RemoveCss',
	SetState              = 'SetState',
	SetSheetSettings      = 'SetSheetSettings',
	AddValidator          = 'AddValidator',
	TextChange            = 'TextChange',
	SetBehavior           = 'SetBehavior',
	SetVirtualKeys        = 'SetVirtualKeys',
	SetCellTooltip        = 'SetCellTooltip'
}

export enum UiTypes {
	Spinner           = 'Spinner',
	Checkbox          = 'Checkbox',
	DataGrid          = 'DataGrid',
	Input             = 'Input',
	Select            = 'Select',
	DatePicker        = 'DatePicker',
	RowMenu           = 'RowMenu',
	CellCheckbox      = 'CellCheckbox',
	CellCheckboxLabel = 'CellCheckboxLabel',
}

export enum DataGridCellType {
	RowMenu           = 'RowMenu',
	Checkbox          = 'Checkbox',
	All               = 'All',
	Offset            = 'Offset',
	Injected          = 'Injected',
	Label             = 'Label',
	Total             = 'Total',
	Data              = 'Data',
	CellCheckbox      = 'CellCheckbox',
	CellCheckboxLabel = 'CellCheckboxLabel',
	None              = 'None'
}

export enum CellClickedType {
	Edit       = 'Edit',
	None       = 'None',
	SliderEdit = 'SliderEdit'
}

export enum PopoverTypes {
	ForecastDetail        = 'ForecastDetail',
	None                  = 'None',
	DataSource            = 'DataSource',
	BUPSplit              = 'BUPSplit',
	CompareWithDatasource = 'CompareWithDatasource',
	DataSourceHTML        = 'DataSourceHtml',
}

export enum CompareRowState {
	NotSet   = 'NotSet',
	NotFound = 'NotFound',
	Found    = 'Found'
}

export enum RowState {
	GroupTotal = 'GroupTotal',
	Spinner    = 'Spinner',
	All        = 'All',
	Total      = 'Total',
	New        = 'New',
	Default    = 'Default',
	Expanded   = 'Expanded'
}

export enum SheetType {
	Default = 'Default',
	Compare = 'Compare'
}

export enum SortOrder {
	desc = -1,
	asc  = 1
}

export enum SortItemType {
	CellKey      = 'CellKey',
	DisplayValue = 'DisplayValue',
	Label        = 'Label',
	LabelMin     = 'LabelMin',
	SortIndex    = 'SortIndex'
}
