<div class="quick_links">

  <ul>
    <li *ngFor="let link of data.links">
      <a  (click)="linkClicked(link)">
        <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
             xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="Overview-pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="Group-4-Copy"
               transform="translate(8.242641, 9.242641) rotate(-135.000000) translate(-8.242641, -9.242641) translate(5.242641, 6.242641)"
               fill="#B2BFC5">
              <rect id="Rectangle-5" x="0" y="-5.68434189e-14" width="2" height="6"></rect>
              <rect id="Rectangle-5" x="0" y="4" width="6" height="2"></rect>
            </g>
          </g>
        </svg>{{link.label}}</a>
    </li>
  </ul>
</div>
