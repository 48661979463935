import { NgModule }                         from '@angular/core';
import { CsFormGeneratorModule }            from '@cs/components/form-generator';
import { CsSharedModule }                   from '@cs/components/shared';
import { DataGridAddMemberDialogComponent } from './data-grid-add-member-dialog.component';
import { MatDialogModule }                  from '@angular/material/dialog';
import { CommonModule }                     from '@angular/common';

@NgModule({
			  imports:         [CommonModule,
								CsFormGeneratorModule,
								MatDialogModule, CsSharedModule],
			  exports:         [DataGridAddMemberDialogComponent],
			  declarations:    [DataGridAddMemberDialogComponent],
			  entryComponents: [DataGridAddMemberDialogComponent]
		  })
export class DataGridAddMemberDialogModule {
}
