<nav class="navbar navbar-toggleable">
	<div class="navbar-header-logo-container" [class.m-0]="moduleDropdownsDisplay?.length > 0">
		<div class="navbar-header-logo cs-pointer" (click)="sectionClicked(homeItem)" [ngStyle]="logoStyling"></div>
	</div>
	<!-- Row of modules  -->
	<ng-container *ngIf="modules?.length <= nrOfVisibleModules">
		<button *ngFor="let module of modules"
				[class.btn-secondary]="activeModule?.name === module.name"
				[class.btn-quaternary]="activeModule?.name !== module.name"
				(click)="moduleClicked(module)"
				class="btn hide-in-print btn-md navbar-btn-styling-special moduleBarButton unset-content">
			<span>{{module?.label}}</span>
		</button>
	</ng-container>
	<!-- Menu  -->
	<ng-container *ngIf="modules?.length > nrOfVisibleModules">
		<button [matMenuTriggerFor]="menu"
				[class.btn-secondary]="activeModule?.active"
				[class.btn-quaternary]="!activeModule?.active"
				class="btn btn-md hide-in-print navbar-btn-styling-special moduleBarButton unset-content">
			<span>{{activeModule?.label}}</span><i class="ml-1 mdi mdi-menu-down"></i>
		</button>
		<mat-menu #menu="matMenu">
			<button mat-menu-item
					*ngFor="let module of modules"
					[disabled]="module.disabled"
					(click)="moduleClicked(module)">
				{{module.label}} - {{module.fullLabel}}</button>
		</mat-menu>
	</ng-container>

	<!-- CUSTOMERS --->
	<ng-container *ngIf="moduleDropdownsDisplay?.length > 0">
		<ng-container *ngFor="let dropdown of moduleDropdownsDisplay">
			<ng-container *ngIf="dropdown?.values?.length > 0 && dropdown?.values[0].data?.length > 0">
				<cs-advanced-dropdown class="hide-in-print"
									  *ngIf="dropdown?.values[0].data?.length === 1 && logo === null || dropdown?.values[0].data?.length > 1"
									  [selected]="dropdown.identifier"
									  [dataSource]="dropdown"
									  [isSmall]="true"
									  (selectionChanged)="onSelectionChanged($event, dropdown)"
									  [selectMultiple]="dropdown.isMultiSelect">
				</cs-advanced-dropdown>
			</ng-container>
		</ng-container>
	</ng-container>

	<!-- SECTIONS --->
	<ul class="navbar-nav flex-pull-right-force flex flex-row hide-in-print">
		<ng-container *ngFor="let navItem of sections | sortBy: 'asc': 'sortIndex'">
			<ng-container *ngIf="navItem.values.length === 0">
				<li class="nav-item"
					[class.disabled]="navItem.disabled"
					[matTooltip]="navItem.fullLabel"
					[class.active]="navItem.active">
					<a class="nav-link "
					   (click)="sectionClicked(navItem)">
						<i *ngIf="navItem.icon" class="{{navItem.icon}} mr-2"></i>{{navItem.label}}</a>
				</li>
			</ng-container>
			<ng-container *ngIf="navItem.values.length > 0 ">
				<li class="nav-item"
					[matTooltip]="navItem.fullLabel"
					[class.active]="navItem.hasActiveChildren"
					[matMenuTriggerFor]="menuInner">
					<a class="nav-link dropdown-toggle">
						<i *ngIf="navItem.hasActiveChildren?navItem.activeIcon: navItem.icon"
						   class="{{navItem.hasActiveChildren?navItem.activeIcon: navItem.icon}} mr-2"></i> {{navItem.hasActiveChildren ? navItem.activeLabel : navItem.label}}
					</a>
				</li>
				<mat-menu #menuInner="matMenu">
					<button mat-menu-item
							*ngFor="let childNavitem of navItem.values | sortBy: 'asc': 'sortIndex'"
							[matTooltip]="childNavitem.fullLabel"
							[disabled]="childNavitem.disabled"
							matTooltipPosition="left"
							(click)="sectionClicked(childNavitem, navItem)">
						<i *ngIf="navItem.icon" class="{{childNavitem.icon}} mr-2"></i>
						{{childNavitem.label}}
					</button>
				</mat-menu>
			</ng-container>
		</ng-container>
	</ul>
	<div class="loading-avatar-container hide-in-print ">
		<div class="loader-navbar-container">
			<!-- TODO: add hidden when done-->
			<div class="loader-navbar-wrapper" [class.done]="!isLoading" [class.show]="isLoading">
				<div
						class="loader ">
				</div>
			</div>

		</div>

		<div class="navbar-header-avatar-container"
			 [class.active]="hasActiveChildren(avatarMenu)">
			<a *ngIf="isImpersonated"
			   class="is-impersonated-icon">
				<i class="mdi mdi-fingerprint mdi-24px"
				   matTooltip="{{'IS_IMPERSONATED' | translate}}"></i>
			</a>
			<a class="nav-link avatar-menu unset-content hover-pointer"
			   [matMenuTriggerFor]="menuAvatar">
				<cs-avatar matTooltip="{{userFullName}}"
						   [fullName]="userFullName"
						   class="cs-avatar-img"></cs-avatar>
				<i class="icon icon-arrow" *ngIf="avatarMenu?.length > 0"></i>
			</a>
			<mat-menu #menuAvatar="matMenu">
				<ng-container *ngFor="let item of avatarMenu | sortBy: 'asc': 'sortIndex'">
					<ng-container *ngIf="item.values.length === 0">
						<button mat-menu-item
								[disabled]="item.disabled"
								(click)="sectionClicked(item)"
								[matTooltip]="item.fullLabel"
								*ngIf="item.show | async"
								class="pm-navbar__dropdown-item"
								matTooltipPosition="left">
							<div>
								<i *ngIf="item.icon" class="pm-navbar__icon {{item.icon}}"></i>
							</div>
							{{item.label }}
						</button>
					</ng-container>
					<ng-container *ngIf="item.values.length > 0">
						<button mat-menu-item
								*ngIf="item.show | async"
								class="pm-navbar__dropdown-item"
								[matMenuTriggerFor]="menuInner">
							<div><i *ngIf="item.icon" class="pm-navbar__icon {{item.icon}}"></i></div>
							{{item.label }}
						</button>
						<mat-menu #menuInner="matMenu">
							<ng-container *ngFor="let child of item.values | sortBy: 'asc': 'sortIndex'">
								<button mat-menu-item
										[disabled]="child.disabled"
										(click)="sectionClicked(child)"
										[matTooltip]="child.fullLabel"
										*ngIf="child.show | async"
										class="pm-navbar__dropdown-item"
										matTooltipPosition="left">
									<div><i *ngIf="child.icon" class="pm-navbar__icon {{child.icon}}"></i></div>
									{{child.label }}
								</button>
							</ng-container>
						</mat-menu>
					</ng-container>
				</ng-container>

			</mat-menu>
		</div>
	</div>
</nav>

