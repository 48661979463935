import { GridGroup }   from '../models/grid-group.model';
import { RowButton }   from '../classes/row-button';
import { GridDataRow } from '../models';
import { GridSheet }   from '../models/grid-sheet.model';

/**
 * Created by alex on 1-8-2017.
 */

export class RowClickEventArgs {


  constructor(public row: GridDataRow, public sheet: GridSheet) {

  }
}

export class RowButtonClickEventArgs extends RowClickEventArgs {

  constructor(public row: GridDataRow, public group: GridGroup, public sheet: GridSheet, public button: RowButton) {
    super(row, sheet);
  }
}

export class ExpansionCollapsedEventArgs extends RowClickEventArgs {
  constructor(public row: GridDataRow, public sheet: GridSheet) {
    super(row, sheet);
  }
}
