import { Injectable }              from '@angular/core';
import { Store, StoreConfig }      from '@datorama/akita';
import { CsButtonBarInputOptions } from '../cs-button-bar.models';
import { IValidationAnnotation }   from '@cs/core';

export interface BottomBarState {
  isOpen: boolean;
  dataIsValid: boolean;
  useEnterToSave: boolean;
  unsavedChanges: number;
  isRowLabel: boolean;
  inputOptions: null | CsButtonBarInputOptions;
  inputValidators: IValidationAnnotation[];
  inputValue: undefined | string;
  inputRequired: boolean;
}

export function createBottomBarInitialState(): BottomBarState {
  return {
    isOpen:         false,
    dataIsValid:    true,
    useEnterToSave: true,
    unsavedChanges: 0,
    isRowLabel:     false,
    inputOptions:   null,
    inputValue:     null,
    inputValidators: [],
    inputRequired:  false // TODO use inputoptions.validators
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'bottom-bar'})
export class BottomBarStore extends Store<BottomBarState> {

  constructor() {
    super(createBottomBarInitialState());
  }

}

