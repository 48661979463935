/**
 * Class to manipulate colors
 */
export class Color {
	red: number;
	green: number;
	blue: number;
	alpha: number;
	hex: string;

	constructor(red, green, blue, alpha) {
		this.red   = red;
		this.green = green;
		this.blue  = blue;
		this.alpha = alpha;
		this.hex   = this.getHexValue(red) + this.getHexValue(green) + this.getHexValue(blue);
	}

	/**
	 * Obtains the hex value of a color
	 * @returns hex value of color
	 */
	private getHexValue(value): string {
		let result = value.toString(16);
		if (result.length < 2) {
			result = '0' + result;
		}
		return result;
	}
}

/**
 * Covert color based on the alpha and it's background
 */
export function colorConverter(color, bgColor): Color {
	return new Color(
		getTintValue(color.red, bgColor.red, color.alpha),
		getTintValue(color.green, bgColor.green, color.alpha),
		getTintValue(color.blue, bgColor.blue, color.alpha),
		color.alpha
	);
}

/**
 * Get tint of color
 */
export function getTintValue(tint, bgTint, alpha): number {
	const tmp = Math.floor((1 - alpha) * bgTint + alpha * tint);
	if (tmp > 255) {
		return 255;
	}
	return tmp;
}

/**
 * Creates Color based on type
 */
export function colorStringParser(type, color): Color {
	switch (type.toLowerCase()) {
		case 'rgba':
			color = color.replace('rgba(', '')
									 .replace(')', '')
									 .replace(' ', '');

			// Split the rgba string into an array.
			var splittedRgba = color.split(',');

			return new Color(
				parseInt(splittedRgba[0], 10),
				parseInt(splittedRgba[1], 10),
				parseInt(splittedRgba[2], 10),
				parseFloat(splittedRgba[3]) || 1
			);

		case 'hex':
			color        = color.replace('#', '');
			const rgbArr = [];

			// String the "#" off the hex-string.
			color = color.replace('#', '');

			// Convert pairs of hex-characters into decimal numbers.
			for (var i = 0; i < color.length; i++) {
				rgbArr.push(parseInt(getHexPartByIndex(i, color), 16));
			}
			return new Color(rgbArr[0], rgbArr[1], rgbArr[2], 1);

		case 'hsla':
			color = color.replace('hsla(', '')
									 .replace('hsl(', '')
									 .replace(')', '')
									 .replace(' ', '');
			// Split the hsla string into an array.
			color = color.split(',');

			const h = parseInt(color[0], 10) / 360;
			const s = parseInt(color[1], 10) / 100;
			const l = parseInt(color[2], 10) / 100;
			const a = parseFloat(color[3]) || 1;

			let r, g, b;

			if (s === 0) {
				r = g = b = l;
			} else {
				const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
				const p = 2 * l - q;
				r       = hue2rgb(p, q, h + 1 / 3);
				g       = hue2rgb(p, q, h);
				b       = hue2rgb(p, q, h - 1 / 3);
			}

			return new Color(Math.round(r * 255), Math.round(g * 255), Math.round(b * 255), a);
	}
}

/**
 * Get hexadecimal number portion
 * @returns number
 */
export function getHexPartByIndex(index, color): string {
	switch (color.length) {
		case 3:
			return color[index] + color[index];
		default:
			index *= 2;
			return color[index] + color[index + 1];
	}
}

/**
 * Covert color tone
 * @returns number
 */
export function hue2rgb(p: number, q: number, t: number): number {
	if (t < 0) t += 1;
	if (t > 1) t -= 1;
	if (t < 1 / 6) return p + (q - p) * 6 * t;
	if (t < 1 / 2) return q;
	if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
	return p;
}

/**
 * Get type of color
 * @returns type
 */
export function getColorType(colorString: string): string {
	if (colorString.indexOf('rgba') !== -1 || colorString.indexOf('rgb') !== -1) {
		return 'rgba';
	}
	if (colorString.indexOf('hsla') !== -1 || colorString.indexOf('hsl') !== -1) {
		return 'hsla';
	}
	return 'hex';
}

/**
 * Function that leads the color coverting steps
 */
export function convertColor(color: string, backgroundColor = '#ffffff'): Color {
	const parsedColor      = colorStringParser(getColorType(color), color);
	// Apha depents on the background color
	const parsedBackground = colorStringParser(getColorType(backgroundColor), backgroundColor);
	let result             = parsedColor;
	if (result.alpha < 1) {
		// Color has transparency, so we need to convert it.
		result = colorConverter(parsedColor, parsedBackground);
	}
	return result;
}

