import { NgModule }     from '@angular/core';
import { CommonModule } from '@angular/common';

import { CsFilterComponent } from './filter.component';
import { FormsModule }       from '@angular/forms';


@NgModule({
	imports:      [
		CommonModule,
		FormsModule
	],
	exports:      [
		CsFilterComponent
	],
	declarations: [
		CsFilterComponent
	],
	providers:    []
})
export class CsFilterModule {
}
