import { FilterBarDataSource, FilterBarResultParams } from '@cs/components/filter-and-compare-bar';
import { CsHttpRequestOptions, Result }               from '@cs/core';
import { KeyValuePair }                               from '@cs/core/generate';
import { IConfigService }                             from '@cs/performance-manager/shared';
import { Observable, Subject }                        from 'rxjs';


export abstract class FilterAndNavbarShellConfigService implements IConfigService {
	moduleName = 'filter-and-navbar-shell';

	activeFilterAndNavbarShellClasses$ = new Subject<Array<string>>();

	abstract getFilterAndCompareBarData(params?: {
																																						[key: string]: any
																																					},
																																					name?: string,
																																					isComparison?: boolean,
																																					paramsMain?: {
																																						[key: string]: any
																																					},
																																					trigger?: string,
																																					options?: CsHttpRequestOptions): Observable<Result<FilterBarDataSource<FilterBarResultParams>>>;

	abstract dropdownSearch(searchInput: string,
																									element: string,
																									name: string,
																									params: {
																										[p: string]: any
																									}, options?: CsHttpRequestOptions): Observable<Result<{
		label: string,
		identifier: string
	}[]>>;

	/**
		* Set the classes for the navbar shell, because some pages require a different background color
		* @param classes list of classes
		*/
	setFilterAndNavbarShellClasses(classes: Array<string>) {
		this.activeFilterAndNavbarShellClasses$.next(classes);
	}

	/**
		* Set the classes for the body, because some pages require a different background color
		* @param classes list of classes
		*/
	setBodyClasses(classes: Array<string>) {
		document.body.classList.add(...classes);
	}

	/**
		* Clean the classes set by a component, should be called on the OnDestroy of the component that requested a different style
		*/
	cleanFilterAndNavbarShellClasses() {
		this.setFilterAndNavbarShellClasses([]);
	}

	/**
		* Clean the classes set by a component, should be called on the OnDestroy of the component that requested a different style
		*/
	cleanBodyClasses(classes: Array<string>) {
		document.body.classList.remove(...classes);
	}
}
