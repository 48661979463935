/**
 * Created by alex on 11-7-2017.
 */

import { Injectable, TemplateRef } from '@angular/core';

import { UiTypes } from '../enums/data-grid.enum';
import { Logger }  from '@cs/components/util';

@Injectable()
export class TemplateStoreService {

  store: Map<UiTypes, TemplateRef<any>> = new Map<UiTypes, TemplateRef<any>>();

  constructor() {
  }

  add(type: UiTypes, template: TemplateRef<any>) {
    this.store.set(type, template);
  }

  remove(type: UiTypes) {
    this.store.delete(type);
  }

  get(type: UiTypes): TemplateRef<any> {
    if (!this.store.has(type)) {
      Logger.ThrowError(`${type} is not found in template store`);
    }
    return this.store.get(type);
  }
}
