import { GridDataRow } from '@cs/components/data-grid';
import { isNullOrUndefined } from '@cs/core/utils';

export const ROWBUTTONS_FUNCTIONS: Map<string, (row: GridDataRow) => boolean> = new Map();

ROWBUTTONS_FUNCTIONS.set('allowdeletionofmanualdatasources', (row: GridDataRow) => {

		const isNotManual = row.values.find(cell =>
			cell.cellData.find(data => data.hasOwnProperty('iddatasource') && data.iddatasource !== 1));

		return !isNullOrUndefined(isNotManual);
	}
);
