import { Component, OnInit, ViewChild } from '@angular/core';
import { CsDatepickerPlain }            from '@cs/components/datepicker';
import { ControlWidget }                from '../models/control-widget.directive';

@Component({
	selector: 'date-picker-widget',
	template: `
				<cs-datepicker-plain [formControl]="control"
									 [attr.id]="id"
									 #dp="picker"
									 [disabled]="readOnly"
				></cs-datepicker-plain>
				<div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length> 0 ">
					<small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
				</div>`
})

export class DatePickerWidgetComponent<T> extends ControlWidget<T> implements OnInit {

	@ViewChild('dp') datepicker: CsDatepickerPlain;


	clearData() {
		super.clearData();
		this.datepicker.resetValueToEmpty();
	}
}
