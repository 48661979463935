import { generateQuickGuid }  from '../data-described/generate.utils';
import { TableDataRowLayout } from './table-data-row.model';


export class TableDataGroupLayout {
	/**
	 * Collection of all the riws for the group
	 */
	rows: TableDataRowLayout[] = [];

	/**
	 * Identification for the data group
	 */
	id: { [key: string]: string | number };

	/**
	 * Id reference for containing group
	 */
	groupId: string;

	/**
	 * Children groups
	 */
	children: TableDataGroupLayout[];

	/**
	 * Depth of the children in the group (for the UI)
	 */
	depth: number;

	/**
	 * Check if group is collapsed
	 */
	collapsed = true;

	constructor(id: { [key: string]: string | number }) {
		this.id      = id;
		this.groupId = generateQuickGuid();
	}

}
