import { ValidationErrors }                                                    from '@angular/forms';
import {
	addClass, createToObjectWithLowerCaseKeys, isEmptyObject, isGreaterAndEqualsThanValidator, isLessAndEqualsThanValidator, isNumber, Logger,
	removeClass, ValidationRuleFilter
}                                                                              from '@cs/components/util';
import { isNullOrUndefined, isString, isUndefined, LoggerUtil }                from '@cs/core';
import {
	DataGridBehaviorAction, DataGridCellTooltipAction, DataGridChangeTextAction, DataGridCssAction, DataGridDataGridSortItemAction,
	DataGridFormatAction,
	DataGridLookupAction, DataGridRemoveCssAction, DataGridSetVirtualKeysAction, DataGridStateAction, DataGridUiStateAction
} from '../classes/data-grid-action';
import { DataGridCalculationOptionsAction }                                    from '../classes/data-grid-calculation-options-action';
import { DataGridRuleMatch, DataGridRuleMatchFilter, DataGridRuleValueFilter } from '../classes/data-grid-rule-match';
import { DataGridSheetSettingsAction }                                         from '../classes/data-grid-sheet-settings-action';
import { DataGridValidatorAction }                                             from '../classes/data-grid-validator-action';
import { DataGridCellType, GridActions, GridItemType, RowState }               from '../enums/data-grid.enum';
import { ICellValidator }                                                      from '../interfaces/i-cell-validator';
import { IMatchLevelKeys }                                                     from '../interfaces/IMatchLevelKeys';
import { GridDataCell }                                                        from '../models/grid-data-cell.model';
import { GridDataRow }                                                         from '../models/grid-data-row.model';
import { GridHeaderCell }                                                      from '../models/grid-head-cell.model';
import { GridRule }                                                            from '../models/grid-rule.model';
import { GridSheet }                                                           from '../models/grid-sheet.model';
import { DataGridHelpers }                                                     from './data-grid-helpers';


export class CellValidationErrors implements ValidationErrors {
	cell: GridDataCell;
	validator: DataGridValidatorAction;
}

export class CellValidator implements ICellValidator {

	constructor(public cell: GridDataCell,
													public properties: DataGridValidatorAction,
													public sheet: GridSheet) {
	}

	getMessage(cell: GridDataCell) {
		throw new Error('Method not implemented.');
	}

	/**
		* Validate current cell value against target matched cell.
		*/
	validate(): CellValidationErrors[] {
		const refcell = DataGridRuleEnforcer.resolveMatchTarget(this.cell, this.properties, this.sheet);
		if (isUndefined(refcell)) {
			LoggerUtil.warn(`Cell validation: Could not resolve a target cell.`);
			LoggerUtil.debug(this.properties);
			return [];
		}

		const matchClone = JSON.parse(JSON.stringify(this.properties.match));
		for (const key of Object.keys(matchClone)) {
			const matchProperty = matchClone[key];
			matchClone[key]     = matchProperty;
			for (const matchValue of Object.keys(matchProperty)) {
				const matchValueContent = matchProperty[matchValue];
				for (const prop of Object.keys(matchValueContent)) {
					const propValue = matchValueContent[prop];
					if (!isNullOrUndefined(propValue)
						&& isString(propValue)
						&& propValue.indexOf('refCell.value') > -1) {
						matchValueContent[prop] = refcell.value;
					}
				}
			}
		}

		return DataGridRuleEnforcer.validateRulesWithErrors(this.cell, matchClone, JSON.stringify(matchClone), this.properties);
	}

}


export class DataGridRuleEnforcer {

	static executeRules(rules: Array<GridRule>, sheetToRender: GridSheet, addRulesToCells: boolean) {

		this.excuteSheetRules(rules, sheetToRender);
		this.executeColumnRules(rules, sheetToRender, addRulesToCells);
		this.executeRowRules(rules, sheetToRender, addRulesToCells);
		this.executeCellRules(rules, sheetToRender, addRulesToCells);

	}

	static find<T extends IMatchLevelKeys>(rule: GridRule, cells: Array<T>, matchAnyKey: boolean = false): Array<T> {
		return this.matchLevelKeys(rule, cells, matchAnyKey) as T[];
	}


	/**
		* Apply rule to cells. Confusing as it is applied to multiple cells. No more rules are added to the cell.
		*/
	static applyRuleToCells(rule: GridRule, foundCells: Array<GridDataCell>, isStaticRule = true, sheet: GridSheet) {
		for (const action of rule.actions) {
			for (const c of foundCells) {
				switch (action.type) {
					case GridActions.SetState:
						// WATCH OUT FOR Single Pointer memory references
						Object.assign(c.cellState, (<DataGridStateAction>action).state);
						break;
					case GridActions.SetUiState:
						// WATCH OUT FOR Single Pointer memory references
						Object.assign(c.cellUIState, (<DataGridUiStateAction>action).state);
						break;
					case GridActions.SetCalculationOptions:
						c.calculation = new DataGridCalculationOptionsAction(<DataGridCalculationOptionsAction>action);
						break;
					case GridActions.SetCellTooltip:
						c.tooltip = new DataGridCellTooltipAction(<DataGridCellTooltipAction>action);
						break;
					case GridActions.SetLookup:
						c.lookup.push(((<DataGridLookupAction>action)));
						c.updateValue();
						break;
					case GridActions.SetFormatting:
						c.format = ((<DataGridFormatAction>action));
						c.formatValue();
						break;
					case GridActions.SetBehavior:
						c.behavior = ((<DataGridBehaviorAction>action));
						break;
					case GridActions.SetVirtualKeys:
						// WATCH OUT FOR Single Pointer memory references
						Object.assign(c.virtualKeys, (<DataGridSetVirtualKeysAction>action).keys);
						break;
					case GridActions.ChangeCss:
						if (isStaticRule)
							c.staticCssClass = addClass(((<DataGridCssAction>action).classes), c.staticCssClass);
						else
							c.dynamicCssClass = addClass(((<DataGridCssAction>action).classes), c.dynamicCssClass);
						break;
					case GridActions.RemoveCss:
						if (isStaticRule)
							c.staticCssClass = removeClass(((<DataGridRemoveCssAction>action).classes), c.staticCssClass);
						else
							c.dynamicCssClass = removeClass(((<DataGridRemoveCssAction>action).classes), c.dynamicCssClass);
						break;
				}

			}
		}

	}

	/**
		* Apply rule to cells. Confusing as it is applied to multiple cells. No more rules are added to the cell.
		*/
	static applyRuleToRows(rule: GridRule, foundRows: Array<GridDataRow>, isStaticRule = true, sheet: GridSheet) {
		for (const action of rule.actions) {
			for (const c of foundRows) {
				switch (action.type) {
					case GridActions.ChangeCss:
						c.cssClass = addClass(((<DataGridCssAction>action).classes), c.cssClass);
						break;
					case GridActions.RemoveCss:
						c.cssClass = removeClass(((<DataGridRemoveCssAction>action).classes), c.cssClass);
						break;
				}

			}
		}

	}

	static applyRuleToHeaderCells(rule: GridRule, foundCells: Array<GridHeaderCell>) {
		for (const action of rule.actions) {
			for (const c of foundCells) {
				switch (action.type) {

					case GridActions.SetUiState:
						// WATCH OUT FOR Single Pointer memory references
						Object.assign(c.cellUIState, (<DataGridUiStateAction>action).state);
						break;
					case GridActions.SetDataGridSortItem:
						// WATCH OUT FOR Single Pointer memory references
						Object.assign(c.headerSortItem, (<DataGridDataGridSortItemAction>action).properties);
						break;
					case GridActions.TextChange:
						const tcAction = <DataGridChangeTextAction>action;
						// if the depth (index of header row) not match the cell ignore it
						if (tcAction.depth !== c.depth)
							continue;

						let newText = '';
						if (!c.properties.hasOwnProperty(tcAction.property))
							Logger.Warning(`[Rule] ${rule.description}:  ${tcAction.type} is not found on properties of cell:
              ${JSON.stringify(c.properties)}`);
						else {
							newText = isNullOrUndefined(tcAction.text) || tcAction.text === ''
																	? c.properties[tcAction.property]
																	: tcAction.text;
							newText = `${tcAction.prefix || ''}${newText}${tcAction.suffix || ''}`;
							// c.properties[tcAction.property] = newText;
						}

						c.value = newText;
						break;
					case GridActions.ChangeCss:
						c.cssClass = addClass(((<DataGridCssAction>action).classes), c.cssClass);
						break;

				}

			}
		}

	}

	static applyRuleToSheets(rule: GridRule, foundCells: Array<GridSheet>) {
		for (const action of rule.actions) {
			for (const c of foundCells) {
				switch (action.type) {
					case GridActions.SetSheetSettings:
						// WATCH OUT FOR Single Pointer memory references
						Object.assign(c.settings, (<DataGridSheetSettingsAction>action).settings);
						break;
				}
			}
		}

	}

	/**
		* Matches items based on the rule's level keys against provided items.
		* Supports matching all keys or any single key.
		*
		* @param rule The rule containing match criteria.
		* @param  items The items to match against the rule.
		* @param matchAnyKey When true, an item matches if it matches on any one key. Defaults to false.
		* @returns The array of items that match the criteria.
		*/
	static matchLevelKeys(rule: GridRule, items: Array<IMatchLevelKeys>, matchAnyKey: boolean = false): Array<IMatchLevelKeys> {
		// Return all items if the rule's match object is empty (no level keys to match).
		if (isEmptyObject(rule.match.levelKeys)) {
			return items;
		}

		// Prepare the keys and their corresponding match filters once to avoid repetitive processing.
		const keysAndFilters = Object.keys(rule.match.levelKeys)
																															.map(key => ({
																																lowerKey: key.toLowerCase(),
																																value:    new DataGridRuleMatchFilter(rule.match.levelKeys[key])
																															}));

		return items.filter(item => {
			if (isNullOrUndefined(item.keys)) return false;

			// Convert item keys to a lower case version for case-insensitive comparison.
			const lowerItemKeys = Object.keys(item.keys)
																															.reduce((acc, key) => {
																																acc[key.toLowerCase()] = item.keys[key];
																																return acc;
																															}, {});

			const matches = keysAndFilters.map(({lowerKey, value}) => {
				// Skip if item does not have the current key.
				if (!lowerItemKeys.hasOwnProperty(lowerKey)) return false;

				// Direct match checks for the current key.
				const itemValue = lowerItemKeys[lowerKey];
				if (isEmptyObject(value)) return true;
				if (DataGridRuleEnforcer.checkIfArray('notIds', value, rule, itemValue)) return false;
				if (!isNullOrUndefined(value.minId) && !isGreaterAndEqualsThanValidator(itemValue, value.minId)) return false;
				if (!isNullOrUndefined(value.maxId) && !isLessAndEqualsThanValidator(itemValue, value.maxId)) return false;
				if (DataGridRuleEnforcer.checkIfArray('ids', value, rule, itemValue)) return false;

				return true;
			});

			// Determine if the item matches based on matchAnyKey criteria.
			return matchAnyKey
										? matches.some(Boolean)
										: matches.every(Boolean);
		});
	}


	static checkIfArray(key: keyof DataGridRuleMatchFilter, value: DataGridRuleMatchFilter, rule: GridRule, lowerKey: number) {

		if (isNullOrUndefined(value[key])) {
			return false;
		}
		const ids = value[key];
		if (!Array.isArray(ids) && isNumber(ids)) {
			value[key] = [ids] as any;
			console.log(rule.description + ' has no valid filter, BUT IT\'S CONVERTED TO AN ARRAY', value);
		} else if (!Array.isArray(ids) && isString(ids)) {
			value[key] = [ids] as any;
			console.log(rule.description + ' has no valid filter, BUT IT\'S CONVERTED TO AN ARRAY', value);
		} else if (!Array.isArray(ids)) {
			console.log(rule.description + ' has no valid filter', value);
			return false;
		}

		if (key === 'notIds')
			return (value[key] as Array<number>).indexOf(lowerKey) > -1;
		else if (key === 'ids')
			return (value[key] as Array<number>).indexOf(lowerKey) === -1;
	}

	/**
		* Find rows/cells in sheet that match dynamic rules. Have the content of the rule execute.
		*/
	static executeDynamicRules(sheet: GridSheet) {

		const dataRows  = DataGridHelpers.findRows(sheet, {}, RowState.All);
		const dataCells = DataGridHelpers.filterCells(sheet, DataGridCellType.All, RowState.All);

		for (const cell of dataCells) {
			// clear the dynamic css classes because this will be set by the rules
			cell.dynamicCssClass = '';
		}

		for (const cell of dataCells) {

			for (const rule of cell.rules) {
				// Only execute the rules that has not only levelKeys and therefore dynamic of nature
				if (isEmptyObject(rule.match.values) && isEmptyObject(rule.match.state) && isEmptyObject(rule.match.factProperties))
					continue;

				const found = this.validateRules(cell, rule.match, rule.description);

				if (found.length > 0) {
					// Logger.Info(`[dynRule] ${rule.description} found ${found.length} cells`);
					this.applyRuleToCells(rule, found, false, sheet);
				}
			}
		}

		// execute dynamic rows after cells. was/is required for specific case.
		for (const row of dataRows) {
			for (const rule of row.rules) {

				// Only execute the rules that has not only levelKeys and therefore dynamic of nature
				if (isEmptyObject(rule.match.values) && isEmptyObject(rule.match.state) && isEmptyObject(rule.match.factProperties))
					continue;

				const foundRows = this.validateRowRules(row, rule.match, rule.description);

				if (foundRows.length > 0) {
					const foundCells: Array<GridDataCell> = [];
					for (const row of foundRows) {
						row.values.forEach(x => {
							if (x.cellType === rule.cellType) {
								foundCells.push(x);
							}
						});
					}
					this.applyRuleToCells(rule, foundCells, false, sheet);
				}
			}
		}
	}

	static matchValues<U>(ruleMatch: DataGridRuleMatch,
																							matchProperty: (x: DataGridRuleMatch) => {
																								[key: string]: DataGridRuleValueFilter
																							},
																							getReferenceValue: (x: U) => {
																								[key: string]: any
																							},
																							cells: Array<U>, description = 'no description provided'): U[] {

		let firstRun    = true;
		let found       = [];
		let iterateOver = cells;

		const match = matchProperty(ruleMatch);
		for (const key of Object.keys(match)) {
			// Get the value Match filter from the object as a object
			const jValue = match[key];

			if (!firstRun)
				iterateOver = found;

			firstRun = false;

			// convert object to a 'class'
			const ruleValueFilter = new DataGridRuleValueFilter(jValue);
			// Don't use KEY!!! Because case sensitive
			const lowerKey        = key.toLowerCase();

			found = iterateOver.filter((cell) => {
				const referenceValue = getReferenceValue(cell);

				if (isNullOrUndefined(referenceValue)) {
					return false;
				}

				const lowerCellKeys = createToObjectWithLowerCaseKeys(referenceValue);
				if (lowerCellKeys.hasOwnProperty(lowerKey)) {

					// if Empty keys match object match cell
					if (isEmptyObject(jValue)) {
						Logger.Warning(`Rule '${description}' has empty values match object for ${lowerKey}`);
						return false;
					}

					return DataGridRuleValueFilter.evaluate(ruleValueFilter, lowerCellKeys[lowerKey]);
				}
			});
			// Logger.Info(`Rule: ${rule.description} has found: ${found.length} matching cells`)
			return found;
		}
	}

	static matchValuesWithErrors<U extends CellValidationErrors>(ruleMatch: DataGridRuleMatch,
																																																														matchProperty: (x: DataGridRuleMatch) => {
																																																															[key: string]: DataGridRuleValueFilter
																																																														},
																																																														getReferenceValue: (x: U) => {
																																																															[key: string]: any
																																																														},
																																																														cells: Array<U>, description = 'no description provided', properties: DataGridValidatorAction): CellValidationErrors[] {


		let found                            = cells;
		const errors: CellValidationErrors[] = [];

		const match = matchProperty(ruleMatch);
		for (const key of Object.keys(match)) {
			// Get the value Match filter from the object as a object
			const jValue = match[key];

			// convert object to a 'class'
			const ruleValueFilter = new ValidationRuleFilter(jValue);
			// Don't use KEY!!! Because case sensitive
			const lowerKey        = key.toLowerCase();

			found = found.filter((cell) => {
				const referenceValue = getReferenceValue(cell);

				if (isNullOrUndefined(referenceValue)) {
					return false;
				}

				const lowerCellKeys = createToObjectWithLowerCaseKeys(referenceValue);
				if (lowerCellKeys.hasOwnProperty(lowerKey)) {

					// if Empty keys match object match cell
					if (isEmptyObject(jValue)) {
						Logger.Warning(`Rule '${description}' has empty values match object for ${lowerKey}`);
						return false;
					}

					// when returnig NULL it is valid
					const result: CellValidationErrors = ValidationRuleFilter.evaluate(ruleValueFilter, lowerCellKeys[lowerKey]) as CellValidationErrors;
					if (result !== null) {
						result.cell      = cell as unknown as GridDataCell;
						result.validator = properties;
						errors.push(result);
					}
					return result;
				}
			});

		}
		// Logger.Info(`Rule: ${rule.description} has found: ${found.length} matching cells`)
		return errors;
	}

	private static excuteSheetRules(rules: Array<GridRule>, sheetToRender: GridSheet) {
		// Only take all sheet rules
		const sheetRules = rules.filter(x => x.target === GridItemType.Sheet);
		for (const rule of sheetRules) {

			const foundSheets = DataGridRuleEnforcer.find(rule, [sheetToRender]);

			if (isEmptyObject(rule.match.values) && isEmptyObject(rule.match.state) && isEmptyObject(rule.match.factProperties)) {
				this.applyRuleToSheets(rule, foundSheets);
				Logger.Info(`[SheetRule] ${rule.description}: ${foundSheets.length} sheets`);
			} else if (rule.match.values.hasOwnProperty('isInNested')) {
				// TODO: make checking for isInNested generic
				foundSheets.filter(s => rule.match.values['isInNested'].is[0] === +s.metaValues.isInNested);
				// TODO: make generic
				Logger.Info(`[SheetRule] ${rule.description}: ${foundSheets.length} nested sheets`);
				this.applyRuleToSheets(rule, foundSheets);
			}
		}
	}

	/**
		* Simplest implementation or Row rules: the match can only contain levelkeys and is matched against the rowkeys.
		* Rule actions are applied to all cells within the Row (respecting the CellType of the rule).
		*/
	private static executeRowRules(rules: Array<GridRule>, sheetToRender: GridSheet, addRulesToCells = true) {
		const rowRules = rules.filter(x => x.target === GridItemType.Row);

		for (const rule of rowRules) {

			// const rows = DataGridHelpers.findRows(sheetToRender, rule.match.levelKeys, rule.rowType);
			const foundRows: GridDataRow[] = [];
			for (const group of sheetToRender.groups) {
				const rows: GridDataRow[] = this.matchLevelKeys(rule, group.dataRows) as GridDataRow[];
				foundRows.push(...rows.filter(value => value.rowState === rule.rowType));
			}

			// add static rules immediately to cells, add dynamic rules to row
			if (isEmptyObject(rule.match.values) && isEmptyObject(rule.match.state) && isEmptyObject(rule.match.factProperties)) {
				const foundCells: Array<GridDataCell> = [];

				if (rule.cellType === DataGridCellType.None) {
					foundRows.forEach(value => {
						value.rules.push(rule);
					});

					Logger.Info(`[RowRule]: ${rule.description} found ${foundCells.length}
        ${rule.cellType} cells in ${foundRows.length} ${rule.rowType} rows`);
					this.applyRuleToRows(rule, foundRows, true, sheetToRender);

					continue;
				}

				for (const row of foundRows) {
					row.values.forEach(x => {
						if (x.cellType === rule.cellType) {
							foundCells.push(x);
						}
					});
				}

				// workaround for freezeData.
				if (addRulesToCells) {
					for (const cell of foundCells) {
						cell.rules.push(rule);
					}
					this.applyValidators(rule, foundCells, sheetToRender);
				}


				Logger.Info(`[RowRule]: ${rule.description} found ${foundCells.length}
        ${rule.cellType} cells in ${foundRows.length} ${rule.rowType} rows`);
				this.applyRuleToCells(rule, foundCells, true, sheetToRender);
			} else {
				foundRows.forEach(row => row.rules.push(rule));
				Logger.Info(`[RowRule]: ${rule.description} found ${foundRows.length} ${rule.rowType} rows`);
			}

		}
	}

	/**
		* Find Cells in sheet that match the rule. Add the rule to the rules array of the cell.
		*/
	private static executeCellRules(rules: Array<GridRule>, sheetToRender: GridSheet, addRulesToCells = true) {
		const dataCells = DataGridHelpers.filterCells(sheetToRender);

		// Only take all cell rules
		const cellRules = rules.filter(x => x.target === GridItemType.Cell);

		for (const rule of cellRules) {
			let cells = dataCells;
			if (rule.cellType !== DataGridCellType.Data || rule.rowType !== RowState.Default) {
				cells = DataGridHelpers.filterCells(sheetToRender, rule.cellType, rule.rowType);
			}

			const foundCells = DataGridRuleEnforcer.find(rule, cells);

			// workaround for freezeData.
			if (addRulesToCells) {
				for (const cell of foundCells) {
					cell.rules.push(rule);
				}
			}

			if (isEmptyObject(rule.match.values) && isEmptyObject(rule.match.state) && isEmptyObject(rule.match.factProperties))
				this.applyRuleToCells(rule, foundCells, true, sheetToRender);

			this.applyValidators(rule, foundCells, sheetToRender);
		}
	}

	private static executeColumnRules(rules: Array<GridRule>, sheetToRender: GridSheet, addRulesToCells = true) {
		const dataCells = DataGridHelpers.filterHeaderCells(sheetToRender);

		// Only take all cell rules
		const cellRules = rules.filter(x => x.target === GridItemType.Column);

		for (const rule of cellRules) {
			let cells = dataCells;
			if (rule.cellType !== DataGridCellType.Data || rule.rowType !== RowState.Default) {
				cells = DataGridHelpers.filterHeaderCells(sheetToRender, rule.cellType, rule.rowType);
			}

			const foundCells = DataGridRuleEnforcer.find(rule, cells);

			// workaround for freezeData.
			if (addRulesToCells) {
				for (const cell of foundCells) {
					cell.rules.push(rule);
				}
			}

			if (isEmptyObject(rule.match.values) && isEmptyObject(rule.match.state) && isEmptyObject(rule.match.factProperties))
				Logger.Info(`[ColumnRule]: ${rule.description} found ${foundCells.length} ${rule.cellType} (header?) cells in ?? columns`);
			this.applyRuleToHeaderCells(rule, foundCells);
		}
	}

	static validateRules(cell: GridDataCell, match: DataGridRuleMatch, description: string) {
		let found = [cell];
		if (!isNullOrUndefined(match['values']) && !isEmptyObject(match['values']))
			found = this.matchValues(match,
																												(x: DataGridRuleMatch) => x.values,
																												(x: GridDataCell) => Object.assign({
																																																																value: isNumber(cell.value)
																																																																							? parseFloat(cell.value)
																																																																							: cell.value
																																																															}, cell.metaValues),
																												found, description);

		if (!isNullOrUndefined(match['factProperties']) && !isEmptyObject(match['factProperties']))
			found = this.matchValues(match,
																												(x: DataGridRuleMatch) => x.factProperties,
																												(x: GridDataCell) => x.cellData[0],
																												found, description);

		if (!isNullOrUndefined(match['state']) && !isEmptyObject(match['state']))
			found = this.matchValues(match,
																												(x: DataGridRuleMatch) => x.state,
																												(x: GridDataCell) => x.cellState,
																												found, description);

		return found;
	}

	static validateRulesWithErrors(cell: GridDataCell, match: any, description: string, properties: DataGridValidatorAction): CellValidationErrors[] {
		let found: CellValidationErrors[] = [{cell, validator: properties}];
		if (!isNullOrUndefined(match['values']) && !isEmptyObject(match['values']))
			found = this.matchValuesWithErrors(match,
																																						(x: DataGridRuleMatch) => x.values,
																																						(x: CellValidationErrors) => Object.assign({
																																																																																		value: isNumber(cell.value)
																																																																																									? parseFloat(cell.value)
																																																																																									: cell.value
																																																																																	}, cell.metaValues),
																																						found, description, properties);

		if (!isNullOrUndefined(match['factProperties']) && !isEmptyObject(match['factProperties']))
			found = this.matchValuesWithErrors(match,
																																						(x: DataGridRuleMatch) => x.factProperties,
																																						(x: CellValidationErrors) => x.cell.cellData[0],
																																						found, description, properties);

		if (!isNullOrUndefined(match['state']) && !isEmptyObject(match['state']))
			found = this.matchValuesWithErrors(match,
																																						(x: DataGridRuleMatch) => x.state,
																																						(x: CellValidationErrors) => x.cell.cellState,
																																						found, description, properties);

		return found;
	}

	static validateRowRules(row: GridDataRow, match: DataGridRuleMatch, description: string) {
		let found = [row];

		// create custom object with selected row state properties
		if (!isNullOrUndefined(match['state']) && !isEmptyObject(match['state']))
			found = this.matchValues(match,
																												(x: DataGridRuleMatch) => x.state,
																												(x: GridDataRow) => ['rowState', 'isExpanded', 'isLoading', 'compareRowState'].reduce((o, key) => {
																													o[key] = x[key];
																													return o;
																												}, {}),
																												found, description);

		return found;
	}

	/**
		* Resolve the target cell relative to the scope of the validated cell (e.g. "row").
		*/
	static resolveMatchTarget(cell: GridDataCell, properties: DataGridValidatorAction, sheet: GridSheet): GridDataCell {
		let cells: Array<GridDataCell>;
		switch (properties.matchScope) {
			case GridItemType.Row:
				const row = DataGridHelpers.findRowByCell(cell, [sheet]);
				cells     = row.values;
				break;
			// if no scope is provided use the provided cell
			default:
				cells = [cell];
			// TODO: implement other scope types such as Column
		}
		if (isNullOrUndefined(cells))
			return undefined;

		let foundCells = cells;

		// if the match has levelkeys that we assume a comparison between cells
		if (!isNullOrUndefined(properties.match.levelKeys)) {
			// Filter by celltype and exlude current cell
			cells = cells.filter(x => x.cellType === properties.celltype && x !== cell);

			const rule = new GridRule({match: new DataGridRuleMatch(properties.match as any), description: 'cell validator'});
			foundCells = DataGridRuleEnforcer.find(rule, cells);
		}

		if (!isNullOrUndefined(foundCells) && foundCells.length === 1) {
			return foundCells[0];
		} else {
			LoggerUtil.warn(`Invalid number of target cells found (${foundCells.length}).`);
		}

		return undefined;
	}

	private static applyValidators(rule: GridRule, allCells: Array<GridDataCell>, sheet: GridSheet) {
		for (const action of rule.actions) {

			for (const c of allCells) {
				const found = this.validateRules(c, rule.match, rule.description);
				if (found.length === 0) continue;

				switch (action.type) {
					case GridActions.AddValidator:
						// add to list of validators
						c.validators.push(new CellValidator(c, <DataGridValidatorAction>action, sheet));
						break;

				}

			}
		}
	}


}
