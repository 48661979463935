import { Injectable }                                           from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable }                                           from 'rxjs';
import { DownloadsConfigService }                               from '../downloads-config.service';
import { DownloadsItem }                                        from '../models/downloads-item';
import { DownloadsCategory }                                    from '../models/downloads-category';

export interface DownloadItemsResolverResult {
  items: Array<DownloadsItem>;
  currentCategory: DownloadsCategory;
}

@Injectable()
export class DownloadItemsResolver implements Resolve<DownloadItemsResolverResult> {
  static resolverName = 'downloadResolveResult';

  constructor(private config: DownloadsConfigService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<DownloadItemsResolverResult> | Promise<DownloadItemsResolverResult> | DownloadItemsResolverResult {

    const obs = new Observable<DownloadItemsResolverResult>(subscriber => {
      const output     = {} as DownloadItemsResolverResult;
      this.config.getDownloadItems()
          .subscribe(resultNewsItems => {
            const items = resultNewsItems.value;
            items.forEach(value => value.isLoading = false);
            output.items = items;

            subscriber.next(output);
            subscriber.complete();
          });
    });

    return obs;
  }

}
