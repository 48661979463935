import { NgModule }             from '@angular/core';
import { CommonModule }         from '@angular/common';
import { ImageSliderComponent } from './image-slider.component';

const COMPONENTS = [ImageSliderComponent];

@NgModule({
  declarations:    [...COMPONENTS],
  exports:         [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  imports:         [
    CommonModule
  ]
})
export class ImageSliderModule {
}
