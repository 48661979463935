import { animate, query, stagger, style, transition, trigger }                              from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, Injector, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog }                                                                        from '@angular/material/dialog';
import { ActivatedRoute, Router }                                                           from '@angular/router';
import { fadeInOnEnterAnimation, FormatProviderService, SafeMethods }                       from '@cs/common';
import { FilterBarResultParams, FilterCompareBarQuery, FilterCompareBarService }            from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                                                            from '@cs/components/toast-manager';
import { CsNavItem, isNullOrUndefined }                                                     from '@cs/core';
import { AppQuery, AppService, AuthenticationQuery, LoginUserProfile }                      from '@cs/performance-manager/shared';
import { UntilDestroy, untilDestroyed }                                                     from '@ngneat/until-destroy';
import { TranslateService }                                                                 from '@ngx-translate/core';
import { fromEvent, interval }                                                              from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap }                       from 'rxjs/operators';
import { HomePmConfigService }                                                              from './home-config.service';
import { HomePmData }                                                                       from './models/home-pm.model';


@UntilDestroy()
@Component({
			   selector   : 'pmc-home-pm',
			   templateUrl: './home-pm.component.html',
			   animations : [
				   fadeInOnEnterAnimation(),
				   trigger('listAnimation', [
					   transition('* => *', [ // each time the binding value changes
						   query(':leave', [
							   stagger(100, [
								   animate('0.25s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
							   ])
						   ], {optional: true}),
						   query(':enter', [
							   style({opacity: 0, transform: 'translate3d(0,-25px,0)'}),
							   stagger(100, [
								   animate('0.25s 400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
																								   opacity  : 1,
																								   transform: 'translate3d(0,0,0)'
																							   }))
							   ])
						   ], {optional: true})
					   ])
				   ]),

				   trigger('listSideAnimation', [
					   transition('* => *', [ // each time the binding value changes
						   query(':leave', [
							   stagger(100, [
								   animate('0.25s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
							   ])
						   ], {optional: true}),
						   query(':enter', [
							   style({opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)'}),
							   stagger(100, [
								   animate('0.25s 400ms cubic-bezier(0.4, 0.0, 0.2, 1)', style({
																								   opacity  : 1,
																								   transform: 'scale3d(1, 1, 1)'
																							   }))
							   ])
						   ], {optional: true})
					   ])
				   ])
			   ]
		   })
export class HomePmComponent implements OnInit, OnDestroy {

	greeting$ = this.authQuery.select(store => store.userProfile)
					.pipe(
						untilDestroyed(this),
						filter(value => value !== null),
						switchMap(value => this.getCurrentDayPart(value)));

	lastLogin$ = this.authQuery.select(store => store.userProfile)
					 .pipe(
						 untilDestroyed(this),
						 filter(value => value !== null),
						 map(value => {
							 return this.relativeTime(value.lastLogin);
						 }));

	time$ = interval(1000)
		.pipe(map(value => new Date().toLocaleTimeString()), distinctUntilChanged());

	date$ = interval(1000)
		.pipe(map(value => new Date().toLocaleDateString()), distinctUntilChanged());

	@ViewChild('headerContent', {static: true, read: ElementRef}) headerContent;

	homeData: HomePmData;

	get containerHeight(): string {
		if (this.headerContent)
			return this.headerContent.nativeElement.clientHeight;
	}

	constructor(public readonly config: HomePmConfigService,
				private readonly authQuery: AuthenticationQuery,
				private readonly activatedRoute: ActivatedRoute,
				private readonly appStateQuery: AppQuery,
				private readonly format: FormatProviderService,
				private readonly injector: Injector,
				readonly filterCompareBarQuery: FilterCompareBarQuery,
				readonly filterCompareBarService: FilterCompareBarService,
				readonly appService: AppService,
				readonly cdRef: ChangeDetectorRef,
				readonly i8n: TranslateService,
				readonly toastService: CsToastManagerService,
				readonly dialog: MatDialog,
				readonly router: Router,
				private readonly elRef: ElementRef) {

	}

	ngOnInit() {
		// this.setData();
		this.WindowResizeHandler();
		this.setHomeData(null);
		this.setHeaderDataValuesSlider();

	}

	relativeTime(time: Date) {
		if (isNullOrUndefined(time))
			return time;

		const date     = new Date(time),
			  diff     = (((new Date()).getTime() - date.getTime()) / 1000),
			  day_diff = Math.floor(diff / 86400);
		const year     = date.getFullYear(),
			  month    = date.getMonth() + 1,
			  day      = date.getDate();

		if (isNaN(day_diff) || day_diff < 0 || day_diff >= 31)
			if (isNaN(year) || isNaN(month) || isNaN(day))
				return null;
			else
				return (
					year.toString() + '-'
					+ ((month < 10)
					   ? '0' + month.toString()
					   : month.toString()) + '-'
					+ ((day < 10)
					   ? '0' + day.toString()
					   : day.toString())
				);

		const r =
				  (
					  (
						  day_diff === 0 &&
						  (
							  (diff < 60 && this.i8n.instant('JUST_NOW'))
							  || (diff < 120 && this.i8n.instant('ONE_MINUTE_AGO'))
							  || (diff < 3600 && `${Math.floor(diff / 60)} ${this.i8n.instant('MINUTES_AGO')}`)
							  || (diff < 7200 && this.i8n.instant('ONE_HOUR_AGO'))
							  || (diff < 86400 && `${Math.floor(diff / 3600)} ${this.i8n.instant('HOURS_AGO')}`)
						  )
					  )
					  || (day_diff === 1 && this.i8n.instant('YESTERDAY'))
					  || (day_diff < 7 && `${day_diff} ${this.i8n.instant('HOURS_AGO')}`)
					  || (day_diff < 31 && `${Math.ceil(day_diff / 7)} ${this.i8n.instant('WEEKS_AGO')}`)
				  );
		return r;
	}

	ngOnDestroy(): void {
	}

	navigateToPage(page: CsNavItem) {
		const isActive = this.homeData.pages.find(value => value.active);

		if (isActive)
			isActive.active = false;

		this.router.navigate([page.name], {relativeTo: this.activatedRoute})
			.then(value => {
				page.active = true;
			});
	}

	setHeaderDataValuesSlider() {
		const slider = this.elRef.nativeElement.querySelector('.home-header-data-values-container');
		let isDown   = false;
		let startX;
		let scrollLeft;

		slider.addEventListener('mousedown', (e) => {
			isDown = true;
			slider.classList.add('active');
			startX     = e.pageX - slider.offsetLeft;
			scrollLeft = slider.scrollLeft;
		});
		slider.addEventListener('mouseleave', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mouseup', () => {
			isDown = false;
			slider.classList.remove('active');
		});
		slider.addEventListener('mousemove', (e) => {
			if (!isDown) return;
			e.preventDefault();
			const x           = e.pageX - slider.offsetLeft;
			const walk        = (x - startX);
			slider.scrollLeft = scrollLeft - walk;
			// console.log(walk);
		});
	}


	filterAndCompareMovement() {

		const elements            = this.elRef.nativeElement as HTMLElement;
		const infoPanel           = elements.querySelector('.home-header-data-list-container') as HTMLElement;
		const indicators          = elements.querySelector('.home-header-data-values-container') as HTMLElement;
		const rootHeaderContainer = indicators.parentElement.parentElement;

		const indicatorWidth = indicators.scrollWidth;
		const infoPanelWidth = infoPanel
							   ? parseInt(
				window.getComputedStyle(infoPanel)
					  .minWidth
					  .replace('px', ''), 0)
							   : 0;
		const parentWidth    = infoPanel
							   ? infoPanel.parentElement.offsetWidth
							   : indicators.parentElement.offsetWidth;

		if (infoPanelWidth + indicatorWidth > parentWidth) {
			if (!rootHeaderContainer.classList.contains('isWrapped')) {
				rootHeaderContainer.classList.add('isWrapped');
				indicators.classList.add('isWrapped');
				if (infoPanel)
					infoPanel.classList.add('isWrapped');
			}
		} else {
			if (rootHeaderContainer.classList.contains('isWrapped')) {
				rootHeaderContainer.classList.remove('isWrapped');
				indicators.classList.remove('isWrapped');
				if (infoPanel)
					infoPanel.classList.remove('isWrapped');
			}
		}

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}

		indicators.style.cursor = isEllipsisActive(indicators)
								  ? 'grab'
								  : 'default';
	}

	private setHomeData(selection: FilterBarResultParams) {

		this.config.getHomepage()
			.subscribe(value => {
						   this.homeData = value.value;
						   SafeMethods.detectChanges(this.cdRef);
						   this.filterAndCompareMovement();
					   }
			);
	}

	private getCurrentDayPart(user: LoginUserProfile) {
		const currentTime = new Date();
		const currentHour = currentTime.getHours();
		let output        = 'HELLO';
		if (currentHour >= 0 && currentHour < 6) {
			output = 'GOODNIGHT';
		} else if (currentHour >= 6 && currentHour < 12) {
			output = 'GOODMORNING';
		} else if (currentHour >= 12 && currentHour < 18) {
			output = 'GOODAFTERNOON';
		} else if (currentHour >= 18 && currentHour < 24) {
			output = 'GOODEVENING';
		}
		return this.i8n.get(output)
				   .pipe(map(timeOfDay => `${timeOfDay} ${user.firstName}`));
	}

	private WindowResizeHandler() {
		const resizeObservable$ = fromEvent(window, 'resize');
		resizeObservable$.pipe(
							 untilDestroyed(this),
							 debounceTime(100))
						 .subscribe(async (evt) => {
							 await this.filterAndCompareMovement();
						 });
	}
}
