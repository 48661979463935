<mat-dialog-content>
  <div class="flex flex-row slider-edit-modal">
    <div class="col-4 no-padding card-block">
      <div class="card-header mb-4 flex-row flex">
        <div class="truncate">{{modalLabel | titlecase}}</div>
        <span class="flex-pull-right">{{periodLabel}}</span>
      </div>
      <div class="form-group">
        <label>{{'VALUES_APPLY_TO' | translate | titlecase}}</label>
        <select class="form-control form-dropdown input-sm"
                [disabled]="isSaving"
                [(ngModel)]="commentTypeSelected">
          <option *ngFor="let c of commentTypes"
                  [ngValue]="c.id">
            {{c.label}}
          </option>
        </select>
      </div>

      <div class="form-group"
           style="min-height:58px">
        <ul>
          <li *ngFor="let info of infoValues">

            <label>{{periodLabel}} | {{info.label}}</label>
            <span>{{info.value || ('NO_DATA' | translate | titlecase) | format:'{0:N2}'}}</span>
          </li>
        </ul>
      </div>
      <div class="form-group"
           [hidden]="disableSliders"
           style="overflow: visible">
        <ul>
          <li *ngFor="let slider of sliders"
              class="slider-form-group">
            <label>{{slider.label}}</label>
            <div class="slider-container">
              <cs-slider [animate]="true"
                         [tooltipDisplay]="'none'"
                         [showPercentageLabels]="true"
                         (onChange)="sliderIsTouched()"
                         [disabled]="isSaving"
                         [(ngModel)]="slider.value">
              </cs-slider>
            </div>
          </li>
        </ul>
      </div>
      <div class="form-group"
           [class.no-sliders-height]="disableSliders">
        <textarea class="form-control"
                  placeholder="Message"
                  [disabled]="isSaving"
                  [(ngModel)]="comment"
                  (ngModelChange)="this.checkForInvalidData()"
                  maxlength="250"></textarea>

        <label class="float-right"
               matTooltip="{{getErrorMessage()}}"
               [matTooltipDisabled]="comment.length > 4">
          <label [ngClass]="{'red-alert':comment.length < 5 }">{{comment.length}}</label> / 250
        </label>

      </div>
      <div class="slider-edit-buttons">
        <button type="button"
                mat-dialog-close=""
                [disabled]="isSaving"
                class="btn btn-tertiary btn-md">Cancel
        </button>
        <span matTooltip="{{getErrorMessage()}}"
              [matTooltipDisabled]="!isInValid">
      <button

              [disabled]="isInValid"
              (click)="saveData()"
              class="'btn btn-primary btn-md'">
        {{'LABELS.SUBMIT' | translate | titlecase}}
      </button>
    </span>
      </div>
    </div>
    <div class="col-8 no-padding card-block">
      <div class="form-group">
        <label class="bold">{{'LONG_LABELS.PROPOSED_VALUES_FOR' | translate: {kpi: kpi} |titlecase}}</label>
      </div>

      <div>
        <cs-table-nxt #tableNxt
                      [data]="predictionTable"
                      (cellValueChanged)="valueChanged($event)"
                      [showPageCounter]="false">
        </cs-table-nxt>
      </div>

      <div class="form-group flex flex-row justify-content-end prediction-status-message-container"
           *ngIf="predictionStatusMessage"
           [innerHTML]="predictionStatusMessage">
      </div>
    </div>
  </div>
</mat-dialog-content>
