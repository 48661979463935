import {CsHttpRequestOptions} from '@cs/core';
import {IConfigService}       from '../app/config-service.interface';

export abstract class BrandingConfigService implements IConfigService {

	moduleName = 'branding';

	abstract getBrandingLogo(image: string, queryParams: {}, options: CsHttpRequestOptions);

}
