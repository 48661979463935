import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormGroup }                                 from '@angular/forms';

import { INode } from '../../interfaces/inode';


@Component({
  selector:        'pmc-property-node-view',
  templateUrl:     './property-node-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsPropertyNodeViewComponent {
  @Input() node: INode;
  @Input() formGroup: FormGroup;
  @Input() editMode       = false;
  @Input() columnLabels   = [];
  @Input() columnWidth    = 100;
  @Input() hideRemarkIcon = false;

}
