<ng-container>

	<div class="simple-dialog dialog-{{type}} update-dialog__update-success" *ngIf="parentRepositories.length > 0">

		<ng-container *ngTemplateOutlet="output ; context: { $implicit: errorLog}"></ng-container>

		<div class="update-button-holder">
			<button *ngIf="hasContinueEvent" class="btn btn--primary btn-md" (click)="updateEvent()" tabindex="1">
				Update
			</button>

			<!--	Revert Commit when closing update?	-->
			<button class="btn btn-md dialog--cancel" (click)="onNoClick()" tabindex="2">
				{{hasContinueEvent ? "Cancel" : "Close"}}
			</button>
		</div>

	</div>

</ng-container>

<ng-template #output let-logOutput>
	<div class="update-dialog__commit-screen__commit-content">

		<table class="repository_table" style="table-layout: auto">

			<thead class="repository_table__table-head" style="height: 30px; max-height: 30px">
			<tr>
				<th class="repository_table__head-cell repository_table__heavy-font">Repository</th>
				<th class="repository_table__head-cell repository_table__heavy-font">New Hash</th>
				<th class="repository_table__head-cell repository_table__heavy-font">Message</th>
				<th class="repository_table__head-cell repository_table__heavy-font" style="width: 55px">Success</th>
			</tr>
			</thead>

			<tbody>

			<ng-container *ngFor="let repositoryValue of createRepositoryMap() | keyvalue; let x = index">

				<tr class="repository_table__branch-row" style="height: fit-content">
					<td class="repository_table__cell repository_table__align-center repository_table__heavy-font">{{repositoryValue.key}}</td>
					<td class="repository_table__cell"></td>
					<td class="repository_table__cell"></td>
					<td style="max-width: 50px" class="repository_table__cell"></td>
				</tr>

				<ng-container *ngFor="let repository of repositoryValue.value">

					<tr style="height: fit-content">
						<td class="repository_table__cell repository_table__align-center repository_table__light-font">
							{{repository.branch}}
						</td>

						<td class="repository_table__cell repository_table__align-center repository_table__light-font">
							{{repository.hashOrBranch.substr(0, 7)}}
						</td>

						<td class="repository_table__cell repository_table__align-center repository_table__light-font">
							{{logOutput.has(repository) ? logOutput.get(repository).message : ""}}
						</td>

						<td style="max-width: 50px" class="repository_table__cell repository_table__align-center repository_table__light-font">
							{{logOutput.has(repository) ? logOutput.get(repository).isSuccessFull ? "V" : "X" : ""}}
						</td>
					</tr>

				</ng-container>
			</ng-container>

			</tbody>

		</table>
	</div>
</ng-template>
