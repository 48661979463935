import {
	Component, OnInit, ChangeDetectionStrategy,
	Input, ViewChild, OnChanges, NgZone, OnDestroy, ChangeDetectorRef, EventEmitter, Output
}                                                                          from '@angular/core';
import { LoggerUtil }                                                      from '@cs/core/utils';
import { DataEntryStateService, DataEntryStateStore, DateEntryStateQuery } from './state';
import { UntilDestroy, untilDestroyed }                                    from '@ngneat/until-destroy';
import { take }                                                            from 'rxjs/operators';
import { DataEntryStateEventArgs, DataEntryStateHeaderColumn }             from './models';
import { DataEntryStateStructure }                                         from './models/data-entry-state-structure';
import { CsLengthRulerDirective, CsWidthRulerDirective }                   from '@cs/components/shared';
import { ComponentChanges, whenChanging }                                  from '@cs/core';
import { DataEntryStateHeaderRow, DataEntryStateRow }                      from './models/data-entry-state-row';


@UntilDestroy()
@Component({
			   selector:        'cs-data-entry-state',
			   templateUrl:     './data-entry-state.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   providers:       [DataEntryStateStore, DateEntryStateQuery, DataEntryStateService]
		   })
export class CsDataEntryStateComponent implements OnInit,
												  OnDestroy,
												  OnChanges {
	static TYPE_NAME = 'data-entry-state';

	@Input() dataRows: DataEntryStateRow[];

	@Input() headerRows: DataEntryStateHeaderRow[];
	@Input() renderSchema: DataEntryStateStructure;
	/**
	 * Emits event when there is a action requested by a widget
	 */
	@Output() onActionRequested = new EventEmitter<DataEntryStateEventArgs>();
	@ViewChild(CsLengthRulerDirective) heightDirective: CsLengthRulerDirective;

	heightCurtain: number;
	widthCurtain: number;

	constructor(private ngZone: NgZone,
				private changeRef: ChangeDetectorRef,
				private _dataEntryState: DataEntryStateService
	) { }

	ngOnInit(): void {
		this._dataEntryState.onActionRequested.pipe(untilDestroyed(this))
			.subscribe(value => {
				this.onActionRequested.emit(value);
			});
	}

	ngOnChanges(changes: ComponentChanges<CsDataEntryStateComponent>): void {

		whenChanging(changes.renderSchema, true)
			.execute(value => {
				this.init(value.currentValue);
			});

		this.ngZone.onStable.pipe(
				untilDestroyed(this),
				take(1)
			)
			.subscribe(value => {
				if (this.heightDirective) {
					this.heightDirective.update();
					this.heightCurtain = this.heightDirective.currentHeight;
					this.widthCurtain  = this.renderSchema.headerRoot.columns.filter((value: DataEntryStateHeaderColumn) => value.visualState.sticky)
											 .reduce((previousValue: number, currentValue: DataEntryStateHeaderColumn) => {
												 return previousValue + currentValue.position.width + 2;
											 }, 0) + 24;
					this.changeRef.detectChanges();
				}
			});
	}

	trackByStructureKey    = (index: number, item: DataEntryStateHeaderColumn) => item.structureKey;
	trackByRowStructureKey = (index: number, item: DataEntryStateRow) => item.structureKey;

	ngOnDestroy(): void {
	}

	@ViewChild(CsWidthRulerDirective, {static: true}) private widthRuler: CsWidthRulerDirective;


	private init(structure: CsDataEntryStateComponent['renderSchema']) {
		if (structure.headerRows != null)
			this.headerRows = structure.headerRows as DataEntryStateHeaderRow[];

		if (structure.lookups.size > 0) {
			// 	this.dataGridLookupService.registerLookups(structure.lookups);
		}

		if (!this.renderSchema.layout) {
			LoggerUtil.error('Please provide layout options', true);
		}

		if (structure.dataRows != null && structure.rowStructure != null && (this.renderSchema.layout.renderAsLegend || this.widthRuler.currentWidth > 0)) {
			this.calculateRowPositions(structure, this.renderSchema.layout.renderAsLegend
												  ? 0
												  : this.widthRuler.currentWidth);

			this.dataRows = structure.dataRows as DataEntryStateRow[];
		}
	}

	private calculateRowPositions(structure: DataEntryStateStructure, availableWidth: number) {
		return structure.calculateDataColumnPositions(availableWidth);
	}
}
