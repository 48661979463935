import { isNullOrUndefined } from '@cs/core';

export class DateUtils {
	static splitCfDate(idDate): IConvertedDay {
		const intern = idDate.toString();
		let out: IConvertedDay;

		if (intern.length === 6) {
			// 201604
			out = {
				month: parseInt(intern.substr(intern.length - 2), 10),
				year:  parseInt(intern.substr(0, 4), 10),
				day:   0
			};
		} else if (intern.length === 8) {
			// 20160423
			out = {
				month: parseInt(intern.substr(4, 2), 10),
				year:  parseInt(intern.substr(0, 4), 10),
				day:   parseInt(intern.substr(intern.length - 2), 10)
			};
		}
		return out;
	}

	static convertCfDateToJsDate(date): Date {
		if (date === '')
			return null;

		const cd = this.splitCfDate(date);
		let jsDate: Date;
		if (!isNullOrUndefined(cd)) {
			jsDate = new Date();
			jsDate.setFullYear(cd.year, cd.month - 1, cd.day);
		} else {
			jsDate = new Date(date);
		}
		return jsDate;
	}

	static convertJsDateToCfDate(date: Date, withDay = false): number {
		let day     = '';
		const month = date.getMonth() + 1; // JS 0 index month
		const year  = date.getFullYear();

		let field = month.toString();
		if (field.length === 1) {
			field = 0 + field;
		}

		if (withDay) {
			day = date.getDate().toString();
			if (day.length === 1) {
				day = 0 + day;
			}
		}

		return parseInt(year + field + day, 10);
	}

	static addDays(date: Date, days: number): Date {
		const copy = new Date(Number(date));
		copy.setDate(date.getDate() + days);
		return copy;
	}

}

export interface IConvertedDay {
	year: number;
	month: number;
	day?: number;
}
