import { Injectable }                   from '@angular/core';
import { Query }                        from '@datorama/akita';
import { BrandingState, BrandingStore } from './branding.store';

@Injectable({providedIn: 'root'})
export class BrandingQuery extends Query<BrandingState> {

	constructor(protected store: BrandingStore) {
		super(store);
	}

}
