import { DataDescribed }                from './data-described.model';
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';
import { isNumber }                     from '@cs/core/utils';
import { Lookup }                       from './lookup.model';


export class ServerSideFilter {
	filter: { [p: string]: any };
	trigger: string;

	constructor(filter: { [p: string]: any } = {}, trigger: string = null) {
		this.filter  = filter;
		this.trigger = trigger;
	}

	static createFilter(panel: any) {
		if (panel.options && (<any>panel.options.data) && (<any>panel.options.data).columnFilter) {
			return new ServerSideFilter((<any>panel.options.data).columnFilter, 'server');
		}
		return new ServerSideFilter();
	}
}

export class ServerSideSortItem {
	sortIndex: number;
	sortOrder: 'Asc' | 'Desc';
}

export class ServerSideSorting {
	filter: { [p: string]: ServerSideSortItem };
	trigger: string;
	neutralSortOption: boolean; 

	constructor(filter: { [p: string]: any } = {}, trigger: string = null, neutralSortOption = null) {
		this.filter  = filter;
		this.trigger = trigger;
		this.neutralSortOption = neutralSortOption ?? true;
	}

	static createSorting(panel: { options: DataDescribed<any> }) {
		if (panel.options && (<any>panel.options.data) && (<any>panel.options.data).columnSorting) {
			return new ServerSideSorting((<any>panel.options.data).columnSorting,
										'server',
										(<any>panel.options.data).neutralSortOption)
										;
		}
		return new ServerSideSorting();
	}
}

export class ServerSideNewPage {
	filter: { [p: string]: any };
	sorting: { [key: string]: ServerSideSortItem };
	pageIndex: number;
	pageSize: number;
	trigger: string;

	constructor(init: ServerSideNewPage) {
		this.filter    = init.filter;
		this.sorting   = init.sorting;
		this.pageIndex = init.pageIndex;
		this.pageSize  = init.pageSize;
		this.trigger   = init.trigger;
	}
}

export class ServerSidePaging {
	pageIndex: number;
	performCount: boolean;
	pageSize: number;
	recordCount: number;

	pagesLookup: Lookup;
	pageSizesLookup: Lookup;

	constructor(panel: { options: DataDescribed<any> }, previousOptions?: DataDescribed<any>) {
		if (panel.options && (<any>panel.options.data) && (<any>panel.options.data).pageIndex > -1) {
			const init    = panel.options.data as ServerSidePaging;
			const options = panel.options;

			this.pageIndex    = getPropertyOf(init, 'pageIndex');
			this.performCount = getPropertyOf(init, 'performCount', null);
			this.pageSize     = getPropertyOf(init, 'pageSize');

			if (hasPropertyOf(init, 'recordCount'))
				this.recordCount = getPropertyOf(init, 'recordCount');
			else {
				this.recordCount = getPropertyOf(previousOptions.data, 'recordCount');
				init.recordCount = this.recordCount;
			}


			this.pagesLookup = ServerSidePaging.calculatePages(options as DataDescribed<Exclude<ServerSidePaging, 'pagesLookup'>>,
				this.pageSize,
				this.recordCount);

			this.pageSizesLookup = (options as DataDescribed<Exclude<ServerSidePaging, 'pagesLookup'>>).getLookupByProperty('pageSize').lookup;

		}
	}


	static calculatePages(options: DataDescribed<Exclude<ServerSidePaging, 'pagesLookup'>>, pageSize: number, recordCount: number) {
		let currentPageSize = options.resolveValueWithLookupByPropertyName(pageSize, 'pageSize');
		currentPageSize     = isNumber(currentPageSize) ? currentPageSize : parseInt(currentPageSize, 0);
		const currentPage   = options.data.pageIndex;

		const amountOfPages = Math.ceil(recordCount / currentPageSize);

		if (currentPage > amountOfPages - 1) {
			options.data.pageIndex = amountOfPages - 1 < 0 ? 0 : amountOfPages - 1;
		}

		const annotation = options.resolveDataAnnotationByPropertyName('pageIndex');

		const pagesLookup = new Lookup({id: annotation.id, values: []});
		for (let i = 0; i < amountOfPages; i++) {
			pagesLookup.values.push({value: i + 1, key: i});
		}

		annotation.lookup = pagesLookup.id;
		options.lookups.push(pagesLookup);
		return pagesLookup;

	}

	static verifyPageIndex(options: DataDescribed<ServerSidePaging>) {
		const data = options.data;

		if (hasPropertyOf(data, 'pageIndex') && hasPropertyOf(data, 'pageSize')) {
			ServerSidePaging.calculatePages(options, data.pageSize, data.recordCount);
		}
	}
}
