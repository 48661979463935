import { InjectionToken }                                                                                             from '@angular/core';
import { FormGroup }                                                                                                  from '@angular/forms';
import { FormLayout, FormLayoutWidgetCollection, FormSettings, KeyValuePair, Lookup, PropertyAnnotation, WidgetInfo } from '@cs/core';
import { Observable }                                                                                                 from 'rxjs/internal/Observable';
import { ControlWidget }                                                                                              from './models';

export interface IFormGeneratorAgentService<TFormInstance> {
	registerForm(form: TFormInstance): void;

	getLookup(lookupName: string): Lookup;

	updateLookup(lookupName: string, values: KeyValuePair<any, any>[]): number;

	patchEmptyLookupValue(foundLookup: Lookup): void;

	getFormSettings(): FormSettings;

	getFormInstance(): TFormInstance;

	registerWidget(widget: ControlWidget<any>);

	findWidget(id: string);

	findWidgetInfo(id: string): WidgetInfo<any>;

	findFormCollectionContainingField(id: string): FormLayoutWidgetCollection<any>;

	getAllWidgets(): void;

	updateDependantLookups(id: string): void;

	publishActionRequested($event: PropertyAnnotation<any>): void;

	subscribeActionRequested(): Observable<PropertyAnnotation<any>>;

	notifyDependantFields<T>(id: keyof T): void;

	convertToFiles(
		value: any,
		form: { widgets: Array<WidgetInfo<any>>; formGroup: FormGroup; layout: FormLayout<any> }): Promise<any>;
}

export const FORM_GENERATOR_AGENT_ACCESSOR = new InjectionToken<IFormGeneratorAgentService<any>>('FormgeneratorAgentAccessor');
