import * as sffjs                      from 'sffjs';
import { Inject, Pipe, PipeTransform } from '@angular/core';
import { FormatProviderService }       from '../format-provider.service';
import { FormatRegisteredItem }        from '@cs/core';


const format: any = sffjs;

@Pipe({name: 'format', pure: true})
export class FormatPipe implements PipeTransform {

	constructor(@Inject(FormatProviderService) private formatProviderService: FormatProviderService) {

	}

	transform(value: any = null, formatString: string = null): any {
		return this.formatProviderService.format(value, new FormatRegisteredItem(null, formatString));
	}


}
