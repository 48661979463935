import { Inject, NgModule, Optional }       from '@angular/core';
import { CommonModule }                     from '@angular/common';
import { DashboardDataEntryStateComponent } from './components/dashboard-data-entry-state/dashboard-data-entry-state.component';
import { CsDataEntryStateModule }           from './data-entry-state.module';


import { DashboardComponentRegistry } from '@cs/components/shared';


@NgModule({
						declarations: [DashboardDataEntryStateComponent],
						imports:      [
							CommonModule,
							CsDataEntryStateModule
						]
					})
export class DashboardDataEntryStateModule {
	constructor(@Optional() @Inject(DashboardComponentRegistry) registry: DashboardComponentRegistry) {

		if (registry) {
			registry.register(DashboardDataEntryStateComponent.TYPE_NAME, DashboardDataEntryStateComponent);
		}
	}
}
