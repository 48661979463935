<div class="deploy-info hide-in-print">
  <div class="flex flex-row flex-justify-content-end">
    <div *ngIf="deploymentDetails?.version" matTooltip="{{'APPLICATION_VERSION' | translate}}"
         class="badge m-0 badge--dark">{{deploymentDetails?.version}}</div>
    <div *ngIf="environment" matTooltip="{{'ENVIRONMENT' | translate}}"
         class="ml-2 badge m-0 badge--{{badge}}">{{environment}}</div>
  </div>
  <ng-container *ngFor="let repository of deploymentDetails?.repositories">
    <div *ngIf="deploymentDetails.repositories.length > 1 && repository?.displayName">
      {{'NAME' | translate}}: {{repository.displayName}}</div>
    <div *ngIf="repository?.branch">
      <a href="{{deploymentDetails.detailsUrl}}" target="_blank" matTooltip="{{'COMMIT' | translate}}: {{deploymentDetails?.repositories[0]?.lastCommitHash}}">
        {{'BRANCH' | translate}}: {{repository.branch}}
      </a>
    </div>
  </ng-container>
  <div *ngIf="deploymentDetails?.buildDate">{{'BUILD_DATE' | translate}}
    : {{deploymentDetails.buildDate | format:'{0:dd MMM yyyy H:mm}:iddate'}}</div>
</div>
