import {
  animate,
  animateChild,
  animation,
  AnimationTriggerMetadata,
  group,
  keyframes,
  query,
  style,
  transition,
  trigger,
  useAnimation
}                            from '@angular/animations';
import { IAnimationOptions } from './animation-options.interface';


const fadeIn = animation([
  animate(
    '{{duration}}ms {{delay}}ms',
    keyframes([style({visibility: 'visible', opacity: 0, easing: 'ease', offset: 0}), style({opacity: 1, easing: 'ease', offset: 1})])
  )
]);

const DEFAULT_DURATION = 1000;

export function fadeInAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'fadeIn', [
    transition(
      '0 => 1',
      [
        ...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), {optional: true})] : []),
        group([
          useAnimation(fadeIn),
          ...(!options || !options.animateChildren || options.animateChildren === 'together'
            ? [query('@*', animateChild(), {optional: true})]
            : [])
        ]),
        ...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), {optional: true})] : [])
      ],
      {
        params: {
          delay:    (options && options.delay) || 0,
          duration: (options && options.duration) || DEFAULT_DURATION
        }
      }
    )
  ]);
}

export function fadeInOnEnterAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'fadeInOnEnter', [
    transition(
      ':enter',
      [
        style({visibility: 'hidden'}),
        ...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), {optional: true})] : []),
        group([
          useAnimation(fadeIn),
          ...(!options || !options.animateChildren || options.animateChildren === 'together'
            ? [query('@*', animateChild(), {optional: true})]
            : [])
        ]),
        ...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), {optional: true})] : [])
      ],
      {
        params: {
          delay:    (options && options.delay) || 0,
          duration: (options && options.duration) || DEFAULT_DURATION
        }
      }
    )
  ]);
}
