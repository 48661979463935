import {
  Directive, ElementRef, EventEmitter, HostBinding, HostListener, Input, OnChanges, OnInit, Output,
  SimpleChanges
} from '@angular/core';

@Directive({
  selector: '[csHyperlink]'
})
export class CsHyperlinkDirective implements OnChanges, OnInit {

  get csHyperlinkDisabled(): boolean {
    return this._csHyperlinkDisabled;
  }

//  @HostBinding('class.hover-pointer')
  @HostBinding('class.disabled')
  @Input() set csHyperlinkDisabled(value: boolean) {
    this._csHyperlinkDisabled = value;
  }

  private _csHyperlinkDisabled = false;

  @Output() csHyperlinkClick = new EventEmitter<MouseEvent>();

  constructor(private el: ElementRef) {
  }

  ngOnInit(): void {
    this.el.nativeElement.classList.add('link');
  }

  ngOnChanges(changes: SimpleChanges): void {

  }

  @HostListener('click', ['$event']) onMouseLeave($event: MouseEvent) {
    if (!this._csHyperlinkDisabled)
      this.csHyperlinkClick.emit($event);
  }

}
