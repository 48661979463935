import { AfterViewInit, Directive, forwardRef, Inject, OnInit }          from '@angular/core';
import { FormatProviderService }                                         from '@cs/common';
import { FormSettings, getErrorMessages, isNullOrUndefined, LoggerUtil } from '@cs/core';
import { getPropertyOf }                                                 from '@cs/core';
import { PropertyAnnotation }                                            from '@cs/core';
import { noop }                                                          from 'rxjs';
import { IFormGeneratorAgentService, FORM_GENERATOR_AGENT_ACCESSOR }     from '../i-form-generator-agent.service';
import { IFormGeneratorNxtComponent }                                    from './form-generator-nxt-component.interface';
import { WidgetBase }                                                    from './widget-base.model';


@Directive()
export class ControlWidget<T> extends WidgetBase<PropertyAnnotation<T>> implements AfterViewInit, OnInit {

	/**
	 * Returns readonly state of the widget
	 * Readonly at form level sets all fields to readOnly
	 */
	get readOnly(): boolean {
		return this._readOnly;
	}

	get isValid() {
		if (this.getSetting('showValidState'))
			return !this.control.pristine && this.control.valid;
	}

	get isInValid() {
		return !this.control.pristine && this.control.invalid;
	}
	get isInWarning() {
		return this.errorMessages.find(value => value.type === 'warning');
	}

	constructor(@Inject(FORM_GENERATOR_AGENT_ACCESSOR) protected formAgent: IFormGeneratorAgentService<IFormGeneratorNxtComponent<T>>,
							@Inject(forwardRef(() => FormatProviderService)) protected formatService: FormatProviderService) {
		super();
		this.formAgent.registerWidget(this);
	}

	ngAfterViewInit() {
		this.initStatusChanges();
	}

	ngOnInit(): void {
		this.getFormSettings();
	}

	getWidget(name: string): ControlWidget<T> {
		return this.formAgent.findWidget(name);
	}

	showErrorStatus() {
		this.errorMessages = getErrorMessages(this.control, this.propertyAnnotation);
	}

	updateDependantLookups() {
		this.formAgent.updateDependantLookups(<string>this.propertyAnnotation.id);
	}

	formatValue() {

	}

	clearData() {
		this.control.setValue(null);
	}

	/**
	 * Setup a listener for the control status changes when the user manipulates the field
	 */
	protected initStatusChanges() {
		const control = this.control;
		control.statusChanges.subscribe(() => {
			this.showErrorStatus();
		});
		control.valueChanges.subscribe(() => {
			// only if no errors  update the the dependent lookups
			if (isNullOrUndefined(this.control.errors) && !this.control.pristine) {
				this.updateDependantLookups();
				this.notifyDependantFields();
			}
		});
	}

	/* There are already so many methods of settting readonly state, no need for another one --jv
	 set readOnly(value: boolean) {
	 this._readOnly = value;
	 }
	 */

	/**
	 * Cached widget readonly state
	 */
	private _readOnly: boolean;

	/**
	 * Cached form settings
	 */
	private formSettings: FormSettings;

	private getFormSettings() {
		this.formSettings = this.formAgent.getFormSettings();

		// Readonly at Form level should make all fields readonly.
		(this.formSettings.readOnly || this.layout.readOnly || this.propertyAnnotation.readOnly || false) ? this.disableControl() : noop();
	}

	private getSetting(prop: keyof FormSettings) {
		if (isNullOrUndefined(this.formSettings)) {
			LoggerUtil.error(`No form settings defined on widget: ${this.propertyAnnotation.id}`, true);
			return new FormSettings({});
		}
		return getPropertyOf(this.formSettings, prop);

	}

	/** Disables control and set field to readonly */
	private disableControl() {
		this._readOnly = true;

		// Only disable control if we have one.
		if (!this.formSettings.readOnlyAsText)
			this.control.disable();
	}

	private notifyDependantFields() {
		this.formAgent.notifyDependantFields(this.propertyAnnotation.id);
	}
}
