import {NgZone} from '@angular/core';
import {Observable, OperatorFunction} from 'rxjs';

export function getTransitionDurationMs(element: HTMLElement) {
  const {transitionDelay, transitionDuration} = window.getComputedStyle(element);
  const transitionDelaySec = parseFloat(transitionDelay);
  const transitionDurationSec = parseFloat(transitionDuration);

  return (transitionDelaySec + transitionDurationSec) * 1000;
}

/**
 * Force a browser reflow
 * @param element element where to apply the reflow
 */
 export function reflow(element: HTMLElement) {
  return (element || document.body).getBoundingClientRect();
}

/**
 * Creates an observable where all callbacks are executed inside a given zone
 *
 * @param zone
 */
export function runInZone<T>(zone: NgZone): OperatorFunction<T, T> {
  return (source) => {
    return new Observable(observer => {
      const next = (value: T) => zone.run(() => observer.next(value));
      const error = (e: any) => zone.run(() => observer.error(e));
      const complete = () => zone.run(() => observer.complete());
      return source.subscribe({next, error, complete});
    });
  };
}
