import { NgModule }             from '@angular/core';
import { CommonModule }         from '@angular/common';
import { ChartLegendComponent } from './chart-legend.component';
import { MatTooltipModule }     from '@angular/material/tooltip';

@NgModule({
	declarations: [ChartLegendComponent],
	exports:      [
		ChartLegendComponent
	],
	imports:      [
		CommonModule,
		MatTooltipModule
	]
})
export class ChartLegendModule {
}
