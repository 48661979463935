<ng-template #tooltiptpl let-percentage="percentage" let-value="value">
	<div>{{value}} ({{percentage}}%) <span>📌</span></div>
</ng-template>
<div [ngStyle]="style" [class]="styleClass"
	 [ngClass]="{'cs-slider':true,'cs-state-disabled':disabled,'cs-slider-horizontal':orientation == 'horizontal','cs-slider-vertical':orientation == 'vertical','cs-slider-animate':animate}"
	 (click)="onBarClick($event)">
	<span *ngIf="showPercentageLabels" class="cs-left-percentage">{{handleValue | format:'{0:N0}' }} %</span>
	<span *ngIf="showPercentageLabels" class="cs-right-percentage">{{100 - handleValue | format:'{0:N0}'}} %</span>
	<span *ngIf="!range"
		  #sliderHandle
		  class="cs-slider-handle" #popover="ngbPopover"
		  [ngbPopover]="tt" triggers="manual" [placement]="orientation == 'vertical' ? 'right' : 'top'"
		  [ngClass]="{'cs-slider-handle-active':dragging}"
		  [style.transition]="dragging ? 'none': null"
		  [ngStyle]="{'left': orientation == 'horizontal' ? handleValue + '%' : null,'bottom': orientation == 'vertical' ? handleValue + '%' : null}">
            </span>
	<span *ngIf="range" class="cs-slider-range"
		  [ngStyle]="{'left':handleValues[0] + '%',width: (handleValues[1] - handleValues[0] + '%')}"></span>
	<span *ngIf="orientation=='vertical'" class="cs-slider-range cs-slider-range-min"
		  [ngStyle]="{'height': handleValue + '%'}"></span>
	<span *ngIf="orientation=='horizontal'" class="cs-slider-range" [ngStyle]="{'width': handleValue + '%'}"></span>
	<span *ngIf="range" (mousedown)="onMouseDown($event,0)" [style.transition]="dragging ? 'none': null"
		  class="cs-slider-handle" [ngStyle]="{'left':handleValues[0] + '%'}"
		  [ngClass]="{'cs-slider-handle-active':handleIndex==0}">
            </span>
	<ng-template #tt>
		<ng-template [ngTemplateOutlet]="tooltipTemplate || tooltiptpl"
					 [ngTemplateOutletContext]="{percentage: handleValue, value: value}"></ng-template>
	</ng-template>
	<span *ngIf="range" (mousedown)="onMouseDown($event,1)" [style.transition]="dragging ? 'none': null"
		  class="cs-slider-handle" [ngStyle]="{'left':handleValues[1] + '%'}"
		  [ngClass]="{'cs-slider-handle-active':handleIndex==1}">
            </span>
</div>
