import { hasPropertyOf }     from '@cs/core/utils';
import { isNullOrUndefined } from '@cs/core/utils';
import { PageAlert }         from './page-alert.model';


export class LayoutAnnotation<T> {

	/**
	 * List of @Link(PageAlert) so the page can show alerts
	 */
	alerts?: PageAlert[];
	constructor(layoutObject: Partial<LayoutAnnotation<T>>) {
		this.alerts = !isNullOrUndefined(layoutObject) && hasPropertyOf(layoutObject, 'alerts')
			? layoutObject.alerts.map(l => new PageAlert(l.message, l.title)) : [];
	}

}
