import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component, ContentChild,
	ElementRef,
	EventEmitter,
	forwardRef,
	Injector,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChildren
}                                       from '@angular/core';
import { MatDialog }                    from '@angular/material/dialog';
import { LoggerUtil }                   from '@cs/core/utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService }             from '@ngx-translate/core';

import * as numeral_                   from 'numeral';
import { merge, Subscription }         from 'rxjs';
import {
	generateQuickGuid, IProperty, isBoolean, isNull,
	isNullOrUndefined, isString, isUndefined
}                                      from '@cs/core';
import { ConfirmationDialogComponent } from '@cs/components/dialogs';
import { ClickOutsideDirective }       from '@cs/components/shared';
import { LookupAgent }                 from '@cs/components/shared';
import {
	Logger, createToObjectWithLowerCaseKeys, filter,
	isNumberValue, stringFormatToNumber
}                                      from '@cs/components/util';

import { SpinnerChangedEventArgs }                              from '@cs/components/spinner';
import { SheetActions }                                         from './classes/data-grid-classes';
import { DataGridFormatAction, DataGridLookupAction }           from './classes/data-grid-action';
import { GridTypeAware }                                        from './decorators/grid-type-aware.decorator';
import {
	CellClickedType, DataGridCellType, GridActions,
	RowState, SortItemType, SortOrder
}                                                               from './enums/data-grid.enum';
import {
	CellActionClickEventArgs,
	CellClickEventArgs,
	CellEditedEventArgs,
	ExpansionCollapsedEventArgs,
	RowButtonClickEventArgs,
	RowClickEventArgs,
	SheetAction,
	SheetActionEventArgs
}                                                               from './event-args';
import { ICellBehaviourParams, IChoiceSets }                    from './interfaces';
import { GridSheet }                                            from './models/grid-sheet.model';
import { GridDataCell, GridDataRow, GridGroup, GridHeaderCell } from './models';


import { DataGridMessageHubService }          from './services';
import { DataGridRuleEnforcer }               from './utils/data-grid-rule-enforcer';
import { DataGridHelpers }                    from './utils/data-grid-helpers';
import { DataGridElementFactory }             from './utils/data-grid-element.factory';
import { CsGridDataTdComponent }              from './components/grid-data-td.component';
import { GridOptions }                        from './classes/grid-options';
import { DataGridSortItem }                   from './classes/data-grid-sort-item';
import { RowButton }                          from './classes/row-button';
import { FormatProviderService, SafeMethods } from '@cs/common';
import { ThresholdData }                      from './classes/ThresholdData';
import { CsDataGridParentService }            from './services/cs-data-grid-parent.service';


const numeral: any = numeral_;


@GridTypeAware
@UntilDestroy()
@Component({
												selector:        'cs-data-grid',
												templateUrl:     'data-grid.component.html',
												styles:          [
													`
														.read-only-cell::after {
															background-color: inherit !important;
														}
													`
												],
												changeDetection: ChangeDetectionStrategy.OnPush,
												providers:       [CsDataGridParentService]
											})
export class CsDataGrid implements OnInit,
																																			OnChanges,
																																			OnDestroy {

	private static getValue(row: GridDataRow, sortItem: DataGridSortItem) {
		let value;
		try {
			switch (sortItem.sortBy) {
				case SortItemType.CellKey:
					value = row.values[sortItem.columnIndex].keys[sortItem.key];
					break;
				case SortItemType.Label:
					value = row.values[sortItem.columnIndex].properties.label;
					break;
				case SortItemType.LabelMin:
					value = row.values[sortItem.columnIndex].properties.labelMin;
					break;
				case SortItemType.SortIndex:
					value = row.values[sortItem.columnIndex].properties.sortIndex;
					break;
				case SortItemType.DisplayValue:
				default:
					value = row.values[sortItem.columnIndex].displayValue;
					break;
			}
		} catch (e) {
			value = row.values[sortItem.columnIndex].displayValue;
			Logger.Warning(sortItem.sortBy + ' is not found while sorting');
		}
		return value;
	}

	/**
		* Sort rows based on the column with columnkeys
		*/
	static sortRows(columnkeys: any = {}, sortOrder: SortOrder = SortOrder.desc,
																	sheets: GridSheet[], options: GridOptions, sortItem: DataGridSortItem): number {

		let columnIndex: number;
		for (const sheet of sheets) {
			for (const group of sheet.groups) {

				// column index contains the same keys as the columnkeys
				if (!isNullOrUndefined(group.columsRows) && group.dataRows.length) {

					// it appears that we need to ignore some type of cells, s.a. checkboxes
					const ignoreIndices = [];
					for (let i = 0; i < group.dataRows[0].values.length; i++) {
						if (group.dataRows[0].values[i].cellType === DataGridCellType.Checkbox) {
							ignoreIndices.push(i);
						}
					}

					const rowKeys = CsDataGrid.getRowTreeKeys(options);
					columnIndex   = DataGridHelpers.findColumnIndexByKeys(columnkeys,
																																																											group.columsRows[group.columsRows.length - 1], rowKeys,
																																																											ignoreIndices, options);

					// Debug fashizzle
					console.log(`sort index: ${columnIndex}, order: ${sortOrder}`);
					// console.log(JSON.stringify(columnkeys));
					// const list = group.dataRows[0].values.reduce( (a, b) => a.concat([b.value]), []); console.log(list);
					// group.dataRows[0].values.forEach(x => console.log(JSON.stringify((x.keys)) + ' ' + x.value));

					// define the sort algorithm (lambda functions not allowed in static functions)
					const sortFunction = function (a, b): number {
						// Keep Total row at the top
						if (a.rowState === RowState.Total)
							return -1;
						if (b.rowState === RowState.Total)
							return 1;

						// keep expanded rows below their parent
						if (a.rowState === RowState.Expanded) {
							return 1;
						}
						if (b.rowState === RowState.Expanded) {
							return -1;
						}

						// const A = a.values[columnIndex];
						// const B = b.values[columnIndex];
						sortItem.columnIndex = columnIndex;
						let A                = CsDataGrid.getValue(a, sortItem);
						let B                = CsDataGrid.getValue(b, sortItem);

						if (isNullOrUndefined(A))
							return -1 * sortOrder;
						if (isNullOrUndefined(B))
							return 1 * sortOrder;

						const tempA = isNumberValue(A);
						const tempB = isNumberValue(B);

						if (tempA !== false)
							A = tempA;

						if (tempB !== false)
							B = tempB;

						if (isString(A) || isString(B)) {
							// string sort
							if (A < B)
								return -1 * sortOrder;
							if (A > B)
								return 1 * sortOrder;
							return 0;
						}

						// numeric sort (default)
						return (A - B) * sortOrder;
					};

					// Sort values
					if (columnIndex >= 0) {
						group.dataRows.sort(sortFunction);

						// match the expanded rows to their expanded counterparts
						for (let i = 0; i < group.dataRows.length; i++) {
							if (group.dataRows[i].isExpanded) {
								let expandIndex = -1;
								for (let j = 0; j < group.dataRows.length; j++) {
									const x = group.dataRows[j];
									if (x.rowState === RowState.Expanded && x.expansion.parentRow === group.dataRows[i]) {
										expandIndex = j;
										break;
									}
								}
								if (expandIndex !== -1) {
									const tempref = group.dataRows.splice(expandIndex, 1);
									group.dataRows.splice(i + 1, 0, tempref[0]);
									i++;
								}
							}
						}
					}
				}
			}
		}
		return columnIndex;
	}


	/**
		* Return keys associated with grid structure rows.
		* note: function is not definitive as this information is located in various places.
		*/
	public static getRowTreeKeys(options: GridOptions): string[] {
		const keys: string[] = [];
		if (!isNullOrUndefined(options.structureData.dimensionTrees.rowTree.memberTree.key)) {
			const key = options.structureData.dimensionTrees.rowTree.memberTree.key.toLowerCase();
			keys.push(key);
		}
		if (!isNullOrUndefined(options.choiceSets.rowSets) && options.choiceSets.rowSets.length) {
			for (const x of options.choiceSets.rowSets) {
				keys.push(x.name.toLowerCase());
			}
		}
		return keys;
	}

	/**
		* Return keys associated with grid structure columns
		* note: function is not definitive as this information is located in various places.
		*/
	public static getColumnTreeKeys(options: GridOptions): string[] {
		const keys: string[] = [];
		if (!isNullOrUndefined(options.structureData.dimensionTrees.columnTree.memberTree.key)) {
			const key = options.structureData.dimensionTrees.columnTree.memberTree.key.toLowerCase();
			keys.push(key);
		}
		if (!isNullOrUndefined(options.choiceSets.columnSets) && options.choiceSets.columnSets.length) {
			for (const x of options.choiceSets.columnSets) {
				keys.push(x.name.toLowerCase());
			}
		}
		return keys;
	}

	/**
		* Sorts the first Total column, when available
		*/
	public static executeDefaultSortRows(sheets: GridSheet[], options: GridOptions) {
		// find total column
		const defaultHeaders: {
			sheet: GridSheet,
			th: GridHeaderCell
		}[] = [];
		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				const defaultHeaderFound = group.columsRows[group.columsRows.length - 1].columns.find(
					th => th.headerSortItem.isDefaultSortOrder);
				if (!isNullOrUndefined(defaultHeaderFound)) {
					defaultHeaders.push({sheet: sheet, th: defaultHeaderFound});
				}
			}
		}

		if (!options.disableSorting || defaultHeaders.length > 0) {
			for (const found of defaultHeaders) {
				const sortKeys = found.th.keys;
				CsDataGrid.sortRows(sortKeys, found.th.headerSortItem.sortOrder === 'desc'
																																		? -1
																																		: 1,
																								[found.sheet], options, found.th.headerSortItem);

			}
		} else {
			Logger.Warning('default sort: Could not find default sort column');
		}

	}

	/**
		* Store for all the generated Datagrid cells
		*/
	@ViewChildren(forwardRef(() => CsGridDataTdComponent)) gridCells: QueryList<CsGridDataTdComponent>;

	/**
		* Refrence to all table body rows
		*/
	@ViewChildren('tablerow', {read: ElementRef}) tableRows: QueryList<ElementRef>;


	/**
		* Show the cells as forms
		*/
	@Input() renderAsForm = false;

	/**
		* Store the parent row when nested
		*/
	@Input() parentRow: GridDataRow;

	/**
		* List of sheets the data-grid uses to show the data on the screen
		*/
	@Input() sheets: Array<GridSheet>;
	/**
		* Specify options for rendering the grid
		*/
	@Input() options: GridOptions;

	/**
		* Set to true if empty rows should be filled with zero values

		*/
	@Input() addZeroToEmptyRow                   = false;
	/**
		* Add overrides for the default sheet actions, like Add and cancel

		*/
	@Input() sheetActionsOverrides: SheetActions = new SheetActions();

	/**
		* Flag indicating if a loader should be shown
		*/
	@Input() isLoadingNewContent = true;

	/**
		* Dataset containing (aggregated) threshold values for showing user warnings during/after data entry
		*/
	@Input() dataEntryThresholdData: ThresholdData = new ThresholdData();

	/**
		* Event that fires when a user edits a cell

		*/
	@Output() onCellsEdited = new EventEmitter<CellEditedEventArgs>();

	/**
		* Event that fires when a sheet action is clicked

		*/
	@Output() onSheetActionClicked = new EventEmitter<SheetActionEventArgs>();

	/**
		* Event that fires when a row is clicked

		*/
	@Output() onRowClicked = new EventEmitter<RowClickEventArgs>();

	/**
		* When the Grid has checkboxes enabled this event will fire when a row is selected.
		* Currently only one row can be selected

		*/
	@Output() onRowSelected = new EventEmitter<RowClickEventArgs>();
	/**
		* When a new row is add to the grid, fire this event

		*/
	@Output() onRowAdded    = new EventEmitter<RowClickEventArgs>();

	/**
		* When the row has a row menu than this event will fire when the user clicks on a menu item

		*/
	@Output() onRowButtonClicked = new EventEmitter<RowButtonClickEventArgs>();

	/**
		* Event fires when a cell has a @Link(CellBehavior) attached.
		* This CellBehavior indicates if a navigation action is required when clicking on the label column

		*/
	@Output() onNavigationRequested = new EventEmitter<CellClickEventArgs>();


	/**
		* Event fires when the cell has a click event that is other than the default edit behavior

		*/
	@Output() requestingCellActionOnClick = new EventEmitter<CellActionClickEventArgs>();

	/**
		* Event that fires when a cell has invalid input

		*/
	@Output() invalidInput = new EventEmitter<CellEditedEventArgs>();

	/**
		* Emits the parent row when the expansion is collapsed

		*/
	@Output() onExpansionCollapsed = new EventEmitter<ExpansionCollapsedEventArgs>();

	/**
		* Property is used for the colSpan
		*/
	columnSpanLastRow: number;

	/**
		* Generate a unique id for the grid

		*/
	id                   = generateQuickGuid();
	sortOrder: SortOrder = SortOrder.desc;
	sortColumn: GridHeaderCell;

	// calculateSheets(sheets: Array<GridSheet>) {
	//   for (const sheet of sheets) {
	//     sheet.calculator.calculateAll();
	//   }
	// }
	@ContentChild('headerFull') hasMergedHeader: ElementRef;

	// Getter and Setter to avoid undifined variable for parentService
	public get _resolvedMemberList(): Map<string, Array<IProperty>> {
		return this.resolvedMemberList;
	}

	public set _resolvedMemberList(value: Map<string, Array<IProperty>>) {
		this.resolvedMemberList = value;
		if (!isNullOrUndefined(this.parentService))
			this.parentService._resolvedMemberList = this.resolvedMemberList;
	}

	constructor(private gridHub: DataGridMessageHubService,
													private elementRef: ElementRef,
													public changeRef: ChangeDetectorRef,
													public dialog: MatDialog,
													private formatService: FormatProviderService,
													private parentService: CsDataGridParentService,
													private i8n: TranslateService,
													private injector: Injector) {

	}

	trackByIdentity = (index: number, item: {
		key: string,
		width: string
	}) => item.key;

	genericCellClickHandler($event: MouseEvent): void {

		if (this.options.isNested) {
			$event.cancelBubble = true;
			$event.preventDefault();
			$event.stopImmediatePropagation();
		}
		const tdId = this.getFirstId(<HTMLElement>$event.target);
		if (!Logger.hasValue(tdId, `id: ${tdId} not found for clicked cell`, true) ||
			tdId.startsWith('th_')) {
			return;
		}

		const foundTD = this.findCellTdById(tdId);

		if (!Logger.hasValue(foundTD, `id: ${tdId} could not match CellTdComponent for property foundTD`, true)) {
			return;
		}

		this.cellClicked($event, foundTD.cell, foundTD.row, foundTD.group, foundTD.sheet);

	}


	getFirstId(target: HTMLElement) {
		let currentElem = target;

		while (currentElem) {
			if (isNullOrUndefined(currentElem)
				|| (!isNullOrUndefined(currentElem.id)
					&& currentElem.id !== ''
					&& currentElem.tagName.toLowerCase() === 'td')) {
				break;
			}
			currentElem = currentElem.parentElement;
		}

		return isNullOrUndefined(currentElem)
									? null
									: currentElem.id;
	}

	getDomPath(target) {
		const path      = [];
		let currentElem = target;
		while (currentElem) {
			path.push(currentElem);
			currentElem = currentElem.parentElement;
		}
		if (path.indexOf(window) === -1 && path.indexOf(document) === -1)
			path.push(document);
		if (path.indexOf(window) === -1)
			path.push(window);
		return path;
	}

	getExpandedRows(): GridDataRow[] {
		const expandedRows = [];
		for (const sheet of this.sheets) {
			for (const group of sheet.groups) {
				const found = group.dataRows.filter(row => row.isExpanded);
				expandedRows.push(...found);
			}
		}
		return expandedRows;
	}

	//#region Angular Lifecycle Hooks
	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('options')
			&& !isNullOrUndefined(this.options)) {
			// Set here after options is defined
			this.parentService.options = this.options;

			this.updateMemberLookupList(this.options.memberLists, this.options.choiceSets);
			this.options.dataKeyParts = this.options.dataKeyParts.map(x => x.toLowerCase());
			this.options.baseKeys     = createToObjectWithLowerCaseKeys(this.options.baseKeys);
			this.cleanChangedCells();
		}
		if (changes.hasOwnProperty('sheets')) {
			this.columnSpanLastRow = this.setLastRowColumnSpan();
			this.hasGridData();
		}
	}

	ngOnInit() {

		this.parentService.cellInputChanged =
			(cell, row, group, sheet, $event) => this.cellInputChanged(cell, row, group, sheet, $event);
		this.parentService.selectionChanged =
			(cell, row, group, sheet, $event) => this.selectionChanged(cell, row, group, sheet, $event);
		this.parentService.checkForTabPress =
			(cell, row, group, sheet, index, $event) => this.checkForTabPress(cell, row, group, sheet, index, $event);
		this.parentService.updateRowValues  =
			(cell, row, group, sheet, $event) => this.updateRowValues(cell, row, group, sheet, $event);

		this.parentService.rowSelected      = rowClickEventArgs => this.rowSelected(rowClickEventArgs);
		this.parentService.rowButtonClicked = (cell, row, group, sheet) => this.rowButtonClicked(cell, row, group, sheet);

		this.parentService.onCellsEdited               = this.onCellsEdited;
		this.parentService.onSheetActionClicked        = this.onSheetActionClicked;
		this.parentService.requestingCellActionOnClick = this.requestingCellActionOnClick;
		this.parentService.onNavigationRequested       = this.onNavigationRequested;
		this.parentService.onRowAdded                  = this.onRowAdded;
		this.parentService.onRowSelected               = this.onRowSelected;
		this.parentService.onRowButtonClicked          = this.onRowButtonClicked;
		this.parentService.findCellTdById              = id1 => this.findCellTdById(id1);

		if (!isNullOrUndefined(this.options) && this.options.isNested) {
			this.gridHub.register(this);
		}
	}

	ngAfterViewInit(): void {
		if (!isNullOrUndefined(this.options) && this.options.isNested) {
			this.runChangeDetection = this.gridHub.runChangeDetection.subscribe(() => this.onRunChangeDetection(''));
		}
	}


	ngOnDestroy() {
		if (!isNullOrUndefined(this.runChangeDetection))
			this.runChangeDetection.unsubscribe();
		if (!isNullOrUndefined(this.options) && this.options.isNested) {
			this.gridHub.unregister(this);
		}
	}

	//#endregion

	//#region Component API

	getClientRect(): ClientRect {
		return this.elementRef.nativeElement.getBoundingClientRect();
	}

	updateCells(type: DataGridCellType) {
		this.gridCells.toArray()
						.forEach(cell => {
							if (cell.cell.cellType === type || type === DataGridCellType.All)
								cell.markForCheck();
						});
	}

	findCellTdById(id: string) {
		const cells = this.gridCells.toArray();
		return cells.find(c => c.cell.id === id);
	}

	/**
		* Clean all compare background and remove the data from the compare property on the @link(GridDataCell)
		*/
	cleanCompare() {

		const sheets = [...this.sheets];

		const dataGrids = this.getNestedDataGrids();
		for (const grid of dataGrids) {
			sheets.push(...grid.sheets);
		}

		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (let i = 0; i < group.dataRows.length; i++) {
					const row = group.dataRows[i];
					row.values.forEach(x => {
																									x.cleanCell();
																								}
					);
				}
			}

			DataGridRuleEnforcer.executeDynamicRules(sheet);
		}

		for (const grid of dataGrids) {
			grid.detectChanges();
		}

		this.detectChanges();
	}

	/**
		* Set the dataGrid to a prestine state. All changes are now the original values and every next change will make the grid dirty again
		* @param sheet the sheet we want to set the state
		*/
	freezeData(sheet: GridSheet) {
		let sheets: GridSheet[] = [];
		if (isNullOrUndefined(sheet))
			sheets = this.sheets;
		else
			sheets = [sheet];

		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (const row of group.dataRows) {
					row.values.forEach(x => {
						if (!x.cellState.readonly && x.cellUIState.dirty) {
							x.originalValue = x.value;
							x.updateValue(x.originalValue || '');
							x.formatValue();
							x.cellState.readonly                  = true;
							x.cellUIState.dirty                   = false;
							x.metaValues.thresholdValueOutOfRange = null;
						}
						if (x.cellState.editable && x.cellUIState.hasReadOnlyVersion) {
							x.cellState.editable = false;
						}
					});
					// set row to default state
					if (row.rowState === RowState.New) {
						row.rowState       = RowState.Default;
						// set the keys for the row
						const keysByRowSet = DataGridHelpers.findKeysByRowSet(row, [sheet], this.options.choiceSets.rowSets);
						row.keys           = Object.assign(row.keys, keysByRowSet);
						// give a id based on the keys
						row.id             = DataGridHelpers.createKeysString(row.keys);
						// add buttons to the newly created rows
						if (!row.isGroup && this.options.rowButtons) {
							row.buttons = this.options.rowButtons.map(btn => Object.assign({}, btn));
						}
						// add new total and offset to calculation cells
						for (const row of group.dataRows) {
							row.values.forEach(cell => {
								// Add calculation cell to the calculator
								if (cell.cellType === DataGridCellType.Total ||
									cell.cellType === DataGridCellType.Offset)
									sheet.calculator.addCalculationCell(cell);
								if (cell.cellType === DataGridCellType.RowMenu) {
									cell.cellState.readonly = false;
									cell.cellState.editable = true;
								}
							});
						}
					}
					if (row.rowState === RowState.Spinner) {
						row.disableSpinnerRow();
						row.selected = false;
					}
				}
			}
			DataGridRuleEnforcer.executeRules(this.options.rules, sheet, false);
			DataGridRuleEnforcer.executeDynamicRules(sheet);
			sheet.calculator.calculateAll()
								.subscribe(value => {
									this.updateCells(DataGridCellType.Data);
									this.updateCells(DataGridCellType.Total);
									this.updateCells(DataGridCellType.Offset);
								});

		}
		this.cleanChangedCells();

	}

	/**
		* Clean the changed Cells store
		*/
	cleanChangedCells(): void {
		this.changedCells.clear();
	}

	/**
		* Loop over the cells of the given row and set the input state
		* @param row The row on which the input state must be set
		* @param isEditable State of the input
		*/
	setRowEditable(row: GridDataRow, isEditable: boolean) {
		row.values.forEach(x => {
			if (x.cellType !== DataGridCellType.RowMenu &&
				x.cellType !== DataGridCellType.Checkbox
				&& x.cellState.editable !== isEditable
				&& x.cellUIState.hasPopoverClick !== CellClickedType.Edit
				&& x.cellUIState.hasPopoverClick !== CellClickedType.SliderEdit
				&& !x.cellState.readonly) {
				// if not editable formatValue to latest value
				if (!isEditable)
					x.formatValue();

				if (x.cellUIState.hasReadOnlyVersion)
					x.cellState.editable = isEditable;
			}
		});
	}

	/**
		* Modify the editMode of the cell when it's allowed the cell will change modes
		* @param cell The cell on which the input state must be set
		* @param isEditable State of the input
		*/
	setCellEditable(cell: GridDataCell, isEditable: boolean) {
		const dummyRow = new GridDataRow([cell]);
		this.setRowEditable(dummyRow, isEditable);
	}

	/**
		* Set all the cells in the given editable state
		* @param sheet Sheet we apply the state to
		* @param isEditable The state that should be applied
		*/
	setSheetEditable(sheet: GridSheet, isEditable: boolean) {
		for (const group of sheet.groups) {
			for (const row of group.dataRows) {
				this.setRowEditable(row, isEditable);
			}
		}
	}

	/**
		* Set all the cells in the given editable state (note: not the same as read only).
		* @param sheets Sheet we apply the state to
		* @param isEditable The state that should be applied
		*/
	setSheetsEditable(sheets: GridSheet[], isEditable: boolean) {

		if (isNullOrUndefined(sheets))
			sheets = this.sheets;

		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				for (const row of group.dataRows) {
					this.setRowEditable(row, isEditable);
				}
			}
		}

	}

	/**
		* Undo all the changes and revert back to the previous edit disabled state
		* @param sheet Sheet we apply the state to
		* @param forceAll Force that the reset is applied to all cells
		*/
	resetAllChanges(pSheet?: GridSheet, forceAll = false) {

		let sheets: GridSheet[] = [];
		if (isNullOrUndefined(pSheet))
			sheets = this.sheets;
		else
			sheets = [pSheet];

		for (const sheet of sheets) {
			for (const group of sheet.groups) {
				const markedForRemoval = [];

				for (let i = 0; i < group.dataRows.length; i++) {
					const row = group.dataRows[i];

					// Check row for removal when is new and not saved
					if (row.rowState === RowState.New) {
						markedForRemoval.push(row.id);
						continue;
					}

					if (row.rowState === RowState.Spinner) {
						row.selected = false;
						row.disableSpinnerRow();
					}
					row.disabled = false;

					row.values.forEach(x => {
						if ((x.cellType === DataGridCellType.Data || x.cellType === DataGridCellType.Injected)
							&& !x.cellState.readonly && (forceAll || x.cellUIState.dirty)) {
							x.updateValue(isNullOrUndefined(x.originalValue)
																					? ''
																					: x.originalValue);
							x.formatValue();
							x.cellUIState.dirty   = false;
							x.cellUIState.invalid = false;
						}
					});
				}

				// remove the new rows that haven't been saved
				for (const id of markedForRemoval) {
					const index = group.dataRows.findIndex(x => x.id === id);
					group.dataRows.splice(index, 1);
				}

			}
			this.setSheetEditable(sheet, false);
		}
		this.cleanChangedCells();

		this.updateCells(DataGridCellType.All);
		this.updateCells(DataGridCellType.Total);
		this.updateCells(DataGridCellType.Offset);
	}

	//#endregion

	//#region EventHandlers

	// Helper function to check if a value is empty
	private isEmpty(value: any): boolean {
		return value === null || value === undefined || value === '';
	}

	private validateCellValue(cell: any, value: any): {
		isValid: boolean,
		error?: any
	} {
		if (isNaN(value)) {
			return {isValid: false, error: {actual: value, validator: {errorMessage: this.i8n.instant('MESSAGE.VALUE_IS_NAN')}}};
		}

		if (!cell.cellState.allowNegative && value < 0) {
			return {isValid: false, error: {actual: value, validator: {errorMessage: this.i8n.instant('MESSAGE.NEGATIVE_VALUE_NOT_ALLOWED')}}};
		}

		if (!cell.cellState.allowPositive && value > 0) {
			return {isValid: false, error: {actual: value, validator: {errorMessage: this.i8n.instant('MESSAGE.POSITIVE_VALUE_NOT_ALLOWED')}}};
		}

		if (cell.cellState.allowMin != null && value < cell.cellState.allowMin) {
			return {isValid: false, error: {actual: value, gte: cell.cellState.allowMin, validator: {errorMessage: this.i8n.instant('MESSAGE.VALUE_SHOULD_BE_GREATER_OR_EQUAL')}}};
		}

		if (cell.cellState.allowMax != null && value > cell.cellState.allowMax) {
			return {
				isValid: false,
				error:   {actual: value, lte: cell.cellState.allowMax, validator: {errorMessage: this.i8n.instant('MESSAGE.VALUE_SHOULD_BE_LESS_OR_EQUAL"')}}
			};
		}

		return {isValid: true};
	}

// Main validation function
	private validateAndSetCellValue(cell: GridDataCell, field: HTMLInputElement, unFormatted: string) {
		// Exploding the first if statement
		const isFieldValueEmpty        = this.isEmpty(field.value);
		const isAllowEmptyValuesToSave = cell.cellUIState.allowEmptyValuesToSave;
		const isFieldNotBoolean        = !isBoolean(field.value);

		if (isFieldNotBoolean && !(isFieldValueEmpty && isAllowEmptyValuesToSave)) {
			const value = unFormatted;

			const validation = this.validateCellValue(cell, value);

			if (!validation.isValid) {
				cell.cellUIState.invalid = true;
				cell.setErrorState([validation.error]);
				this.invalidInput.emit(new CellEditedEventArgs(cell));
				return;
			}

			if (!cell.validateCell()) {
				cell.cellUIState.invalid = true;
				this.invalidInput.emit(new CellEditedEventArgs(cell));
				return;
			}

			cell.cellUIState.invalid = false;
			field.value              = unFormatted;
		}
	}


	/**
		* Handler for the changed event on a input field, fires an @Output event and adds the cell to the changed cell Map
		*/
	cellInputChanged(cell: GridDataCell,
																		row: GridDataRow,
																		group: GridGroup,
																		sheet: GridSheet,
																		changeEvent: Event,
																		updateValueAsDataSource = true,
																		useClone                = true,
																		onlyAddToChangeCells    = false) {

		if (this.options.config.hasOwnProperty('saveAsManualDataSource') && !this.options.config.saveAsManualDataSource)
			updateValueAsDataSource = this.options.config.saveAsManualDataSource;

		const cellKeyObject = DataGridHelpers.combineAllKeys(cell, row, group, sheet, this.options);

		// Cast to an input element
		const field = <HTMLInputElement>changeEvent.target;

		// short circuit, don't update value
		if (onlyAddToChangeCells) {
			cell.cellUIState.dirty = true;
			this.resolveDataEntryThresholdForCell(cell, cellKeyObject);
			this.addCellToChangedList(cell, cellKeyObject, updateValueAsDataSource, useClone);
			return;
		}

		const unFormatted = field.value === ''
																						? ''
																						: changeEvent.type === 'change' || isBoolean(field.value)
																								?
																								numeral(field.value)
																									.value()
																								: numeral(field.value)
																									.format(this.checkFormatingForDecimals(field));

		// If value is not changed from current value, skip
		if (!this.checkIfValueIsChanged(cell.value, unFormatted, cell.format))
			return;

		this.validateAndSetCellValue(cell, field, unFormatted);


		// update cell data because we workaround angular for performance wins
		cell.updateValue(unFormatted);
		cell.formatValue();
		cell.setInputStartValue(unFormatted);

		// If value is same as original, remove from changelist
		if (!this.checkIfValueIsChanged(cell.originalValue, field.value, cell.format)) {
			cell.cellUIState.dirty = false;
			this.removeCellFromChangeList(cell, cellKeyObject);
		} else {
			cell.cellUIState.dirty = true;
			this.resolveDataEntryThresholdForCell(cell, cellKeyObject);
			this.addCellToChangedList(cell, cellKeyObject, updateValueAsDataSource, useClone);
		}
	}

	cellClicked(event: MouseEvent, cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet) {

		if (event.altKey) {
			LoggerUtil.log(cell);
			LoggerUtil.log(row);
			LoggerUtil.log(sheet);
			return;
		}

		if (cell.behavior.action !== 'None') {
			if (cell.behavior.action === 'ToggleCellSelection') {
				if (!cell.cellState.isSelected) {
					this.cellInputChanged(cell, row, group, sheet, event, false, false, true);
					cell.cellState.isSelected = true;
				} else {
					const cellKeyObject = DataGridHelpers.combineAllKeys(cell, row, group, sheet, this.options);
					this.removeCellFromChangeList(cell, cellKeyObject);
					cell.cellState.isSelected = false;
				}

			} else if (row.isExpanded) {
				// get confirmation to discard changes in expanded grid
				const expandedGrid  = this.getNestedDataGrids()
																														.find(grid => grid.parentRow === row);
				const hasdirtyCells = !isNullOrUndefined(expandedGrid) && expandedGrid.changedCells.size;
				if (!hasdirtyCells) {
					this.collapseRow(row);
				} else {
					// row contains dirty grid, ask confirmation before closing
					const confirmDialogRef = this.dialog.open(ConfirmationDialogComponent, {
						data: {
							message:          `Expanded row contains unsaved changes.\nDiscard changes?`,
							showOKButton:     true,
							showCancelButton: true
						}
					});

					confirmDialogRef.afterClosed()
																					.subscribe(confirmed => {
																						if (confirmed === true) {
																							expandedGrid.sheets.forEach(expandedsheet => {
																								expandedGrid.resetAllChanges(expandedsheet, true);
																							});
																							this.collapseRow(row);
																							// Emit a cell edit as we have some changes (i.e. discarded values)
																							this.onCellsEdited.emit(new CellEditedEventArgs(null));
																						}
																					});
				}
			} else {
				this.onNavigationRequested.emit(new CellClickEventArgs(cell, row, sheet));
			}

			// Make sure the event will not bubble up to the row click
			event.preventDefault();
			event.stopPropagation();
			event.cancelBubble = true;
		}

		// when cell is not in edit mode
		if (!cell.cellState.editable
			&& (cell.cellUIState.hasPopoverClick === CellClickedType.None || isNullOrUndefined(cell.cellUIState.hasPopoverClick))) {
			// const cellIsEditMode = DataGridHelpers.findCellsInEditMode(this.sheets);
			let clickOutsideSub;
			// turn all cells in edit-mode off
			// cellIsEditMode.forEach(x => x.cellState.editable = false);
			this.setCellEditable(cell, true);
			this.setupClickOutSideHandler(cell, event.target);
		} else if (!cell.cellState.editable && cell.cellUIState.hasPopoverClick !== CellClickedType.None) {
			this.requestingCellActionOnClick.emit(new CellActionClickEventArgs(cell, row, sheet, this.sheets, this));
		} else {
			// This is for now turned off, because of GH nested grid not bubbling up there click event. Found no side-effects for now
			// Found it: side effect is that the datepicker component close() is called immediately
			event.preventDefault();
			event.stopPropagation();
			event.cancelBubble = true;
		}
		this.updateCell([cell]);
		if (event.ctrlKey) {
			Logger.Debug(cell);
			Logger.Debug(row);
		}

	}

	updateRowValues(changedCell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, changeEvent: SpinnerChangedEventArgs) {
		const indexOfChangedCell = row.values.findIndex(x => x.keys === changedCell.keys);
		const updateCells        = [];
		for (let i = indexOfChangedCell; i < row.values.length; i++) {

			const cell = row.values[i];
			if (cell.cellType === DataGridCellType.Data) {
				let val = (isNullOrUndefined(cell.value) || cell.value.toString() === '')
														? 0
														: cell.value;
				val     = isString(val)
														? parseFloat(val.toString())
														: val;

				if (val === NaN || isNullOrUndefined(val)) {
					val = 0;
				}

				const newValue = val + changeEvent.change;

				if (!cell.cellState.allowNegative && newValue < 0) {
					return;
				}
				cell.updateValue(newValue);
				cell.formatValue();
				cell.cellUIState.dirty = true;
				updateCells.push(cell);
				const cellKeyObject = DataGridHelpers.combineAllKeys(cell, row, group, sheet, this.options);

				this.addCellToChangedList(cell, cellKeyObject, true, false, false);
			}
		}
		this.updateCell(updateCells);
		// this.onRunChangeDetection('')
	}


	/**
		* 'Deletes' row by setting all Data cell values to empty
		*/
	deleteRow(row: GridDataRow, group: GridGroup, sheet: GridSheet) {

		const index     = group.dataRows.findIndex(x => x.id === row.id && x.rowState === RowState.Default);
		const datacells = row.values.filter(c => c.cellType === DataGridCellType.Data);
		for (const cell of datacells) {
			if (cell.cellType === DataGridCellType.Data) {
				cell.updateValue('');
				cell.formatValue();
				cell.cellUIState.dirty = true;
				const cellKeyObject    = DataGridHelpers.combineAllKeys(cell, row, group, sheet, this.options);

				// performance issue: don't notify grid of each cell update, only once per row
				this.addCellToChangedList(cell, cell.keys, true, true, false);
			}
		}
		group.dataRows.splice(index, 1);
		this.onCellsEdited.emit(new CellEditedEventArgs(null));
	}

	/**
		* Updates keys of entire row to reflect the new selection
		*/
	selectionChanged(changedCell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, changeEvent: Event,
																		onlyUpdate = false) {

		const originalCellKeyObject = DataGridHelpers.combineAllKeys(changedCell, row, group, sheet, this.options);
		const originalCellKey       = DataGridHelpers.createKeysString(originalCellKeyObject);

		// If value is not changed from data and cell has not been changed before, than ignore the change
		// note: if the cell is already in the changelist then additional changes in this row prevent us to simply revert the
		// changes and forces to treat it as a regular update
		if (!this.checkIfValueIsChanged(changedCell.originalValue, changedCell.value, changedCell.format)
			&& this.changedCells.has(originalCellKey)) {
			return;
		}

		// Injected columns are generally not editable. For exceptions, use first keys property as key.
		if (changedCell.cellType === DataGridCellType.Injected && !changedCell.cellState.readonly && isNullOrUndefined(changedCell.key)) {
			if (!Object.getOwnPropertyNames(changedCell.keys).length)
				Logger.ThrowError(`${changedCell.index} has no keys`);
			changedCell.key = Object.getOwnPropertyNames(changedCell.keys)[0];
		}

		if (!changedCell.key)
			Logger.ThrowError(`${changedCell.properties.label} has no key`);

		// remove old key from changed list
		this.changedCells.delete(originalCellKey);

		// set the keys for the cell based on selected value and the given key
		changedCell.keys[changedCell.key] = changedCell.value;

		// update the row cells with key value after selection changed
		for (const rowCell of row.values) {
			if (rowCell.cellType !== DataGridCellType.Injected) {
				// remove old key from changed list
				const originalRowCellKeyObject = DataGridHelpers.combineAllKeys(rowCell, row, group, sheet, this.options);
				const originalRowCellKey       = DataGridHelpers.createKeysString(originalRowCellKeyObject);
				this.changedCells.delete(originalRowCellKey);
				Object.assign(rowCell.keys, changedCell.keys);
			}
		}

		const cellKeyObject = DataGridHelpers.combineAllKeys(changedCell, row, group, sheet, this.options);

		// update cell data because we workaround angular for performance wins
		changedCell.updateValue();
		if (onlyUpdate)
			return;

		changedCell.cellUIState.dirty = true;

		// only add changed data cells to the changedlist
		if (changedCell.cellType === DataGridCellType.Data)
			this.addCellToChangedList(changedCell, cellKeyObject, false, false, false);

		// add entire row when an injected column key has changed
		if (changedCell.cellType === DataGridCellType.Injected && (row.rowState === RowState.Default || row.rowState === RowState.New)) {
			for (const rowCell of row.values) {
				if (rowCell.cellType === DataGridCellType.Data) {
					const rowCellKeyObject = DataGridHelpers.combineAllKeys(rowCell, row, group, sheet, this.options);
					// rowCell.updateValue();
					// rowCell.cellUIState.dirty = true;
					this.addCellToChangedList(rowCell, rowCellKeyObject, false, false, false);
				}
			}
		}
		// check for duplicate cell keys and force grid update
		this.checkDuplicateCellKeys(group);
		DataGridRuleEnforcer.executeDynamicRules(sheet); // apply css changes
		this.detectChanges();
		this.onCellsEdited.emit(new CellEditedEventArgs(changedCell)); // trigger css updates, change detection and savebar to update
	}

	/**
		* Add the cell to a Map that keeps track of the all the changed cells
		*/
	addCellToChangedList(cell: GridDataCell, cellKeyObject: object, updateValueAsDataSource: boolean = true, useClone = true,
																						notify                                                                                       = true) {
		const cellKey = DataGridHelpers.createKeysString(cellKeyObject);

		// Create a copy of the original cell
		const copy = useClone
															? new GridDataCell(cell, this.formatService)
															: cell;
		// update the keys so all necessary keys are filled
		for (const key of Object.getOwnPropertyNames(cellKeyObject)) {
			if (!isNullOrUndefined(cellKeyObject[key]))
				copy.keys[key] = cellKeyObject[key];
		}

		if (updateValueAsDataSource)
			cell.updateValueAsDataSource(copy);

		this.changedCells.set(cellKey, copy);

		if (notify)
			this.onCellsEdited.emit(new CellEditedEventArgs(cell));
	}

	removeCellFromChangeList(cell: GridDataCell, cellKeyObject: object) {
		const cellKey = DataGridHelpers.createKeysString(cellKeyObject);

		this.changedCells.delete(cellKey);
		cell.resetDirty();
		this.onCellsEdited.emit(new CellEditedEventArgs(cell));
	}


	rowSelected($event: RowClickEventArgs) {
		this.onRowSelected.emit($event);
		this.updateCells(DataGridCellType.All);
		this.onRunChangeDetectionDelayed('');
	}

	rowClicked(row: GridDataRow, group: GridGroup, sheet: GridSheet, changeEvent: MouseEvent) {
		if (row.popoverIsOpen) {
			changeEvent.stopImmediatePropagation();
			return;
		}

		// check if there is a expand on the row
		const found = row.values.find(cell => cell.behavior.action === 'Expand');

		if (!isNullOrUndefined(found)) {
			// if found then check if one of the data cells is not read-only
			const foundNotReadOnly = row.values.find(item => !item.cellState.readonly && item.cellType === DataGridCellType.Data);
			if (isNullOrUndefined(foundNotReadOnly)) {
				// If all the data cells are read-only then we assume the user want to expand the row by clicking anywhere on the cell
				this.cellClicked(changeEvent, found, row, group, sheet);
			}
		}

		this.onRowClicked.emit(new RowClickEventArgs(row, sheet));
	}

	/**
		* Handle the sheetaction clicks for the table-header
		*/
	sheetActionClicked(id: number, sheet: GridSheet, actionParams: any = null) {
		let eventType;
		let newRow = null;
		const grid = this;
		switch (id) {
			case 0:
				eventType = SheetAction.Save;
				break;
			case 1:
				eventType = SheetAction.Cancel;
				if (isNullOrUndefined(this.sheetActionsOverrides.cancelAction))
					this.resetAllChanges(sheet, true);
				else
					this.sheetActionsOverrides.cancelAction(sheet);
				break;
			case 2:
				eventType = SheetAction.Add;
				if (isNullOrUndefined(this.sheetActionsOverrides.addAction)) {
					if (this.options.useDataSourceValueAsKey.findIndex(key => key.toLowerCase() === 'iddatasource')) {
						// assume new empty rows have only manual datasources
						newRow = this.addEmptyRow(sheet, undefined, {'iddatasource': 1});
					} else {
						newRow = this.addEmptyRow(sheet);
					}
				} else {
					this.sheetActionsOverrides.addAction(sheet);
				}
				break;
			case 3:
				eventType = SheetAction.Custom;
				break;
		}

		this.onSheetActionClicked.emit(new SheetActionEventArgs(eventType, grid, sheet, newRow, actionParams));
	}

	//#endregion

	/**
		* Provides the developer with all the nested grid as an array

		*/
	getNestedDataGrids(): CsDataGrid[] {
		return this.gridHub.allRegisteredNestedGrid.filter(grid => grid.options.parentGridId === this.id);
	}

	/**
		* Returns the current datagrid and all nested grids

		*/
	getAllDataGrids(): CsDataGrid[] {
		return [this, ...this.getNestedDataGrids()];
	}

	getChangedCells() {
		return this.changedCells;
	}

	getKeysString(cell: GridDataCell | GridHeaderCell) {
		if (isNullOrUndefined(cell)) {
			return;
		}
		return isNullOrUndefined(cell.keys)
									? `{key:${cell.key}`
									: JSON.stringify(cell.keys);
	}

	getTotalAmountOfRows() {
		if (isNullOrUndefined(this.sheets))
			return 0;

		let nrOfRows = 0;
		for (const sheet of this.sheets) {
			if (sheet.groups.length === 0)
				continue;

			for (const group of sheet.groups) {
				nrOfRows += group.dataRows
																? group.dataRows.filter(r => r.rowState === RowState.Default).length
																: 0;
			}

		}

		return nrOfRows;
	}

	getTotalAmountOfDeletedRows() {
		if (isNullOrUndefined(this.sheets))
			return 0;

		let nrOfRows = 0;
		for (const sheet of this.sheets) {
			nrOfRows += sheet.metaValues.initialNrOfRows;
		}
		return nrOfRows - this.getTotalAmountOfRows();
	}

	public addEmptyRow(sheet: GridSheet, editable = true, keys: {
		[key: string]: any
	}                                             = {}): GridDataRow {

		const newRow = DataGridElementFactory.addEmptyRow(sheet);
		const group  = DataGridHelpers.findGroupByRow(newRow.id, [sheet]).group;
		for (const key of Object.getOwnPropertyNames(keys)) {
			newRow.values.forEach(cell => {
				if (cell.key === key) {
					cell.value = keys[key];
				}
			});
		}

		// Bug with selection change won't hook the following advance dorpdown seletion
		for (const key of Object.getOwnPropertyNames(keys)) {
			newRow.values.forEach(cell => {
				if (cell.key === key) {
					this.selectionChanged(cell, newRow, group, sheet, null);
				}
			});
		}

		this.setRowEditable(newRow, editable);

		// create temporary sheet to apply the rules to the new row.
		const fakesheet = new GridSheet(sheet.key, sheet.keys, sheet.properties, this.options);
		const fakegroup = new GridGroup();
		// hack: set rowstate temporary to default so the rules can be executed
		newRow.rowState = RowState.Default;
		fakegroup.dataRows.push(newRow);
		fakesheet.groups.push(fakegroup);
		DataGridRuleEnforcer.executeRules(this.options.rules, fakesheet, true);
		DataGridRuleEnforcer.executeDynamicRules(fakesheet);
		// hack: set rowstate back to new row
		newRow.rowState = RowState.New;
		newRow.values.forEach(cell => cell.validateCell());
		this.hasGridData();
		// emit the creation of a new row
		this.onRowAdded.emit(new RowClickEventArgs(newRow, sheet));
		this.detectChanges();
		return newRow;
	}

	async expandRow(gridRow: GridDataRow, sheets: Array<GridSheet>,
																	expandOptions: GridOptions, params: ICellBehaviourParams) {
		const found = DataGridHelpers.findGroupByRow(gridRow.id, this.sheets);

		const emptyrow             = DataGridElementFactory.createGridDataExpansionRow(found.group.columsRows, gridRow, this.injector);
		emptyrow.parent            = gridRow.id;
		expandOptions.parentGridId = this.id;

		emptyrow.expansion = {
			sheets:       sheets,
			options:      expandOptions,
			parentRow:    gridRow,
			expandParams: params
		};

		found.group.dataRows.splice(found.rowIndex + 1, 0, emptyrow);
		gridRow.isExpanded = true;

		const group = DataGridHelpers.findGroupByRow(gridRow.id, this.sheets).group;
		const sheet = DataGridHelpers.findSheetByGroup(group, this.sheets);
		DataGridRuleEnforcer.executeDynamicRules(sheet);

		this.detectChanges();
	}

	getChangedCellsReadyForApiLegacy(addBaseKeys: boolean = false) {
		return DataGridHelpers.getChangedCellsReadyForApiLegacy(Array.from(this.changedCells.values()), this.options, addBaseKeys,
																																																										this.injector);
	}

	getChangedCellsReadyForApi(addBaseKeys = false, useLegacyFormat = false) {
		return DataGridHelpers.getChangedCellsReadyForApi(Array.from(this.changedCells.values()), this.options, addBaseKeys,
																																																				useLegacyFormat, this.injector);
	}


	calculateSheetsAsync(sheets: Array<GridSheet>) {
		const observables = [];
		for (const sheet of sheets) {
			observables.push(sheet.calculator.calculateAll());
		}

		return merge(...observables);
	}


	/**
		* Update the parent row values with the new values when nested datagrid is changed
		*/
	updateParentRowValues() {

		if (isNullOrUndefined(this.parentRow))
			return;

		// get parent cells that need updating
		const foundCells = this.parentRow.values.filter(cell => cell.cellType !== DataGridCellType.Injected);

		this.sheets.forEach(sheet => {
			sheet.calculator.calculateCells(DataGridCellType.All, foundCells);
		});
	}

	/**
		* Change the next cell to a editable state,
		*/
	checkForTabPress(cell: GridDataCell, row: GridDataRow, group: GridGroup, sheet: GridSheet, cellIndex: number, $event: KeyboardEvent) {

		if (isNullOrUndefined(cell) || row.rowState === RowState.New)
			return;

		// Check if the keypress is a TAB otherwise ignore
		if ($event.which !== 9)
			return;

		// Cancel all other stuff
		$event.preventDefault();

		this.cellInputChanged(cell, row, group, sheet, $event);
		// Turn current cell to read only
		cell.cellState.editable = false;

		if (!isNullOrUndefined(cell.clickOutsideSubscription) && cell.clickOutsideSubscription.closed) {
			cell.clickOutsideSubscription.complete();
			cell.clickOutsideSubscription.unsubscribe();
			cell.clickOutsideSubscription = null;
		}

		// Get all data cells and put them in one array
		const allCells: Array<GridDataCell> = [];
		for (const sheet of this.sheets) {
			allCells.push(...DataGridHelpers.filterCells(sheet));
		}

		// Find the current index location of the active cell in the allCells array
		const indexOfCell  = allCells.findIndex(c =>
																																											DataGridHelpers.createKeysString(c.keys) === DataGridHelpers.createKeysString(
																																												cell.keys));
		// Get the new index, when TAB add 1, when SHIFT+TAB is pressed -1
		const newCellIndex = indexOfCell + ($event.shiftKey
																																						? -1
																																						: 1);
		// If the new index is out of range of the array length return
		if (newCellIndex === allCells.length || newCellIndex < 0) {
			this.updateCell([cell]);
			return;
		}
		// Get next not readonly cell to change to edit mode
		const newEditableCell = allCells[newCellIndex];
		if (!newEditableCell.cellState.readonly) {
			newEditableCell.cellState.editable = true;
			this.setupClickOutSideHandler(newEditableCell, $event.target);
		}

		this.updateCell([cell, newEditableCell]);
		SafeMethods.detectChanges(this.changeRef);
	}

	headerCellClicked(event: MouseEvent, thCell: GridHeaderCell, sheet: GridSheet) {
		if (event.ctrlKey)
			Logger.Debug(thCell);

		if (this.options.disableSorting) return;


		if (!isNullOrUndefined(thCell) && !thCell.isGroup) {
			if (thCell === this.sortColumn && this.sortOrder === SortOrder.desc) {
				this.sortOrder = SortOrder.asc;
			} else {
				this.sortOrder = SortOrder.desc;
			}
			CsDataGrid.sortRows(thCell.keys, this.sortOrder, [sheet], this.options, thCell.headerSortItem);
			this.sortColumn = thCell;

		}
	}

	rowButtonClicked(btn: RowButton, row: GridDataRow, group: GridGroup, sheet: GridSheet) {
		if (btn.disabled(row))
			return;

		this.onRowButtonClicked.emit(new RowButtonClickEventArgs(row, group, sheet, btn));
	}

	/**
		* Safe Method to execute the detectChanges function, will not result in destroyed view error if changeref is already destroyed
		*/
	detectChanges() {

		this.updateCells(DataGridCellType.All);

		if (!isNullOrUndefined(this.changeRef) && !this.changeRef['destroyed']) {
			this.changeRef.detectChanges();
		}
	}

	trackRowsByFn(index, item: GridDataRow) {
		return item.id;
	}


	/**
		* Detects if the header is truncated and if so add an tooltip
		*/
	detectTruncatedField($event: MouseEvent, cellHeader: GridHeaderCell) {
		const element = $event.currentTarget as HTMLElement;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}


		cellHeader.isTruncated = isEllipsisActive(element);

	}

	/**
		* Returns array of cells that are out of threshold range
		*/
	getChangedCellsOutOfThresholdRange(): GridDataCell[] {
		return Array.from(this.changedCells.values())
														.filter(cell => {
															// Cell is in range by default, as not all cells have thresholds defined
															let inRange = true;

															if (!isNull(cell.metaValues.thresholdValueOutOfRange) && Math.abs(
																cell.metaValues.thresholdValueOutOfRange) > 0.0001) {
																inRange = false;
															}
															return !inRange;
														});
	}

	/**
		* Update hasDuplicateCellKeys property of all group Data cells
		* @param group GridGroup
		*/
	checkDuplicateCellKeys(group: GridGroup): void {
		// short-circuit if there are no choicesets, because there can't be any duplicates by definition
		if (!isNullOrUndefined(this.options.choiceSets) && this.options.choiceSets.rowSets.length === 0) {
			return;
		}

		// keep performance, update only current group
		const groupCellsArray = group.dataRows.reduce((a, b) => {
			return a.concat(...b.values);
		}, []);

		// update duplicate status of all changed cells
		groupCellsArray.forEach(cell => {
			if (cell.cellType === DataGridCellType.Data) {
				this.checkDuplicateCellKeysForCell(cell, groupCellsArray);
			}
		});
	}

	/**
		* Update cell hasDuplicateCellKeys property for usage in rules.
		* Case note implemented: row is deleted and then added again (or vice versa). Current behaviour is that the cells are
		* labeled as duplicate (bad UX).
		*/
	checkDuplicateCellKeysForCell(cell: GridDataCell, groupCells: GridDataCell[]): void {
		// short-circuit if there are no choicesets, because there can't be any duplicates by definition
		if (!isNullOrUndefined(this.options.choiceSets) && this.options.choiceSets.rowSets.length === 0) {
			return;
		}

		// get keys of current cell
		const searchKeys = Object.assign({}, cell.keys);
		delete searchKeys['injectedkey'];

		const found = filter(groupCells, searchKeys, 'keys', 'all');

		// has duplicate if there are more than one cells found (current cell is also included in the list)
		cell.metaValues.hasDuplicateCellKeys = !isNullOrUndefined(found) && found.length > 1
																																									? 1
																																									: 0;
	}


	/**
		* Map of Cells that have changed where the key is a combined id based on the keys
		*/
	private changedCells: Map<string, GridDataCell> = new Map();

	private resolvedMemberList: Map<string, Array<IProperty>>;

	private runChangeDetection: Subscription;

	private updateCell(cellsToUpdate: GridDataCell[]) {
		const cells    = this.gridCells.toArray();
		const cellKeys = [];
		cellsToUpdate.forEach(x => cellKeys.push(x.id));

		for (const c of cells) {
			const foundIndex = cellKeys.findIndex(keys => keys === c.cell.id);
			if (foundIndex > -1) {
				c.markForCheck();
				cellsToUpdate.splice(foundIndex, 1);

				if (cellsToUpdate.length === 0)
					break;
			}
		}
	}

	/**
		* Run change detection for nested grids
		*/
	private onRunChangeDetection(id: string) {
		this.detectChanges();
	}

	/**
		* Run change detection for nested grids
		*/
	private onRunChangeDetectionDelayed(id: string) {
		setTimeout(() => {
			this.detectChanges();
		}, 200);

	}


	//#region Lookup


	private updateMemberLookupList(members: object, choiceSets: IChoiceSets) {
		if (isNullOrUndefined(members) || isNullOrUndefined(choiceSets)) {
			return;
		}

		// find in the rule list all rules that have the lookuptype set to choiceset
		// it's used to map the Carriers
		const foundLookups: DataGridLookupAction[] = <DataGridLookupAction[]>this.options.rules
																																																																											.map(item =>
																																																																																	item.actions.filter(
																																																																																		(action: DataGridLookupAction) =>
																																																																																			(action.type === GridActions.SetLookup
																																																																																				&& action.lookupType === 'ChoiceSet')))
																																																																											.reduce((a, b) => a.concat(b), []);

		this._resolvedMemberList = new Map();

		if (isUndefined(members)) {
			return;
		} else {

			for (const key of Object.keys(members)) {
				if (!choiceSets.rowSets) {
					continue;
				}
				const set        = choiceSets.rowSets.find(x => {
					return x.hasOwnProperty('memberList') && x.memberList.toLowerCase() === key.toLowerCase();
				});
				const memberList = [];
				if (isUndefined(set)) {
					continue;
				}
				for (const member of members[key]) {
					const lookup = new DataGridLookupAction({key: set.name, display: set.display});
					memberList.push(LookupAgent.resolveProperty(member, lookup.key));
				}
				this._resolvedMemberList.set(set.name, memberList);
			}
		}

	}


	//#endregion

	private setLastRowColumnSpan() {
		if (isNullOrUndefined(this.sheets)
			|| this.sheets.length === 0
			|| this.sheets[0].groups.length === 0
			|| isNullOrUndefined(this.sheets[0].groups[0].columsRows)
			|| this.sheets[0].groups[0].columsRows.length === 0)
			return 0;

		const tableHeadRows = this.sheets[0].groups[0].columsRows;

		const lastHeaderRow = tableHeadRows[tableHeadRows.length - 1];
		return lastHeaderRow.columns.length + (this.options.showCheckboxes
																																									? -1
																																									: 0);
	}

	private hasGridData() {
		if (isNullOrUndefined(this.sheets))
			return false;

		for (const sheet of this.sheets) {
			if (sheet.groups.length > 0) {
				const group                      = sheet.groups[0];
				sheet.metaValues.hasData         = group.dataRows.length > 0;
				sheet.metaValues.initialNrOfRows = 0;

				for (const group of sheet.groups) {
					sheet.metaValues.initialNrOfRows += group.dataRows
																																									? group.dataRows.filter(r => r.rowState === RowState.Default).length
																																									: 0;
				}
			}
		}
	}

	private collapseRow(gridRow: GridDataRow) {
		const found = DataGridHelpers.findExpansionRow(gridRow.id, this.sheets);
		found.group.dataRows.splice(found.rowIndex, 1);
		gridRow.isExpanded = false;

		const group = DataGridHelpers.findGroupByRow(gridRow.id, this.sheets).group;
		const sheet = DataGridHelpers.findSheetByGroup(group, this.sheets);
		DataGridRuleEnforcer.executeDynamicRules(sheet);
		this.onExpansionCollapsed.emit(new ExpansionCollapsedEventArgs(gridRow, sheet));

		this.detectChanges();
	}

	/**
		* Function to check if a value is different than the original value.
		* @param original Original value
		* @param changed Changed value
		* @returns Flag if a value is different than the original value
		*/
	private checkIfValueIsChanged(original: any, changed: any, format: DataGridFormatAction) {
		let formatOriginal = original;
		if (!isNullOrUndefined(format) && !isNullOrUndefined(format.format))
			formatOriginal = stringFormatToNumber(format.format, original);

		let originalValue: any;
		let fieldValue: any;

		if (isBoolean(original)) {
			originalValue = original;
		} else {
			originalValue = isNullOrUndefined(formatOriginal)
																			? null
																			: parseFloat(formatOriginal);
		}

		if (isBoolean(changed)) {
			fieldValue = changed;
		} else {
			fieldValue = isNullOrUndefined(changed)
																? null
																: parseFloat(changed);
		}

		// if the original value is NULL and there is an empty string then there is no change
		if (isNull(original) && changed === '')
			return false;

		// Check if cell data is changed
		return originalValue !== fieldValue;
	}

	private setupClickOutSideHandler(cell: GridDataCell, target: EventTarget) {
		const clickOutsideDir = new ClickOutsideDirective(null);

		if (cell.clickOutsideSubscription)
			cell.clickOutsideSubscription.complete();

		cell.clickOutsideSubscription = clickOutsideDir.clickOutsideSubscription;

		cell.clickOutsideSubscription.pipe(untilDestroyed(this))
						.subscribe(x => {
							this.setCellEditable(cell, false);
							this.updateCell([cell]);
							// this.detectChanges();
							if (!isNullOrUndefined(cell.clickOutsideSubscription) && cell.clickOutsideSubscription.closed) {
								cell.clickOutsideSubscription.complete();
								cell.clickOutsideSubscription.unsubscribe();
							}
							cell.clickOutsideSubscription = null;
						});
		clickOutsideDir.startClickOutsideListener(target);
	}

	/** Resolve data entry threshold for current cell
		*  Set threshold values to cell metadata and update cell status (out of range/warning/..? )
		*  Keep large grids performant, by only doing the threshold lookup when the cell is actually edited.
		*/
	private resolveDataEntryThresholdForCell(cell: GridDataCell, cellKeyObject: {}): void {

		const thresholdData = this.dataEntryThresholdData;
		const sheet         = DataGridHelpers.findSheetByCell(cell, this.sheets);

		// Reset meta values
		const originalRangeValue                 = cell.metaValues.thresholdValueOutOfRange;
		cell.metaValues.thresholdValueOutOfRange = null;
		cell.metaValues.thresholdMin             = null;
		cell.metaValues.thresholdMax             = null;

		// Thresholds are aggregated, only compare on the key parts of the data
		const cellCompareKeys = Object.assign({}, cellKeyObject);
		for (const key of Object.keys(cellCompareKeys)) {
			if (thresholdData.dataKeyParts.indexOf(key) === -1 || isUndefined(cellCompareKeys[key])) {
				delete cellCompareKeys[key];
			}
		}

		// Get thresholds for this cell
		let cellThresholdData = filter(thresholdData.parsedData, cellCompareKeys);

		// Fallback: get grand total threshold for this cell
		if (cellThresholdData.length === 0 && thresholdData.fallbackKeyParts.length > 0) {

			// Fallbacks are further aggregated, only compare on the key parts of the data
			const fallbackCompareKeys = Object.assign({}, cellKeyObject);
			for (const key of Object.keys(fallbackCompareKeys)) {
				if (thresholdData.fallbackKeyParts.indexOf(key) === -1 || isUndefined(cellCompareKeys[key])) {
					delete fallbackCompareKeys[key];
				}
			}
			cellThresholdData = filter(thresholdData.parsedFallbackData, fallbackCompareKeys);
		}

		if (cellThresholdData.length === 1) {
			cell.metaValues.thresholdMin = cellThresholdData[0].thresholdmin;
			cell.metaValues.thresholdMax = cellThresholdData[0].thresholdmax;

			if (!isNull(cell.metaValues.thresholdMin) && !isNull(cell.metaValues.thresholdMax)
				&& cell.metaValues.thresholdMin === cell.metaValues.thresholdMax
			) {
				cell.metaValues.thresholdValueOutOfRange = 0;
			} else if (cell.value < cell.metaValues.thresholdMin) {
				cell.metaValues.thresholdValueOutOfRange = -Math.abs(cell.value - cell.metaValues.thresholdMin);
			} else if (cell.value > cell.metaValues.thresholdMax) {
				cell.metaValues.thresholdValueOutOfRange = Math.abs(cell.value - cell.metaValues.thresholdMax);
			} else {
				cell.metaValues.thresholdValueOutOfRange = null;
			}

			// Trigger dynamic rule update when range value has updated.
			if (cell.metaValues.thresholdValueOutOfRange !== originalRangeValue) {
				DataGridRuleEnforcer.executeDynamicRules(sheet);
				this.detectChanges();
			}
		} else if (cellThresholdData.length > 1) {
			Logger.Warning(`Found multiple threshold values ${cellThresholdData.length}, expected 1. Check grid configuration.`);
		}

		return;
	}

	private checkFormatingForDecimals(field: HTMLInputElement): string {
		if (!isNullOrUndefined(field.value.split)) {
			const checkDecimals = field.value.split('.');
			let numberFormat    = '0';

			if (checkDecimals.length < 2)
				return numberFormat;

			numberFormat += '.';
			for (let i = 0; i < checkDecimals[1].length; i++) {
				numberFormat += '0';
			}
			return numberFormat;
		}
	}
}
