import {
	ChangeDetectionStrategy, ChangeDetectorRef,
	Component, OnDestroy, OnInit, ViewChild
}                                                             from '@angular/core';
import { ActivatedRoute }                                     from '@angular/router';
import { FilterCompareBarQuery }                              from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                              from '@cs/components/toast-manager';
import { CsHttpRequestOptions, FileUtils, isNullOrUndefined } from '@cs/core';
import { ContainerSettings }                                  from '@cs/performance-manager/shared';
import { UntilDestroy, untilDestroyed }                       from '@ngneat/until-destroy';
import { TranslateService }                                   from '@ngx-translate/core';
import { filter, map }                                        from 'rxjs/operators';
import {
	CsPropertiesFormComponent
}                                                             from './components/properties-form/properties-form.component';
import {
	MdmPropertiesViewerConfigService
}                                                             from './mdm-properties-viewer-config.service';
import { MdmProfileContext }                                  from './models/mdm-properties-result-params';
import { PageList }                                           from './models/page-list-item.model';
import { MdmPropertiesPageButton }                            from './models/properties-page-button.model';


@UntilDestroy()
@Component({
			   selector       : 'pmc-mdm-properties-viewer',
			   templateUrl    : './mdm-properties-viewer.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class MdmPropertiesViewerComponent extends ContainerSettings implements OnInit, OnDestroy {

	idStation: number;
	hasValidApiParameters = false;
	pageList: PageList;
	pageTitle: string;

	editMode = false;

	isSaving = false;

	@ViewChild('propertiesForm') propertiesForm: CsPropertiesFormComponent;
	identifierObject: MdmProfileContext;


	constructor(public route: ActivatedRoute,
				private config: MdmPropertiesViewerConfigService,
				private filterCompareBarQuery: FilterCompareBarQuery,
				public readonly changeRef: ChangeDetectorRef,
				private i8n: TranslateService,
				private toast: CsToastManagerService) {
		super();
	}

	ngOnInit() {
		this.filterCompareBarQuery
			.select((store) => store.mainbarResultParams)
			.pipe(
				untilDestroyed(this),
				filter(value => !isNullOrUndefined(value))
			)
			.subscribe(value => {
				this.identifierObject = this.config.getIdentifierObject(value);
				this.pageTitle        = this.config.getPageTitle(value);
				this.loadPage();
			});

	}

	async loadPage() {
		this.pageList = [];
		this.changeRef.detectChanges();
		this.pageList              = await this.getPageList(this.identifierObject);
		this.hasValidApiParameters = false;
		this.changeRef.markForCheck();
	}

	/**
	 * Necessary for @Link(untilDestroyed)
	 */
	ngOnDestroy() {

	}

	toggleEditMode() {
		this.editMode = !this.editMode;
		this.propertiesForm.toggleEditMode(this.editMode);
	}

	onSubmit() {
		this.isSaving = true;
		// todo Listen to is saved
		this.toast.show({
							type   : 'success',
							content: this.i8n.instant('PAGE_IS_SAVED')
						});
	}

	onExportButton(button: MdmPropertiesPageButton) {
		button.isLoading = true;

		const options = new CsHttpRequestOptions({
													 errorResponseHandler: error1 => {
														 switch (error1.status) {
															 case 204:
																 this.toast.show({
																					 type   : 'warning',
																					 title  : 'Empty report',
																					 content: 'Could not download PDF export. Please change current filter selection.'
																				 });
																 this.hasValidApiParameters = true;
																 return true;
														 }
														 button.isLoading = false;
														 return false;
													 }
												 });

		this.config.requestExport(this.config.mdmPageName,
								  this.propertiesForm.selectedPageNode.name,
								  button.name,
								  this.identifierObject,
								  options)
			.subscribe(
				(response) => {
					FileUtils.downloadFile(response.value);
					button.isLoading = false;
					this.changeRef.detectChanges();
				});
	}

	private async getPageList(identifierObject: MdmProfileContext) {
		return await this.config.getPageList(this.config.mdmPageName, identifierObject)
						 .pipe(map(result => result.value))
						 .toPromise();
	}
}
