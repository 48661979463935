import { AfterViewInit, ChangeDetectorRef, ElementRef, OnDestroy }                     from '@angular/core';
import { Router }                                                                      from '@angular/router';
import { AppMessageHubService, AppNavigationService, SafeMethods, WaitingForResponse } from '@cs/common';
import {
	DashboardComponent,
	DashboardDownloadButtonEventArgs,
	DashboardGridData,
	DashboardPanelInfoIcon,
	DashboardPanelSettingEventArgs,
	DashboardPanelType,
	NotifyServerForChangesDashboardPanelEventArgs
}                                                                                      from '@cs/components/dashboard';
import { FilterBarDataSource, FilterBarResultParams, FilterCompareBarQuery }           from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                                                       from '@cs/components/toast-manager';
import {
	ApplicationSelectionTargetEnum,
	ApplicationSelectionTargetResult,
	FileUtils,
	flattenObject,
	isNullOrUndefined,
	isObject,
	isString,
	LoggerUtil,
	restoreFlattenObject,
	Result
}                                                                                      from '@cs/core';
import { AppService, ContainerSettings }                                               from '@cs/performance-manager/shared';
import { TabService }                                                                  from '@cs/performance-manager/tabbed-page';
import { UntilDestroy, untilDestroyed }                                                from '@ngneat/until-destroy';
import { TranslateService }                                                            from '@ngx-translate/core';
import { Subject, Subscription }                                                       from 'rxjs';
import { tap }                                                                         from 'rxjs/operators';
import { DashboardConfigService, isDashboardConfigServiceWithChangeNotify }            from './dashboard-config.service';

@UntilDestroy()
export abstract class DashboardBase extends ContainerSettings implements OnDestroy,
																																																																									AfterViewInit {

	abstract dashboard: DashboardComponent;
	abstract panelContentTopBar: ElementRef;

	isLoading$: Subject<boolean> = new Subject();
	data: DashboardGridData;
	mainbarDataSource: FilterBarDataSource<FilterBarResultParams>;

	/**
		* Current request handeling a navbar update
		*/
	currentRequest: Subscription;
	isLoadingPage = false;

	get isDetailsPage() {
		return this._parentName != null;
	}

	protected _parentName: string;

	protected constructor(protected readonly dashboardConfigService: DashboardConfigService,
																							protected readonly filterCompareBarQuery: FilterCompareBarQuery,
																							protected readonly appNavigationService: AppNavigationService,
																							protected readonly appService: AppService,
																							protected readonly cdRef: ChangeDetectorRef,
																							protected readonly i8n: TranslateService,
																							protected readonly toastService: CsToastManagerService,
																							protected readonly router: Router,
																							protected readonly tabService: TabService,
																							readonly appMessageHub: AppMessageHubService) {
		super();

		this.appNavigationService.registerCleanUpAction(params => {
			const restored = restoreFlattenObject(params);

			if (restored.hasOwnProperty('panelSettings') && restored.panelSettings != null) {
				params.panelSettings = restored.panelSettings;
			}

			for (const key of Object.keys(params)) {
				const qValue = params[key];
				if (isString(qValue) && qValue.indexOf('[') > -1) {
					let sValue: string = qValue;
					sValue             = sValue.replace('[', '')
																																.replace(']', '');
					params[key]        = sValue.toString()
																																.split(',');
				}
			}

			return params;
		});

		this.appNavigationService.registerPreProcessAction(params => {
			if (params.hasOwnProperty('panelSettings') && params.panelSettings != null && isObject(params.panelSettings)) {
				params = flattenObject(params);
			}
			return params;
		});
	}

	onDownloadButtonClicked(args: DashboardDownloadButtonEventArgs) {
		const icon   = args.icon as DashboardPanelInfoIcon;
		icon.loading = 'clicked';

		// Check if the panel is a popup based on the parent name
		const panelName    = this._parentName != null
																							? this._parentName
																							: args.panelName;
		const subPanelName = this._parentName != null
																							? args.panelName
																							: undefined;

		const loader = this.dashboard.setIconLoader(icon);

		// MERGE THE POPOVER SELECTION OBJECT so that the button is called with the dashboard selection object
		this.dashboardConfigService.downloadFile(panelName, {
							...args.selectionObject,
							...(isNullOrUndefined(this.mainbarDataSource)
											? {}
											: this.mainbarDataSource.resultParams)
						} as unknown as FilterBarResultParams, subPanelName)
						.subscribe(result => {
																		const fileResponse = result.value;
																		if (fileResponse.status === 204) {
																			this.toastService.show({
																																											type        : 'error',
																																											title       : this.i8n.instant('NO_PANEL'),
																																											content     : this.i8n.instant('DOWNLOAD_ERROR_MESSAGE'),
																																											clickToClose: true
																																										});
																		} else if (!isNullOrUndefined(fileResponse)) {
																			FileUtils.downloadFile(fileResponse);
																		} else {
																			this.toastService.show({
																																											type        : 'error',
																																											title       : this.i8n.instant('NO_PANEL'),
																																											content     : this.i8n.instant('DOWNLOAD_ERROR_MESSAGE'),
																																											clickToClose: true
																																										});
																		}
																		icon.loading = 'done';
																		clearTimeout(loader);
																		this.dashboard.detectChanges();

																	},
																	error => {
																		icon.loading = 'done';
																		clearTimeout(loader);
																		LoggerUtil.error(error);
																	});
	}

	onPanelOptionSelected(args: DashboardPanelSettingEventArgs) {
		const patchedParams = Object.assign({}, args.selectionObject, {panelSettings: args.panelSettings});

		this.router.navigate([], {
							queryParams        : flattenObject({panelSettings: args.panelSettings}),
							queryParamsHandling: 'merge'
						})
						.then(value => {
							this.dashboardConfigService.getPanel(args.panelName, patchedParams, this._parentName)
											.subscribe(result => {
												const newPanel             = result.value;
												const index                = this.data.panels.findIndex(panel => panel.gridSlot === newPanel.gridSlot);
												const newDashboard         = new DashboardGridData(this.data);
												newDashboard.panels[index] = Object.assign({}, newDashboard.panels[index], newPanel);
												this.data                  = newDashboard;
												SafeMethods.detectChanges(this.cdRef);
											});
						});

	}

	/**
		* Clean the panelsettings when a new dashboard is rendered (not patched), this wil be done by the server.
		*/
	newDashboardRendered($event: {
		firstRender: boolean
	}) {
		this.router.navigate([], {
			queryParams        : $event.firstRender
																								? flattenObject({
																																									panelSettings: !isNullOrUndefined(this.dashboard)
																																																								? this.dashboard.getPanelSettingsFromData(this.data)
																																																								: {}
																																								})
																								: flattenObject({panelSettings: this.dashboard.getPanelSettings(this.data)}),
			queryParamsHandling: 'merge'
		});
	}


	onNotifyChangesToServer($event: NotifyServerForChangesDashboardPanelEventArgs<any>) {

		if (isDashboardConfigServiceWithChangeNotify(this.dashboardConfigService)) {

			this.dashboardConfigService.notifyChangeToServer(
								$event.panelName,
								$event.data,
								$event.selection,
								null,
								$event.csHttpRequestOptions
							)
							.pipe(tap(WaitingForResponse.new(isLoading => {
								$event.requestInProgress.next(isLoading);
							})))
							.subscribe(value => {
								$event.callback(value);
								setTimeout(() => {
									this.onNotifyChangesToServerSuccess($event);
								}, 10);
							});
		}
	}

	onNotifyChangesToServerSuccess($event: NotifyServerForChangesDashboardPanelEventArgs<any>) {
		this.appService.refreshNavigationBar();
	}

	ngAfterViewInit(): void {
		this.appMessageHub.registerForApplicationWideMessages()
						.pipe(untilDestroyed(this))
						.subscribe(value => this.refreshData());


	}

	ngOnDestroy(): void {
	}


	refreshData() {
		this.setData(this.filterCompareBarQuery.getValue().mainbarResultParams);
	}

	onApplicationTriggerRequested($event: ApplicationSelectionTargetResult) {

		if ($event.selectionMeta.applicationAction === 'ExpandDashboard') {
			const row = this.dashboard.getRow($event.panelName);

			if (isNullOrUndefined(row))
				LoggerUtil.error(`${$event.panelName} does not result in a parent row`, true);

			row.isExpanded = !row.isExpanded;
		} else if ($event.selectionMeta.applicationAction === 'CollapseDashboardPanel') {
			const panel = this.dashboard.getPanel($event.panelName);

			if (isNullOrUndefined(panel))
				LoggerUtil.error(`${$event.panelName} does not result in a component`, true);

			panel.isCollapsed = !panel.isCollapsed;
		} else if ($event.selectionMeta.applicationAction === ApplicationSelectionTargetEnum.PrintPanel) {
			window.print();
		}

	}

	protected setData(value: FilterBarResultParams) {
		this.isLoading$.next(true);

		if (!isNullOrUndefined(this.currentRequest) && !this.currentRequest.closed)
			this.currentRequest.unsubscribe();

		this.currentRequest = this.dashboardConfigService.getDashboardData(value, this.filterCompareBarQuery.getValue().mainbarResultParams)
																												.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))),
																																		untilDestroyed(this))
																												.subscribe(result => {
																																								this.renderData(result);
																																							},
																																							error => {
																																								this.renderErrorState();
																																							});
	}

	protected setDetailsData(value: FilterBarResultParams, panelName: string, callback: (result: Result<DashboardGridData>) => void = null) {

		if (panelName == null) {
			LoggerUtil.error(`No panelName provided`);
		}
		this._parentName = panelName;

		this.isLoading$.next(true);

		this.dashboardConfigService.getPanelDetails(value, panelName)
						.pipe(tap(WaitingForResponse.new(isLoading => {
							this.isLoadingPage = isLoading;
						})))
						.subscribe(result => {
																		if (callback != null)
																			callback(result);

																		this.renderData(result);
																	},
																	error => {
																		this.renderErrorState();
																	});

		// Not all dashboard implementations rely on the navbar
		if (this.dashboardConfigService.getFilterAndCompareBarData != null)
			this.dashboardConfigService.getFilterAndCompareBarData(value, 'details')
							.subscribe(result => {
								this.mainbarDataSource = result.value;
								SafeMethods.detectChanges(this.cdRef);
							});
	}

	private renderErrorState() {
		this.isLoading$.next(false);
		this.data = new DashboardGridData({
																																					label        : '',
																																					alerts       : [{message: this.i8n.instant('NO_ABLE_TO_LOAD_DASHBOARD'), type: 'danger'}],
																																					grid         : [Array(0)],
																																					panels       : Array(0),
																																					latestRefresh: null,
																																					name         : ''
																																				});
		SafeMethods.detectChanges(this.cdRef);
	}

	private renderData(result) {
		if (isNullOrUndefined(result.value)) {
			this.data = {
				label        : '',
				alerts       : [{message: this.i8n.instant('NO_DASHBOARD_DATA'), type: 'danger'}],
				grid         : [Array(0)],
				panels       : Array(0),
				latestRefresh: null,
				name         : ''
			};
		} else if (result.value.panels.length === 0) {
			this.data           = result.value;
			this.data.grid      = [[12]];
			this.data.panels[0] = {
				data    : {html: this.i8n.instant('NO_PANEL_DATA')},
				gridSlot: 0,
				infoType: 'info',
				type    : DashboardPanelType.INFORMATION
			} as any;
		} else {
			this.data = result.value; /*?*/
			this.appService.update({informationLabel: this.data.latestRefresh});
		}
		this.isLoading$.next(false);
		SafeMethods.detectChanges(this.cdRef);
	}


}

