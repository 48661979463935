import { NgModule }                from '@angular/core';
import { Routes, RouterModule }    from '@angular/router';
import { StepOneComponent }        from './step-one/step-one.component';
import { PasswordChangeComponent } from './password-change/password-change.component';
import { IsResetPageGuard }        from '@cs/performance-manager/shared';


const routes: Routes = [

  {
    path:       '',
    redirectTo: 'step-one',
    pathMatch:  'full'
  },

  {
    path:      'step-one',
    component: StepOneComponent
  },
  {
    path:      'verify-reset',
    component: PasswordChangeComponent,
    data:      {'requireResetCode': true}
  },
  {
    path:      'set-password',
    component: PasswordChangeComponent,
    data:      {'requireResetCode': false, requireOldPassword: false}
  },
  {
    path: 'change-password',
    // make sure the user is authenticated
    canActivate: [IsResetPageGuard],
    component:   PasswordChangeComponent,
    data:        {'requireResetCode': false}
  }

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ResetPasswordRoutingModule {
}
