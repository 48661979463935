import { Injectable }                       from '@angular/core';
import { Store, StoreConfig, persistState } from '@datorama/akita';
import { AuthMethod }                       from '../models/auth-method.model';

export interface LoginState {
	method?: string;
	rememberMe: boolean;
	inProgress: boolean;
	titleMessage: string;
	underTitleMessage: string;
	username: string;
	inErrorState: boolean;
	loginOptions: Array<AuthMethod>;
}

export function createInitialLoginState(): LoginState {
	return {
		rememberMe:        false,
		inProgress:        false,
		titleMessage:      '',
		underTitleMessage: '',
		username:          '',
		inErrorState:      false,
		method:            null,
		loginOptions:      []
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'login'})
export class LoginStore extends Store<LoginState> {

	constructor() {
		super(createInitialLoginState());

		persistState({
			include: ['login']
		});
	}

}

