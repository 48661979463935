import { forwardRef, Type }                                       from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';


/**
 * Function to create the basic provider to components which use `ngModel` as required by Angular.
 * @param type component type which extends `ValueAccessorBase`
 */
export function createValueAccessorProviders<T>(type: Type<ValueAccessorBase<T>>) {
	return [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => type), multi: true}
	];
}

export abstract class ValueAccessorBase<T> implements ControlValueAccessor {

	get value(): T {
		return this.innerValue;
	}

	set value(value: T) {
		if (this.innerValue !== value) {
			this.innerValue = value;
			this._controlValueAccessorChangeFn(value);
		}
	}

	protected innerValue: T;

	writeValue(value: T) {
		this.innerValue = value;
	}

	registerOnChange(fn: (value: T) => void) {
		this._controlValueAccessorChangeFn = fn;
	}

	registerOnTouched(fn: () => void) {
		this._onTouched = fn;
	}

	protected _controlValueAccessorChangeFn: (value: any) => void = () => {};
	/**
	 * Called when the checkbox is blurred. Needed to properly implement ControlValueAccessor.
	 * @docs-private
	 */
	protected _onTouched: () => any                               = () => {};
}
