import { gv }                                                            from '@cs/core/utils';
import { FilterBarData, FilterBarDataDescribed, FilterBarRenderSetting } from './models/filter-bar-data-described';
import {
	hasPropertyOf, LoggerUtil, Lookup, pathChecked, PropertyAnnotation,
	getSelectionKeys, isString, IsObject, DataDescribed
}                                                                        from '@cs/core';
import { isNullOrUndefined, isObject }                                   from '@cs/core';

import { FilterBarElementValue, FilterBarElementData } from './models/filter-bar-element-data';
import { FilterBarItem }                               from './models/filter-bar-element';
import { FilterBarDataSource }                         from './models/filter-bar-data-source';
import { SubFilterItem }                               from './models/filter-bar-sub-filter-item';
import { ViewSelectionItem }                           from './models/filter-bar-view-selection-item';
import { DefaultUrlSerializer }                        from '@angular/router';

function getDisplayType(id: keyof FilterBarData, renderSettings: Array<FilterBarRenderSetting>) {
	return (renderSettings.find(value => value.id === id) || {displayType: 'Default'}).displayType;
}

function getFilterBarValues(lookup: Lookup) {
	const output                  = [];
	let defaultFilterBarItemValue = null;
	for (const value of lookup.values) {

		if (!value.children) {

			if (defaultFilterBarItemValue === null) {
				defaultFilterBarItemValue       = new FilterBarElementValue();
				defaultFilterBarItemValue.data  = [];
				defaultFilterBarItemValue.label = '';
			}
			const label = IsObject(value.value)
																	? value.value.label
																	: value.value;

			defaultFilterBarItemValue.data.push(new FilterBarElementData({
																																																																	identifier: value.key,
																																																																	label:      label,
																																																																	params:     value.value
																																																																}));
		} else {
			const filterBarItemValue = new FilterBarElementValue();
			filterBarItemValue.label = value.value;

			filterBarItemValue.data = value.children.map(value1 => new FilterBarElementData({
																																																																																				identifier: value1.key,
																																																																																				label:      IsObject(value1.value)
																																																																																																? value1.value.label
																																																																																																: value1.value,
																																																																																				params:     IsObject(value1.value)
																																																																																																? value1.value
																																																																																																: null

																																																																																			}));
			output.push(filterBarItemValue);
		}

	}
	if (defaultFilterBarItemValue !== null) output.unshift(defaultFilterBarItemValue);

	return output as FilterBarElementValue[];
}

function isMultiSelect(current: PropertyAnnotation<FilterBarData>) {
	return current.type.indexOf('[]') > -1;
}

async function createFilterBarItem(current: PropertyAnnotation<FilterBarData>, input: FilterBarDataDescribed, sortIndex: number) {

	const selectedValue = !hasPropertyOf(input.data, current.id as string)
																							? null
																							: input.data[current.id];
	const lookupValues  = input.getLookup(current.lookup);

	if (lookupValues === null) {
		LoggerUtil.warn(`no look ${current.lookup}`);
		return null;
	}

	if (isNullOrUndefined(lookupValues) || isNullOrUndefined(lookupValues.values) || lookupValues.values.length === 0)
		return null;

	const filterBarItem = new FilterBarItem({
																																										displayType:   getDisplayType(current.id, pathChecked(input, ['layout',
																																																																																																								'renderSettings'], [], false)),
																																										identifier:    current.id.toString(),
																																										label:         current.label,
																																										requireReload: false,
																																										search:        {
																																											searchEndPoint: current.search.searchEndPoint,
																																											useApiSearch:   gv(() => current.search.searchEndPoint, false),
																																											hasSearchBar:   current.search.hasSearchBar
																																										},
																																										isLoading:     false,
																																										selectedValue: selectedValue as string | number,
																																										values:        getFilterBarValues(lookupValues),
																																										sortIndex:     sortIndex,
																																										isMultiSelect: isMultiSelect(current)
																																									});

	return filterBarItem;
}

function createSubFilterNavItem(current: PropertyAnnotation<FilterBarData>, input: FilterBarDataDescribed) {
	const selectedValue = !hasPropertyOf(input.data, current.id as string)
																							? null
																							: input.data[current.id];

	if (selectedValue === null) {
		LoggerUtil.debug(`Subfilter item: ${current.id} is excluded because it's NULL`);
		return null;
	}
	const resolvedValue = input.resolveValueWithLookup(selectedValue, current.lookup);

	if (resolvedValue == null)
		return null;

	const filterBarItem = new SubFilterItem({
																																										label: `${current.label}: ${IsObject(resolvedValue)
																																																																						? (resolvedValue as any).label
																																																																						: resolvedValue}`,
																																										id:    getSelectionKeys(current)
																																																		.selectionValueTargetKey
																																																		.toString()
																																									});

	return filterBarItem;

}

function createViewSelectionFilterNavItem(current: PropertyAnnotation<FilterBarData>, input: FilterBarDataDescribed) {
	const urlSerializer   = new DefaultUrlSerializer();
	const urlTree         = urlSerializer.parse(window.location.search);
	const params          = urlTree.queryParams;
	let pageViewSelection = null;

	if (params.hasOwnProperty('pageViewSelection')
		&& params.pageViewSelection != null
		&& isString(params.pageViewSelection)) {
		pageViewSelection = JSON.parse(atob(params.pageViewSelection));
	}

	let selectedValue: string | {
		[key: string]: any
	} = null;

	if (hasPropertyOf(input.data, current.id as string)
		&& (!pageViewSelection || (pageViewSelection && !hasPropertyOf(pageViewSelection, current.id as string)))) {
		selectedValue = input.data[current.id] as {
																																													[key: string]: any
																																												} | string;
	} else if (hasPropertyOf(input.data, current.id as string)
		&& input.data[current.id] !== null
		&& pageViewSelection
		&& hasPropertyOf(pageViewSelection, current.id as string)) {
		const data = input.data[current.id] as any;
		if (isObject(data) && data.hasOwnProperty('id') && data.id !== 0 && data.id !== null)
			selectedValue = data;
		else
			selectedValue = pageViewSelection[current.id];
	}
	let idSelection = selectedValue;

	if (selectedValue === null) {
		LoggerUtil.debug(`View filter item: ${current.id} is excluded because it's NULL`);
		return null;
	}
	const resolvedValue = input.getLookup(current.lookup);

	if (resolvedValue == null)
		return null;

	// Find the same object reference so the key can be a complex object.
	if (isObject(selectedValue)) {
		// check if the current selected value has an id property
		const id = hasPropertyOf(selectedValue as {
			[key: string]: any
		}, 'id')
													? (selectedValue as {
				id: string
			}).id
													: null;

		if (id === null) {
			throw new Error(`No id found on property: ${JSON.stringify(selectedValue)}`);
			return null;
		}

		// Find the lookup reference so the value matches the rendered objects.
		const found = resolvedValue.values.find(value => {
			if (hasPropertyOf(value.key as {
				[key: string]: any
			}, 'id'))
				return value.key.id === id;
			else
				return false;
		});

		// If the value is found in the lookup use that key as the object reference.
		// if not just set the current selection
		if (!found)
			idSelection = selectedValue;
		else
			idSelection = found.key;
	}

	const filterBarItem = new ViewSelectionItem({
																																														label:       current.label,
																																														id:          current.id as string,
																																														selected:    idSelection as string | number,
																																														lookup:      resolvedValue,
																																														description: current.description
																																													});

	return filterBarItem;

}

export async function dataDescribedToSubFilterNavbar<T = any>(input: FilterBarDataDescribed,
																																																														renderStubs = true): Promise<FilterBarDataSource<T>> {
	input.dataAnnotation.fields.forEach(value => {
		value.groupId = 'subfilter';
	});

	return dataDescribedToFilterNavbar(input, renderStubs);
}

export async function dataDescribedToNavbar<T = any>(input: DataDescribed<any>): Promise<Array<FilterBarItem>> {
	let data = input as FilterBarDataDescribed;


	if (!(input instanceof FilterBarDataDescribed))
		data = new FilterBarDataDescribed(data);


	let indexDropdown = 0;
	const output      = [];
	for (const item of data.dataAnnotation.fields) {
		output.push(await createFilterBarItem(item, data, indexDropdown));
		indexDropdown++;
	}
	return output;
}

export async function dataDescribedToFilterNavbar<T = any>(input: FilterBarDataDescribed,
																																																											renderStubs = true): Promise<FilterBarDataSource<T>> {

	let data = input;


	if (!(input instanceof FilterBarDataDescribed))
		data = new FilterBarDataDescribed(input);

	const convertedItem = new FilterBarDataSource<T>();

	convertedItem.resultParams       = pathChecked(data, ['data'], null) as any;
	//const tempResultParams					 = pathChecked(data, ['dataSetMetaInfo', 'resultParams'], null, false);
	convertedItem.apiParams          = pathChecked(data, ['dataSetMetaInfo', 'apiParams'], convertedItem.resultParams, false);
	convertedItem.activateComparison = pathChecked(data, ['dataSetMetaInfo', 'activateComparison'], false, false);
	const navItems                   = [];
	const filterItems                = [];
	const subFilterItems             = [];
	const viewSelectionItems         = [];

	let indexDropdown = 0;
	for (const item of data.dataAnnotation.fields) {
		switch (item.groupId) {
			case 'navigation': {
				const filterItem = await createFilterBarItem(item, data, indexDropdown);

				if (filterItem !== null)
					navItems.push(filterItem);
				else if (renderStubs)
					navItems.push({displayType: 'Stub', identifier: 'stub'} as any);

				break;
			}

			case 'filter': {
				const filterItem = await createFilterBarItem(item, data, indexDropdown);
				if (filterItem !== null) {
					filterItems.push(filterItem);
					indexDropdown++;
				}
				break;
			}

			case 'subfilter': {
				const subFilterItem = createSubFilterNavItem(item, data);
				if (subFilterItem !== null)
					subFilterItems.push(subFilterItem);

				break;
			}

			case 'situationalfilter': {
				const viewSelectionItem = createViewSelectionFilterNavItem(item, data);
				if (viewSelectionItem !== null)
					viewSelectionItems.push(viewSelectionItem);

				break;
			}
		}
	}
	convertedItem.filterElements     = filterItems;
	convertedItem.navElements        = navItems;
	convertedItem.subFilterItems     = subFilterItems;
	convertedItem.viewSelectionItems = viewSelectionItems;

	return convertedItem;
}

