<div class="single-indicators">
	<div [class.icon-wrapper]="icon">
		<div *ngIf="icon" class="circle-icon">
			<i class="mdi mdi-{{icon}}"></i>
		</div>
		<div [class.content-wrapper]="icon">
			<div class="single-indicators-header-container" *ngIf="!icon">
				<label class="text-no-whitespace text-truncate">{{label}}</label>
			</div>
			<div class="single-indicators-header-container" *ngIf="icon">
				<label class="text-no-whitespace">{{label}}</label>
			</div>
			<div class="goal-wrapper-container">
				<div class="single-indicators-value-container attention-text">
					<!--<label class="truncate">{{label}}</label>-->
					<ng-container *ngIf="value!=null">
						<span class="value">{{(value | format:formatValue)}}</span>
					</ng-container>
					<ng-container *ngIf="value==null">
						<small *ngIf="!emptyDataReasonIsNull"
								class="mt-1 f-body-s font-weight-bold text-align-left"> {{emptyDataReason}}</small>
						<small *ngIf="emptyDataReasonIsNull"
								class="mt-1 f-body-s font-weight-bold  text-align-left"> {{'NO_DATA' | translate}}</small>
					</ng-container>

					<div class="offset-values-wrapper attention-text"  *ngIf="icon">
						<ng-container *ngFor="let indicator of dataSource?.data">
							<div class="value-container"
								[class.help-cursor]="indicator?.offsetTooltip"
								matTooltip="{{indicator?.offsetTooltip}}" matTooltipPosition="above">
								<small class="offset {{indicator?.offsetStyle}}">
									{{(indicator?.offset | format:formatOffset) || ('NO_DATA' | translate)}}
								</small>
							</div>
						</ng-container>
					</div>

				</div>
				<div class="goals-wrapper" *ngIf="!icon">
					<div class="goal-label-wrapper">
						<ng-container *ngFor="let indicator of dataSource?.data">
							<div [class.help-cursor]="indicator?.goalLabelTooltip"
								matTooltip="{{indicator?.goalLabelTooltip}}"
								matTooltipPosition="above">{{indicator?.goalLabel}}</div>
						</ng-container>
					</div>  <!--<ng-container *ngIf="indicator?.goalLabel && indicator?.goal !== null">:</ng-container>-->
					<div class="goal-values-wrapper">
						<ng-container *ngFor="let indicator of dataSource?.data">
							<div class="value-container attention-text"
								[class.help-cursor]="indicator?.goalTooltip"
								matTooltip="{{indicator?.goalTooltip}}" matTooltipPosition="above">
								<small> {{(indicator?.goal | format:formatGoal) || ('NO_DATA' | translate) }}</small>
							</div>
						</ng-container>
					</div>
					<div class="delta-container-wrapper">
						<ng-container *ngFor="let indicator of dataSource?.data">
							<div class="value-container">
								<!--<span>vs</span>-->
								<span>Δ</span>
							</div>
						</ng-container>
					</div>
					<div class="offset-values-wrapper attention-text">
						<ng-container *ngFor="let indicator of dataSource?.data">
							<div class="value-container"
								[class.help-cursor]="indicator?.offsetTooltip"
								matTooltip="{{indicator?.offsetTooltip}}" matTooltipPosition="above">
								<small class="offset {{indicator?.offsetStyle}}">
									{{(indicator?.offset | format:formatOffset) || ('NO_DATA' | translate)}}
								</small>
							</div>
						</ng-container>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
