import { KeyValuePair }  from './key-value-pair.model';
import { getPropertyOf } from '@cs/core/utils';


export class Lookup<TKey = any, TValue = any, TFilter = any> {
	filter: TFilter;
	id: string;
	values: KeyValuePair<TKey, TValue>[];

	constructor(init: Partial<Lookup<TKey, TValue, TFilter>>) {
		this.id     = getPropertyOf(init, 'id');
		this.values = getPropertyOf(init, 'values');
		this.filter = getPropertyOf(init, 'filter', null);
	}

}
