import { PropertyAnnotation, TableDataCell, TableDataRowLayout } from '@cs/core';

export class TableCellClickEventArgs<T> {
  item: PropertyAnnotation<T>;
  row: any;

  constructor(item: PropertyAnnotation<T>, row: any) {
    this.item = item;
    this.row  = row;
  }
}
