export enum DataEntryStateColumnType {
	DataEntryState  = 'DataEntryState',
	Text            = 'Text',
	Status          = 'Status',
	Label           = 'Label',
	Header          = 'Header',
	HeaderVertical  = 'HeaderVertical',
	Placeholder     = 'Placeholder',
	StatusWithLabel = 'StatusWithLabel'
}
