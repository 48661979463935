import { FilterBarData, FilterBarRenderSetting } from './models/filter-bar-data-described';
import { Lookup, IFilterTree }                   from '@cs/core';

import { FilterBarElementValue, FilterBarElementData } from './models/filter-bar-element-data';
import { FilterBarItem }                               from './models/filter-bar-element';
import { FilterBarDataSource }                         from './models/filter-bar-data-source';


function getDisplayType(id: keyof FilterBarData, renderSettings: Array<FilterBarRenderSetting>) {
	return (renderSettings.find(value => value.id === id) || {displayType: 'Default'}).displayType;
}

function getFilterBarValues(lookup: Lookup) {
	const output                  = [];
	let defaultFilterBarItemValue = null;
	for (const value of lookup.values) {

		if (!value.children) {

			if (defaultFilterBarItemValue === null) {
				defaultFilterBarItemValue       = new FilterBarElementValue();
				defaultFilterBarItemValue.data  = [];
				defaultFilterBarItemValue.label = '';
			}

			defaultFilterBarItemValue.data.push(new FilterBarElementData({
																																																																	identifier: value.key,
																																																																	label:      value.value
																																																																}));
		} else {
			const filterBarItemValue = new FilterBarElementValue();
			filterBarItemValue.label = value.value;

			filterBarItemValue.data = value.children.map(value1 => new FilterBarElementData({
																																																																																				identifier: value1.key,
																																																																																				label:      value1.value
																																																																																			}));
			output.push(filterBarItemValue);
		}

	}
	if (defaultFilterBarItemValue !== null) output.unshift(defaultFilterBarItemValue);

	return output as FilterBarElementValue[];
}

function createLookups(current: IFilterTree, id: string): Lookup {
	return new Lookup({
																				id:     id,
																				values: current.memberTree.members.map(value => ({value: value.properties.label, key: value.id})),
																				filter: null
																			});
}

function createFilterBarItem(current: IFilterTree, input: Array<IFilterTree>, sortIndex: number) {

	// take the first level as label
	const label         = current.memberTree.label;
	// Take the key from the dimension tree
	const identifier    = current.memberTree.key;
	const lookupValues  = createLookups(current, identifier);
	const selectedValue = current.memberTree.selectedValue
																							? current.memberTree.selectedValue
																							: lookupValues.values[0].key;

	const filterBarItem = new FilterBarItem({
																																										displayType:   getDisplayType(identifier, []),
																																										identifier:    identifier,
																																										label:         label,
																																										requireReload: false,
																																										search:        {
																																											searchEndPoint: null,
																																											useApiSearch:   false,
																																											hasSearchBar:   lookupValues.values.length > 10
																																										},
																																										isLoading:     false,
																																										selectedValue: selectedValue as string | number,
																																										values:        getFilterBarValues(lookupValues),
																																										sortIndex:     sortIndex,
																																										isMultiSelect: false
																																									});

	return filterBarItem;
}


export function dimensionTreeToFilterNavbar<T = any>(input: Array<IFilterTree>,
																																																					renderStubs = true): FilterBarDataSource<T> {

	const data = input;


	const convertedItem = new FilterBarDataSource<T>();

	convertedItem.resultParams       = {} as any;
	convertedItem.apiParams          = {};
	convertedItem.activateComparison = false;

	const navItems           = [];
	const filterItems        = [];
	const subFilterItems     = [];
	const viewSelectionItems = [];

	let indexDropdown = 0;
	for (const item of data) {
		switch ('navigation') {
			case 'navigation': {
				const filterItem = createFilterBarItem(item, data, indexDropdown);

				if (filterItem !== null) {
					filterItems.push(filterItem);
					// Update the result params
					convertedItem.resultParams[filterItem.identifier] = filterItem.selectedValue;
				} else if (renderStubs)
					filterItems.push({displayType: 'Stub', identifier: 'stub'} as any);

				indexDropdown++;
				break;
			}

		}
	}
	convertedItem.filterElements     = filterItems;
	convertedItem.navElements        = navItems;
	convertedItem.subFilterItems     = subFilterItems;
	convertedItem.viewSelectionItems = viewSelectionItems;

	return convertedItem;
}

