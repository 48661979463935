<div class="flex flex-column full-height full-width">
  <div *ngIf="currentChart?.data?.length > 0"
       [ngClass]="clickTypeClass"
       [class.is-clickable]="isClickable"
       [class.whole-chart-clickable]="isWholeChartClickable"
       #csChartLoaderDirective="cs-chart-loader"
       style="height: 100%; width: 100%"
       cs-chart-loader
       [chartOptions]="chartOptions"
       (chartClicked)="chartClicked($event, currentChart)"
       (chartElementClicked)="onChartIsClicked($event, currentChart)"
       chartType="{{currentChart?.layout.chartType}}"
       [chartData]="chartData">
  </div>
  <!--  <mat-slider [displayWith]="labelFn" [step]="1" [min]="1" [max]="12" [thumbLabel]="true"  ></mat-slider>-->
  <div class="chart-slider-container" *ngIf="showSlider">
    <ng5-slider [options]="options"
                (valueChange)="changeActiveChart($event)"
                [manualRefresh]="manualRefresh"
                [value]="data.selected">
    </ng5-slider>
  </div>
</div>


<div *ngIf="currentChart?.data?.length === 0" class="no-data-container">
  <div class="no-data">
    <div class="icon">
      <i class="mdi mdi-48px mdi-chart-areaspline"></i>
      <div>{{'NO_PANEL' | translate}} </div>
    </div>

  </div>

</div>
