import { FilterBarItem } from './models/filter-bar-element';

export class CompareAndFilterBarDataSource {
  isCompareRow                       = false;
  row;
  state: string;
  isLoading                          = false;
  renderedDropDowns: FilterBarItem[] = [];
  lastClickedIndex                   = 0;

  constructor(dataSource, isCompareRow?) {
    if (isCompareRow !== undefined) {
      this.isCompareRow = isCompareRow;
    }

    this.createRows(dataSource);
  }

  createRows(dataSource) {
    for (const row of dataSource) {
      const dropdowns = [];

      for (const dropdown of row.dropdowns) {
        const compareDropdown = {...dropdown};

        if (this.isCompareRow) {
          compareDropdown.identifier += '_compare';
        }

        dropdowns.push(compareDropdown);
      }

      this.row = {
        'dropdowns': dropdowns
      };
    }
  }

  getStyle(dropdown) {
    if (this.isCompareRow && !dropdown.isComparable) {
      return {
        'display': 'none'
      };
    }
    return;
  }
}
