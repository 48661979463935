import { BaseNode }     from './base-node';
import { PropertyNode } from './property-node';

export class GroupNode extends BaseNode {
  public columns: number;
  public columnLabels: Array<string>;
  public rowLabels: Array<string>;
  public nodeType = 'GroupNode';

  public constructor(data) {
    super(data, 'properties');
    this.columns = data.columns;
    this.columnLabels = data.columnLabels;
    this.rowLabels = data.rowLabels;
  }

  public processChildren(children) {
    for (let child of children) {
      this.children.push(this.createChild(PropertyNode, child));
    }
  }
}
