import { Directive, ElementRef, Input, AfterViewInit, HostBinding, OnChanges } from '@angular/core';
import { ComponentChanges, whenChanging, generateQuickGuid }                   from '@cs/core';

@Directive({
						 selector: '[csWidthRuler]',
						 exportAs: 'csWidthRulerRef'
					 })
export class CsWidthRulerDirective implements AfterViewInit,
																						OnChanges {


	get currentWidth(): number {
		return this._currentWidth;
	}


	constructor(private _elementRef: ElementRef) {

	}

	ngAfterViewInit(): void {
		this.init();
	}


	ngOnChanges(changes: ComponentChanges<CsWidthRulerDirective>): void {

	}

	private _currentWidth: number;

	private updateAvailableWidth(): number {
		const rulerElement = this._elementRef.nativeElement as HTMLElement;
		if (rulerElement)
			return rulerElement.getBoundingClientRect().width;
		else
			throw new Error('Ruler element is not found');
	}

	/**
	 * Get the initial width
	 */
	private init() {
		this.currentWidth = this.updateAvailableWidth();
	}

	private set currentWidth(value: number) {
		this._currentWidth = value;
	}
}
