import { getPropertyOf } from '@cs/core/utils';

export class TableConditionalAnnotation {
	/**
	 * Operator on which is going to be evaluated
	 */
	operator: string;
	/**
	 * Value to be evaluated againts
	 */
	referenceValue: any;
	/**
	 * Style to be apply
	 */
	style: string;

	constructor(init: Partial<TableConditionalAnnotation>) {
		this.operator       = getPropertyOf(init, 'operator', null);
		this.referenceValue = getPropertyOf(init, 'referenceValue', null);
		this.style          = getPropertyOf(init, 'style', null);
	}
}
