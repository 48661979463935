<ng-container *ngFor="let node of nodes">
  <ng-container [ngSwitch]="node?.nodeType">
    <div *ngSwitchCase="'PageNode'" class="container page-node">
      <pmc-properties-view class="children"
                           *ngIf="node.children?.length > 0"
                           [formGroup]="formGroup"
                           [editMode]="editMode"
                           [nodes]="node.children">
      </pmc-properties-view>
    </div>
    <div *ngSwitchCase="'SectionNode'" class="section-node">
      <pmc-properties-view class="children" *ngIf="node.children?.length > 0"
                           [formGroup]="formGroup"
                           [editMode]="editMode"
                           [nodes]="node.children">
      </pmc-properties-view>
    </div>
    <div *ngSwitchCase="'GroupNode'" class="cs-panel-header group-node">
      <div class="cs-panel-header--header">
        <label>{{node.label}}</label>

        <div class="button-groups">
          <button class="btn btn--single-icon"
                  *ngIf="!node.editMode"
                  (click)="toggleEditMode(node)"
                  matTooltipPosition="above"
                  [matTooltip]="'EDIT'| translate | titlecase">
            <i class="mdi mdi-pencil"></i>
          </button>
          <button class="btn btn--single-icon"
                  *ngIf="node.editMode"
                  (click)="cancelHandler(node)"
                  matTooltipPosition="above"
                  [matTooltip]="'CANCEL'| translate | titlecase">
            <i class="mdi mdi-close-circle"></i>
          </button>
          <button class="btn btn--single-icon"
                  *ngIf="node.editMode"
                  (click)="saveData(node)"
                  matTooltipPosition="above"
                  [matTooltip]="'SAVE_ALL'| translate | titlecase">
            <i class="mdi mdi-content-save"></i>
          </button>
        </div>
      </div>
      <div class="cs-panel-header--content property-node"
           [class.editable]="node.editMode">
        <div class="cs-row">
          <div class="cs-col-8 offset-4 ">
            <div class="column-container"
                 *ngIf="node.columnLabels.length > 1">
              <div *ngFor="let columnLabel of node.columnLabels"
                   [ngStyle]="{'width': (100 / node.columnLabels.length) + '%'}"
                   class="column-label">{{columnLabel}}
              </div>
            </div>
          </div>
        </div>
        <div class="cs-row property-row" *ngIf="node.columnLabels.length > 0">
          <div class="cs-col-4 label-column">
            <label [attr.for]="node.key" *ngIf="node.rowLabels.length <= 0">{{node.children[0].label}}</label>
            <label [attr.for]="node.key" class="group-multi-label"
                   *ngIf="node.rowLabels.length > 0">
              <span *ngFor="let label of node.rowLabels">{{label}}</span>
            </label>
          </div>
          <div class="cs-col-8 value-column all-children-flex" [class.group-multi-values]="node.rowLabels.length > 0">
            <ng-container *ngFor="let child of node.children; let last = last">
              <pmc-property-node-view [editMode]="node.editMode"
                                      [formGroup]="formGroup"
                                      [columnWidth]="(100 / node.columnLabels.length)"
                                      [ngStyle]="{'width': (100 / node.columnLabels.length) + '%'}"
                                      [node]="child">
              </pmc-property-node-view>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="node.columnLabels.length <= 0">
          <div class="cs-row property-row" *ngFor="let child of node.children">
            <div class="cs-col-4 label-column">
              <label [attr.for]="node.key">{{child.label}}</label>
            </div>
            <div class="cs-col-8 value-column all-children-flex" [class.has-remark]="child.input.data.remark">
              <pmc-property-node-view [editMode]="node.editMode"
                                      [formGroup]="formGroup"
                                      [node]="child"></pmc-property-node-view>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-container>
