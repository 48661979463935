import { NgModule }                            from '@angular/core';
import { CommonModule }                        from '@angular/common';
import { FormsModule }                         from '@angular/forms';
import { NgbDatepicker, NgbDatepickerContent } from './datepicker';
import { NgbDatepickerMonth }                  from './datepicker-month';
import { NgbDatepickerNavigation }             from './datepicker-navigation';
import { NgbInputDatepicker }                  from './datepicker-input';
import { NgbDatepickerDayView }                from './datepicker-day-view';
import { NgbDatepickerNavigationSelect }       from './datepicker-navigation-select';

@NgModule({
	declarations:    [
		NgbDatepicker, NgbDatepickerContent, NgbDatepickerMonth, NgbDatepickerNavigation, NgbDatepickerNavigationSelect,
		NgbDatepickerDayView, NgbInputDatepicker
	],
	exports:         [NgbDatepicker, NgbDatepickerContent, NgbInputDatepicker, NgbDatepickerMonth],
	imports:         [CommonModule, FormsModule],
	entryComponents: [NgbDatepicker]
})
export class NgbDatepickerModule {
}
