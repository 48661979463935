import { DataViewColumnPosition, DataViewPosition } from './data-view-position';

export class DataViewRowPosition extends DataViewPosition {
	cellPositions: Array<DataViewColumnPosition> = [];
	id: string;

	get cellPositionsByFieldMap() {
		return this.cellPositions.reduce((mapAccumulator, obj) => {
			mapAccumulator.set(obj.field, obj);
			return mapAccumulator;
		}, new Map());


	}

	constructor(init: Partial<DataViewRowPosition>) {
		super(init);

		this.cellPositions = init.cellPositions;
		init.id            = init.id;

	}

	calculateCellPositionsAndRowWidth() {
		let previousPosition = 0;
		const orderedList    = this.cellPositions.sort((a, b) => a.index - b.index);
		for (let i = 0; i < orderedList.length; i++) {
			const cell    = orderedList[i];
			cell.position = previousPosition;
			cell.isLast   = i === (orderedList.length - 1);
			previousPosition += cell.width;
		}
		// set the row width
		this.width = previousPosition;
	}

	getColumn(id: string) {
		const result = this.cellPositions.find(value => value.field === id);

		if (result == null)
			throw new Error(`${id} has not position, please provide one `);

		return result;
	}

}
