import { HttpErrorResponse }                                      from '@angular/common/http';
import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, forwardRef, Inject, Input, OnChanges, OnInit, Output, ViewChild
}                                                                 from '@angular/core';
import { FormGroup }                                              from '@angular/forms';
import { ActivatedRoute, Router }                                 from '@angular/router';
import { WizardComponentRegistry }                                from '@cs/components/shared';
import { CsToastManagerService }                                  from '@cs/components/toast-manager';
import { DATA_CONSTANTS }                                         from '@cs/core/generate';
import { TranslateService }                                       from '@ngx-translate/core';
import { WizardValidationResult }                                 from './models/wizard-validation-result';
import { WizardQuery, WizardService, WizardStore }                from './state';
import { ComponentChanges, gv, LoggerUtil, Result, whenChanging } from '@cs/core';

import { CsHttpRequestOptions }                from '@cs/core/http';
import { UntilDestroy, untilDestroyed }        from '@ngneat/until-destroy';
import { WizardStepperComponent }              from './components/wizard-stepper';
import { Base, StepData, WizardParentContext } from './models';
import { RequestStepDataEventArgs }            from './models/request-step-data-event-args';
import { Step }                                from './models/step';
import { WizardStepsDataDescribed }            from './models/wizard-data-described';
import { WizardConfigService }                 from './services/wizard.service';


@UntilDestroy()
@Component({
			   selector:        'cs-wizard',
			   templateUrl:     './wizard.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   providers:       [
				   {provide: Base, useExisting: forwardRef(() => WizardComponent)},
				   WizardStore,
				   WizardService,
				   WizardQuery
			   ]
		   })
export class WizardComponent implements OnInit,
										OnChanges {


	@Input() dataContext: WizardStepsDataDescribed;
	@Output() requestStepData: EventEmitter<RequestStepDataEventArgs> = new EventEmitter<RequestStepDataEventArgs>();

	@ViewChild('wizardStepper', {static: true}) stepper: WizardStepperComponent;

	contentComponent: any;
	contentData: unknown;
	contentDataStore: Map<string, any> = new Map<string, any>();

	steps: Array<Step>   = [];
	contentContext: WizardParentContext;
	formgroup: FormGroup = new FormGroup({});

	get isLastStep() {
		return gv(() => this.stepper.isLastStep, false);
	}

	get canNavigateForward(): boolean {
		const name = gv(() => this.contentContext.step.name);
		if (name == null)
			return true;
		return this.formgroup.get(name).valid;
	}

	get canNavigateBack(): boolean {

		if (gv(() => this.stepper.isFirstStep))
			return false;

		return true;
	}

	constructor(@Inject(WizardComponentRegistry) private registry: WizardComponentRegistry,
				@Inject(WizardConfigService) private config: WizardConfigService,
				private readonly changeRef: ChangeDetectorRef,
				public router: Router,
				private route: ActivatedRoute,
				private wizardService: WizardService,
				@Inject(CsToastManagerService) private toast: CsToastManagerService,
				@Inject(TranslateService) private translateService: TranslateService
	) {
	}

	ngOnInit(): void {
		// listen to status changes
		this.formgroup.statusChanges.pipe(untilDestroyed(this))
			.subscribe(value => {
				this.changeRef.detectChanges();
			});
	}

	ngOnChanges(changes: ComponentChanges<WizardComponent>): void {

		whenChanging(changes.dataContext, true)
			.execute(value => {
				this.steps = value.currentValue.data.map(value1 => new Step(value1));
			});

	}

	async setStepActive(step: Step, refreshContext?: {
		[key: string]: any
	}) {

		if (step.data == null) {
			const result: Result<any> = await this.config.getStepData(this.getStepContextData(step.name), refreshContext)
												  .toPromise();

			this.contentData = result.value;

		} else
			this.contentData = step.data;

		if (!this.contentDataStore.has(step.name))
			this.contentDataStore.delete(step.name);

		this.contentDataStore.set(step.name, JSON.parse(JSON.stringify(this.contentData)));

		if (this.requestStepData) {
			this.contentComponent = null;
			this.changeRef.detectChanges();
		}
		this.contentComponent = this.registry.getTemplate(step.content).component;

		const formGroup = this.formgroup.contains(step.name)
						  ? this.formgroup.get(step.name) as FormGroup
						  : new FormGroup({}, {updateOn: step.updateOn ?? 'change'});

		this.contentContext = {step: step, formGroup: formGroup};

		if (!this.formgroup.contains(step.name)) {
			this.formgroup.addControl(step.name, this.contentContext.formGroup);
		}

		this.wizardService.setActiveStep(step);

		this.changeRef.detectChanges();

		if (this.contentContext.step.validateOnEnter) {
			await this.isValid();
		}
	}


	async navigateBack($event: MouseEvent) {
		if (this.canNavigateBack)
			this.stepper.navigateBack();
	}

	async navigateNext($event: MouseEvent) {
		if (this.canNavigateForward && (this.contentContext.step.validateOnExit
										? await this.isValid()
										: true)) {
			if (this.contentContext.step.updateSteps) {
				const errorHandler = CsHttpRequestOptions.CreateErrorResponseHandler((response: HttpErrorResponse) => {
					switch (response.status) {
						case 400:
							// todo put the error to the panel
							return false;

						default:
							return false;
					}
					return false;
				});

				const updatedWizard = await this.config
												.updateWizardSteps(this.getStepContextData(this.contentContext.step.name))
												.toPromise();

				this.dataContext = updatedWizard.value;

			}
			this.wizardService.removeErrorMessages(this.contentContext.step.name);
			this.stepper.navigateNext();
		}
	}

	async finishWizard($event: MouseEvent) {
		if (await !this.isValid())
			return;

		const result: Result<any> = await this.config.finishWizard(this.getStepContextData(this.contentContext.step.name))
											  .toPromise();

		if (result)
			this.router.navigate(['../../'], {relativeTo: this.route});
		else
			LoggerUtil.error(`Error saving user`);
	}

	private getStepContextData(currentStep: string): RequestStepDataEventArgs {

		return {
			currentStep: currentStep,
			context:     Object.keys(this.formgroup.value)
							   .map(key => {
								   const data = new StepData();
								   data.name  = key;
								   data.data  = this.formgroup.value[key].hasOwnProperty(DATA_CONSTANTS.UNPACK_ME_VAR)
												? this.formgroup.value[key][DATA_CONSTANTS.UNPACK_ME_VAR] // get the wrapped object
												: this.formgroup.value[key];
								   return data;
							   })
		};

	}

	private async isValid(): Promise<Boolean> {
		return new Promise((resolve, reject) => {
			const errorHandler = CsHttpRequestOptions.CreateErrorResponseHandler((response: HttpErrorResponse) => {
				switch (response.status) {
					case 400:
						const results = response.error as WizardValidationResult[];

						if (results == null || results.toString() === '') {
							resolve(false);
							this.toast.show({
												type:         'error',
												content:      this.translateService.instant('INVALID_WITHOUT_REASON'),
												clickToClose: true
											});
							return true;
						}

						const currentStepResult = results.find(value => value.name === this.contentContext.step.name);
						if (currentStepResult.refreshWizardStep) {
							this.updateStep(currentStepResult)
								.then(value => setTimeout(() => this.showErrorMessages(results), 100));
						} else {
							this.showErrorMessages(results);
						}
						resolve(false);
						return true;
					default:
						resolve(true);
				}
				return false;
			});

			this.config.validateWizardSteps(this.getStepContextData(this.contentContext.step.name), errorHandler)
				.subscribe(value => resolve(true));
		});
	}

	private showErrorMessages(validationResult: WizardValidationResult[]): void {
		this.wizardService.showErrorMessages(validationResult);
	}

	private async updateStep(currentStepResult: WizardValidationResult): Promise<void> {
		await this.setStepActive(this.steps.find(step => step.name === currentStepResult.name), currentStepResult.refreshContext);
	}
}
