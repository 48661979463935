import { ValidationRuleMatch }            from '@cs/components/util';
import { DataGridAction }                 from './data-grid-action';
import { DataGridCellType, GridItemType } from '../enums/data-grid.enum';
import { GridActions }                    from '../enums/data-grid.enum';

import { isNullOrUndefined } from '@cs/core';

export class DataGridValidatorAction implements DataGridAction {
	readonly type: GridActions = GridActions.AddValidator;

	/**
		* Scope to which to limit the match (e.g. row of the cell)
		*/
	matchScope: GridItemType = GridItemType.Cell;

	/**
		* Filter by cell type (default: Data).
		*/
	celltype: DataGridCellType = DataGridCellType.Data;

	/**
		* LevelKeys or factProperties to Match on (see LevelKeys for more info)
		*/
	match: ValidationRuleMatch;

	// target: GridItemType.Cell; // fixed property, only validated against cell values
	/**
		* Message for display purposes
		*/
	errorMessage: string;

	constructor(initial: Partial<DataGridValidatorAction> = null) {
		if (!isNullOrUndefined(initial))
			Object.assign(this, initial);
	}
}
