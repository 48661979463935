<div class="spinner-container">
	<div class="hover-pointer spinner" #up>
		<i class="mdi mdi-menu-up mdi-24px"></i>
	</div>
	<input type="text"
		   (change)="inputChanged($event)"
		   [ngModel]="displayValue"/>
	<!--<div class="cell-content">-->
	<!--{{displayValue}}-->
	<!--</div>-->
	<div class="hover-pointer spinner" #down>
		<i class="mdi mdi-menu-down mdi-24px"></i>
	</div>
</div>
