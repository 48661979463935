import { DataDescribed, getSelectionAction } from '@cs/core';


/**
 * Check the if a SelectionTarget exists and if so return a class based on the value
 * @param value DataDescribed from the server
 */
export function getSelectionTargetClass(value: DataDescribed<any, any, any>) {

	if (value == null)
		return '';

	const selectionAction = getSelectionAction(value);

	switch (selectionAction) {
		case 'Navigate':
			return 'navigate-ipa';
		case 'ModalWindow':
			return 'modal-window-ipa';
		case 'Download':
			return 'download-ipa';
		case 'CurrentWindow':
			return 'current-window-ipa';
		case 'NewWindow':
			return 'new-window-ipa';
		default:
			return '';
	}
}

