<div class="simple-dialog dialog-{{type}}">
  <h1 *ngIf="data?.dialogTitle" mat-dialog-title>{{data.dialogTitle}}</h1>
  <div mat-dialog-content>
    <div [innerHtml]="data.message"></div>
  </div>
  <div class="simple-dialog--button-container" mat-dialog-actions>
    <button *ngIf="showYes" class="btn btn--primary btn-md" [mat-dialog-close]="true" tabindex="3">
      {{'YES'|translate  | capitalize}}
    </button>
    <button *ngIf="showNo" class="btn btn-md" [mat-dialog-close]="false" tabindex="2">
      {{'NO'|translate | capitalize}}
    </button>
		<button *ngIf="showOk" class="btn btn--primary btn-md" [mat-dialog-close]="true" tabindex="3">
      {{'OK'|translate  | capitalize}}
    </button>
    <button *ngIf="showCancel" class="btn btn-md dialog--cancel" (click)="onNoClick()" tabindex="-1">
      {{'CANCEL'|translate | capitalize}}
    </button>
  </div>
</div>
