import { getPropertyOf } from '@cs/core';

export class AuthMethod {
	/**
	 * The icon attached to the authMethod
	 */
	icon: string;
	/**
	 * The User friendly name
	 */
	label: string;
	/**
	 * the server friendly name
	 */
	key: string;
	/**
	 * Is the auth method active
	 */
	active: boolean;

	constructor(init: AuthMethod) {
		this.key    = getPropertyOf(init, 'key');
		this.active = getPropertyOf(init, 'active', false);
		this.icon   = getPropertyOf(init, 'icon');
		this.label  = getPropertyOf(init, 'label');

	}

}
