import { NgModule, ModuleWithProviders, forwardRef } from '@angular/core';
import { CommonModule }                              from '@angular/common';
import { CsDateParserFormatter }                     from './date-parser-formatter';

import { CsDatepickerI18n }                          from './datepicker-i18';
import { CsCultureModule }                           from '@cs/common';
import { NgbDateParserFormatter, NgbDatepickerI18n } from '../datepicker-core';


export { CsDatepickerI18n }      from './datepicker-i18';
export { CsDateParserFormatter } from './date-parser-formatter';


@NgModule({
	declarations:    [],
	exports:         [],
	imports:         [CommonModule, CsCultureModule],
	entryComponents: []
})
export class CsDataPickerCultureModule {
	static forRoot(): ModuleWithProviders<CsDataPickerCultureModule> {
		return {
			ngModule:  CsDataPickerCultureModule,
			providers: [
				{provide: NgbDateParserFormatter, useClass: forwardRef(() => CsDateParserFormatter)},
				{provide: NgbDatepickerI18n, useClass: forwardRef(() => CsDatepickerI18n)}
			]
		};
	}
}
