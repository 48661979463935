import { Injectable }                     from '@angular/core';
import { Query }                          from '@datorama/akita';
import { ReportingState, ReportingStore } from './reporting.store';

@Injectable({providedIn: 'root'})
export class ReportingQuery extends Query<ReportingState> {

	constructor(protected store: ReportingStore) {
		super(store);
	}

}
