<div class="cs-statistics cs-performance-stats" >
  <div *ngIf="statisticData?.headerLabel"
       class="cs-statistics--header">
    <b class="cs-statistics--header--label">
      {{statisticData?.headerLabel}}
    </b>
  </div>
  <div class="cs-statistics-data">
    <div class="row">
      <div class="col-6 summary-values" *ngFor="let data of statisticData.data.summaryValues">
        <label>{{data.label}}</label>
        <div class="value" title="{{data.description}}">{{data.value}}</div>
      </div>
    </div>

    <cs-statistics-panel-comparison-bar [data]="barData"
                                        *ngFor="let barData of statisticData.data.comparisonBars">
    </cs-statistics-panel-comparison-bar>
  </div>
</div>
