import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { DashboardCombiDataIndicator }           from '../../models/dashboard-combi-data-indicator';
import { DashboardCombiDataIndicatorMeta }       from '../../models/dashboard-combi-data-indicator-meta';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { PopoverRef }                            from '@cs/components/popover';
import { ArrayUtils }                            from '@cs/core';

@Component({
						 selector:    'cs-dashboard-list',
						 templateUrl: './dashboard-list.component.html',
						 styles:      [
							 `
				   .dashboard-list-container {
					   min-width: 112px;
					   max-width: 165px;
				   }

				   ul {
					   padding-left: 0;
					   margin-bottom: 0;
				   }

				   li {
					   list-style: none;
					   margin-bottom: 0.5em;
				   }

				   li a {
					   margin-right: 16px;
				   }

				   label {
					   color: inherit;
					   font-weight: 500;
					   font-size: 12px;
					   margin-bottom: 0.5em;
				   }

				   small {
					   margin-right: 0.9em;
					   margin-bottom: 0.7em;
					   border: 1px solid white;
					   padding: 0.2em 0.5em;
					   border-radius: 3px;
					   font-size: 10px;
				   }

				   .dashboard-list-group:not(:first-child) {
					   margin-top: 0.8em;
				   }
							 `
						 ]
					 })
export class DashboardCombiListComponent extends DashboardPanelComponentBase<DashboardCombiDataIndicatorMeta> implements OnInit {
	name: string;

	constructor(@Inject(forwardRef(() => PopoverRef)) popover: PopoverRef<DashboardCombiDataIndicator>) {
		super();
		this.data = popover.data.meta;
	}

	ngOnInit() {
	}

	update(data: DashboardCombiDataIndicatorMeta): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

	dataChanged(value: DashboardCombiDataIndicatorMeta) {

	}


}
