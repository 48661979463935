import { GridHeaderCell }                  from '../models/grid-head-cell.model';
import { IDataEntryInfo, IPopoverContext } from '../interfaces';


export class GridSheetMetaValues {
	/**
	 * Indicates if sheet is in an nested grid
	 */
	isInNested: boolean = null;
	hasData             = false;
	/**
	 * Original number of rows so we can easily detect deleted rows
	 */
	initialNrOfRows     = 0;

	constructor(initial?: Partial<GridSheetMetaValues>) {
		Object.assign(this, initial);
	}
}

export class PopoverCalculations {
	/**
	 * Stores the AWB based on the TONS
	 */
	awb           = 0;
	/**
	 * The LOOSE percentage of loose/ bup ratio

	 */
	loose_bup     = 0;
	/**
	 * The IMPORT percentage of import/ export ratio
	 */
	import_export = 0;
	/**
	 * The LOCAL percentage of local/ transit ratio
	 */
	local_transit = 0;
}

export class PopoverContext implements IPopoverContext {
	info: IDataEntryInfo;
	column: GridHeaderCell;
	currentLabel: string;
	commentTypes: {
		label: string;
		id: string
	}[];
	commentTypeSelected: string;
	comment: string;
	calc: PopoverCalculations = new PopoverCalculations();
	sliderIsTouched           = false;

	constructor(initial?: Partial<PopoverContext>) {
		Object.assign(this, initial);
	}
}

export class SheetActions {
	cancelAction: Function;
	addAction: Function;
}

export enum DataEntrySaveMethod {
	AutoSave     = 'AutoSave',
	BottomBar    = 'BottomBar',
	TopButtonBar = 'TopButtonBar',
}


