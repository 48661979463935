export class RowButton {
	description: string;
	disabled: (row: any) => boolean;
	label: string;
	name: string;
	iconClass: string;
	btnClass?: string;
	tooltipPosition = 'above';

	constructor(label: string, name: string, description: string, disabled?: (row: any) => boolean) {
		this.label       = label;
		this.name        = name;
		this.description = description;
		this.disabled    = disabled;
	}
}
