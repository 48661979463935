<form class="cs-form-generator" novalidate [formGroup]="formGroup" (ngSubmit)="onSaveForm(formGroup.valid)"
      [class.cs-not-in-edit-mode]="!isInEditMode"
      [class.cs-layout-vertical]="!isLayoutHorizontal()"
      [class.cs-form-generator-edit-mode-single]="editMode === 'single'">

  <fieldset *ngIf="dataSource">
    <div class="fieldset-content">

      <!--- Revert button --->
      <div class="text-right">
        <a class="btn btn-secondary btn-sm cs-pointer"
           *ngIf="isInEditMode && showRevert && !formGroup.pristine" (click)="onRevertForm()">Revert</a>
      </div>

      <div *ngFor="let row of dataSource.form.elements" class="row" [class.hidden]="!hasVisibleMembers(row.elements)">
        <div *ngFor="let field of row.elements" [className]="'col form-element'">

          <!--TODO: Add [className]="field.class">-->
          <div class="form-group" (click)="onToggleSaveField($event, field.name)" [class.row]="isLayoutHorizontal()"
               [class.hidden]="field.hidden">
            <label [class.col-form-label]="isLayoutHorizontal()" [class.col-sm-3]="isLayoutHorizontal()"
                   [class.reporting-style] [for]="field.name"
                   *ngIf="field.uiType !== 'select'">
              {{field.label}}
            </label>
            <div [class.col-sm-9]="isLayoutHorizontal()"
                 [ngSwitch]="field.uiType"
                 [ngClass]="field.uiType + '_switch'"
                 [class.cs-form-generator-active-save-field]="activeSaveFieldName === field.name">
              <div class="inner">

                <!-- Text only version of the field value -->
                <div class="field-value-as-text" *ngIf="!isInEditMode && field.uiType !== 'fileselect'">
                  <ng-container *ngIf="field.uiType === 'datepicker'">
                    {{fieldValuesAsString[field.name] | date:"dd-MM-yyyy"}}
                  </ng-container>
                  <ng-container *ngIf="field.uiType !== 'datepicker'">
                    {{fieldValuesAsString[field.name]}}
                  </ng-container>
                </div>

                <!-- Input control for the field value -->
                <div class="field-value-as-control" *ngIf="isInEditMode || field.uiType === 'fileselect'">
                  <input *ngSwitchCase="'text'" [type]="field.uiType" [formControlName]="field.name"
                         class="form-control" [id]="field.name"
                         [placeholder]="field.placeholder || ''" [attr.aria-describedby]="field.name + '-help'">

                  <input *ngSwitchCase="'password'" [type]="field.uiType" [formControlName]="field.name"
                         class="form-control" [id]="field.name"
                         [placeholder]="field.placeholder || ''" [attr.aria-describedby]="field.name + '-help'">

                  <textarea *ngSwitchCase="'textarea'" [formControlName]="field.name" class="form-control"
                            [id]="field.name"
                            [placeholder]="field.placeholder || ''" [attr.aria-describedby]="field.name + '-help'">
                  </textarea>

                  <span *ngSwitchCase="'radio'" class="btn-group" data-toggle="buttons">
                  <label class="btn btn-radio" [ngClass]="{'active': currentFormGroupValue[field.name]}">
                    <input
                      [type]="field.uiType"
                      [formControlName]="field.name"
                      class="form-control"
                      [id]="field.name"
                      [placeholder]="field.placeholder"
                      [attr.aria-describedby]="field.name + '-help'">
                      <i class="icon icon-check"></i>
                  </label>
                </span>

                  <span *ngSwitchCase="'checkbox'" data-toggle="buttons">
                  <label class="btn btn-checkbox" [ngClass]="{'active': currentFormGroupValue[field.name]}">
                    <input
                      [type]="field.uiType"
                      [formControlName]="field.name"
                      class="form-control"
                      [id]="field.name"
                      [placeholder]="field.placeholder"
                      [attr.aria-describedby]="field.name + '-help'">
                      <i class="icon icon-check"></i>
                  </label>
                </span>

                  <input *ngSwitchCase="'range'" [type]="field.uiType" [formControlName]="field.name"
                         class="form-control" [id]="field.name"
                         [placeholder]="field.placeholder" [attr.aria-describedby]="field.name + '-help'">

                  <input *ngSwitchCase="'number'" [type]="field.uiType" [formControlName]="field.name"
                         class="form-control" [id]="field.name"
                         [placeholder]="field.placeholder || ''" [attr.aria-describedby]="field.name + '-help'">

                  <!-- Combobox Issues:
                      * [Filter] does not work in form-generator (focus not correct, cancels form)
                      * Fixed with Field
                   -->
                  <cs-combobox *ngSwitchCase="'combobox'" [formControlName]="field.name" class="form-control"
                               [id]="field.name"
                               [placeholder]="field.placeholder || ''" [attr.aria-describedby]="field.name + '-help'"
                               [multiple]="true"
                               [options]="comboboxOptions[field.name]"
                               [customInput]="false"
                               [filter]="true"
                  ></cs-combobox>

                  <!-- Standard "simple" Select element -->
                  <div *ngSwitchCase="'simple-select'" class="select-control-wrapper"
                       [class.hover-pointer]="isInEditMode">
                    <div class="select-control-text">
                      <select [formControlName]="field.name" [compareWith]="selectCompare" class="form-control"
                              [id]="field.name" [attr.aria-describedby]="field.name + '-help'">
                        <option *ngFor="let x of getLookupValues(field.lookup)" [ngValue]="x.key">{{ x.value }}</option>
                      </select>
                      <!--                      <i class="icon icon-arrow"></i>-->
                    </div>

                  </div>


                  <div *ngSwitchCase="'select'" class="advanced-dropdown-form-wrapper">
                    <cs-advanced-dropdown [dataSource]="advancedDropDownDataSources[field.name]"
                                          [formControlName]="field.name" class="form-control"
                                          [id]="field.name" [attr.aria-describedby]="field.name + '-help'">
                    </cs-advanced-dropdown>
                  </div>


                  <!-- When a new dataSource is set or revert is called, datepicker component fails to register in the formGroup -->
                  <!-- See: https://github.com/angular/angular/issues/14057 -->
                  <cs-datepicker *ngSwitchCase="'datepicker'" [formControl]="formGroup.controls[field.name]"
                                 [id]="field.name" [outputFormat]="dateFormat"
                                 [attr.aria-describedby]="field.name + '-help'"></cs-datepicker>

                  <cs-form-field-list
                    *ngIf="field.uiType === 'checkboxlist' || field.uiType === 'checkboxgrid' || field.uiType === 'radiolist' || field.uiType === 'radiogrid'"
                    [formControlName]="field.name" [type]="field.uiType" [lookupValues]="getLookupValues(field.lookup)"
                    [height]="field.elementParameters?.height"
                    [isScrollable]="field.elementParameters?.isScrollable"
                    [showAsHorizontalList]="field.elementParameters?.showAsHorizontalList"
                    [gridColumns]="field.elementParameters?.gridColumns">
                  </cs-form-field-list>

                  <cs-form-field-tree
                    *ngIf="field.uiType === 'checkboxtree' || field.uiType === 'radiotree'"
                    [formControlName]="field.name" [type]="field.uiType"
                    [tree]="getTree(field.lookup)"
                    [elementParameters]="field.elementParameters">
                  </cs-form-field-tree>

                  <!-- File select -->
                  <cs-file-select *ngSwitchCase="'fileselect'" [formControl]="formGroup.controls[field.name]"
                                  [id]="field.name" [attr.aria-describedby]="field.name + '-help'"
                                  (fileDownloadRequest)="fileDownloadRequested($event)"
                                  [accept]="field.elementParameters?.acceptExtMime"
                  ></cs-file-select>

                  <!--Field Actions-->
                  <div class="cs-form-generator-buttons"
                       [ngClass]="{'hidden':isInEditMode}"
                       *ngIf="showFieldButtons && !isInEditMode && field.fieldActions !== null">
                    <a *ngFor="let action of field.fieldActions" class="btn btn-secondary btn-sm cs-pointer"
                       (click)="onFieldAction(action.id, field, $event)"
                       [innerHtml]="action.name"></a>
                  </div>
                </div>
              </div>

              <!--Field save buttons-->
              <div class="btn-group" role="group" *ngIf="activeSaveFieldName === field.name">
                <button type="button" class="btn btn-secondary" (click)="onToggleSaveField($event)"><i
                  class="material-icons">close</i></button>
                <button type="button" class="btn btn-secondary" (click)="onSaveField(field, $event)"><i
                  class="material-icons">done</i></button>
              </div>

              <!--Field validations-->
              <div class="cs-form-control-feedback" *ngIf="showValidation(field.name, 'required')">Required</div>
              <div class="cs-form-control-feedback" *ngIf="showValidation(field.name, 'pattern')">Pattern not Correct
              </div>
              <!--<div class="cs-form-control-feedback" *ngIf="showValidation(field.name, 'custom')">Error</div>-->

              <!--Field help-->
              <small [id]="field.name + '-help'" class="form-text text-muted">{{field.help}}</small>

            </div>
          </div>
        </div>
      </div>

      <!-- -->
      <div class="text-right btn-group btn-group-sm" *ngIf="!readOnly && editMode !== 'editonly' && !formGroup.pristine">
        <button class="btn btn-quaternary cs-pointer float-left" type="button" *ngIf="!isInEditMode"
                (click)="toggleIsInEditMode()">
          <i class="mdi mdi-lead-pencil" [hidden]="editBtnLabel"></i>{{editBtnLabel}}
        </button>
        <button class="btn btn--md cs-pointer float-left" type="reset"
                *ngIf="isInEditMode"
                (click)="onCancelForm()">
          Cancel
        </button>
        <button *ngIf="isInEditMode"
                class="btn btn--primary btn--md cs-pointer"
                [disabled]="formGroup.pristine"
                type="submit"
        >Save
        </button>
      </div>
    </div>
  </fieldset>

</form>
