
<input matInput class="mat-select-search-input mat-select-search-hidden"/>

<div class="mat-select-search-inner"
     [ngClass]="{'mat-select-search-inner-multiple': matSelect.multiple}">
  <input csInput
         class="mat-select-search-input"
         autocomplete="off"
         #searchSelectInput
         (keydown)="_handleKeydown($event)"
         (input)="onInputChange($event.target.value)"
         (blur)="onBlur($event.target.value)"
         [placeholder]="placeholderLabel"
  />
  <button mat-button *ngIf="value"
          mat-icon-button
          aria-label="Clear"
          matTooltip="Clear"
          (click)="_reset(true)"
          class="mat-select-search-clear">
    <i class="mdi mdi-format-clear"></i>
  </button>
  <button mat-button *ngIf="!value && matSelect?.value?.length > 0"
    mat-icon-button
    aria-label="Deselect all"
    matTooltip="Deselect all"
    (click)="deselectAll()"
    class="mat-select-search-clear">
    <i class="mdi mdi-checkbox-intermediate"></i>
  </button>
</div>

<div *ngIf="noEntriesFoundLabel && value && displayOptions.values?.length === 0"
     class="mat-select-search-no-entries-found">
  {{noEntriesFoundLabel}}
</div>
