import {
	ChangeDetectorRef, Component, ElementRef, Inject, OnInit
}                                                                                 from '@angular/core';
import { DomSanitizer, SafeHtml }                                                 from '@angular/platform-browser';
import { DataTransforms }                                                         from '@cs/common';
import { FormatProviderService }                                                  from '@cs/common/culture';
import { CsPopoverData, CsPopoverOverlayBase, CsPopoverOverlayRef, GridDataCell } from '@cs/components/data-grid';
import { FilterCompareBarQuery }                                                  from '@cs/components/filter-and-compare-bar';
import { LookupAgent }                                                            from '@cs/components/shared';
import { isNullOrUndefined }                                                      from '@cs/core';
import { LoggerUtil, replacePlaceholders, replacePlaceholdersWithExpressions }    from '@cs/core/utils';
import { TranslateService }                                                       from '@ngx-translate/core';
import { Observable }                                                             from 'rxjs';

@Component({
												selector   : 'pmc-data-source-html-hover',
												templateUrl: './data-source-html-hover.component.html'
											})

export class DataSourceHtmlHoverComponent extends CsPopoverOverlayBase implements OnInit {

	mainPresetLabel$: Observable<string>;
	comparebarPresetLabel$: Observable<string>;
	offsetPresetLabel$: Observable<string>;

	datasources: Array<{
		iddatasource: number,
		value: number
	}>;
	compareDatasources: Array<{
		iddatasource: number,
		value: number
	}>;
	hasCompareCell: boolean;
	hasCompareData: boolean;

	htmlContent: SafeHtml;
	itemForMapping: any;

	constructor(public element: ElementRef,
													private sanitizer: DomSanitizer,
													public cdr: ChangeDetectorRef,
													@Inject(FilterCompareBarQuery) public filterCompareBarQuery: FilterCompareBarQuery,
													@Inject(CsPopoverData) public data: GridDataCell,
													@Inject(FormatProviderService) private format: FormatProviderService,
													@Inject(TranslateService) private i8n: TranslateService,
													@Inject(CsPopoverOverlayRef) public overlayRef: CsPopoverOverlayRef) {
		super();
		const clone = data.cellData.map(item => Object.assign({}, item));

		// check if there is a compare cell
		this.hasCompareCell = !isNullOrUndefined(data.compareCell);

		this.datasources = DataTransforms.mergeSameDesignations(clone);

		this.itemForMapping = {
			'CellDisplayValue'          : this.format.formatByString(data.value, '{0:N2}'),
			'CellValue'                 : data.value,
			'DatasourceBreakdown'       : this.createDatasourceBreakdown(this.datasources, false, data.metaValues.relDifference),
			'CompareDatasourceBreakdown': ''
		};

		//	const template = '<div> ${DatasourceBreakdown}🧓👱‍♂️ <small>${CellValue}|format:\'{0:N3}\'</small>a das dasdads${CompareDatasourceBreakdown}</div>';
		let template = '${DatasourceBreakdown}${CompareDatasourceBreakdown}';
		if (data.tooltip && data.tooltip.templateHtml) {
			template = data.tooltip.templateHtml;
		} else {
			LoggerUtil.log('No template found. Revert to default breakdown');
		}

		if (this.hasCompareCell) {
			this.hasCompareData                               = data.compareCell.value !== null;
			const compareClone                                = data.compareCell.cellData.map(item => Object.assign({}, item));
			this.compareDatasources                           = DataTransforms.mergeSameDesignations(compareClone);
			this.itemForMapping['CompareDatasourceBreakdown'] = this.createDatasourceBreakdown(this.compareDatasources, true);
		}

		this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(replacePlaceholdersWithExpressions(this.itemForMapping, template, this.format));
	}

	ngOnInit(): void {
		super.initPopover();
		this.mainPresetLabel$       = this.filterCompareBarQuery.select(store => store.mainbarPresetLabel);
		this.comparebarPresetLabel$ = this.filterCompareBarQuery.select(store => store.comparebarPresetLabel);
		this.offsetPresetLabel$     = this.filterCompareBarQuery.select(store => store.offsetPresetLabel);


	}

	private createDatasourceBreakdown(datasources: any[], isCompare: boolean, relDifference?: number): string {
		let breakdown               = '';
		const mainPresetLabel       = this.filterCompareBarQuery.getValue().mainbarPresetLabel;
		const comparebarPresetLabel = this.filterCompareBarQuery.getValue().comparebarPresetLabel;
		const offsetPresetLabel     = this.filterCompareBarQuery.getValue().offsetPresetLabel;

		const label        = isCompare
																							? (comparebarPresetLabel)
																							: (mainPresetLabel);
		const compareLabel = (comparebarPresetLabel);

		datasources.forEach(source => {
			breakdown += `<div class="d-flex flex-row justify-content-between data-row">
                <span class="data-hover-label text-truncate">${label} ${isCompare
																																																																								? ''
																																																																								: (relDifference && relDifference !== 0
																																																																											? `<span class="offset-label">(${this.format.formatByString((relDifference - 1) * 100, '{0:N2}')} %)</span>`
																																																																											: '')}</span>
                <span class="data-hover-value border-bottom-1">${this.format.formatByString(source.value, '{0:N2}')}</span>
            </div>`;
			breakdown += `<div class="d-flex flex-row justify-content-between data-source-item data-row">
                <span class="data-source-item-label text-truncate" title="${source.iddatasource}">
                    <i class="mdi mdi-database mr-1"></i> ${this.getLookupValue(source.iddatasource, 'IdDataSource')}
                </span>
                <span class="data-hover">${this.format.formatByString(source.value, '{0:N2}')}</span>
            </div>`;
		});

		if (isCompare && !this.hasCompareData) {
			breakdown += `<div class="d-flex flex-row justify-content-between data-row">
                <span class="data-hover-label text-truncate">
                    <i class="mdi mdi-database mr-1"></i> ${compareLabel}
                </span>
                <span class="data-hover-value border-bottom-1">${this.i8n.instant('NO_DATA')}</span>
            </div>`;
		}


		return breakdown;
	}

	private getLookupValue(value: any, lookupKey: string): any {
		if (isNullOrUndefined(value) || isNullOrUndefined(lookupKey)) return value;
		return LookupAgent.resolve(value, lookupKey);
	}

}
