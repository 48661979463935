import { IConfigService, ActionButtonResult }                  from '@cs/performance-manager/shared';
import { ApprovalComment, ApprovalDataDescribed, CommentData } from './models/approval-data-described';
import { Observable }                                          from 'rxjs';
import { Result }                                              from '@cs/core';
import { ApprovalOverviewDataDescribed }                       from './models/approval-overview-data-described';

export abstract class ApprovalConfigService implements IConfigService {

	moduleName = 'approval';

	abstract postApprovalComment(scopeName: string,
															 comment: ApprovalComment,
															 selection: { [p: string]: any },
															 params: { [p: string]: any }): Observable<Result<CommentData>>;

	abstract postApprovalButton(scopeName: string,
															buttonName: string,
															selection: { [p: string]: any },
															params: { [p: string]: any }): Observable<Result<ActionButtonResult>>;

	abstract getApprovalData(scopeName: string,
													 selection: { [p: string]: any },
													 params: { [p: string]: any }): Observable<Result<ApprovalDataDescribed>>;

	abstract getApprovalOverviewData(scopeName: string,
																	 selection: { [p: string]: any },
																	 params: { [p: string]: any }): Observable<Result<ApprovalOverviewDataDescribed>>;

}
