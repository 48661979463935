import { ToastConfig } from '@cs/components/toast-manager';


export const ToastConfigUnilode: ToastConfig = {
	position:     {
		bottom: 20,
		top:    0,
		right:  20
	},
	animation:    {
		fadeOut: 200,
		fadeIn:  200
	},
	timeOut:      10000,
	pauseOnHover: true
};
