<div class="pm-dashboard-container">
  <div [@loadingPanelState]="isLoadingDebounced$ | async">
    <cs-dashboard [data]="data"
                  #dashboard
                  (dashboardEntityClicked)="onDashboardEntryClick($event)"
                  (downloadButtonClicked)="onDownloadButtonClicked($event)"
                  (panelOptionSelected)="onPanelOptionSelected($event)"
                  (newDashboardRendered)="newDashboardRendered($event)"
                  (showDetailsButtonClicked)="onShowDetailsButtonClick($event)"
				  (applicationTriggerRequested)="onApplicationTriggerRequested($event)"
                  (notifyChangesToServer)="onNotifyChangesToServer($event)">
    </cs-dashboard>
  </div>
</div>
