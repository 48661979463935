export enum LayoutOrientation {

	/**
	 * Render each item next to each other
	 */
	Horizontal = 'horizontal',
	/**
	 * Render each item under each other
	 */
	Vertical   = 'vertical'
}
