import { CellClickedType, PopoverTypes, UiTypes } from '../enums/data-grid.enum';
import { isUndefined }                            from '@cs/core';

export class DataGridUIState {
  uiType?: UiTypes;
  dirty?: boolean;
  isHidden?: boolean;
  hasDataEvents?: boolean;
  hasPopoverHover?: PopoverTypes;
  hasPopoverClick?: CellClickedType;
  _previousUiType: UiTypes;
  invalid: boolean;
  hasReadOnlyVersion: boolean;
  allowEmptyValuesToSave: boolean;


  constructor(initial?: Partial<DataGridUIState>) {
    if (initial === null)
      this.setDefaults();
    else if (!isUndefined(initial))
      Object.assign(this, initial);
  }

  setDefaults() {
    this.dirty                  = false;
    this.isHidden               = false;
    this.hasPopoverHover        = PopoverTypes.None;
    this.hasPopoverClick        = CellClickedType.None;
    this.hasDataEvents          = false;
    this.invalid                = false;
    this.hasReadOnlyVersion     = true;
    this.allowEmptyValuesToSave = false;
  }
}
