<div class="cs-chart-panel" #chartPanelWrapper
     [@isCollapsed]="collapsed"
>
  <div class="row">
    <div class="statistics">
      <cs-statistics-panel #statisticsPanel
                           [title]="title"
                           [isLoading]="isStatsLoading"
                           [dataSource]="primaryStatisticsData"
                           (tabSwitched)="tabSwitched.emit($event)">
      </cs-statistics-panel>
    </div>

    <div class="statistics" *ngIf="secondStatisticsData?.length > 0">
      <cs-statistics-panel #secondStatisticsPanel
                           [title]="title"
                           [isLoading]="isStatsLoading"
                           [dataSource]="secondStatisticsData"
                           (tabSwitched)="tabSwitched.emit($event)">
      </cs-statistics-panel>
    </div>


    <div class="chart">
      <div class="main-chart">
        <div class="hide-line-div"></div>

        <div class="chart-area"
             [class.isLoading]="isChartLoading"
             cs-chart-loader
             [chartData]="chartDataTable"
             [chartOptions]="chartOptions"
             [chartType]="chartType">
          <!--<ng-template #dataIncompleteContent>-->
          <!--<span class="incomplete-data-message">{{'DATA_NOT_COMPLETE' | translate}}</span>-->
          <!--</ng-template>-->
        </div>
        <div class="chart-loader"
             @chartPanelState
             *ngIf="isLoadingLonger$ | async">
          <div class="chart-loader-container">
            <div class="progress">
              <div class="progress-bar progress-bar-striped progress-bar-animated"
                   role="progressbar" aria-valuenow="75"
                   aria-valuemin="0" aria-valuemax="100"
                   style="width: 75%"></div>
            </div>
          </div>
        </div>
        <div class="chart-panel-legend-container"
             [@loadingPanelState]="isChartLoading">
          <ul class="chart-panel-legend">
            <li *ngFor="let serie of chartPanelData?.series" matTooltip="{{serie.label}}" [matTooltipPosition]="'left'">
              <div class="chart-panel-legend-circle-container">
                <svg width="8" height="8"
                     xmlns="http://www.w3.org/2000/svg">
                  <circle [ngStyle]="{
                    'fill':'#'+serie?.preset?.chartStyle.color
                    }"
                          cx="50%" cy="50%" r="4"/>
                </svg>
              </div>
              <span class="ml-2 text-truncate">{{serie.label}}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
