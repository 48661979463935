import { NgModule }                from '@angular/core';
import { CommonModule }            from '@angular/common';
import { DashboardPopupComponent } from './dashboard-popup.component';
import { TranslateModule }         from '@ngx-translate/core';
import { MatDialogModule }         from '@angular/material/dialog';
import { MatTooltipModule }        from '@angular/material/tooltip';
import { CsCultureModule }         from '@cs/common';
import { DashboardModule }         from '@cs/components/dashboard';
import { CsSharedModule }          from '@cs/components/shared';


@NgModule({
	entryComponents: [DashboardPopupComponent],
	declarations:    [DashboardPopupComponent],
	imports:         [
		CommonModule,
		CsSharedModule,
		DashboardModule,
		MatTooltipModule,
		MatDialogModule,
		TranslateModule.forChild(),
		CsCultureModule
	]
})
export class DashboardPopupModule {
}
