import { Injectable }                from '@angular/core';
import { Store, StoreConfig }        from '@datorama/akita';


export interface AppState {
  canAccessAdmin: boolean;
  isDebugUser: boolean;
  activeSection?: string;
  currentAppParamsScope?: { [key: string]: any };
  informationLabel?: Date;
  allowNegativeDataEntry?: boolean;
}

export function createInitialStateAppState(): AppState {
  return {
    canAccessAdmin:         false,
    isDebugUser:            false,
    activeSection:          null,
    currentAppParamsScope:  null,
    allowNegativeDataEntry: false,
    informationLabel:       null
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'app'})
export class AppStore extends Store<AppState> {

  constructor() {
    super(createInitialStateAppState());
  }

}

