import { getPropertyOf } from '@cs/core/utils';
import { ActionResult }  from './action-result.model';


export class GenericAction {

	/**
	 * The type of action
	 */
	type: 'headerButton';
	/**
	 * Text to display in the UI
	 */
	label?: string;
	/**
	 * Identifier for the action
	 */
	name: string;
	/**
	 * Action that needs to be executed
	 */
	actionCallback?: (...args: any[]) => void;
	/**
	 * Function to determine if the button is disabled
	 */
	isDisabled?: (...args: any[]) => ActionResult<boolean>;
	/**
	 * Show this message to enplaning the current state of the action, could be set in the IsDisabled method
	 */
	currentStateMessage?: string;

	constructor(init: Partial<GenericAction>) {
		this.type           = getPropertyOf(init, 'type', 'headerButton');
		this.label          = getPropertyOf(init, 'label');
		this.name           = getPropertyOf(init, 'name');
		this.actionCallback = getPropertyOf(init, 'actionCallback', () => {
			alert(`${name} has no callback`);
		});
		this.isDisabled     = getPropertyOf(init, 'isDisabled', () => new ActionResult(false));
	}
}
