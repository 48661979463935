import { Component, OnChanges, Input, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { opacityAndBlur, SafeMethods }                                             from '@cs/common';
import { trigger, transition, query, stagger, animate, style }                     from '@angular/animations';


@Component({
	selector:        'cs-placeholder',
	templateUrl:     './placeholder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations:      [
		opacityAndBlur('loadingPanelState'),
		trigger('listAnimation', [
			transition('* => *', [ // each time the binding value changes
				query(':leave', [
					stagger(50, [
						animate('300ms ease-out', style({opacity: 0, height: '0px'}))
					])
				], {optional: true}),
				query(':enter', [
					style({opacity: 0, transform: 'translate3d(0,-50px,0)'}),
					stagger(50, [
						animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)', style({
							opacity:   1,
							transform: 'translate3d(0,0,0)'
						}))
					])
				], {optional: true})
			])
		])
	]
})
export class CsPlaceholderComponent implements OnChanges {

	@Input() rows      = 1;
	@Input() columns   = 1;
	@Input() width     = '250px';
	@Input() height: string;
	@Input() isLoading = true;
	@Input() centered = false;
	grid               = [];

	constructor(private changeDetect: ChangeDetectorRef) {
	}

	ngOnChanges() {
		this.grid = [];
		for (let i = 0; i < this.rows; i++) {
			const row = [];
			for (let j = 0; j < this.columns; j++) {
				row.push('');
			}
			this.grid.push(row);
		}
	}

	detectChanges() {
		SafeMethods.detectChanges(this.changeDetect);
	}
}
