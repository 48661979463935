import { TabbedPageComponent } from './tabbed-page.component';
import { CsRoute }             from '@cs/common';


export class PmTabbedPageRoutingLoader {
	static getRoutes(children: CsRoute[]): CsRoute[] {
		return [{
			path:     '', component: TabbedPageComponent,
			children: [
				...children
			]
		}
		];
	}
}
