import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import {
	GitTableNavigationFilter, mapRepositoriesByLabel,
	RepositoryDto, RepositoryType
}                                                              from '@cs/performance-manager/git-graph';
import {
	SubmoduleUpdateDialogComponent
}                                                              from '@cs/performance-manager/submodule-update-dialog';
import { UntilDestroy, untilDestroyed }                        from '@ngneat/until-destroy';
import { filter as filter$ }                                   from 'rxjs/operators';
import { FilterCompareBarQuery, FilterCompareBarService }      from '@cs/components/filter-and-compare-bar';
import { isNullOrUndefined }                                   from '@cs/core/utils';
import { GitEnvironmentViewConfigService }                     from './git-environment-view-config.service';
import { MatDialog }                                           from '@angular/material/dialog';

@UntilDestroy()
@Component({
			   selector:    'pmc-git-environment-view',
			   templateUrl: './git-environment-view.component.html',
			   styleUrls:   ['./git-environment-view.component.scss']
		   })
export class GitEnvironmentViewComponent implements AfterViewInit {

	// Head repositories and their sub repositories
	public headRepositoryMap: Map<string, RepositoryDto[]>      = new Map<string, RepositoryDto[]>();
	public headRepositoryMapStore: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

	public branches: string[] = ['preview', 'develop', 'qa', 'master'];

	public repositoriesToUpdate: Map<RepositoryDto, Set<RepositoryDto>>[] = [];

	// Filter parameters
	public selectedParams: GitTableNavigationFilter = new GitTableNavigationFilter();

	constructor(@Inject(GitEnvironmentViewConfigService) public service: GitEnvironmentViewConfigService,
				@Inject(ChangeDetectorRef) public changeDetector: ChangeDetectorRef,
				@Inject(FilterCompareBarQuery) public filterCompareBarQuery: FilterCompareBarQuery,
				@Inject(FilterCompareBarService) public filterCompareBarService: FilterCompareBarService,
				@Inject(MatDialog) private dialog: MatDialog) {
	}

	ngAfterViewInit(): void {
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(untilDestroyed(this), filter$(value => !isNullOrUndefined(value)))
			.subscribe((resultParams) => {

				// Clear old selection
				this.repositoriesToUpdate.forEach((value, index, array) => {
					value.forEach((value, key, map) => {
						this.repositoriesToUpdate[index].get(key)
														.clear();
					});
				});

				this.selectedParams = new GitTableNavigationFilter();

				for (const key in resultParams.selection) {
					this.selectedParams[key] = resultParams.selection[key];
				}

				this.loadRepositoryData();
			});

	}

	loadRepositoryData() {
		console.log('Env filter selection initial');
		console.log(this.selectedParams);

		if (this.selectedParams.parentRepositoryLabel != undefined) {
			const parentRepositoryParameters = this.selectedParams.parentRepositoryLabel.split(' | ');

			this.selectedParams.parentRepositoryLabel = parentRepositoryParameters[0];

			// @ts-ignore
			if (parentRepositoryParameters[1] != undefined) this.selectedParams.parentApplicationType = parentRepositoryParameters[1];
		}

		if (this.selectedParams.childRepositoryName != undefined) this.selectedParams.childRepositoryName = this.selectedParams.childRepositoryName.split(' | ')[0];

		// Makes sure that neither lib or undefined gets passed to the filter
		if (this.selectedParams.parentApplicationType === RepositoryType.LIB || this.selectedParams.parentApplicationType === RepositoryType.UNDEFINED) this.selectedParams.parentApplicationType = RepositoryType.CDP;

		this.selectedParams.checkRecursive = false;

		console.log('Env filter selection');
		console.log(this.selectedParams);

		this.service.GetRepositories(this.selectedParams)
			.subscribe(result => {

				console.log('Result');
				console.log(result.value);

				const repositoryResult = result.value.filter(r => r.subModules.length > 0);

				const repositoryMap = mapRepositoriesByLabel(repositoryResult);

				this.headRepositoryMap      = repositoryMap;
				this.headRepositoryMapStore = repositoryMap;

				this.repositoriesToUpdate = [];

				this.changeDetector.markForCheck();
			});
	}

	getTotalSelectedAmount(): number {
		return this.repositoriesToUpdate.map((map) => {
					   let size = 0;

					   map.forEach((set) => {
						   size = size + set.size;
					   });

					   return size;
				   })
				   .reduce((a, b) => a + b, 0);
	}

	updateRepositoryEvent() {
		const uniqueSubmodules: RepositoryDto[] = [];

		this.repositoriesToUpdate.forEach(repositoryMap => {

			for (const key of repositoryMap.keys()) {
				if (!uniqueSubmodules.some(s => s.name == key.name) && repositoryMap.get(key).size > 0) {
					uniqueSubmodules.push(key);
				}
			}

		});

		const headRepositories: RepositoryDto[] = [];

		this.repositoriesToUpdate.forEach(repositoryMap => {
			repositoryMap.forEach(s => {
				s.forEach(r => {
					headRepositories.push(r);
				});
			});
		});

		this.openUpdateDialog(headRepositories, uniqueSubmodules);
	}

	// ---------------- Update Dialog ---------------- \\
	openUpdateDialog(headRepositories: RepositoryDto[], uniqueSubmodules: RepositoryDto[]) {

		const dialogRef =
				  this.dialog.open(SubmoduleUpdateDialogComponent, {
					  data: {
						  childRepository:    uniqueSubmodules[0],
						  parentRepositories: headRepositories,
						  afterUpdateEvent:   () => this.loadRepositoryData(),
						  allowSameBranch:    headRepositories.length > 1
					  }
				  });

		// dialogRef.afterClosed().subscribe(result => {
		// 	console.log(`Dialog result: ${result}`);
		// });

	}

}
