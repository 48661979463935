export type StepState = 'isActive' | 'ísChecked' | 'isDisabled' | 'isChecked' | 'isError' | 'isWarning';

export class Step {
	name: string;
	label: string;
	description: string;
	content: string;
	canNavigateBack: boolean;
	updateSteps: boolean;
	validateOnEnter: boolean;
	validateOnExit: boolean;
	validateOnChange: boolean;
	index: number;
	icon: string;
	data?: unknown;
	state: StepState        = 'isDisabled';
	updateOn?: 'change' | 'blur' | 'submit';
	/**
	 * error message propagated from the wizard step component
	 */
	showContextErrorMessage = true;

	constructor(init: Step) {
		Object.assign(this, init);
	}
}
