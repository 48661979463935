import { NgModule }             from '@angular/core';
import { CsHyperlinkDirective } from './cs-hyperlink.directive';

@NgModule({
    imports:      [],
    exports:      [CsHyperlinkDirective],
    declarations: [CsHyperlinkDirective]
  }
)
export class CsHyperlinkModule {
}
