import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { CsViewerComponent }             from './viewer.component';
import { CsPdfViewerModule }             from './components/pdf-wrapper/pdf-wrapper.module';
import { CsCommonPipesModule }           from '@cs/common';


@NgModule({
	declarations: [CsViewerComponent],
	exports:      [CsViewerComponent],
	imports:      [CommonModule,
								 CsPdfViewerModule,
								 CsCommonPipesModule
	]
})
export class CsViewerModule {
	static forRoot(): ModuleWithProviders<CsViewerModule> {
		return {ngModule: CsViewerModule};
	}
}
