import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, Injector, OnDestroy,
	OnInit, ViewChild, Inject, ElementRef
} from '@angular/core';
import {
	DashboardGridData,
	DashboardComponent as CsDashboardComponent
}                                                       from '@cs/components/dashboard';
import { FilterCompareBarQuery, FilterCompareBarStore } from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                        from '@cs/components/toast-manager';
import { DashboardConfigService }                       from './dashboard-config.service';
import { isNullOrUndefined }                            from '@cs/core';
import { UntilDestroy, untilDestroyed }                 from '@ngneat/until-destroy';
import { debounceTime, filter as filter$ }              from 'rxjs/operators';
import { TranslateService }                             from '@ngx-translate/core';
import { AppMessageHubService, opacityAndBlur }         from '@cs/common';
import { Subject }                                      from 'rxjs';
import { AppService }                                   from '@cs/performance-manager/shared';
import { SelectionTargetResult }                        from '@cs/core';
import { DashboardBase }                                from './dashboard-base';
import { handlePostAction, onDashboardEntryClick }      from './dashboard-helpers';
import { Router }                                       from '@angular/router';
import { AppNavigationService }                         from '@cs/common';
import { TabService }                                   from '@cs/performance-manager/tabbed-page';

@UntilDestroy()
@Component({
	selector:        'pmc-dashboard',
	templateUrl:     './dashboard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations:      [opacityAndBlur('loadingPanelState')]
})
export class DashboardComponent extends DashboardBase implements OnInit, OnDestroy {

	@ViewChild('dashboard', {static: true}) dashboard: CsDashboardComponent;
	@ViewChild('panelContentTopBar', {static: false, read: ElementRef}) panelContentTopBar: ElementRef;

	data: DashboardGridData;
	isLoading$: Subject<boolean> = new Subject();
	isLoadingDebounced$          = this.isLoading$.pipe(untilDestroyed(this), debounceTime(300));

	constructor(private readonly injector: Injector,
							readonly dashboardConfigService: DashboardConfigService,
							readonly filterCompareBarQuery: FilterCompareBarQuery,
							readonly filterCompareBarStore: FilterCompareBarStore,
							readonly appNavigationService: AppNavigationService,
							readonly appService: AppService,
							readonly cdRef: ChangeDetectorRef,
							readonly i8n: TranslateService,
							readonly toastService: CsToastManagerService,
							readonly router: Router,
							@Inject(TabService) readonly tabService: TabService,
							readonly appMessageHub: AppMessageHubService) {
		super(dashboardConfigService, filterCompareBarQuery, appNavigationService,
			appService, cdRef, i8n, toastService, router, tabService, appMessageHub);
	}

	ngOnInit() {

		// this.filterCompareBarStore.reset();
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
				.pipe(
					untilDestroyed(this),
					debounceTime(300), // A little workaround for firing twice when switching form home
					filter$(value => !isNullOrUndefined(value))
				)
				.subscribe((value) => {
					this.setData(value);
				});

		if (this.dashboardConfigService.initWithData)
			this.setData(null);
	}

	ngOnDestroy(): void {
	}

	onShowDetailsButtonClick($event: SelectionTargetResult) {
		this.onDashboardEntryClick($event);
	}

	onDashboardEntryClick($event: SelectionTargetResult) {
		onDashboardEntryClick($event, this.injector, this.dashboardConfigService, this.data.name)
			.then(value => handlePostAction(value, this.injector));
	}
}


