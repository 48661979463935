import { Injectable }            from '@angular/core';
import { Store, StoreConfig }    from '@datorama/akita';
import { FilterBarResultParams } from '../models/filter-bar-result-params';
import { SubFilterItem }         from '../models/filter-bar-sub-filter-item';

export interface FilterCompareBarState {
	mainbarResultParams?: FilterBarResultParams;
	comparebarResultParams?: FilterBarResultParams;
	mainbarPresetLabel?: string;
	comparebarPresetLabel?: string;
	mainbarApiParams?: { [key: string]: any };
	subFilterItems?: Array<SubFilterItem>;
	comparebarApiParams?: { [key: string]: any };
	offsetPresetLabel?: string;
	hasComparison?: boolean;
	isNotNavigatingAway?: boolean;
	specialParams?: { [p: string]: any };
}

export function createInitialState(): FilterCompareBarState {
	return {
		mainbarResultParams:    null,
		comparebarResultParams: null,
		mainbarPresetLabel:     null,
		offsetPresetLabel:      'Offset',
		hasComparison:          false,
		mainbarApiParams:       {},
		subFilterItems:         [],
		comparebarApiParams:    {},
		isNotNavigatingAway:    false,
		specialParams:          {}
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'filter-compare-bar', resettable: true})
export class FilterCompareBarStore extends Store<FilterCompareBarState> {

	constructor() {
		super(createInitialState());
	}

	/**
	 * force the 'private' storeValue field to be overridden, and therefore not triggering a change
	 * @param state the new state
	 */
	updateWithoutNotify(state: FilterCompareBarState) {
		this['storeValue'] = {...this._value(), ...state};
	}
}

