import {
	Directive, ElementRef, Input, OnChanges,
	Inject, OnDestroy, Output, EventEmitter, OnInit, NgZone
}                                                     from '@angular/core';
import {
	ChartItemClickEventArgs,
	CsChartLoaderSetup,
	CsChartProvider
}                                                     from './cs-chart-provider.interface';
import { ComponentChanges, LoggerUtil, whenChanging } from '@cs/core';
import { fromEvent }                                  from 'rxjs';
import { debounceTime }                               from 'rxjs/operators';
import { UntilDestroy, untilDestroyed }               from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({selector: '[cs-chart-loader]', exportAs: 'cs-chart-loader'})
export class CsChartLoaderDirective implements OnInit, OnChanges, OnDestroy {
	public _element: any;

	@Input('chartType') public chartType: string;
	@Input('chartOptions') public chartOptions: Object;
	@Input('chartData') public chartData: Object;

	@Output() chartElementClicked: EventEmitter<ChartItemClickEventArgs> = new EventEmitter();
	@Output() chartClicked: EventEmitter<MouseEvent>                     = new EventEmitter();

	get isLegendClickHandeld() {
		return this.chartProvider.isLegendClickHandeld;
	}

	set isLegendClickHandeld(value: boolean) {
		this.chartProvider.isLegendClickHandeld = value;
	}

	constructor(@Inject(CsChartLoaderSetup) private chartSetup: CsChartLoaderSetup,
				public element: ElementRef,
				private readonly ngZone: NgZone) {
		this._element           = this.element.nativeElement;
		this.chartProvider      = this.chartSetup.createNewChartProviderInstance();
		const resizeObservable$ = fromEvent(window, 'resize');
		resizeObservable$.pipe(
							 untilDestroyed(this),
							 debounceTime(300))
						 .subscribe(async (evt) => {
							 await this.refreshData();
						 });

		fromEvent(this._element, 'click')
			.pipe(
				untilDestroyed(this),
				debounceTime(300))
			.subscribe((value: MouseEvent) => {
				if (this.isLegendClickHandeld)
					this.isLegendClickHandeld = false;
				else
					this.chartClicked.emit(value);
			});


	}

	ngOnInit() {
		this.chartProvider.clickOutput$
			.pipe(untilDestroyed(this))
			.subscribe(eventArgs => this.chartElementClicked.emit(
				this.chartSetup.getMetaProperties(eventArgs,
												  this.chartProvider)));
	}

	ngOnChanges(changes: ComponentChanges<CsChartLoaderDirective>) {
		LoggerUtil.debug(changes);
		whenChanging(changes.chartData, true)
			.execute(async value => {
				LoggerUtil.debug('Draw chart');
				const options = !changes.chartOptions
								? null
								: changes.chartOptions.currentValue;
				await this.drawChart(value.currentValue, options);
			});

	}

	ngOnDestroy(): void {
	}

	async refreshData() {
		await this.drawChart(this.chartData, this.chartOptions);
	}

	resize() {
		if (typeof (Event) === 'function') {
			// modern browsers
			window.dispatchEvent(new Event('resize'));
		} else {
			// for IE and other old browsers
			// causes deprecation warning on modern browsers
			const evt = window.document.createEvent('UIEvents');
			(<any>evt).initUIEvent('resize', true, false, window, 0);
			window.dispatchEvent(evt);
		}
	}

	getDebugInfo(): any {
		return this.chartProvider.getDebugInfo();
	}

	hideLegend() {
		this.chartProvider.hideLegend(this.element.nativeElement);
	}

	hideColumn(column: number) {
		this.chartProvider.hideColumn(column);
	}

	private readonly chartProvider: CsChartProvider;


	private async drawChart(value: Object, chartoptions: Object) {

		if (chartoptions == null)
			LoggerUtil.debug('Chartoptions are null');

		this.chartProvider.drawChart(chartoptions,
									 this.chartType,
									 value,
									 this._element);

	}
}
