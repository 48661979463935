import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';
import { TableSettings }                from './table-settings.model';
import { TableHeaderRowLayout }         from './table-header-row.model';
import { TableDataGroupLayout }         from './table-data-group.model';
import { TableAnnotation }              from './table-annotation.model';


export class TableLayout<T> {
	/**
	 * Identification of the form
	 */
	id: string;
	/**
	 * Name to show in the UI
	 */
	label: string;
	/**
	 * Layout settings for the form
	 */
	layout: TableSettings;

	headerRows: TableHeaderRowLayout[] = [];

	dataGroups: TableDataGroupLayout[] = [];

	rowIndexMapper: Map<string, number> = new Map();

	pageSize: number;
	pageSizes: Array<number>;
	enablePaging: boolean;

	constructor(init: Partial<TableAnnotation<T>>) {
		this.id        = getPropertyOf(init, 'id');
		this.label     = getPropertyOf(init, 'label');
		this.layout    = hasPropertyOf(init, 'layout') ? new TableSettings(init.layout) : new TableSettings({});
		this.pageSizes = getPropertyOf(init, 'pageSizes');
	}

	getLastHeaderRow() {
		return this.headerRows[this.headerRows.length - 1];
	}
}
