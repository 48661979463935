import { isNullOrUndefined } from '@cs/core';

export interface PasswordPolicyGroup {
  label?: string;

  items?: Array<string>;

}

export interface PasswordChangeResult {
  success?: boolean;

  messages?: Array<string>;

}


export class CsPasswordPolicyGroup implements PasswordPolicyGroup {

  label: string;
  items: string[] = [];

  constructor(init?: Partial<PasswordPolicyGroup>) {
    if (!isNullOrUndefined(init))

      Object.assign(this, init);
  }

}

