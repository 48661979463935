import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ManagedByMeConfigService }             from './managed-by-me-config.service';
import { simpleFadeInOut }                      from '@cs/common';
import { DataDescribed }                        from '@cs/core';

@Component({
  selector:    'pmc-managed-by-me',
  templateUrl: './managed-by-me.component.html',
  styleUrls:   ['./managed-by-me.component.scss'],
  animations:  [
    simpleFadeInOut('isLoadingContent')
  ]
})
export class ManagedByMeComponent implements OnInit {

  data: DataDescribed<any>;
  isLoadingContent = false;

  constructor(private config: ManagedByMeConfigService,
              private changeRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    this.isLoadingContent = true;
    this.config.getResources(1)
        .subscribe(result => {
          this.data             = result.value;
          this.isLoadingContent = false;
          this.changeRef.markForCheck();
        });
  }

  onRowClicked($event: any) {

  }
}
