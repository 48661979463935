import {
	AfterContentInit, AfterViewInit, ChangeDetectionStrategy,
	ChangeDetectorRef, Component, ViewChild, Inject, OnDestroy, Optional
}                                                           from '@angular/core';
import { CsPlaceholderComponent }                           from '@cs/components/placeholder';
import { NewsItem }                                         from './models/news-item';
import { NewsConfigService }                                from './news-config.service';
import { NewsCategory }                                     from './models/news-category';
import { NewsListComponent }                                from './news-list/news-list.component';
import { simpleFadeInOut, WaitingForResponse, SafeMethods } from '@cs/common';
import { ActivatedRoute }                                   from '@angular/router';
import { TabService }                                       from '@cs/performance-manager/tabbed-page';
import { tap, debounceTime }                                from 'rxjs/operators';
import { Subject }                                          from 'rxjs';
import { UntilDestroy, untilDestroyed }                     from '@ngneat/until-destroy';
import { LoggerUtil }                                       from '@cs/core';

@UntilDestroy()
@Component({
			   selector       : 'pmc-news',
			   templateUrl    : './news.component.html',
			   styleUrls      : ['./news.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   animations     : [
				   simpleFadeInOut('isLoadingContent')
			   ]
		   })
export class NewsComponent implements AfterContentInit, OnDestroy, AfterViewInit {
	newsItems: Array<NewsItem> = [];
	isLoadingContent           = false;
	@ViewChild('newsListComponent', {static: true}) newsList: NewsListComponent;
	@ViewChild(CsPlaceholderComponent, {static: true}) placeholder: CsPlaceholderComponent;
	isSingleItem               = false;
	otherNews: Array<NewsItem> = [];
	rows$: Subject<number>     = new Subject();
	isLoadingDebounced$        = this.rows$.pipe(untilDestroyed(this), debounceTime(300));

	constructor(private newsConfig: NewsConfigService,
				private route: ActivatedRoute,
				private changeRef: ChangeDetectorRef,
				@Optional() @Inject(TabService) private tabService: TabService = {
					setInProgress: () => {
						LoggerUtil.warn('No Tabservice for NewsComponent');
					}
				} as any) {

	}

	ngAfterContentInit() {
		this.isLoadingContent = true;
		this.isSingleItem     = this.route.snapshot.params.hasOwnProperty('idNewsItem');
		if (this.isSingleItem) {
			const id = this.route.snapshot.params.idNewsItem;

			this.newsConfig.getNewsCategories()
				.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))))
				.subscribe((result) => {

					const categories            = result.value;
					let categorie: NewsCategory = null;

					if (categories.length > 0) {
						categorie = categories[0];
					}
					this.newsConfig.getNewsItem(id)
						.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))))
						.pipe(tap(() => this.rows$.next(0)))
						.subscribe(resultNewsItems => {
							this.newsItems = [resultNewsItems.value];
							this.newsList.detectChanges();
							this.placeholder.detectChanges();
							this.isLoadingContent = false;
						});

					this.newsConfig.getNewsItems(categorie.id)
						.subscribe(resultNewsItems => {
							this.otherNews = resultNewsItems.value;
							SafeMethods.detectChanges(this.changeRef);
						});

				});
		} else {
			// If not having a item id show all items
			this.newsConfig.getNewsCategories()
				.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))))
				.subscribe((result) => {
					const categories            = result.value;
					let categorie: NewsCategory = null;
					if (categories.length > 0) {
						categorie = categories[0];
					}
					this.newsConfig.getNewsItems(categorie.id)
						.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))))
						.pipe(tap(() => this.rows$.next(0)))
						.subscribe(resultNewsItems => {
							this.newsItems = resultNewsItems.value;
							this.newsList.detectChanges();
							this.placeholder.detectChanges();
							this.isLoadingContent = false;
							SafeMethods.detectChanges(this.changeRef);
						});

				});
		}

	}

	ngAfterViewInit(): void {
		this.rows$.next(2);
		this.placeholder.detectChanges();
	}

	ngOnDestroy(): void {
	}

	private maxItems = 5;

}
