import { Injectable }                                            from '@angular/core';
import { HttpClient }                                            from '@angular/common/http';
import { FilterCompareBarState, FilterCompareBarStore }          from './filter-compare-bar.store';
import { Subject }                                                              from 'rxjs';
import { FilterSelectionChangedEventArgs, SelectionChangedWithSearchEventArgs } from '@cs/components/advanced-dropdown';
import { FilterBarResultParams, FilterBarResultParamsSelection }                from '../models/filter-bar-result-params';
import { isNullOrUndefined }                                     from '@cs/core';
import { FilterBarDataSource }                                   from '../models/filter-bar-data-source';

export type CleanupAction = (params: { [key: string]: any }) => { [key: string]: any };

@Injectable({providedIn: 'root'})
export class FilterCompareBarService {


	filterbarSelectionChanged: Subject<FilterSelectionChangedEventArgs>     = new Subject();
	filterbarSearchChanged: Subject<SelectionChangedWithSearchEventArgs>     = new Subject();
	filterbarNavigationRequested: Subject<FilterBarResultParamsSelection>   = new Subject();
	toggleCompareBarChanged: Subject<boolean>                               = new Subject();
	toggleNavigationWarning: Subject<boolean>                               = new Subject();
	compareBarIsLoaded: Subject<FilterBarDataSource<FilterBarResultParams>> = new Subject();
	toggleCompareBarComplete: Subject<boolean>                              = new Subject();

	constructor(private filterCompareBarStore: FilterCompareBarStore) {
	}

	triggerNavigation(selection: FilterBarResultParamsSelection) {
		this.filterbarNavigationRequested.next(selection);
	}

	update(state: Partial<FilterCompareBarState>) {
		this.filterCompareBarStore.update(state);
	}

	updateWithoutNotify(state: Partial<FilterCompareBarState>) {
		this.filterCompareBarStore.updateWithoutNotify(state);
	}

	setMainbar(dataSource: FilterBarDataSource<FilterBarResultParams>) {
		if (isNullOrUndefined(dataSource))
			return;

		if (isNullOrUndefined(dataSource.resultParams))
			return;

		this.update({
									mainbarResultParams: dataSource.resultParams,
									mainbarPresetLabel:  dataSource.resultParams.presetLabel,
									mainbarApiParams:    dataSource.apiParams,
									subFilterItems:      dataSource.subFilterItems || [],
									specialParams:       {}
								});
	}

	setCompareBar(dataSource: FilterBarDataSource<FilterBarResultParams>) {
		if (isNullOrUndefined(dataSource)) {
			this.update({
										comparebarResultParams: null,
										comparebarPresetLabel:  null,
										comparebarApiParams:    {}
									});
			return;
		}

		if (isNullOrUndefined(dataSource.resultParams))
			return;

		this.update({
									comparebarResultParams: dataSource.resultParams,
									comparebarPresetLabel:  dataSource.resultParams.presetLabel,
									comparebarApiParams:    dataSource.apiParams
								});
	}

	/**
	 * Show or hide the Compare bar for the filterbar
	 * @param show The visibility of the compareBar
	 */
	toggleCompareBar(hasComparison: boolean): any {
		this.toggleCompareBarChanged.next(hasComparison);
	}

	toggleCompareButton(hasComparison: boolean) {
		this.update({
									hasComparison
								});
	}

	notifyCompareBarIsLoaded(comparebarDataSource: FilterBarDataSource<FilterBarResultParams>) {
		this.compareBarIsLoaded.next(comparebarDataSource);
	}

	setSpecialSaveParams(params: { [key: string]: any }) {
		this.update({
									specialParams: params
								});
	}

	showNoNavigationWarning() {
		this.toggleNavigationWarning.next(true);
	}

	hideNoNavigationWarning() {
		this.toggleNavigationWarning.next(false);
	}

	dropdownSearch(e: SelectionChangedWithSearchEventArgs): void {

		this.filterbarSearchChanged.next(e);
	}
}
