import { DashboardPanelInfoIcon } from './dashboard-panel-info-icon';


export enum DashboardPanelMetaInfoEnum {
	LittleLarger = 'LittleLarger'
}

export interface DashboardPanelMetaInfo {
	readonly infoIcons?: Array<DashboardPanelInfoIcon>;
	displayHints?: DashboardPanelMetaInfoEnum;
}
