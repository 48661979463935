// @ts-ignore
import { DeploymentDetails } from '@cs/common';
import config                from './environment.config.json';
// @ts-ignore
import details               from './deployment-details.json';


import { Environment } from '@cs/common';

interface DeploymentConfig {
	/**
	 * Use the mocking framework
	 */
	useMockData: boolean;
	/**
	 * Current running environment.
	 */
	environment: Environment;
	/**
	 * API Key for GoogleMaps
	 */
	googleMapsApiKey?: string;
	/**
	 * info about the deployment, used to set the environment details label
	 */
	deploymentDetails: DeploymentDetails;
	/**
	 * Set the location for the the transaction files
	 */
	translationLocation: string;

	/**
	 * Set relative root of the api from the domain.top. like '/api-endpoint-root'
	 */
	apiEndpoint: string;
}

interface CustomerAppConfig {

	/**
	 * The title shown in browser tab
	 */
	title: string;

	/**
	 * Enter the support email shown on login page
	 */
	supportEmail: string;

	/**
	 * Name of the customer
	 */
	customerName: string;
}


export const dsnSentry = config.dsnSentry;

export const baseCustomerVars: CustomerAppConfig = {
	title:        config.title,
	supportEmail: config.supportEmail,
	customerName: config.customerName
};

export const deploymentVars: DeploymentConfig = {
	environment:         config.environment as Environment,
	useMockData:         config.useMockData,
	apiEndpoint:         config.apiUrl, // Only change if the regular naming convention is not used
	translationLocation: config.translationLocation,
	googleMapsApiKey:    config.googleMapsApiKey,
	// Some weird stuff is happening when settin the deploymentDetails directly
	deploymentDetails: {
		buildDate:    details.buildDate,
		version:      details.version,
		repositories: details.repositories,
		detailsUrl:   details.detailsUrl
	}
};
