import { NgModule }              from '@angular/core';
import { DataEntryStateQuery }   from './data-entry-state.query';
import { DataEntryStateService } from './data-entry-state.service';
import { DataEntryStateStore }   from './data-entry-state.store';

@NgModule({
											providers: [
												DataEntryStateQuery, DataEntryStateService, DataEntryStateStore
											]
										})
export class DataEntryStateModule {

}
