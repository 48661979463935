import { fromEvent as observableFromEvent, Observable, Subscription }               from 'rxjs';
import { debounceTime, distinctUntilChanged }                                       from 'rxjs/operators';
import { Directive, ElementRef, Input, Output, EventEmitter, OnChanges, OnDestroy } from '@angular/core';
import { ComponentChanges, whenChanging }                                           from '@cs/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';


@UntilDestroy()
@Directive({selector: '[scroll-page]'})
export class CsScrollPageDirective implements OnChanges, OnDestroy {

	private _window: Window;
	private _minY             = 100;
	private _className        = '';
	private _debounceTime     = 300;
	private _scrollHandler: Observable<Event>;
	private _scrollHandlerSubscription: Subscription;
	private _temporaryTimeout = false;

	/**
	 * Specify the offset from the top of the page.
	 * @param  minY Set the offset in pixels
	 */
	@Input('scrollMin')
	set scrollMin(minY: number) {
		this._minY = minY || this._minY;
	}

	@Input() useElementHeight = true;

	/**
	 * Wait time assuring the scrolling is stopped
	 * @param  debounceTime Set debounce in ms
	 */
	@Input('debounceTime')
	set debounceTime(debounceTime: number) {
		this._debounceTime = debounceTime || this._debounceTime;
	}

	/**
	 * If the default sticky class isn't sufficient, specify the override here
	 */
	@Input('scrollClass')
	set scrollClass(className: string) {
		this._className = className || this._className;
	}

	/**
	 * Flag to stop the scroll detection event firing
	 */
	@Input()
	enableScrollDetection = true;

	@Output() onScroll: EventEmitter<any> = new EventEmitter();

	constructor(private _element: ElementRef) {
		this._window        = window;
		this._scrollHandler = observableFromEvent(this._window, 'scroll');
	}

	ngOnChanges(changes: ComponentChanges<CsScrollPageDirective>): void {
		whenChanging(changes.enableScrollDetection, true).execute(value => {
			if (value.currentValue) {
				this.subscribeForScrollEvent();
			} else {
				this.cancelSubscription();
			}
		});
	}

	subscribeForScrollEvent() {
		this.cancelSubscription();

		this._scrollHandlerSubscription = this._scrollHandler.pipe(
			untilDestroyed(this),
			debounceTime(this._debounceTime)).subscribe((e) => this.handleScrollEvent(e));
	}

	handleScrollEvent(e) {
		const scrollHeight = this.useElementHeight ? this._element.nativeElement.scrollHeight : document.body.scrollHeight;
		if (((this._window.innerHeight + this._window.pageYOffset ) >= (scrollHeight - this._minY)) && !this._temporaryTimeout) {
			this._temporaryTimeout = true;
			this.onScroll.emit(e);
		} else if (((this._window.innerHeight + this._window.pageYOffset) <= (scrollHeight - this._minY)) && this._temporaryTimeout) {
			this._temporaryTimeout = false;
		}
	}

	ngOnDestroy(): void {
		this.cancelSubscription();
	}

	private cancelSubscription() {
		if (this._scrollHandlerSubscription && !this._scrollHandlerSubscription.closed)
			this._scrollHandlerSubscription.unsubscribe();
	}
}
