import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Commit, RepositoryDto }                          from '@cs/performance-manager/git-graph';
import {
	UpdateRepositoryType
}                                                         from '../../models/submodule-update-dialog.models';

@Component({
			   selector:    'pmc-submodule-update-commit-info',
			   templateUrl: './submodule-update-commit-info.component.html',
			   styleUrls:   ['../../submodule-update-dialog.component.scss']
		   })
export class SubmoduleUpdateCommitInfoComponent implements OnInit {

	// Update dialog settings
	type: UpdateRepositoryType = UpdateRepositoryType.warning;

	@Input() public commits: Commit[] = [];
	@Input() public selectedRepository: RepositoryDto;

	@Output() removeEvent      = new EventEmitter<RepositoryDto>();
	@Output() pushEvent        = new EventEmitter<Map<string, RepositoryDto[]>>();
	@Output() cancel           = new EventEmitter<void>();
	@Output() cancelCommitInfo = new EventEmitter<void>();

	constructor() {
	}

	ngOnInit(): void {
	}

	RemoveCommitEvent(selectedRepository: RepositoryDto) {
		this.removeEvent.emit(selectedRepository);
	}

	PushRepositoryArrayAsync(repositoryDtos: RepositoryDto[]) {
		const map = new Map<string, RepositoryDto[]>();
		map.set(repositoryDtos[0].name, repositoryDtos);

		this.pushEvent.emit(map);
	}

	onNoClick() {
		this.cancel.emit();
	}

	back() {
		this.cancelCommitInfo.emit();
	}
}
