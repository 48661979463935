import { CommonModule }                        from '@angular/common';
import { MatTooltipModule }                    from '@angular/material/tooltip';
import { ModuleWithProviders, NgModule, Type } from '@angular/core';
import { HttpClient }                          from '@angular/common/http';
import { MatButtonToggleModule }               from '@angular/material/button-toggle';

import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';

import { CsCultureProvider }   from '@cs/common/culture';
import { LoggerUtil }          from '@cs/core/utils';
import { CsSharedModule }      from '@cs/components/shared';
import { CsCommonPipesModule } from '@cs/common/pipes';
import { NgbCollapseModule }   from '@cs/components/ngb-collapse';

import { CsTranslationLoader, IConfigService } from '@cs/performance-manager/shared';
import { CsApplicationSettings }               from '@cs/common';

import { SubmoduleUpdateDialogConfigService } from './submodule-update-dialog-config.service';
import { SubmoduleUpdateViewComponent }       from './components/submodule-update-view/submodule-update-view.component';
import { SubmoduleUpdateResultComponent }     from './components/submodule-update-result/submodule-update-result.component';
import { SubmoduleUpdateCommitInfoComponent } from './components/submodule-update-commit-info/submodule-update-commit-info.component';
import { SubmoduleUpdateServiceService }      from './services/submodule-update-service.service';
import { SubmoduleUpdateDialogComponent }     from './submodule-update-dialog.component';
import { FormsModule }                        from '@angular/forms';
import { MatDialogModule }                    from '@angular/material/dialog';

export function dialogUpdateTranslationLoader(client: HttpClient,
											  pmAppSettings: CsApplicationSettings,
											  service: IConfigService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`, client);
}


@NgModule({
			  declarations: [
				  SubmoduleUpdateDialogComponent,
				  SubmoduleUpdateViewComponent,
				  SubmoduleUpdateResultComponent,
				  SubmoduleUpdateCommitInfoComponent
			  ],

			  exports: [
				  SubmoduleUpdateDialogComponent
			  ],

			  imports: [
				  TranslateModule.forChild({
											   loader:  {
												   provide:    TranslateLoader,
												   useFactory: dialogUpdateTranslationLoader,
												   deps:       [HttpClient, CsApplicationSettings, SubmoduleUpdateDialogConfigService]
											   },
											   isolate: true
										   }),

				  CommonModule,
				  MatButtonToggleModule,
				  CsCommonPipesModule,
				  CsSharedModule,
				  NgbCollapseModule,
				  MatTooltipModule,
				  FormsModule,
				  MatDialogModule
			  ]
		  })

export class SubmoduleUpdateDialogModule {

	static forRoot(moduleConfig: Type<SubmoduleUpdateDialogConfigService>,
				   serviceConfig: Type<SubmoduleUpdateServiceService>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<SubmoduleUpdateDialogModule> {
		return {
			ngModule:  SubmoduleUpdateDialogModule,
			providers: [
				{
					provide:  SubmoduleUpdateDialogConfigService,
					useClass: moduleConfig
				},
				{
					provide:  SubmoduleUpdateServiceService,
					useClass: serviceConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};

	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use(culture.getCulture());
	}

}
