<div>
  <label class="mb-3">{{legendData.legendLabel}}</label>
  <ul>
    <li *ngFor="let status of legendData.indicators">
      <div class="d-flex justify-content-between align-items-center">
        <a class="datablock-with-content {{status?.layout?.class}}"
          [class.opacity-30]="status?.v === 0"
          [ngStyle]="status?.layout?.style">
        </a>
        {{status?.d}}
      </div>
    </li>
  </ul>

</div>
