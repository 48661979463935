import { AfterViewInit, ChangeDetectorRef, Component, Input, OnChanges } from '@angular/core';
import { ComponentChanges, getPropertyOf, hasPropertyOf, whenChanging }  from '@cs/core';
import { GaugeDataDescribed, GaugeType }                                 from './models/gauge-data-described';
import { isNullOrUndefined }                                             from '@cs/core';

@Component({
	selector:    'cs-gauge',
	templateUrl: './gauge.component.html'
})
export class GaugeComponent implements OnChanges, AfterViewInit {

	/**
	 * Data used to display the gauge
	 */
	@Input() dataSource: GaugeDataDescribed;
	/**
	 * Flag indicating how big the gauge should render
	 */
	@Input() size: 'md' | 'sm' | 'l' | 'xl' = 'md';
	/**
	 * Type of text used in the center of the gauge
	 */
	@Input() gaugeType                      = GaugeType.SINGLE_VALUE;

	/**
	 * The position of the arc
	 */
	entryStatePosition = {transform: 'rotate(0deg)'};
	/**
	 * Set the fomatting of the all the numbers is .net formatstring
	 */
	formatValue        = '{0:N0}';
	formatTotal        = '{0:N0}';
	formatThreshold    = '{0:N0}';
	formatStart        = '{0:N0}';
	/**
	 * The position of the provided threshold value, at this point a line will be drawn
	 */
	thresholdPosition: { transform: string };
	/**
	 * Counter the orientation of the threshold pointer, this makes sure the label is horizontal aligned
	 */
	thresholdLabelOrientation: { transform: string };
	/**
	 * Flag indicating that the gauge label should make room for the theshold label
	 */
	makeRoomForThresholdLabel: boolean;

	gaugeColor: { background?: string };


	constructor(private changeRef: ChangeDetectorRef) {
	}

	ngOnChanges(changes: ComponentChanges<GaugeComponent>) {
		whenChanging(changes.dataSource, true)
			.execute(changedvalue => {
				this.setArc(changedvalue.currentValue);

				if (hasPropertyOf(changedvalue.currentValue.dataAnnotation, 'fields')) {
					const fields         = changedvalue.currentValue.dataAnnotation.fields;
					const value          = fields.find(val => val.id === 'value');
					const total          = fields.find(val => val.id === 'total');
					const threshold      = fields.find(val => val.id === 'threshold');
					const start          = fields.find(val => val.id === 'start');
					this.formatValue     = (!isNullOrUndefined(value) && hasPropertyOf(value, 'format')) ?
						value.format : this.formatValue;
					this.formatTotal     = (!isNullOrUndefined(total) && hasPropertyOf(total, 'format')) ?
						total.format : this.formatTotal;
					this.formatThreshold = (!isNullOrUndefined(threshold) && hasPropertyOf(threshold, 'format')) ?
						threshold.format : this.formatThreshold;
					this.formatStart     = (!isNullOrUndefined(start) && hasPropertyOf(start, 'format')) ?
						start.format : this.formatStart;
				}
			});
	}

	private calcArc(value: number, total: number, threshold: number = null, color: string = '#000') {
		this.entryStatePosition = {transform: `rotate(${(180 / total) * value}deg)`};
		if (threshold) {
			const rotation                 = (180 / total) * threshold;
			this.makeRoomForThresholdLabel = rotation >= 70 && rotation <= 110;
			this.thresholdPosition         = {transform: `rotate(${rotation}deg)`};
			this.thresholdLabelOrientation = {transform: `translate(-170%, 0) rotate(${-rotation}deg)`};
		} else {
			this.thresholdPosition         = {transform: `rotate(0deg)`};
			this.thresholdLabelOrientation = {transform: `scale(0)`};
		}

		if (color)
			this.gaugeColor = {background: color};

		this.changeRef.detectChanges();
	}

	ngAfterViewInit(): void {
	}

	private setArc(currentValue: GaugeDataDescribed) {
		try {
			const {value, total, threshold, color} = currentValue.data;
			if (hasPropertyOf(currentValue, 'layout')) {
				const layout   = currentValue.layout;
				this.gaugeType = getPropertyOf(layout, 'gaugeType', this.gaugeType);
			}

			this.calcArc(value, total, threshold, color);
		} catch (e) {
			this.calcArc(0, 0);
		}
	}
}
