import { ErrorHandler, Injectable, Inject, InjectionToken }                                  from '@angular/core';
import { LoggerUtil }                                                                        from '@cs/core';
import { HttpErrorResponse, HttpHeaders, HttpParams }                                        from '@angular/common/http';
import { interval, Observable, Subject }                                                     from 'rxjs';
import { debounceTime, distinctUntilChanged, takeWhile }                                     from 'rxjs/operators';
import { isNullOrUndefined }                                                                 from '@cs/core';
import { ILoggerProvider, LoggerResponse }                                                   from './classes/logger-provider.interface';


export function ErrorLoggingSetupFactory() {
  return null;
}

/**
 * Token to set the amount of retries before http request fails
 */
export const ERROR_LOGGING_PROVIDER = new InjectionToken<ILoggerProvider>('ERROR_LOGGING_PROVIDER', {
  providedIn: 'root',
  factory:    ErrorLoggingSetupFactory
});


/**
 * A generic client for logging unhandled Angular exceptions, delegating the exceptions to one or more endpoints
 */
@Injectable({providedIn: 'root'})
export class CsGenericErrorLogger implements ErrorHandler {
  _console: Console = console;

  /**
   * Use a observable as a queue. This allows for fine grain filtering and rate controlling.
   */
  get queue(): Subject<Error> {
    if (isNullOrUndefined(this._queue))
      this._queue = new Subject();

    return this._queue;
  }

  /**
   * The subscription to the observable, subscribe to this handle to process the incoming errors
   */
  queueHandle$ = this.queue.pipe(
    // Ignores error that happens more than once in the specified period
    debounceTime(2000),
    // Only change when different than last
    distinctUntilChanged(),
    // Added a rate limit that only send x errors within the rateLimitPeriod
    takeWhile(value => this.errorSendCounter < this.loggingProvider.options.rateLimitCount)
  );

  /**
   * The subscription to the observable, subscribe to this handle to listen for the end of the rateLimitPeriod
   */
  cleanUp$ = interval(this.loggingProvider.options.rateLimitPeriod);

  private _queue: Subject<Error>;
  private errorSendCounter = 0;

  /**
   * Logs an error, returning whether logging was successful
   * @param   err The exception to log
   */
  public logError(err: Error) {
    // Get API client, log error
    return new Observable<LoggerResponse>(observer => {
      let response = new LoggerResponse();
      // Log to Sentry
      try {
        LoggerUtil.warn(`😭 Passing exception to Sentry`);

        if (err) {
          const errorToSend   = new Error();
          errorToSend.message = err.message;
          errorToSend.name    = err.name;
          errorToSend.stack   = err.stack;

          this.errorSendCounter++;

          this.loggingProvider.captureException(errorToSend)
              .then(value => {
                response = value.value;
                response.logged = true;
                observer.next(response);
                observer.complete();
              })
              .catch(reason => observer.error(reason));

        } else {
          response.logged = false;
          observer.next(response);
          observer.complete();
        }

      } catch (ex) {
        LoggerUtil.warn(`Failed to log exception ${ex}`);
        response.logged = false;
        observer.next(response);
        observer.complete();
      }

    });
  }

  constructor(@Inject(ERROR_LOGGING_PROVIDER) private loggingProvider: ILoggerProvider) {
    this.startHandlingErrors();
  }

  /**
   * Return if the error logger is enable
   */
  isLogging(): boolean {
    return this.loggingProvider.options.enableErrorLogging;
  }

  /**
   * The method that is called by angular
   * @param err the instance of the catched error
   */
  handleError(err: Error | HttpErrorResponse): void {
    // ignore the HttpErrorResponses because these are handled at application level
    if (err instanceof HttpErrorResponse)
      return;
    if (!this.loggingProvider.options.enableErrorLogging) {
      this.rethrowError(err);
      return;
    }
    // Pass to error queue
    this.queue.next(err);
  }

  startHandlingErrors() {
    if (!this.loggingProvider.options.enableErrorLogging)
      return;

    this.loggingProvider.setupErrorProvider().then(result => {
      this.queueHandle$.subscribe((errorToLog) => {
        this.logError(errorToLog).subscribe(value => {
          LoggerUtil.debug('Error is logged');
        });
      }, error1 => {
        LoggerUtil.error(error1);
      });

      this.cleanUp$.subscribe(value => {
        this.errorSendCounter = 0;
      });
    }).catch(reason => console.log(reason));

  }

  /**
   * Function that is rethrowing the error with context (if available) provided by angular.
   * @param error the error that needs to be thrown
   */
  rethrowError(error: Error) {
    const originalError = this._findOriginalError(error);
    const context       = this._findContext(error);
    // Note: Browser consoles show the place from where console.error was called.
    // We can use this to give users additional information about the error.
    const errorLogger = getErrorLogger(error);

    errorLogger(this._console, `ERROR`, error);
    if (originalError) {
      errorLogger(this._console, `ORIGINAL ERROR`, originalError);
    }
    if (context) {
      errorLogger(this._console, 'ERROR CONTEXT', context);
    }
  }

  /** @internal */
  _findContext(error: any): any {
    if (error) {
      return getDebugContext(error) ? getDebugContext(error) :
        this._findContext(getOriginalError(error));
    }

    return null;
  }

  /** @internal */
  _findOriginalError(error: Error): any {
    let e = getOriginalError(error);
    while (e && getOriginalError(e)) {
      e = getOriginalError(e);
    }

    return e;
  }

  logHttpBreadcrumb(param: { headers: HttpHeaders; data: any; method: string; param: HttpParams; url: string; status_code: number }) {
    this.loggingProvider.addBreadcrumb({
      category:    'xhr',
      status_code: param.status_code,
      level:       'info',
      data:        {
        method:      param.method,
        body:        param.data,
        url:         param.url,
        status_code: param.status_code
      },
      type:        'http',
      message:     param.url
    });
  }

  hasInternetConnection() {
    return navigator.onLine;
  }
}


export const ERROR_TYPE = 'ngType';
export const ERROR_DEBUG_CONTEXT = 'ngDebugContext';
export const ERROR_ORIGINAL_ERROR = 'ngOriginalError';
export const ERROR_LOGGER = 'ngErrorLogger';

export function getDebugContext(error: Error) {
  return (error as any)[ERROR_DEBUG_CONTEXT];
}

export function getOriginalError(error: Error): Error {
  return (error as any)[ERROR_ORIGINAL_ERROR];
}

export function getErrorLogger(error: Error): (console: Console, ...values: any[]) => void {
  return (error as any)[ERROR_LOGGER] || defaultErrorLogger;
}


function defaultErrorLogger(console: Console, ...values: any[]) {
  (<any>console.error)(...values);
}
