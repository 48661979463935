import { ModuleWithProviders, NgModule, Type }                                   from '@angular/core';
import { CommonModule }                                                          from '@angular/common';
import { HomePmComponent }                                                       from './home-pm.component';
import { DashboardModule, DashboardEventHub }                                    from '@cs/components/dashboard';
import { CsFilterAndCompareBarModule }                                           from '@cs/components/filter-and-compare-bar';
import { TranslateLoader, TranslateModule, TranslateService }                    from '@ngx-translate/core';
import { HttpClient }                                                            from '@angular/common/http';
import { CsApplicationSettings }                                                 from '@cs/common';
import { IConfigService }                                                        from '@cs/performance-manager/shared';
import { CsTranslationLoader }                                                   from '@cs/performance-manager/shared';
import { LoggerUtil }                                                            from '@cs/core';
import { CsCultureModule, ComponentTranslationLoaderService, CsCultureProvider } from '@cs/common';
import { RouterModule }                                                          from '@angular/router';
import { DashboardPopupModule }                                                  from '@cs/performance-manager/dashboard';
import { HomePmConfigService }                                                   from './home-config.service';

export function homePmTranslationLoader(client: HttpClient,
										pmAppSettings: CsApplicationSettings,
										service: IConfigService,
										componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader, pmAppSettings.deploymentDetails.buildDate);
}

@NgModule({
			  declarations: [HomePmComponent],
			  providers:    [DashboardEventHub],
			  imports:      [
				  CommonModule,
				  RouterModule,
				  CsCultureModule,
				  DashboardModule,
				  DashboardPopupModule,
				  TranslateModule.forChild({
											   loader:  {
												   provide:    TranslateLoader,
												   useFactory: homePmTranslationLoader,
												   deps:       [HttpClient, CsApplicationSettings, HomePmConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  CsFilterAndCompareBarModule
			  ]
		  })
export class PmHomePmModule {
	static forRoot(moduleConfig: Type<PmHomePmModule>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<PmHomePmModule> {
		return {
			ngModule:  PmHomePmModule,
			providers: [
				{
					provide:  HomePmConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language for now hardcoded en
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them for now hardcoded en
		translate.use(culture.getCulture());
	}
}
