import { getPropertyOf } from '@cs/core/utils';

/**
 * Group model describing the grouping of the headers
 */
export class DataDescribedGroup {

	/**
	 * Identification of the group
	 */
	id?: string;
	/**
	 * Name to show in the UI
	 */
	label?: string;
	/**
	 * The children for this group
	 */
	children?: DataDescribedGroup[];
	/**
	 * If group has a parent, use this to set that parent id
	 */
	groupId: string;


	constructor(init: Partial<DataDescribedGroup>) {
		this.id       = getPropertyOf(init, 'id', null);
		this.label    = getPropertyOf(init, 'label', '');
		this.children = getPropertyOf(init, 'children', null);
		this.groupId  = getPropertyOf(init, 'groupId', null);


	}

}

/**
 * Group model describing the grouping of the data
 */
export class DataDescribedDataGroup extends DataDescribedGroup {

}
