export class PropertyTypeNode {
  public useValidationRegex: boolean;
  public useMaxLength: boolean;
  public useToggle: boolean;
  public useIntValue: boolean;
  public useDecimalValue: boolean;
  public useTextValue: boolean;
  public useDataValue: boolean;
  public useFile: boolean;
  public name: string;
  public description: string;

  public constructor(data) {
    this.useValidationRegex = data.useValidationRegex;
    this.useMaxLength = data.useMaxLength;
    this.useToggle = data.useToggle;
    this.useIntValue = data.useIntValue;
    this.useDecimalValue = data.useDecimalValue;
    this.useTextValue = data.useTextValue;
    this.useDataValue = data.useDataValue;
    this.useFile = data.useFile;
    this.name = data.name;
    this.description = data.description;
  }

  public processChildren(children) { }
}
