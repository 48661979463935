<div class="cs-panel-header root-repository__content">

	<div>

		<div class="cs-panel-header root-repository__title-div">

			<div class="root-repository__title-left">
				<h1 class="f-h1">Cohelion Submodule Overview</h1>
			</div>

			<ng-container *ngIf="hasRecursiveChildRepositories(this.parentRepositoryMap)">
				<div class="root-repository__title-right root-repository__light-font">
					<input type="checkbox" class="btn-checkbox root-repository__repository-checkbox" [(ngModel)]="showAll">
					<label>Show All Child Repositories</label>
				</div>
			</ng-container>

		</div>

		<div class="cs-panel-header--content root-repository__table-content-holder">
			<ng-container *ngTemplateOutlet="oldView"></ng-container>
		</div>

	</div>

</div>

<ng-template #oldView>

	<ng-container *ngIf="parentRepositoryMap.size > 0">

		<ng-container *ngFor="let clientRepository of parentRepositoryMap | keyvalue">

			<ng-container *ngIf="hasSubmodules(clientRepository.value)">

				<!--has to be a deep copy otherwise filters somehow fuck the display up-->
				<SubmoduleBranchTable
					[repository]="JSON.parse(JSON.stringify(clientRepository))"
					[initialCollapse]="false">
				</SubmoduleBranchTable>

				<RepositoryColumnedTableComponent
					[repository]="clientRepository"
					[collapseRecursive]="!showAll"
					[showChildCommitDifference]="false"
					(navigationEvent)="navigate($event)"
					[initialCollapse]="false">
				</RepositoryColumnedTableComponent>

			</ng-container>

		</ng-container>


	</ng-container>

	<ng-container *ngIf="parentRepositoryMap.size < 1 || parentRepositoryMap == undefined">
		<div class="header-div header-div__no-border header-div__title-left">
			<h3 class="f-h3">No Submodules Found</h3>
		</div>
	</ng-container>

</ng-template>
