import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy, ViewChild, ElementRef, Injector } from '@angular/core';
import { Router }                                                                                                    from '@angular/router';
import {
	AppMessageHubService, AppNavigationService
}                                                                                                                    from '@cs/common/services';
import {
	DashboardComponent as CsDashboardComponent, DashboardDownloadButtonEventArgs, DashboardGridData
}                                                                                                                    from '@cs/components/dashboard';
import {
	FilterCompareBarQuery
}                                                                                                                    from '@cs/components/filter-and-compare-bar';
import {
	CsToastManagerService
}                                                                                                                    from '@cs/components/toast-manager';
import {
	SelectionTargetResult
}                                                                                                                    from '@cs/core/generate';
import { convertToSelectionObjects }                                                                                 from '@cs/core/utils';
import {
	DashboardBase, DashboardConfigService, handlePostAction, onDashboardEntryClick
}                                                                                                                    from '@cs/performance-manager/dashboard';
import {
	AppService
}                                                                                                                    from '@cs/performance-manager/shared';
import {
	TabService
}                                                                                                                    from '@cs/performance-manager/tabbed-page';
import {
	UntilDestroy, untilDestroyed
}                                                                                                                    from '@ngneat/until-destroy';
import {
	TranslateService
}                                                                                                                    from '@ngx-translate/core';
import { filter, takeWhile }                                                                                         from 'rxjs/operators';
import { ReportingQuery, ReportingStateService }                                                                     from '../state';


@UntilDestroy()
@Component({
												selector       : 'pmc-reporting-dashboard',
												templateUrl    : './reporting-dashboard.component.html',
												styleUrls      : ['./reporting-dashboard.component.css'],
												changeDetection: ChangeDetectionStrategy.OnPush
											})
export class ReportingDashboardComponent extends DashboardBase implements OnInit, OnDestroy {

	@ViewChild('dashboard', {static: true}) dashboard: CsDashboardComponent;
	@ViewChild('panelContentTopBar', {static: false, read: ElementRef}) panelContentTopBar: ElementRef;

	data: DashboardGridData;

	constructor(private reportingStateService: ReportingStateService,
													private dashboardConfig: DashboardConfigService,
													private injector: Injector,
													private reportingStateQuery: ReportingQuery,
													private changeDetection: ChangeDetectorRef,
													protected readonly filterCompareBarQuery: FilterCompareBarQuery,
													protected readonly appNavigationService: AppNavigationService,
													protected readonly appService: AppService,
													protected readonly cdRef: ChangeDetectorRef,
													protected readonly i8n: TranslateService,
													protected readonly toastService: CsToastManagerService,
													protected readonly router: Router,
													protected readonly tabService: TabService,
													readonly appMessageHub: AppMessageHubService) {
		super(dashboardConfig, filterCompareBarQuery, appNavigationService,
								appService, cdRef, i8n, toastService, router, tabService, appMessageHub);
	}

	ngOnInit(): void {
		this.reportingStateQuery
						.select(store => store.selection)
						.pipe(untilDestroyed(this),
												takeWhile(() => this.reportingStateQuery.getValue().actAsDashboard),
												filter(value => value !== null))
						.subscribe(value => {

							this.getDashboard(value.selection);
						});

	}

	private getDashboard(selection: {
		[key: string]: any
	}): void {

		this.dashboardConfig.getDashboardData({
																																									dashboard: this.reportingStateQuery.getValue()
																																										.activeReport
																																										.meta.name,
																																									selection: convertToSelectionObjects(selection)
																																								})
						.subscribe(result => {
							this.data = result.value;
							this.changeDetection.detectChanges();
						});
	}

	onShowDetailsButtonClick($event: SelectionTargetResult) {
		this.onDashboardEntryClick($event);
	}

	onDashboardEntryClick($event: SelectionTargetResult) {
		onDashboardEntryClick($event, this.injector, this.dashboardConfigService, this.data.name)
			.then(value => handlePostAction(value, this.injector));
	}

	onDownloadButtonClicked(args: DashboardDownloadButtonEventArgs) {
		const currentValue = this.reportingStateQuery.getValue();
		const dataPayload = {
			dashboard:currentValue
				.activeReport
				.meta.name,
			selection: convertToSelectionObjects(currentValue)
		};
		Object.assign(args.selectionObject, dataPayload);
		super.onDownloadButtonClicked(args);
	}

	ngOnDestroy(): void {
	}
}
