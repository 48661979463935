import { TableDataCell, TableDataRowLayout } from '@cs/core';

export class ValueChangedEventArgs {
	row: TableDataRowLayout;
	cell: TableDataCell;

	constructor(init: { row: TableDataRowLayout; cell: TableDataCell }) {
		this.row  = init.row;
		this.cell = init.cell;
	}

}
