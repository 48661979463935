import { CsOption }          from './option.model';
import { isNullOrUndefined } from '@cs/core';


export class CsOptionList {

	private readonly _options: Array<CsOption> = [];

	private _highlightedOption: CsOption = null;

	private readonly _hideSelected: boolean = false; // hides selected options from list

	static equalValues(v0: Array<string>, v1: Array<string>): boolean {
		if (v0.length !== v1.length) {
			return false;
		}

		let a: Array<string> = v0.slice().sort();
		let b: Array<string> = v1.slice().sort();

		return a.every((v, i) => {
			return v === b[i];
		});
	}

	constructor(options: Array<any>, valueProp: string, keyProp: string, hideSelected = false) {

		if (isNullOrUndefined(options)) {
			options = [];
		}

		this._hideSelected = hideSelected;

		this._options = options.map((option) => {
			let o: CsOption = new CsOption(option[keyProp], option[valueProp], option.cssClass);
			if (option.disabled) {
				o.disable();
			}
			if (option.selected) {
				o.select();
			}
			return o;
		});

		this.highlight();
	}

	get options(): Array<CsOption> {
		return this._options;
	}

	getOptionsByValue(value: any): Array<CsOption> {
		return this.options.filter((option) => {
			return option.value === value;
		});
	}

	/** Value. **/

	get value(): Array<any> {
		return this.selection.map((selectedOption) => {
			return selectedOption.value;
		});
	}

	set value(v: Array<any>) {
		v = typeof v === 'undefined' || v === null ? [] : v;
		this.options.forEach((option) => {
			if (v.some(x => x === option.value)) {
				this.select(option);
			} else {
				this.deselect(option);
			}
		});
	}

	/** Selection. **/

	get selection(): Array<CsOption> {
		return this.options.filter((option) => {
			return option.selected;
		});
	}

	// Select the option and optionally hide it as well.
	select(option: CsOption) {
		option.select();
		if (this._hideSelected)
			option.hide();
	}

	deselect(option: CsOption) {
		option.deselect();
		if (this._hideSelected)
			option.show();
	}

	clearSelection() {
		this.options.forEach((option) => {
			option.deselect();
		});
	}

	get filtered(): Array<CsOption> {
		return this.options.filter((option) => {
			// ignore already selected options
			return option.shown;
		});
	}

	/**
	 * Filter the shown items by term
	 */
	filter(term: string) {

		if (term.trim() === '') {
			this.resetFilter();
		} else {
			this.options.forEach((option) => {
				let l: string = option.label.toUpperCase();
				let t: string = term.toUpperCase();
				const match   = l.indexOf(t) > -1;
				if (match && !(this._hideSelected && option.selected)) {
					option.show();
				} else {
					option.hide();
				}
			});
		}

		this.highlight();
	}

	resetFilter() {
		this.options.forEach((option) => {
			if (!this._hideSelected || !option.selected)
				option.show();
		});
	}

	/** Highlight. **/

	get highlightedOption(): CsOption {
		return this._highlightedOption;
	}

	highlight() {
		let option: CsOption = this.hasShownSelected() ?
			this.getFirstShownSelected() : this.getFirstShown();
		this.highlightOption(option);
	}

	highlightOption(option: CsOption) {
		this.clearHighlightedOption();

		if (option !== null) {
			option.highlighted      = true;
			this._highlightedOption = option;
		}
	}

	/**
	 * Returns the search order, starting from index to N, then from index to 0.
	 * @param N length of array (i.e. not the highest index)
	 * @param index current index, where index < N
	 * @param fwrd true indicates to go forward first (higher indices).
	 */
	private getSearchOrderIndices(N: number, index: number, fwrd: boolean = true): Array<number> {
		if (index < 0 || index > N)
			return [];

		let pre  = [];   // indices before index
		let post = [];  // indices after index

		if (index > 0)
			// 0 ... index-1
			pre = [...Array(index).keys()].map(i => i).reverse();

		if (index < N - 1)
			// index+1 ... N-1
			post = [...Array(N - 1 - index).keys()].map(i => i + index + 1);

		return fwrd ? [...post, ...pre] : [...pre, ...post];
	}

	highlightNextOption() {
		const options = this.options;
		const index   = this.getHighlightedIndexFromList(options);

		// list of indices starting from index to end, then from index to 0
		const indices = this.getSearchOrderIndices(options.length, index, true);

		for (const j of indices) {
			if (options[j].shown) {
				this.highlightOption(options[j]);
				return;
			}
		}
	}

	highlightPreviousOption() {
		const options = this.options;
		const index   = this.getHighlightedIndexFromList(options);

		// list of indices starting from index to 0, then from index to end
		const indices = this.getSearchOrderIndices(options.length, index, false);

		for (const j of indices) {
			if (options[j].shown) {
				this.highlightOption(options[j]);
				return;
			}
		}
	}

	private clearHighlightedOption() {
		if (this.highlightedOption !== null) {
			this.highlightedOption.highlighted = false;
			this._highlightedOption            = null;
		}
	}

	private getHighlightedIndexFromList(options: Array<CsOption>) {
		return options.findIndex(option => option.highlighted === true);
	}

	getHighlightedIndex() {
		return this.getHighlightedIndexFromList(this.options);
	}

	/** Util. **/

	hasShown() {
		return this.options.some(option => option.shown);
	}

	hasSelected() {
		return this.options.some(option => option.selected);
	}

	hasUnselected() {
		return this.options.some(option => !option.selected);
	}

	hasShownSelected() {
		return this.options.some((option) => {
			return option.shown && option.selected;
		});
	}

	private getFirstShown(): CsOption {
		for (let option of this.options) {
			if (option.shown) {
				return option;
			}
		}
		return null;
	}

	private getFirstShownSelected(): CsOption {
		for (let option of this.options) {
			if (option.shown && option.selected) {
				return option;
			}
		}
		return null;
	}
}
