import { LoggerUtil }                        from '@cs/core/utils';
import { forkJoin, Observable, of }          from 'rxjs';
import { TranslateLoader }                   from '@ngx-translate/core';
import { HttpClient }                        from '@angular/common/http';
import { ComponentTranslationLoaderService } from '@cs/common';
import { catchError, map, tap }              from 'rxjs/operators';

export class CsTranslationLoader implements TranslateLoader {

	constructor(
		location: string,
		http: HttpClient,
		private componentLoader?: ComponentTranslationLoaderService,
		hash?: string
	) {
		this.http     = http;
		this.location = location;
		this.hash     = hash || ''; // Default to an empty string if hash is not provided
	}

	/**
		* Gets the translations for the specified language.
		* @param lang The language to get translations for.
		* @returns An Observable that emits the merged translations.
		*/
	getTranslation(lang: string): Observable<any> {
		const requests: Observable<{
			[key: string]: string
		}>[] = [];

		// Add component translations to the requests if componentLoader is provided
		if (this.componentLoader) {
			for (const component of Array.from(this.componentLoader.getComponentModuleNames())) {
				requests.push(this.componentLoader.setupComponentModule(component));
			}
		}

		// Add the main language translation request
		const mainTranslationRequest = this.http.get<{
																																						[key: string]: string
																																					}>(
																																						`${this.location}/${lang}.json?version=${this.hash}`
																																					)
																																					.pipe(catchError(err => {
																																						LoggerUtil.error(`Failed to load translation file @${this.location}`, false);
																																						LoggerUtil.error(err, true);

																																						return of({});
																																					}));
		requests.push(mainTranslationRequest);

		// Combine all requests using forkJoin
		return forkJoin(requests)
			.pipe(
				map((translations: Array<{
									[key: string]: string
								}>) => {
									// Merge all translation objects into a single object
									return translations.reduce((accumulatedTranslations, currentTranslations) => {
										return {...accumulatedTranslations, ...currentTranslations};
									}, {});
								}
				), tap(x => console.log(this.location, x))
			);
	}

	private readonly http: HttpClient;
	private readonly location: string;
	private readonly hash: string;
}
