import { PropertyAnnotation }           from '@cs/core/generate';
import { DataAnnotation }               from './data-annotation.model';
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';
import { LoggerUtil }                   from '@cs/core/utils';
import { isNullOrUndefined }            from '@cs/core/utils';
import { isString }                     from '@cs/core/utils';
import { LayoutAnnotation }             from './layout-annotation.model';
import { Lookup }                       from './lookup.model';

export type DataDescribedArray<T, TLayout = LayoutAnnotation<T>, TMetaData = any> = DataDescribed<T, TLayout, TMetaData, Array<T>>;

export class DataDescribed<T, TLayout = LayoutAnnotation<T>, TMetaData = any, TData = T> {

	data: TData;

	dataAnnotation: DataAnnotation<T>;

	lookups: Lookup[];

	layout: TLayout;

	dataSetMetaInfo: TMetaData;

	constructor(init: Partial<DataDescribed<T, TLayout, TMetaData, TData>>) {

		if (hasPropertyOf(init, 'dataAnnotation')) {
			this.dataAnnotation = new DataAnnotation(init.dataAnnotation);
		}

		if (hasPropertyOf(init, 'data')) {
			this.data = getPropertyOf(init, 'data');
		}

		if (hasPropertyOf(init, 'layout')) {
			this.layout = getPropertyOf(init, 'layout');
		}

		if (hasPropertyOf(init, 'lookups')) {
			this.lookups = hasPropertyOf(init, 'lookups')
						   ? init.lookups.map(l => new Lookup(l))
						   : [];
		}

		if (hasPropertyOf(init, 'dataSetMetaInfo')) {
			this.dataSetMetaInfo = getPropertyOf(init, 'dataSetMetaInfo', null);
		}

	}

	getProperty(propName: string): PropertyAnnotation<any> | null {
		return this.dataAnnotation.fields.find(value1 => value1.id === propName);
	}

	/**
	 * Try to find the lookup in the Describe Data
	 * @param lookupName Name of the lookup
	 * @returns result of the search, should be a lookup
	 */
	getLookup(lookupName: string): Lookup {
		if (!hasPropertyOf(this, 'lookups'))
			LoggerUtil.warn(`${lookupName} is not found, because no list of lookups is provided`);

		const foundLookup = this.lookups.find(l => l.id === lookupName);
		if (isNullOrUndefined(foundLookup)) {
			LoggerUtil.warn(`${lookupName} is not found in the lookups`);
			return null;
		}
		return foundLookup;
	}

	/**
	 * Resolve the value with a lookup provided in the dataDescribe model
	 * @param value Name of the lookup
	 * @param lookupName result of the lookup, returning full lookup
	 */
	resolveValueWithLookup(value, lookupName: string) {
		const lookup = this.getLookup(lookupName);

		if (!isNullOrUndefined(lookup)
			&& !isNullOrUndefined(lookup.values) && lookup.values.length > 0) {
			const found = lookup.values.find(v => v.key === value);
			if (isNullOrUndefined(found)) {
				LoggerUtil.error(`${value} is not found in ${lookupName}`);
				return null;
			} else
				return found.value;
		}
	}

	/**
	 * Resolve the value with a lookup provided in the dataDescribe model
	 * @param value Value of the property
	 * @param propName  Name of the property
	 */
	resolveValueWithLookupByPropertyName(value, propName: keyof T) {
		const {field, lookup} = this.getLookupByProperty(propName);

		if (!isNullOrUndefined(lookup)
			&& !isNullOrUndefined(lookup.values) && lookup.values.length > 0) {
			const compareAsString = isString(value);
			const found           = lookup.values.find(v => (compareAsString
															 ? v.key.toString()
															 : v.key) === value);
			if (isNullOrUndefined(found)) {
				LoggerUtil.error(`${value} is not found in ${field.lookup}`);
				return null;
			} else
				return found.value;
		}
	}

	/**
	 * Get the lookup by property name
	 * @param propName name of property
	 */
	getLookupByProperty(propName: keyof T) {
		const field  = this.dataAnnotation.fields.find(value1 => value1.id === propName);
		const lookup = this.getLookup(field.lookup);
		return {field, lookup};
	}

	/**
	 * Get the data-annotation for a specific field
	 * @param propName the field name
	 */
	resolveDataAnnotationByPropertyName(propName: keyof T) {
		return this.dataAnnotation.fields.find(value1 => value1.id === propName);
	}

	/**
	 * Get the id properties from the data
	 */
	getSelectionObject() {
		if (Array.isArray(this.data))
			throw new Error('Not implemented yet');

		const data         = this.data as unknown as T;
		const allKeyFields = this.dataAnnotation.fields.filter(value => value.key);
		const result       = {};

		for (const field of allKeyFields) {
			result[field.id as any] = data[field.id];
		}

		return result;
	}
}


