import { Component }     from '@angular/core';
import { ControlWidget } from '../models/control-widget.directive';

@Component({
	selector: 'input-mask-widget',
	template: `
				<div class="form-group">
					<input [name]="name"
						   class="form-control"
						   [formControl]="control"
						   [attr.id]="id"
						   [disabled]="readOnly"
						   [class.is-valid]="isValid"
						   [class.is-invalid]="isInValid"
						   [class.is-warning]="isInWarning"
						   csInputMask
						   alias="awb"
					>
					<div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length> 0 ">
						<small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
					</div>
				</div>
						`
})

export class InputMaskWidgetComponent<T> extends ControlWidget<T> {
}
