import {
	HttpErrorResponse, HttpEvent, HttpHandler,
	HttpInterceptor, HttpRequest, HttpResponse
}                                             from '@angular/common/http';
import { EMPTY, Observable, throwError }      from 'rxjs';
import { catchError, map, retry }             from 'rxjs/operators';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import { ErrorConfig }                        from './error-config.interface';
import { ErrorService }                       from './state/error.service';
import { CsHttpErrorResponse }                from '@cs/core';
import { TranslateService }                   from '@ngx-translate/core';

export function ErrorConfigFactory() {
	return new ErrorConfig();
}

/**
 * Token to set the amount of retries before http request fails
 */
export const ERROR_CONFIG = new InjectionToken<ErrorConfig>('Error_Config', {
	providedIn: 'root',
	factory:    ErrorConfigFactory
});

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

	constructor(@Inject(ERROR_CONFIG) private config: ErrorConfig,
							@Inject(ErrorService) private errorService: ErrorService,
							private l8n: TranslateService
	) {

	}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			// Retry the request when failed
			retry(this.config.retryCount),
			catchError((response: CsHttpErrorResponse) => {

				// HTTP Error is already handled
				if (response instanceof CsHttpErrorResponse && response.isHandled)
					return throwError(response);

				// Check if there are overrides for specific status codes
				if (this.config.globalHttpHooksOverrides.has(response.status)) {
					return this.config.globalHttpHooksOverrides.get(response.status)(response);
				}

				let output = throwError(response);

				switch (response.status) {
					// 'SoftHandle' the bad request application wide, because if there is no
					// handling for a 400 in the executed method then the user should see this a minor warning instead of a hard error
					case 400:
						this.errorService.handleBadRequest(response);
						break;
					case 401:
					case 405:
					case 406:
						this.errorService.handleNotAuthenticated();
						output = EMPTY;
						break;
					case 419:
						this.errorService.handleExpiredAutoLoginMessage(this.l8n);
						break;
					case 450:
						this.errorService.handleMfaRequest(response, this.l8n);
						break;
					case 503:
						this.errorService.handleMaintenanceMode(response);
						output = EMPTY;
						break;
					case 500:
						this.errorService.handleInternalServerError(response);
						break;
					case 403:
						this.errorService.notAuthorized(response.error);
						output = EMPTY;
						break;
					case 413:
						output = EMPTY;
						break;
					case 423:
						this.errorService.handleExpiredPassword(this.l8n);
						output = EMPTY;
						break;
					case 432:
						this.errorService.handleResetPassword(this.l8n);
						output = EMPTY;
						break;
					case 426:
						this.errorService.handlesetPassword(this.l8n);
						output = EMPTY;
						break;
					case 0:
						this.errorService.handle0ResponseServerError(response);
						break;
					default:
						this.errorService.handleUnhandledServerError(response);
						break;
				}

				return output;
			}));
	}

}
