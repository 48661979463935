import { NgModule }          from '@angular/core';
import { CommonModule }      from '@angular/common';
import { NewsListComponent } from './news-list.component';
import { CsCultureModule }   from '@cs/common';

@NgModule({
  declarations: [NewsListComponent],
  imports:      [
    CommonModule,
    CsCultureModule
  ],
  exports:      [NewsListComponent]
})
export class NewsListModule {
}
