import { Component, OnChanges, Input }                                                      from '@angular/core';
import { IndicatorData }                                                                    from './models/indicator-data-described';
import { ComponentChanges, whenChanging, hasPropertyOf, DataAnnotation, isNullOrUndefined } from '@cs/core';

@Component({
	selector:    'cs-indicator',
	templateUrl: './indicator.component.html'
})
export class IndicatorComponent implements OnChanges {

	@Input() dataSource: IndicatorData;
	@Input() dataAnnotation: DataAnnotation<any>;

	formatValue  = '{0:P0}';
	formatOffset = '{0:P0}';
	formatGoal   = '{0:P0}';

	constructor() {
	}

	ngOnChanges(changes: ComponentChanges<IndicatorComponent>) {
		whenChanging(changes.dataAnnotation, true)
			.execute(changedvalue => {
				if (hasPropertyOf(changedvalue.currentValue, 'fields')) {
					const fields      = changedvalue.currentValue.fields;
					const value       = fields.find(val => val.id === 'value');
					const offset      = fields.find(val => val.id === 'offset');
					const goal        = fields.find(val => val.id === 'goal');
					this.formatValue  = (!isNullOrUndefined(value) && hasPropertyOf(value, 'format')) ?
						value.format : this.formatValue;
					this.formatOffset = (!isNullOrUndefined(offset) && hasPropertyOf(offset, 'format')) ?
						offset.format : this.formatOffset;
					this.formatGoal   = (!isNullOrUndefined(goal) && hasPropertyOf(goal, 'format')) ?
						goal.format : this.formatGoal;
				}
			});
	}


}
