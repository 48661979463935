import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	OnChanges,
	Output,
	EventEmitter,
	HostBinding,
	ViewChild,
	ElementRef, AfterViewInit, ViewChildren, QueryList, OnDestroy, NgZone, ChangeDetectorRef
}                                                  from '@angular/core';
import { ChartLegendItem }                         from './chart-legend-item.model';
import { ComponentChanges, isString, LoggerUtil }  from '@cs/core';
import { ChartLegendSeriesEventArgs }              from './chart-legend.models';
import { ResizeSensor }                            from 'css-element-queries';
import { SafeMethods }                             from '@cs/common';
import { BaseChartLegendItem, ChartLegendOptions } from '@cs/components/cs-chart-loader';


interface ChartArea {
	bottom: string;
	height: string;
	left: string;
	right: string;
	top: string;
	width: string;
}

interface ChartLegendArea {
	paddingLeft: string;
	paddingRight: string;
	justifyContent: 'stretch' | 'start' | 'center' | 'end';
}

@Component({
	selector:        ' cs-chart-legend-nxt',
	templateUrl:     './chart-legend.component.html',
	styleUrls:       ['./chart-legend.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChartLegendComponent implements AfterViewInit, OnChanges, OnDestroy {
	get chartArea(): ChartLegendArea {

		if (this.chartOptions == null || this.chartOptions.chartArea == null)
			return;


		const chartArea = this.chartOptions.chartArea;
		const legend    = this.chartOptions.legend;

		const useFullWidth = legend.useFullWidth;

		function isPercentage(value: string) {
			if (value == null || !isString(value))
				return false;

			return value.endsWith('%');
		}

		function makePxValue(value: string) {
			if (value == null)
				return null;

			return value + 'px';
		}


		return {
			paddingLeft:    useFullWidth ? null : isPercentage(chartArea.left) ? chartArea.left : makePxValue(chartArea.left),
			paddingRight:   useFullWidth ? null : isPercentage(chartArea.right) ? chartArea.right : makePxValue(chartArea.right),
			justifyContent: legend.alignment as 'start' | 'center' | 'end'
		};
	}

	@Input() series: Array<ChartLegendItem>;
	@Input() chartOptions: ChartLegendOptions;

	@Input('chartType') public chartType: string;

	@Output() onMouseOver: EventEmitter<Readonly<ChartLegendSeriesEventArgs>>   = new EventEmitter<Readonly<ChartLegendSeriesEventArgs>>();
	@Output() onMouseLeave: EventEmitter<Readonly<ChartLegendSeriesEventArgs>>  = new EventEmitter<Readonly<ChartLegendSeriesEventArgs>>();
	@Output() onLegendClick: EventEmitter<Readonly<ChartLegendSeriesEventArgs>> = new EventEmitter<Readonly<ChartLegendSeriesEventArgs>>();

	private resizeSensor: ResizeSensor;
	private _useCompactMode: boolean;
	private _useExtraCompactMode: boolean;


	get useCompactMode() {
		return this._useCompactMode;
	}

	get useExtraCompactMode() {
		return this._useExtraCompactMode;
	}


	@HostBinding('style.margin')
	get compensatePanelPadding() {
		return this.chartType === 'PieChart' ? '0 -20px' : '0 0 -20px 0';
	}

	@HostBinding('style.zIndex')
	get hasZIndex() {
		return 99;
	}

	@HostBinding('style.position')
	get hasPosition() {
		return this.chartType === 'PieChart' ? '' : 'relative';
	}

	@HostBinding('style.width')
	get hasfullWidth() {
		return this.chartType === 'PieChart' ? '100%' : '';
	}

	@HostBinding('style.display')
	get hasDataAndIsVisible() {
		return this.series == null || this.series.length === 0 ? 'none' : '';
	}

	@ViewChild('legendRuler', {static: true}) ruler: ElementRef<HTMLDivElement>;
	@ViewChildren('element') legendItems: QueryList<ElementRef<HTMLDivElement>>;

	constructor(private readonly ngZone: NgZone, private readonly changeRef: ChangeDetectorRef) {
	}


	ngOnChanges(changes: ComponentChanges<ChartLegendComponent>): void {


	}


	onMouseOverHandler(serie: ChartLegendItem, $event: MouseEvent) {
		this.onMouseOver.emit({event: $event, series: serie});
	}

	onMouseLeaveHandler(serie: ChartLegendItem, $event: MouseEvent) {
		this.onMouseLeave.emit({event: $event, series: serie});
	}

	onLegendClickHandler(serie: BaseChartLegendItem, $event: MouseEvent) {

		if (!serie.canFilter) {
			LoggerUtil.debug(`${serie.label} is not filterable`);
			return;
		}

		// toggle the legend item
		serie.isFiltered = !serie.isFiltered;

		this.onLegendClick.emit({event: $event, series: serie});
	}

	detectTruncation(chartItemParent: HTMLDivElement) {
		const element = chartItemParent.querySelector('.chart-legend--item__text') as HTMLDivElement;

		return (element.offsetWidth >= element.scrollWidth);
	}

	ngAfterViewInit(): void {
		// let waitFor       = null;
		// this.resizeSensor = new ResizeSensor(this.ruler.nativeElement, item => {
		// 	if (waitFor == null) {
		// 		waitFor = setTimeout(() => {
		// 			this.checkIfCompactModeShouldActivate();
		// 			clearTimeout(waitFor);
		// 			waitFor = null;
		// 		}, 100);
		// 	} else {
		// 		clearTimeout(waitFor);
		// 		waitFor = null;
		//
		// 	}
		// });
		// this.ngZone.onStable.pipe(take(1)).subscribe(value => this.checkIfCompactModeShouldActivate());

	}

	private checkIfCompactModeShouldActivate() {

		let counter = 0;
		this.legendItems.forEach(item1 => {
			if (!this.detectTruncation(item1.nativeElement))
				counter++;
		});

		if (counter > 0)
			this._useCompactMode = true;
		else
			this._useCompactMode = false;

		// if (counter > 0 && !this._useCompactMode && !this._useExtraCompactMode)
		// 	this._useCompactMode = true;
		// else if (counter > 0 && this._useCompactMode && !this._useExtraCompactMode)
		// 	this._useExtraCompactMode = true;
		// else if (counter === 0 && this._useCompactMode && this._useExtraCompactMode)
		// 	this._useExtraCompactMode = false;
		// else if (counter === 0 && this._useCompactMode && !this._useExtraCompactMode)
		// 	this._useCompactMode = false;

		SafeMethods.detectChanges(this.changeRef);

	}

	ngOnDestroy(): void {
		//this.resizeSensor.detach();
	}
}


