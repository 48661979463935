import { FormGroup } from '@angular/forms';
import { Step }      from './step';

export abstract class Base {
}

export interface WizardParentContext {
	step: Step;
	formGroup: FormGroup;
}
