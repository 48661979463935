import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { INode } from './interfaces/inode';

@Injectable()
export class MdmPropertiesViewerEventService {

  saveDataRequested: Subject<INode> = new Subject<INode>();
  cancelRequested: Subject<INode> = new Subject<INode>();

}
