import { OverlayRef } from '@angular/cdk/overlay';
import { ToastData } from './toast-manager.config';
import { Subject } from 'rxjs';

export class ToastRef {

	constructor(private overlayRef: OverlayRef, private data: ToastData, private positionStrategy: any) {
		this.overlayRef.detachments().subscribe(() => {
			this._afterClosed.next();
			this._afterClosed.complete();
		});
	}

	close() {
		this.overlayRef.dispose();
	}

	isVisible(): boolean {
		return this.overlayRef && this.overlayRef.hasAttached();
	}

	getData(): ToastData {
		return this.data;
	}

	getPosition(): DOMRect {
		return this.overlayRef.overlayElement.getBoundingClientRect();
	}

	updatePosition(newBottomPosition: string) {
		this.positionStrategy.bottom(newBottomPosition);
		this.overlayRef.updatePosition();
	}

	afterClosed() {
		return this._afterClosed.asObservable();
	}
	private readonly _afterClosed = new Subject<void>();
}
