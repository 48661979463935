import { DataKey } from '../utils/data-key.model';


export class DataStructureColumn<TValue = unknown> {
	value: TValue;
	meta?: object;
	id: string;
	keys: DataKey;

	constructor(init: DataStructureColumn<TValue>) {
		this.meta  = init.meta;
		this.value = init.value;
		this.id    = init.id;
		this.keys  = Object.assign({}, init.keys); // new copy of the keys
	}
}
