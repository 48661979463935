import { FormatProviderService }                                                                                   from '@cs/common';
import { replacePlaceholders }                                                                                     from '@cs/core/utils';
import {
	CellValidationErrors
}                                                                                                                  from '../utils/data-grid-rule-enforcer';
import {
	LookupAgent
}                                                                                                                  from '@cs/components/shared';
import {
	createToObjectWithLowerCaseKeys, isNumber, stringFormatToNumber
}                                                                                                                  from '@cs/components/util';
import { FormatRegisteredItem, generateQuickGuid, IProperty, isBoolean, isNullOrUndefined, isString, isUndefined } from '@cs/core';
import { Subject }                                                                                                 from 'rxjs';
import {
	CellBehavior
}                                                                                                                  from '../classes/cell-behavior';
import {
	DataGridCellTooltipAction,
	DataGridFormatAction, DataGridLookupAction
}                                                                                                                  from '../classes/data-grid-action';
import {
	DataGridItemState
}                                                                                                                  from '../classes/data-grid-item-state';
import {
	DataGridType
}                                                                                                                  from '../classes/data-grid-type';
import {
	DataGridUIState
}                                                                                                                  from '../classes/data-grid-u-i-state';
import {
	GridDataCellMetaValues
}                                                                                                                  from '../classes/grid-data-cell-meta-values';
import {
	IDataGridCalculationOptionsAction
}                                                                                                                  from '../classes/i-data-grid-calculation-options-action';
import {
	DataGridCellType, GridActions, GridItemType, RowState
}                                                                                                                  from '../enums/data-grid.enum';
import {
	ICellValidator
}                                                                                                                  from '../interfaces/i-cell-validator';
import {
	IMatchLevelKeys
}                                                                                                                  from '../interfaces/IMatchLevelKeys';
import { GridRule }                                                                                                from './grid-rule.model';

export class GridDataCell implements DataGridType, IMatchLevelKeys {

	compareCell: GridDataCell                      = null;
	dataGridType: GridItemType                     = GridItemType.Cell;
	cellType: DataGridCellType                     = DataGridCellType.Data;
	index: number;
	properties: IProperty;
	format: DataGridFormatAction;
	cellUIState: DataGridUIState                   = new DataGridUIState(null);
	cellData: Array<any>                           = [];
	cellState: DataGridItemState                   = new DataGridItemState(null);
	lookup: Array<DataGridLookupAction>            = [];
	metaValues: GridDataCellMetaValues             = new GridDataCellMetaValues();
	colSpan: number;
	calculation: IDataGridCalculationOptionsAction = {
		aggregationFactor:           1,
		scope:                       GridItemType.Sheet,
		type:                        GridActions.SetCalculationOptions,
		order:                       0,
		rowType:                     RowState.Default,
		whenNullUpdateOriginalValue: false
	};

	rules: Array<GridRule>            = [];
	staticCssClass                    = '';
	dynamicCssClass                   = '';
	lookupLabel: 'label' | 'labelMin';
	behavior: CellBehavior            = new CellBehavior();
	validators: Array<ICellValidator> = [];

	clickOutsideSubscription: Subject<any>;

	id = generateQuickGuid();

	inputStartValue;
	isLastColumnOfHeaderGroup: boolean;
	isFirstColumnOfHeaderGroup: boolean;
	errorMessages: string[] = [];
	tooltip: DataGridCellTooltipAction;

	get originalValue(): any {
		return this._originalValue;
	}

	set originalValue(val: any) {
		this._originalValue = val;
	}

	get value(): any {
		return this._value;
	}

	set value(val: any) {
		this._value = val;
	}

	get displayValue(): any {
		return this._displayValue;
	}

	set displayValue(val: any) {
		if (isNullOrUndefined(val) || isBoolean(val)) {
			this._displayValue = isNullOrUndefined(val)
																								? val
																								: '' + val;
		} else {
			this._displayValue = '' + val;
		}
	}

	get key(): any {
		return this._key;
	}

	set key(value: any) {
		this._key = isString(value)
														? value.toLowerCase()
														: value;
	}

	get keys(): any {
		return this._keys;
	}

	set keys(value: any) {
		this._keys = createToObjectWithLowerCaseKeys(value);
	}

	get virtualKeys(): {
		[key: string]: number | string | boolean
	} {
		return this._virtualKeys;
	}

	set virtualKeys(value: {
		[key: string]: number | string | boolean
	}) {
		this._virtualKeys = createToObjectWithLowerCaseKeys(value);
	}

	get groupKeys(): any {
		return this._groupKeys;
	}

	set groupKeys(value: any) {
		this._groupKeys = createToObjectWithLowerCaseKeys(value);
	}

	constructor(args: Partial<GridDataCell> = {}, private formatProviderService: FormatProviderService) {
		Object.assign(this, args);
		// Set initial display value
		this.displayValue = this.value;
	}

	updateValue(newValue?: any) {
		// when value is provided set the new value
		if (!isUndefined(newValue)) {
			this.value        = newValue;
			this.displayValue = newValue;
			if (!isNullOrUndefined(this.compareCell))
				this.resolveMetaValues();
		}
		if (this.lookup.length > 0) {
			const lookup = this.lookup.find(x => x.lookupType === 'Value');

			// if lookup is found resolve it
			if (!isUndefined(lookup)) {
				this.properties = LookupAgent.resolveProperty(this.value, lookup.key);

				try {
					this.displayValue = this.properties[lookup.display];
				} catch (e) {
					this.displayValue = this.value;
				}
			}
		} else {
			// When the cell does not have a lookup just update the display value
			this.displayValue = this.value;
		}
		this.validateCell();
	}

	validateCell() {
		// updateValue can be called without newValue (when UiType is select/datepicket etc)
		if (this.validators.length > 0) {
			this.cellUIState.invalid = this.validators.every(validator => {
				const result = validator.validate();
				this.setErrorState(result);
				return result.length > 0;
			});
			if ((isNullOrUndefined(this.value) || this.value === '') && this.cellUIState.allowEmptyValuesToSave)
				this.cellUIState.invalid = false;

			return this.cellUIState.invalid;
		} else
			return true;
	}

	formatValue() {
		if (!isNullOrUndefined(this.format))
			this.displayValue = this.formatProviderService.format(isNumber(this.value)
																																																									? parseFloat(this.value)
																																																									: this.value, new FormatRegisteredItem(null, this.format.format));

	}

	updateValueAsDataSource(copy: GridDataCell) {

		const manualEntry = this.cellData.find(x => x['iddatasource'] === 1);

		const previousValue    = this.calculateValueByDataSources();
		// Check if the cell value is an empty string if thats the case use that as correction
		const manualCorrection = copy.value === ''
																											? ''
																											: (copy.value - previousValue);

		if (manualEntry) {
			manualEntry.value = manualCorrection;
		} else {
			const keys        = Object.assign({}, copy.keys);
			keys.iddatasource = 1;
			keys.value        = manualCorrection;
			this.cellData.push(keys);
		}
	}

	setInputStartValue(value = null) {
		let val = value;
		if (isNullOrUndefined(value) && !isNullOrUndefined(this.format))
			val = stringFormatToNumber(this.format.format, this.value);

		this.inputStartValue = isNullOrUndefined(val)
																									? this.value
																									: '' + val;
	}

	/**
		* Set the cell values while initialisation
		*/
	setInitialValue() {
		// only calculate the datasources for data cells
		if (this.cellType === DataGridCellType.Data)
			this.value = this.calculateValueByDataSources(true); // sum of datasources
		else if (this.cellType === DataGridCellType.Injected && this.cellData.length > 0 && this.value == null)
			this.value = this.cellData[0].value;
		else if (this.cellType === DataGridCellType.Injected && this.cellData.length === 0 && this.value == null)
			this.value = isNullOrUndefined(this.properties)
																? ''
																: this.properties.label; // issues with display of row totals

		this.originalValue = this.value; // this.value
		this.setInputStartValue();
	}

	/**
		* Resolve the @link GridDataCellMetaValues based on the compare cell
		*/
	resolveMetaValues() {
		this.metaValues = new GridDataCellMetaValues(
			Object.assign(this.metaValues, {
				absDifference:   this.absoluteDifference(this),
				relDifference:   this.relativeDifference(this),
				comparisonValue: isNullOrUndefined(this.compareCell)
																					? null
																					: this.compareCell.value
			}));
	}

	/**
		* Calculate the absolute difference between the host cell and compare cell
		* @param cell Pass the host cell
		* @returns The absolute difference
		*/
	absoluteDifference(cell: GridDataCell) {
		if (isNullOrUndefined(cell.compareCell)) {
			return null;
		}

		const valX = cell.value;
		const valY = cell.compareCell.value;
		if (!isNumber(valY) || !isNumber(valX))
			return null;

		return valX - valY;
	}

	/**
		* Returns the relative change of the host cell value to the compare cell value
		* @param cell Pass the host cell
		* @returns The relative difference
		*/
	relativeDifference(cell: GridDataCell) {
		if (isNullOrUndefined(cell.compareCell)) {
			return null;
		}

		const valX = cell.value;
		const valY = cell.compareCell.value;

		if (!isNumber(valY) || !isNumber(valX))
			return null;

		return isFinite(valX / valY)
									? valX / valY
									: 0;
	}


	cleanCell() {
		this.compareCell = null;
		this.resolveMetaValues();
		this.dynamicCssClass = '';
	}

	isChanged() {
		let orgValue = this.originalValue;

		if (!isNullOrUndefined(this.format))
			orgValue = this.formatProviderService.format(
				isNumber(this.originalValue)
				? parseFloat(this.originalValue)
				: this.originalValue,
				new FormatRegisteredItem(null, this.format.format));

		return orgValue !== this.displayValue;
	}

	resetDirty(): void {
		this.cellUIState.dirty = false;
	}

	public setErrorState(result: CellValidationErrors[]): void {
		if (result === null || result.length === 0) {
			this.errorMessages = [];
			return;
		}

		this.errorMessages = result.map(x => replacePlaceholders(x, x.validator.errorMessage));
	}

	private _keys: any;
	private _key: any;
	private _value: any;
	private _displayValue: string;
	private _originalValue: any;
	private _groupKeys: any;
	private _virtualKeys: {
		[key: string]: number | string | boolean
	} = {};

	/**
		* Calculates the total value of all data-sources added to the cell
		* @param includeManual When you want to include a manual entry to the calculation
		* @returns returns the calculated value
		*/
	private calculateValueByDataSources(includeManual = false): number {

		let output = null;

		if (this.cellData.length === 0) {
			return output;
		} else {
			let calculatedValue = 0;
			for (const datasource of this.cellData) {
				if (!isString(datasource.value)) {

					if (datasource.iddatasource === 1 && !includeManual)
						continue;

					if (isBoolean(datasource.value))
						calculatedValue = datasource.value;
					else
						calculatedValue += datasource.value;
				}
			}
			output = calculatedValue;
		}

		return output;
	}
}
