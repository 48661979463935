import { Component, Input, SimpleChanges, Inject, OnInit, OnChanges } from '@angular/core';
import { animate, style, transition, trigger }                        from '@angular/animations';
import { AuditTrailStateService }                                     from '@cs/components/audit-trail';
import { isNullOrUndefined }                                          from '@cs/core';


/**
 * Wrapper for audits component. Component will load it own data through service.
 */

@Component({
	selector:    'cs-statistics-panel-audits',
	templateUrl: './statistics-panel-audits.component.html',
	animations:  [
		trigger('statisticsState', [

			transition(':leave', [ // each time the binding value changes

				style({opacity: 1}),
				animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity: 0
					}))

			]), transition(':enter', [ // each time the binding value changes

				style({opacity: 0}),
				animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
					style({
						opacity: 1
					}))

			])
		])
	]

})
export class CsStatisticsPanelAuditsComponent implements OnChanges {

	@Input() auditsData: any;

	constructor(@Inject(AuditTrailStateService) private auditsStateService: AuditTrailStateService) {
	}


	ngOnChanges(data: SimpleChanges) {
		if (data.hasOwnProperty('auditsData')) {
			const auditsData = data['auditsData'].currentValue;
			if (!isNullOrUndefined(auditsData)) {
				this.auditsStateService.onFactTableNameChanged(data.auditsData.currentValue.factTableName);
			}

		}
	}

}

