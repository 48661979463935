import { Component, OnInit, ChangeDetectionStrategy, Input, Optional, Inject } from '@angular/core';

@Component({
						 selector:    'cs-data-entry-state-indicator',
						 templateUrl: './data-entry-state-indicator.component.html',

						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class CsDataEntryStateIndicatorComponent implements OnInit {

	/**
	 * The color of the background
	 */
	@Input() bgColor: string;
	/**
	 * The color of the border
	 */
	@Input() borderColor: string;
	/**
	 * The color of the text, defaults to the borderColor
	 */
	@Input() textColor: string;
	/**
	 * Add text to the indicator
	 */
	@Input() text: string;
	/**
	 * Used for tooltip
	 */
	@Input() tooltip: string;

	constructor() { }

	ngOnInit(): void {
	}

}
