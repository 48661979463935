import { PropertyAnnotation } from '../data-described/property-annotation.model';
import { FormControl }        from '@angular/forms';
import { WidgetLayout }       from './widget-layout.model';


export class WidgetInfo<T> {

	constructor(propertyAnnotation: PropertyAnnotation<T>, formControl: FormControl) {
		this.propertyAnnotation = propertyAnnotation;
		this.control            = formControl;
	}

	layout: WidgetLayout;
	propertyAnnotation: PropertyAnnotation<T>;
	control: FormControl;
	include = true;
}
