import { Directive, Input, OnInit, TemplateRef } from '@angular/core';
import { CsTemplateRegistry }                    from '../services/cs-template-registry.service';
import { ICsTemplateHandleDirective }            from './cs-template-handle.directive-interface';

@Directive({
	selector: '[csTemplateHandle]',
	host:     {}
})
export class CsTemplateHandleDirective implements ICsTemplateHandleDirective, OnInit {

	@Input('csTemplateHandle') type: string; // header | body

	constructor(public template: TemplateRef<any>,
							private templateList: CsTemplateRegistry) {

	}

	getType(): string {
		return this.type;
	}

	ngOnInit(): void {
		this.templateList.register(this.type, this);
	}
}
