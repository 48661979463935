import { RepositoryDto }   from '@cs/performance-manager/git-graph';
import { BehaviorSubject } from 'rxjs';


export enum UpdateRepositoryType {
	none    = 'none',
	info    = 'info',
	success = 'success',
	warning = 'warning',
	danger  = 'danger'
}

export interface UpdateRepositoryOptions {
	showYes?: boolean;
	showCancel?: boolean;
	showNo?: boolean;
	showOk?: boolean;
	type?: UpdateRepositoryType;
	afterUpdateEvent: any;
	dialogTitle?: string;
	message: string;
	childRepository: RepositoryDto;
	parentRepositories: RepositoryDto[];
	allowSameBranch: boolean;
}

export class UpdateRepositoryRequest {
	request: BehaviorSubject<boolean>;
	message: string;
	repository: RepositoryDto;

	currentProgress = 0;
	maxSteps        = 0;
}

export class RepositoryUpdateResult {
	isSuccessFull: boolean;
	message: string;
}
