import { NgModule }        from '@angular/core';
import { CommonModule }    from '@angular/common';
import { GaugeComponent }  from './gauge.component';
import { CsCultureModule } from '@cs/common';

const COMPONENTS = [GaugeComponent];

@NgModule({
	declarations:    [...COMPONENTS],
	imports:         [
		CommonModule,
		CsCultureModule
	],
	exports:         [...COMPONENTS],
	entryComponents: [...COMPONENTS]
})
export class CsGaugeModule {
}
