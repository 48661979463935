import { Component, Input, OnInit }                    from '@angular/core';
import { isNullOrUndefined } 													 from '@cs/core';
import { CsFileData, CsFileDataDescribed, CsFileType } from './cs-file-data-described';

/**
 * Viewer component.
 */
@Component({
	selector:    'cs-viewer',
	templateUrl: './viewer.component.html'
})
export class CsViewerComponent implements OnInit {

	@Input() dataSource: CsFileDataDescribed;
	files: CsFileData[];
	currentFile: CsFileData;

	constructor() {
	}

	ngOnInit() {

		this.files = this.dataSource.data;

		if (!isNullOrUndefined(this.files) && this.files.length > 0)
			this.currentFile = this.files[0];
	}

	resolveType(extension: string): CsFileType {
		switch (extension) {
			case 'jpg':
			case 'jpeg':
			case 'png':
			case 'gif':
				return CsFileType.IMAGE;
				break;
			case 'pdf':
				return CsFileType.PDF;
		}
	}

	changeSelected(file: CsFileData) {
		this.currentFile = file;
	}

}
