import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { FormsModule }                   from '@angular/forms';

import { CsComboboxComponent }               from './combobox.component';
import { CsComboboxScrollerComponent }       from './combobox-scroller.component';
import { ScrollingModule }                   from '@angular/cdk/scrolling';
import { MatTooltipModule }                  from '@angular/material/tooltip';
import { TranslateModule }                   from '@ngx-translate/core';
import { ComponentTranslationLoaderService } from '@cs/common';


@NgModule({
	declarations: [
		CsComboboxComponent,
		CsComboboxScrollerComponent
	],
	exports:      [
		CsComboboxComponent
	],
	imports:      [
		CommonModule,
		FormsModule,
		ScrollingModule,
		MatTooltipModule,
		TranslateModule
	]
})
export class CsComboboxModule {
	static forRoot(): ModuleWithProviders<CsComboboxModule> {
		return {ngModule: CsComboboxModule};
	}

	constructor(loader: ComponentTranslationLoaderService) {
		loader.registerComponentModule('combobox');
	}
}
