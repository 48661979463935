import { IDashboardPanel } from './i-dashboard-panel';

export class DashboardPanelOptions {
	name: string;
	panel: IDashboardPanel;
	height: string;

	constructor(init: DashboardPanelOptions) {
		this.name  = init.name;
		this.panel = init.panel;
		this.height = init.height;
	}
}
