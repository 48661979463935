<div class="tasks_container">
  <ng-container *ngFor="let group of data?.groups">
    <div class="tasks-group">
      <h4 class="tasks-group--label f-color-dimmed">{{group.label}} ({{group.items.length}})</h4>
      <ng-container *ngFor="let item of group.items">
        <div class="task-item"
             [class.task-item-has-link]="item.link"
             (click)="navigateToLink(item)">
          <i class="task-item--icon {{item.icon}}" matTooltip="{{item.iconDescription | translate}}"></i>
          <div class="task-item--label"
               [innerHTML]="item.label"
          ></div>
          <i *ngIf="item.link"
             class="task-item--link-indicator mdi mdi-arrow-right"></i>
        </div>
      </ng-container>
    </div>
  </ng-container>

</div>
