<div mat-dialog-content>
  <upload-form
    [imgSrc]=""
    [buttonText]="'Upload file'"
    [inProgress]="inProgress"
    [commentText]="commentText"
    (onFilesDropped)="handleFilesDropped($event)"
    (onCommentTextChange)="handleCommentTextChange($event)"
    (upload)="uploadFiles()"
    (uploadComplete)="handleUploadComplete($event)">
  </upload-form>
</div>
