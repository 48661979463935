import { isUndefined } from '@cs/core';

/**
	* Class representing the state of an item in a data grid.
	*/
export class DataGridItemState {
	/**
		* Indicates whether the item should be hidden.
		*/
	hidden;
	/**
		* Indicates whether the item should be locked.
		*/
	locked;
	/**
		* Indicates whether the item is read-only.
		*/
	readonly;
	/**
		* Indicates whether the item is invalid.
		*/
	invalid;
	/**
		* Indicates whether the item is editable.
		*/
	editable;
	/**
		* Flag indicating a reason should be specified when changed
		*/
	requireReason;
	/**
		* Indicates the minimal value of a cell for input
		*/
	allowMin: number;
	/**
		* Indicates the maximal value of a cell for input
		*/
	allowMax: number;
	/**
		* Indicates whether negative values are allowed.
		*/
	allowNegative: boolean;
	/**
		* Indicates whether positive values are allowed.
		*/
	allowPositive: boolean;
	/**
		* Is Selected allows for selection of cells
		*/
	isSelected: boolean;


	/**
		* Creates an instance of DataGridItemState.
		* @param Initial state for the item.
		*/
	constructor(initial?: Partial<DataGridItemState>) {
		if (initial === null)
			this.setDefaults();
		else if (!isUndefined(initial))
			Object.assign(this, initial);
	}

	/**
		* Sets the default state for the item.
		*/
	setDefaults() {
		this.hidden        = false;
		this.locked        = false;
		this.readonly      = true;
		this.invalid       = false;
		this.editable      = false;
		this.requireReason = false;
		this.allowNegative = false;
		this.allowPositive = true;
		this.allowMax      = null;
		this.allowMin      = null;
		this.isSelected    = false;
	}
}
