<div class="news-list-container" [@listAnimation]="newsItems?.length">
  <ng-container *ngFor="let item of newsItems">
    <div class="cs-panel news-item">
      <p [title]="item.datePublication" class="news-item--date">
        {{item.datePublication | format: '{0:d MMM yyyy}:idDate'}}
      </p>
      <h2 class="news-item--title">
        <i class="mdi mdi-newspaper"></i> {{item.title}}
      </h2>
      <div class="news-item--body" [innerHtml]="item.body"></div>

    </div>
  </ng-container>
</div>
