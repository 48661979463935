<!--	Checkout Submodule	-->
<div class="simple-dialog dialog-{{type}} update-dialog__holder">

	<div class="update-dialog__main-screen">

		<div class="update-dialog__detail-line">
			<h1 class="update-dialog__align-left update-dialog__heavy-font" *ngIf="childRepository != undefined">
				Update: {{childRepository.name}}
			</h1>

			<h1 class="update-dialog__align-right update-dialog__heavy-font"
				*ngIf="childRepository != undefined && parentRepositories.length < 2">
				On Repository: {{parentRepositories[0].name}} : {{parentRepositories[0].branch}}
			</h1>

			<h1 class="update-dialog__align-right update-dialog__heavy-font"
				*ngIf="childRepository != undefined && parentRepositories.length > 1">
				Amount of repositories to update: {{parentRepositories.length}}
			</h1>
		</div>

		<div
			class="update-dialog__loader-div"
			*ngIf="!requestInProgress$.isStopped && !isUpdating"
			[csLoader]="requestInProgress$ | async"
			[loaderAdditionalClasses]="['loader--big']">
		</div>

		<ng-container *ngIf="!isUpdating && requestInProgress$.isStopped">

			<ng-container *ngIf="!commitInfo">
				<ng-container *ngIf="!updateFinished && !commitFinished">
					<pmc-submodule-update-view
						class="update-dialog__holder"
						[parentRepositories]="parentRepositories"
						[featureBranchesStore]="featureBranchesStore"

						(submoduleCheckout)="CheckoutSubmoduleEvent($event)"
						(push)="PushAndCommit($event)"
						(showCommitInfo)="showCommitInfo($event)"
						(cancel)="onNoClick()">
					</pmc-submodule-update-view>
				</ng-container>

				<ng-container *ngIf="commitFinished && !updateFinished">
					<pmc-submodule-update-result
						class="update-dialog__holder"
						[parentRepositories]="parentRepositories"
						[errorLog]="submoduleCommitErrors"
						(continueEvent)="PushEvent($event)"
						[hasContinueEvent]="true"
						(cancel)="onNoClick()">
					</pmc-submodule-update-result>
				</ng-container>

				<ng-container *ngIf="updateFinished">
					<pmc-submodule-update-result
						class="update-dialog__holder"
						[parentRepositories]="parentRepositories"
						[errorLog]="pushCommitErrors"
						[hasContinueEvent]="false"
						(cancel)="onNoClick()">
					</pmc-submodule-update-result>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="commitInfo && !updateFinished">
				<pmc-submodule-update-commit-info
					class="update-dialog__holder"
					[commits]="unPushedCommits"
					[selectedRepository]="selectedRepository"
					(removeEvent)="RemoveCommitEvent($event)"
					(pushEvent)="PushEvent($event)"
					(cancel)="onNoClick()"
					(cancelCommitInfo)="commitInfo = !commitInfo">
				</pmc-submodule-update-commit-info>
			</ng-container>

		</ng-container>

		<ng-container *ngIf="isUpdating">
			<ng-container *ngTemplateOutlet="loadScreen"></ng-container>
		</ng-container>

		<div *ngIf="!requestInProgress$.isStopped || isUpdating">
			<h2>{{loadMessage}}</h2>
		</div>

	</div>

</div>

<ng-template #loadScreen>
	<div class="update-dialog__load-screen">

		<ng-container *ngIf="parentRepositories.length > 1">
			<ng-container *ngFor="let request of requests">

				<div class="update-dialog__load">

					<h2 class="update-dialog__load__message">
						{{request.message}}
					</h2>

					<ng-container *ngIf="!request.request.isStopped">

						<div style="width: 100%">
							<div class="progress">
								<div class="progress-bar progress-bar-striped progress-bar-animated"
									 role="progressbar"
									 aria-valuemin="0"
									 aria-valuemax="100"
									 [style.width.%]="request.currentProgress"></div>
							</div>
						</div>

					</ng-container>

				</div>

			</ng-container>
		</ng-container>

		<ng-container *ngIf="parentRepositories.length == 1">

			<ng-container *ngFor="let request of requests">

				<h2>
					{{request.message}}
				</h2>

				<div class="update-dialog__load__content">
					<div class="update-dialog__load__screen_roller"
						 [csLoader]="request.request | async"
						 [loaderAdditionalClasses]="['loader--big']">
					</div>
				</div>

			</ng-container>

		</ng-container>


	</div>
</ng-template>
