import {
	AfterViewInit,
	Component,
	Input,
	OnChanges,
	OnInit,
	Output,
	EventEmitter,
	ViewChild,
	forwardRef, ChangeDetectorRef
}                                                                              from '@angular/core';
import { FormControl, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';

import { CsOption }                    from './option.model';
import { CsOptionList }                from './option-list';
import { CsComboboxScrollerComponent } from './combobox-scroller.component';
import { CsKeys }                      from '@cs/components/util';


@Component({
	selector:    'cs-combobox',
	templateUrl: './combobox.component.html',
	providers:   [{
		provide:     NG_VALUE_ACCESSOR,
		useExisting: forwardRef(() => CsComboboxComponent),
		multi:       true
	},
								{
									provide:     NG_VALIDATORS,
									useExisting: forwardRef(() => CsComboboxComponent),
									multi:       true
								}]
})

export class CsComboboxComponent implements AfterViewInit, ControlValueAccessor, OnChanges, OnInit {

	/**
	 * The datasource of the component.
	 */
	@Input() options: Array<any> = [];
	/**
	 * Allows the combobox to be cleared when selected an option.
	 */
	@Input() allowClear          = true;
	/**
	 * Disables the combobox.
	 */
	@Input() disabled            = false;
	/**
	 * show smaller textfield like control
	 */
	@Input() isSmall             = false;
	// TODO: This values does nothing yet.
	/**
	 * Makes the combobox required.
	 */
	@Input() required            = false;
	/**
	 * Enable multiples selected values in the combobox.
	 * This will return multiple id's.
	 */
	@Input() multiple            = false;
	/**
	 * Shows or hides a filter in the combobox scroller component.
	 */
	@Input() filter              = true;
	/**
	 * Shows or hides a filter in the combobox scroller component.
	 */
	@Input() notFoundMsg         = 'No results round';
	/**
	 * Represents the placeholder attribute.
	 */
	@Input() placeholder         = 'Select';
	/**
	 * Aria key.
	 */
	@Input() key                 = '';

	/**
	 * Allows custom input.
	 * Ouputs the inputted text through custom
	 */
	@Input() customInput = false;

	@Input() valueProperty = 'value';
	@Input() keyProperty   = 'label';

	/**
	 * Outputs the custom inputted text as string.
	 */
	@Output() custom: EventEmitter<string> = new EventEmitter<string>();
	/**
	 * Outputs a event when the combobox scroller is opened.
	 */
	@Output() opened: EventEmitter<null>   = new EventEmitter<null>();
	/**
	 * Outputs an event when closed the combobox scroller.
	 */
	@Output() closed: EventEmitter<null>   = new EventEmitter<null>();
	/**
	 * Outputs an event when a new item is selected.
	 */
	@Output() selected: EventEmitter<any>  = new EventEmitter<any>();

	/**
	 * Outputs an event when a item is deselected.
	 */
	@Output() deselected: EventEmitter<any> = new EventEmitter<any>();
	@ViewChild('selection') selectionElement: any;
	@ViewChild('dropdown') dropdown: CsComboboxScrollerComponent;


	control: FormControl;
	optionList: CsOptionList = new CsOptionList([], this.valueProperty, this.keyProperty, false);
	hasSelected: boolean     = false;
	filterInputWidth         = 1;
	hasFocus: boolean        = false;

	isBelow: boolean = true;
	isOpen: boolean  = false;
	isMultiSelect    = false;
	showFilter       = true;

	clearClicked: boolean = false;

	width: number;
	top: number;
	left: number;

	/** ControlValueAccessor */
	private _value: Array<any>       = [];
	private initialValue: Array<any> = [];
	private onChange                 = (_: any) => {
	};
	private onTouched                = () => {
	};

	constructor(private cdRef: ChangeDetectorRef) {

	}

	ngOnInit() {
	}

	ngAfterViewInit() {
		setTimeout(() => {
			this.updateFilterWidth();
		});
	}

	ngOnChanges(changes: any) {
		if (changes.hasOwnProperty('options')) {
			this.updateOptionsList(changes['options'].isFirstChange());
		}
		this.updateShowFilter();
	}


	public validate(c: FormControl) {
		this.control = c;
	}

	//#region Handle window events
	onWindowClick() {
		this.closeDropdown();
	}

	onWindowResize() {
		this.updateWidth();
	}

	//#endregion

	//#region Handle main component events
	onContainerClick(event: any) {
		event.stopImmediatePropagation();
		event.preventDefault();
		event.cancelBubble = true;

		this.toggleDropdown();
	}

	onContainerFocus() {
		if (this.optionList.selection.length !== this.options.length)
			this.onTouched();
	}

	onContainerKeydown(event: any) {
		if (this.optionList.selection.length !== this.options.length)
			this.handleSelectContainerKeydown(event);
	}

	onDeselectOptionClick(option: CsOption, event: any) {
		event.stopImmediatePropagation();
		event.preventDefault();
		event.cancelBubble = true;

		if (!this.disabled) {
			this.deselectOption(option);
		}
	}

	//#endregion

	//#region Handle drop down menu events (scroller)
	onDropdownOptionClicked(option: CsOption) {
		if (this.multiple) {
			this.selectOption(option);
			if (!this.optionList.hasUnselected()) {
				// close dropdown and focus main element
				this.closeDropdown(true);
			}
		} else {
			this.selectOption(option);
			this.closeDropdown(true);
		}
	}

	onDropdownClose(focus: any) {
		this.closeDropdown(focus);
	}

	onFilterClick() {
		// empty, we already listen to selection changes
	}

	onFilterInput(term: string) {
		this.optionList.filter(term);
	}

	onFilterKeydown(event: any) {
		this.handleFilterKeydown(event);
	}

	//#endregion

	//#region ControlValueAccessor interface methods
	writeValue(v: any) {
		if (typeof v === 'undefined' || v === null || v === '') {
			v = [];
		} else if (typeof v === 'string') {
			v = [v];
		} else if (typeof v === 'number') {
			v = [v];
		} else if (!Array.isArray(v)) {
			throw new TypeError('Value must be a string or an array.');
		}

		this.updateOptionsList(true);

		if (!CsOptionList.equalValues(v, this._value)) {
			this.optionList.value = v;
		}
		this.initialValue = v;
	}

	registerOnChange(fn: (_: any) => void) {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void) {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean) {
		this.disabled = isDisabled;
	}

	//#endregion

	//#region Value property
	get value(): any {
		if (this._value.length === 0) {
			return [];
		} else {
			return this.multiple ? this._value : this._value[0];
		}
	}

	set value(v: any) {
		if (typeof v === 'undefined' || v === null || v === '') {
			v = [];
		} else if (typeof v === 'string') {
			v = [v];
		} else if (typeof v === 'number') {
			v = [v];
		} else if (!Array.isArray(v)) {
			throw new TypeError('Value must be a string or an array.');
		}

		if (!CsOptionList.equalValues(v, this._value)) {
			this.optionList.value = v;
			this.valueChanged();
		}

	}

	private valueChanged() {
		this._value      = this.optionList.value;
		this.hasSelected = this._value.length > 0;
		this.updateFilterWidth();

		this.onChange(this.value);
	}

	//#endregion

	/** Initialization. **/

	private updateOptionsList(firstTime: boolean) {
		let v: Array<any>;

		if (!firstTime) {
			if (this.initialValue.length > 0) {
				v                 = this.initialValue;
				this.initialValue = [];
			} else {
				v = this.optionList.value;
			}
		}

		this.optionList = new CsOptionList(this.options, this.keyProperty, this.valueProperty, this.multiple);

		if (!firstTime) {
			this.optionList.value = v;
			this.valueChanged();
		}
	}

	/** Dropdown. **/

	private toggleDropdown() {
		if (!this.disabled) {
			this.isOpen ? this.closeDropdown(true) : this.openDropdown();
		}
	}

	private openDropdown() {
		if (!this.isOpen && this.optionList.selection.length !== this.options.length) {
			this.updateWidth();
			this.updatePosition();
			this.isOpen = true;
			this.opened.emit(null);
		}
	}

	private closeDropdown(focus = false) {
		if (this.isOpen) {
			this.clearFilterInput();
			this.isOpen = false;
			if (focus) {
				this.focus();
			}
			this.closed.emit(null);
		}
	}

	/** Select. **/

	private selectOption(option: CsOption) {
		if (!option.selected) {
			if (!this.multiple)
				this.optionList.clearSelection();

			this.optionList.select(option);

			this.valueChanged();
			this.selected.emit(option.undecoratedCopy());
		}
	}

	private deselectOption(option: CsOption) {
		if (option.selected) {
			this.optionList.deselect(option);
			this.valueChanged();
			this.deselected.emit(option.undecoratedCopy());

			if (this.multiple) {
				this.updatePosition();
				this.optionList.highlight();
				if (this.isOpen) {
					this.dropdown.applyFilterInput();
					this.dropdown.moveHighlightedIntoView();
				}
			}
			this.cdRef.markForCheck();
		}
	}

	private clearSelection() {
		let selection: Array<CsOption> = this.optionList.selection;
		if (selection.length > 0) {
			this.optionList.clearSelection();
			this.valueChanged();

			if (selection.length === 1) {
				this.deselected.emit(selection[0].undecoratedCopy());
			} else {
				this.deselected.emit(selection.map((option) => {
					return option.undecoratedCopy();
				}));
			}
		}
	}

	private toggleSelectOption(option: CsOption) {
		option.selected ?
			this.deselectOption(option) : this.selectOption(option);
	}

	private selectHighlightedOption() {
		let option: CsOption = this.optionList.highlightedOption;
		if (option !== null) {
			this.selectOption(option);
			if (this.multiple) {
				if (this.optionList.hasUnselected()) {
					// move cursor to next available option
					this.optionList.highlightNextOption();
				} else {
					this.closeDropdown(true);
				}
			} else {
				// close dropdown and return focus on main element
				this.closeDropdown(true);
			}
		}
	}

	private removeLastSelected() {
		const sel: Array<CsOption> = this.optionList.selection;

		if (sel.length > 0) {
			let option: CsOption = sel[sel.length - 1];
			this.deselectOption(option);
		}
	}

	private clearFilterInput() {
		this.dropdown.clearFilterInput();
	}


	private handleSelectContainerKeydown(event: KeyboardEvent) {
		const key = event.which;

		if (this.isOpen) {
			if (key === CsKeys.esc ||
				(key === CsKeys.up && event.altKey)) {
				this.closeDropdown(true);
			} else if (key === CsKeys.tab) {
				this.closeDropdown();
			} else if (key === CsKeys.enter) {
				this.selectHighlightedOption();
			} else if (key === CsKeys.up) {
				this.optionList.highlightPreviousOption();
				this.dropdown.moveHighlightedIntoView();
				if (!this.filter) {
					event.preventDefault();
				}
			} else if (key === CsKeys.down) {
				this.optionList.highlightNextOption();
				this.dropdown.moveHighlightedIntoView();
				if (!this.filter) {
					event.preventDefault();
				}
			}
		} else {
			if (key === CsKeys.enter || key === CsKeys.space ||
				(key === CsKeys.down && event.altKey)) {

				// The setTimeout is added to prevent the enter keydown event on Firefox.
				setTimeout(() => {
					this.openDropdown();
				});
			}
		}

		if (key === CsKeys.backspace) {
			this.removeLastSelected();
		}

	}


	private handleFilterKeydown(event: any) {
		const key = event.which;

		if (key === CsKeys.esc || key === CsKeys.tab || key === CsKeys.up || key === CsKeys.down || key === CsKeys.enter) {
			this.handleSelectContainerKeydown(event);
		}
	}

	/** View. **/

	focus() {
		this.hasFocus = true;
		this.selectionElement.nativeElement.focus();
	}

	blur() {
		this.hasFocus = false;
		this.selectionElement.nativeElement.blur();
	}

	updateWidth() {
		this.width = this.selectionElement.nativeElement.offsetWidth;
	}

	updatePosition() {
		let e     = this.selectionElement.nativeElement;
		this.left = e.offsetLeft;
		this.top  = e.offsetTop + e.offsetHeight;
	}

	updateFilterWidth() {

	}

	/**
	 * Automatically show filter when there are more than 8 items.
	 * Unless filter is disabled
	 */
	private updateShowFilter() {
		const threshold = 8;
		this.showFilter = this.optionList.options.length > threshold && this.filter;
	}
}
