import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { CapitalizePipe }                from './capitalize.pipe';
import { FileSizePipe }                  from './file-size.pipe';
import { SortByPipe }                    from './sort.pipe';



@NgModule({
	imports:      [
		CommonModule
	],
	providers:    [],
	exports:      [CapitalizePipe, FileSizePipe, SortByPipe, CommonModule],
	declarations: [CapitalizePipe, FileSizePipe, SortByPipe]
})
export class CsCommonPipesModule {
	static forRoot(): ModuleWithProviders<CsCommonPipesModule> {
		return {ngModule: CsCommonPipesModule, providers: []};
	}
}
