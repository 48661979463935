import { Injectable }         from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';

export interface TabState {
	inProgress: boolean;
}

export function createInitialTabState(): TabState {
	return {
		inProgress: false
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'tab'})
export class TabStore extends Store<TabState> {

	constructor() {
		super(createInitialTabState());
	}

}

