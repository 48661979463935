/**
 * A wrapper service for displaying toast messages.
 * For debugging purposes, temporarily the toast messages are sent to the console too.
 */
import { Injectable }            from '@angular/core';
import { CsToastManagerService } from '@cs/components/toast-manager';

@Injectable({providedIn: 'root'})
export class ToastService {

	constructor(private toast: CsToastManagerService) {
	}

	/**
	 * Display a success toast
	 */
	public success(title: string,
								 message: string = '') {

		message
			? console.log(`${title} : ${message}`)
			: console.log(`${title}`);

		this.toast.show({content: message, title: title, type: 'success'});
	}

	/**
	 * Displays a warning toast
	 */
	public warning(title: string,
								 message: string = '') {

		console.warn(`${title} : ${message}`);
		this.toast.show({content: message, title: title, type: 'warning'});
	}

	/**
	 * Displays an error toast
	 */
	public error(title: string,
							 message: string = '') {

		console.error(`${title} : ${message}`);
		this.toast.show({content: message, title: title, type: 'error', showProgressBar: false, clickToClose: true});
	}

	/**
	 * Displays an info toast
	 */
	public info(title: string,
							message: string) {

		console.log(`${title} : ${message}`);
		this.toast.show({content: message, title: title, type: 'info'});
	}

	public html(type: string,
							html: string,
							override?: any) {
		// this.toast.html(html, type, override);
	}
}
