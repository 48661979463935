<div class="flex flex-justify-content-space-between flex-align-items-center dynamic-button-container">
	<div class="cs-statistics-header" *ngIf="title">
			<h1 class="chart-panel__title">{{title}}</h1>
	</div>
	<div *ngIf="alertMessage" class="flex flex-align-items-end">
			<div class="alert alert-danger fade show pr-2" role="alert">
					{{alertMessage}}
			</div>
	</div>
	<div class="dynamic-button-wrapper">
			<ng-container *ngFor="let dynamicButton of registeredDynamicButtons">
					<div *ngIf="isVisible(dynamicButton)" class="dynamic-button">
							<ng-container *ngIf="dynamicButton.displayInstance.type !== 'Import' || dynamicButton.displayInstance['usePopup']; else selectFileButton">
									<!-- Regular button -->
									<button class="btn btn--sm btn--icon-and-text"
													[ngClass]="dynamicButton.displayInstance.btnClass || 'btn-quaternary'"
													[disabled]="dynamicButton.displayInstance.disabled"
													matTooltip="{{dynamicButton.displayInstance.description}}"
													[csLoader]="dynamicButton.isLoading"
													(click)="dynamicButtonClicked(dynamicButton, $event)">
											<i class="{{dynamicButton.displayInstance.iconClass}} mr-1" style="font-size:unset"></i>
											<span>{{dynamicButton.displayInstance.label}}</span>
									</button>
							</ng-container>
							<!-- Select file button -->
							<ng-template #selectFileButton>
									<label class="btn btn-quaternary btn--sm btn--icon-and-text"
												 style="line-height: normal;"
												 [class.disabled]="dynamicButton.displayInstance.disabled"
												 matTooltip="{{dynamicButton.displayInstance.description}}">
											<i class="{{dynamicButton.displayInstance.iconClass}} mr-1" style="font-size:unset"></i>
											<span>{{dynamicButton.displayInstance.label}}</span>
											<input hidden #fileinput
														 [disabled]="dynamicButton.displayInstance.disabled"
														 type="file"
														 accept="{{dynamicButton.displayInstance.accept}}"
														 (click)="clearInputElementValue()"
														 (change)="dynamicButtonClicked(dynamicButton, $event)">
									</label>
							</ng-template>
					</div>
			</ng-container>
	</div>
</div>
