import { Injectable, Optional }                    from '@angular/core';
import { IFormGeneratorAgentService }              from './i-form-generator-agent.service';
import { FileUtil, FormLayout, isNullOrUndefined } from '@cs/core';
import { Logger }                                  from '@cs/components/util';
import {
	FormLayoutWidgetCollection,
	FormSettings, isEmptyObject,
	KeyValuePair,
	Lookup,
	PropertyAnnotation,
	WidgetInfo
}                                                  from '@cs/core';
import { FormGeneratorLookupService }              from './form-generator-lookup.service';
import { Subject }                                 from 'rxjs';
import { FormGroup }                               from '@angular/forms';
import {
	ControlWidget, FileControlWidget,
	IFormGeneratorNxtComponent, LookupControlWidget
}                                                  from './models';

@Injectable()
export class FormGeneratorAgentService<TFormInstance extends IFormGeneratorNxtComponent<any> = IFormGeneratorNxtComponent<any>> implements IFormGeneratorAgentService<TFormInstance> {
	private formInstance: TFormInstance;
	private widgets: ControlWidget<any>[]                             = [];
	private _actionRequestedSubject: Subject<PropertyAnnotation<any>> = new Subject();

	constructor(@Optional() private formGeneratorLookup: FormGeneratorLookupService) {
	}

	registerForm(form: TFormInstance) {
		this.formInstance = form;
	}

	/**
	 * Try to find the lookup in the Describe Data of the form
	 * @param lookupName Name of the lookup
	 * @returns result of the search, should be a lookup
	 */
	getLookup(lookupName: string): Lookup {
		if (isNullOrUndefined(this.formInstance.data.lookups)) {
			Logger.Warning(`No lookups provided in dataDescribed`);
			return null;
		}
		const foundLookup = this.formInstance.data.lookups.find(l => l.id === lookupName);
		if (isNullOrUndefined(foundLookup)) {
			Logger.Warning(`${lookupName} is not found in the lookups`);
			return null;
		}
		this.patchEmptyLookupValue(foundLookup);

		return foundLookup;
	}

	updateLookup(lookupName: string, values: KeyValuePair<any, any>[]): number {
		const foundLookup = this.formInstance.data.lookups.find(l => l.id === lookupName);
		if (isNullOrUndefined(foundLookup)) {
			Logger.Warning(`${lookupName} is not found in the lookups`);
			return null;
		}
		foundLookup.values = values;
		this.patchEmptyLookupValue(foundLookup);
		this.resetFieldValueThatUsesLookup(foundLookup.id);
		return foundLookup.values.length;
	}

	patchEmptyLookupValue(foundLookup: Lookup) {
		if (isNullOrUndefined(foundLookup) || isNullOrUndefined(foundLookup.values))
			return;

		// check if empty value and fill with dash
		const foundEmptyValue = foundLookup.values.find(v => v.key === 0);
		if (!isNullOrUndefined(foundEmptyValue) && foundEmptyValue.value === '') {
			foundEmptyValue.value = '-';
		}
	}

	/**
	 * Get the form layout settings so controls could make there one decisions what to render
	 */
	getFormSettings(): FormSettings {
		return this.formInstance.form.layout.layout;
	}

	/**
	 * Get the form instance
	 */
	getFormInstance(): TFormInstance {
		return this.formInstance;
	}


	registerWidget(widget: ControlWidget<any>) {
		this.widgets.push(widget);
	}

	findWidget(id: string) {
		return this.widgets.find(w => w.id === id);
	}

	findWidgetInfo(id: string): WidgetInfo<any> {
		return this.formInstance.form.widgets.find(w => w.propertyAnnotation.id === id);
	}

	findFormCollectionContainingField(id: string): FormLayoutWidgetCollection<any> {
		return this.formInstance.form.layout.fieldSets.reduce((old, newValue) => {
			old.push(...newValue.widgetCollections);
			return old;
		}, []).find((w: FormLayoutWidgetCollection<any>) => w.id === id);
	}

	getAllWidgets() {
		return this.widgets;
	}

	/**
	 * Update the fields that are dependant on the changed field/ control
	 */
	updateDependantLookups(id: string) {
		// Find all lookups that has an filter property and are dependant on the changed control
		const lookupsToUpdate = this.formInstance.data.lookups.filter(lookup => !isNullOrUndefined(lookup.filter)
			&& lookup.filter.dataAnnotation.fields.find(f => f.id === id));

		lookupsToUpdate.forEach(lookup => {
			const filterObject  = this.formInstance.getFormData();
			const lookupId      = lookup.id;
			const contextObject = this.formInstance.getContextObject();
			this.formGeneratorLookup.getLookupWithFilter(lookupId, filterObject, contextObject).subscribe((result) => {
				const nrOfFound = this.updateLookup(lookupId, result);
				this.formInstance.updateForm();
			});
		});
	}

	private resetFieldValueThatUsesLookup(id: string) {
		const foundWidgets: LookupControlWidget<any>[] =
						<LookupControlWidget<any>[]>this.getAllWidgets()
																						.filter(w => w instanceof LookupControlWidget)
																						.filter((w: LookupControlWidget<any>) =>
																							(!isNullOrUndefined(w.lookup) && w.lookup.id === id));
		foundWidgets.forEach(w => {
			// Reset the field to NULL or and empty array, because the lookup is changed
			w.control.setValue(w.isMultiSelect ? [] : null);
		});

	}

	publishActionRequested($event: PropertyAnnotation<any>) {
		this._actionRequestedSubject.next($event);
	}

	subscribeActionRequested() {
		return this._actionRequestedSubject.asObservable();
	}

	notifyDependantFields<T>(id: keyof T) {
		const fieldId                                  = id as string;
		const foundWidgets: LookupControlWidget<any>[] =
						<LookupControlWidget<any>[]>this.getAllWidgets()
																						.filter(w => w.propertyAnnotation.hasDependencies)
																						.filter(w => w.propertyAnnotation.dependencies.find(value1 => value1.dependsOnId === fieldId));

		if (foundWidgets.length === 0)
			return;

		const filterObject  = this.formInstance.getFormData();
		const contextObject = this.formInstance.getContextObject();
		this.formGeneratorLookup.dependantFieldHasChanged(fieldId, filterObject, contextObject)
				.subscribe((result) => {
					const dataToUpdate = result.value;

					if (isEmptyObject(dataToUpdate))
						return;

					foundWidgets.forEach(w => {
						if (dataToUpdate.data.hasOwnProperty(w.propertyAnnotation.id))
							w.control.setValue(dataToUpdate.data[w.propertyAnnotation.id]);
					});
				});
	}

	/**
	 * Convert the @Link(File) filelist to real ArrayBuffer object
	 */
	async convertToFiles(
		value: any,
		form: { widgets: Array<WidgetInfo<any>>; formGroup: FormGroup; layout: FormLayout<any> }) {

		for (const key of Object.keys(value)) {
			const foundWidget = form.widgets.find(w => w.propertyAnnotation.type.toLowerCase() === 'byte[]'
				&& w.propertyAnnotation.id === key);
			if (!isNullOrUndefined(foundWidget)) {
				const widget = <FileControlWidget<any>>this.findWidget(foundWidget.propertyAnnotation.id.toString());
				if (widget.selectedFiles.length === 1) {
					value[key] = await FileUtil.convertToBlob(widget.selectedFiles[0]).toPromise();
				}
			}
		}
		return value;
	}
}
