import { NgModule, ModuleWithProviders, Type }                                       from '@angular/core';
import { CommonModule }                                                              from '@angular/common';
import { CsAvatarModule }                                                            from '@cs/components/avatar';
import { CsSharedModule }                                                            from '@cs/components/shared';
import { CsTableModule }                                                             from '@cs/components/table';
import { ApprovalRoutingModule }                                                     from './approval-routing.module';
import { ApprovalComponent }                                                         from './approval.component';
import { CsApplicationSettings }                                                     from '@cs/common';
import { ApprovalConfigService }                                                     from './approval-config.service';
import { HttpClient }                                                                from '@angular/common/http';
import { IConfigService }                                                            from '@cs/performance-manager/shared';
import { ComponentTranslationLoaderService, CsCommonPipesModule, CsCultureProvider } from '@cs/common';
import { CsTranslationLoader }                                                       from '@cs/performance-manager/shared';
import { TranslateModule, TranslateLoader, TranslateService }                        from '@ngx-translate/core';
import { LoggerUtil }                                                                from '@cs/core';
import { PmDynamicButtonBarModule }                                                  from '@cs/performance-manager/shared';
import { ApprovalOverviewComponent }                                                 from './approval-overview/approval-overview.component';
import { MatDialogModule }                                                           from '@angular/material/dialog';
import { MatTooltipModule }                                                          from '@angular/material/tooltip';
import { FormsModule }                                                               from '@angular/forms';
import { DynamicButtonsAgent }                                                       from '@cs/performance-manager/shared';
import { DefaultApprovalPageButtonsAgent }                                           from './default-dynamic-button-bar';
import { DialogBasicModule }                                                         from '@cs/performance-manager/shared';


export function approvalTranslationLoader(client: HttpClient,
										  pmAppSettings: CsApplicationSettings,
										  service: IConfigService,
										  componentLoader: ComponentTranslationLoaderService) {
	return new CsTranslationLoader(`${pmAppSettings.translationLocation}/${service.moduleName}`,
								   client, componentLoader);
}

@NgModule({
			  declarations: [ApprovalComponent, ApprovalOverviewComponent],
			  imports:      [
				  CommonModule,
				  PmDynamicButtonBarModule,
				  CsTableModule,
				  MatDialogModule,
				  MatTooltipModule,
				  DialogBasicModule,
				  FormsModule,
				  CsAvatarModule,
				  CsSharedModule,
				  TranslateModule.forChild({
											   loader:  {
												   provide:    TranslateLoader,
												   useFactory: approvalTranslationLoader,
												   deps:       [HttpClient, CsApplicationSettings, ApprovalConfigService,
																ComponentTranslationLoaderService]
											   },
											   isolate: true
										   }),
				  ApprovalRoutingModule,
				  CsCommonPipesModule
			  ]
		  })
export class PmApprovalModule {
	static forRoot(moduleConfig: Type<ApprovalConfigService>,
				   appSetting: CsApplicationSettings,
				   dynamicPageButtons: Type<DynamicButtonsAgent> = DefaultApprovalPageButtonsAgent): ModuleWithProviders<PmApprovalModule> {
		return {
			ngModule:  PmApprovalModule,
			providers: [
				{
					provide:  ApprovalConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				},
				{
					provide:  DynamicButtonsAgent,
					useClass: dynamicPageButtons
				}
			]
		};
	}

	constructor(translate: TranslateService, culture: CsCultureProvider) {

		if (!translate) {
			LoggerUtil.warn('Translation service is not set-up');
			return;
		}

		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang(culture.getDefaultCulture());

		// the lang to use, if the lang isn't available, it will use the current loader to get them
		translate.use(culture.getCulture());
	}
}
