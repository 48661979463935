
<div class="cs-viewer flex flex-row">

	<!-- Sidebar navigation -->
  <div class="files" *ngIf="files?.length > 1">
    <div class="title file-item f-body-l flex flex-column">
      <b>{{currentFile.fileName}}</b>
      <small>Select a file</small>
    </div>
    <div class="file-item" *ngFor="let file of files">
      <div class="flex flex-column" (click)="changeSelected(file)">
        <div class="flex f-body-m">
          <span>
            <i class="mdi mdi-{{resolveType(file.extension) === 'pdf'? 'pdf-box': resolveType(file.extension)}}"></i>
              {{file.fileName}}
          </span>
          <i class="file-icon mdi mdi-arrow-right"></i>
        </div>
        <small class="f-body-xs">{{file.size | fileSize}}</small>
      </div>
    </div>
  </div>

	<!-- Document render -->
  <div class="render">
    <ng-container *ngIf="resolveType(currentFile.extension) === 'pdf'">
      <cs-pdf-viewer [src]="currentFile.previewUrl">
      </cs-pdf-viewer>
    </ng-container>

    <ng-container *ngIf="resolveType(currentFile.extension) === 'image'">
      <img [src]="currentFile.previewUrl">
    </ng-container>

    <div *ngIf="files?.length === 0" class="no-data-container">
      <div class="no-data">
        <div class="icon">
          <i class="mdi mdi-48px mdi-image"></i>
        </div>
      </div>
    </div>
  </div>

</div>
