import { Component }                                                                 from '@angular/core';
import { LoginConfigService }                                                        from './login-config.service';
import { LoginQuery }                                                                from './state/login.query';
import { LoginService }                                                              from './state/login.service';
import { Observable }                                                                from 'rxjs';
import { RouterOutlet }                                                              from '@angular/router';
import { animate, animateChild, group, query, sequence, style, transition, trigger } from '@angular/animations';


@Component({
	selector:    'pmc-login',
	templateUrl: './login.component.html',
	animations:  [trigger('fadeAnimation', [

		transition('* => *', [
			query(':enter, :leave', style({
					position: 'absolute',
					display:  'block',
					width:    300
				}),
				{optional: true}),
			query(':enter', style({opacity: 0}),
				{optional: true}),
			sequence([
				query(':leave', animateChild(),
					{optional: true}),
				group([
					query(':leave', [
							style({opacity: 1}),
							animate('0.275s ease-out',
								style({opacity: 0}))
						],
						{optional: true}),
					query(':enter', [
							style({opacity: 0}),
							animate('0.275s ease-out',
								style({opacity: 1}))
						],
						{optional: true})
				]),
				query(':enter', animateChild(),
					{optional: true})
			])
		])
	]),
								trigger(
									'textAnimation',
									[
										transition(
											'* => *',
											[
												style({transform: 'translateY(-100%)', opacity: 0}),
												animate('300ms cubic-bezier(0.4, 0.0, 0.2, 1)',
													style({transform: 'translateY(0)', opacity: 1}))
											]
										)
									]
								)]
})

export class LoginComponent {

	$underTitleMessage: Observable<string>;
	$loginMessage: Observable<string>;
	$isErrorState: Observable<boolean>;

	constructor(public loginConfig: LoginConfigService,
							private loginService: LoginService,
							private loginQuery: LoginQuery) {
		this.loginService.setTitleMessage(this.loginConfig.loginWelcomeMessage);
		this.$isErrorState      = loginQuery.select(store => store.inErrorState);
		this.$loginMessage      = loginQuery.select(store => store.titleMessage);
		this.$underTitleMessage = loginQuery.select(store => store.underTitleMessage);
	}

	public getRouterOutletState(outlet: RouterOutlet) {
		return outlet.isActivated ? outlet.activatedRoute : '';
	}

	navigateToWebsite() {
		window.open('https://cohelion.com', '_blank');
	}
}
