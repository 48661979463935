<div #legendRuler class="full-width legend-ruler"></div>
<div class="chart-legend" [ngStyle]="chartArea"
	 [class.pi-chart-legend]="chartType === 'PieChart'"
	 [class.compact]="useCompactMode"
	 [class.extra-compact]="useExtraCompactMode"
>
	<div class="chart-legend--item" *ngFor="let serie of series"
		 matTooltip="{{serie.label}}"
		 matTooltipPosition="below"
		 #element
		 [class.can-filter]="serie.canFilter"
		 [matTooltipDisabled]="detectTruncation(element)"
		 (click)="onLegendClickHandler(serie, $event)"
		 (mouseover)="onMouseOverHandler(serie, $event)"
		 (mouseleave)="onMouseLeaveHandler(serie, $event)"
	>
		<div class="chart-legend-circle-container">
			<ng-container *ngIf="serie.shape === 'circle'">
				<svg width="8" height="8" xmlns="http://www.w3.org/2000/svg">
					<circle [ngStyle]="{
                    'fill':serie?.color
                    }"
							cx="50%" cy="50%" r="4"/>
				</svg>
			</ng-container>
			<ng-container *ngIf="serie.shape === 'area'">
				<svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
					<rect x="0" y="8" width="24" height="6" stroke="none" stroke-width="0" fill-opacity="0.3"
						  [ngStyle]="{
								 'fill': serie?.color
								 }"></rect>
					<path d="M0,8L24,8" [ngStyle]="{
								 'stroke': serie?.color,
								 'stroke-dasharray': serie?.lineDashStyle?.join(',') || 0,
								 'stroke-width': serie?.lineWidth || 3
								 }" fill-opacity="1" fill="none"></path>
				</svg>
			</ng-container>
			<ng-container *ngIf="serie.shape === 'line'">
				<svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
					<line [ngStyle]="{
								 'stroke': serie?.color,
								 'stroke-dasharray': serie?.lineDashStyle?.join(',') || 0,
								 'stroke-width': serie?.lineWidth || 3
								 }" x1="0" y1="8" x2="190" y2="8"/>
				</svg>
			</ng-container>
			<ng-container *ngIf="serie.shape === 'bars'">
				<svg width="24" height="16" xmlns="http://www.w3.org/2000/svg">
					<line [ngStyle]="{
								 'stroke': serie?.color,
								 'stroke-dasharray': serie?.lineDashStyle?.join(',') || 0,
								 'stroke-width': serie?.lineWidth || 12
								 }" x1="0" y1="8" x2="190" y2="8"/>
				</svg>
			</ng-container>

		</div>
		<div class="chart-legend--item__text text-truncate text-no-whitespace"
			 [class.is-filtered]="serie.isFiltered">{{serie.label}}</div>
	</div>
</div>

