import {
	Component, OnInit, OnDestroy, ElementRef,
	ChangeDetectorRef,
	ChangeDetectionStrategy,
	AfterViewInit
}                                                                     from '@angular/core';
import { trigger, state, style, transition, animate, query, stagger } from '@angular/animations';
import { FilterCompareBarQuery, FilterCompareBarService }             from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                                      from '@cs/components/toast-manager';
import {
	ApprovalDataDescribed, ApprovalComment,
	FactsToApproveItem, ApprovalMetaDataSet,
	ApprovalDeviation, ApprovalCellOptions,
	LocalState, ApprovalResultParams
}                                                                     from './models/approval-data-described';
import { Subscription }                                               from 'rxjs';
import { MatDialog }                                                  from '@angular/material/dialog';
import { Router, ActivatedRoute }                                     from '@angular/router';
import { ApprovalConfigService }                                      from './approval-config.service';
import { isNullOrUndefined }                                          from '@cs/core';
import { DialogType, DialogBasicComponent }                           from '@cs/performance-manager/shared';
import { getUIPlaceholder, isUIPlaceholder }                          from '@cs/common/culture';
import { FormatProviderService }                                      from '@cs/common';
import {
	getPropertyOf, FormatRegisteredItem, hasPropertyOf,
	generateQuickGuid
}                                                                     from '@cs/core';
import { AppQuery }                                                   from '@cs/performance-manager/shared';
import { DynamicButton }                                              from '@cs/performance-manager/shared';
import { SafeMethods }                                                from '@cs/common';
import { TranslateService }                                           from '@ngx-translate/core';
import { filter as filter$ }                                          from 'rxjs/operators';
import { UntilDestroy, untilDestroyed }                               from '@ngneat/until-destroy';
import { AppNavigationService }                                       from '@cs/common';
import { FilterAndNavbarShellConfigService }                          from '@cs/performance-manager/filter-and-navbar-shell';
import { AuthenticationService }                                      from '@cs/performance-manager/shared';
import { AuthenticationQuery }                                        from '@cs/performance-manager/shared';


@UntilDestroy()
@Component({
			   selector:        'pmc-approval',
			   templateUrl:     './approval.component.html',
			   styleUrls:       ['./approval.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   animations:      [
				   trigger('openClose', [
					   state('open', style({
											   height:  '*',
											   opacity: 1
										   })),
					   state('closed', style({
												 height:        '0',
												 opacity:       0.0,
												 pointerEvents: 'none'
											 })),
					   transition('open => closed', [
						   animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
					   ]),
					   transition('closed => open', [
						   animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
					   ])
				   ]),
				   trigger('showCommentLabels', [
					   state('closed', style({
												 opacity:   1,
												 transform: 'translateY(0)'
											 })),
					   state('open', style({
											   opacity:   0.0,
											   transform: 'translateY(25px)'
										   })),
					   transition('open => closed', [
						   animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
					   ]),
					   transition('closed => open', [
						   animate('400ms cubic-bezier(0.4, 0.0, 0.2, 1)')
					   ])
				   ]),
				   trigger('listAnimation', [
					   transition('* => *', [ // each time the binding value changes
						   query(':leave', [
							   stagger(50, [
								   animate('0.275s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
							   ])
						   ], {optional: true}),
						   query(':enter', [
							   style({opacity: 0, transform: 'translate3d(0,-50px,0)'}),
							   stagger(50, [
								   animate('0.275s cubic-bezier(0.4, 0.0, 0.2, 1)', style({
																							  opacity:   1,
																							  transform: 'translate3d(0,0,0)'
																						  }))
							   ])
						   ], {optional: true})
					   ])
				   ])
			   ]
		   })
export class ApprovalComponent implements OnInit,
										  OnDestroy,
										  AfterViewInit {

	dynamicButtons: DynamicButton[];

	data: ApprovalDataDescribed;

	approvalItems: ApprovalItem[];

	constructor(private authorizationService: AuthenticationService,
				protected readonly toastService: CsToastManagerService,
				private dialog: MatDialog,
				private elementRef: ElementRef,
				private router: Router,
				private activatedRoute: ActivatedRoute,
				private config: ApprovalConfigService,
				readonly filterCompareBarQuery: FilterCompareBarQuery,
				readonly filterCompareBarService: FilterCompareBarService,
				private readonly format: FormatProviderService,
				private readonly appNavigationService: AppNavigationService,
				private readonly filterAndNavbarShellConfigService: FilterAndNavbarShellConfigService,
				private appStateQuery: AppQuery,
				private readonly authorizationQuery: AuthenticationQuery,
				public readonly changeRef: ChangeDetectorRef,
				protected readonly i8n: TranslateService) {
	}

	ngOnInit() {
		this.filterAndNavbarShellConfigService.setBodyClasses(this.bodyClasses);

		this.appNavigationService.registerForStoppingNavigationChanges(this.ticket, () => {
			const hasNewComments = this.checkForNewComments();
			return !hasNewComments || confirm(this.i8n.instant('NAVIGATE'));
		});

		this.navbarChangeSubscription = this.filterCompareBarService.filterbarNavigationRequested.subscribe(value => {
			const {dataEntryRoundScopeName, pageName, selection} = value as ApprovalResultParams;
			if (dataEntryRoundScopeName && pageName.toLowerCase() === 'acceptlist')
				this.getData(dataEntryRoundScopeName, selection);
		});

	}

	ngAfterViewInit(): void {
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(
				untilDestroyed(this),
				filter$(value => !isNullOrUndefined(value))
			)
			.subscribe((value: ApprovalResultParams) => {
				const {dataEntryRoundScopeName, pageName, selection} = value;
				if (dataEntryRoundScopeName && pageName.toLowerCase() === 'acceptlist')
					this.getData(dataEntryRoundScopeName, selection);
			});

	}

	ngOnDestroy() {
		if (!isNullOrUndefined(this.navbarChangeSubscription))
			this.navbarChangeSubscription.unsubscribe();

		this.appNavigationService.unregisterForStoppingNavigationChanges(this.ticket);

		this.filterAndNavbarShellConfigService.cleanBodyClasses(this.bodyClasses);
	}

	addComment(item: ApprovalItem) {
		const {firstName, lastName} = this.authorizationQuery.getValue().userProfile;

		item.newComment.userName = `${firstName} ${lastName}`;

		item.comments.push(item.newComment);
		item.comments.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());

		item.newComment = new ApprovalComment({
												  userName:  `${firstName} ${lastName}`,
												  timestamp: new Date(),
												  message:   '',
												  isSelf:    true,
												  factId:    item.factId,
												  isNew:     true
											  });

		SafeMethods.detectChanges(this.changeRef);

	}

	deleteComment(item: ApprovalItem, comment: ApprovalComment) {
		item.comments.splice(item.comments.findIndex(value => value.id === comment.id), 1);
	}

	async buttonClicked(event: string, resultParams: ApprovalResultParams) {
		const {dataEntryRoundScopeName, selection} = resultParams;

		if (event.toLowerCase() === 'reject' && !this.checkForNewComments()) {
			this.toastService.show({
									   type:         'alert',
									   title:        this.i8n.instant('SORRY'),
									   content:      this.i8n.instant('ADDING_COMMENT'),
									   clickToClose: true
								   });
			SafeMethods.detectChanges(this.changeRef);
			return;
		} else if (event.toLowerCase() === 'approve') {
			const confirmed = await this.getUserConfirmation(
				this.i8n.instant('CONFIRMING'), DialogType.info
			);
			if (!confirmed)
				return;
		}
		try {
			this.getNewComments()
				.forEach(async (value) => {
					const result = this.config
									   .postApprovalComment(dataEntryRoundScopeName,
															value,
															selection,
															resultParams)
									   .toPromise();
				});

			this.config.postApprovalButton(dataEntryRoundScopeName,
										   event,
										   selection,
										   resultParams)
				.subscribe(value => {
					const result = value.value;
					if (result.refreshView)
						this.getData(dataEntryRoundScopeName, selection);
					if (result.message)
						this.toastService.show({
												   type:         'info',
												   content:      result.message,
												   clickToClose: true
											   });
				});
		} catch (e) {

		}


	}

	getUserConfirmation(msg: string, type: DialogType = DialogType.none): Promise<boolean> {
		return new Promise<boolean>((resolve) => {
			const confirmDialogRef = this.dialog.open(DialogBasicComponent, {
				data: {
					dialogTitle: this.i8n.instant('CONFIRM'),
					type:        type,
					message:     msg
				}

			});

			confirmDialogRef.afterClosed()
							.subscribe(confirmed => {
								resolve(!!confirmed);
							});
		});
	}

	generateLabel(badgeInfo: any): string {
		let output = `<small class="f-body-s">`;
		if (badgeInfo.label) {
			output += `<i class="${badgeInfo.class} mr-2"></i><b>${badgeInfo.label}</b> `;
		}
		if (badgeInfo.user) {
			output += `<small>${this.i8n.instant('BY')}</small> <b>${badgeInfo.user}</b>`;
		}
		if (badgeInfo.time) {
			output += ` | <small style="font-size: 80%">${badgeInfo.time}</small>`;
		}
		output += '</small>';

		if (badgeInfo.lastEditUser) {
			output += `<br><small class="f-body-s"><small>${this.i8n.instant('LAST')} <b>${badgeInfo.lastEditRemark}</b> ${this.i8n.instant(
				'BY')} <b>${badgeInfo.lastEditUser}</b> | <small style="font-size: 80%">${badgeInfo.lastEditDate}</small></small></small>`;
		}
		return output;
	}


	getApprovalBadge(state: LocalState) {
		const {
				  Labels
				  , LocalStateName
				  , LocalStateLabel
				  , LocalStateUndoLabel
				  , LastAction
				  , DateLastEdit
				  , IsModifiedAfterSubmit
				  , IsModifiedAfterApproval
			  }        = this.data.dataSetMetaInfo.approvalState.LocalState;
		let badge: any = {};

		if (LocalStateName.toLowerCase() === 'pendingapproval') {
			badge = {
				class: 'mdi mdi-clock text-warning'
			};
		} else if (LocalStateName.toLowerCase() === 'approved') {
			badge = {
				class: 'mdi mdi-check-circle text-success'
			};
		} else if (LocalStateName.toLowerCase() === 'rejected') {
			badge = {
				class: 'mdi mdi-close-circle text-danger'
			};
		} else if (LocalStateName.toLowerCase() === 'notstarted') {
			badge = {
				class: 'mdi mdi-clock text-warning'
			};
		} else if (LocalStateName.toLowerCase() === 'inprogress') {
			badge = {
				class: 'mdi mdi-clock text-success'
			};
		} else if (LocalStateName.toLowerCase() === 'reopened') {
			badge = {
				class: 'mdi mdi-clock text-success'
			};
		} else if (LocalStateName.toLowerCase() === 'finished') {
			badge = {
				class: 'mdi mdi-check-circle text-success'
			};
		} else if (LocalStateName.toLowerCase() === 'waitingtoopen') {
			badge = {
				class: 'mdi mdi-clock text-success'
			};
		} else if (LocalStateName.toLowerCase() === 'closed') {
			badge = {
				class: 'mdi mdi-check-circle text-success'
			};
		}

		badge.label = LocalStateLabel;

		if (LastAction.Label) {
			badge.user = LastAction.User;
			if (!LastAction.IsCurrentState) {
				badge.label += ' - ' + LastAction.Label;
			} else {
				badge.label = LastAction.Label;
			}
			badge.time = LastAction.Date.length
						 ? this.format.format(new Date(LastAction.Date), new FormatRegisteredItem(null, '{0:g}'))
						 : '';
		}

		if (LocalStateUndoLabel) {
			badge.label += ' (' + LocalStateUndoLabel + ')';
		}

		badge.lastEditUser   = Labels.LastEditUser;
		badge.lastEditDate   = DateLastEdit.length
							   ? this.format.format(new Date(DateLastEdit), new FormatRegisteredItem(null, '{0:g}'))
							   : '';
		badge.lastEditRemark = (IsModifiedAfterApproval
								? this.i8n.instant('AFTER_APPROVAL')
								:
								(IsModifiedAfterSubmit
								 ? this.i8n.instant('AFTER_SUBMIT')
								 : ''));

		return badge;
	}

	toggleCommentaryPanel(item: ApprovalItem) {
		item.isOpen = !item.isOpen;
	}

	goToDataEntry(item: ApprovalItem) {
		this.filterCompareBarService.triggerNavigation(item.keys);
	}

	private navbarChangeSubscription: Subscription;

	private ticket: string = generateQuickGuid();

	private bodyClasses: Array<string> = ['bg-app-background'];


	private createApprovalItems(data: ApprovalDataDescribed) {
		return data.layout.factsToApprove.map(value => {
			return new ApprovalItem({
										label:      value.label,
										keys:       value.keys,
										factId:     value.id,
										options:    new ApprovalItemOptions(value.options),
										groups:     this.createApprovalGroups(value, data),
										comments:   this.findAllComments(value, data.dataSetMetaInfo),
										newComment: new ApprovalComment({
																			userName:  '',
																			timestamp: new Date(),
																			message:   '',
																			isSelf:    true,
																			factId:    value.id,
																			isNew:     true
																		})
									});
		});
	}

	private createApprovalGroups(item: FactsToApproveItem, data: ApprovalDataDescribed) {
		const result    = [];
		const positions = [];

		// find all comments for the matching facts
		data.data.forEach((value, index) => {
			if (value.factId === item.id) {
				result.push(value);
				positions.push(index);
			}
		});

		// cleanup the already found data rows
		for (let i = 0; i < positions.length; i++) {
			const position = positions[i];
			data.data.splice(position - i, 1);
		}
		const groups = result.map(value => new ApprovalGroup({
																 facts:       value,
																 deviations:  this.getDeviations(value, data.dataSetMetaInfo),
																 cellOptions: this.getCellOptions(value, data.dataSetMetaInfo)
															 }));

		return groups || [];
	}

	private findAllComments(item: FactsToApproveItem, dataSetMetaInfo: ApprovalMetaDataSet) {
		const result    = [];
		const positions = [];

		// find all comments for the matching facts
		dataSetMetaInfo.comments.forEach((value, index) => {
			if (value.factId === item.id) {
				result.push(new ApprovalComment(value));
				positions.push(index);
			}
		});

		// cleanup the already found comments
		for (let i = 0; i < positions.length; i++) {
			const position = positions[i];
			dataSetMetaInfo.comments.splice(position - i, 1);
		}
		// order the show the last comment on top
		return result.map(value => new ApprovalComment(value))
					 .sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime());
	}

	private getDeviations(value: any, dataSetMetaInfo: ApprovalMetaDataSet) {
		const found = dataSetMetaInfo.deviations.find(value1 => value1.rowId === value.id);

		return isNullOrUndefined(found)
			   ? null
			   : new ApprovalDeviation(found);
	}

	private getCellOptions(value: any, dataSetMetaInfo: ApprovalMetaDataSet) {
		const found = dataSetMetaInfo.cellOptions.find(value1 => value1.rowId === value.id);

		return isNullOrUndefined(found)
			   ? null
			   : new ApprovalCellOptions(found);
	}

	private checkForNewComments() {
		return this.approvalItems.filter(value => value.comments.find(comment => comment.isNew)).length > 0;
	}

	private getNewComments() {
		return this.approvalItems.reduce((previousValue, currentValue) => {
			previousValue.push(...currentValue.comments.filter(comment => comment.isNew));
			return previousValue;
		}, []);
	}

	private getData(scopeName: string, selection: {
		[key: string]: any
	}) {
		this.config.getApprovalData(scopeName, selection, this.filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams)
			.subscribe(value => {
				this.data          = value.value;
				this.approvalItems = this.createApprovalItems(this.data);
				if (hasPropertyOf(this.data.dataSetMetaInfo, 'pageButtons'))
					// WIP HAVE TO REGISTER THE BUTTONS, because of new implementacion of DynamicButton?
					this.dynamicButtons = this.data.dataSetMetaInfo.pageButtons.map(btn => {
						btn.type  = btn.name;
						btn.label = isUIPlaceholder(btn.label)
									? getUIPlaceholder(btn.label, this.i8n)
									: btn.label;
						return new DynamicButton(btn);
					});
				SafeMethods.detectChanges(this.changeRef);
				// THIS IS JUST A LITTLE QUICKFIX DO not Do it like this
				setTimeout(() => {


					const headerTitle = document.querySelector('.chart-panel__title');
					const foundBadge  = document.querySelector('#approvalBadge');

					// const user = LastAction.User;
					const badgeInfo = this.getApprovalBadge(this.data.dataSetMetaInfo.approvalState.LocalState);

					if (foundBadge) {
						foundBadge.classList.add('fade-out');
					}


					if (badgeInfo === null)
						return;

					const badge = document.createElement('div');
					badge.id    = 'approvalBadge';
					// badge.classList.add('badge');
					// badge.classList.add(badgeInfo.class);
					badge.innerHTML           = this.generateLabel(badgeInfo);
					badge.style.verticalAlign = 'top';
					badge.style.marginTop     = '0.5rem';
					if (foundBadge) {
						headerTitle.parentElement.replaceChild(badge, foundBadge);
					} else {
						headerTitle.parentElement.appendChild(badge);
					}
					setTimeout(() => badge.classList.add('fade-in'), 100);

					SafeMethods.detectChanges(this.changeRef);
				}, 1000);

			});
	}
}

@UntilDestroy()
export class ApprovalGroup {
	facts: {
		[key: string]: any
	};
	deviations: ApprovalDeviation | null;
	cellOptions: ApprovalCellOptions | null;

	get hasDeviation() {
		return !isNullOrUndefined(this.deviations);
	}

	constructor(init: Partial<ApprovalGroup>) {
		this.facts       = getPropertyOf(init, 'facts', []);
		this.deviations  = getPropertyOf(init, 'deviations', null);
		this.cellOptions = getPropertyOf(init, 'cellOptions', null);
	}

}

@UntilDestroy()
export class ApprovalItem {
	label: string;
	keys: {
		[key: string]: any
	};
	factId: number;
	groups: ApprovalGroup[];
	comments: Array<ApprovalComment>;
	newComment: ApprovalComment;
	isOpen: boolean;
	options: ApprovalItemOptions;

	constructor(init: Partial<ApprovalItem>) {
		this.label      = getPropertyOf(init, 'label');
		this.factId     = getPropertyOf(init, 'factId');
		this.keys       = getPropertyOf(init, 'keys', {});
		this.groups     = getPropertyOf(init, 'groups', []);
		this.comments   = getPropertyOf(init, 'comments', []);
		this.newComment = getPropertyOf(init, 'newComment', null);
		this.isOpen     = getPropertyOf(init, 'isOpen', false);
		this.options    = getPropertyOf(init, 'options', null);
	}
}

@UntilDestroy()
export class ApprovalItemOptions {
	useComments: boolean;
	useDataEntryLink: boolean;

	constructor(init: Partial<ApprovalItemOptions>) {
		this.useComments      = getPropertyOf(init, 'useComments');
		this.useDataEntryLink = getPropertyOf(init, 'useDataEntryLink');
	}
}

