import { NgModule } from '@angular/core';

import { FilterCompareBarService } from './state/filter-compare-bar.service';
import { FilterCompareBarQuery }   from './state/filter-compare-bar.query';
import { FilterCompareBarStore }   from './state/filter-compare-bar.store';
import { CommonModule }            from '@angular/common';


@NgModule({
	imports:   [CommonModule],
	providers: [FilterCompareBarQuery,
							FilterCompareBarStore,
							FilterCompareBarService]
})
export class CsFilterAndCompareBarServicesModule {

}
