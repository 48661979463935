import { CsHttpRequestOptions, Result } from '@cs/core';
import { IConfigService }               from '@cs/performance-manager/shared';
import { Observable }                   from 'rxjs';

// local imports
import { Repository }               from './models/Repository';
import { RepositoryDto }            from './models/RepositoryDto';
import { GitTableNavigationFilter } from './models/GitTableNavigationFilter';

export abstract class GitGraphConfigService implements IConfigService {

	// this is used for asset and translation resolvement
	moduleName = 'gitGraph';

	/**
	 * Gets list of submodule identifiers
	 */
	public abstract getSubmoduleList(filter: GitTableNavigationFilter, options?: CsHttpRequestOptions): Observable<Result<RepositoryDto[]>>;

	/**
	 * Gets annotated submodule
	 * @param identifier
	 */
	public abstract getAnnotatedSubmoduleTree(identifier: string, options?: CsHttpRequestOptions): Observable<Result<Repository>>;

}
