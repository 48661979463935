import { getPropertyOf, LoggerUtil } from '@cs/core';
import { ErrorMessage }              from '../../app/error-messages';

export class LoginUserProfile {
	lastName: string;
	isLDAP: boolean;
	gender: string;
	firstName: string;
	isImpersonated: boolean;
	lastLogin: Date;

	constructor(init: Partial<LoginUserProfile>) {
		try {
			this.isLDAP         = getPropertyOf(init, 'isLDAP', false);
			this.lastName       = getPropertyOf(init, 'lastName', '');
			this.gender         = getPropertyOf(init, 'gender', '');
			this.firstName      = getPropertyOf(init, 'firstName', '');
			this.isImpersonated = getPropertyOf(init, 'isImpersonated', false);
			this.lastLogin      = getPropertyOf(init, 'lastLogin', null);
		} catch (e) {
			LoggerUtil.error(ErrorMessage.MAPPING_BUSINESS_MODEL_TO_APPLICATION_MODEL_FAILED(LoginUserProfile, e));
		}

	}

}

