import { IConfigService } from '@cs/performance-manager/shared';
import { Observable }     from 'rxjs';
import { Result }         from '@cs/core';
import { HomePmData }     from './models/home-pm.model';

export abstract class HomePmConfigService implements IConfigService {

  moduleName = 'home';

  abstract getHomepage(): Observable<Result<HomePmData>>;
}
