import { FormControl, FormGroup } from '@angular/forms';
import { noop }                   from 'rxjs';
import { Logger }                 from '@cs/components/util';
import { isNullOrUndefined }      from '@cs/core';
import {
	AwbNumberValidationAnnotation,
	DataDescribed,
	DateRangeValidationAnnotation,
	InputMaskValidationAnnotation,
	IValidationAnnotation,
	LoggerUtil,
	MaxlengthValidationAnnotation,
	MinlengthValidationAnnotation,
	PropertyAnnotation,
	RangeValidationAnnotation,
	RegexValidationAnnotation,
	RequiredValidationAnnotation,
	ValidationAnnotationType
}                                 from '@cs/core';
import { CsValidatorRegistry }    from '@cs/components/form-generator-nxt';

export class WizardDataDescribedParser {

	static parseDataAnnotations<T extends DataDescribed<any, any, any, any>>(dataDescr: T,
																																					 formGroup: FormGroup,
																																					 csValidatorRegistry: CsValidatorRegistry): FormGroup {

		if (dataDescr == null || dataDescr.dataAnnotation == null)
			throw new Error('This is not a dataDescribed object, if you need a formgroup for this format you need to create a new parser');

		for (const annot of dataDescr.dataAnnotation.fields) {
			const fieldName   = annot.id.toString();
			const validators  = WizardDataDescribedParser.getValidators(annot, csValidatorRegistry);
			const formControl = new FormControl(dataDescr.data[fieldName], validators);
			formGroup.addControl(fieldName, formControl);
		}

		return formGroup;
	}

	static parseValidatorsList(validators: IValidationAnnotation[],
														 csValidatorRegistry?: CsValidatorRegistry,
														 propType?: string) {
		const output = [];

		if (isNullOrUndefined(validators))
			return output;

		// Switch to the function variant because a switch in a for loop with breaks. breaks the for loop :S
		validators.forEach(val => {
			switch (val.type) {
				case ValidationAnnotationType.Minlength:
					output.push(new MinlengthValidationAnnotation(val as MinlengthValidationAnnotation));
					break;
				case ValidationAnnotationType.Maxlength:
					output.push(new MaxlengthValidationAnnotation(val as MaxlengthValidationAnnotation));
					break;
				case ValidationAnnotationType.Required:
					output.push(new RequiredValidationAnnotation(val as RequiredValidationAnnotation));
					break;
				case ValidationAnnotationType.Range:
					const rangeVal = val as RangeValidationAnnotation;
					// Convert a date to a UNIX timestamp, so the validator can compare with numbers instead of dates
					// TODO: this should be formalised by the server
					if (propType === 'DateTime')
						output.push(new DateRangeValidationAnnotation(rangeVal));
					else
						output.push(new RangeValidationAnnotation(rangeVal));
					break;
				case ValidationAnnotationType.Regex:
				case 'regex':
					output.push(new RegexValidationAnnotation(val as RegexValidationAnnotation));
					break;
				case ValidationAnnotationType.InputMask:
					output.push(new InputMaskValidationAnnotation(val as InputMaskValidationAnnotation));
					break;
				case ValidationAnnotationType.AwbNumber:
					output.push(new AwbNumberValidationAnnotation(val as AwbNumberValidationAnnotation));
					break;
				default:
					if (isNullOrUndefined(val.type)) {
						Logger.Warning(`${JSON.stringify(val)} is not a valid validator`);
						break;
					}
					if (csValidatorRegistry && csValidatorRegistry.hasValidator(val.type)) {
						const validator = csValidatorRegistry.getValidator(val.type);
						if (isNullOrUndefined(validator)) {
							Logger.Warning(`${val.type} was found as validator, but the entry is NULL`);
							break;
						}

						output.push(new validator(val));
					} else {
						Logger.Warning(`${val.type} is not found as validator`);
					}

			}
		});
		return output;
	}

	static parseValidators(propertyAnnot: PropertyAnnotation<any>, csValidatorRegistry?: CsValidatorRegistry) {
		return WizardDataDescribedParser.parseValidatorsList(propertyAnnot.validators, csValidatorRegistry, propertyAnnot.type);
	}

	private static getValidators(annotation: PropertyAnnotation<any>, csValidatorRegistry: CsValidatorRegistry) {
		if (annotation.validators == null)
			annotation.validators = [];
		// extra is required check because some properies
		// could have been optional false but the required attribute is missing so no required validation is added
		if (!annotation.optional && !isNullOrUndefined(annotation.validators)
			&& isNullOrUndefined(annotation.validators.find(a => a.type === ValidationAnnotationType.Required))) {
			annotation.validators.push({
																	 type:          ValidationAnnotationType.Required,
																	 errorMessage:  `${annotation.label} is required`, // TODO: TRanslate?
																	 validatorFunc: null // is ignored by the constructor
																 });
			LoggerUtil.warn(
				`${annotation.id.toString()} is required, but no required attribute is added to the model. So we added an fallback validator`);
		}

		annotation.validators = WizardDataDescribedParser.parseValidators(annotation, csValidatorRegistry);

		// when optional is true but we have a required validator change it to optional false
		if (annotation.optional && !isNullOrUndefined(annotation.validators)
			&& annotation.validators.find(a => a.type === ValidationAnnotationType.Required)) {
			annotation.optional = false;
		}

		const output = [];
		for (const validator of annotation.validators) {
			output.push(...validator.validatorFunc());
		}
		return output;
	}

}
