import { Inject, Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined }           from '@cs/core';
import zipObject                       from 'lodash/zipObject';
import { FormatProviderService }       from '@cs/common';
import { FormatRegisteredItem }        from '@cs/core';

@Pipe({name: 'googleChartTransformer'})
export class GoogleChartTransformerPipe implements PipeTransform {

	constructor(@Inject(FormatProviderService) private formatProviderService: FormatProviderService) {

	}

	transform(data: any, args?: any): any {
		if (!data || !data.data || !data.series) {
			return;
		}

		const mappedData = this.mapData(data, data.series);

		const hasData = this.hasData(mappedData);

		return hasData ? mappedData : null;
	}

	/**
	 * This function maps the given data to a google chart multi dimensional array

	 */
	mapData(chartData, series) {
		const dataColumns = [];
		const mappings    = [];
		const tooltipProp = chartData.resolveTooltipProp || 'label';
		const xAxisProp   = chartData.resolveXAxisProp || 'labelMin';

		// Create empty mappings arrays based on the given axis members
		// Also create a list of id's to match data to a axis row
		for (const o of chartData.mainAxisMembers) {
			dataColumns.push(o.id);
			const tooltipVal = o.hasOwnProperty(tooltipProp) ? o[tooltipProp] : 'label';
			const xAxisVal   = o.hasOwnProperty(xAxisProp) ? o[xAxisProp] : 'labelMin';
			mappings.push([xAxisVal, tooltipVal]);
		}

		// Added the first column for the labels on the axis
		const header: any[] = [{label: 'Column', id: 'column', type: 'string'}, {type: 'string', role: 'tooltip', p: {'html': true}}];

		// Loop over the series and create a header for that serie
		for (let i = 0; i < chartData.series.length; i++) {
			const data      = series[i] || {label: 'Value ' + (i + 1)};
			// Add header to the header array
			const head: any = {label: data.label, id: data.presetName, type: 'number'};
			if (!isNullOrUndefined(data.preset) && data.preset.color)
				head.color = '#' + data.preset.color;

			head.format = !isNullOrUndefined(data.preset) && data.preset.format ? data.preset.format : '{0:N2}';

			header.push(head);
			// Create a object by zipping two arrays where the first array contains the keys and the second the values
			// they are merged based on index
			const zippedData = [];
			if (!isNullOrUndefined(chartData.data.series[i])) {
				chartData.data.series[i].forEach(item => {
					zippedData.push(zipObject(chartData.data.columns, item));
				});
			}

			// get the property name for the key
			const colName   = chartData.data.columns[0];
			// get the property name for the value
			const valueName = chartData.data.columns[1];

			// loop over the given axises and fill the axis row with data
			for (let o = 0; o < chartData.mainAxisMembers.length; o++) {
				// get the axis
				const axisMem = chartData.mainAxisMembers[o];
				// get the axis row
				const row     = mappings[o];
				// Find the matching key for the Axis row and, if found, add the value to the row
				const found   = zippedData.find(x => x[colName] === axisMem.id);
				const value   = !isNullOrUndefined(found) ? found[valueName] : null;

				row.push(value);

			}

		}

		this.addTooltips(mappings, header);

		// add headers to the mappings
		mappings.splice(0, 0, header);

		return mappings;
	}

	hasData(data) {
		let hasData = false;

		data.forEach(x => {
			const nonEmptyValues = x.filter((value, key) => {
				return key > 0 && value > -1;
			});

			if (nonEmptyValues.length > 0) {
				hasData = true;
				return;
			}
		});

		return hasData;
	}

	private generateTooltipHtml(row: any[], header: any[]) {
		// create container
		const tooltipContainer = document.createElement('div');
		tooltipContainer.classList.add('google-visualization-tooltip');

		const ul = document.createElement('ul');
		ul.classList.add('google-visualization-tooltip-item-list');
		const labelTooltip = document.createElement('li');
		labelTooltip.classList.add('google-visualization-tooltip-item');
		labelTooltip.classList.add('google-visualization-tooltip-header');
		labelTooltip.innerHTML = row[1];

		ul.appendChild(labelTooltip);

		for (let i = 2; i < header.length; i++) {
			const head  = header[i];
			const value = this.formatProviderService.format(row[i],
				new FormatRegisteredItem(null, head.format));
			const label = head.label + ':';
			const color = head.color;

			if (isNullOrUndefined(value))
				continue;

			const labelTooltip = document.createElement('li');
			labelTooltip.classList.add('google-visualization-tooltip-item');

			const spanLabel = document.createElement('span');
			spanLabel.classList.add('google-visualization-tooltip-label');
			spanLabel.textContent = label;

			const spanValue = document.createElement('span');
			spanValue.classList.add('google-visualization-tooltip-value');
			spanValue.textContent = value;

			const icon = document.createElement('div');

			if (!isNullOrUndefined(color))
				icon.style.backgroundColor = color;

			icon.classList.add('google-visualization-tooltip-square');

			labelTooltip.appendChild(icon);
			labelTooltip.appendChild(spanLabel);
			labelTooltip.appendChild(spanValue);

			ul.appendChild(labelTooltip);
		}
		tooltipContainer.appendChild(ul);
		return tooltipContainer.innerHTML;
	}

	private addTooltips(mappings: any[][], header: any[]) {
		for (const row of mappings) {
			row[1] = this.generateTooltipHtml(row, header);
		}
	}

}
