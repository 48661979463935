<ng-container *ngIf="imageUrl">
  <div class="cs-avatar"
       [style.background-image]="'url('+imageUrl+')'">
  </div>
</ng-container>
<ng-container *ngIf="!imageUrl">
  <div class="cs-avatar textual">
    <div class="avatar">{{initials}}</div>
  </div>
</ng-container>
