<div class="form-field-list">
  <div class="form-field-list-buttons" *ngIf="fieldType === 'checkbox'">
    <a (click)="onSelectAll()" [class.disabled]="disabled">Select All</a>
    <a (click)="onSelectNone()" [class.disabled]="disabled">None</a>
  </div>
  <div class="list-container" [ngStyle]="{'max-height': height+'px', 'overflow': isScrollable? 'auto' : 'hidden'}">
    <ul class="list" [ngClass]="'list-'+listType"
        [ngStyle]="{'flex-direction': showAsHorizontalList || listType === 'grid'? 'row' : 'column'}">
      <li class="list-item" *ngFor="let item of lookupValues"
          [ngClass]="listType === 'grid'? 'list-item-grid-'+gridColumns : null">
        <div *ngIf="fieldType === 'checkbox'" class="" data-toggle="buttons">
          <label class="btn btn-checkbox" [ngClass]="{'active': isSelected(item.id,false), 'disabled': disabled}">
            <input #checkbox
                   [type]="fieldType"
                   class="list-item-checkbox"
				   [id]="item.id"
                   [name]="item.id"
                   [value]="1"
                   (change)="onChange($event)"
                   [checked]="isSelected(item.id, false)"
                   [disabled]="disabled">
            <i class="icon icon-check"></i>
          </label>
          <label class="list-item-label  hover-pointer"  [for]="item.id" (click)="onLabelClick(item.id, false)">{{item.value}}</label>
        </div>

        <div *ngIf="fieldType === 'radio'" class=" btn-group" data-toggle="buttons">
          <label class="btn btn-radio" [ngClass]="{'active': isSelected(item.key, true), 'disabled': disabled}">
            <input #radioButton
                   [type]="fieldType"
				   [id]="item.key"
                   class="list-item-radio"
                   [name]="radiogroup"
                   [value]="item.key"
                   (change)="onChange($event)"
                   [checked]="isSelected(item.key,true)"
                   [disabled]="disabled">
            <i class="icon icon-check"></i>
          </label>
          <label class="list-item-label hover-pointer" [for]="item.id" (click)="onLabelClick(item.key, true)">{{item.value}}</label>
        </div>
      </li>
    </ul>
  </div>
</div>
