import { CommonModule }                  from '@angular/common';
import { FormsModule }                   from '@angular/forms';
import { NgModule, ModuleWithProviders } from '@angular/core';

import { CsInputMaskDirective }   from './input-mask.directive';
import { CsInputMaskConfig }      from './input-mask-aliases';
import { CsInputMaskDefaults }    from './input-mask-defaults';
import { CsInputMaskDefinitions } from './input-mask-definitions';


@NgModule({
	imports:      [
		CommonModule,
		FormsModule
	],
	declarations: [
		CsInputMaskDirective
	],
	exports:      [
		CsInputMaskDirective
	]
})
export class CsMaskModule {
	static forRoot(): ModuleWithProviders<CsMaskModule> {
		return {
			ngModule:  CsMaskModule,
			providers: [CsInputMaskConfig, CsInputMaskDefaults, CsInputMaskDefinitions]
		};
	}

}
