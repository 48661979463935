import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { CsPdfViewerComponent }          from './pdf-wrapper.component';
import { PdfViewerModule } 							 from '../pdf-viewer/pdf-viewer.module';



@NgModule({
	declarations: [CsPdfViewerComponent],
	exports:      [CsPdfViewerComponent],
	imports:      [CommonModule,
								 PdfViewerModule]
})
export class CsPdfViewerModule {
	static forRoot(): ModuleWithProviders<CsPdfViewerModule> {
		return {ngModule: CsPdfViewerModule};
	}
}
