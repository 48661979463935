import { Component, Input }                    from '@angular/core';
import { DomSanitizer }                        from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector:    'cs-statistics-panel-none',
  templateUrl: './statistics-panel-none.component.html',
  animations:  [
    trigger('statisticsState', [

      transition(':leave', [ // each time the binding value changes

        style({opacity: 1}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 0
          }))

      ]), transition(':enter', [ // each time the binding value changes

        style({opacity: 0}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 1
          }))

      ])
    ])
  ]

})
export class CsStatisticsPanelNoneComponent {
  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() data: any;

  constructor(private sanitizer: DomSanitizer) {
  }

}
