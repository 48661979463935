<div class="wizard-container shadow-subtle flex flex-column">
	<div class="wizard-header-container cs-row">
		<div class="cs-col-3">
			<h1 class="f-h1">{{dataContext?.dataAnnotation?.displayName}}</h1>
		</div>
		<div class="wizard-header-container--navigation cs-col-9">
			<button class="btn btn--icon-and-text"
					[disabled]="!canNavigateBack"
					(click)="navigateBack($event)">
				<i class="mdi mdi-arrow-left"></i>
				<div class="f-uppercase">{{'BACK' | translate}}</div>
			</button>
			<button class="btn btn--icon-and-text"
					*ngIf="!isLastStep"
					[disabled]="!canNavigateForward"
					(click)="navigateNext($event)">
				<div class="f-uppercase">{{'NEXT' | translate}}</div>
				<i class="mdi mdi-arrow-right"></i>
			</button>
			<button class="btn btn--icon-and-text"
					*ngIf="isLastStep"
					[disabled]="!canNavigateForward"
					(click)="finishWizard($event)">
				<div class="f-uppercase">{{'FINISH' | translate}}</div>
				<i class="mdi mdi-flag-checkered"></i>
			</button>
		</div>
	</div>
	<div class="cs-row">
		<div class="wizard-container--sidebar cs-col-3">

			<div class="stepper-container">
				<cs-wizard-stepper #wizardStepper="wizardStepper"
								   [steps]="steps"
								   (stepChanged)="setStepActive($event)"
				></cs-wizard-stepper>
			</div>
			<div class="stepper-container--logo">

			</div>
		</div>
		<div class="wizard-content-container cs-col-9">
			<div class="wizard-content-container--slot " [formGroup]="formgroup">
				<ng-container [csComponentLoader]="contentComponent"
							  [componentData]="contentData"
							  [parentContext]="contentContext"
				></ng-container>
			</div>
		</div>
	</div>
</div>
