import { Store, StoreConfig } from '@datorama/akita';
import { Injectable } from '@angular/core';
import { AuditTrailAudits } from '../components/audit-trail-audits/audit-trail-audits.model';
import { TableDataDescribed } from '@cs/core';

export interface AuditTrailState {

   factTableName: string;              // Source FactTable for all data requests.

   isAuditPanelVisible: boolean;       // TODO
   isAuditPanelLoading: boolean;       // loading data for audits
   isChangesPanelVisible: boolean;     // changes panel should be visible
   isChangesPanelLoading: boolean;     // loading data for changes panel
   lastChangesSelection: { [p: string]: number | string }; // selection used to retreive the changes, used for lookup of audits.

   auditsData: AuditTrailAudits;       // data for audits panel
   changesData: { meta: TableDataDescribed<any>,
                  data: TableDataDescribed<any> };  // data for audit changes
}

export function createInitialAuditTrailState(): AuditTrailState {
  return {
    factTableName: null,
    isAuditPanelVisible: false,
    isAuditPanelLoading: false,
    isChangesPanelVisible: false,
    isChangesPanelLoading: false,
    lastChangesSelection: {},
    auditsData: new AuditTrailAudits({ items: null}),
    changesData: { meta: null, data: null }
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({ name: 'audit-trail' })
export class AuditTrailStateStore extends Store<AuditTrailState> {
  constructor() {
    super(createInitialAuditTrailState());
  }
}
