import { GridHeaderRow }                             from './grid-head-row.model';
import { GridDataRow }                               from './grid-data-row.model';
import { DataGridType }                              from '../classes/data-grid-type';
import { GridItemType }                              from '../enums/data-grid.enum';
import { createToObjectWithLowerCaseKeys, isString } from '@cs/components/util';

/**
 * Created by alex on 28-6-2017.
 */

export class GridGroup implements DataGridType {
	dataGridType: GridItemType       = GridItemType.Group;
	columsRows: Array<GridHeaderRow> = [];
	dataRows: Array<GridDataRow>     = [];

	private _key: any;
	private _keys: any;

	get key(): any {
		return this._key;
	}

	set key(value: any) {
		this._key = isString(value) ? value.toLowerCase() : value;
	}

	get keys(): any {
		return this._keys;
	}

	set keys(value: any) {
		const copy = {};
		for (const key of Object.getOwnPropertyNames(value)) {
			copy[key] = value[key];
		}
		this._keys = createToObjectWithLowerCaseKeys(copy);
	}

	constructor() {
	}

}
