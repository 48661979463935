import { Injector }                                             from '@angular/core';
import { Params }                                               from '@angular/router';
import { SafeMethods }                                          from '@cs/common';
import {
	CsDataGrid, DataGridCellType, DataGridHelpers, DataGridInterpreter, DataGridMessageHubService, DataGridRuleEnforcer, GridDataCell,
	GridDataRow, GridItemType, GridOptions, GridSheet, GridSheetMetaValues, ICellBehaviourParams, IDataGridStructure, IInitData,
	IInitDataConfigLayout, RowState
}                                                               from '@cs/components/data-grid';
import {
	FilterBarResultParams, FilterCompareBarQuery
}                                                               from '@cs/components/filter-and-compare-bar';
import { CsHttpRequestOptions, isNullOrUndefined, pathChecked } from '@cs/core';
import {
	AppQuery, BottomBarQuery, BottomBarService, DynamicButton, ImportResultMessage, ToastService
}                                                               from '@cs/performance-manager/shared';
import { TranslateService }                                     from '@ngx-translate/core';
import { Subject }                                              from 'rxjs';
import { DataGridUtils }                                        from '../agents/data-grid.utils';
import { DataEntryConfigService }                               from '../data-entry-config.service';
import {
	DataEntryResultParams
}                                                               from '../models/data-entry-result-params';
import {
	DataEntryStateQuery
}                                                               from '../state/data-entry-state.query';
import {
	DataEntryStateService
}                                                               from '../state/data-entry-state.service';

export class DataGridWorker {
	readonly config: DataEntryConfigService;
	public refreshAfterSave: boolean;

	/**
	 * Raw Data form the server, Used for initialisation of the page
	 */
	data: IInitData;
	/**
	 * Collection of sheets containing information to display a table
	 */
	sheets: Array<GridSheet>;
	/**
	 * Options for the dataGrid
	 */
	options: GridOptions;
	/**
	 * Layout settings for the datagrid
	 */
	layout: IInitDataConfigLayout;

	hasUnconfirmedFacts: boolean;
	/**
	 * The buttons provided by the server,
	 */
	dynamicButtons: DynamicButton[];

	/**
	 * event indicating that import is done uploading
	 */
	onImportUploaded: Subject<ImportResultMessage> = new Subject<ImportResultMessage>();

	get gridName(): string {
		return this._gridName;
	}

	constructor(private _gridName: string,
				private gridRef: CsDataGrid,
				private injector: Injector) {

		this.toastService              = this.injector.get(ToastService);
		this.i8n                       = this.injector.get(TranslateService);
		this.config                    = this.injector.get(DataEntryConfigService);
		this.filterCompareBarQuery     = this.injector.get(FilterCompareBarQuery);
		this.dataEntryStateService     = this.injector.get(DataEntryStateService);
		this.dataEntryStateQuery       = this.injector.get(DataEntryStateQuery);
		this.dataGridMessageHubService = this.injector.get(DataGridMessageHubService);
		this.bottomBarService          = this.injector.get(BottomBarService);
		this.bottomBarQuery            = this.injector.get(BottomBarQuery);

		this.initAgent();
	}

	updateData(data: IInitData) {
		this.data   = data;
		this.layout = this.data.config.layout;

		this.bottomBarService.useRowLabel(this.layout.useRowLabel);

		if (this.layout.useReasonOnSave)
			this.bottomBarService.inputOptions(this.layout.useReasonOnSave);

		this.checkIfDataContainsFacts(this.data);
		this.options = DataGridUtils.getOptions(this.data);
		this.setupClientSettings(this.data);
		const buttonsResult = DataGridUtils.setupButtons(this.data, this.injector);
		this.dynamicButtons = buttonsResult.dynamicButtons;

		this.dataEntryStateService.update({
											  cargoToolSettings: this.options.client.cargoDataEntryTool,
											  saveMethod:        this.options.saveMethod
										  });
		return this.updateStructure();
	}

	updateCompareData(data: IInitData) {
		this.compareData    = data;
		this.compareOptions = DataGridUtils.getOptions(this.compareData);
		this.checkIfDataContainsFacts(this.compareData);
		return this.updateCompareStructure();
	}

	cleanCompare() {
		this.compareOptions = null;
		this.compareData    = null;
		this.compareSheets  = [];

		if (this.gridRef) {
			this.gridRef.cleanCompare();
			this.gridRef.calculateSheetsAsync(this.sheets)
				.subscribe(value => {
					this.gridRef.updateCells(DataGridCellType.Offset);
				});
		}
	}

	refreshData() {
		const resultParams = this.filterCompareBarQuery.getValue().mainbarResultParams as DataEntryResultParams;
		this.config.getInitBundle(resultParams.dataEntryGrid,
								  resultParams.selection,
								  Object.assign({}, resultParams, {selection: undefined}))
			.subscribe(result => {
				this.updateData(result.value)
					.then(value1 => {
						this.gridRef.updateCells(DataGridCellType.All);
						// event request parent update?
					});
			});
	}

	updateCalculations() {
		this.gridRef.calculateSheetsAsync(this.sheets)
			.subscribe(value => {
				for (const sheet of this.sheets) {
					DataGridRuleEnforcer.executeDynamicRules(sheet);
				}
				this.gridRef.updateCells(DataGridCellType.All);
				this.gridRef.updateCells(DataGridCellType.Total);
			});

	}


	filterCells(cellType: DataGridCellType, rowType: RowState, scopeType: GridItemType) {
		const cells: GridDataCell[] = [];

		for (const sheet of this.sheets) {
			cells.push(...DataGridHelpers.filterCells(sheet, cellType, rowType, scopeType));
		}

		return cells;
	}

	updateCells(type: DataGridCellType) {
		this.gridRef.updateCells(type);
	}

	getChangedCells() {
		const allDataGrids = this.gridRef.getNestedDataGrids();
		if (isNullOrUndefined(allDataGrids))
			return;

		allDataGrids.push(this.gridRef);

		let invalidData   = false;
		let changed       = 0;
		let requireReason = false;

		for (const grid of allDataGrids) {
			const changedCells = Array.from(grid.getChangedCells()
												.values());
			changed += changedCells.length;

			if (!invalidData)
				invalidData = !isNullOrUndefined(changedCells.find(cell => cell.cellUIState.invalid));

			if (!requireReason)
				requireReason = !isNullOrUndefined(changedCells.find(cell => cell.cellState.requireReason));
		}

		if (isNullOrUndefined(allDataGrids))
			return;

		// check if there are invalid injected cells
		allDataGrids.forEach(grid => {
			if (isNullOrUndefined(grid) || isNullOrUndefined(grid.sheets))
				return;

			for (const sheet of grid.sheets) {
				const foundInvalidInjectedCells = DataGridHelpers.filterCells(sheet, DataGridCellType.Injected)
																 .filter(cell => cell.cellUIState.invalid);
				if (!isNullOrUndefined(foundInvalidInjectedCells) && foundInvalidInjectedCells.length > 0) {
					invalidData = true;
					return;
				}
			}

		});

		if (!invalidData)
			invalidData = !this.checkIfOneValueIsEnteredOnNewlyCreatedRows(allDataGrids);

		const numberOfChangedCells = changed;
		const numberOfChangedRows  = this.getNumberOfChangedRows(allDataGrids);

		// notify service one or more cell require a reason to be entered
		this.bottomBarService.inputRequired(requireReason);
		this.bottomBarService.isDataValid(!invalidData);


		if (this.bottomBarQuery.getValue().isRowLabel) {
			this.bottomBarService.registerChange(numberOfChangedRows);
			if (this.options.useSaveBar)
				this.bottomBarService.toggleBottomBar(numberOfChangedRows > 0);
		} else {
			this.bottomBarService.registerChange(numberOfChangedCells);
			if (this.options.useSaveBar)
				this.bottomBarService.toggleBottomBar(numberOfChangedCells > 0);
		}
		return numberOfChangedCells;
	}

	async renderExpensionDataGrid(rowToExpand: GridDataRow, selection: any, dataEntryGrid: string,
								  params: {} & DataEntryResultParams & ICellBehaviourParams, sheet: GridSheet) {
		// Don't load expansion data twice
		if (rowToExpand.isLoading) {
			return;
		}

		rowToExpand.isLoading = true;

		const struct           = await this.config.getInitBundle(dataEntryGrid, selection, params)
										   .toPromise();
		const expandData       = struct.value;
		const expandOptions    = DataGridUtils.getOptions(expandData);
		expandOptions.isNested = true;
		const sheets           = await this.createSheets(expandData, expandOptions);

		sheets.forEach(s => {
			s.colGroup                  = sheet.colGroup;
			s.settings.fixedSize        = true;
			s.settings.showHeaderAction = false;
			s.settings.showAddAction    = false;
			s.settings.showLabelAction  = false;
			s.settings.showSaveAction   = false;
			s.settings.showCancelAction = false;
			s.metaValues                = new GridSheetMetaValues({isInNested: true}); // TODO: make generic
			s.calculator.calculateAll();
			DataGridRuleEnforcer.executeRules(expandOptions.rules, s, false);
			DataGridRuleEnforcer.executeDynamicRules(sheet);
		});
		CsDataGrid.executeDefaultSortRows(sheets, expandOptions);
		this.gridRef.expandRow(rowToExpand, sheets, expandOptions, params);
		rowToExpand.isLoading = false;

		return sheets;
	}


	async expandStructure(struct: IInitData) {

		this.updateExpandStructure(struct.structureData, struct.facts, this.options)
			.then(sheets => {
				sheets.forEach(sheet => sheet.calculator.calculateAll());
				for (const sheet of sheets) {
					DataGridRuleEnforcer.executeDynamicRules(sheet);
					DataGridUtils.calculateDataGridCellWidth(this.gridRef,
															 this.options, sheet, sheets,
															 this.dataEntryStateQuery.getValue().showChart, this.layout.defaultWidthForLabels);
				}
				CsDataGrid.executeDefaultSortRows(sheets, this.options);
				this.sheets.push(...sheets);
				this.updateCalculations();
				SafeMethods.detectChanges(this.gridRef.changeRef);
			});

	}

	async updateExpandStructure(structureData: IDataGridStructure, data, options: GridOptions) {

		let sheets = await
			DataGridInterpreter.createEmptySheetsAsync(
				structureData.dimensionTrees.sheets.memberTree,
				structureData.dimensionTrees.columnTree.memberTree,
				structureData.dimensionTrees.rowTree.memberTree,
				options);

		sheets = await DataGridInterpreter.fillDataGridWithDataRowsAsync(
			sheets,
			data,
			options);

		return sheets;
	}

	addEmptyRow(sheet: GridSheet, editable?: boolean, keys?: Params) {
		this.gridRef.addEmptyRow(sheet, editable, keys);
	}


	updateCellWidth() {

		for (const sheet of this.sheets) {
			DataGridUtils.calculateDataGridCellWidth(this.gridRef,
													 this.options, sheet, this.sheets,
													 this.dataEntryStateQuery.getValue().showChart, this.layout.defaultWidthForLabels);

		}

	}


	/**
	 * Update the layout of the grid (columns, rows, sheets, ... etc)
	 */
	protected async updateStructure() {

		this.sheets = await this.createSheets(this.data, this.options);
		// Delay the calculation because, requesting directly after setting the property the ruler has not the right width yet
		setTimeout(() => {
			for (const sheet of this.sheets) {
				DataGridUtils.calculateDataGridCellWidth(this.gridRef,
														 this.options, sheet, this.sheets,
														 this.dataEntryStateQuery.getValue().showChart, this.layout.defaultWidthForLabels);

			}
		}, 0);


		return this.sheets;
	}

	/**
	 * Create the sheets based on the server InitBundle
	 * @param data the data from the server as InitBundle
	 * @param options the options for the grid
	 */
	protected async createSheets(data: IInitData, options: GridOptions) {
		const sheets = await
			DataGridInterpreter.createEmptySheetsAsync(
				data.structureData.dimensionTrees.sheets.memberTree,
				data.structureData.dimensionTrees.columnTree.memberTree,
				data.structureData.dimensionTrees.rowTree.memberTree,
				options);

		return await this.renderSheets(sheets, data, options);
	}

	/**
	 * Will prepare the data to match the rendered main grid. After this is done loop over all the cells to compare the data.
	 * The compare will set some deviations and will be giving meaning by applying the rule system
	 */
	protected async updateCompareStructure() {

		this.compareSheets = await
			DataGridInterpreter.createEmptySheetsAsync(
				this.compareData.structureData.dimensionTrees.sheets.memberTree,
				this.compareData.structureData.dimensionTrees.columnTree.memberTree,
				this.compareData.structureData.dimensionTrees.rowTree.memberTree,
				this.compareOptions);

		this.compareSheets = await DataGridInterpreter.fillDataGridWithDataRowsAsync(
			this.compareSheets,
			this.compareData.facts,
			this.compareOptions);

		DataGridUtils.compareSheets(this.sheets, this.compareSheets, this.options, this.compareOptions)
					 .subscribe(value => {
						 this.gridRef.calculateSheetsAsync(this.sheets)
							 .subscribe(value1 => {
								 this.gridRef.updateCells(DataGridCellType.All);
								 // SafeMethods.detectChanges(this.gridRef.changeRef);
							 });
					 });

	}

	protected async renderSheets(sheets: GridSheet[], data: IInitData, options: GridOptions) {
		const filledSheets = await DataGridInterpreter.fillDataGridWithDataRowsAsync(sheets, data.facts, options);

		await this.gridRef.calculateSheetsAsync(filledSheets)
				  .toPromise();

		for (const sheet of filledSheets) {
			DataGridRuleEnforcer.executeDynamicRules(sheet);
		}
		// this.sheetsCreated.next(this);
		return filledSheets;
	}

	private toastService: ToastService;
	private i8n: TranslateService;
	private appStateQuery: AppQuery;
	private clientSettings: any;
	private dataEntryStateService: DataEntryStateService;
	private dataEntryStateQuery: DataEntryStateQuery;
	private filterCompareBarQuery: FilterCompareBarQuery;
	/**
	 * The data that is used to compare the sheets with
	 */
	private compareSheets: Array<GridSheet>;
	private compareData: IInitData;
	private compareOptions: GridOptions;
	private dataGridMessageHubService: DataGridMessageHubService;
	private bottomBarService: BottomBarService;
	private bottomBarQuery: BottomBarQuery;

	private checkIfDataContainsFacts(data: IInitData) {
		if (!data.facts.meta.hasData) {
			this.toastService.info(this.i8n.instant('NO_DATA'), this.i8n.instant('NO_DATA_AVAILABLE'));
		}
	}

	private setupClientSettings(data: IInitData) {
		this.clientSettings   = this.data.config.client;
		this.refreshAfterSave = !isNullOrUndefined(this.clientSettings.refreshAfterSave)
								? this.clientSettings.refreshAfterSave
								: false;
		this.dataEntryStateService.setScrollDetection(pathChecked(this.data, ['config', 'layout', 'enableContinuesScrolling'], false));

	}

	private async isDataConfirmed(dataEntryGrid: string, selection: FilterBarResultParams, params: {
		[key: string]: string
	}) {

		if (!this.config.enableLegacyDataConfirm)
			return;

		const options: CsHttpRequestOptions = new CsHttpRequestOptions();
		options.errorResponseHandler        = (error: any) => true;

		try {
			// Temporary fix until CARGO also has this method
			this.config.hasUnconfirmedFacts(dataEntryGrid, params, selection, options)
				.subscribe(result => {
					this.hasUnconfirmedFacts = result.value.hasUnconfirmedFacts;
					if (this.hasUnconfirmedFacts) {
						this.dynamicButtons.splice(0, 0, new DynamicButton({
																			   name:        'confirmData',
																			   label:       'Confirm data',
																			   description: 'Confirm your data when finished',
																			   type:        'EntryGridButton',
																			   btnClass:    'btn-primary',
																			   confirm:     'This will indicate you\'re done with data-entry. Are you sure?'
																		   }));
					}
				});

		} catch (ex) {

		}

	}

	private checkIfOneValueIsEnteredOnNewlyCreatedRows(allDataGrids: CsDataGrid[]) {
		let foundNewRowsCount = 0;
		let notEmptyRowsCount = 0;
		if (isNullOrUndefined(allDataGrids))
			return;
		for (const grid of allDataGrids) {
			if (isNullOrUndefined(grid) || isNullOrUndefined(grid.sheets))
				return;
			for (const sheet of grid.sheets) {
				if (isNullOrUndefined(sheet) || isNullOrUndefined(sheet.groups))
					return;
				for (const group of sheet.groups) {
					const foundNewRows = group.dataRows.filter(row => row.rowState === RowState.New && !row.isExpanded);
					foundNewRowsCount += foundNewRows.length;

					const notEmptyRows = foundNewRows.filter(row =>
																 row.values.findIndex(
																	 cell => cell.cellType === DataGridCellType.Data && !isNullOrUndefined(
																		 cell.value)) > -1);
					notEmptyRowsCount += notEmptyRows.length;

				}
			}
		}
		return foundNewRowsCount === notEmptyRowsCount;
	}

	private getNumberOfChangedRows(allDataGrids: CsDataGrid[]) {
		let output = 0;
		for (const grid of allDataGrids) {
			for (const sheet of grid.sheets) {
				for (const group of sheet.groups) {
					const foundDirtyRows = group.dataRows.filter(row => {
						return row.rowState === RowState.New
							|| (row.values.findIndex(cell => cell.cellUIState.dirty) > -1);
					});
					output += foundDirtyRows.length;
				}
			}
		}
		const deletedRows = this.gridRef.getTotalAmountOfDeletedRows();
		output += (deletedRows < 0
				   ? 0
				   : deletedRows);
		return output;
	}

	private initAgent() {

	}
}
