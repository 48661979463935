import { CsTemplate } from './table-template.directive';
import {
  Component, Input, AfterContentInit, TemplateRef, ContentChild, ContentChildren, QueryList
}                     from '@angular/core';

@Component({
  selector: 'cs-column',
  template: ``
})
export class Column implements AfterContentInit {
  @Input() field: string;
  @Input() title: string;
  @Input() class       = '';
  @Input() headerClass = '';
  @ContentChildren(CsTemplate) templates: QueryList<any>;
  @ContentChild(TemplateRef) template: TemplateRef<any>;

  public headerTemplate: TemplateRef<any>;
  public bodyTemplate: TemplateRef<any>;
  public editTemplate: TemplateRef<any>;


  ngAfterContentInit(): void {
    if (!this.title) {
      this.title = this.field;
    }
    this.templates.forEach((item) => {
      switch (item.getType()) {
        case 'header':
          this.headerTemplate = item.template;
          break;

        case 'body':
          this.bodyTemplate = item.template;
          break;

        case 'edit':
          this.editTemplate = item.template;
          break;

        default:
          this.bodyTemplate = item.template;
          break;
      }
    });
  }
}
