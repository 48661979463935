import {
	Component, OnInit, ChangeDetectionStrategy,
	Input, OnChanges, ChangeDetectorRef, Inject, forwardRef
}                                       from '@angular/core';
import { Base }                         from '../../models/wizard-parent-context';
import { WizardService, WizardQuery }   from '../../state';
import { Observable }                   from 'rxjs';
import { ComponentChanges }             from '@cs/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, map }                  from 'rxjs/operators';
import { Step, StepState }              from '../../models/step';


@UntilDestroy()
@Component({
			   selector:        'cs-wizard-step',
			   templateUrl:     './wizard-step.component.html',
			   styleUrls:       ['./wizard-step.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class WizardStepComponent implements OnInit,
											OnChanges {
	@Input() step: Step;
	@Input() isLast: boolean;
	/**
	 * flag indicating in what state the step is
	 */
	@Input() state: StepState = 'isDisabled';
	/**
	 * Error message context
	 */
	errorMessage: string;

	isSelected$: Observable<boolean> = this.wizardStateQuery.select(store => store.currentStep)
										   .pipe(
											   filter(value => value != null),
											   map(value => value.name === this.step.name)
										   );

	constructor(private readonly changeRef: ChangeDetectorRef,
				@Inject(forwardRef(() => WizardQuery)) private readonly wizardStateQuery: WizardQuery,
				@Inject(forwardRef(() => WizardService)) private readonly wizardStateService: WizardService,
				@Inject(forwardRef(() => Base)) public readonly host: Base) {
	}

	ngOnInit(): void {
		this.wizardStateQuery.select(store => store.errorMessages)
			.pipe(
				untilDestroyed(this),
				map(value => {
					if (value == null)
						return null;

					return value.find(item => item.name === this.step.name);
				}),
				filter(foundResult => foundResult != null)
			)
			.subscribe(value => {
				this.step.state   = 'isError';
				this.errorMessage = value.errorMessage;
				this.detectChanges();
			});

		this.wizardStateService.wizardStepStatusChanged.pipe(
				untilDestroyed(this),
				filter(item => item.name === this.step.name)
			)
			.subscribe(value => {
				let state: StepState = null;
				switch (value.state) {
					case 'invalid':
						state = 'isError';
						break;
					case 'valid':
						state = 'isChecked';
						break;
				}
				this.step.state   = state;
				this.errorMessage = value.errorMessage;

				this.detectChanges();
			});
	}

	ngOnChanges(changes: ComponentChanges<WizardStepComponent>): void {
		// whenChanging(changes.step, true).execute(value => {
		// 	const cValue = value.currentValue;
		// 	this.steps = value.currentValue.icon;
		// });
	}

	detectChanges() {
		this.changeRef.detectChanges();
	}
}
