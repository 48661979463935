import { ModuleWithProviders, NgModule }     from '@angular/core';
import { CommonModule }                      from '@angular/common';
import { ReactiveFormsModule }               from '@angular/forms';
import { ComponentTranslationLoaderService } from '@cs/common/culture';
import { TranslateModule }                   from '@ngx-translate/core';
import { WizardComponent }                   from './wizard.component';
import { WizardStepperComponent }            from './components/wizard-stepper/wizard-stepper.component';
import { WizardStepComponent }               from './components/wizard-step/wizard-step.component';
import { CsSharedModule }                    from '@cs/components/shared';
import { WizardStepIconComponent }           from './components/wizard-step-icon/wizard-step-icon.component';

const COMPONENTS = [WizardComponent,
					WizardStepperComponent,
					WizardStepComponent,
					WizardStepIconComponent];

@NgModule({
			  declarations: [
				  ...COMPONENTS
			  ],
			  providers:    [],
			  exports:      [
				  ...COMPONENTS
			  ],
			  imports:      [
				  CommonModule,
				  TranslateModule,
				  CsSharedModule,
				  ReactiveFormsModule
			  ]
		  })
export class WizardModule {
	static forRoot(): ModuleWithProviders<WizardModule> {
		return {ngModule: WizardModule};
	}

	constructor(loader: ComponentTranslationLoaderService) {
		loader.registerComponentModule('wizard');
	}


}
