/**
 * Created by alex on 24-7-2017.
 */

import { Pipe, PipeTransform } from '@angular/core';
import { isNullOrUndefined } from '@cs/core';
import { LookupAgent } from '../utils/lookup-agent';


@Pipe({
  name: 'lookup'
})

export class LookupPipe implements PipeTransform {
  transform(value: any, lookupKey: string): any {
    if (isNullOrUndefined(value) || isNullOrUndefined(lookupKey))
      return value;

    return LookupAgent.resolve(value, lookupKey);

  }
}
