import { OnInit, ViewContainerRef } from '@angular/core';
import {
  Component, Input
}                                   from '@angular/core';

@Component({
  selector: 'cs-columnBodyTemplateLoader',
  template: ``
})
export class ColumnBodyTemplateLoader implements OnInit {

  @Input() column: any;

  @Input() rowData: any;

  @Input() rowIndex: number;

  constructor(public viewContainer: ViewContainerRef) {
  }

  ngOnInit() {
    const view = this.viewContainer.createEmbeddedView(this.column.bodyTemplate, {
      '\$implicit': this.column.field ? this.rowData[this.column.field] : this.rowData,
      'rowData':    this.rowData,
      'rowIndex':   this.rowIndex
    });
  }
}
