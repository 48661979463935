import { Component, OnInit }     from '@angular/core';
import { ErrorConfigService }    from './error-config.service';
import { CsApplicationSettings } from '@cs/common';

@Component({
			   selector:    'csw-error',
			   templateUrl: './error.component.html',
			   styleUrls:   ['./error.component.scss']
		   })
export class ErrorComponent implements OnInit {

	constructor(public readonly config: ErrorConfigService,
				public readonly appSettings: CsApplicationSettings) {
	}

	ngOnInit() {
	}

	goBack() {
		window.history.back();
	}
}
