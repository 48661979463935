import { DataDescribed, Lookup, LoggerUtil, KeyValuePair } from '@cs/core';
import { isNullOrUndefined }                               from '@cs/core';
import { LookupAgent }                                     from '@cs/components/shared';


/**
 * Static class for Utility function pertaining DataDescribed classes.
 */
export class DataDescribedUtil {

	/**
	 * Returns prefilled lookup lists for fields with lookupId having the format 'AllMembers:Key'.
	 * For example 'AllMembers:IdBusinessUnit', will return the members of IdBusinessUnit property in AllMembers.
	 */
	public static GetAllMemberLookupLists<T>(data: DataDescribed<T>): Lookup[] {
		const prefix = 'AllMembers:';

		const lookups: Lookup[] = [];

		// Short-list of ids starting with prefix
		const lookupIds: string[] = data.dataAnnotation.fields.reduce((a, b) => {
			if (!isNullOrUndefined(b.lookup) &&
				b.lookup.toLowerCase().startsWith(prefix.toLowerCase())) {
				return a.concat(b.lookup);
			} else {
				return a;
			}
		}, []);

		// extract the allmembers key from id and convert to members to Lookup object
		lookupIds.forEach((lookupId) => {
			const allmembersKey = lookupId.substring(prefix.length).toLowerCase();

			if (window['members'].hasOwnProperty(allmembersKey)) {
				// quick-fix: disregard the data, just copy the entire list
				lookups.push(new Lookup({
					id:     lookupId,
					filter: null,
					values: DataDescribedUtil.convertToLookupFormat(allmembersKey)
				}));
			} else {
				LoggerUtil.warn(`No such key ${allmembersKey} in all members.`);
			}
		});

		return lookups;
	}

	/**
	 * Convert allmembers structure to KeyValuePair.
	 */
	private static convertToLookupFormat(allmembersKey: string): KeyValuePair<any, string>[] {

		const values: KeyValuePair<any, string>[] = [];

		const members = window['members'][allmembersKey];
		for (const key of Object.keys(members)) {
			// use LookupAgent to apply default arguments
			const label = LookupAgent.resolve(key, allmembersKey);

			// member key is probably numeric when coming from allmembers
			if (!isNaN(parseFloat(key))) {
				try {
					// see if we can access it that way
					const numerickey = parseFloat(key);
					const a          = members[numerickey];
					values.push({key: numerickey, value: label});
				} catch (e) {
					// default use the string value of the key
					values.push({key: key, value: label});
				}
			} else {
				// default use the string value of the key
				values.push({key: key, value: label});
			}

		}
		return values;
	}

}
