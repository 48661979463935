/* tslint:disable */
import { Injectable } from '@angular/core';

import { InputMaskHelpers } from './input-mask-helpers';
import { CsInputMaskOptions } from './input-mask-options';

/**
 * Input mask Default options service, exports/config default options.
 * 


 */
@Injectable()
// tslint:disable
export class CsInputMaskDefaults {
    constructor() {
        let self = this;

        return new Proxy(this, {
            get(target, prop) {
                if (!(prop in target)) {
                    return self.defaults[prop];
                }
                return target[prop];
            }
        });
    }
    /**
     * Mask default options
     * 


     */
    defaults: CsInputMaskOptions = {
        placeholder: '_',
        optionalmarker: {
            start: '[',
            end: ']'
        },
        quantifiermarker: {
            start: '{',
            end: '}'
        },
        groupmarker: {
            start: '(',
            end: ')'
        },
        alternatormarker: '|',
        escapeChar: '\\',
        mask: null, // needs tobe null instead of undefined as the extend method does not consider props with the undefined value
        regex: null, //regular expression as a mask        
        oncomplete: () => { }, // executes when the mask is complete
        onincomplete: () => { }, // executes when the mask is incomplete and focus is lost
        oncleared: () => { }, // executes when the mask is cleared
        repeat: 0, // repetitions of the mask: * ~ forever, otherwise specify an integer
        greedy: true, // true: allocated buffer for the mask and repetitions - false: allocate only if needed
        autoUnmask: false, // automatically unmask when retrieving the value with InputMaskHelpers.fn.val or value if the browser supports __lookupGetter__ or getOwnPropertyDescriptor
        removeMaskOnSubmit: false, // remove the mask before submitting the form.
        clearMaskOnLostFocus: true,
        insertMode: true, // insert the input or overwrite the input
        clearIncomplete: false, // clear the incomplete input on blur
        alias: null,
        onKeyDown: () => { }, // callback to implement autocomplete on certain keys for example. args => event, buffer, caretPos, opts
        onBeforeMask: null, // executes before masking the initial value to allow preprocessing of the initial value.	args => initialValue, opts => return processedValue
        onBeforePaste: (pastedValue, opts) => {
            return InputMaskHelpers.isFunction(opts.onBeforeMask) ? opts.onBeforeMask(pastedValue, opts) : pastedValue;
        }, // executes before masking the pasted value to allow preprocessing of the pasted value.	args => pastedValue, opts => return processedValue
        onBeforeWrite: null, // executes before writing to the masked element. args => event, opts
        onUnMask: null, // executes after unmasking to allow postprocessing of the unmaskedvalue.	args => maskedValue, unmaskedValue, opts
        showMaskOnFocus: false, // show the mask-placeholder when the input has focus
        showMaskOnHover: true, // show the mask-placeholder when hovering the empty input
        onKeyValidation: null, // executes on every key-press with the result of isValid. Params: key, result, opts
        skipOptionalPartCharacter: ' ', // a character which can be used to skip an optional part of a mask
        numericInput: false, // numericInput input direction style (input shifts to the left while holding the caret position)
        rightAlign: false, // align to the right
        undoOnEscape: true, // pressing escape reverts the value to the value before focus
        // numeric basic properties
        radixPointDefinitionSymbol: undefined, // set the radixPoint definitionSymbol ~ used for awareness of the radixpoint
        // numeric basic properties
        keepStatic: null, // try to keep the mask static while typing. Decisions to alter the mask will be posponed if possible - null see auto selection for multi masks
        positionCaretOnTab: true, // when enabled the caret position is set after the latest valid position on TAB
        tabThrough: false, // allows for tabbing through the different parts of the masked field
        supportsInputType: ['text', 'tel', 'password'], // list with the supported input types
        // specify keyCodes which should not be considered in the keypress event, otherwise the preventDefault will stop their default behavior especially in FF
        ignorables: [8, 9, 13, 19, 27, 33, 34, 35, 36, 37, 38, 39, 40, 45, 46, 93, 112, 113, 114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 0, 229],
        isComplete: null, // override for isComplete - args => buffer, opts - return true || false
        canClearPosition: () => { }, // hook to alter the clear behavior in the stripValidPositions args => maskset, position, lastValidPosition, opts => return true|false
        preValidation: null, // hook to preValidate the input.  Usefull for validating regardless the definition.	args => buffer, pos, char, isSelection, opts => return true/false/command object        
        postValidation: null, // hook to postValidate the result from isValid.	Usefull for validating the entry as a whole.	args => buffer, currentResult, opts => return true/false
        staticDefinitionSymbol: undefined, // specify a definitionSymbol for static content, used to make matches for alternators
        jitMasking: false, // just in time masking ~ only mask while typing, can n (number), true or false
        nullable: true, // return nothing instead of the buffertemplate when the user hasn't entered anything.
        inputEventOnly: false, // dev option - testing inputfallback behavior
        noValuePatching: false, // disable value property patching
        positionCaretOnClick: 'none', // none, lvp (based on the last valid position (default), radixFocus (position caret to radixpoint on initial click)
        casing: null, // mask-level casing. Options: null, "upper", "lower" or "title"
        inputmode: 'verbatim', // specify the inputmode  - already in place for when browsers will support it
        colorMask: false, // enable css styleable mask
        androidHack: false // see README_android.md
    };
}
