import { AfterViewInit, Directive }                 from '@angular/core';
import { isNullOrUndefined }                        from '@cs/core/utils';
import { FormatRegisteredItem, LoggerUtil, Lookup } from '@cs/core';
import { ControlWidget }                            from './control-widget.directive';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class ReadOnlyControlWidget<T> extends ControlWidget<T> implements AfterViewInit {

	lookup: Lookup;
	isMultiSelect = false;

	get controlValue(): string | Array<any> {
		return this._controlValue === '' ? '-' : this._controlValue;
	}

	set controlValue(value: string | Array<any>) {
		if (isNullOrUndefined(this.propertyAnnotation.format)) {
			this._controlValue = this.formatService.formatByDataType(value, this.propertyAnnotation.type);
		} else if (!isNullOrUndefined(this.propertyAnnotation.format)) {
			this._controlValue = this.formatService.format(value, new FormatRegisteredItem(null, this.propertyAnnotation.format));
		}
	}

	ngAfterViewInit() {
		this.initStatusChanges();
		this.initLookupControl();
		this.initLookup();
	}

	/**
	 * The value the readonly control widget uses to display the data
	 */
	private _controlValue: string | Array<any>;

	/**
	 * Instantiate the lookup by requesting it from the @Link(FormGeneratorAgentService)
	 */
	private initLookup() {
		// Check if the lookup exits
		if (isNullOrUndefined(this.propertyAnnotation.lookup) || this.propertyAnnotation.lookup === '') {
			this.controlValue = this.control.value;
			return;
		}

		this.lookup = this.formAgent.getLookup(this.propertyAnnotation.lookup);
		// Set the first value to the Angular FormControl when value is NULL
		if (!isNullOrUndefined(this.control.value)
			&& !isNullOrUndefined(this.lookup) && !isNullOrUndefined(this.lookup.values) && this.lookup.values.length > 0) {
			if (this.isMultiSelect) {
				this.controlValue = this.control.value.map(val => {
					const found = this.lookup.values.find(v => v.key === val);
					if (isNullOrUndefined(found))
						LoggerUtil.error(`${this.control.value} is not found in ${this.propertyAnnotation.lookup}`);
					else
						return found.value;
				});
			} else {
				const found = this.lookup.values.find(v => v.key === this.control.value);
				if (isNullOrUndefined(found))
					LoggerUtil.error(`${this.control.value} is not found in ${this.propertyAnnotation.lookup}`);
				else
					this.controlValue = found.value;
			}
		}

	}

	private initLookupControl() {
		this.isMultiSelect = this.control.value instanceof Array;
	}


}

