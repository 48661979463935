import { Component }                   from '@angular/core';
import { DomSanitizer }                from '@angular/platform-browser';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { ArrayUtils }                  from '@cs/core';

@Component({
			   selector:    'cs-dashboard-statistics',
			   templateUrl: './dashboard-statistics.component.html'
		   })
export class DashboardStatisticsComponent extends DashboardPanelComponentBase<any> {
	name: string;

	constructor(private sanitizer: DomSanitizer) {
		super();
	}

	calculateWidth(size) {
		return this.sanitizer.bypassSecurityTrustStyle('width: ' + (size * 100) + '%');
	}

	update(data: any): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

	dataChanged(value: any) {

	}
}
