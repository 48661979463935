import { getPropertyOf, LoggerUtil } from '@cs/core';
import { ErrorMessage }              from '../../../app/error-messages';
import { isNullOrUndefined }         from '@cs/core';
import { Observable, of }            from 'rxjs';
import { CsNavItem }                 from '@cs/core';
import { findPmNavItem }             from '../pm-navbar.utils';


export enum PmNavbarItemType {
	APP_LINK    = 'APP_LINK',
	APP_ACTION  = 'APP_ACTION',
	REMOTE_LINK = 'REMOTE_LINK',
}

export class PmNavbarItem extends CsNavItem {
	disabled = false;
	/**
	 * Order for placement
	 */
	sortIndex: number | null;

	get getPath(): string {
		const route = this.routeToParent.concat();

		if (!this.ignoreNameForRouting)
			route.push(this.name);

		return route.join('/');
	}

	get activeLabel(): string {
		const found = findPmNavItem(this.values, item => item.active);
		return found.label;
	}

	get activeIcon(): string {
		const found = findPmNavItem(this.values, item => item.active);
		return found.icon;
	}

	/**
	 * The full label for the item
	 */
	fullLabel: string | null;
	/**
	 * The short label for the item
	 */
	label: string;
	/**
	 * The id of the item
	 */
	name: string;
	/**
	 * Used for generating nested dropdowns
	 */
	values?: Array<PmNavbarItem>;
	/**
	 * Preserve the query-params when switching to page
	 */
	preserveQueryParams: boolean;
	/**
	 * Preserve the url-params when switching to page and there is no placeholder variable in the path
	 */
	preserveModuleUrlParams: boolean;
	/**
	 * Set the behavior of the link item.
	 */
	type: PmNavbarItemType;
	/**
	 * Action that will execute when item is clicked, change type to APP_LINK to recognise the action
	 */
	action?: (item: PmNavbarItem) => void;
	/**
	 * Do not use the name for routing
	 */
	ignoreNameForRouting: boolean;

	/**
	 * Detect if navbaritem has active children
	 */
	get hasActiveChildren() {
		return !isNullOrUndefined(findPmNavItem(this.values, value => value.active));
	}

	routeToParent: string[];

	readonly params: { [key: string]: string; } | null;

	readonly appParams: { [key: string]: string; } | null;

	constructor(init: Partial<PmNavbarItem>) {
		super(init);
		try {
			this.fullLabel               = getPropertyOf(init, 'fullLabel', null);
			this.label                   = getPropertyOf(init, 'label', '');
			this.name                    = getPropertyOf(init, 'name');
			this.sortIndex               = getPropertyOf(init, 'sortIndex', null);
			this.routeToParent           = getPropertyOf(init, 'routeToParent', []);
			this.params                  = getPropertyOf(init, 'params', null);
			this.appParams               = getPropertyOf(init, 'appParams', {});
			this.resolveFromModule       = getPropertyOf(init, 'resolveFromModule', '');
			this.preserveQueryParams     = getPropertyOf(init, 'preserveQueryParams', false);
			this.disabled                = getPropertyOf(init, 'disabled', false);
			this.ignoreNameForRouting    = getPropertyOf(init, 'ignoreNameForRouting', false);
			this.show                    = getPropertyOf(init, 'show', of(true));
			this.icon                    = getPropertyOf(init, 'icon', null);
			this.path                    = getPropertyOf(init, 'path', null);
			this.type                    = getPropertyOf(init, 'type', PmNavbarItemType.APP_LINK);
			this.action                  = getPropertyOf(init, 'action', null);
			this.preserveModuleUrlParams = getPropertyOf(init, 'preserveModuleUrlParams', false);
			this.values                  = getPropertyOf(init, 'values', []).map(value => new PmNavbarItem(value));
		} catch (e) {
			LoggerUtil.error(ErrorMessage.MAPPING_BUSINESS_MODEL_TO_APPLICATION_MODEL_FAILED(PmNavbarItem, e));
		}
	}
}
