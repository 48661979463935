import { FormatRegisteredItem }          from '@cs/core';
import { isNullOrUndefined, LoggerUtil } from '@cs/core';


export class FormatRegistry {

	private formattingPairs: { [key: string]: FormatRegisteredItem } = {};
	options                                                          = {
		nullAsZero: true,
		/**
		 * When the backend doesn't honor the {0:P} specifications by providing the value as decimal
		 */
		dividePercentageBy100: false
	};

	constructor() {
	}


	hasFormat(type: string) {
		return this.formattingPairs[type.toLowerCase()];
	}

	/**
	 * Set the format string for corresponding data-type
	 * @param type Provide the data-type like DateTime
	 * @param formatString Prove the string in .NET stringformat notation
	 * @param parseMethod Function to parse from string
	 */
	register(type: string, formatString: string, parseMethod?: (value) => any) {
		this.formattingPairs[type.toLowerCase()] = new FormatRegisteredItem(type, formatString, parseMethod);
	}

	getFormatEntry(type: string): FormatRegisteredItem {
		if (isNullOrUndefined(type)) {
			LoggerUtil.warn('No type provided');
		} else if (this.hasFormat(type.toLowerCase())) {
			const found = this.formattingPairs[type.toLowerCase()];
			// todo: when more providers are added add here
			return new FormatRegisteredItem(found.type, found.formatString, found.parse);

		}
		return null;
	}
}
