import { BaseNode }                from './base-node';
import { SectionNode }             from './section-node';
import { MdmPropertiesPageButton } from '../properties-page-button.model';
import { Subject }                 from 'rxjs';

export class PageNode extends BaseNode {
  public nodeType = 'PageNode';

  meta: {
    pageImageUrl: string;
    buttons: Array<MdmPropertiesPageButton>
  };

  public constructor(data) {
    super(data, 'sections');
    this.meta = data.meta;
  }

  public processChildren(children) {
    for (const child of children) {
      this.children.push(this.createChild(SectionNode, child));
    }
  }
}
