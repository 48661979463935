import{
  trigger,
  state,
  style,
  animate,
  transition,
  query,
  group,
  AnimationTriggerMetadata,
} from '@angular/animations';

/**
 * Animations used by the mat-menu component.
 * Animation duration and timing values are based on:
 */
export const nicePopInAndOutAnimations: {
  readonly transformContainer: AnimationTriggerMetadata;
  readonly fadeInItems: AnimationTriggerMetadata;
  readonly translateContainer: AnimationTriggerMetadata;
} = {
  /**
   * This animation controls the menu panel's entry and exit from the page.
   *
   * When the menu panel is added to the DOM, it scales in and fades in its border.
   *
   * When the menu panel is removed from the DOM, it simply fades out after a brief
   * delay to display the ripple.
   */
  transformContainer: trigger('transformContainer', [
    state('void', style({
      opacity: 0,
      transform: 'scale(0.8)'
    })),
    transition('void => enter', group([
       animate('100ms linear', style({
        opacity: 1
      })),
      animate('120ms cubic-bezier(0, 0, 0.2, 1)', style({transform: 'scale(1)'})),
    ])),
    transition('* => void', animate('100ms 25ms linear', style({opacity: 0})))
  ]),
  /**
   * This animation fades in the background color and content of the menu panel
   * after its containing element is scaled in.
   */
  fadeInItems: trigger('fadeInItems', [
    // now. Remove next time we do breaking changes.
    state('showing', style({opacity: 1})),
    transition('void => *', [
      style({opacity: 0}),
      animate('400ms 100ms cubic-bezier(0.55, 0, 0.55, 0.2)')
    ])
  ]),
  /**
   * This animation slides the target down and turns up the opacity
   */
  translateContainer: trigger('translateContainer', [
    state('void', style({
      opacity: 0,
      transform: 'translate(-50%, -100%)'
    })),
    state('enter', style({
      opacity: 1,
      transform: 'translate(-50%, 0)'
    })),
    transition('void => enter', group([
      animate('100ms linear', style({
        opacity: 1
      })),
      animate('120ms cubic-bezier(0, 0, 0.2, 1)', style({transform: 'translate(-50%, 0)'})),
    ])),
    transition('* => void', animate('100ms 25ms linear', style({opacity: 0})))
  ]),
};
