import { enableProdMode }         from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { Logger }                 from '@cs/components/util';
import { LoggerUtil }             from '@cs/core/utils';

import { AppModule }      from './app/app.module';
import { pmAppSettings }  from './environments/environment';
import { Environment }    from '@cs/common';


if (pmAppSettings.environment === Environment.PRODUCTION) {
	enableProdMode();
	LoggerUtil.environment = pmAppSettings.environment;
	Logger.enabled = false;
}

platformBrowserDynamic()
	.bootstrapModule(AppModule)
	.catch(err => console.error(err));


