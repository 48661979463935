import { Injectable } from '@angular/core';
import { AuthMethod } from '../models/auth-method.model';
import { LoginStore } from './login.store';

@Injectable({providedIn: 'root'})
export class LoginService {

	constructor(private loginStore: LoginStore) {
	}

	setTitleMessage(titleMessage: string) {
		this.loginStore.update({titleMessage});
	}

	setUnderTitleMessage(underTitleMessage: string) {
		this.loginStore.update({underTitleMessage});
	}

	setRememberMe(rememberMe: boolean) {
		this.loginStore.update({rememberMe});
	}

	setErrorState(inErrorState: boolean) {
		this.loginStore.update({inErrorState});
	}

	setInProgress(inProgress: boolean) {
		this.loginStore.update({inProgress});
	}

	setUserName(username: string) {
		this.loginStore.update({username});
	}

	SetAuthMethod(method: string) {
		this.loginStore.update({method});
	}

	setLoginOptions(loginOptions: Array<AuthMethod>) {
		this.loginStore.update({loginOptions});
	}
}
