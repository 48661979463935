<ng-container *ngIf="clientRepository">
	<ng-container>

		<div class="repository-table-container">

			<ng-container *ngIf="showTitle">
				<div class="repository-table-container__title-div">

					<div class="repository-table-container__left">
						<h1>
							{{clientRepository.key}}
						</h1>
					</div>

					<div class="repository-table-container__right" *ngIf="hasBranchExpander && clientRepository.value.length > 1">

						<button type="button" class="drop_button" (click)="collapse.toggle()">
							<i class="mdi mdi-chevron-down mdi-24px hover-pointer"
							   [ngClass]="{'drop_button__rotate-icon-forward': !isCollapsed, 'drop_button__rotate-icon-back': isCollapsed}"
							   [matTooltip]="!isCollapsed?('COLLAPSE'|translate|titlecase):('EXPAND'|translate|titlecase)"
							   [attr.aria-expanded]="!isCollapsed" aria-controls="collapseExample"></i>
						</button>

					</div>

				</div>
			</ng-container>

			<table class="repository_table repository_table__table-fixed">

				<ng-container>

					<colgroup>
						<col span="1" [ngStyle]="{ 'width.px': titleColumn }">
						<col span="2" [ngStyle]="{ 'width.px': dataColumns }">
						<col span="1" [ngStyle]="{ 'width.px': diffColumn }">
						<col span="1" [ngStyle]="{ 'width.px': dataColumns }">
					</colgroup>

					<thead class="repository_table__table-head">
					<tr>
						<td class="repository_table__head-cell" *ngFor="let column of columnsToDisplay">
							<h2 class="repository_table__heavy-font">
								{{column}}
							</h2>
						</td>
					</tr>
					</thead>


					<!--	Head for when the branchExpander is checked when declaring this component	-->
					<ng-container *ngIf="hasBranchExpander">
						<tbody>
						<ng-container *ngTemplateOutlet="recursiveRepositories; context: { $implicit: getMainBranch(clientRepository.value)}"></ng-container>
						</tbody>
					</ng-container>

					<!--	All the other branches that are not the main branch head	-->
					<ng-container>
						<tbody>
						<tr>
							<td colspan="5">
								<div #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
									<table class="repository_table repository_table__table-fixed">

										<colgroup>
											<col span="1" [ngStyle]="{ 'width.px': titleColumn }">
											<col span="2" [ngStyle]="{ 'width.px': dataColumns }">
											<col span="1" [ngStyle]="{ 'width.px': diffColumn }">
											<col span="1" [ngStyle]="{ 'width.px': dataColumns }">
										</colgroup>

										<tbody>
										<ng-container *ngFor="let branchedRepository of clientRepository.value">
											<ng-container *ngIf="branchedRepository.branch != mainBranch">
												<ng-container *ngTemplateOutlet="recursiveRepositories; context: { $implicit: branchedRepository}"></ng-container>
											</ng-container>
										</ng-container>
										</tbody>

									</table>
								</div>
							</td>
						</tr>
						</tbody>
					</ng-container>

				</ng-container>
			</table>

		</div>

	</ng-container>
</ng-container>


<!--	Branched Repositories Display	-->
<ng-template #recursiveRepositories let-branchedRepository>

	<ng-container *ngIf="branchedRepository != undefined && branchedRepository.subModules.length > 0">

		<tr class="repository_table__branch-row">

			<td class="repository_table__cell">
				<h2 class="repository_table__heavy-font">
					{{branchedRepository.branch}}
				</h2>
			</td>


			<ng-container *ngIf="!branchedRepository.failed">
				<td class="repository_table__cell"></td>

				<td class="repository_table__cell">
					<h3 class="repository_table__light-font" [matTooltip]="getFullDate(branchedRepository)">
						{{getRoundedDate(branchedRepository)}}
					</h3>
				</td>

				<td class="repository_table__cell">
					<h3 [class]="getWeightColour(branchedRepository) + ' repository_table__heavy-font'">
						{{branchedRepository.commitsBehind}}
					</h3>
				</td>
			</ng-container>

			<ng-container *ngIf="branchedRepository.failed">
				<td class="repository_table__bordered" colspan="3">
					<h3 *ngFor="let error of branchedRepository.errorList" class="repository_table__light-font badge badge--error" style="color: darkred">
						{{error}}
					</h3>
				</td>
			</ng-container>

			<td class="repository_table__cell">
				<h3 class="repository_table__light-font">
					{{branchedRepository.commit}}
				</h3>
			</td>
		</tr>

		<ng-container *ngTemplateOutlet="recursiveChildRepositories; context: { $implicit: branchedRepository.subModules, indentionLevel : 1}"></ng-container>

		<!--	Child Repositories	-->
		<ng-template #recursiveChildRepositories let-childRepositories let-indentionLevel="indentionLevel">

			<ng-container *ngFor="let subRepository of childRepositories">

				<tr>

					<td class="repository_table__cell"
						(click)="navigate(branchedRepository, subRepository)">

						<h2 class="repository_table__clickable-row" [ngStyle]="{'margin-left.px': indentionLevel * 7.5}">
							- {{subRepository.label}}
						</h2>

					</td>


					<ng-container *ngIf="!subRepository.failed">
						<td class="repository_table__cell">
							<h3 class="repository_table__light-font">
								{{subRepository.branch}}
							</h3>
						</td>

						<td class="repository_table__cell">
							<h3 class="repository_table__light-font" [matTooltip]="getFullDate(subRepository)">
								{{getRoundedDate(subRepository)}}
							</h3>
						</td>

						<td class="repository_table__cell">
							<ng-container *ngIf="showChildCommitDifference">

								<!--	currently can only update direct submodules		-->
								<h3 *ngIf="allowUpdate && indentionLevel == 1"
									[matTooltip]="'Update Repository'"
									[class]="getWeightColour(subRepository) + ' repository_table__heavy-font repository_table__clickable-row'"
									(click)="openUpdateDialog(branchedRepository, subRepository)">
									{{subRepository.commitsBehind}}
								</h3>

								<h3 *ngIf="allowUpdate && indentionLevel != 1"
									[class]="getWeightColour(subRepository) + ' repository_table__heavy-font'">
									{{subRepository.commitsBehind}}
								</h3>
								<!--	currently can only update direct submodules		-->



								<h3 *ngIf="!allowUpdate"
									[class]="getWeightColour(subRepository) + ' repository_table__heavy-font'">
									{{subRepository.commitsBehind}}
								</h3>

							</ng-container>
						</td>

					</ng-container>

					<ng-container *ngIf="subRepository.failed">
						<td class="repository_table__bordered" colspan="3">
							<h3 *ngFor="let error of subRepository.errorList" class="repository_table__light-font badge badge--error" style="color: darkred">
								{{error}}
							</h3>
						</td>
					</ng-container>

					<td class="repository_table__cell">
						<h3 class="repository_table__light-font">
							{{subRepository.commit}}
						</h3>
					</td>
				</tr>

				<!--	recursive row for submodules of repositories	-->
				<tr>
					<ng-container *ngTemplateOutlet="collapsedChildRepositories; context: { $implicit: subRepository.subModules, indentionLevel : indentionLevel}"></ng-container>
				</tr>

			</ng-container>

		</ng-template>

		<ng-template #collapsedChildRepositories let-repositories let-indentionLevel="indentionLevel">

			<td colspan="5">

				<div [(ngbCollapse)]="collapseRecursive">

					<table class="repository_table repository_table__table-fixed repository_table__table-rec">

						<colgroup>
							<col span="1" [ngStyle]="{ 'width.px': titleColumn }">
							<col span="2" [ngStyle]="{ 'width.px': dataColumns }">
							<col span="1" [ngStyle]="{ 'width.px': diffColumn }">
							<col span="1" [ngStyle]="{ 'width.px': dataColumns }">
						</colgroup>

						<tbody>
							<ng-container *ngTemplateOutlet="recursiveChildRepositories; context: { $implicit: repositories, indentionLevel : indentionLevel + 1}"></ng-container>
						</tbody>

					</table>
				</div>
			</td>
		</ng-template>

	</ng-container>

</ng-template>



