import { NgModule }                           from '@angular/core';
import { CommonModule }                       from '@angular/common';
import { MatTooltipModule }                   from '@angular/material/tooltip';
import { CsDataEntryStateCellComponent }      from './components/data-entry-state-cell';
import {
	CsDataEntryStateHeaderComponent
}                                             from './components/data-entry-state-header/data-entry-state-header.component';
import { NgbCollapseModule }                  from '@cs/components/ngb-collapse';
import { CsDataEntryStateComponent }          from './data-entry-state.component';
import { CsDataEntryStateRowComponent }       from './components/data-entry-state-row/data-entry-state-row.component';
import { CsDataEntryStateIndicatorComponent } from './components/data-entry-state-indicator/data-entry-state-indicator.component';
import { CsSharedModule }                     from '@cs/components/shared';

const COMPONENTS = [
	CsDataEntryStateComponent,
	CsDataEntryStateRowComponent,
	CsDataEntryStateIndicatorComponent,
	CsDataEntryStateHeaderComponent,
	CsDataEntryStateCellComponent
];

@NgModule({
						declarations: [
							...COMPONENTS
						],
						imports:      [
							CommonModule,
							CsSharedModule,
							NgbCollapseModule,
							MatTooltipModule
						],
						exports:      [...COMPONENTS]
					})
export class CsDataEntryStateModule {}
