<div class="dropdown dropdown-filter"
	 [ngClass]="{'show': showMenu, 'small-advanced-dropdown': isSmall}"
	 (window:click)="hideMenu($event)">
	<!-- when searchbar exists => keydown listner is on the button -->
	<button class="advanced-dropdown-toggle"
			[class.disabled]="disabled"
			(click)="toggleMenuState($event)"
			(keydown)="onMultipleFilterKeydown($event)"
			type="button"
			id="dropdownMenuButton"
			data-toggle="dropdown"
			aria-haspopup="true"
			aria-expanded="false"
			[attr.disabled]="disabled ? '' : null">
		<span>{{dataSource?.label}}</span>
		<div class="flex flex-row align-items-center full-width"
			 [class.is-multiple]="selectedItem.length > 1">
			<p class="value">{{selectedItem[selectedItem.length - 1]?.label}} </p>
			<span class="value-multiple-number"
				  *ngIf="selectedItem.length > 1">{{selectedItem.length}}+</span>
		</div>

		<i *ngIf="!disabled" class="icon icon-dropdown-arrow"></i>
	</button>
	<!-- when searchbar doesn't exist => keydown listner is on the div wrapper of the list -->
	<div class="dropdown-menu" aria-labelledby="dropdownMenuButton"
		 [ngClass]="{'dropdown-search': dataSource?.search?.hasSearchBar,
       'inverted':showAbove}"
		 (keydown)="onMultipleFilterKeydown($event)" #dropdownlist>
		<div class="dropdown-menu__list {{additionalMenuListCssClasses}}" [ngStyle]="{
                                               'width.px': maxWidth
                                               }">
			<form *ngIf="dataSource?.search?.hasSearchBar && dataSource?.values[0]?.data.length > 1">
				<div class="form-group">
					<input type="search" (input)="filterItems($event.target.value)" class="form-control" #searchInput
						   placeholder="Search">
				</div>

				<div class="flex full-width mt-2 multiple-selector" *ngIf="selectMultiple">
					<input type="checkbox" [checked]="hasMultiSelectionEnabled" attr.for="{{dataSource.identifier}}" (click)="toggleMultiSelection($event)"/>

					<label class="f-dimmed-text-color" (click)="toggleMultiSelection($event)" attr.name="{{dataSource.identifier}}">
						select multiple
					</label>

					<small class="link flex-pull-right" *ngIf="selectedItem.length>1"
						   (click)="deselectAll()">deselect {{selectedItem.length}}</small>
				</div>


			</form>
			<!-- Note that itemSize attribute is a string--> 
			<cdk-virtual-scroll-viewport 
			#dropdownListScrollViewport [itemSize]="itemHeight" 
										 minBufferPx="200" maxBufferPx="300"
										 [ngStyle]="{
                                               'maxHeight.px': maxHeight
                                               }"
										 class="full-height">

				<cs-advanced-dropdown-item *cdkVirtualFor="let group of groups; let i = index; trackBy:trackByGroup"
										   (itemClicked)="itemClicked($event)"
										   [itemTemplate]="itemTemplate"
										   [headerTemplate]="headerTemplate"
										   [addDivider]="i < dataSource?.values.length - 1"
										   [group]="group">
				</cs-advanced-dropdown-item>
			</cdk-virtual-scroll-viewport>
		</div>
	</div>
</div>
