import { NgModule }                 from '@angular/core';
import { CsFormFieldTreeComponent } from './components/form-field-tree/form-field-tree.component';

import { CsFormGeneratorComponent }         from './form-generator.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule }                     from '@angular/common';
import { CsDatepickerModule }               from '@cs/components/datepicker';
import { CsAdvancedDropdownModule }         from '@cs/components/advanced-dropdown';
import { CsComboboxModule }                 from '@cs/components/combobox';
import { CsFormFieldListComponent }         from './components/form-field-list/form-field-list.component';
import { CsFileSelect }                     from './components/file-select/file-select.component';
import { CsHyperlinkModule }                from '@cs/components/shared';

@NgModule({
	imports:      [
		FormsModule,
		ReactiveFormsModule,
		CommonModule,
		CsDatepickerModule,
		CsAdvancedDropdownModule,
		CsComboboxModule,
		CsHyperlinkModule
	],
	declarations: [
		CsFormGeneratorComponent,
		CsFormFieldListComponent,
		CsFormFieldTreeComponent,
		CsFileSelect
	],
	exports:      [
		CsFormGeneratorComponent,
		CsFormFieldListComponent,
		CsFormFieldTreeComponent
	]
})
export class CsFormGeneratorModule {
}
