import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';

import { CsSliderComponent } from './slider.component';

import { CsSharedModule }   from '@cs/components/shared';
import { NgbPopoverModule } from '@cs/components/ngb-popover';


@NgModule({
	declarations:    [CsSliderComponent],
	exports:         [CsSliderComponent],
	imports:         [CommonModule, NgbPopoverModule, CsSharedModule],
	entryComponents: [CsSliderComponent]
})
export class CsSliderModule {
	static forRoot(): ModuleWithProviders<CsSliderModule> {
		return {ngModule: CsSliderModule};
	}
}

