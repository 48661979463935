<cs-placeholder [rows]="isLoadingDebounced$ | async" [width]="'1000px'" [centered]="true" [isLoading]="false"></cs-placeholder>
<div class="change-log-page-container">
  <br/>
  <br/>
  <ngb-accordion #acc="ngbAccordion" [closeOthers]="false" activeIds="0">
    <ng-container *ngFor="let changeset of changelog?.changeSets; let i=index">
      <ngb-panel id="{{i}}">
        <ng-template ngbPanelTitle>
          <div class="changelog-header">
            <ng-container *ngIf="changeset.name"><label style="width:auto">{{changeset.name}}</label></ng-container>
            <span class="badge badge-pill badge-primary ml-2">{{changeset.totalChangeCount}}</span>
            <div class="push mr-2">
              <small>{{ changeset.date | date}}</small>
            </div>
            <i class="mdi mdi-chevron-down"></i>
          </div>
        </ng-template>
        <ng-template ngbPanelContent>
          <ng-container *ngFor="let categorizedChanges of changeset.categorizedChanges">
            <div class="item">
              <h3>{{categorizedChanges.category}}</h3>
              <ul>
                <ng-container *ngFor="let change of categorizedChanges.changes">
                  <li>
                    <!-- Showing and linking commit hashes might only be usefull for developers -->
                    <small *ngIf="change?.repositoryUrls.hashes?.length > 0" style="white-space:nowrap">
                      [
                      <ng-container *ngFor="let hash of change.repositoryUrls.hashes; let last=last">
                        <a target="repository"
                           href="{{change.repositoryUrls.url.replace('{0}',hash)}}"
                           class="text-monospace">
                          {{hash?.substring(0, 8)}}
                        </a>
                        <ng-container *ngIf="!last">,</ng-container>
                      </ng-container>
                      ]&nbsp;
                    </small>
                    <span [innerHtml]="change.message" class="cl_message"></span>
                  </li>
                </ng-container>
              </ul>
            </div>
          </ng-container>
        </ng-template>
      </ngb-panel>
    </ng-container>
    <ng-container *ngIf="changelog?.changeSets.length === 0 ">
      <ngb-panel [disabled]="true" >
        <ng-template ngbPanelTitle >
          <div class="changelog-header">
            <label style="width:auto">{{'NO_CHANGELOG_ENTRIES' | translate}}</label>
            <span class="badge badge-pill badge-primary ml-2">0</span>
            <div class="push mr-2">
              <small></small>
            </div>
          </div>


        </ng-template>
      </ngb-panel>
    </ng-container>
  </ngb-accordion>

</div>
