<div class="cs-panel-header environment-view__content">

	<div class="cs-content">

		<div class="cs-panel-header environment-view__title-div">

			<div class="environment-view__title-left">
				<h1 class="f-h1">Cohelion Submodule Visualization:</h1>
			</div>

		</div>


		<div class="git-graph__main-content">


			<ng-container *ngIf="parentRepositoryMapStore.size > 0">

				<div class="git-graph__main-content__holder">

					<div class="cs-panel-header environment-view__title-div">

						<div class="environment-view__title-left">
							<h2 class="f-h2">
								Submodule: {{this.selectedSubmodule ? this.selectedSubmodule.label : ''}}</h2>
						</div>

					</div>

					<div class="git-graph__display">

						<ng-container>
							<div class="git-graph__commit-info">

								<ng-container *ngIf="selectedCommit != null">

									<ng-container>
										<div class="git-graph__commit-info__details">
											<div class="git-graph__commit-info__line">
												<h2 class="git-graph__commit-info__line__left">Commit:</h2>
												<h2 class="git-graph__commit-info__line__right">{{selectedCommit.hashOrBranch}}</h2>
											</div>
											<div class="git-graph__commit-info__line">
												<h2 class="git-graph__commit-info__line__left">Date:</h2>
												<h2 class="git-graph__commit-info__line__right">{{formatDate(selectedCommit.authorDate, 'longDate', this._locale)}}</h2>
											</div>
											<div class="git-graph__commit-info__line">
												<h2 class="git-graph__commit-info__line__left">Author:</h2>
												<h2 class="git-graph__commit-info__line__right">{{selectedCommit.author}}</h2>
											</div>
											<div class="git-graph__commit-info__line">
												<h2 class="git-graph__commit-info__line__left">Message:</h2>
												<h2 class="git-graph__commit-info__line__right">{{selectedCommit.message}}</h2>
											</div>
										</div>
									</ng-container>

									<ng-container *ngIf="selectedSubmodule && parentRepositoryMapStore.size > 0">
										<div class="git-graph__commit-info__table">
											<RepositoryColumnedTableComponent
												[repository]="GetCurrentMap()"
												[collapseRecursive]="true"
												[showTitle]="false"
												[initialCollapse]="false"
												(navigationEvent)="navigate($event)"
												[titleColumnSizeOverride]="150"
												[dataColumnSizeOverride]="100">
											</RepositoryColumnedTableComponent>
										</div>
									</ng-container>


								</ng-container>
							</div>
						</ng-container>


						<ng-container>
							<div class="git-graph__graph-holder">
								<div #graphOutput class="git-graph__graph-holder__content">
									No data.
								</div>

							</div>
						</ng-container>

					</div>

				</div>

			</ng-container>


		</div>

	</div>

</div>
