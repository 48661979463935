import { FormsModule }                               from '@angular/forms';
import { NgModule, ModuleWithProviders, forwardRef } from '@angular/core';
import { CommonModule }                              from '@angular/common';

import { CsDatepicker } from './datepicker.component';


import { CsDatepickerConfig }                                                 from './datepicker-config';
import { CsDatepickerPlain }                                                  from './datepicker-plain.component';
import { MatIconModule }                                                      from '@angular/material/icon';
import {
	CsCultureDateParserFormatter,
	CsCultureDateParserFormatterDefault,
	CsCultureProvider,
	CsCultureProviderDefault
}                                                                             from '@cs/common';
import { NgbDateParserFormatter, NgbDatepickerI18n, NgbDatepickerModule }     from './datepicker-core';
import { CsDataPickerCultureModule, CsDateParserFormatter, CsDatepickerI18n } from './culture';

@NgModule({
	declarations:    [CsDatepicker, CsDatepickerPlain],
	exports:         [CsDataPickerCultureModule, CsDatepicker, CsDatepickerPlain],
	imports:         [CommonModule, CsDataPickerCultureModule, NgbDatepickerModule, MatIconModule, FormsModule],
	entryComponents: [CsDatepicker, CsDatepickerPlain]
})
export class CsDatepickerModule {
	static forRoot(): ModuleWithProviders<CsDatepickerModule> {
		return {
			ngModule:  CsDatepickerModule,
			providers: [
				CsDatepickerConfig,
				{provide: CsCultureProvider, useClass: CsCultureProviderDefault},
				{
					provide:  CsCultureDateParserFormatter,
					useClass: forwardRef(() => CsCultureDateParserFormatterDefault)
				},
				{
					provide:  NgbDateParserFormatter,
					useClass: forwardRef(() => CsDateParserFormatter)
				},
				{
					provide:  NgbDatepickerI18n,
					useClass: forwardRef(() => CsCultureProvider)
				},
				{
					provide:  NgbDatepickerI18n,
					useClass: forwardRef(() => CsDatepickerI18n)
				}
			]
		};
	}
}
