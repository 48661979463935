import {
	HttpErrorResponse,
	HttpEvent,
	HttpHandler,
	HttpInterceptor, HttpRequest
}                              from '@angular/common/http';
import { Observable }          from 'rxjs';
import { Injectable }          from '@angular/core';
import { CsHttpRequest }       from './cs-http-client.service';
import { isNullOrUndefined }   from '@cs/core/utils';
import { Router }              from '@angular/router';
import { CsHttpErrorResponse } from '@cs/core/generate';


@Injectable({providedIn: 'root'})
export class HttpErrorInterceptor implements HttpInterceptor {
	constructor(private router: Router) {
	}

	intercept(request: CsHttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const executed = request;

		// Wrap arround the request and handle the errors. if no error, do nothing and pass the request on
		return new Observable(observer => {
			// And subscribe to the original observable to ensure the HttpRequest is made
			const subscription = next.handle(request)
															 .subscribe(
																 event => {
																	 observer.next(event);
																 },
																 (e: HttpErrorResponse) => {
																	 if (e.message === 'Http failure response for (unknown url): 0 Unknown Error')
																		 console.warn('THIS ERROR COULD BE CAUSED BY AN AD-BLOCKER PLEASE TURN IT OFF!!!!');
																	 // used for legacy support
																	 if (!isNullOrUndefined(executed.csHttpRequestOptions)
																		 && !isNullOrUndefined(executed.csHttpRequestOptions.errorResponseHandler)) {
																		 if (executed.csHttpRequestOptions.errorResponseHandler(e)) {
																			 const error     = new CsHttpErrorResponse(e as any);
																			 error.isHandled = true;
																			 observer.error(error);
																			 return;
																		 }
																	 }
																	 observer.error(e);
																 },
																 () => {
																	 observer.complete();
																 });
			// return teardown logic in case of cancelled requests
			return () => {
				subscription.unsubscribe();
			};
		});
	}
}

export class HttpInterceptorHandler implements HttpHandler {

	constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {
	}

	handle(request: HttpRequest<any>): Observable<HttpEvent<any>> {
		return this.interceptor.intercept(request, this.next);
	}

}
