<div class="cs-statistics cs-performance-stats" @statisticsState>
  <div class="cs-statistics-data" style="display: flex;flex-direction: column;">
    <div class="icon-container">
      <i class="mdi mdi-chart-areaspline no-stats-icon"></i>
      <div>
        <i>No statistics</i>
      </div>
    </div>
  </div>
</div>

