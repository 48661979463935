import { Injectable }                     from '@angular/core';
import { Query }                          from '@datorama/akita';
import { BottomBarStore, BottomBarState } from './bottom-bar.store';

@Injectable({providedIn: 'root'})
export class BottomBarQuery extends Query<BottomBarState> {

  constructor(protected store: BottomBarStore) {
    super(store);
  }

}
