import { Injectable }            from '@angular/core';
import { HttpClient }            from '@angular/common/http';
import { AppState, AppStore }    from './app.store';

import { AuthenticationService } from '../../authentication/state/authentication.service';

@Injectable({providedIn: 'root'})
export class AppService {

  constructor(private appStore: AppStore,
              private http: HttpClient,
              private readonly authenticationService: AuthenticationService) {
  }

  update(state: Partial<AppState>) {
    this.appStore.update(state);
  }

  /**
   *  Trigger a update on the filter nav bar
   */
  refreshNavigationBar() {
    this.appStore.update({currentAppParamsScope: {...this.appStore._value().currentAppParamsScope}});
  }

  updateUserProfile() {
    this.authenticationService.getUserProfile();
  }
}
