import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { Environment }     from '@cs/common';
import { defaulAppRoutes } from '../src/app/app-routing.module';
import { pmAppSettings }   from '../src/environments/environment';

@NgModule({
						imports: [RouterModule.forRoot(defaulAppRoutes, {enableTracing: pmAppSettings.environment !== Environment.PRODUCTION})],
						exports: [RouterModule]
					})
export class CustomerRoutingModule {
}
