import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatTooltip }                                                    from '@angular/material/tooltip';


import { isNullOrUndefined }                                                   from '@cs/core';
import { FormLayoutWidgetCollection, FormSettings, LabelPosition, WidgetInfo } from '@cs/core';
import { FormGeneratorAgentService }                                           from './form-generator.agent';

@Component({
						 selector: 'pm-form-widget-collection',
						 template: `
                           <div class="form-collection form-collection-{{collectionOrientation}}"
                                [class.required]="isRequired"
                                [class.read-only]="checkIfReadOnly()"
                           >
                               <div *ngIf="collection?.showLabel"
                                    class="form-collection-label-container form-collection-label--position-{{collectionLabelPosition}}"
                                    [ngStyle]="{width:collectionLabelWidth, minWidth: collectionLabelWidth, margin: collectionLabelMargin}"
                               >
                                   <label class="form-collection-label">
                                       {{collection.label}}
                                   </label>
                                   <ng-container *ngIf="!isReadOnly && !isRequired">
                                       <label class="link cs-pointer"
                                              #tooltip="matTooltip" [matTooltip]="'FORM_COLLECTION_CLEAR_BUTTON_INFO' | translate "
                                              matTooltipPosition="above"
                                              (mouseover)="checkTooltip(tooltip)"
                                              (click)="resetValue($event)">{{'CLEAR' | translate}}</label>
                                   </ng-container>
                               </div>
                               <div class="form-collection-widgets-container">
                                   <ng-container *ngFor="let info of widgets">
                                       <ng-container *ngIf="info.include">
                                           <pm-form-element [widgetInfo]="info">
                                           </pm-form-element>
                                       </ng-container>
                                   </ng-container>
                               </div>
                           </div>`,
						 styleUrls: ['./form-generator-nxt.component.scss']
					 })

export class FormWidgetCollectionComponent<T> implements OnChanges {

	@Input() collection: FormLayoutWidgetCollection<T>;

	widgets: WidgetInfo<T>[];

	/**
	 * flag to set the class required on the form-collection class

	 */
	isRequired = false;
	/**
	 * flag to set the class read-only on the form-collection class

	 */
	isReadOnly = false;

	/**
	 * When the form shows the form label left change to flex: row else to flex:column
	 */
	collectionOrientation: string;

	/**
	 *  Label width override
	 */
	collectionLabelWidth: string;
	/**
	 * Label position override
	 */
	collectionLabelPosition: 'left' | 'center' | 'right';
	/**
	 * Label position margin override
	 */
	collectionLabelMargin: string;

	constructor(private formAgent: FormGeneratorAgentService,
							private changeRef: ChangeDetectorRef) {
	}

	checkTooltip(tooltip: MatTooltip) {
		setTimeout(() => this.changeRef.detectChanges(), 5);
	}


	ngOnChanges(changes: SimpleChanges): void {

		if (changes.hasOwnProperty('collection')) {
			this.formSettings            = this.formAgent.getFormSettings();
			const coll                   = changes.collection.currentValue as FormLayoutWidgetCollection<T>;
			this.widgets                 = coll.widgets;
			this.isRequired              = isNullOrUndefined(this.widgets.find(w => w.propertyAnnotation.optional));
			this.isReadOnly              = this.checkIfReadOnly();
			this.collectionLabelPosition = this.formSettings.collectionLabelPosition;
			this.collectionOrientation   = this.formSettings.labelPosition === LabelPosition.Left
																		 ? 'horizontal'
																		 : 'vertical';
			this.collectionLabelWidth    = this.formSettings.collectionLabelWidth;
			this.collectionLabelMargin   = this.formSettings.collectionLabelMargin;
		}

	}

	/**
	 * Expensive way to check if a field is read-only, change this when performance issues arise
	 */
	checkIfReadOnly() {
		return this.formSettings.readOnly || !isNullOrUndefined(this.widgets.find(w => w.layout.readOnly || w.propertyAnnotation.readOnly));
	}

	resetValue($event: MouseEvent) {
		this.widgets.forEach(value => {
			const control = this.formAgent.findWidget(value.propertyAnnotation.id as string);
			control.clearData();
		});


		this.changeRef.detectChanges();
	}

	/**
	 * Form settings
	 */
	private formSettings: FormSettings;
}
