import { animate, animateChild, group, query, sequence, state, style, transition, trigger } from '@angular/animations';

export function simpleFadeInOut(triggerName: string) {
  return trigger(triggerName, [
    state('true',
      style({
        opacity: 1
      })
    ),
    state('false',
      style({
        opacity: 0
      })
    ),
    // When the element goes from 'selected' state to whatever...
    transition('true <=> false', [
      animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)')
    ])
  ]);
}

export function collapseFadeInOut(triggerName: string) {
  return trigger(triggerName, [
    state('true',
      style({
        opacity: 1,
        width:   '*'
      })
    ),
    state('false',
      style({
        opacity: 0,
        width:   0
      })
    ),
    // When the element goes from 'selected' state to whatever...
    transition('true <=> false', [
      animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)')
    ])
  ]);
}

export function routerFadeTransition(triggerName: string, timings: string = '0.275s ease-out') {
  return trigger(triggerName, [

    transition('* => *', [
      query(':enter, :leave', style({position: 'fixed'}),
        {optional: true}),
      query(':enter', style({opacity: 0}),
        {optional: true}),
      sequence([
        query(':leave', animateChild(),
          {optional: true}),
        group([
          query(':leave', [
              style({opacity: 1}),
              animate(timings,
                style({opacity: 0}))
            ],
            {optional: true}),
          query(':enter', [
              style({opacity: 0}),
              animate(timings,
                style({opacity: 1}))
            ],
            {optional: true})
        ]),
        query(':enter', animateChild(),
          {optional: true})
      ])
    ])
  ]);
}
