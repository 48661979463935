import { getPropertyOf, hasPropertyOf } from '@cs/core';

export enum DashboardTasksItemStatus {
	DONE    = 'done',
	OPEN    = 'open',
	ERROR   = 'error',
	OVERDUE = 'overdue'
}

export class DashboardTasksItem {
	get icon(): string {
		switch (this.status) {
			case DashboardTasksItemStatus.DONE:
				return 'mdi mdi-check-circle-outline text-success';
			case DashboardTasksItemStatus.OPEN:
				return 'mdi mdi-timeline-text-outline';
			case DashboardTasksItemStatus.OVERDUE:
				return 'mdi mdi-timeline-alert-outline text-danger';
			case DashboardTasksItemStatus.ERROR:
				return 'mdi mdi-close-octagon-outline text-danger';
			default:
				return 'mdi mdi-timeline-clock-outline';
		}
	}

	get iconDescription(): string {
		switch (this.status) {
			case DashboardTasksItemStatus.DONE:
				return 'TASK_DONE_DESCRIPTION';
			case DashboardTasksItemStatus.OPEN:
				return 'TASK_OPEN_DESCRIPTION';
			case DashboardTasksItemStatus.OVERDUE:
				return 'TASK_OVERDUE_DESCRIPTION';
			case DashboardTasksItemStatus.ERROR:
				return 'TASK_ERROR_DESCRIPTION';
			default:
				return 'TASK_EVENT_DESCRIPTION';
		}
	}

	status: DashboardTasksItemStatus;
	label: string;
	link?: string | { [key: string]: any };
	payload: DashboardTasksPayload;

	constructor(init: Partial<DashboardTasksItem>) {
		this.label   = getPropertyOf(init, 'label');
		this.link    = getPropertyOf(init, 'link', null);
		this.status  = getPropertyOf(init, 'status', null);
		this.payload = getPropertyOf(init, 'payload', null);
	}
}

export class DashboardTasksGroup {
	label: string;
	items: DashboardTasksItem[];
	name: string;

	constructor(init: DashboardTasksGroup) {
		this.label = getPropertyOf(init, 'label');
		this.name  = getPropertyOf(init, 'name');
		this.items = hasPropertyOf(init, 'items') ? init.items.map(value => new DashboardTasksItem(value)) : [];
	}
}

export class DashboardTasks {
	groups: DashboardTasksGroup[];


	constructor(init: DashboardTasks) {
		this.groups = hasPropertyOf(init, 'groups') ? init.groups.map(value => new DashboardTasksGroup(value)) : [];
	}
}

export class DashboardTasksPayload {
	navBarParams: { [key: string]: any };
	moduleBar: { [key: string]: any };

	constructor(init: DashboardTasksPayload) {
		this.navBarParams = getPropertyOf(init, 'navBarParams', null);
		this.moduleBar    = getPropertyOf(init, 'moduleBar', null);
	}
}
