import { Observable } from 'rxjs';
import { LoggerUtil } from './logger.util';

export class FileUtil {
	static convertToBlob(file: File): Observable<any> {
		return Observable.create(execute);

		function execute(observer) {
			try {
				observer.next(file.slice(0, file.size, file.type));
				observer.complete();
			} catch (ex) {
				observer.error(ex);
			}
		}
	}

	static createFileUrl(file: Blob): Observable<string | ArrayBuffer> {
		return new Observable(execute);

		function execute(observer) {
			try {
				const reader: FileReader = new FileReader();
				reader.addEventListener('load', () => {
					observer.next(reader.result);
					observer.complete();
				});

				reader.readAsDataURL(file);

			} catch (ex) {
				observer.error(ex);
			}
		}

	}

	private static convertToBlob2(file: File): Observable<any> {
		return Observable.create(execute);

		function execute(observer) {
			const fileReader = new FileReader();
			fileReader.readAsArrayBuffer(file);
			fileReader.onloadend = result => {
				LoggerUtil.debug(`File: ${file.name} is read`);
				observer.next(new Blob((<any>result.srcElement).result, {type: file.type}));
				observer.complete();
			};
			fileReader.onerror   = error => observer.error(error);
		}
	}
}
