import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
	selector:    'cs-filter',
	templateUrl: './filter.component.html'
})
export class CsFilterComponent implements OnInit {
	@Input() data: Array<any>;
	@Input() columns: Array<string>;
	@Output() output = new EventEmitter();
	textSearch       = '';

	constructor() {

	}

	ngOnInit(): void {

	}

	/**
	 * When searched for texrt inside the full data object.
	 *
	 */
	onTextSearch(): void {
		// Return every row if there is no search.
		if (this.textSearch === '') {
			this.output.emit(this.data);
		} else {
			const value              = this.textSearch.toLowerCase();
			const result: Array<any> = this.data.filter((row) => {
				for (const column in row) {
					if (!this.columns || (this.columns.indexOf(column.toLowerCase()) > -1)) {
						if (row[column] !== null && row[column].toString().toLowerCase().indexOf(value) > -1) {
							return true;
						}
					}
				}
			});

			this.output.emit(result);
		}
	}
}
