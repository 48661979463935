<div class="overflow-page-container approval-page-container">
  <div class="full-width approval-page-button-bar-container">
    <pmc-dynamic-button-bar [title]="data?.dataAnnotation?.displayName"
                            [hideEventsButton]="false"
                            [hideCollapseButton]="false"
                            [dynamicButtons]="dynamicButtons"
                            [currentPageComponent]="this">
    </pmc-dynamic-button-bar>
  </div>
  <div [@listAnimation]="approvalItems?.length">
    <div class="approval-item-container"
         *ngFor="let item of approvalItems">
      <div class="approval-item--header">
        <h5 class="approval-item-header--title">{{item.label}}</h5>
        <hr class="spacer">
        <button *ngIf="item.options?.useDataEntryLink"
                class="btn btn--icon-and-text no-wrap" (click)="goToDataEntry(item)">
          <i class="mdi mdi-arrow-right"></i>{{'GO_TO' | translate | capitalize}}
        </button>
      </div>
      <div>
        <table class="table cs-table">
          <colgroup>
            <col width="200px">
          </colgroup>
          <thead class="table-headers">

          <tr>
            <th class="table-cell table-headers__cell" *ngFor="let col of data.dataAnnotation.fields">
              {{col.label}}
            </th>
          </tr>

          </thead>
          <ng-container *ngFor="let groups of item.groups; let isFirst = first">
            <tbody class="table-body">
            <tr class="table-body__row">
              <td *ngFor="let col of data.dataAnnotation.fields; let isFirstCol = first"
                  class="{{groups?.cellOptions?.values[col.id]?.statusClass}}"
                  [class.injected]="isFirstCol">
                <div class="data-grid-cell-background"></div>
                <div class="cell-content">
                  {{groups.facts[col.id]}}
                </div>
              </td>
            </tr>
            <tr class="table-body__row total-row bold-row"
                *ngIf="groups.hasDeviation">
              <td *ngFor="let col of data.dataAnnotation.fields; let isFirstCol = first"
                  class="{{groups?.deviations?.values[col.id]?.statusClass}} cell-container"
                  [class.injected]="isFirstCol"
                  [class.has-deviation]="groups.hasDeviation">
                <div class="data-grid-cell-background"></div>
                <div class="cell-content" [class.has-deviation]="isFirstCol">
                  <div class="sign" *ngIf="isFirstCol">∟</div>
                  {{groups?.deviations?.values[col.id]?.value}}
                </div>
              </td>
            </tr>

            </tbody>
          </ng-container>
        </table>
      </div>
      <div *ngIf="item.options?.useComments"
           class="approval-item-comments-container">

        <div class="flex flex-column" [@openClose]="item.isOpen ? 'open' : 'closed'">
          <div class="comments-container">
            <div class="flex flex-row align-items-center comments-title">
              <i class="mdi mdi-chat mr-3"></i>
              <label class="mb-0 comments-label">{{'COMMENTS' | translate}}</label>
              <label class="badge badge--dark ml-3 mb-0">{{item.comments.length}}</label>
            </div>

            <ol class="chat" style="background: #ffffff;
    box-shadow: 0px 0px 0px 1px #c7d4da, 0px 2px 2px 0px #eff3f4;
    border-radius: 2px;
    position: relative;">
              <li *ngFor="let comment of item.comments"
                  [ngClass]="{self: comment.isSelf, other: !comment.isSelf}"
              >
                <div class="avatar" matTooltip="{{comment.userName}}">
                  <cs-avatar fullName="{{comment.userName}}"></cs-avatar>
                </div>
                <div class="msg">
                  <p>{{comment.message}}</p>
                  <time>{{comment.timestamp | format:'{0:t}'}}</time>
                  <time class="mr-2">{{comment.timestamp | format:'{0:M}'}}</time>
                </div>
                <i *ngIf="comment.isNew"
                   matTooltip="Remove unsaved comment"
                   class="remove-chat mdi mdi-delete"
                   (click)="deleteComment(item, comment)"></i>
              </li>
              <li *ngIf="item.comments.length === 0" class="no-comments">
                <small class="f-body-s">{{'NO_COMMENTS' | translate}}</small>
              </li>
            </ol>

          </div>
          <div class="comments-response-container">
            <div class="form-group">
              <input class="form-control" id="commentarea"
                     [(ngModel)]="item.newComment.message"
                     placeholder="Add your clarification here">
            </div>
            <div matTooltip="{{item.newComment.message.length === 0?'Provide a comment':''}}">
              <button class="btn btn--icon-and-text add-comment-btn btn--large"
                      [disabled]="item.newComment.message.length ===0"
                      (click)="addComment(item)">
                <i class="mdi mdi-comment-plus"></i> {{'ADD' | translate | capitalize}}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="flex col-12">
            <div class="comments-title-closed" [@showCommentLabels]="item.isOpen ? 'open' : 'closed'">
              <i class="mdi mdi-chat mr-3"></i>
              <label class="mb-0 comments-label">{{'COMMENTS' | translate}}</label>
              <label class="badge badge--dark ml-3 mb-0">{{item.comments.length}}</label>
            </div>
            <button class="btn btn--icon-and-text flex-pull-right mb-0 mr-0"
                    [class.mt-0]="!item.isOpen"
                    [class.mt-3]="item.isOpen"
                    (click)="toggleCommentaryPanel(item)">
            <span *ngIf="item.isOpen"><i
              class="mdi mdi-arrow-collapse"></i> {{'COLLAPSE' | translate | capitalize}} </span>
              <span *ngIf="!item.isOpen && item?.comments?.length === 0"> <i
                class="mdi mdi-plus"></i> {{'ADD_COMMENT' | translate | capitalize}}</span>
              <span *ngIf="!item.isOpen && item?.comments?.length === 1">
              <i class="mdi mdi-comment-search"></i> {{'VIEW_COMMENT' | translate | capitalize}}</span>
              <span *ngIf="!item.isOpen && item?.comments?.length > 1">
              <i class="mdi mdi-comment-search"></i> {{'VIEW_COMMENTS' | translate | capitalize}} </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
