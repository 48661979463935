import { defer, MonoTypeOperatorFunction, Observable, Subject } from 'rxjs';
import { finalize, map }                                        from 'rxjs/operators';

export const prepare = <T>(callback: () => void): MonoTypeOperatorFunction<T> => {
  return (source: Observable<T>): Observable<T> => defer(() => {
    callback();
    return source as Observable<T>;
  });
};


export function isWaitingForResponse<T>(indicator: Subject<boolean>): (source: Observable<T>) => Observable<T> {
  return function waitingFunction(source: Observable<T>): Observable<T> {
    return source.pipe(
      prepare(() => indicator.next(true)),
      finalize(() => indicator.next(false))
    ) as Observable<T>;
  };
}


export const toClass = <T>(ClassType: { new(): T }) => (source: Observable<T>) => source.pipe(
  map(val => Object.assign(new ClassType(), val))
);

/**
 * Helping class for spying on a request or observable, is used for loaders
 */
export class WaitingForResponse {
  /**
   * Flag indicating that the request is ongoing
   */
  isWaiting$: Subject<boolean> = new Subject<boolean>();

  /**
   * Factory for the class, creates a new instance of the waiting class and starts the loader
   */
  static new(callback?: (isLoading) => void) {
    return new WaitingForResponse(callback).start();
  }

  /**
   * Next hook for tap operator
   */
  next     = () => this.finished();
  /**
   * Error hook for tap operator
   */
  error    = () => this.finished();
  /**
   * Complete hook for tap operator
   */
  complete = () => {
    this.finished();
    this.destroy();
  }

  constructor(callback?: (isLoading) => void) {
    if (callback)
      this.isWaiting$.subscribe(value => {
        callback(value);
        if (value === false)
          this.destroy();
      });
  }

  /**
   * Start the wait and return the instance for usage in tap operator
   */
  start() {
    this.isWaiting$.next(true);
    return this;
  }

  /**
   * When the request is done tell the subscribers that the wait is over
   */
  finished() {
    this.isWaiting$.next(false);
  }


  private destroy() {
    this.isWaiting$.complete();
  }
}
