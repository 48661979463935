import { NgModule }               from '@angular/core';
import { CommonModule }           from '@angular/common';
import { CsPlaceholderComponent } from './placeholder.component';


@NgModule({
	declarations: [CsPlaceholderComponent],
	imports:      [
		CommonModule
	],
	exports:      [CsPlaceholderComponent]
})
export class CsPlaceholderModule {
}
