import { LoggerUtil }     from '@cs/core';
import { Alert }          from './alert';
import { DashboardPanel } from './dashboard-panel';

/**
 * Data that renders the Dashboard grid
 */
export class DashboardGridData {
	/**
	 * Display label for UI
	 */
	label?: string;
	/**
	 * Unique name for programming purposes
	 */
	name: string;
	/**
	 * Description of the the Dashboard, could be useful for display purposes
	 */
	description?: string;
	/**
	 * Shows alerts in the UI
	 */
	alerts?: Alert[]                   = [];
	/**
	 * List of dashboard panels
	 */
	panels: DashboardPanel<any, any>[] = [];
	/**
	 * Set the layout for the grid/ implemented by the bootstrap grid with a multi dimensional array
	 *  [8,4  ], | xxxxxxxxx|xxxx (gridslot: 1 and 2)
	 *  [4,4,4], | xxxx|xxxx|xxxx (gridslot: 3, 4 and 5)
	 *  [6,6  ]  | xxxxxxx|xxxxxx (gridslot: 6 and 7)
	 */
	grid: [number[]]                   = [[]];

	latestRefresh: Date;

	constructor(init: DashboardGridData) {

		this.label       = init.label;
		this.name        = init.name;
		this.description = init.description;
		this.alerts      = init.alerts ? init.alerts.map(a => new Alert(a)) : [];

		this.latestRefresh = init.latestRefresh ? new Date(init.latestRefresh) : null;

		if (!init.panels)
			LoggerUtil.warn('No panels specified for the dashboard');
		this.panels = init.panels ? init.panels.map(a => new DashboardPanel<any, any>(a)) : [];

		if (!init.grid)
			LoggerUtil.warn('No grid specified for the dashboard');

		this.grid = init.grid ? init.grid : [[]];
	}
}
