import { formatDate, KeyValue }                           from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Inject,
	LOCALE_ID,
	OnInit,
	ViewChild
}                                                         from '@angular/core';
import { FilterCompareBarQuery, FilterCompareBarService } from '@cs/components/filter-and-compare-bar';
import { isNull, isNullOrUndefined }                      from '@cs/core';
import { LoggerUtil }                                     from '@cs/core/utils';
import { BranchTypes }                                    from './models/BranchTypes';
import { GitTableNavigationFilter }                       from './models/GitTableNavigationFilter';
import { BranchUserApi }                                  from '@gitgraph/core';
import { CommitOptions, createGitgraph }                  from '@gitgraph/js'; // import javascript library
import { createForeignObject, createG, createText }       from '@gitgraph/js/lib/svg-elements';
import { UntilDestroy, untilDestroyed }                   from '@ngneat/until-destroy';
import { filter as filter$ }                              from 'rxjs/operators';
import { GitGraphConfigService }                          from './git-graph-config.service';
import { Commit }                                         from './models/Commit';
import { Repository }                                     from './models/Repository';
import { mapRepositoriesByName }                          from './util/git-table-tools';
import { RepositoryDto, RepositoryType }                  from './models/RepositoryDto';
import { Router }                                         from '@angular/router';

@UntilDestroy()
@Component({
			   selector:    'pmc-git-graph',
			   templateUrl: './git-graph.component.html',
			   styleUrls:   ['./git-graph.component.scss']
		   })
export class GitGraphComponent implements OnInit, AfterViewInit {

	@ViewChild('graphOutput', {static: false}) graphOutput: ElementRef<HTMLElement>;
	@ViewChild('graphTestOutput', {static: false}) graphTestOutput: ElementRef<HTMLElement>;

	public selectedCommit: Commit;
	public selectedRepository: Repository;


	// Filter parameters
	public selectedSubmoduleIdentifier = '';
	public selectedSubmodule: RepositoryDto;
	public selectedTextElement: SVGTextElement;

	public parentRepositoryMapStore: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

	public readonly formatDate = formatDate;

	constructor(@Inject(GitGraphConfigService) private service: GitGraphConfigService,
				@Inject(FilterCompareBarQuery) private filterCompareBarQuery: FilterCompareBarQuery,
				@Inject(ChangeDetectorRef) public changeDetector: ChangeDetectorRef,
				@Inject(FilterCompareBarService) public filterCompareBarService: FilterCompareBarService,
				@Inject(Router) private router: Router,
				@Inject(LOCALE_ID) private _locale: string) {
	}

	async ngOnInit() {
		await this.UpdateGraph();
	}

	async ngAfterViewInit() {

		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(untilDestroyed(this), filter$(value => !isNullOrUndefined(value)))
			.subscribe(async (resultParams) => {

				this.selectedCommit = undefined;

				console.log('Result Parameters');
				console.log(resultParams.selection);

				this.selectedSubmoduleIdentifier = resultParams.selection['submoduleIdentifier'];

				if (this.selectedSubmoduleIdentifier == undefined) {
					const urlSearchParams = new URLSearchParams(window.location.search);

					this.selectedSubmoduleIdentifier = urlSearchParams.get('submoduleIdentifier');
				}

				await this.UpdateGraph();
			});

	}

	getRepositoryCount(submoduleName: string, commitHash: string): number {
		const selectedModules = this.parentRepositoryMapStore.get(submoduleName);

		console.log('Selected Modules: ' + submoduleName);
		console.log(selectedModules);

		console.log('Filter using');
		console.log(commitHash);

		const expectedRepository = selectedModules.filter(repository => repository.hashOrBranch == commitHash)[0];

		return expectedRepository == undefined
			   ? 0
			   : expectedRepository.subModules.length;
	}

	showCommitInformation(commit: Commit, textElement: SVGTextElement) {
		if (this.selectedTextElement) this.selectedTextElement.setAttribute('class', 'git-graph__repository-text git-graph__repository-text__clickable');

		textElement.setAttribute('class', 'git-graph__repository-text git-graph__repository-text__selected');
		this.selectedTextElement = textElement;

		this.selectedCommit = commit;

		this.changeDetector.markForCheck();

		window.scrollTo({
							top:      0,
							behavior: 'smooth'
						});
	}

	GetCurrentMap(): KeyValue<string, RepositoryDto[]> {
		const result: KeyValue<string, RepositoryDto[]> = {
			key:   this.selectedRepository.name,
			value: this.parentRepositoryMapStore.get(this.selectedRepository.name)
					   .filter(r => r.hashOrBranch == this.selectedCommit.hashOrBranch)
		};

		return result;
	}

	// ---------------- Navigate ---------------- \\
	// TODO has to be updated to a router.navigate, because it navigates to a view that is in a different filter bar
	async navigate($event: any) {

		const childRepository: RepositoryDto  = $event.childRepository;
		const parentRepository: RepositoryDto = $event.parentRepository;

		let selection;

		if (childRepository.repositoryType == RepositoryType.LIB) {

			selection = {
				parentRepositoryLabel: `${childRepository.label}`,
				parentBranch:          `${childRepository.branch}`,

				navId: 'git-submodule-table'
			};

			const looseBranch = BranchTypes[childRepository.branch];
			const branchTypes = Object.keys(BranchTypes)
									  .filter(key => isNaN(Number(BranchTypes[key])))
									  .map(key => BranchTypes[key]);

			if (looseBranch != undefined) {
				selection.parentBranchLoose = `${childRepository.branch} | ${childRepository.branch}`;
			} else {
				const expectedBranchGroup = branchTypes.filter(br => childRepository.branch.includes(br))[0];

				if (expectedBranchGroup != undefined) {
					selection.parentBranch = `${childRepository.branch} | ${branchTypes.filter(br => childRepository.branch.includes(br))[0]}`;
				} else {
					selection.parentBranch = `${childRepository.branch}`;
				}
			}

		} else {
			selection = {
				parentRepositoryLabel: `${childRepository.label} | ${childRepository.repositoryType}`,
				parentBranch:          childRepository.branch,

				childRepositoryName: `${parentRepository.label}`,
				childBranch:         parentRepository.branch,

				navId: 'git-customer-table'
			};
		}

		console.log('Given selection');
		console.log(selection);

		await this.router.navigate(['/table'])
				  .then(() => {
					  setTimeout(() => {
						  this.filterCompareBarService.triggerNavigation(selection);
					  }, 1000);
				  });
	}

	serialize(obj: any): string {
		const params = new URLSearchParams();
		for (const key in obj) {
			if (obj.hasOwnProperty(key)) {
				params.set(key, obj[key]);
			}
		}
		return params.toString();
	}

	private async UpdateGraph() {
		const mainFilterValue = this.selectedSubmoduleIdentifier;

		console.log('Main Filter Value');

		if (isNull(mainFilterValue) || mainFilterValue.length === 0) return;

		await this.LoadSubmoduleData(mainFilterValue);

		await this.LoadGitGraphData(mainFilterValue);
	}

	private async LoadGitGraphData(mainFilterValue: string) {
		// 418 status code is handled in module loader

		return new Promise<void>((resolve, reject) => {
			this.service.getAnnotatedSubmoduleTree(mainFilterValue)
				.subscribe({
							   next:     data => {

								   console.log('Annotated Submodule Tree');
								   console.log(data);

								   const repository = JSON.parse(JSON.stringify(data.value));

								   this.selectedRepository = repository;

								   this.drawGraph(this.graphOutput.nativeElement, repository);

								   this.changeDetector.markForCheck();

								   resolve();
							   }, error: error => {
						reject(error);
					}
						   }
				);
		});
	}


	private async LoadSubmoduleData(submoduleHash: string) {

		return new Promise<void>((resolve, reject) => {

			this.service.getSubmoduleList(new GitTableNavigationFilter())
				.subscribe({
							   next: result => {
								   console.log('Submodule List');
								   console.log(result.value);

								   const expectedSubmodules = result.value.filter(repository => repository.identifier == submoduleHash);

								   if (expectedSubmodules.length > 0) {

									   const repositoryMap = mapRepositoriesByName(expectedSubmodules);

									   this.parentRepositoryMapStore = repositoryMap;

									   this.selectedSubmodule = expectedSubmodules[0];

									   this.changeDetector.markForCheck();

									   resolve();
								   }
							   }
						   });
		});

	}

	/**
	 * Draws the git graph using the gitGraphJS UserApi
	 */
	private drawGraph(element: HTMLElement, repository: Repository) {

		// clear placeholder text
		element.innerText = '';

		// Note: horizontal orientation is only supported WITHOUT commit messages
		const gitgraph = createGitgraph(element, {
			author:                   'none',
			branchLabelOnEveryCommit: false
		});

		// Quirck: Main branch "MUST" have a name for branching from parentHash to work

		// Keep track of all hashes and commits and branches
		// GitJS has limited user API...
		const hashMap = new Map<string, CommitReference>();

		// The last commit is the root node
		// A commit should always have parentHash.length > 1
		const reverseCommits: Commit[] = repository.commits.reverse();

		reverseCommits.forEach(commit => {
			this.RenderCommit(hashMap, commit, reverseCommits, gitgraph);
		});
	}

	private RenderCommit(hashMap: Map<string, CommitReference>, commit: Commit, commitStack: Commit[], gitgraph) {

		const existingParentHashes = commit.parentHash.filter(x => hashMap.has(x));

		const isMerge = existingParentHashes.length > 1;

		const parentHash = existingParentHashes[0]
						   ? existingParentHashes[0]
						   : commit.hashOrBranch;
		const parentRef  = hashMap.get(parentHash);

		const finalCommit = commitStack.some(c => c.graphBranchId == commit.graphBranchId);

		if (isMerge) {

			for (let i = 1; i < commit.parentHash.length; i++) {
				const mergeRef = hashMap.get(existingParentHashes[i]);

				this.AddMergeCommitToBranch(parentRef, mergeRef, commit, finalCommit);
			}

			hashMap.set(commit.hashOrBranch, {branch: parentRef.branch, hasCommit: false});
		} else {
			this.renderBranch(hashMap, commit, gitgraph, commit.graphBranchId, parentHash, finalCommit);
		}

	}


	private renderBranch(hashMap, commit, gitgraph, id, parentHash, isFinalCommit) {
		LoggerUtil.debug(`Branch from ${parentHash}`);

		const branch = gitgraph.branch({
										   name: id,
										   from: parentHash
									   });

		this.AddCommitToBranch(branch, commit, isFinalCommit);

		// Add it to the hashMap so we can lookup the branch later
		hashMap.set(commit.hashOrBranch, {branch: branch, hascommit: true});
	}

	/**
	 * Merges a parent commit into a merge target.
	 */
	private AddMergeCommitToBranch(parentCommit: CommitReference, mergeTarget: CommitReference, commit: Commit, finalCommit: boolean) {

		const branch = parentCommit.branch.merge(
			{
				branch:        mergeTarget.branch,
				commitOptions: {
					hash:          commit.hashOrBranch,
					subject:       commit.message,
					author:        commit.author,
					renderMessage: this.renderMessageFunction(commit, finalCommit)
				}
			});

		// Add refs (branch names) to commit.
		// Could not find a way to add branch names after rendering commits
		for (const ref of commit.refNames) branch.tag(ref);
	}

	/**
	 * Add Commit to a branch in the BranchUserApi.
	 * @param mainBranch - The main branch to which the commit will be added.
	 * @param commit - The commit to be added.
	 * @param branchName - An optional branch name of the super project.
	 */
	private AddCommitToBranch(mainBranch: BranchUserApi<SVGElement>,
							  commit: Commit,
							  isFinalCommit: boolean) {

		// Commit with special renderer
		const branch = mainBranch.commit({
											 subject:       `${commit.message}`,
											 hash:          commit.hashOrBranch,
											 author:        commit.author,
											 renderMessage: this.renderMessageFunction(commit, isFinalCommit)
										 });

		// Add refs (branch names) to commit.
		// Could not find a way to add branch names after rendering commits
		for (const ref of commit.refNames) {
			branch.tag(ref);
		}
	}

	/**
	 * Returns a function that GitGraph uses to render the commit message.
	 * @param commitData - The data of the commit.
	 * @returns The function for rendering the commit message.
	 */
	private renderMessageFunction(commitData: Commit, isFinalCommit): CommitOptions['renderMessage'] {

		isFinalCommit = false;

		const repositoryBranchesElements = this.RepositoryDetails(commitData.repositories);

		return (commit: any) => {

			let commitInfo = `${commit.hashAbbrev} | ${formatDate(commitData.commitDate, 'longDate', this._locale)} `;

			const commitTextElements = [];

			if (commitData.repositories.length > 0) {

				const absoluteLinkedRepositoryAmount = this.getRepositoryCount(this.selectedSubmodule.name, commitData.hashOrBranch);

				const finalInfo = absoluteLinkedRepositoryAmount > 1
								  ? `${commitInfo} | ${absoluteLinkedRepositoryAmount} Repositories`
								  : `${commitInfo} | ${absoluteLinkedRepositoryAmount} Repository`;

				const textElement: SVGTextElement = createText({
																   fill:    commit.style.dot.color,
																   content: finalInfo
															   });

				textElement.setAttribute('class', 'git-graph__repository-text git-graph__repository-text__clickable');
				textElement.addEventListener('click', () => this.showCommitInformation(commitData, textElement));

				commitTextElements.push(textElement);

				this.showCommitInformation(commitData, textElement);
			} else {
				if (commitData.message.includes('Hidden commits')) {
					commitInfo = `${commitData.message}`;
				} else {
					commitInfo = `${commit.hashAbbrev} | ${formatDate(commitData.commitDate, 'longDate', this._locale)} | Head`;
				}

				const textElement: SVGTextElement = createText({
																   fill:    commit.style.dot.color,
																   content: commitInfo
															   });

				textElement.setAttribute('class', 'git-graph__repository-text');

				commitTextElements.push(textElement);

				if (commitData.isHead) {

					const headElement: SVGTextElement = createText({
																	   fill:    commit.style.dot.color,
																	   content: commitData.headBranch
																   });

					headElement.setAttribute('class', 'git-graph__repository-text');

					commitTextElements.push(createForeignObject({
																	translate: {x: 0, y: 20},
																	content:   commitData.headBranch,
																	width:     400
																}));
				}


			}

			const translate = {
				x: isFinalCommit
				   ? -140
				   : 0,
				y: isFinalCommit
				   ? commit.style.dot.size * 1.5 + 22
				   : commit.style.dot.size * 1.5
			};

			return createG({
							   translate: translate,

							   children: [

								   createG({
											   children: commitTextElements
										   }),

								   createG({
											   children:  repositoryBranchesElements,
											   translate: {x: 0, y: commit.style.dot.size + 5}
										   })

							   ]

						   });
		};
	}

	/**
	 * Renders repositories as multiline text
	 */
	private RepositoryDetails(repositories: Repository[], indent: number = 0): SVGElement[] {

		const elements: SVGElement[] = [];
		const textHeight             = 17;

		for (const repository of repositories) {
			const contentStr = `+ ${repository.name}`;

			elements.push(
				createForeignObject({
										translate: {x: 0, y: textHeight * elements.length},
										content:   contentStr,
										width:     400
									}
				)
			);

		}

		return elements;
	}
}

export class CommitReference {
	branch: BranchUserApi<SVGElement>;
	hasCommit: boolean;
}

