import { DateUtils } from '@cs/common';
import { BaseInput } from './base-input';

export class DateInput extends BaseInput {
  public date;

  public constructor(data: any) {
    super(data);

    function n(n) {
      return n > 9 ? '' + n : '0' + n;
    }

    if (this.node.value.toString().length > 0) {
      const date = DateUtils.convertCfDateToJsDate(this.node.data.intValue);
      this.date  = date.getFullYear() + '-' + n(date.getMonth()) + '-' + n(date.getDay());
    }
  }

  public onDateChange(e) {
    const dateParts    = this.date.split('-');
    this.data.intValue = dateParts.join('');
    this.markAsDirty();
  }
}
