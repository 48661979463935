import { LoggerUtil }       from '@cs/core';
import { Injectable, Type } from '@angular/core';
import { toLowerCase }      from '@cs/components/util';
import { ValidationResult } from '@cs/core/generate';


export abstract class DashboardPanelComponentBase<T> {

	/**
	 * Easy handler for the registry name
	 * PLEASE MAKE ME STATIC!!!!!
	 */
	TYPE_NAME: string;
	name: string;

	set data(value: T) {
		this._data = value;
		this.dataChanged(this._data);
	}

	/**
	 * The data for this component
	 */
	get data(): T {
		return this._data;
	}

	/**
	 * The data source that contains the values of the panel.
	 */
	protected _data: T;

	abstract update(data: T): void;

	abstract dataChanged(value: T);

	setErrors(validationErrors: ValidationResult[]) {

	}

}


@Injectable({providedIn: 'root'})
export class DashboardComponentRegistry {

	constructor() {
	}


	hasTemplate(type: string) {
		return this.components.has(toLowerCase(type));
	}

	register(type: string, widget: Type<DashboardPanelComponentBase<unknown>>) {
		this.components.set(toLowerCase(type), widget);
	}

	getTemplate(type: string): Type<DashboardPanelComponentBase<unknown>> {
		if (this.hasTemplate(type)) {
			return this.components.get(toLowerCase(type));
		}
		LoggerUtil.error(`${type} is not found in template registry`);
		return null;
	}

	private components: Map<string, Type<DashboardPanelComponentBase<unknown>>> = new Map<string, Type<DashboardPanelComponentBase<unknown>>>();

}
