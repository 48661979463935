import { PropertyAnnotation }            from './property-annotation.model';
import { LoggerUtil, isNullOrUndefined } from '@cs/core/utils';

/**
 * Get the propertynames that is labeled as a key
 */
export function getIdHelperObj<T>(fields: PropertyAnnotation<T>[]): Array<string> {
	if (isNullOrUndefined(fields)) {
		LoggerUtil.error('There is no data-annotation provided field');
		return [];
	}

	return fields.filter(f => f.key || f.generatedKey)
				 .map(f => f.id.toString());
}

export function parseCompositeKey<T>(urlIdObject: string, idParamKey = 'id'): T | any {

	if (isNullOrUndefined(urlIdObject))
		return {};

	const isCompositeKey = urlIdObject.match(/(.+_.+)/);
	if (!isNullOrUndefined(isCompositeKey)) {
		const result = {};

		urlIdObject.split('|')
				   .forEach(v => {
					   const s      = v.split('_');
					   result[s[0]] = s[1];
				   });
		return result;
	} else {
		const result       = {};
		result[idParamKey] = urlIdObject;

		return result;
	}

}

/**
 * Semi-random string formatted as GUID
 */
export function generateQuickGuid() {
	return (
		Math.random()
			.toString(36)
			.substring(2, 15) +
		Math.random()
			.toString(36)
			.substring(2, 15)
	);
}


export function mailTo(supportEmail: string, subject: string, body: string = '') {
	window.open(`mailto:${supportEmail}?subject=${subject}&body=${body}`);
}

/**
 * Construct an object that contains the requested fields and it's value
 * @param object The object that is checked if it has the keyNames
 * @param keyNames A list of keys that we want to extract from the object
 */
export function getKeys<T>(object: T, keyNames: string[]) {
	const output = {};

	for (const key of keyNames) {
		if (object.hasOwnProperty(key)) {
			output[key] = object[key];
		}
	}

	return output;
}

/**
 * Get the id properties from the data
 */
export function getSelectionObject<T>(newData: T, fields: PropertyAnnotation<T>[]): Array<{}> | {} {


	const data         = newData;
	const allKeyFields = fields.filter(value => value.key);
	let output         = null;

	if (Array.isArray(data)) {
		const resultArray = [];
		for (const row of data) {
			const result = {};
			for (const field of allKeyFields) {
				result[field.id as any] = row[field.id];
			}
			resultArray.push(result);
		}

		output = resultArray;
	} else {
		const result = {};
		for (const field of allKeyFields) {
			result[field.id as any] = data[field.id];
		}

		output = result;
	}

	return output;
}
