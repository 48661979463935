<div>
  <form id="form"
				enctype="application/x-www-form-urlencoded"
        (keyup.enter)="useGoogleCaptcha ? false : resetPassword()">
    <div class="form-group email">
      <input class="form-control"
             type="text"
             name="username"
             autocomplete="off"
             placeholder="{{'ENTER_EMAIL_OF_LOST_ACCOUNT' | translate}}"
             (keyup)="checkErrorState()"
             [(ngModel)]="username"
             required="">
    </div>
    <div class="form-group" *ngIf="useGoogleCaptcha">
      <div class="mb-1 g-recaptcha-container reset-password" [innerHtml]="captchaHtml">
      </div>
    </div>
    <div matTooltip="{{getErrorMessage()}}"
         matTooltipPosition="above"
         [matTooltipDisabled]="!isErrorState">
         <ng-container *ngIf="useGoogleCaptcha">
          <button id="form-submit"
            [csLoader]="isWaitingForReset"
            [loaderAdditionalClasses]="['loader--white']"
            [disabled]="isErrorState"
            class="btn btn--primary btn--lg">
          {{'RESET_PASSWORD' | translate}}
          </button>
         </ng-container>
         <ng-container *ngIf="!useGoogleCaptcha">
          <button
            [csLoader]="isWaitingForReset"
            [loaderAdditionalClasses]="['loader--white']"
            [disabled]="isErrorState"
            (click)="resetPassword()"
            class="btn btn--primary btn--lg">
          {{'RESET_PASSWORD' | translate}}
          </button>
         </ng-container>
    </div>
	  <button (click)="goBackOfLogout()"
			  class="btn btn--white mt-2 btn--lg">
		  {{('CANCEL' | translate)}}
	  </button>
  </form>
  <p class="card-text login-footer-text">
    {{'IF_YOU_HAVE_PROBLEMS_PERSIST' | translate | capitalize}}
    <a href="mailto:{{loginConfig.supportEmail}}"
       class="link">{{loginConfig.supportEmail}}</a>
  </p>
</div>
