import { FormGeneratorLookupService }   from '@cs/components/form-generator-nxt';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { ListViewService }              from '@cs/customer/api/api/listView.service';
import { map }                          from 'rxjs/operators';

export class AppFormGeneratorLookupService extends FormGeneratorLookupService {
	constructor(private lookupService: ListViewService) {
		super();
	}

	getLookupWithFilter(lookupName: string, formDetails: any, contextObject: any = {}, csHttpRequestOptions: CsHttpRequestOptions = null) {
		return this.lookupService.listViewLookupWithFilterPost(lookupName, JSON.stringify(formDetails), JSON.stringify(contextObject), csHttpRequestOptions);
	}

	dependantFieldHasChanged(changedFieldId: string, formDetails: any, contextObject: any, csHttpRequestOptions?: CsHttpRequestOptions) {
		return this.lookupService.listViewDependsOnPanelUpdatePost(changedFieldId,
																															 JSON.stringify(formDetails), JSON.stringify(contextObject), csHttpRequestOptions)
							 .pipe(map(value => Result.success(value)));
	}


}

