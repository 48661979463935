import { BaseInput } from './base-input';

export class IntegerWithEnumInput extends BaseInput {
  public options;
  public enumValue;
  public constructor(data: any) {
    super(data);
    this.options = this.node.getConfig('enumOptions');
    this.enumValue = this.node.data.textValue;
  }

  public onSelectChange(e) {
    this.data.intValue = this.options.indexOf(this.value);
    this.markAsDirty();
  }
}
