import {
	Component, OnInit, ChangeDetectionStrategy, Inject,
	ChangeDetectorRef, ViewChild, AfterViewInit, forwardRef
}                                                                                     from '@angular/core';
import { UntilDestroy, untilDestroyed }                                               from '@ngneat/until-destroy';
import { filter }                                                                     from 'rxjs/operators';
import { FormGeneratorNxtComponent }                                                  from '../../form-generator-nxt.component';
import { ComponentLoaderDataContext, WizardContentComponent }                         from '@cs/components/shared';
import { WizardQuery, WizardService, WizardValidationResult }                         from '@cs/components/wizard';
import { DataDescribed, DataDescribedArray, FormDataDescribed, LayoutAnnotationForm } from '@cs/core';

@UntilDestroy()
@Component({
			   selector:        'cs-wizard-form-nxt',
			   templateUrl:     './wizard-form-nxt.component.html',
			   styleUrls:       ['./wizard-form-nxt.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class WizardFormNxtComponent
	extends WizardContentComponent<DataDescribedArray<any, LayoutAnnotationForm<any>>, DataDescribedArray<any, LayoutAnnotationForm<any>>>
	implements OnInit {

	static readonly TYPE_NAME = 'Form';

	@ViewChild(FormGeneratorNxtComponent, {static: true}) formGenerator: FormGeneratorNxtComponent<any>;

	constructor(@Inject(
					ComponentLoaderDataContext) readonly context: ComponentLoaderDataContext<DataDescribedArray<any, LayoutAnnotationForm<any>>>,
				private changeRef: ChangeDetectorRef,
				@Inject(forwardRef(() => WizardService)) wizardService: WizardService,
				@Inject(forwardRef(() => WizardQuery)) wizardQuery: WizardQuery) {
		super(context, wizardService, wizardQuery);
	}

	override ngOnInit(): void {
		super.ngOnInit();
		this.dataContext = new FormDataDescribed(this.context.data);
		this.changeRef.detectChanges();
		this.wizardStateService.clearErrorMessages.pipe(untilDestroyed(this),
														filter(value => value === this.parentContext.step.name))
			.subscribe(value => this.formGenerator.resetErrorResponse());
	}

	validate(value: WizardValidationResult) {
		this.formGenerator.showErrorResponse((value.validationResults
												   .map(validationResult => validationResult)), true);
	}


}
