/**
 * Created by alex on 11-7-2017.
 */

import { Injectable } from '@angular/core';
import { CsDataGrid } from '../data-grid.component';
import { Subject }    from 'rxjs';

@Injectable()
export class DataGridMessageHubService {

	runChangeDetection: Subject<string> = new Subject();

	readonly allRegisteredNestedGrid: Array<CsDataGrid> = [];

	constructor() {
	}


	register(dataGrid: CsDataGrid) {
		this.allRegisteredNestedGrid.push(dataGrid);
	}

	unregister(dataGrid: CsDataGrid) {
		const foundIndex = this.allRegisteredNestedGrid.findIndex(grid => grid.id === dataGrid.id);
		this.allRegisteredNestedGrid.splice(foundIndex, 1);
	}

}
