import { DynamicButtonRegistration } from './dynamic-button-bar.agent';


export class DynamicButtonBarClickedEventArgs {
  data: DynamicButtonRegistration;
  files: FileList;
  handled = false;

  constructor(data: DynamicButtonRegistration, files?: FileList) {
    this.data  = data;
    this.files = files;
  }
}
