/**
 * Created by alex on 7-7-2017.
 */

import { GridSheet }   from '../models/grid-sheet.model';
import { GridDataRow } from '../models/grid-data-row.model';
import { CsDataGrid }  from '../data-grid.component';
import { SheetAction } from './sheet.action';

export interface IActionParams {
	name: string;
}

export class SheetActionEventArgs {

	constructor(public type: SheetAction,
							public grid: CsDataGrid,
							public sheet: GridSheet,
							public newRow: GridDataRow,
							public actionParams: IActionParams) {

	}
}
