<div class="cs-panel-header root-repository__content">

	<div class="cs-content">

		<div class="cs-panel-header root-repository__title-div">

			<div class="root-repository__title-left">
				<h1 class="f-h1">Cohelion Customer Overview</h1>
			</div>

			<ng-container *ngIf="hasRecursiveChildRepositories(this.headRepositoryMap)">
				<div class="root-repository__title-right root-repository__light-font">
					<input type="checkbox" class="btn-checkbox root-repository__repository-checkbox"
						   [(ngModel)]="showAll">
					<label>Show All Child Repositories</label>
				</div>
			</ng-container>

		</div>

		<div class="cs-panel-header--content root-repository__table-content-holder">

			<ng-container *ngIf="headRepositoryMap.size > 0">
				<ng-container *ngFor="let clientRepository of headRepositoryMap | keyvalue">
					<RepositoryColumnedTableComponent
						[repository]="clientRepository"
						[allowUpdate]="true"
						[collapseRecursive]="!showAll"
						[hasBranchExpander]="true"
						[mainBranch]="'master'"
						[initialCollapse]="initialCollapse"
						(navigationEvent)="navigate($event)"
						(updateEvent)="loadRepositoryData()">
					</RepositoryColumnedTableComponent>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="headRepositoryMap.size < 1 || headRepositoryMap == undefined">
				<div class="header-div header-div__no-border header-div__title-left">
					<h3 class="f-h3">No Repositories Found</h3>
				</div>
			</ng-container>


		</div>

	</div>

</div>

