import { isNullOrUndefined } from '@cs/core/utils';

export enum FormatProviders {
	DotNet = 'dotNet'
}

export class FormatRegisteredItem {
	type: string;
	formatString: string;
	provider = FormatProviders.DotNet;
	parse: (value) => any;

	constructor(type: string, formatString: string, parseMethod = (value) => value) {
		this.type         = isNullOrUndefined(type) ? null : type.toLowerCase();
		this.formatString = formatString;
		this.parse        = parseMethod;
	}
}
