import { LoggerUtil }                                                              from '@cs/core';
import { AfterViewInit, Directive, Injectable, OnInit, Type }                      from '@angular/core';
import { toLowerCase }                                                             from '@cs/components/util';
import { DataDescribed, getErrorMessages, ValidationResult }                       from '@cs/core/generate';
import { UntilDestroy, untilDestroyed }                                            from '@ngneat/until-destroy';
import { debounceTime, filter, map }                                               from 'rxjs/operators';
import { ComponentLoaderDataContext }                                              from '../directives/component-loader.directive';
import { WizardParentContext, WizardService, WizardQuery, WizardValidationResult } from '@cs/components/wizard';
import { FormControl, FormGroup }                                                  from '@angular/forms';

/**
 * Workaround DI hooks angular when used as based class constructor argument
 */
type WizardServiceBase = WizardService;
type WizardQueryBase = WizardQuery;

/**
 * Use this key to wrap data that is not according the data described spec and to be used in the wizard formgroups
 */


// tslint:disable-next-line:no-empty-interface
@UntilDestroy()
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class WizardContentComponent<T, TData = T> implements OnInit, AfterViewInit {
	/**
	 * Extra context provided by the wizard, like step information or formgroups
	 * @protected
	 */
	parentContext: WizardParentContext;
	/**
	 * Store for the Wizard provided formgroup
	 * @protected
	 */
	formGroup: FormGroup;
	/**
	 * The data receiver for the component that is loaded. Allows for uniform handling of data changes when NgModel is implemented
	 * @protected
	 */
	formControl: FormControl = new FormControl();

	get dataContext(): T {
		return this._dataContext;
	}

	set dataContext(value: T) {
		this._dataContext = value;
	}

	protected constructor(protected readonly context: ComponentLoaderDataContext<TData, WizardParentContext>,
						  protected wizardStateService: WizardServiceBase,
						  protected wizardStateQuery: WizardQueryBase) {

	}

	ngOnInit() {
		this.parentContext = this.context.parentContext;
	}

	ngAfterViewInit() {
		this.wizardStateQuery.select(store => store.errorMessages)
			.pipe(
				untilDestroyed(this),
				map(value => {
					if (value == null)
						return null;

					return value.find(item => item.name === this.parentContext.step.name);
				}),
				filter(foundResult => foundResult != null)
			)
			.subscribe(value => {
				this.validate(value);
			});

		// Read the errormessages when status changes
		this.context.parentContext.formGroup.statusChanges.pipe(untilDestroyed(this), debounceTime(300))
			.subscribe(value => {
				const errorsAll: ValidationResult[] = [];
				const data                          = this.context.data as unknown as DataDescribed<any>;


				for (const controlName of Object.keys(this.parentContext.formGroup.controls)) {
					const control = this.parentContext.formGroup.controls[controlName];
					const prop    = data.dataAnnotation.fields.find(field => field.id === controlName);
					if (prop) {
						const errors = getErrorMessages(control, prop);
						errorsAll.push(...errors);
					}

				}

				const errorMessage = errorsAll.length > 0 && this.parentContext.step.showContextErrorMessage
									 ? errorsAll[0].errorMessage
									 : null;

				this.wizardStateService.wizardStepStatusChanged.next({
																		 state:             value.toString()
																								 .toLowerCase(),
																		 name:              this.parentContext.step.name,
																		 errorMessage:      errorMessage,
																		 validationResults: errorsAll
																	 });
			});
	}

	abstract validate(value: WizardValidationResult);

	private _dataContext: T;
}

export interface WizardRegisterItem {
	component: Type<WizardContentComponent<unknown, unknown>>;
	dataLoaderFn?: Function;
}


@Injectable({providedIn: 'root'})
export class WizardComponentRegistry {

	constructor() {
	}

	hasTemplate(type: string) {
		return this.components.has(toLowerCase(type));
	}

	register<T extends Type<WizardContentComponent<unknown, unknown>>>(type: string,
																	   component: T
	) {
		this.components.set(toLowerCase(type), {component: component});
	}

	getTemplate(type: string): WizardRegisterItem {
		if (this.hasTemplate(type)) {
			return this.components.get(toLowerCase(type));
		}
		LoggerUtil.error(`${type} is not found in template registry`);
		return null;
	}

	private components: Map<string, WizardRegisterItem> = new Map<string, WizardRegisterItem>();

}

