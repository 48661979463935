export type CSFormFieldListType = 'checkboxlist' | 'checkboxgrid' | 'radiolist' | 'radiogrid';

import {
	ChangeDetectorRef, Component, ElementRef, forwardRef, Input, OnInit, QueryList,
	ViewChildren
}                                                               from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { isString, LoggerUtil }                                 from '@cs/core/utils';
import { CSFormGeneratorDataSourceLookupValues }                from '../../form-generator.models';
import { isNullOrUndefined }                                    from '@cs/core';

@Component({
			   selector:    'cs-form-field-list',
			   templateUrl: './form-field-list.component.html',
			   styles:      [`
								 label.btn.btn-checkbox.disabled,
								 label.btn.btn-radio.disabled {
									 background-color: #d3d3d3;
									 border-color: #B2BFC5;
								 }
							 `],
			   providers:   [
				   {
					   provide:     NG_VALUE_ACCESSOR,
					   useExisting: forwardRef(() => CsFormFieldListComponent),
					   multi:       true
				   }
			   ]
		   })
export class CsFormFieldListComponent implements OnInit, ControlValueAccessor {


	@Input() type: String;
	@Input() lookupValues: Array<CSFormGeneratorDataSourceLookupValues> = [];
	@Input() height: number;
	@Input() isScrollable                                               = false;
	@Input() showAsHorizontalList                                       = false;
	@Input() gridColumns                                                = 2;
	@ViewChildren('radioButton', {read: ElementRef}) radioButtons: QueryList<ElementRef>;
	@ViewChildren('checkbox', {read: ElementRef}) checkboxes: QueryList<ElementRef>;
	fieldType: string;
	listType: string;
	listValue: Array<any>                                               = [];
	// random name for the radiobuttons "group"
	radiogroup: string                                                  = Math.random()
																			  .toString(36)
																			  .slice(2);
	disabled                                                            = false;

	get value(): string {
		return this.listValue.join(',');
	}

	set value(value: string) {
		if (value.length) {
			this.listValue = value.split(',')
								  .map(Number);
		} else {
			this.listValue = [];
		}
		this.propagateChange(this.value);
	}

	constructor(private changeRef: ChangeDetectorRef) {
	}

	ngOnInit() {
		// Support both checkboxlist and radiogrid naming for old api
		const types    = this.type.match(/(\w+)(list|grid)$/);
		this.fieldType = types[1];
		this.listType  = types[2];
	}


	/**
	 * Called by the form controls

	 */
	onChange(data: any) {
		const key = data.target.name;
		if (this.fieldType === 'checkbox') {
			if (data.target.checked) {
				this.listValue = this.listValue.concat(parseFloat(key));
			} else {
				this.listValue = this.listValue.filter((x) => x !== parseFloat(key));
			}
		}
		if (this.fieldType === 'radio') {
			this.listValue = [data.target.value];
		}

		this.propagateChange(this.value);
	}

	/**
	 * Control value set by external Form Control

	 */
	writeValue(data: string) {
		// split map converts empty string to 0 (zero), check if we get passed an empty string
		if (isNullOrUndefined(data) || !data.toString().length) {
			this.listValue = [];
		} else {
			this.listValue = data.toString()
								 .split(',')
								 .map(Number);
		}
	}

	/**
	 * From control state set by external form builder

	 */
	setDisabledState(isDisabled: boolean) {
		console.log('setDisabledState:' + isDisabled);
		this.disabled = isDisabled;
	}

	/**
	 * Callback by which we let the external world know this form control value has changed.

	 */
	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	validate(fc: FormControl) {
		return this.validateFn(fc);
	}

	isSelected(key: string, isRadio = false) {
		if (!isRadio) {
			return this.listValue
				   ? this.listValue.indexOf(key) !== -1
				   : false;
		}
		return this.listValue
			   ? key === this.listValue[0] + ''
			   : false;
	}

	onLabelClick(key, isRadio) {
		// Determine the target array based on whether it's radio or checkbox
		const targetArray = isRadio ? this.radioButtons : this.checkboxes;

		// Find the element by matching its name attribute with the key
		const targetElement = targetArray.find(el => el.nativeElement.name === key);

		if (targetElement) {
			targetElement.nativeElement.click();
		} else {
			// If not found by name, try to find by id attribute matching the key
			const targetById = targetArray.find(el => el.nativeElement.id === key);

			if (targetById) {
				targetById.nativeElement.click();
			} else {
				LoggerUtil.error(`Element with key "${key}" not found. Please update the name property by the server`);
			}
		}
	}

	onSelectAll() {
		if (!this.disabled && this.lookupValues) {
			this.value = this.lookupValues.reduce((a, b) => {
								 return a.concat(b.id);
							 }, [])
							 .join(',');
		}

	}

	onSelectNone() {
		if (!this.disabled) {
			this.value = '';
		}
	}

	private propagateChange: any   = (obj: any) => {
	};
	private onTouchedCallback: any = (obj: any) => {
	};
	private validateFn: any        = (fc: any) => {
	};
}
