import { GridActions }       from '../enums/data-grid.enum';
import { DataGridItemState } from './data-grid-item-state';
import { DataGridSortItem }  from './data-grid-sort-item';
import { DataGridUIState }   from './data-grid-u-i-state';
import { CellBehavior }      from './cell-behavior';

export abstract class DataGridAction {
	readonly type: GridActions;
}

export class DataGridFormatAction implements DataGridAction {
	readonly type: GridActions = GridActions.SetFormatting;
	format: string;

	constructor(initial: Partial<DataGridFormatAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridStateAction implements DataGridAction {
	readonly type: GridActions = GridActions.SetState;
	state: DataGridItemState   = new DataGridItemState();

	constructor(initial: Partial<DataGridStateAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridSetVirtualKeysAction implements DataGridAction {
	readonly type: GridActions = GridActions.SetVirtualKeys;
	keys: {
		[key: string]: number | string | boolean
	};

	constructor(initial: Partial<DataGridSetVirtualKeysAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridUiStateAction implements DataGridAction {
	readonly type: GridActions = GridActions.SetUiState;
	state: DataGridUIState     = new DataGridUIState();

	constructor(initial: Partial<DataGridUiStateAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridDataGridSortItemAction implements DataGridAction {
	readonly type: GridActions   = GridActions.SetDataGridSortItem;
	properties: DataGridSortItem = new DataGridSortItem();

	constructor(initial: Partial<DataGridDataGridSortItemAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridChangeTextAction implements DataGridAction {
	readonly type: GridActions     = GridActions.TextChange;
	property: 'label' | 'labelMin' = 'label';
	text: string;
	prefix: string;
	suffix: string;
	depth                          = 0;

	constructor(initial: Partial<DataGridChangeTextAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridCssAction implements DataGridAction {
	readonly type: GridActions = GridActions.ChangeCss;
	classes                    = '';

	constructor(initial: Partial<DataGridCssAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridBehaviorAction extends CellBehavior implements DataGridAction {
	readonly type: GridActions = GridActions.SetBehavior;

	constructor(initial: Partial<DataGridBehaviorAction>) {
		super(initial);
		Object.assign(this, initial);
	}
}

export class DataGridRemoveCssAction implements DataGridAction {
	readonly type: GridActions = GridActions.RemoveCss;
	classes                    = '';

	constructor(initial: Partial<DataGridCssAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridLookupAction implements DataGridAction {
	readonly type: GridActions        = GridActions.SetLookup;
	key: string;
	source: 'AllMembers'              = 'AllMembers';
	lookupType: 'Value' | 'ChoiceSet' = 'Value';
	display: 'label' | 'labelMin'     = 'label';
	unique: string                    = null;
	disableNotUnique                  = false;

	constructor(initial: Partial<DataGridLookupAction>) {
		Object.assign(this, initial);
	}
}

export class DataGridCellTooltipAction implements DataGridAction {
	readonly type: GridActions = GridActions.SetCellTooltip;
	templateHtml: string;

	constructor(initial: Partial<DataGridCellTooltipAction>) {
		Object.assign(this, initial);
	}
}
