<div class="value-text"  *ngIf="disabled && !file">
  <a *ngIf="fileLinkIdentifier" csHyperlink (csHyperlinkClick)="fileDownloadLinkClicked(fileLinkIdentifier)">Download</a>
  <span *ngIf="!fileLinkIdentifier">No file available</span>
</div>
<div class="value-text" *ngIf="disabled && (file || operation === 'remove')">
  <!-- Form generator does not have a "saving in progress" sate, fake one -->
  <span title="Waiting for server response.">Uploading...</span>
</div>
<div class="value-control" *ngIf="!disabled">
  <select [ngModel]="operation" (ngModelChange)="onFileOperationChange($event)">
    <option *ngFor="let option of fileOperations" [ngValue]="option.key">{{option.value}}</option>
  </select>
  <span *ngIf="file" class="selected-file-name" [title]="file.name">
    {{file.name}}
  </span>
  <label *ngIf="operation === 'upload'"
         class="btn btn-quaternary btn-sm browse-button"
  >
    <input hidden #fileInput
           type="file"
           accept="{{accept}}"
           (click)="clearInputElementValue()"
           (change)="onFileSelectChange($event)">
    Browse
  </label>
</div>
