import { isNullOrUndefined, IProperty }                         from '@cs/core';
import { GridGroup }                                            from '../models/grid-group.model';
import { GridSheet }                                            from '../models/grid-sheet.model';
import { GridHeaderCell }                                       from '../models/grid-head-cell.model';
import { GridDataCell, GridDataRow }                            from '../models';
import { DataGridCellType, RowState, UiTypes }                  from '../enums/data-grid.enum';
import { GridHeaderRow }                                        from '../models/grid-head-row.model';
import { DataGridHelpers }                                      from './data-grid-helpers';
import { DataGridUIState, GridDataCellMetaValues, GridOptions } from '../classes';
import { FormatProviderService }                                from '@cs/common';
import { pathChecked }                                          from '@cs/core';
import { Injector }                                             from '@angular/core';


/**
	* Created by alex on 28-7-2017.
	*/

export class DataGridElementFactory {
	/**
		* Added injector in this way to avoid a lot of method changes. Needs to be different in newer version
		*/
	static injector: Injector = null;

	static createSheet(key: string, keys: {
		[key: string]: any
	}, properties: IProperty, options: GridOptions) {
		const sheet = new GridSheet(key, keys, properties, options);

		// turn off the calculator when asked by the server
		if (pathChecked(options, ['config', 'calculations', 'calculateTotals'], 'all', false) === 'none')
			sheet.calculator.disableCalculations();

		// Use the pagetitle for the sheet label
		if (options.usePageTitleAsSheetLabel)
			sheet.properties.label = options.usePageTitleAsSheetLabel;

		return sheet;
	}

	static createGridHeaderCell(config: Partial<GridHeaderCell>) {
		return new GridHeaderCell(config);
	}

	static createGridDataRow(dataGridHead, isEditable = false) {

		const formatService = DataGridElementFactory.injector.get(FormatProviderService);

		const values        = [];
		const lastColumnRow = dataGridHead[dataGridHead.length - 1];
		for (let i = 0; i < lastColumnRow.columns.length; i++) {
			const col: GridHeaderCell = lastColumnRow.columns[i];
			// Add cell to row
			const cell                = new GridDataCell({}, formatService);

			if (col.cellType === DataGridCellType.Injected) {
				cell.cellUIState = new DataGridUIState(col.cellUIState);
				cell.lookup      = col.lookup;
				cell.cellType    = DataGridCellType.Injected;
				cell.key         = col.key;
				cell.metaValues  = new GridDataCellMetaValues(Object.assign(cell.metaValues, {columnkey: col.key}));
			}

			Object.assign(cell.cellState, {
				readonly: !isEditable,
				editable: isEditable
			});

			Object.assign(cell.cellUIState, col.cellUIState);

			// check for cells that always needs to be read-only
			if (col.cellType === DataGridCellType.Total || col.cellType === DataGridCellType.Offset || col.cellType === DataGridCellType.RowMenu) {
				cell.cellType           = col.cellType;
				cell.cellState.editable = false;
				cell.cellState.readonly = true;
				if (col.cellType === DataGridCellType.RowMenu)
					cell.cellUIState.hasReadOnlyVersion = false;
			}

			// Set the keys of the row and column to the cell so we can find the cell by keys
			cell.keys = Object.assign({}, cell.keys, col.keys);

			values.push(cell);
		}
		return new GridDataRow(values, {});
	}

	static createGridDataExpansionRow(dataGridHead, gridRow: GridDataRow, injector: Injector) {
		const formatService = injector.get(FormatProviderService);

		const values                       = [];
		const lastColumnRow: GridHeaderRow = dataGridHead[dataGridHead.length - 1];

		// Add cell to row
		const cell = new GridDataCell({}, formatService);

		cell.cellUIState = new DataGridUIState({uiType: UiTypes.DataGrid});
		cell.cellType    = DataGridCellType.Injected;
		cell.colSpan     = lastColumnRow.columns.length;

		Object.assign(cell.cellState, {
			editable: true
		});

		values.push(cell);
		const expansionRow    = new GridDataRow(values, {});
		expansionRow.rowState = RowState.Expanded;
		return expansionRow;
	}


	static addEmptyRow(sheet: GridSheet): GridDataRow {
		const group    = sheet.groups[0];
		const emptyRow = this.createGridDataRow(group.columsRows, true);
		group.dataRows.push(emptyRow);
		emptyRow.rowState = RowState.New;

		return emptyRow;
	}

	static addTotalRow(sheet: GridSheet, gridGroup?: GridGroup): GridDataRow {
		let group = sheet.groups[0];

		if (!isNullOrUndefined(gridGroup))
			group = gridGroup;

		const emptyRow = this.createGridDataRow(group.columsRows, false);

		group.dataRows.splice(0, 0, emptyRow);
		emptyRow.rowState     = RowState.Total;
		emptyRow.id           = DataGridHelpers.createKeysString(group.keys);
		emptyRow.keys         = group.keys;
		emptyRow.isGroupTotal = !isNullOrUndefined(gridGroup);

		if (!isNullOrUndefined(gridGroup)) {

			const groupKeys = DataGridHelpers.findGroupKeys(gridGroup);

			emptyRow.values.forEach(cell => cell.groupKeys = groupKeys);
		}
		return emptyRow;
	}
}
