/* tslint:disable */

import { Injectable } from '@angular/core';

import { CsCultureProvider }  from '@cs/common';
import { InputMaskHelpers }   from './input-mask-helpers';
import { CsInputMaskOptions } from './input-mask-options';

/**
 * Input mask config, exports/config aliases
 */
@Injectable()
// tslint:disable
export class CsInputMaskConfig {
	constructor(private cultureProvider: CsCultureProvider) {

	}

	/**
	 * Return radix(decimal) point according to current culture.
	 */
	get radixPoint() {
		return this.cultureProvider.getCulture() !== 'nl' ? '.' : ',';
	}

	/**
	 * Return group(thousands) separator according to current culture.
	 */
	get groupSeparator() {
		return this.cultureProvider.getCulture() === 'nl' ? '.' : ',';
	}

	autoEscape(txt, opts) {
		let escapedTxt = '';
		for (let i = 0; i < txt.length; i++) {
			if (opts.definitions[txt.charAt(i)] ||
				opts.optionalmarker.start === txt.charAt(i) ||
				opts.optionalmarker.end === txt.charAt(i) ||
				opts.quantifiermarker.start === txt.charAt(i) ||
				opts.quantifiermarker.end === txt.charAt(i) ||
				opts.groupmarker.start === txt.charAt(i) ||
				opts.groupmarker.end === txt.charAt(i) ||
				opts.alternatormarker === txt.charAt(i)) {
				escapedTxt += '\\' + txt.charAt(i);
			} else {
				escapedTxt += txt.charAt(i);
			}
		}
		return escapedTxt;
	}

	/**
	 * Input mask aliases
	 */
	aliases: { [key: string]: CsInputMaskOptions } = {

		'numeric?': {
			mask:                 (opts) => {
				if (opts.repeat !== 0 && isNaN(opts.integerDigits)) {
					opts.integerDigits = opts.repeat;
				}
				opts.repeat = 0;
				if (opts.groupSeparator === opts.radixPoint) { // treat equal separator and radixpoint
					if (opts.radixPoint === '.') {
						opts.groupSeparator = ',';
					} else if (opts.radixPoint === ',') {
						opts.groupSeparator = '.';
					} else opts.groupSeparator = '';
				}
				if (opts.groupSeparator === ' ') { // prevent conflict with default skipOptionalPartCharacter
					opts.skipOptionalPartCharacter = undefined;
				}
				opts.autoGroup = opts.autoGroup && opts.groupSeparator !== '';
				if (opts.autoGroup) {
					if (typeof opts.groupSize === 'string' && isFinite(opts.groupSize)) opts.groupSize = parseInt(opts.groupSize);
					if (isFinite(opts.integerDigits)) {
						var seps           = Math.floor(opts.integerDigits / opts.groupSize);
						var mod            = opts.integerDigits % opts.groupSize;
						opts.integerDigits = parseInt(opts.integerDigits) + (mod === 0 ? seps - 1 : seps);
						if (opts.integerDigits < 1) {
							opts.integerDigits = '*';
						}
					}
				}

				// enforce placeholder to single
				if (opts.placeholder.length > 1) {
					opts.placeholder = opts.placeholder.charAt(0);
				}
				// only allow radixfocus when placeholder = 0
				if (opts.positionCaretOnClick === 'radixFocus' && (opts.placeholder === '' && opts.integerOptional === false)) {
					opts.positionCaretOnClick = 'lvp';
				}
				opts.definitions[';']                  = opts.definitions['~']; // clone integer def for decimals
				opts.definitions[';'].definitionSymbol = '~';

				if (opts.numericInput === true) { // finance people input style
					opts.positionCaretOnClick = opts.positionCaretOnClick === 'radixFocus' ? 'lvp' : opts.positionCaretOnClick;
					opts.digitsOptional       = false;
					if (isNaN(opts.digits)) opts.digits = 2;
					opts.decimalProtect = false;
				}

				var mask = '[+]';
				mask += this.autoEscape(opts.prefix, opts);

				if (opts.integerOptional === true) {
					mask += '~{1,' + opts.integerDigits + '}';
				} else mask += '~{' + opts.integerDigits + '}';
				if (opts.digits !== undefined) {
					opts.radixPointDefinitionSymbol = opts.decimalProtect ? ':' : opts.radixPoint;
					var dq                          = opts.digits.toString().split(',');
					if (isFinite(dq[0] && dq[1] && +isFinite(dq[1]))) {
						mask += opts.radixPointDefinitionSymbol + ';{' + opts.digits + '}';
					} else if (isNaN(opts.digits) || parseInt(opts.digits) > 0) {
						if (opts.digitsOptional) {
							mask += '[' + opts.radixPointDefinitionSymbol + ';{1,' + opts.digits + '}]';
						} else mask += opts.radixPointDefinitionSymbol + ';{' + opts.digits + '}';
					}
				}
				mask += this.autoEscape(opts.suffix, opts);
				mask += '[-]';

				opts.greedy = false; // enforce greedy false

				return mask;
			},
			placeholder:          '',
			greedy:               false,
			digits:               '*', // number of fractionalDigits
			digitsOptional:       true,
			radixPoint:           '.',
			positionCaretOnClick: 'radixFocus',
			groupSize:            3,
			groupSeparator:       '',
			autoGroup:            false,
			allowMinus:           true,
			negationSymbol:       {
				front: '-', // "("
				back:  '' // ")"
			},
			integerDigits:        '+', // number of integerDigits
			integerOptional:      true,
			prefix:               '',
			suffix:               '',
			rightAlign:           true,
			decimalProtect:       true, // do not allow assumption of decimals input without entering the radixpoint
			min:                  null, // minimum value
			max:                  null, // maximum value
			step:                 1,
			insertMode:           true,
			autoUnmask:           false,
			unmaskAsNumber:       false,
			inputmode:            'numeric',
			preValidation:        function (buffer, pos, c, isSelection, opts): any {
				if (c === '-' || c === opts.negationSymbol.front) {
					if (opts.allowMinus !== true) return false;
					opts.isNegative = opts.isNegative === undefined ? true : !opts.isNegative;
					if (buffer.join('') === '') return true;
					return {caret: pos, dopost: true};
				}
				if (isSelection === false && c === opts.radixPoint && (opts.digits !== undefined && (isNaN(opts.digits) || parseInt(opts.digits) > 0))) {
					var radixPos = InputMaskHelpers.inArray(opts.radixPoint, buffer);
					if (radixPos !== -1) {
						if (opts.numericInput === true) {
							return pos === radixPos;
						}
						return {'caret': radixPos + 1};
					}
				}

				return true;
			},
			postValidation:       function (buffer, currentResult, opts) {
				function buildPostMask(buffer, opts) {
					// define base for formatter
					var postMask = '';
					postMask += '(' + opts.groupSeparator + '*{' + opts.groupSize + '}){*}';
					if (opts.radixPoint !== '') {
						var radixSplit = buffer.join('').split(opts.radixPoint);
						if (radixSplit[1]) {
							postMask += opts.radixPoint + '*{' + radixSplit[1].match(/^\d*\??\d*/)[0].length + '}';
						}
					}

					return postMask;
				}

				var suffix = opts.suffix.split(''), prefix = opts.prefix.split('');

				if (currentResult.pos === undefined && currentResult.caret !== undefined && currentResult.dopost !== true) return currentResult;

				var caretPos    = currentResult.caret !== undefined ? currentResult.caret : currentResult.pos;
				var maskedValue = buffer.slice();
				if (opts.numericInput) {
					caretPos    = maskedValue.length - caretPos - 1;
					maskedValue = maskedValue.reverse();
				}
				// mark caretPos
				var charAtPos = maskedValue[caretPos];
				if (charAtPos === opts.groupSeparator) {
					caretPos += 1;
					charAtPos = maskedValue[caretPos];
				}


				if (caretPos === maskedValue.length - opts.suffix.length - 1 && charAtPos === opts.radixPoint) return currentResult;

				if (charAtPos !== undefined) {
					if (charAtPos !== opts.radixPoint &&
						charAtPos !== opts.negationSymbol.front &&
						(charAtPos !== opts.negationSymbol.back)
					) {
						maskedValue[caretPos] = '?';
						if (opts.prefix.length > 0 && caretPos >= (opts.isNegative === false ? 1 : 0) && caretPos < opts.prefix.length - 1 + (opts.isNegative === false ? 1 : 0)) {
							prefix[caretPos - (opts.isNegative === false ? 1 : 0)] = '?';
						} else if (opts.suffix.length > 0 && caretPos >= (maskedValue.length - opts.suffix.length) - (opts.isNegative === false ? 1 : 0)) {
							suffix[caretPos - (maskedValue.length - opts.suffix.length - (opts.isNegative === false ? 1 : 0))] = '?';
						}
					}
				}
				// make numeric
				prefix           = prefix.join('');
				suffix           = suffix.join('');
				var processValue = maskedValue.join('').replace(prefix, '');
				processValue     = processValue.replace(suffix, '');
				processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.groupSeparator), 'g'), '');
				// strip negation symbol
				processValue     = processValue.replace(new RegExp('[-' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + ']', 'g'), '');
				processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.negationSymbol.back) + '$'), '');
				// strip placeholder at the end
				if (isNaN(opts.placeholder)) {
					processValue = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.placeholder), 'g'), '');
				}

				// strip leading zeroes
				if (processValue.length > 1 && processValue.indexOf(opts.radixPoint) !== 1) {
					if (charAtPos === '0') {
						processValue = processValue.replace(/^\?/g, '');
					}
					processValue = processValue.replace(/^0/g, '');
				}

				if (processValue.charAt(0) === opts.radixPoint && opts.numericInput !== true) {
					processValue = '0' + processValue;
				}

				if (processValue !== '') {
					processValue = processValue.split('');
					// handle digits
					if (!opts.digitsOptional && isFinite(opts.digits)) {
						var radixPosition = InputMaskHelpers.inArray(opts.radixPoint, processValue);
						var rpb           = InputMaskHelpers.inArray(opts.radixPoint, maskedValue);
						if (radixPosition === -1) {
							processValue.push(opts.radixPoint);
							radixPosition = processValue.length - 1;
						}
						for (var i = 1; i <= opts.digits; i++) {
							if (!opts.digitsOptional && (processValue[radixPosition + i] === undefined || processValue[radixPosition + i] === opts.placeholder.charAt(0))) {
								processValue[radixPosition + i] = currentResult.placeholder || opts.placeholder.charAt(0);
							} else if (rpb !== -1 && maskedValue[rpb + i] !== undefined) {
								processValue[radixPosition + i] = processValue[radixPosition + i] || maskedValue[rpb + i];
							}
						}
					}

					processValue = processValue.join('');
				}

				if (opts.isNegative && currentResult.event === 'blur') {
					opts.isNegative = processValue !== '0';
				}

				processValue = prefix + processValue;
				processValue += suffix;
				if (opts.isNegative) {
					processValue = opts.negationSymbol.front + processValue;
					processValue += opts.negationSymbol.back;
				}
				processValue = processValue.split('');
				// unmark position
				if (charAtPos !== undefined) {
					if (charAtPos !== opts.radixPoint && charAtPos !== opts.negationSymbol.front && charAtPos !== opts.negationSymbol.back) {
						caretPos = InputMaskHelpers.inArray('?', processValue);
						if (caretPos > -1)
							processValue[caretPos] = charAtPos;
						else caretPos = currentResult.caret || 0;
					} else if (charAtPos === opts.radixPoint ||
						charAtPos === opts.negationSymbol.front ||
						charAtPos === opts.negationSymbol.back) {
						var newCaretPos = InputMaskHelpers.inArray(charAtPos, processValue);
						if (newCaretPos !== -1) caretPos = newCaretPos;
						//  else charAtPos = undefined;
					}
				}

				if (opts.numericInput) {
					caretPos     = processValue.length - caretPos - 1;
					processValue = processValue.reverse();
				}

				var rslt = {
					caret:             (charAtPos === undefined || currentResult.pos !== undefined) ? caretPos + (opts.numericInput ? -1 : 1) : caretPos,
					buffer:            processValue,
					refreshFromBuffer: currentResult.dopost || buffer.join('') !== processValue.join('')
				};

				//  console.log(JSON.stringify(rslt));
				return rslt.refreshFromBuffer ? rslt : currentResult;
			},
			onBeforeWrite:        function (e, buffer, caretPos, opts) {
				function parseMinMaxOptions(opts) {
					if (opts.parseMinMaxOptions === undefined) {
						// convert min and max options
						if (opts.min !== null) {
							opts.min = opts.min.toString().replace(new RegExp(InputMaskHelpers.escapeRegex(opts.groupSeparator), 'g'), '');
							if (opts.radixPoint === ',') opts.min = opts.min.replace(opts.radixPoint, '.');
							opts.min = isFinite(opts.min) ? parseFloat(opts.min) : NaN;
							if (isNaN(opts.min)) opts.min = Number.MIN_VALUE;
						}
						if (opts.max !== null) {
							opts.max = opts.max.toString().replace(new RegExp(InputMaskHelpers.escapeRegex(opts.groupSeparator), 'g'), '');
							if (opts.radixPoint === ',') opts.max = opts.max.replace(opts.radixPoint, '.');
							opts.max = isFinite(opts.max) ? parseFloat(opts.max) : NaN;
							if (isNaN(opts.max)) opts.max = Number.MAX_VALUE;
						}
						opts.parseMinMaxOptions = 'done';
						//  console.log(opts.min + " " + opts.max);
					}
				}

				if (e) {
					switch (e.type) {
						case 'keydown':
							return opts.postValidation(buffer, {caret: caretPos, dopost: true}, opts);
						case 'blur':
						case 'checkval':
							var unmasked;
							parseMinMaxOptions(opts);
							if (opts.min !== null || opts.max !== null) {
								unmasked = opts.onUnMask(buffer.join(''), undefined, InputMaskHelpers.deepClone({}, opts, {unmaskAsNumber: true}));
								if (opts.min !== null && unmasked < opts.min) {
									opts.isNegative = opts.min < 0;
									return opts.postValidation(opts.min.toString().replace('.', opts.radixPoint).split(''), {  // needs fix for MIN_VALUE & MAX_VALUE
										caret:       caretPos,
										dopost:      true,
										placeholder: '0'
									}, opts);
								} else if (opts.max !== null && unmasked > opts.max) {
									opts.isNegative = opts.max < 0;
									return opts.postValidation(opts.max.toString().replace('.', opts.radixPoint).split(''), {  // needs fix for MIN_VALUE & MAX_VALUE
										caret:       caretPos,
										dopost:      true,
										placeholder: '0'
									}, opts);
								}
							}
							return opts.postValidation(buffer, {
								caret:       caretPos,
								dopost:      true,
								placeholder: '0',
								event:       'blur'
							}, opts);
						case '_checkval':
							return {caret: caretPos};
						default:

							break;
					}
				}
			},
			regex:                {
				integerPart: function (opts, emptyCheck) {
					return emptyCheck ? new RegExp('[' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + '\+]?') : new RegExp('[' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + '\+]?\\d+');
				}
				,
				integerNPart: function (opts) {
					return new RegExp('[\\d' + InputMaskHelpers.escapeRegex(opts.groupSeparator) + InputMaskHelpers.escapeRegex(opts.placeholder.charAt(0)) + ']+');
				}
			}
			,
			definitions: {
				'~': {
					validator: function (chrs, maskset, pos, strict, opts, isSelection) {
						var isValid: any = strict ? new RegExp('[0-9' + InputMaskHelpers.escapeRegex(opts.groupSeparator) + ']').test(chrs) : new RegExp('[0-9]').test(chrs);
						if (isValid === true) {
							if (opts.numericInput !== true && maskset.validPositions[pos] !== undefined && maskset.validPositions[pos].match.def === '~' && !isSelection) {
								var processValue   = maskset.buffer.join('');
								// strip negation symbol
								processValue       = processValue.replace(new RegExp('[-' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + ']', 'g'), '');
								processValue       = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.negationSymbol.back) + '$'), '');
								// filter 0
								processValue       = processValue.replace(/0/g, opts.placeholder.charAt(0));
								var bufferTemplate = maskset._buffer.join(''); // getBuffer().slice(lvp).join('');
								if (processValue === opts.radixPoint) {
									processValue = bufferTemplate;
								}
								while (processValue.match(InputMaskHelpers.escapeRegex(bufferTemplate) + '$') === null) {
									bufferTemplate = bufferTemplate.slice(1);
								}
								processValue = processValue.replace(bufferTemplate, '');
								processValue = processValue.split('');

								if (processValue[pos] === undefined) {
									isValid = {
										'pos':    pos,
										'remove': pos
									};
								} else {
									isValid = {
										pos: pos
									};
								}
							}
						} else if (!strict && chrs === opts.radixPoint && maskset.validPositions[pos - 1] === undefined) {
							maskset.buffer[pos] = '0';
							isValid             = {
								'pos': pos + 1
							};
						}
						return isValid;
					}
					,
					cardinality: 1
				}
				,
				'+': {
					validator:   function (chrs, maskset, pos, strict, opts) {
						return (opts.allowMinus && (chrs === '-' || chrs === opts.negationSymbol.front));

					},
					cardinality: 1,
					placeholder: ''
				},
				'-': {
					validator:   function (chrs, maskset, pos, strict, opts) {
						return (opts.allowMinus && chrs === opts.negationSymbol.back);

					},
					cardinality: 1,
					placeholder: ''
				},
				':': {
					validator:   function (chrs, maskset, pos, strict, opts) {
						var radix        = '[' + InputMaskHelpers.escapeRegex(opts.radixPoint) + ']';
						let isValid: any = new RegExp(radix).test(chrs);
						if (isValid && maskset.validPositions[pos] && maskset.validPositions[pos].match.placeholder === opts.radixPoint) {
							isValid = {
								'caret': pos + 1
							};
						}
						return isValid;
					},
					cardinality: 1,
					placeholder: function (opts) {
						return opts.radixPoint;
					}
				}
			},
			onUnMask:    function (maskedValue, unmaskedValue, opts) {
				if (unmaskedValue === '' && opts.nullable === true) {
					return unmaskedValue;
				}
				var processValue = maskedValue.replace(opts.prefix, '');
				processValue     = processValue.replace(opts.suffix, '');
				processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.groupSeparator), 'g'), '');
				if (opts.placeholder.charAt(0) !== '')
					processValue = processValue.replace(new RegExp(opts.placeholder.charAt(0), 'g'), '0');
				if (opts.unmaskAsNumber) {
					if (opts.radixPoint !== '' && processValue.indexOf(opts.radixPoint) !== -1) processValue = processValue.replace(InputMaskHelpers.escapeRegex.call(this, opts.radixPoint), '.');
					return Number(processValue);
				}
				return processValue;
			}
			,
			isComplete:   function (buffer, opts) {
				var maskedValue = buffer.join(''),
						bufClone    = buffer.slice();
				// verify separator positions
				if (bufClone.join('') !== maskedValue) return false;

				var processValue = maskedValue.replace(opts.prefix, '');
				processValue     = processValue.replace(opts.suffix, '');
				processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.groupSeparator), 'g'), '');
				if (opts.radixPoint === ',') processValue = processValue.replace(InputMaskHelpers.escapeRegex(opts.radixPoint), '.');
				return isFinite(processValue);
			},
			onBeforeMask: function (initialValue, opts) {
				opts.isNegative = undefined;

				initialValue = initialValue.toString().charAt(initialValue.length - 1) === opts.radixPoint
					? initialValue.toString().substr(0, initialValue.length - 1) : initialValue.toString();

				if (opts.radixPoint !== '' && isFinite(initialValue)) {
					var vs        = initialValue.split('.'),
							groupSize = opts.groupSeparator !== '' ? parseInt(opts.groupSize) : 0;
					if (vs.length === 2 && (vs[0].length > groupSize || vs[1].length > groupSize || (vs[0].length <= groupSize && vs[1].length < groupSize)))
						initialValue = initialValue.replace('.', opts.radixPoint);
				}
				var kommaMatches = initialValue.match(/,/g);
				var dotMatches   = initialValue.match(/\./g);
				if (dotMatches && kommaMatches) {
					if (dotMatches.length > kommaMatches.length) {
						initialValue = initialValue.replace(/\./g, '');
						initialValue = initialValue.replace(',', opts.radixPoint);
					} else if (kommaMatches.length > dotMatches.length) {
						initialValue = initialValue.replace(/,/g, '');
						initialValue = initialValue.replace('.', opts.radixPoint);
					} else { // equal
						initialValue = initialValue.indexOf('.') < initialValue.indexOf(',') ? initialValue.replace(/\./g, '') : initialValue = initialValue.replace(/,/g, '');
					}
				} else {
					initialValue = initialValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.groupSeparator), 'g'), '');
				}

				if (opts.digits === 0) {
					if (initialValue.indexOf('.') !== -1) {
						initialValue = initialValue.substring(0, initialValue.indexOf('.'));
					} else if (initialValue.indexOf(',') !== -1) {
						initialValue = initialValue.substring(0, initialValue.indexOf(','));
					}
				}

				if (opts.radixPoint !== '' && isFinite(opts.digits) && initialValue.indexOf(opts.radixPoint) !== -1) {
					var valueParts = initialValue.split(opts.radixPoint),
							decPart    = valueParts[1].match(new RegExp('\\d*'))[0];
					if (parseInt(opts.digits) < decPart.toString().length) {
						var digitsFactor = Math.pow(10, parseInt(opts.digits));
						// make the initialValue a valid javascript number for the parsefloat
						initialValue     = initialValue.replace(InputMaskHelpers.escapeRegex(opts.radixPoint), '.');
						initialValue     = Math.round(parseFloat(initialValue) * digitsFactor) / digitsFactor;
						initialValue     = initialValue.toString().replace('.', opts.radixPoint);
					}
				}
				return initialValue;
			}
			,
			canClearPosition: function (maskset, position, lvp, strict, opts) {
				var vp       = maskset.validPositions[position],
						canClear =
							vp.input !== opts.radixPoint ||
							(maskset.validPositions[position].match.fn !== null && opts.decimalProtect === false) ||
							(vp.input === opts.radixPoint && maskset.validPositions[position + 1] && maskset.validPositions[position + 1].match.fn === null) ||
							isFinite(vp.input) ||
							position === lvp ||
							vp.input === opts.groupSeparator ||
							vp.input === opts.negationSymbol.front ||
							vp.input === opts.negationSymbol.back;

				if (canClear && (vp.match.nativeDef === '+' || vp.match.nativeDef === '-')) {
					opts.isNegative = false;
				}

				return canClear;
			}
			,
			onKeyDown: function (e, buffer, caretPos, opts, unmaskedvalue, setValueEvent) {

				if (e.ctrlKey) {
					switch (e.keyCode) {
						case InputMaskHelpers.keyCode.UP:
							this.value = (parseFloat(unmaskedvalue) + parseInt(opts.step));
							setValueEvent.call(this);

							break;
						case InputMaskHelpers.keyCode.DOWN:
							this.value = (parseFloat(unmaskedvalue) - parseInt(opts.step));
							setValueEvent.call(this);
							break;
					}
				}
			}
		},


		'numeric':    {
			mask:                 (opts) => {
				let autoEscape = (txt) => {

					var escapedTxt = '';
					for (var i = 0; i < txt.length; i++) {
						if (opts.definitions[txt.charAt(i)] ||
							opts.optionalmarker.start === txt.charAt(i) ||
							opts.optionalmarker.end === txt.charAt(i) ||
							opts.quantifiermarker.start === txt.charAt(i) ||
							opts.quantifiermarker.end === txt.charAt(i) ||
							opts.groupmarker.start === txt.charAt(i) ||
							opts.groupmarker.end === txt.charAt(i) ||
							opts.alternatormarker === txt.charAt(i))
							escapedTxt += '\\' + txt.charAt(i);
						else escapedTxt += txt.charAt(i);
					}

					return escapedTxt;
				};

				if (opts.repeat !== 0 && isNaN(opts.integerDigits)) {
					opts.integerDigits = opts.repeat;
				}
				opts.repeat = 0;

				if (<any>this.groupSeparator === ' ') { // prevent conflict with default skipOptionalPartCharacter
					opts.skipOptionalPartCharacter = undefined;
				}
				opts.autoGroup = opts.autoGroup && <any>this.groupSeparator !== '';
				if (opts.autoGroup) {
					if (typeof opts.groupSize === 'string' && isFinite(opts.groupSize)) opts.groupSize = parseInt(opts.groupSize);
					if (isFinite(opts.integerDigits)) {
						var seps           = Math.floor(opts.integerDigits / opts.groupSize);
						var mod            = opts.integerDigits % opts.groupSize;
						opts.integerDigits = parseInt(opts.integerDigits) + (mod === 0 ? seps - 1 : seps);
						if (opts.integerDigits < 1) {
							opts.integerDigits = '*';
						}
					}
				}

				// enforce placeholder to single
				if (opts.placeholder.length > 1) {
					opts.placeholder = opts.placeholder.charAt(0);
				}
				// only allow radixfocus when placeholder = 0
				if (opts.positionCaretOnClick === 'radixFocus' && (opts.placeholder === '' && opts.integerOptional === false)) {
					opts.positionCaretOnClick = 'lvp';
				}
				opts.definitions[';']                  = opts.definitions['~']; // clone integer def for decimals
				opts.definitions[';'].definitionSymbol = '~';

				if (opts.numericInput === true) { // finance people input style
					opts.positionCaretOnClick = opts.positionCaretOnClick === 'radixFocus' ? 'lvp' : opts.positionCaretOnClick;
					opts.digitsOptional       = false;
					if (isNaN(opts.digits)) opts.digits = 2;
					opts.decimalProtect = false;
				}

				var mask = '[+]';
				mask += autoEscape(opts.prefix);

				if (opts.integerOptional === true) {
					mask += '~{1,' + opts.integerDigits + '}';
				} else mask += '~{' + opts.integerDigits + '}';
				if (opts.digits !== undefined) {
					opts.radixPointDefinitionSymbol = opts.decimalProtect ? ':' : this.radixPoint;
					var dq                          = opts.digits.toString().split(',');
					if (isFinite(dq[0] && dq[1] && +isFinite(dq[1]))) {
						mask += opts.radixPointDefinitionSymbol + ';{' + opts.digits + '}';
					} else if (isNaN(opts.digits) || parseInt(opts.digits) > 0) {
						if (opts.digitsOptional) {
							mask += '[' + opts.radixPointDefinitionSymbol + ';{1,' + opts.digits + '}]';
						} else mask += opts.radixPointDefinitionSymbol + ';{' + opts.digits + '}';
					}
				}
				mask += autoEscape(opts.suffix);
				mask += '[-]';

				opts.greedy = false; // enforce greedy false

				// convert min and max options
				if (opts.min !== null) {
					opts.min = opts.min.toString().replace(new RegExp(InputMaskHelpers.escapeRegex(this.groupSeparator), 'g'), '');
					if (this.radixPoint === ',') opts.min = opts.min.replace(this.radixPoint, '.');
				}
				if (opts.max !== null) {
					opts.max = opts.max.toString().replace(new RegExp(InputMaskHelpers.escapeRegex(this.groupSeparator), 'g'), '');
					if (this.radixPoint === ',') opts.max = opts.max.replace(this.radixPoint, '.');
				}
				return mask;
			},
			placeholder:          '_',
			greedy:               false,
			digits:               '*', // number of fractionalDigits
			digitsOptional:       true,
			positionCaretOnClick: 'radixFocus',
			groupSize:            3,
			autoGroup:            false,
			allowPlus:            true,
			allowMinus:           true,
			negationSymbol:       {
				front: '-', // "("
				back:  '' // ")"
			},
			integerDigits:        '+', // number of integerDigits
			integerOptional:      true,
			prefix:               '',
			suffix:               '',
			rightAlign:           true,
			decimalProtect:       true, // do not allow assumption of decimals input without entering the radixpoint
			min:                  null, // minimum value
			max:                  null, // maximum value
			step:                 1,
			insertMode:           true,
			autoUnmask:           false,
			unmaskAsNumber:       true,
			inputmode:            'numeric',
			postFormat:           (buffer, pos, opts) => { // this needs to be removed //  this is crap


				//
				if (opts.numericInput === true) {
					buffer = buffer.reverse();
					if (isFinite(pos)) {
						pos = buffer.join('').length - pos - 1;
					}
				}
				var i, l;

				// position overflow corrections
				pos = pos >= buffer.length ? buffer.length - 1 : (pos < 0 ? 0 : pos);

				var charAtPos = buffer[pos];

				var cbuf = buffer.slice();
				if (charAtPos === this.groupSeparator && pos > opts.prefix.length && pos < (buffer.length - opts.suffix.length)) {
					cbuf.splice(pos--, 1);
					charAtPos = cbuf[pos];
				}

				var isNegative = cbuf.join('').match(new RegExp('^' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front)));
				isNegative     = isNegative !== null && isNegative.length === 1;

				if (pos > ((isNegative ? opts.negationSymbol.front.length : 0) + opts.prefix.length) && (pos < (cbuf.length - opts.suffix.length))) {
					// mark current pos
					cbuf[pos] = '!';
				}
				var bufVal = cbuf.join(''), bufValOrigin = cbuf.join(); // join without args to keep the exact elements

				if (isNegative) {
					bufVal = bufVal.replace(new RegExp('^' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front)), '');
					bufVal = bufVal.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.negationSymbol.back) + '$'), '');
				}

				bufVal = bufVal.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.suffix) + '$'), '');
				bufVal = bufVal.replace(new RegExp('^' + InputMaskHelpers.escapeRegex(opts.prefix)), '');
				if (bufVal.length > 0 && opts.autoGroup || bufVal.indexOf(this.groupSeparator) !== -1) {
					var escapedGroupSeparator = InputMaskHelpers.escapeRegex(this.groupSeparator);
					bufVal                    = bufVal.replace(new RegExp(escapedGroupSeparator, 'g'), '');
					var radixSplit            = bufVal.split(charAtPos === this.radixPoint ? '!' : this.radixPoint);
					bufVal                    = <any>this.radixPoint === '' ? bufVal : radixSplit[0];
					if (charAtPos !== opts.negationSymbol.front) bufVal = bufVal.replace('!', '?');
					if (bufVal.length > opts.groupSize) {
						var reg = new RegExp('([-\+]?[\\d\?]+)([\\d\?]{' + opts.groupSize + '})');
						while (reg.test(bufVal) && <any>this.groupSeparator !== '') {
							bufVal = bufVal.replace(reg, '$1' + this.groupSeparator + '$2');
							bufVal = bufVal.replace(this.groupSeparator + this.groupSeparator, this.groupSeparator);
						}
					}
					bufVal = bufVal.replace('?', '!');
					if (<any>this.radixPoint !== '' && radixSplit.length > 1) {
						bufVal += (charAtPos === this.radixPoint ? '!' : this.radixPoint) + radixSplit[1];
					}
				}

				bufVal = opts.prefix + bufVal + opts.suffix;
				if (isNegative) {
					bufVal = opts.negationSymbol.front + bufVal + opts.negationSymbol.back;
				}

				var needsRefresh = bufValOrigin !== bufVal.split('').join(),
						newPos       = InputMaskHelpers.inArray('!', bufVal);
				if (newPos === -1) newPos = pos;
				if (needsRefresh) {
					buffer.length = bufVal.length; // align the length
					for (i = 0, l = bufVal.length; i < l; i++) {
						buffer[i] = bufVal.charAt(i);
					}
					buffer[newPos] = charAtPos;
				}

				//
				newPos = (opts.numericInput && isFinite(pos)) ? buffer.join('').length - newPos - 1 : newPos;
				if (opts.numericInput) {
					buffer = buffer.reverse();
					if (InputMaskHelpers.inArray(this.radixPoint, buffer) < newPos && (buffer.join('').length - opts.suffix.length) !== newPos) {
						newPos = newPos - 1;
					}
				}

				return {
					pos:                 newPos,
					'refreshFromBuffer': needsRefresh,
					'buffer':            buffer,
					isNegative:          isNegative
				};
			}
			,
			onBeforeWrite: (e, buffer, caretPos, opts) => {


				var rslt;
				if (e && (e.type === 'blur' || e.type === 'checkval' || e.type === 'keydown')) {
					var maskedValue  = opts.numericInput ? buffer.slice().reverse().join('') : buffer.join(''),
							processValue = maskedValue.replace(opts.prefix, '');
					processValue     = processValue.replace(opts.suffix, '');
					processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(this.groupSeparator), 'g'), '');
					if (this.radixPoint === ',') processValue = processValue.replace(this.radixPoint, '.');
					// handle negation symbol
					var isNegative = processValue.match(new RegExp('[-' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + ']', 'g'));
					isNegative     = isNegative !== null && isNegative.length === 1;
					processValue   = processValue.replace(new RegExp('[-' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + ']', 'g'), '');
					processValue   = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.negationSymbol.back) + '$'), '');
					// strip placeholder at the end
					if (isNaN(opts.placeholder)) {
						processValue = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(opts.placeholder), 'g'), '');
					}
					processValue = processValue === opts.negationSymbol.front ? processValue + '0' : processValue;

					if (processValue !== '' && isFinite(processValue)) {
						var floatValue       = parseFloat(processValue),
								signedFloatValue = isNegative ? floatValue * -1 : floatValue;
						if (e.type === 'blur') {
							if (opts.min !== null && isFinite(opts.min) && signedFloatValue < parseFloat(opts.min)) {
								floatValue  = Math.abs(opts.min);
								isNegative  = opts.min < 0;
								maskedValue = undefined;
							} else if (opts.max !== null && isFinite(opts.max) && signedFloatValue > parseFloat(opts.max)) {
								floatValue  = Math.abs(opts.max);
								isNegative  = opts.max < 0;
								maskedValue = undefined;
							}
						}

						processValue = floatValue.toString().replace('.', this.radixPoint).split('');
						if (isFinite(opts.digits)) {
							var radixPosition = InputMaskHelpers.inArray(this.radixPoint, processValue);
							var rpb           = InputMaskHelpers.inArray(this.radixPoint, maskedValue);
							if (radixPosition === -1) {
								processValue.push(this.radixPoint);
								radixPosition = processValue.length - 1;
							}
							for (var i = 1; i <= opts.digits; i++) {
								if (!opts.digitsOptional && (processValue[radixPosition + i] === undefined || processValue[radixPosition + i] === opts.placeholder.charAt(0))) {
									processValue[radixPosition + i] = '0';
								} else if (rpb !== -1 && maskedValue[rpb + i] !== undefined) {
									processValue[radixPosition + i] = processValue[radixPosition + i] || maskedValue[rpb + i];
								}
							}

							if (processValue[processValue.length - 1] === this.radixPoint) {
								delete processValue[processValue.length - 1];
							}
						}

						if ((floatValue.toString() !== processValue && floatValue.toString() + '.' !== processValue) || isNegative) {
							processValue = (opts.prefix + processValue.join('')).split('');
							if (isNegative && (floatValue !== 0 || e.type !== 'blur')) {
								processValue.unshift(opts.negationSymbol.front);
								processValue.push(opts.negationSymbol.back);
							}

							if (opts.numericInput) processValue = processValue.reverse();
							rslt = opts.postFormat(processValue, opts.numericInput ? caretPos : caretPos - 1, opts);
							if (rslt.buffer) rslt.refreshFromBuffer = rslt.buffer.join('') !== buffer.join('');


							return rslt;
						}
					}
				}
				if (opts.autoGroup) {
					rslt       = opts.postFormat(buffer, opts.numericInput ? caretPos : (caretPos - 1), opts);
					rslt.caret =
						((caretPos < (rslt.isNegative ? opts.negationSymbol.front.length : 0) + opts.prefix.length) ||
							(caretPos > (rslt.buffer.length - (rslt.isNegative ? opts.negationSymbol.back.length : 0))))
							? rslt.pos : rslt.pos + 1;


					return rslt;
				}
			},
			regex:         {
				integerPart: (opts) => {
					return new RegExp('[' + InputMaskHelpers.escapeRegex(opts.negationSymbol.front) + '\+]?\\d+');
				}
				,
				integerNPart: (opts) => {
					return new RegExp('[\\d' + InputMaskHelpers.escapeRegex(this.groupSeparator) + InputMaskHelpers.escapeRegex(opts.placeholder.charAt(0)) + ']+');
				}
			},
			signHandler:   (chrs, maskset, pos, strict, opts): any => {
				if (!strict && (opts.allowMinus && chrs === '-') || (opts.allowPlus && chrs === '+')) {
					var matchRslt = maskset.buffer.join('').match(opts.regex.integerPart(opts));

					if (matchRslt && matchRslt[0].length > 0) {
						if (maskset.buffer[matchRslt.index] === (chrs === '-' ? '+' : opts.negationSymbol.front)) {
							if (chrs === '-') {
								if (opts.negationSymbol.back !== '') {
									return {
										'pos':    0,
										'c':      opts.negationSymbol.front,
										'remove': 0,
										'caret':  pos,
										'insert': {
											'pos': maskset.buffer.length - 1,
											'c':   opts.negationSymbol.back
										}
									};
								} else {
									return {
										'pos':    0,
										'c':      opts.negationSymbol.front,
										'remove': 0,
										'caret':  pos
									};
								}
							} else {
								if (opts.negationSymbol.back !== '') {
									return {
										'pos':    0,
										'c':      '+',
										'remove': [0, maskset.buffer.length - 1],
										'caret':  pos
									};
								} else {
									return {
										'pos':    0,
										'c':      '+',
										'remove': 0,
										'caret':  pos
									};
								}
							}
						} else if (maskset.buffer[0] === (chrs === '-' ? opts.negationSymbol.front : '+')) {
							if (chrs === '-' && opts.negationSymbol.back !== '') {
								return {
									'remove': [0, maskset.buffer.length - 1],
									'caret':  pos - 1
								};
							} else {
								return {
									'remove': 0,
									'caret':  pos - 1
								};
							}
						} else {
							if (chrs === '-') {
								if (opts.negationSymbol.back !== '') {
									return {
										'pos':    0,
										'c':      opts.negationSymbol.front,
										'caret':  pos + 1,
										'insert': {
											'pos': maskset.buffer.length,
											'c':   opts.negationSymbol.back
										}
									};
								} else {
									return {
										'pos':   0,
										'c':     opts.negationSymbol.front,
										'caret': pos + 1
									};
								}
							} else {
								return {
									'pos':   0,
									'c':     chrs,
									'caret': pos + 1
								};
							}
						}
					}
				}
				return false;
			}
			,
			radixHandler:       (chrs, maskset, pos, strict, opts): any => {
				if (!strict && opts.numericInput !== true) {
					// if ($.inArray(chrs, [",", "."]) !== -1) chrs = this.radixPoint;
					if (chrs === this.radixPoint && (opts.digits !== undefined && (isNaN(opts.digits) || parseInt(opts.digits) > 0))) {
						var radixPos     = InputMaskHelpers.inArray(this.radixPoint, maskset.buffer),
								integerValue = maskset.buffer.join('').match(opts.regex.integerPart(opts));

						if (radixPos !== -1 && maskset.validPositions[radixPos]) {
							if (maskset.validPositions[radixPos - 1]) {
								return {
									'caret': radixPos + 1
								};
							} else {
								return {
									'pos':   integerValue.index,
									c:       integerValue[0],
									'caret': radixPos + 1
								};
							}
						} else if (!integerValue || (integerValue['0'] === '0' && (integerValue.index + 1) !== pos)) {
							maskset.buffer[integerValue ? integerValue.index : pos] = '0';
							return {
								'pos': (integerValue ? integerValue.index : pos) + 1,
								c:     this.radixPoint
							};
						}
					}
				}
				return false;
			},
			leadingZeroHandler: (chrs, maskset, pos, strict, opts, isSelection): any => {
				if (!strict) {
					var initialPos = pos,
							buffer     = opts.numericInput === true ? maskset.buffer.slice('').reverse() : maskset.buffer.slice('');
					if (opts.numericInput) {
						pos = buffer.join('').length - pos - 1;
					}
					buffer.splice(0, opts.prefix.length);
					buffer.splice(buffer.length - opts.suffix.length, opts.suffix.length);

					pos               = pos - opts.prefix.length;
					var radixPosition = InputMaskHelpers.inArray(this.radixPoint, buffer),
							matchRslt     = buffer.slice(0, radixPosition !== -1 ? radixPosition : undefined).join('').match(opts.regex.integerNPart(opts));
					if (matchRslt && (radixPosition === -1 || pos <= radixPosition || opts.numericInput)) {
						var decimalPart = radixPosition === -1 ? 0 : parseInt(buffer.slice(radixPosition + 1).join('')),
								leadingZero = matchRslt['0'].indexOf(opts.placeholder !== '' ? opts.placeholder.charAt(0) : '0') === 0;
						if (opts.numericInput) {
							if (leadingZero && decimalPart !== 0 && isSelection !== true) {
								maskset.buffer.splice((buffer.length - matchRslt.index - 1) + opts.suffix.length, 1);
								return {
									'pos':    initialPos,
									'remove': (buffer.length - matchRslt.index - 1) + opts.suffix.length
								};
							}
						} else {
							if (leadingZero && (matchRslt.index + 1 === pos || (isSelection !== true && decimalPart === 0))) {
								maskset.buffer.splice(matchRslt.index + opts.prefix.length, 1);
								return {
									'pos':    matchRslt.index + opts.prefix.length,
									'remove': matchRslt.index + opts.prefix.length
								};
							} else if (chrs === '0' && (isSelection !== true && matchRslt['0'] !== opts.placeholder) && pos <= matchRslt.index && matchRslt['0'] !== this.groupSeparator) {
								return false;
							}
						}
					}
				}
				return true;
			},
			definitions:        {
				'~': {
					validator: (chrs, maskset, pos, strict, opts, isSelection) => {
						var isValid = opts.signHandler(chrs, maskset, pos, strict, opts);
						if (!isValid) {
							isValid = opts.radixHandler(chrs, maskset, pos, strict, opts);
							if (!isValid) {
								isValid = strict ? new RegExp('[0-9' + InputMaskHelpers.escapeRegex(this.groupSeparator) + ']').test(chrs) : new RegExp('[0-9]').test(chrs);
								if (isValid === true) {
									isValid = opts.leadingZeroHandler(chrs, maskset, pos, strict, opts, isSelection);
									if (isValid === true && opts.numericInput !== true) {
										// handle overwrite when fixed precision
										var radixPosition = InputMaskHelpers.inArray(this.radixPoint, maskset.buffer);
										if (radixPosition !== -1 && (opts.digitsOptional === false || maskset.validPositions[pos]) && opts.numericInput !== true && pos > radixPosition && !strict) {
											isValid = {
												'pos':    pos,
												'remove': pos
											};
										} else {
											isValid = {
												pos: pos
											};
										}
									}
								}
							}
						}

						return isValid;
					}
					,
					cardinality: 1
				}
				,
				'+': {
					validator: (chrs, maskset, pos, strict, opts) => {
						var isValid = opts.signHandler(chrs, maskset, pos, strict, opts);
						if (!isValid && ((strict && opts.allowMinus && chrs === opts.negationSymbol.front) || (opts.allowMinus && chrs === '-') || (opts.allowPlus && chrs === '+'))) {
							if (!strict && chrs === '-') {
								if (opts.negationSymbol.back !== '') {
									isValid = {
										'pos':    pos,
										'c':      chrs === '-' ? opts.negationSymbol.front : '+',
										'caret':  pos + 1,
										'insert': {
											'pos': maskset.buffer.length,
											'c':   opts.negationSymbol.back
										}
									};
								} else {
									isValid = {
										'pos':   pos,
										'c':     chrs === '-' ? opts.negationSymbol.front : '+',
										'caret': pos + 1
									};
								}
							} else {
								isValid = true;
							}
						}
						return isValid;
					}
					,
					cardinality: 1,
					placeholder: ''
				},
				'-': {
					validator: (chrs, maskset, pos, strict, opts) => {
						var isValid = opts.signHandler(chrs, maskset, pos, strict, opts);
						if (!isValid && strict && opts.allowMinus && chrs === opts.negationSymbol.back) {
							isValid = true;
						}
						return isValid;
					}
					,
					cardinality: 1,
					placeholder: ''
				}
				,
				':': {
					validator:   (chrs, maskset, pos, strict, opts) => {
						var isValid = opts.signHandler(chrs, maskset, pos, strict, opts);
						if (!isValid) {
							var radix = '[' + InputMaskHelpers.escapeRegex(this.radixPoint) + ']';
							isValid   = new RegExp(radix).test(chrs);
							if (isValid && maskset.validPositions[pos] && maskset.validPositions[pos].match.placeholder === this.radixPoint) {
								isValid = {
									'caret': pos + 1
								};
							}
						}
						return isValid;
					},
					cardinality: 1,
					placeholder: (opts) => {
						return this.radixPoint;
					}
				}
			}
			,
			onUnMask: (maskedValue, unmaskedValue, opts) => {
				if (unmaskedValue === '' && opts.nullable === true) {
					return unmaskedValue;
				}
				var processValue = maskedValue.replace(opts.prefix, '');
				processValue     = processValue.replace(opts.suffix, '');
				processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(this.groupSeparator), 'g'), '');
				if (opts.unmaskAsNumber) {
					if (<any>this.radixPoint !== '' && processValue.indexOf(this.radixPoint) !== -1) processValue = processValue.replace(InputMaskHelpers.escapeRegex.call(this, this.radixPoint), '.');
					return Number(processValue);
				}
				return processValue;
			}
			,
			isComplete: (buffer, opts) => {
				var maskedValue = buffer.join(''),
						bufClone    = buffer.slice();
				// verify separator positions
				opts.postFormat(bufClone, 0, opts);
				if (bufClone.join('') !== maskedValue) return false;

				var processValue = maskedValue.replace(opts.prefix, '');
				processValue     = processValue.replace(opts.suffix, '');
				processValue     = processValue.replace(new RegExp(InputMaskHelpers.escapeRegex(this.groupSeparator), 'g'), '');
				if (this.radixPoint === ',') processValue = processValue.replace(InputMaskHelpers.escapeRegex(this.radixPoint), '.');
				return isFinite(processValue);
			}
			,
			onBeforeMask:     (initialValue, opts) => {
				initialValue = initialValue.toString();
				if (opts.numericInput === true) {
					initialValue = initialValue.split('').reverse().join('');
				}
				if (<any>this.radixPoint !== '' && isFinite(initialValue)) {
					var vs        = initialValue.split('.'),
							groupSize = <any>this.groupSeparator !== '' ? parseInt(opts.groupSize) : 0;
					if (vs.length === 2 && (vs[0].length > groupSize || vs[1].length > groupSize))
						initialValue = initialValue.replace('.', this.radixPoint);
				}
				var kommaMatches = initialValue.match(/,/g);
				var dotMatches   = initialValue.match(/\./g);
				if (dotMatches && kommaMatches) {
					if (dotMatches.length > kommaMatches.length) {
						initialValue = initialValue.replace(/\./g, '');
						initialValue = initialValue.replace(',', this.radixPoint);
					} else if (kommaMatches.length > dotMatches.length) {
						initialValue = initialValue.replace(/,/g, '');
						initialValue = initialValue.replace('.', this.radixPoint);
					} else { // equal
						initialValue = initialValue.indexOf('.') < initialValue.indexOf(',') ? initialValue.replace(/\./g, '') : initialValue = initialValue.replace(/,/g, '');
					}
				} else {
					initialValue = initialValue.replace(new RegExp(InputMaskHelpers.escapeRegex(this.groupSeparator), 'g'), '');
				}

				if (opts.digits === 0) {
					if (initialValue.indexOf('.') !== -1) {
						initialValue = initialValue.substring(0, initialValue.indexOf('.'));
					} else if (initialValue.indexOf(',') !== -1) {
						initialValue = initialValue.substring(0, initialValue.indexOf(','));
					}
				}

				if (<any>this.radixPoint !== '' && isFinite(opts.digits) && initialValue.indexOf(this.radixPoint) !== -1) {
					var valueParts = initialValue.split(this.radixPoint),
							decPart    = valueParts[1].match(new RegExp('\\d*'))[0];
					if (parseInt(opts.digits) < decPart.toString().length) {
						var digitsFactor = Math.pow(10, parseInt(opts.digits));
						// make the initialValue a valid javascript number for the parsefloat
						initialValue     = initialValue.replace(InputMaskHelpers.escapeRegex(this.radixPoint), '.');
						initialValue     = Math.round(parseFloat(initialValue) * digitsFactor) / digitsFactor;
						initialValue     = initialValue.toString().replace('.', this.radixPoint);
					}
				}

				if (opts.numericInput === true) {
					initialValue = initialValue.split('').reverse().join('');
				}
				return initialValue;
			},
			canClearPosition: (maskset, position, lvp, strict, opts) => {
				var positionInput = maskset.validPositions[position].input,
						canClear      = ((positionInput !== this.radixPoint || (maskset.validPositions[position].match.fn !== null && opts.decimalProtect === false)) || isFinite(positionInput)) ||
							position === lvp ||
							positionInput === this.groupSeparator ||
							positionInput === opts.negationSymbol.front ||
							positionInput === opts.negationSymbol.back;
				return canClear;
			},
			onKeyDown:        function (e, buffer, caretPos, opts, unmaskedvalue, setValueEvent) {


				// var $input = $(this);
				if (e.ctrlKey) {
					switch (e.keyCode) {
						case InputMaskHelpers.keyCode.UP:
							this.value = (parseFloat(unmaskedvalue) + parseInt(opts.step));
							setValueEvent.call(this);

							break;
						case InputMaskHelpers.keyCode.DOWN:
							this.value = (parseFloat(unmaskedvalue) - parseInt(opts.step));
							setValueEvent.call(this);
							break;
					}
				}
			}
		},
		'currency':   {
			prefix:               '$ ',
			groupSeparator:       ',',
			alias:                'numeric',
			placeholder:          '_',
			autoGroup:            true,
			digits:               2,
			digitsOptional:       false,
			clearMaskOnLostFocus: false
		},
		'decimal':    {
			alias:          'numeric',
			allowPlus:      false,
			allowMinus:     false,
			radixPoint:     '.',
			placeholder:    '_',
			autoGroup:      true,
			min:            0,
			max:            null,
			digits:         2,
			digitsOptional: false,
			integerDigits:  '+'
		},
		'integer':    {
			alias:      'numeric',
			digits:     0,
			min:        0,
			radixPoint: ''
		},
		'percentage': {
			alias:          'numeric',
			radixPoint:     '.',
			placeholder:    '_',
			autoGroup:      false,
			min:            0,
			max:            100,
			suffix:         ' %',
			allowPlus:      false,
			allowMinus:     false,
			digits:         5,
			digitsOptional: false,
			integerDigits:  3
		},
		'url':        {
			definitions: {
				'i': {
					validator:   '.',
					cardinality: 1
				}
			},
			mask:        '(\\http:// )|(\\http\\s:// )|(ftp:// )|(ftp\\s:// )i{+}',
			insertMode:  false,
			autoUnmask:  false,
			inputmode:   'url'
		},
		'ip':         { // ip-address mask
			mask:        'i[i[i]].i[i[i]].i[i[i]].i[i[i]]',
			definitions: {
				'i': {
					validator:   function (chrs, maskset, pos, strict, opts) {
						if (pos - 1 > -1 && maskset.buffer[pos - 1] !== '.') {
							chrs = maskset.buffer[pos - 1] + chrs;
							if (pos - 2 > -1 && maskset.buffer[pos - 2] !== '.') {
								chrs = maskset.buffer[pos - 2] + chrs;
							} else chrs = '0' + chrs;
						} else chrs = '00' + chrs;
						return new RegExp('25[0-5]|2[0-4][0-9]|[01][0-9][0-9]').test(chrs);
					},
					cardinality: 1
				}
			},
			onUnMask:    function (maskedValue, unmaskedValue, opts) {
				return maskedValue;
			},
			inputmode:   'numeric'
		},
		'email':      {
			// https:// en.wikipedia.org/wiki/Domain_name#Domain_name_space
			// https:// en.wikipedia.org/wiki/Hostname#Restrictions_on_valid_host_names
			// should be extended with the toplevel domains at the end
			mask:          '*{1,64}[.*{1,64}][.*{1,64}][.*{1,63}]@-{1,63}.-{1,63}[.-{1,63}][.-{1,63}]',
			greedy:        false,
			onBeforePaste: function (pastedValue, opts) {
				pastedValue = pastedValue.toLowerCase();
				return pastedValue.replace('mailto:', '');
			},
			definitions:   {
				'*': {
					validator:   '[0-9A-Za-z!#$%&\' * +/=?^_`{|}~\-]',
					cardinality: 1,
					casing:      'lower'
				},
				'-': {
					validator:   '[0-9A-Za-z\-]',
					cardinality: 1,
					casing:      'lower'
				}
			},
			onUnMask:      function (maskedValue, unmaskedValue, opts) {
				return maskedValue;
			},
			inputmode:     'email'
		},
		'mac':        {
			mask: '##:##:##:##:##:##'
		},
		'awb':        {
			mask:         '### ########',
			positionCaretOnClick: 'lvp',
			onBeforeMask: function (unmaskedValue, opts) {
				const amountOfCharacters  = (opts.mask.match(new RegExp('#', 'g')) || []).length;
				const headedZerosRequired = amountOfCharacters - unmaskedValue.length;
				for (let i = 0; i < headedZerosRequired; i++)
					//unmaskedValue = (0 + unmaskedValue); //do not fill default with zeros

				return unmaskedValue;
			}
		},
		'Regex':      {
			mask:        'r',
			greedy:      false,
			repeat:      '*',
			regex:       null,
			regexTokens: null,
			// Thx to https:// github.com/slevithan/regex-colorizer for the tokenizer regex
			tokenizer:        /\[\^?]?(?:[^\\\]]+|\\[\S\s]?)*]?|\\(?:0(?:[0-3][0-7]{0,2}|[4-7][0-7]?)?|[1-9][0-9]*|x[0-9A-Fa-f]{2}|u[0-9A-Fa-f]{4}|c[A-Za-z]|[\S\s]?)|\((?:\?[:=!]?)?|(?:[?*+]|\{[0-9]+(?:,[0-9]*)?\})\??|[^.?*+^${[()|\\]+|./g,
			quantifierFilter: /[0-9]+[^,]/,
			isComplete:       function (buffer, opts) {
				return new RegExp(opts.regex, opts.casing ? 'i' : '').test(buffer.join(''));
			},
			definitions:      {
				'r': {
					validator:   function (chrs, maskset, pos, strict, opts) {
						var cbuffer        = maskset.buffer.slice(),
								bufferStr,
								regexPart      = '',
								isValid        = false,
								openGroupCount = 0,
								groupToken;

						function RegexToken(isGroup?, isQuantifier?) {
							this.matches      = [];
							this.isGroup      = isGroup || false;
							this.isQuantifier = isQuantifier || false;
							this.quantifier   = {
								min: 1,
								max: 1
							};
							this.repeaterPart = undefined;
						}

						function analyseRegex() {
							var currentToken         = new RegexToken(),
									match, m, opengroups = [];

							opts.regexTokens = [];

							//  The tokenizer regex does most of the tokenization grunt work
							while (match = opts.tokenizer.exec(opts.regex)) {
								m = match[0];
								switch (m.charAt(0)) {
									case '(': //  Group opening
										opengroups.push(new RegexToken(true));
										break;
									case ')': //  Group closing
										groupToken = opengroups.pop();
										if (opengroups.length > 0) {
											opengroups[opengroups.length - 1].matches.push(groupToken);
										} else {
											currentToken.matches.push(groupToken);
										}
										break;
									case '{':
									case '+':
									case '*': // Quantifier
										var quantifierToken        = new RegexToken(false, true);
										m                          = m.replace(/[{}]/g, '');
										var mq                     = m.split(','),
												mq0                    = isNaN(mq[0]) ? mq[0] : parseInt(mq[0]),
												mq1                    = mq.length === 1 ? mq0 : (isNaN(mq[1]) ? mq[1] : parseInt(mq[1]));
										quantifierToken.quantifier = {
											min: mq0,
											max: mq1
										};
										if (opengroups.length > 0) {
											var matches = opengroups[opengroups.length - 1].matches;
											match       = matches.pop();
											if (!match.isGroup) {
												groupToken = new RegexToken(true);
												groupToken.matches.push(match);
												match = groupToken;
											}
											matches.push(match);
											matches.push(quantifierToken);
										} else {
											match = currentToken.matches.pop();
											if (!match.isGroup) {
												groupToken = new RegexToken(true);
												groupToken.matches.push(match);
												match = groupToken;
											}
											currentToken.matches.push(match);
											currentToken.matches.push(quantifierToken);
										}
										break;
									default:
										if (opengroups.length > 0) {
											opengroups[opengroups.length - 1].matches.push(m);
										} else {
											currentToken.matches.push(m);
										}
										break;
								}
							}

							if (currentToken.matches.length > 0) {
								opts.regexTokens.push(currentToken);
							}
						}

						function validateRegexToken(token, fromGroup) {
							var isvalid = false;
							if (fromGroup) {
								regexPart += '(';
								openGroupCount++;
							}
							for (var mndx = 0; mndx < token.matches.length; mndx++) {
								var matchToken = token.matches[mndx];
								if (matchToken.isGroup === true) {
									isvalid = validateRegexToken(matchToken, true);
								} else if (matchToken.isQuantifier === true) {
									var crrntndx     = InputMaskHelpers.inArray(matchToken, token.matches),
											matchGroup   = token.matches[crrntndx - 1];
									var regexPartBak = regexPart;
									if (isNaN(matchToken.quantifier.max)) {
										while (matchToken.repeaterPart && matchToken.repeaterPart !== regexPart && matchToken.repeaterPart.length > regexPart.length) {
											isvalid = validateRegexToken(matchGroup, true);
											if (isvalid) break;
										}
										isvalid = isvalid || validateRegexToken(matchGroup, true);
										if (isvalid) matchToken.repeaterPart = regexPart;
										regexPart = regexPartBak + matchToken.quantifier.max;
									} else {
										for (var i = 0, qm = matchToken.quantifier.max - 1; i < qm; i++) {
											isvalid = validateRegexToken(matchGroup, true);
											if (isvalid) break;
										}
										regexPart = regexPartBak + '{' + matchToken.quantifier.min + ',' + matchToken.quantifier.max + '}';
									}
								} else if (matchToken.matches !== undefined) {
									for (var k = 0; k < matchToken.length; k++) {
										isvalid = validateRegexToken(matchToken[k], fromGroup);
										if (isvalid) break;
									}
								} else {
									var testExp;
									if (matchToken.charAt(0) === '[') {
										testExp = regexPart;
										testExp += matchToken;
										for (var j = 0; j < openGroupCount; j++) {
											testExp += ')';
										}
										var exp = new RegExp('^(' + testExp + ')$', opts.casing ? 'i' : '');
										isvalid = exp.test(bufferStr);
									} else {
										for (var l = 0, tl = matchToken.length; l < tl; l++) {
											if (matchToken.charAt(l) === '\\') continue;
											testExp = regexPart;
											testExp += matchToken.substr(0, l + 1);
											testExp = testExp.replace(/\|$/, '');
											for (var j = 0; j < openGroupCount; j++) {
												testExp += ')';
											}
											var exp = new RegExp('^(' + testExp + ')$', opts.casing ? 'i' : '');
											isvalid = exp.test(bufferStr);
											if (isvalid) break;
										}
									}
									regexPart += matchToken;
								}
								if (isvalid) break;
							}

							if (fromGroup) {
								regexPart += ')';
								openGroupCount--;
							}

							return isvalid;
						}

						if (opts.regexTokens === null) {
							analyseRegex();
						}

						cbuffer.splice(pos, 0, chrs);
						bufferStr = cbuffer.join('');
						for (var i = 0; i < opts.regexTokens.length; i++) {
							var regexToken = opts.regexTokens[i];
							isValid        = validateRegexToken(regexToken, regexToken.isGroup);
							if (isValid) break;
						}

						return isValid;
					},
					cardinality: 1
				}
			}
		}
	};
}

