import { Directive, ElementRef, Input, AfterViewInit, HostBinding, OnChanges, NgZone } from '@angular/core';
import { ComponentChanges, whenChanging, generateQuickGuid }                           from '@cs/core';

@Directive({
						 selector: '[csLengthRuler]',
						 exportAs: 'csLengthRulerRef'
					 })
export class CsLengthRulerDirective implements AfterViewInit,
																							 OnChanges {


	get currentHeight(): number {
		return this._currentHeight;
	}


	constructor(private _elementRef: ElementRef, private ngZone: NgZone) {

	}

	ngAfterViewInit(): void {
		this.init();
	}


	ngOnChanges(changes: ComponentChanges<CsLengthRulerDirective>): void {

	}

	private _currentHeight: number;

	private updateAvailableHeight(): number {


		const rulerElement = this._elementRef.nativeElement as HTMLElement;
		if (rulerElement)
			return rulerElement.clientHeight;
		else
			throw new Error('Ruler element is not found');
	}

	update() {
		this.currentHeight = this.updateAvailableHeight();

	}

	/**
	 * Get the initial width
	 */
	private init() {
		this.currentHeight = this.updateAvailableHeight();
	}

	private set currentHeight(value: number) {
		this._currentHeight = value;
	}
}
