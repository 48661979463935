<div class="mt-4 managed-by-me-page">
  <div [@isLoadingContent]="!isLoadingContent">
    <cs-table-nxt [data]="data"
                  [filter]="false"

                  [selectable]="false"
    >
    </cs-table-nxt>
  </div>
</div>
