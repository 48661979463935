<div class="wrapper">
	<i *ngIf="pdfLoaded"
	   class="mdi mdi-download download-icon"
	   (click)="download()"></i>
	<pdf-viewer #pdf
				[src]="{url: src,
                withCredentials: true}"
				(after-load-complete)="onLoaded($event)"
				(error)="onError($event)">
	</pdf-viewer>
</div>

