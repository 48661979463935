import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }        from '@angular/material/dialog';
import { CsFormGeneratorComponent }             from '@cs/components/form-generator';
import { createToObjectWithLowerCaseKeys }      from '@cs/components/util';
import { isNullOrUndefined }                    from '@cs/core';
import { isNumber }                             from '@cs/core/utils';
import { CSAddMemberDialog }                    from './data-grid-add-member-dialog.models';
import { IAddMemberDialogData }                 from './data-grid-add-member-dialog.interfaces';
import { DataEntryConfigService }               from '../../data-entry-config.service';
import { ElementItem, FormDefinitionForm }      from '@cs/performance-manager/shared';
import { BehaviorSubject }                      from 'rxjs';
import { tap }                                  from 'rxjs/operators';
import { WaitingForResponse }                   from '@cs/common';


@Component({
			   selector:    'pmc-data-grid-add-member-dialog',
			   templateUrl: './data-grid-add-member-dialog.component.html',
			   styleUrls:   ['./data-grid-add-member-dialog.component.scss'],
			   host:        {
				   class: 'add-member-dialog-container'
			   }
		   })

/**
 * Dialog wrapped around the form generator component.
 * Returns form group value(s) when form validation is successful.
 */
export class DataGridAddMemberDialogComponent implements OnInit {

	@ViewChild('form', {static: false}) form: CsFormGeneratorComponent;

	dialog: CSAddMemberDialog = null;

	hasDialogData                                = false;
	requestInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


	constructor(public dialogRef: MatDialogRef<DataGridAddMemberDialogComponent>,
				@Inject(MAT_DIALOG_DATA) public data: IAddMemberDialogData,
				public datagridService: DataEntryConfigService) {
		// empty constructor
	}

	ngOnInit() {
		this.hasDialogData = false;

		this.datagridService.getAddMemberForm(this.data.gridName, this.data.selection, this.data.memberList, this.data.params)
			.pipe(tap(WaitingForResponse.new(isLoading => this.requestInProgress$.next(isLoading))))
			.subscribe(
				response => {
					const result = response.value;
					this.dialog  = new CSAddMemberDialog(result);

					if (isNullOrUndefined(this.dialog.formDefinition))
						return;

					this.hasDialogData = true;

					// Disable form element(s) for which there is already a value
					const keys = createToObjectWithLowerCaseKeys(this.data.keys);
					for (const key of Object.keys(this.dialog.formDefinition.data)) {
						if (keys.hasOwnProperty(key.toLowerCase()) && !isNullOrUndefined(keys[key.toLowerCase()])) {
							this.dialog.formDefinition.data[key] = keys[key.toLowerCase()];
							this.disableFormElement(this.dialog.formDefinition.form, key);
						}
					}
				});
	}


	onOkClick(): void {
		// pass form value(s) when it's valid
		if (this.form.formGroup.valid) {
			// pass all form control values, even the disables ones
			this.dialogRef.close({action: 'yes', data: this.parseFormValues(this.form.formGroup.getRawValue())});
		}
	}

	onNoClick(): void {
		this.onCloseClick();
	}

	onCloseClick(): void {
		this.dialogRef.close(null);
	}

	/**
	 * Parse form values: we are expected to ids, convert them to numbers
	 */
	parseFormValues(val: any): any {
		for (const key of Object.keys(val)) {
			if (isNumber(val[key]))
				val[key] = +val[key];
		}
		return val;
	}

	/**
	 * Modify incoming form definition and disable the specified element
	 */
	private disableFormElement(form: FormDefinitionForm, name: string) {
		let elementItem: ElementItem = null;
		form.elements.some(x => x.elements.some(y => {
			if (y.name.toLowerCase() === name.toLowerCase()) {
				elementItem = y;
				return true;
			}
			return false;
		}));
		if (!isNullOrUndefined(elementItem)) {
			elementItem.actions.push({
										 event:     'onInit'
										 , actions: ['disableField']
									 });
		}
	}


}
