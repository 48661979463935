import { Inject, Injectable } from '@angular/core';

import { CsCultureProvider }                from '@cs/common';
import { NgbDatepickerI18n, NgbDateStruct } from '../datepicker-core';

const I18N_VALUES = {
	en: {
		weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
		months:   ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
		monthsFull:
							['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
	},
	nl: {
		weekdays: ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'],
		months:   ['jan', 'feb', 'mrt', 'apr', 'mei', 'jun', 'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
		monthsFull:
							['januari', 'februari', 'maart', 'april', 'mei', 'juni', 'juli', 'augustus', 'september', 'oktober', 'november', 'december']
	}
};

@Injectable()
export class CsDatepickerI18n extends NgbDatepickerI18n {
	constructor(@Inject(CsCultureProvider) private csCultureProvider: CsCultureProvider) {
		super();
	}

	private get language() {
		return this.csCultureProvider.getCulture().split('-')[0].toLowerCase();
	}

	getWeekdayShortName(weekday: number): string {
		return I18N_VALUES[this.language].weekdays[weekday - 1];
	}

	getMonthShortName(month: number): string {
		return I18N_VALUES[this.language].months[month - 1];
	}

	getMonthFullName(month: number): string {
		return I18N_VALUES[this.language].monthsFull[month - 1];
	}

	getDayAriaLabel(date: NgbDateStruct): string {
		return '';
	}
}

