import { DataDescribed, pathChecked, PropertyAnnotation } from '@cs/core';
import { DashboardPanelMetaInfo }                         from './dashboard-panel-meta-info';
import { DashboardPanelType }                             from './dashboard-panel-type';
import { IDashboardPanel }                                from './i-dashboard-panel';
import { PanelOption }                                    from './panel-option';

/**
 * Dashboard panel information used to render data panels in the dashboard Grid
 */
export class DashboardPanel<T = any, TComponent = any> implements IDashboardPanel {
	/**
	 * Display label for UI
	 */
	label: string;
	/**
	 * Unique name for programming purposes
	 */
	name: string;
	/**
	 * Flag for showing the drop-shadow
	 */
	hasShadow = true;
	/**
	 * Set the data type of the Panel so the grid wil load the corresponding handler
	 */
	type: DashboardPanelType;
	/**
	 * Specifies the index of the slot where the panel should be injected
	 */
	gridSlot: number;
	/**
	 * Data used by the panel. Is an generic type
	 */
	data: T;
	/**
	 * Component to load
	 */
	component?: TComponent;
	/**
	 * Css class for the container
	 */
	class?: string;

	/**
	 * Will be used to show options for the panel. Like a other view of the panel.
	 */
	options?: DataDescribed<unknown>;
	_panelOptions: PanelOption[];

	/**
	 * Panel loader flag, indicates is the panel is loading
	 */
	isPanelLoading = false;

	get panelOptions() {
		if (!this._panelOptions)
			this._panelOptions = this.getPanelOptions();

		return this._panelOptions;
	}

	/**
	 * Show a line of information for the panel that is persistent
	 */
	note?: string;

	/**
	 * Extra information about the panel
	 */
	meta?: DashboardPanelMetaInfo;
	/**
	 * Reason used to set a class styling the panel accordingly
	 */
	reason?: string;


	isTruncated = false;

	get hasFooter() {
		return (this.options != null && this.options.dataAnnotation.fields.some(value => value.visible)) || this.note != null;
	}

	constructor(init: IDashboardPanel) {
		Object.assign(this, init);
	}

	private getPanelOptions(): PanelOption[] {
		const fields = pathChecked(this.options, ['dataAnnotation', 'fields'], []) as PropertyAnnotation<any>[];

		const allVisibleFields = fields.filter(field => field.visible);

		return allVisibleFields.map(value => new PanelOption({propertyAnnotation: value, id: value.id}));

	}

}
