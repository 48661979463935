import { FormatProviderService }         from '@cs/common/culture';
import { LoggerUtil }                    from './logger.util';
import { createObjectWithLowerCaseKeys } from './object.utils';

/**
	*  Replace the placeholders like: :idCustomer and provide 'idCustomer' as the value as the first group
	*/
export const PlaceholderRegexPathUrlVariable = /:(.\w+)/g;

/**
	* Replace the placeholders like: ${idCustomer} and provide 'idCustomer' as the value as the first group
	*/
export const PlaceholderRegexStringInterpolation = /\$\{([^}]+)\}/g;

export function toCamelCase(str: string) {
	return str.charAt(0)
											.toLowerCase() + str.slice(1);
}

export function toKebabCase(str: string) {
	return str.replace(/([a-z])([A-Z])/g, '$1-$2')
											.toLowerCase();
}

export function isBase64(v, opts) {
	if (v instanceof Boolean || typeof v === 'boolean') {
		return false;
	}

	if (!(opts instanceof Object)) {
		opts = {};
	}

	if (opts.allowEmpty === false && v === '') {
		return false;
	}

	let regex       = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}==|[A-Za-z0-9+\/]{3}=)?';
	const mimeRegex = '(data:\\w+\\/[a-zA-Z\\+\\-\\.]+;base64,)';

	if (opts.mimeRequired === true) {
		regex = mimeRegex + regex;
	} else if (opts.allowMime === true) {
		regex = mimeRegex + '?' + regex;
	}

	if (opts.paddingRequired === false) {
		regex = '(?:[A-Za-z0-9+\\/]{4})*(?:[A-Za-z0-9+\\/]{2}(==)?|[A-Za-z0-9+\\/]{3}=?)?';
	}

	return (new RegExp('^' + regex + '$', 'gi')).test(v);
}


/**
	* Replace placeholders in a string with the values found in the replacement object.
	* @param replacementObject The object {[placeholdername: string] : value}
	* @param stringToReplace String that should contain the placeholders
	* @param placeholderRegex The regular expression that returns for the full match ( ${idCustomer} )
	* and the first group containing the placeholderName ( idCustomer )
	*/
export function replacePlaceholders(replacementObject        = null, stringToReplace: string,
																																				placeholderRegex: RegExp = PlaceholderRegexStringInterpolation) {

	const replacements = createObjectWithLowerCaseKeys(replacementObject);
	if (replacements) {
		const replaceRegex = placeholderRegex;
		let replaceMatch   = replaceRegex.exec(stringToReplace);
		while (replaceMatch != null) {
			const replaceParam = replaceMatch[0];
			const replacement  = replacements[replaceMatch[1].toLowerCase()];
			if (replacement != null) {
				stringToReplace = stringToReplace.replace(replaceParam, replacement);
			} else {
				LoggerUtil.warn(`${replacement} not found`);
			}
			replaceMatch = replaceRegex.exec(stringToReplace);
		}
	}
	return stringToReplace;
}

export const PlaceholderRegexStringInterpolationWithFormat = /\${([^|}]+)}(?:\s*\|\s*(\w+)\s*(?::\s*'([^']*)')?)?/g;

/**
	* Replace placeholders in a string with the values found in the replacement object.
	* @param replacementObject The object {[placeholdername: string] : value}
	* @param stringToReplace String that should contain the placeholders
	* @param placeholderRegex The regular expression that returns for the full match ( ${idCustomer} )
	* and the first group containing the placeholderName ( idCustomer )
	*/
export function replacePlaceholdersWithExpressions(
	replacementObject        = null,
	stringToReplace: string,
	formatService: any       = null,
	placeholderRegex: RegExp = PlaceholderRegexStringInterpolationWithFormat
) {
	const replacements = createObjectWithLowerCaseKeys(replacementObject);

	if (replacements) {
		const replaceRegex = placeholderRegex;

		while (true) {
			replaceRegex.lastIndex = 0; // Reset the regex position

			const replaceMatch = replaceRegex.exec(stringToReplace);
			if (!replaceMatch) break; // No more matches found

			const replaceParam = replaceMatch[0];
			const expression   = replaceMatch[1];
			const pipeName     = replaceMatch[2];
			const formatArgs   = replaceMatch[3];

			try {
				const replacement        = evaluateExpressionOrGetValue(expression, replacements);
				let formattedReplacement = replacement;
				if (pipeName != null) {
					formattedReplacement = applyPipe(replacement, pipeName, formatArgs, formatService);
				}

				if (replacement != null) {
					stringToReplace = stringToReplace.replace(replaceParam, formattedReplacement);
				} else {
					LoggerUtil.warn(`${replaceParam} not found`);
				}
			} catch (error) {
				LoggerUtil.warn(`Error evaluating expression: ${expression}`);
			}
		}
	}

	return stringToReplace;
}


function evaluateExpressionOrGetValue(expression: string, replacements: any): any {
	// Tokenize the expression
	const tokens = tokenize(expression);

	// If it's a single token and exists in replacements, return its value
	if (tokens.length === 1 && replacements.hasOwnProperty(tokens[0].toLowerCase())) {
		return replacements[tokens[0].toLowerCase()];
	}

	// Otherwise, replace variables and evaluate the expression
	const evaluatedTokens = tokens.map(token => {
		if (replacements.hasOwnProperty(token.toLowerCase())) {
			return replacements[token.toLowerCase()];
		}
		return token;
	});

	// Parse and evaluate the expression
	return parseExpression(evaluatedTokens);
}

function tokenize(expression: string): string[] {
	return expression.split(/([+\-*/()])/)
																		.map(token => token.trim())
																		.filter(token => token !== '');
}

function parseExpression(tokens: string[]): number {
	const outputQueue: any[]      = [];
	const operatorStack: string[] = [];

	const precedence = {
		'+': 1,
		'-': 1,
		'*': 2,
		'/': 2
	};

	tokens.forEach(token => {
		if (!isNaN(Number(token))) {
			outputQueue.push(Number(token));
		} else if ('+-*/'.includes(token)) {
			while (operatorStack.length && precedence[operatorStack[operatorStack.length - 1]] >= precedence[token]) {
				outputQueue.push(operatorStack.pop());
			}
			operatorStack.push(token);
		} else if (token === '(') {
			operatorStack.push(token);
		} else if (token === ')') {
			while (operatorStack[operatorStack.length - 1] !== '(') {
				outputQueue.push(operatorStack.pop());
			}
			operatorStack.pop();
		}
	});

	while (operatorStack.length) {
		outputQueue.push(operatorStack.pop());
	}

	const stack: number[] = [];
	outputQueue.forEach(token => {
		if (typeof token === 'number') {
			stack.push(token);
		} else {
			const b = stack.pop();
			const a = stack.pop();
			switch (token) {
				case '+':
					stack.push(a + b);
					break;
				case '-':
					stack.push(a - b);
					break;
				case '*':
					stack.push(a * b);
					break;
				case '/':
					stack.push(a / b);
					break;
			}
		}
	});

	return stack[0];
}

function applyPipe(value: any, pipeName: string, args: string, formatService: any): string {
	// Simple format application, extend as needed
	if (pipeName === 'format') {
		return formatService.formatByString(value, args);
	}
	return value.toString();
}


/**
	* Copies a given text to the clipboard.
	* First tries to use the Clipboard API, which is the modern approach.
	* Falls back to a more traditional method using a temporary textarea if the Clipboard API is not available or fails.
	*
	* @param text The text to be copied to the clipboard.
	* @returns A promise that resolves to a boolean indicating the success or failure of the copy operation.
	*/
export function copyTextToClipboard(text: string): Promise<boolean> {
	// Check if the Clipboard API is available
	if (navigator.clipboard && navigator.clipboard.writeText) {
		return navigator.clipboard.writeText(text)
																		.then(() => true)
																		.catch((err) => {
																			console.error('Clipboard API copy failed:', err);
																			return useFallbackCopyMethod(text);
																		});
	} else {
		// Use the fallback method if Clipboard API is not available
		return this.useFallbackCopyMethod(text);
	}
}

/**
	* Fallback method to copy text to clipboard.
	* Creates a temporary textarea element to hold and select the text, then executes the copy command.
	*
	* @param text The text to be copied.
	* @returns A promise that resolves to a boolean indicating the success or failure of the operation.
	*/
function useFallbackCopyMethod(text: string): Promise<boolean> {
	return new Promise((resolve) => {
		// Create a temporary textarea element
		const textArea = document.createElement('textarea');
		textArea.value = text;
		document.body.appendChild(textArea);
		textArea.select();

		try {
			// Execute the copy command
			const successful = document.execCommand('copy');
			resolve(successful);
		} catch (err) {
			console.error('Fallback copy method failed:', err);
			resolve(false);
		} finally {
			// Clean up by removing the textarea
			document.body.removeChild(textArea);
		}
	});
}
