import { BaseInput } from './base-input';

export class MonthInput extends BaseInput {
  public year;
  public month;
  public years = [];
  public months = [
    {'value': '01', 'label': 'January'},
    {'value': '02', 'label': 'February'},
    {'value': '03', 'label': 'March'},
    {'value': '04', 'label': 'April'},
    {'value': '05', 'label': 'May'},
    {'value': '06', 'label': 'June'},
    {'value': '07', 'label': 'July'},
    {'value': '08', 'label': 'August'},
    {'value': '09', 'label': 'September'},
    {'value': '10', 'label': 'October'},
    {'value': '11', 'label': 'November'},
    {'value': '12', 'label': 'December'},
  ];
  public constructor(data: any) {
    super(data);

    let currentYear = new Date().getFullYear();
    for (let i = currentYear + 1; i >= 1960; i--) {
      this.years.push(i);
    }

    this.year = this.node.data.intValue.toString().substr(0,4);
    this.month = this.node.data.intValue.toString().substr(4);
  }

  public onYearSelectChange(e) {
    this.setValue(this.year + this.month);
    this.markAsDirty();
  }

  public onMonthSelectChange(e) {
    this.setValue(this.year + this.month);
    this.markAsDirty();
  }
}
