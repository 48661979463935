import { DataDescribed, generateQuickGuid, LayoutAnnotation } from '@cs/core/generate';
import { isNullOrUndefined, getPropertyOf, hasPropertyOf }    from '@cs/core/utils';
import { GridDataCell }                                       from '@cs/components/data-grid';

export interface ISliderEditModalData {
	dataEntryGrid: string;
	changedCells: Array<GridDataCell>;
	sliderData: any;
	factTableName: string;
	params: { scenario: string };
}

export class SliderRow {
	id: string;
	label: string;
	value: number;

	constructor(init: Partial<SliderRow>) {
		this.id    = getPropertyOf(init, 'id', generateQuickGuid());
		this.label = getPropertyOf(init, 'label', '');
		this.value = getPropertyOf(init, 'value', 50);
	}
}

export class PredicationAnnotation {
	infoRows: Array<InfoRow>;
	sliders: Array<SliderRow>;
	showSliders: boolean;
	labelLookupId: string;
	statusMessage: string;

	constructor(init: Partial<PredicationAnnotation>) {
		this.infoRows      = hasPropertyOf(init, 'infoRows') ? init.infoRows.map(value => new InfoRow(value)) : [];
		this.showSliders   = getPropertyOf(init, 'showSliders', false);
		this.sliders       = hasPropertyOf(init, 'sliders') ? init.sliders.map(value => new SliderRow(value)) : [];
		this.labelLookupId = getPropertyOf(init, 'labelLookupId', null);
		this.labelLookupId = getPropertyOf(init, 'statusMessage', null);
	}
}

export class PredictionLayoutAnnotation<T> extends LayoutAnnotation<T> {
	predictionModal: PredicationAnnotation;

	constructor(layoutObject: Partial<PredictionLayoutAnnotation<T>>) {
		super(layoutObject);
		this.predictionModal = !isNullOrUndefined(layoutObject) && hasPropertyOf(layoutObject, 'predictionModal')
			? new PredicationAnnotation(layoutObject.predictionModal) : new PredicationAnnotation({});
	}
}

export class InfoRow {
	id: string;
	fieldId: string;
	label: string;
	value: number;

	constructor(init: Partial<InfoRow>) {
		this.label   = getPropertyOf(init, 'label', '');
		this.id      = getPropertyOf(init, 'id', generateQuickGuid());
		this.value   = getPropertyOf(init, 'value', 0);
		this.fieldId = getPropertyOf(init, 'fieldId');
	}
}


export class PredictionDataDescribed extends DataDescribed<any[]> {

	layout: PredictionLayoutAnnotation<any>;

	constructor(init: Partial<PredictionDataDescribed>) {
		super(init);

	}

}
