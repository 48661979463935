import { Injectable }         from '@angular/core';
import { Query }              from '@datorama/akita';
import { TabState, TabStore } from './tab.store';


@Injectable({providedIn: 'root'})
export class TabQuery extends Query<TabState> {

	constructor(protected store: TabStore) {
		super(store);
	}

}
