import { Component, OnInit }             from '@angular/core';
import { DashboardPanelComponentBase }   from '@cs/components/shared';
import { IndicatorDataDescribed }        from '@cs/components/indicator';
import { ArrayUtils, isNullOrUndefined } from '@cs/core';

@Component({
			   selector:    'cs-dashboard-indicators',
			   templateUrl: './dashboard-indicators.component.html',
			   styleUrls:   ['./dashboard-indicators.component.scss']
		   })
export class DashboardIndicatorsComponent extends DashboardPanelComponentBase<IndicatorDataDescribed> implements OnInit {
	name: string;
	constructor() {
		super();
	}

	ngOnInit() {
	}

	update(data: IndicatorDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			(this.data && ArrayUtils.isEqual(this.data.data, data.data)))
			return;

		this.data = data;
	}

	dataChanged(value: IndicatorDataDescribed) {

	}

}
