import { DataEntryStateColumn, DataEntryStateHeaderColumn } from './data-entry-state-column';
import { DataViewRow, DataViewRowInit }                     from '@cs/core';

export enum DataEntryStateRowType {
	Header = 'Header',
	Data   = 'Data',
}

export class DataEntryStateRow extends DataViewRow<DataEntryStateColumn, DataEntryStateRowType> {
	type: DataEntryStateRowType = DataEntryStateRowType.Data;
	badges?: string[];
	private _structureKey: string;

	get structureKey(): string {
		if (this._structureKey == null)
			this._structureKey = this.path.join('#');
		return this._structureKey;
	}

	constructor(init: DataViewRowInit<DataEntryStateColumn, DataEntryStateRowType> & Partial<DataEntryStateRow>) {
		super(init);

		this.badges = init.badges;
	}

}

export class DataEntryStateHeaderRow extends DataViewRow<DataEntryStateHeaderColumn, DataEntryStateRowType> {
	type: DataEntryStateRowType = DataEntryStateRowType.Header;
}
