import { Injectable }                                   from '@angular/core';
import { Query }                                        from '@datorama/akita';
import { FilterCompareBarStore, FilterCompareBarState } from './filter-compare-bar.store';

@Injectable({providedIn: 'root'})
export class FilterCompareBarQuery extends Query<FilterCompareBarState> {

  constructor(protected store: FilterCompareBarStore) {
    super(store);
  }

}
