import { NgModule }                    from '@angular/core';
import { CommonModule }                from '@angular/common';
import { MatDialogModule }             from '@angular/material/dialog';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';


const components = [ConfirmationDialogComponent];

@NgModule({
  declarations:    [...components],
  exports:         [...components],
  imports:         [CommonModule,
                    MatDialogModule],
  entryComponents: [...components]
})
export class CsSharedDialogModule {

}

