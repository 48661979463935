import { Inject, ModuleWithProviders, NgModule, Optional, SkipSelf, Type } from '@angular/core';
import { CommonModule }                                                    from '@angular/common';
import { CsApplicationSettings }                                           from '@cs/common';
import { AuthenticationConfigService }                                     from './authentication-config.service';

import { RouterModule }                                           from '@angular/router';
import { AuthenticationQuery }                                    from './state/authentication.query';
import { AuthenticationService }                                  from './state/authentication.service';
import { AuthenticationStore }                                    from './state/authentication.store';
import { AuthenticationGuard, IsLoggedInGuard, IsResetPageGuard } from './guards/authentication.guard';
import { TranslateModule }                                        from '@ngx-translate/core';


const providers = [
	AuthenticationQuery,
	AuthenticationService,
	AuthenticationStore,
	AuthenticationGuard,
	IsLoggedInGuard,
	IsResetPageGuard
];

@NgModule({
			  declarations: [],
			  imports:      [
				  CommonModule,
				  TranslateModule,
				  RouterModule
			  ],
			  providers:    [
				  ...providers
			  ]
		  })
export class AuthenticationModule {
	static forRoot(moduleConfig: Type<AuthenticationConfigService>,
				   appSetting: CsApplicationSettings): ModuleWithProviders<AuthenticationModule> {
		return {
			ngModule:  AuthenticationModule,
			providers: [
				{
					provide:  AuthenticationConfigService,
					useClass: moduleConfig
				},
				{
					provide:  CsApplicationSettings,
					useValue: appSetting
				},
				...providers
			]
		};
	}

	constructor(@Optional() @SkipSelf() @Inject(AuthenticationModule) parentModule: AuthenticationModule | null) {
		if (parentModule) {
			throw new Error(
				`AuthenticationModule has already been loaded. only allowed to be added once in the consuming application`);
		}
	}
}
