import {
  animate,
  animateChild,
  animation,
  AnimationTriggerMetadata,
  group,
  keyframes,
  query,
  style,
  transition,
  trigger,
  useAnimation
} from '@angular/animations';

import { IAnimationOptions } from './animation-options.interface';

const zoomOut = animation(
  group([
    animate(
      '{{duration}}ms {{delay}}ms',
      keyframes([
        style({opacity: 1, transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 0}),
        style({opacity: 0, transform: 'scale3d(0.3, 0.3, 0.3)', easing: 'ease', offset: 0.5}),
        style({opacity: 0, easing: 'ease', offset: 1})
      ])
    ),
    animate(
      '{{duration}}ms {{delay}}ms',
      keyframes([
        style({transform: 'scale3d(1, 1, 1)', easing: 'ease', offset: 0}),
        style({transform: 'scale3d(0.3, 0.3, 0.3)', easing: 'ease', offset: 0.5})
      ])
    )
  ])
);

const DEFAULT_DURATION = 1000;

export function zoomOutAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'zoomOut', [
    transition(
      '0 => 1',
      [
        ...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), {optional: true})] : []),
        group([
          useAnimation(zoomOut),
          ...(!options || !options.animateChildren || options.animateChildren === 'together'
            ? [query('@*', animateChild(), {optional: true})]
            : [])
        ]),
        ...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), {optional: true})] : [])
      ],
      {
        params: {
          delay:    (options && options.delay) || 0,
          duration: (options && options.duration) || DEFAULT_DURATION
        }
      }
    )
  ]);
}

export function zoomOutOnLeaveAnimation(options?: IAnimationOptions): AnimationTriggerMetadata {
  return trigger((options && options.anchor) || 'zoomOutOnLeave', [
    transition(
      ':leave',
      [
        ...(options && options.animateChildren === 'before' ? [query('@*', animateChild(), {optional: true})] : []),
        group([
          useAnimation(zoomOut),
          ...(!options || !options.animateChildren || options.animateChildren === 'together'
            ? [query('@*', animateChild(), {optional: true})]
            : [])
        ]),
        ...(options && options.animateChildren === 'after' ? [query('@*', animateChild(), {optional: true})] : [])
      ],
      {
        params: {
          delay:    (options && options.delay) || 0,
          duration: (options && options.duration) || DEFAULT_DURATION
        }
      }
    )
  ]);
}
