import { RepositoryType } from './RepositoryDto';

export class GitTableNavigationFilter {
	parentRepositoryLabel?: string;
	parentRepositoryName?: string;
	parentBranch?: string;
	parentBranchLoose?: string;

	parentApplicationType?: RepositoryType;


	parentApplicationTypes?: RepositoryType[];

	childRepositoryName?: string;
	childRepositoryNameLoose?: string;
	childBranch?: string;
	childBranchLoose?: string;

	childApplicationType?: RepositoryType;

	childApplicationTypes?: RepositoryType[];

	// The filter won't recursively check the child repositories of repositories that have the following application type
	IgnoreTypes?: RepositoryType[];

	navId?: string;

	checkRecursive?: boolean;
}
