import { HttpErrorResponse } from '@angular/common/http';
import { Observable }        from 'rxjs';

export class ErrorConfig {
  retryCount               = 3;
  globalHttpHooksOverrides = new Map<number, (error: HttpErrorResponse) => Observable<never>>();

  constructor(init: Partial<ErrorConfig> = {}) {
    Object.assign(this, init);
  }
}
