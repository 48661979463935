import { Injectable }                   from '@angular/core';
import { IConfigService }               from '../app/config-service.interface';
import { CsHttpRequestOptions, Result } from '@cs/core';
import { Observable }                   from 'rxjs';


export abstract class HealthConfigService implements IConfigService {
  moduleName = 'app-health';

  abstract supportEmail: string;

  /**
   * Checks if the Api is still responding
   * @param errorHandler The handling of errors intercepting the global error handler
   */
  abstract checkApiHealth(errorHandler?: CsHttpRequestOptions): Observable<Result<string>>;
}
