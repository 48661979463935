/* tslint:disable */
import {
	Component,
	ElementRef,
	AfterViewInit,
	OnDestroy,
	Input,
	Output,
	EventEmitter,
	forwardRef,
	TemplateRef,
	ViewChild,
	Renderer2
}                                                  from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbPopover }                              from '@cs/components/ngb-popover';

/* tslint:enable */

export const SLIDER_VALUE_ACCESSOR: any = {
	provide:     NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CsSliderComponent),
	multi:       true
};

/**
 * Context for the result template in case you want to override the default one
 */
export interface TooltipTemplateContext {
	/**
	 * percentage of progress
	 */
	percentage: number;

	/**
	 * progress value
	 */
	value: number;
}

@Component({
	selector:    'cs-slider',
	templateUrl: './slider.component.html',
	providers:   [SLIDER_VALUE_ACCESSOR]

})
export class CsSliderComponent implements AfterViewInit, OnDestroy, ControlValueAccessor {

	@ViewChild('popover') popover: NgbPopover;

	/**
	 * When true, displays an animation on click of the slider bar.
	 *
	 */
	@Input() animate: boolean;

	/**
	 * When true show two percentage labels
	 */
	@Input() showPercentageLabels = false;

	/**
	 * When true, it specifies that the element should be disabled.
	 *
	 */
	@Input() disabled: boolean;

	/**
	 * Mininum boundary value
	 */
	@Input() min = 0;

	/**
	 * Maximum boundary value.
	 */
	@Input() max = 100;

	/**
	 * Orientation of the slider, valid values are horizontal and vertical.
	 */
	@Input() orientation: 'horizontal' | 'vertical' = 'horizontal';

	/**
	 * Step factor to increment/decrement the value.
	 */
	@Input() step = 1;

	/**
	 * When specified, allows two boundary values to be picked.
	 */
	@Input() range: boolean;

	/**
	 * Inline style of the component.
	 */
	@Input() style: any;

	/**
	 * Style class of the component.
	 */
	@Input() styleClass: string;

	/**
	 * Callback to invoke on value change via slide.
	 */
	@Output() onChange: EventEmitter<{ event: Event, value?: number, values?: number[] }> = new EventEmitter();

	/**
	 * Callback to invoke when slide stops.
	 */
	@Output() onSlideEnd: EventEmitter<{ originalEvent: Event, value: number }> = new EventEmitter();

	/**
	 * A template to show on top of handler.
	 */
	@Input() tooltipTemplate: TemplateRef<TooltipTemplateContext>;
	/**
	 * Control if tooltip template shown.
	 */
	@Input() tooltipDisplay: 'none' | 'always' = 'always';

	public value: number;

	public values: number[];

	public handleValue: number;

	public handleValues: number[] = [];

	public dragging: boolean;

	public dragListener: any;

	public mouseupListener: any;

	public initX: number;

	public initY: number;

	public barWidth: number;

	public barHeight: number;

	public sliderHandleClick: boolean;

	public handleIndex: number;

	public onModelChange: Function = () => {
	};

	public onModelTouched: Function = () => {
	};

	constructor(public el: ElementRef, public renderer: Renderer2) {
	}

	onMouseDown(event: Event, index?: number) {
		if (this.disabled) {
			return;
		}

		this.dragging = true;
		this.updateDomData();
		this.sliderHandleClick = true;
		this.handleIndex       = index;
		event.preventDefault();
	}

	onBarClick(event) {
		if (this.disabled) {
			return;
		}

		if (!this.sliderHandleClick) {
			this.updateDomData();
			this.handleChange(event);
		}

		this.sliderHandleClick = false;
	}

	ngAfterViewInit() {
		if (this.disabled) {
			return;
		}

		this.dragListener = this.renderer.listen('body', 'mousemove', (event) => {
			if (this.dragging) {
				this.handleChange(event);
			}
		});

		this.mouseupListener = this.renderer.listen('body', 'mouseup', (event) => {
			if (this.dragging) {
				this.dragging = false;
				this.onSlideEnd.emit({originalEvent: event, value: this.value});
			}
		});
		if (this.popover && this.tooltipDisplay === 'always') {
			this.popover.open();
		}
	}

	handleChange(event: Event) {
		const handleValue = this.calculateHandleValue(event);
		const newValue    = this.getValueFromHandle(handleValue);

		if (this.range) {
			if (!this.dragging) {
				if (newValue <= this.values[0]) {
					this.handleIndex = 0;
				} else if (newValue >= this.values[1]) {
					this.handleIndex = 1;
				} else {
					this.handleIndex = Math.abs(this.values[0] - newValue) > Math.abs(this.values[1] - newValue) ? 1 : 0;
				}
			}
			if (this.step) {
				this.handleStepChange(newValue, this.values[this.handleIndex]);
			} else {
				this.handleValues[this.handleIndex] = handleValue;
				this.updateValue(newValue, event);
			}
		} else {
			if (this.step) {
				this.handleStepChange(newValue, this.value);
			} else {
				this.handleValue = handleValue;
				this.updateValue(newValue, event);
			}
		}
	}

	handleStepChange(newValue: number, oldValue: number) {
		const diff  = (newValue - oldValue);
		const steps = Math.round(diff / this.step);
		newValue    = oldValue + steps * this.step;
		this.updateValue(newValue);
		this.updateHandleValue();
		return;

		// if (diff < 0 && (-1 * diff) >= this.step / 2) {
		//     newValue = oldValue - this.step;
		//     this.updateValue(newValue);
		//     this.updateHandleValue();
		// } else if (diff > 0 && diff >= this.step / 2) {
		//     newValue = oldValue + this.step;
		//     this.updateValue(newValue);
		//     this.updateHandleValue();
		// }
	}

	writeValue(value: any): void {
		if (this.range) {
			this.values = value || [0, 0];
		} else {
			this.value = value || 0;
		}
		this.updateHandleValue();
	}

	registerOnChange(fn: Function): void {
		this.onModelChange = fn;
	}

	registerOnTouched(fn: Function): void {
		this.onModelTouched = fn;
	}

	setDisabledState(val: boolean): void {
		this.disabled = val;
	}

	private getWindowScrollTop(): number {
		const doc = document.documentElement;
		return (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
	}

	private getWindowScrollLeft(): number {
		const doc = document.documentElement;
		return (window.pageXOffset || doc.scrollLeft) - (doc.clientLeft || 0);
	}

	updateDomData(): void {
		const rect     = this.el.nativeElement.children[0].getBoundingClientRect();
		this.initX     = rect.left + this.getWindowScrollLeft();
		this.initY     = rect.top + this.getWindowScrollTop();
		this.barWidth  = this.el.nativeElement.children[0].offsetWidth;
		this.barHeight = this.el.nativeElement.children[0].offsetHeight;
	}

	calculateHandleValue(event): number {
		if (this.orientation === 'horizontal') {
			return ((event.pageX - this.initX) * 100) / (this.barWidth);
		} else {
			return (((this.initY + this.barHeight) - event.pageY) * 100) / (this.barHeight);
		}
	}

	updateHandleValue(): void {
		if (this.range) {
			this.handleValues[0] = (this.values[0] < this.min ? 0 : this.values[0] - this.min) * 100 / (this.max - this.min);
			this.handleValues[1] = (this.values[1] > this.max ? 100 : this.values[1] - this.min) * 100 / (this.max - this.min);
		} else {
			if (this.value < this.min) {
				this.handleValue = 0;
			} else if (this.value > this.max) {
				this.handleValue = 100;
			} else {
				this.handleValue = (this.value - this.min) * 100 / (this.max - this.min);
			}
		}
	}

	updateValue(val: number, event?: Event): void {
		if (this.range) {
			let value = val;

			if (this.handleIndex === 0) {
				if (value < this.min) {
					value                = this.min;
					this.handleValues[0] = 0;
				} else if (value > this.values[1]) {
					value                = this.values[1];
					this.handleValues[0] = this.handleValues[1];
				}
			} else {
				if (value > this.max) {
					value                = this.max;
					this.handleValues[1] = 100;
				} else if (value < this.values[0]) {
					value                = this.values[0];
					this.handleValues[1] = this.handleValues[0];
				}
			}

			this.values[this.handleIndex] = Math.floor(value); // to support steps<1 try and CHANGE to this.round(val,this.step,this.min)
			this.onModelChange(this.values);
			this.onChange.emit({event: event, values: this.values});
		} else {
			if (val < this.min) {
				val              = this.min;
				this.handleValue = 0;
			} else if (val > this.max) {
				val              = this.max;
				this.handleValue = 100;
			}

			this.value = Math.floor(val); // to support steps<1 try and CHANGE to this.round(val,this.step,this.min)
			this.onModelChange(this.value);
			this.onChange.emit({event: event, value: this.value});
		}
	}

	round(number, increment, offset) {
		return Math.ceil((number - offset) / increment) * increment + offset;
	}

	getValueFromHandle(handleValue: number): number {
		return (this.max - this.min) * (handleValue / 100) + this.min;
	}

	ngOnDestroy() {
		if (this.dragListener) {
			this.dragListener();
		}

		if (this.mouseupListener) {
			this.mouseupListener();
		}
	}
}
