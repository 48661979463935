import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { FormsModule } from '@angular/forms';
import { IUploadService } from './i-upload-service.component';
import { UploadFormModule } from '../upload-form/upload-form.module';


@NgModule({
  declarations: [IUploadService],
  imports: [
    CommonModule,
    MatDialogModule,
    FormsModule,
    UploadFormModule
  ],
  exports: [IUploadService]
})
export class IUploadServiceModule { }
