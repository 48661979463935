export class DataViewPosition {
	width: number;
	path: Array<string>;
	readonly index: number;


	get widthPx(): string {

		return this.width
					 ? `${this.width}px`
					 : 'auto';
	}


	constructor(init: Partial<DataViewPosition>) {
		this.width = init.width || 0;
		this.path  = init.path;
		this.index = init.index;
	}
}

export class DataViewColumnPosition extends DataViewPosition {

	position: number;
	field: string;
	isLast: boolean;
	children: number;
	totalChildren: number;


	get widthPxWithChildOffset(): string {

		return this.width
					 ? `${this.width + ((this.totalChildren > 1
															 ? this.totalChildren - 1
															 : 0) * 2)}px`
					 : 'auto';
	}

	constructor(init: Omit<DataViewColumnPosition, 'isLast' | 'widthPx' | 'widthPxWithChildOffset'>) {
		super(init);

		this.position      = init.position;
		this.field         = init.field;
		this.children      = init.children;
		this.totalChildren = init.totalChildren;
	}
}
