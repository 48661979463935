import { getPropertyOf, LoggerUtil } from '@cs/core';
import { ErrorMessage }              from '@cs/performance-manager/shared';

export interface IAccountInfo {
	isLDAP: boolean;
	userName: string;
}

export interface LoginModel {
	/**
	 * Gets or sets the username.
	 */
	username: string;
	/**
	 *
	 */
	readonly requiresPassword?: boolean;
	/**
	 *
	 */
	readonly forgotPasswordAvailable?: boolean;
	/**
	 * Gets or sets the password.
	 */
	password?: string;
	/**
	 *
	 */
	readonly requiresCallBackUrl?: boolean;
	/**
	 * A callback url, for providers that require a external login.
	 */
	callBackUrl?: string;
	/**
	 * Gets or sets the method for authenticating
	 */
	method?: string;
	/**
	 * Indicates if the Google Captcha should be shown
	 */
	readonly requiresGoogleCaptcha?: boolean;
	/**
	 *
	 */
	readonly googleCaptchaChallenge?: string;
	/**
	 *
	 */
	googleCaptchaResponse: string;
}


export class AccountInfo implements IAccountInfo {
	isLDAP: boolean;
	userName: string;

	constructor(init: Partial<AccountInfo>) {
		try {
			this.isLDAP   = getPropertyOf(init, 'isLDAP', false);
			this.userName = getPropertyOf(init, 'userName');
		} catch (e) {
			LoggerUtil.error(ErrorMessage.MAPPING_BUSINESS_MODEL_TO_APPLICATION_MODEL_FAILED(AccountInfo, e));
		}

	}
}
