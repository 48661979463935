import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReportingDashboardComponent} from './reporting-dashboard.component';
import {DashboardModule} from '@cs/components/dashboard';


@NgModule({
	declarations: [
		ReportingDashboardComponent
	],
	imports: [
		CommonModule,
		DashboardModule
	]
})
export class ReportingDashboardModule {
}
