import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CsCultureProvider } from './culture-provider';
import { catchError, tap } from 'rxjs/operators';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { LoggerUtil } from '@cs/core';

interface TranslationResponse {
	[key: string]: string;
}

@Injectable({
													providedIn: 'root'
												})
export class ComponentTranslationLoaderService {

	constructor(private http: HttpClient, private culture: CsCultureProvider) {}

	registerComponentModule(module: string): void {
		this.componentModuleStore.set(module, null);
	}

	getComponentModuleNames(): IterableIterator<string> {
		return this.componentModuleStore.keys();
	}

	setupComponentModule(module: string): Observable<TranslationResponse> {
		if (!module) {
			return of({});
		}

		const url = this.constructUrl(module);

		if (this.cache.has(url)) {
			const value = this.cache.get(url);
			LoggerUtil.debug(`${module} translation file is already loaded, added from cache. @${url}`);
			// tslint:disable-next-line:no-non-null-assertion
			return of(value!);
		}

		if (this.isLoading.has(url)) {
			LoggerUtil.debug(`Loading ${module} translation file is in progress, waiting for @${url}`);
			// tslint:disable-next-line:no-non-null-assertion
			return this.isLoading.get(url)!.asObservable();
		}

		const loaderHandler$ = new BehaviorSubject<TranslationResponse | null>(null);
		this.isLoading.set(url, loaderHandler$);

		this.http.get<TranslationResponse>(url).pipe(
			catchError(err => {
				LoggerUtil.error(`Failed to load ${module} translation file @${url}`, err);
				loaderHandler$.next({});
				return of({});
			}),
			tap((translations: TranslationResponse) => {
				loaderHandler$.next(translations);
				loaderHandler$.complete();
				this.isLoading.delete(url);
				this.cache.set(url, translations);
				LoggerUtil.debug(`Loaded ${module} translation file @${url}`);
			})
		).subscribe();

		return loaderHandler$.asObservable();
	}

	private cache: Map<string, TranslationResponse> = new Map();
	private isLoading: Map<string, BehaviorSubject<TranslationResponse | null>> = new Map();
	private componentModuleStore: Map<string, null> = new Map();

	private constructUrl(module: string): string {
		return `${this.culture.getTranslationLocation()}/components/${module}/${this.culture.getCulture()}.json?version=${this.culture.getCacheBusterHash()}`;
	}
}
