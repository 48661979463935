import { Component, Inject, OnInit, Optional, ViewChild }                    from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef }                                     from '@angular/material/dialog';
import {
	DataGridLookupAction,
	GridDataCell
}                                                                            from '@cs/components/data-grid';
import { isNullOrUndefined, isString }                                       from '@cs/core';
import { DataEntryPredictionConfigService }                                  from '../data-entry-prediction-config.service';
import { getPropertyOf, hasPropertyOf, LoggerUtil, PropertyAnnotation }      from '@cs/core';
import { AppQuery }                                                          from '@cs/performance-manager/shared';
import { TranslateService }                                                  from '@ngx-translate/core';
import { InfoRow, ISliderEditModalData, PredictionDataDescribed, SliderRow } from './prediction-data.described';
import { CsTableNxtComponent, ValueChangedEventArgs }                        from '@cs/components/table-nxt';
import { FilterCompareBarQuery }                                             from '@cs/components/filter-and-compare-bar';
import { CsToastManagerService }                                             from '@cs/components/toast-manager';
import {
	createToObjectWithLowerCaseKeys,
	transformToSelectionObject
}                                                                            from '@cs/components/util';
import {
	LookupAgent
}                                                                            from '@cs/components/shared';


@Component({
	selector:    'csw-slider-edit.modal',
	templateUrl: './slider-edit.modal.component.html',
	styleUrls:   ['./slider-edit.modal.component.scss']
})
export class SliderEditModalComponent implements OnInit {

	nothingChanged = true;
	hasEmptyValues: boolean;
	isSaving       = false;
	isInValid      = true;
	designation: string;
	hasNegativeValues: boolean;

	/**
	 * List of types that could be given to the comments
	 */
	commentTypes = [];

	/**
	 * Stores the comment entered by the user
	 */
	comment = '';

	/**
	 * Label of the selected period
	 */
	periodLabel = '';

	commentTypeSelected;

	private factTableName: string;
	private selection: {} & any & { [p: string]: any };
	private params: { scenario: string };

	infoValues: Array<InfoRow> = [];
	invalidData                = false;
	kpi: string;
	disableSliders             = true;
	predictionTable: PredictionDataDescribed;
	sliders: Array<SliderRow>  = [];
	modalLabel: string;

	predictionStatusMessage: string;

	@ViewChild('tableNxt') tableNxt: CsTableNxtComponent<any>;
	private changedCells: Array<GridDataCell>;
	private _originalPredicationTable: PredictionDataDescribed;
	allowNegativeEntry: boolean;


	constructor(public dialogRef: MatDialogRef<SliderEditModalComponent>,
							@Optional() @Inject(MAT_DIALOG_DATA) public data: ISliderEditModalData,
							@Inject(AppQuery) private appQuery: AppQuery,
							@Inject(FilterCompareBarQuery) private filterCompareBarQuery: FilterCompareBarQuery,
							@Inject(DataEntryPredictionConfigService) private config: DataEntryPredictionConfigService,
							private i8n: TranslateService,
							private toastService: CsToastManagerService) {
		if (!isNullOrUndefined(data))
			this.initComponent(data);
	}

	onNoClick(): void {
		this.dialogRef.close();
	}

	ngOnInit() {
		if (!isNullOrUndefined(this.data))
			this.loadData(this.data.dataEntryGrid, this.selection);
	}

	private loadData(dataEntryGrid: string, selection: { [p: string]: any }) {
		this.config.getPredicationInfo(
			dataEntryGrid, selection).subscribe(result => {
			console.log(result.value);
			const dataDescr     = result.value as PredictionDataDescribed;
			const keyAnnotation = dataDescr.dataAnnotation.fields.find(value => value.key);

			this.patchCurrentValues(dataDescr, keyAnnotation, this.data.changedCells);
			this._originalPredicationTable = dataDescr;
			this.predictionTable           = this.cleanOverallEntry(dataDescr, keyAnnotation);
			this.periodLabel               = this.setPeriodLabel(this.predictionTable.data, keyAnnotation);
			this.disableSliders            = !dataDescr.layout.predictionModal.showSliders;
			this.predictionStatusMessage   = dataDescr.layout.predictionModal.statusMessage;
			this.sliders                   = this.setSliders(dataDescr.data, dataDescr.layout.predictionModal.sliders, keyAnnotation);
			this.modalLabel                = this.getLabel(dataDescr.layout.predictionModal.labelLookupId, this.changedCells);
			this.infoValues                = this.convertInfovalues(dataDescr.data, dataDescr.layout.predictionModal.infoRows);
			this.allowNegativeEntry        = this.appQuery.getValue().allowNegativeDataEntry;
		});
	}

	private convertInfovalues(data: any[], infoRows: InfoRow[] = []) {
		const infoValues: Array<InfoRow> = [];

		for (const info of infoRows) {

			// Sum the values for a column
			const total = data
				.map(value => value[info.fieldId])
				.reduce((previousValue, currentValue) =>
					previousValue + (isString(currentValue) ? parseFloat(currentValue.toString()) : currentValue), 0);

			const row = new InfoRow({
				label:   info.label,
				fieldId: info.fieldId,
				value:   total
			});

			infoValues.push(row);
		}

		return infoValues;

	}

	saveData() {
		const pdd       = this.updatePredictionDataWithSliders(new PredictionDataDescribed(
			this._originalPredicationTable), this.sliders);
		const apiParams = this.filterCompareBarQuery.getValue().mainbarResultParams.selection;
		this.config.savePredictionData(pdd, apiParams, this.data.params)
				.subscribe(value => {
					this.toastService.show({
						type:            'success',
						content:         this.i8n.instant('DATA_IS_SAVED'),
						showProgressBar: true
					});

				});

	}

	sliderIsTouched() {
		// this.popoverContext.sliderIsTouched = true;
	}

	/**
	 * When the value is changed, the component updates the cell in the DataGrid outside the modal.
	 * This will validate the input based on the rules provided in the grid.
	 * If there is a prediction calculation provided it will update the the corresponding
	 * properties of that row
	 * @param args the changed cell in the modal
	 */
	valueChanged(args: ValueChangedEventArgs) {
		console.log(args);
		const keyAnnotation = this.predictionTable.dataAnnotation.fields.find(value => value.key) as PropertyAnnotation<any>;
		const cell          = this.getCell(keyAnnotation, args.row.id, this.data.changedCells);

		if (!isNullOrUndefined(cell))
			cell.updateValue(args.cell.value);

		const conversionFactors = this.getDataRowByKey(this._originalPredicationTable, keyAnnotation, args);
		if (this.config.calculatePrediction(args.cell, args.row, conversionFactors)) {
			this.tableNxt.detectChanges();
		}

		this.checkForInvalidData();
	}

	getErrorMessage() {
		let output;

		if (this.nothingChanged) {
			output = this.i8n.instant('MESSAGES.NOT_TOUCHED_YET');
		} else if (this.invalidData) {
			output = this.i8n.instant('MESSAGES.INVALID_DATA');
		} else if (this.hasEmptyValues) {
			output = this.i8n.instant('MESSAGES.HAS_EMPTY_VALUES');
		} else if (this.comment.length < 5) {
			output = this.i8n.instant('MESSAGES.NOT_MEETING_COMMENT_REQUIREMENTS');
		}
		return output;
	}

	checkForInvalidData() {
		// Check if one of the cells is invalid
		this.data.changedCells.forEach(value => value.validateCell());
		const foundInvalidCell = this.data.changedCells.find(cell => cell.cellUIState.invalid);

		this.invalidData = !isNullOrUndefined(foundInvalidCell) || this.tableNxt.hasInvalidValues();

		// TODO: Check for empty calculations
		if (!this.appQuery.getValue().allowNegativeDataEntry) {
			this.hasNegativeValues = this.tableNxt.hasNegativeValues();
		}

		this.hasEmptyValues = this.tableNxt.hasEmptyValues();

		// Check if one of the currenEditCells has changed
		this.nothingChanged = !this.tableNxt.hasValuesChanged();

		this.isInValid = this.comment.length < 5
			|| this.invalidData
			|| this.nothingChanged
			|| this.hasEmptyValues
			|| this.hasNegativeValues;
	}

	initComponent(data: ISliderEditModalData) {

		if (isNullOrUndefined(this.data))
			this.data = data;

		const {mainbarResultParams, mainbarApiParams} = this.filterCompareBarQuery.getValue();
		const navBarSelection                         = createToObjectWithLowerCaseKeys(mainbarResultParams.selection);
		const keySelection                            = transformToSelectionObject({});
		const selection                               = Object.assign({}, navBarSelection, keySelection);
		const lookupAction                            = new DataGridLookupAction({key: 'idDesignation'});
		const designation                             = LookupAgent.resolve(mainbarApiParams.idDesignation, lookupAction.key, lookupAction.display);
		const dataEntrygrid                           = data.dataEntryGrid;
		// TODO: THis should be provided by the sever and should not use an id
		const kpi                                     = mainbarApiParams.kpi || mainbarApiParams.KPI;


		this.changedCells   = data.changedCells;
		this.designation    = designation;
		this.factTableName  = data.factTableName;
		this.selection      = selection;
		this.params         = data.params;
		this.disableSliders = true;
		this.kpi            = kpi;

		this.config.getEventTypes().subscribe(result => {
			this.commentTypes        = result.value;
			this.commentTypeSelected = this.commentTypes[0].id;
		});
	}

	private getCurrentCellValue(keyAnnotation: PropertyAnnotation<any>, data: { [key: string]: any }, changedCells: Array<GridDataCell>) {
		try {
			const foundCell = this.getCell(keyAnnotation, data, changedCells);
			return foundCell.value;
		} catch (e) {
			LoggerUtil.error(`No value found for ${keyAnnotation.id as any}`);
			return 0;
		}
	}

	private getCell(keyAnnotation: PropertyAnnotation<any>, data: { [key: string]: any }, changedCells: Array<GridDataCell>) {
		try {
			const keyValue         = getPropertyOf(data, keyAnnotation.id as any);
			const keyProperty      = keyAnnotation.id.toString().toLowerCase();
			const foundChangedCell = changedCells.find(value =>
				hasPropertyOf(value.keys, keyProperty) && value.keys[keyProperty] === keyValue);
			return foundChangedCell;
		} catch (e) {
			LoggerUtil.error(`No cell found for ${keyAnnotation.id as any}`);
			return null;
		}

	}

	private setSliders(data: any[], sliders: SliderRow[], keyAnnotation: PropertyAnnotation<any>): SliderRow[] {

		const conversionFactor = data.length > 1
			? data.find(value => value[keyAnnotation.id] === 'overall')
			: data[0];

		for (const slider of sliders) {
			const value  = conversionFactor[slider.id];
			slider.value = isNullOrUndefined(value) || isString(value) ? 0 : value * 100;
		}

		return sliders;

	}

	/**
	 * Get the label to display the period of the data that is shown
	 */
	private setPeriodLabel(data: any[], keyAnnotation: PropertyAnnotation<any>) {

		if (data.length === 0) {
			return '';
		}

		const headers  = data
			.map(value => value[keyAnnotation.id]);
		// map all idMonths and check the largest and the smallest
		const lastdate = Math.max.apply(null, headers);
		const mindate  = Math.min.apply(null, headers);

		const minMonth = LookupAgent.resolve(mindate, keyAnnotation.id.toString(), 'labelMin');
		const maxMonth = LookupAgent.resolve(lastdate, keyAnnotation.id.toString(), 'labelMin');

		if (minMonth === maxMonth) {
			return `${minMonth}`;
		} else {
			return `${minMonth} - ${maxMonth}`;
		}
	}

	private getLabel(lookupId: string, changedCells: Array<GridDataCell>) {
		if (changedCells.length === 0 || isNullOrUndefined(lookupId) || !hasPropertyOf(changedCells[0].keys, lookupId.toLowerCase()))
			return this.i8n.instant('LABELS.NO_LABEL');

		return LookupAgent.resolve(changedCells[0].keys[lookupId.toLowerCase()], lookupId);
	}

	private cleanOverallEntry(dataDescr: PredictionDataDescribed, keyAnnotation: PropertyAnnotation<any>) {
		const predictionData  = JSON.parse(JSON.stringify(dataDescr)) as PredictionDataDescribed;
		const hasOverallIndex = predictionData.data.findIndex(value => value[keyAnnotation.id] === 'overall');

		if (hasOverallIndex > -1)
			predictionData.data.splice(hasOverallIndex, 1);

		return predictionData;
	}

	private patchCurrentValues(dataDescr: PredictionDataDescribed, keyAnnotation: PropertyAnnotation<any>, changedCells: Array<GridDataCell>) {
		for (const data of dataDescr.data) {
			data['editCurrentValue'] = this.getCurrentCellValue(keyAnnotation, data, changedCells);
		}
	}

	private getDataRowByKey(dataDescr: PredictionDataDescribed,
													keyAnnotation: PropertyAnnotation<any>,
													args: ValueChangedEventArgs) {
		const keyProperty  = keyAnnotation.id.toString();
		const foundDataRow = dataDescr.data.find(value =>
			hasPropertyOf(value, keyProperty) && value[keyProperty] === args.row.id[keyProperty]);

		return foundDataRow;

	}

	private updatePredictionDataWithSliders(dataDescr: PredictionDataDescribed, sliders: SliderRow[]) {
		const keyAnnotation = dataDescr.dataAnnotation.fields.find(value => value.key);
		const data          = dataDescr.data;
		const dataRow       = data.length > 1
			? data.find(value => value[keyAnnotation.id] === 'overall')
			: data[0];

		for (const slider of sliders) {
			dataRow[slider.id] = slider.value / 100;
		}

		return dataDescr;
	}
}
