<div class="combi-entry-state-container">
  <div class="combi-entry-state-header">
    <label>{{ data.label}}</label>
  </div>
  <div>
    <div class="row">
      <div class="col-6">
        <label class="label-header">{{data.indicatorLabel}}</label>
        <div class="d-flex justify-content-center mt-2">
          <a *ngFor="let status of data.indicators"
             class="datablock-with-content {{status?.layout?.class}}"
             [class.opacity-30]="status?.v === 0"
             [ngStyle]="status?.layout?.style"
             title="{{status?.d}}"
             csPopover
             [content]="listComponent"
             [settings]="{hasTip:true, class:'popover-dark'}"
             [csPopoverDisabled]="!status?.meta"
             openOn="mouseover"
             preferredPosition="top"
             [csPopoverData]="status">
            <div class="multi-status-content">{{status.v}}</div>
          </a>
        </div>
      </div>
      <div class="col-6">
        <label class="label-header">{{'ENTRY_STATE' | translate}}</label>
       <cs-gauge [dataSource]="data.state" size="sm"></cs-gauge>
      </div>
    </div>
    <div class="d-flex justify-content-start align-items-center">
      <div csPopover [content]="legendComponent"
           openOn="mouseover"
           preferredPosition="bottomLeft"
           [csPopoverData]="data"
           class="d-flex justify-content-start align-items-center legend-trigger">
        <i class="mdi mdi-information-outline mdi-18px mr-1"></i>
        <small class="mb-0 text-muted">{{'LEGEND' | translate}}</small>
      </div>

    </div>
  </div>
</div>
