import { hasPropertyOf }         from '@cs/core/utils';
import { DataDescribed }         from '../data-described/data-described.model';
import { LayoutAnnotationTable } from './layout-annotation-table.model';
import { DataGroup }             from './data-described-data-group.model';

export class TableDataDescribed<T> extends DataDescribed<T, LayoutAnnotationTable<T>> {
	dataGroups: DataGroup<T>[];

	constructor(init: Partial<TableDataDescribed<T>>) {
		super(init);
		this.dataGroups = hasPropertyOf(init, 'dataGroups') ? init.dataGroups.map(value => new DataGroup<T>(value)) : [];
	}
}

