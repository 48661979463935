import { FormControl } from '@angular/forms';

import { PropertyDataNode } from '../node-types/property-data-node';

export class BaseInput extends FormControl {
  public showRemarkField = false;
  public node;
  public data: PropertyDataNode;
  public metaData = {};
  public controlName: string;

  public constructor(data: any) {
    super(data.node.value);

    this.controlName = data.controlName;
    this.data = new PropertyDataNode(data.node.data);
    this.node = data.node;

    this.valueChanges.subscribe(() => this.onChange());
  }

  public onChange() {
    if (this.node.propertyType.useToggle) {
      this.data.enabled = this.value;
      return;
    }

    if (this.node.propertyType.useIntValue) {
      this.data.intValue = this.value;
      return;
    }

    if (this.node.propertyType.useDecimalValue) {
      this.data.decimalValue = this.value;
      return;
    }

    if (this.node.propertyType.useTextValue) {
      this.data.textValue = this.value;
      return;
    }

    if (this.node.propertyType.useDataValue) {
      this.data.dataValue = this.value;
      return;
    }
  }

  public toggleRemark() {
    this.showRemarkField = !this.showRemarkField;
  }

  public onRemarkChange(e) {
    this.data.remark = e.target.value;
    this.markAsDirty();
  }

  public setMetaData(key: string, data: any) {
    this.metaData[key] = data;
  }
}
