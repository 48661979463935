import { isNullOrUndefined } from '@cs/core';

/**
 * Created by alex on 22-6-2017.
 */
export class Logger {
	static enabled: boolean      = true;
	static getBuildinfo: boolean = false;

	static ThrowError(msg) {
		// TODO: Add client side error logging to server
		//throw new Error(msg);
		console.log(msg);
	}

	static Debug(consoleMsg) {
		if (Logger.enabled) {
			console.log(consoleMsg);
		}
	}

	static Warning(msg: string | Object) {
		console.warn(msg);
	}

	static hasValue(value: any, propertyName: string, warning = false) {
		if (isNullOrUndefined(value) || value.length === 0) {
			const msg = `No ${propertyName} found`;
			warning ? Logger.Warning(msg) : Logger.ThrowError(msg);
			return false;
		} else {
			return true;
		}
	}

	static Info(consoleMsg: string) {
		if (Logger.enabled) {
			console.info(consoleMsg);
		}
	}
}
