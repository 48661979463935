import { RepositoryDto }                             from '../models/RepositoryDto';
import { FilterValuesLoosely, FilterValuesStrictly } from './RepositoryFilter';
import { GitTableNavigationFilter }                  from '../models/GitTableNavigationFilter';

export function getWeight(repository: RepositoryDto): number {
	const weight = (repository.commitsBehind / 60) * (monthsAgo(repository.lastCommitDate) / 0.8);

	return weight <= 3
		   ? weight
		   : 3;
}

export function getWeightColour(repository: RepositoryDto): string {
	if (repository != undefined) return getBadgeBasedOnThreshold(getWeight(repository));
}

export function getBadgeBasedOnThreshold(threshHold: number): string {
	switch (true) {
		case threshHold <= 1:
			return 'badge badge--success';
		case threshHold > 1 && threshHold <= 2:
			return 'badge badge--info';
		case threshHold > 3:
			return 'badge badge--warning';
		default:
			return 'badge badge--error';
	}
}

export function mapRepositoriesByLabel(repositories: RepositoryDto[]): Map<string, RepositoryDto[]> {
	const result: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

	repositories.forEach(repository => {

		if (result.has(repository.label)) {
			result.get(repository.label)
				  .push(repository);
		} else {
			result.set(repository.label, [repository]);
		}

	});

	return result;
}

export function mapRepositoriesByName(repositories: RepositoryDto[]): Map<string, RepositoryDto[]> {
	const result: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

	repositories.forEach(repository => {

		if (result.has(repository.name)) {
			result.get(repository.name)
				  .push(repository);
		} else {
			result.set(repository.name, [repository]);
		}

	});

	return result;
}

export function formatDate(date: Date): string {

	const day   = date.getDate()
					  .toString()
					  .padStart(2, '0'); // Get day and pad with leading zero if necessary
	const month = (date.getMonth() + 1).toString()
									   .padStart(2, '0'); // Get month (0-based) and pad with leading zero if necessary
	const year  = date.getFullYear()
					  .toString(); // Get full year

	return `${day}/${month}/${year}`;
}

// ---------------- Filter logic below ---------------- \\

export function filterRepositoryMap(arrayToFilter: Map<string, RepositoryDto[]>, parameterFilterValues: GitTableNavigationFilter, formFilterValues: GitTableNavigationFilter, noCommitsBehind: boolean) {
	let result = deepCopyMap(arrayToFilter);

	if (parameterFilterValues != undefined) result = filterMapValuesStrict(result, parameterFilterValues);

	if (formFilterValues != undefined) result = filterMapValuesLoose(result, formFilterValues);

	if (noCommitsBehind) result = filterMapCommitsBehind(result);

	return result;
}

function filterMapCommitsBehind(mapToFilter: Map<string, RepositoryDto[]>) {

	function filterRepositoriesWithCommitsBehind(repositories: RepositoryDto[]) {
		const repositoryResult: RepositoryDto[] = [];

		repositories.forEach(repository => {
			if (repository.subModules.length > 0) repository.subModules = filterRepositoriesWithCommitsBehind(repository.subModules);

			if (repository.commitsBehind > 0 || repository.subModules.length > 0) repositoryResult.push(repository);
		});

		return repositoryResult;
	}

	const result = new Map<string, RepositoryDto[]>();

	Array.from(mapToFilter.keys())
		 .forEach(submoduleKey => {

			 const repositoryDto = mapToFilter.get(submoduleKey)
											  .filter(repository => {
												  repository.subModules = filterRepositoriesWithCommitsBehind(repository.subModules);
												  if (repository.subModules.length > 0) return repository;
											  });

			 if (repositoryDto) result.set(submoduleKey, repositoryDto);
		 });

	return result;
}


export function filterMapValuesLoose(mapToFilter: Map<string, RepositoryDto[]>, filterValues: GitTableNavigationFilter) {
	const result = new Map<string, RepositoryDto[]>();
	Array.from(mapToFilter.keys())
		 .forEach(submoduleKey => {

			 const resultFilter = filterArrayValuesLoose(mapToFilter.get(submoduleKey), filterValues);

			 if (resultFilter.length > 0) result.set(submoduleKey, resultFilter);
		 });

	return result;
}


export function filterMapValuesStrict(mapToFilter: Map<string, RepositoryDto[]>, filterValues: GitTableNavigationFilter) {
	const result = new Map<string, RepositoryDto[]>();

	Array.from(mapToFilter.keys())
		 .forEach(submoduleKey => {

			 const resultFilter = filterArrayValuesStrict(mapToFilter.get(submoduleKey), filterValues);

			 if (resultFilter.length > 0) result.set(submoduleKey, resultFilter);
		 });

	return result;
}


export function deepCopyMap(arrayToFilter: Map<string, RepositoryDto[]>): Map<string, RepositoryDto[]> {
	let result = new Map<string, RepositoryDto[]>();

	Array.from(arrayToFilter.keys())
		 .forEach(submoduleKey => {
			 result.set(submoduleKey, JSON.parse(JSON.stringify(arrayToFilter.get(submoduleKey))));
		 });

	return result;
}

export function filterArrayValuesLoose(arrayToFilter: RepositoryDto[], filterValues: GitTableNavigationFilter): RepositoryDto[] {
	let result: RepositoryDto[];

	result = FilterValuesLoosely(arrayToFilter, filterValues);

	return result;
}


export function filterArrayValuesStrict(arrayToFilter: RepositoryDto[], filterValues: GitTableNavigationFilter): RepositoryDto[] {
	let result: RepositoryDto[];

	result = FilterValuesStrictly(
		arrayToFilter,
		filterValues);

	return result;
}

export function monthsAgo(inputDate: Date): number {
	inputDate = new Date(inputDate);

	const currentDate = new Date();

	return (currentDate.getFullYear() - inputDate.getFullYear()) * 12 + currentDate.getMonth() - inputDate.getMonth();
}

export function calculateMonthsAgo(inputDate: Date): string {
	const months = monthsAgo(inputDate);

	return formatMonthsAgoString(months);
}

export function formatMonthsAgoString(monthsAgo: number): string {
	if (monthsAgo === 0) {
		return 'This month';
	} else if (monthsAgo === 1) {
		return '1 month ago';
	} else {
		return monthsAgo + ' months ago';
	}
}

// Hacky checks to see if any repository has any recurisve child repositories, i.e. child repos that have child repos
export function hasRecursiveChildRepositories(parentRepositoryMap: Map<string, RepositoryDto[]>): boolean {

	let result = false;

	for (let parentRepositoryMapElement of parentRepositoryMap) {

		const valueMap = parentRepositoryMapElement[1];

		for (let repositoryDto of valueMap) {

			if (repositoryDto.subModules.some(s => s.subModules.length > 0)) {
				result = true;
				break;
			}
		}

		if (result) break;
	}

	return result;
}

export function sortRepositoryArrayByCommitDifference(repositoryArray: RepositoryDto[]) {
	repositoryArray.sort((a, b) => b.commitsBehind - a.commitsBehind);
}


export function sortRepositoryArrayByBranch(repositoryArray: RepositoryDto[], branchOrder: string[]) {
	repositoryArray.sort((a, b) => branchSort(branchOrder, a, b));
}

export function branchSort(branchOrder: string[], a: RepositoryDto, b: RepositoryDto): number {
	const branchA: string = a.branch;
	const branchB: string = b.branch;

	const labelA: string = a.label;
	const labelB: string = b.label;

	// First, compare labels
	const labelComparison = labelA.localeCompare(labelB);

	if (labelComparison !== 0) {
		return labelComparison;
	}

	// If labels are the same, check branch order
	const orderA = branchOrder.indexOf(branchA);
	const orderB = branchOrder.indexOf(branchB);

	if (orderA !== -1 && orderB !== -1) {
		// Both branches are in the order, compare their order
		return orderA - orderB;
	} else if (orderA !== -1) {
		// Only branchA is in the order
		return -1;
	} else if (orderB !== -1) {
		// Only branchB is in the order
		return 1;
	} else {
		// Neither branch is in the order, sort by branch names
		return branchA.localeCompare(branchB);
	}
}

