import { RenderOrientation }     from './render-orientation';
import { AllDashboardPanelType } from './dashboard-models';

export class DashboardGridColumn {
	panels: AllDashboardPanelType[] = [];
	/**
	 * The boostrap grid colspan amount
	 */
	colspan: number;
	/**
	 * The way the panel needs to be rendered
	 */
	renderOrientation: RenderOrientation;
	private _id: string;
	get id() {
		if (this._id == null)
			this._id = this.panels.map(value => value.name)
										 .join('_');
		return this._id;
	}
}
