import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';
import { LayoutOrientation }            from './layout-orientation.model';
import { LabelPosition }                from './label-position.model';

export class FormLayoutAnnotation {
	/**
	 * Render direction of the group elements
	 */
	groupOrientation?: LayoutOrientation;
	/**
	 * Show the state of a valid field
	 */
	showValidState?: boolean;
	/**
	 * Render direction of the form elements
	 */
	orientation?: LayoutOrientation;
	/**
	 * Position of the label of each Widget
	 */
	labelPosition?: LabelPosition;
	/**
	 * Don't add the id field to the rendering process
	 */
	ignoreIdColumn?: boolean;

	/**
	 * Flag to set all the fields in the form to disabled
	 */
	readOnly?: boolean;

	/**
	 * Flag to set all the readOnly fields as text
	 */
	readOnlyAsText?: boolean;
	/**
	 * Flag to show the fields that are marked as key
	 */
	includeKeyFields?: boolean;
	/**
	 * Use this label for the ungrouped form fields if other groups are defined
	 */
	defaultFieldSetGroupName?: string;
	/**
	 * flag used by parent to show a reset button
	 */
	showResetButton?: boolean;
	/**
	 * flag used by parent to show a button bar
	 */
	showButtons?: boolean;
	/**
	 * flag used by parent to show a close MatDialog button
	 */
	showSaveAndCloseButton?: boolean;
	/**
	 * flag used by parent to show a close button
	 */
	showCloseButton?: boolean;

	constructor(init: Partial<FormLayoutAnnotation>) {
		this.showValidState           = getPropertyOf(init, 'showValidState', false);
		this.ignoreIdColumn           = getPropertyOf(init, 'ignoreIdColumn', true);
		this.readOnly                 = getPropertyOf(init, 'readOnly', false);
		this.readOnlyAsText           = getPropertyOf(init, 'readOnlyAsText', false);
		this.defaultFieldSetGroupName = getPropertyOf(init, 'defaultFieldSetGroupName', '');
		this.includeKeyFields         = getPropertyOf(init, 'includeKeyFields', false);
		this.showSaveAndCloseButton   = getPropertyOf(init, 'showSaveAndCloseButton', true);
		this.showCloseButton          = getPropertyOf(init, 'showCloseButton', false);
		this.showButtons              = getPropertyOf(init, 'showButtons', true);

		this.orientation      = hasPropertyOf(init, 'orientation')
														?
														init.orientation.toLowerCase() as LayoutOrientation
														: LayoutOrientation.Vertical;
		this.groupOrientation = hasPropertyOf(init, 'groupOrientation')
														?
														init.groupOrientation.toLowerCase() as LayoutOrientation
														: LayoutOrientation.Horizontal;

		this.labelPosition = hasPropertyOf(init, 'labelPosition')
												 ?
												 init.labelPosition.toLowerCase() as LabelPosition
												 : LabelPosition.Left;
	}
}
