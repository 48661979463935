<div class="compare-container">
  <div class="compare-container-wrapper"
       [@toggleCompareBar]="isCompareMode" (@toggleCompareBar.done)="toggleAnimationDone($event)">
    <ng-container *ngTemplateOutlet="filterRow; context: {data: mainBar}"></ng-container>

    <ng-container *ngTemplateOutlet="filterRow; context: {data: compareBar, isCompareBar:true}"></ng-container>

  </div>

  <div class="row button-row">
    <div class="col">
      <div class="button-container">
        <div [ngStyle]="{'opacity': hasCompare? 1: 0,     'pointer-events': hasCompare ? 'all' : 'none' }">
          <button type="button"
                  id="compareButton"
                  style="margin: 0"
                  class="btn btn-quaternary btn--sm">
            <div class="compare-bar-button-content cs-loader-btn" [class.is-loading]="isLoadingCompareBar">

              <div class="loader-container extend-left">
                <div class="loader"></div>
              </div>
              <i class="mdi mr-1" [ngClass]="{'mdi-plus': !isCompareMode,'mdi-minus':isCompareMode}"></i>
              {{compareLabel}}
            </div>
          </button>
        </div>

      </div>

    </div>
  </div>
  <div *ngIf="hasCompare" class="background-curtain-container"></div>
</div>

<ng-template #filterRow let-data="data" let-isCompareBar="isCompareBar">
  <div class="filter-container" [class.pb-0]="isCompareBar"><!--[@listAnimation]="data?.state"-->
    <!--trackBy: dropdownTracking-->
    <div *ngFor="let dropdown of data?.renderedDropDowns; let i = index;"
         [class.hide-arrow]="dropdown?.isLoading"
         class="col filter-dropdown-container {{'dropdown_'+i}}" [ngStyle]="getWidth()">
      <ng-container *ngIf="dropdown.displayType === 'Default'">
        <cs-advanced-dropdown [selected]="dropdown.isMultiSelect ? null : state[dropdown.identifier]"
                              [dataSource]="dropdown"
                              [ngStyle]="data?.getStyle(dropdown)"
                              [selectMultiple]="dropdown.isMultiSelect"
                              [invertedStyling]="invertStyling"
                              (selectionChanged)="onSelectionChanged(data, $event, dropdown)"
                              (searchInputChanged)="onSearchChanged(data, $event, dropdown)"
                              (dropdownOpenStateChanged)="onDropdownOpenChanged($event)">
        </cs-advanced-dropdown>
      </ng-container>
      <ng-container *ngIf="dropdown.displayType === 'DatePicker'">
        <cs-datepicker [isInFilterBar]="true" outputFormat="iddate" [title]="dropdown.label"
                       [identifier]="dropdown.identifier" [ngModel]="dropdown.selectedValue"
                       [invertedStyling]="invertStyling"
                       [showButtons]="false" (selectionChanged)="onSelectionChanged(data,$event, dropdown)">
        </cs-datepicker>
      </ng-container>
      <ng-container *ngIf="dropdown.displayType === 'Stub'">
        <div class="col stub-filter-col">
          <div class="stub-filter">

          </div>
        </div>
      </ng-container>
      <div class="loader-small-container">
        <div class="loadernavbar"></div>
      </div>
    </div>

  </div>
</ng-template>
