import { DataEntryPredictionConfigService } from './data-entry-prediction-config.service';
import { Inject }                           from '@angular/core';

export abstract class  PredictionAgent {
  // abstract getPredicationInfo(dataGridName: string,
  //                             selection: { [key: string]: any },
  //                             params?: { [key: string]: any }): Observable<PredictionData>;
}

export class DefaultPredictionAgent extends PredictionAgent {
  constructor(@Inject(DataEntryPredictionConfigService) private config: DataEntryPredictionConfigService) {
    super();
  }

  // getPredicationInfo(dataGridName: string, selection: { [p: string]: any }, params: { [p: string]: any } = {}) {
  //   return this.config.getPredicationInfo(dataGridName, selection, params)
  //              .pipe(
  //                map(value => value.value)
  //              );
  // }


}
