<ng-template #datasourcesList let-data="data" let-datasources="datasources" let-label="label" let-compare="compare"
			 let-comparelabel="comparelabel">
	<div>
		<div class="d-flex flex-row justify-content-between data-row">
      <span class="data-hover-label text-truncate">{{label}} <span
			  *ngIf="(hasCompareCell && hasCompareData) && compare === false"
			  class="offset-label"
			  matTooltip="Offset compared to {{comparelabel}}"
			  [matTooltipPosition]="'above'"
	  >({{((data.metaValues.relDifference - 1) * 100) | format:'{0:N2}'}} %)</span></span>
			<span class="data-hover-value border-bottom-1">{{data.value | format:'{0:N2}'}}</span>
		</div>
		<div class="d-flex flex-row justify-content-between data-source-item data-row"
			 *ngFor="let source of datasources">

    <span class="data-source-item-label text-truncate"
		  title="{{source.iddatasource | lookup:'IdDataSource'}}">
        <i class="mdi mdi-database mr-1"></i> {{source.iddatasource | lookup:'IdDataSource'}}
      </span>
			<span class="data-hover">{{source.value | format:'{0:N2}'}}</span>
		</div>
	</div>
</ng-template>

<div class="animated fadeIn tooltip-container {{setDirection}}">

	<div class="dark-tooltip dark small forecast-detail-hover-tooltip {{setDirection}} ">
		<div class="tooltip-content forecast-detail-hover">
			<ng-container
					*ngTemplateOutlet="datasourcesList; context: {data: data, datasources:datasources,
        label:(mainPresetLabel$ | async), compare:false, comparelabel:(comparebarPresetLabel$ | async)}"></ng-container>
			<ng-container *ngIf="hasCompareCell">
				<div style="height: 8px"></div>
				<ng-container
						*ngTemplateOutlet="datasourcesList; context: {data: data.compareCell,
          datasources:compareDatasources, label:(comparebarPresetLabel$ | async), compare:true}"></ng-container>
			</ng-container>
			<div *ngIf="(!hasCompareCell || !hasCompareData) && (comparebarPresetLabel$ | async)">
				<div class="d-flex flex-row justify-content-between data-row">
        <span class="data-hover-label text-truncate">
        <i class="mdi mdi-database mr-1"></i> {{(comparebarPresetLabel$ | async)}}</span>
					<span class="data-hover-value border-bottom-1">{{'NO_DATA' | translate | capitalize}}</span>
				</div>
			</div>
		</div>

	</div>
	<div class="tooltip-tip"></div>
</div>
