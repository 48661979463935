import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { CsAdvancedDropdownModule } from '@cs/components/advanced-dropdown';
import { TranslateModule }          from '@ngx-translate/core';

import { CsFilterAndCompareBarComponent }    from './filter-and-compare-bar.component';
import { CsDatepickerModule }                from '@cs/components/datepicker';
import { ComponentTranslationLoaderService } from '@cs/common';


@NgModule({
						imports:      [
							CommonModule,
							CsAdvancedDropdownModule,
							CsDatepickerModule,
							TranslateModule
						],
						declarations: [
							CsFilterAndCompareBarComponent
						],
						exports:      [
							CsFilterAndCompareBarComponent
						]
					})
export class CsFilterAndCompareBarModule {
	constructor(loader: ComponentTranslationLoaderService) {
		loader.registerComponentModule('filter-nav-bar');
	}
}



