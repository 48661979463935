import { ActivatedRoute, DefaultUrlSerializer, Router, UrlTree } from '@angular/router';
import { LoggerUtil }                                            from '@cs/core';
import { AppConstants }                                          from '@cs/performance-manager/shared';

/**
 * Default handler when login was successful
 * @param router instance of the Router
 * @param homeUrl redirect to this url after login when no return Url is present in the queryParams
 */
export function loginDefaultResultHandler(route: ActivatedRoute, router: Router, homeUrl: string,
																					excludePaths: string[][]) {
	try {
		let returnUrl = route.snapshot.queryParams[AppConstants.RETURN_URL] || homeUrl;

		for (const pathsegments of excludePaths) {
			const recoverUrl = pathsegments.join('/');

			// Test if the returnUrl is recover entry. If so remove it
			const regex    = new RegExp(`(${recoverUrl})(\\/?\\?{0}|\\/?\\?{1}.*)`);
			const hasMatch = returnUrl.match(regex);
			if (hasMatch != null && hasMatch.length > 0) {
				returnUrl = homeUrl;
			}
		}

		const urlObj: UrlTree = new DefaultUrlSerializer().parse(returnUrl);
		const goToUrl         = urlObj.root.children.primary.toString();
		router.navigate([goToUrl], {queryParams: urlObj.queryParams});
	} catch (e) {
		LoggerUtil.warn('Could not parse the return url, navigating to home');
		router.navigate([homeUrl]);
	}


}
