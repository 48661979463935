export type CsFormGeneratorEditMode = 'none' | 'single' | 'all' | 'editonly';
export type CsFormGeneratorLayout = 'vertical' | 'horizontal';

export interface CsFormGeneratorDataSource {
	/**
	 * Initial data for filling the form.
	 */
	data?: Object;

	/**
	 * The layout of the form elements including validations.
	 */
	form: CsFormGeneratorDataSourceForm;

	/**
	 * Lookups used for when filling what.
	 */
	lookups?: Array<CsFormGeneratorDataSourceLookup>;
}

export interface CsFormGeneratorDataSourceForm {
	elements: Array<CsFormGeneratorDataSourceFormGroup>;
}

export interface CsFormGeneratorDataSourceFormGroup {
	elements: Array<CsFormGeneratorDataSourceFormField>;
}

export interface CsFormGeneratorDataSourceLookup {
	name: string;
	values?: Array<CSFormGeneratorDataSourceLookupValues>;
	tree?: CSFormGeneratorDataSourceTree;
	dependsOn?: Array<string>;
	custom?: boolean;
	/**
	 * Default Value
	 */
	neutralValue?: any;
}

export interface CSFormGeneratorDataSourceTree {
	levels: { key: string, label: string }[];
	members: Array<CSFormGeneratorDataSourceTreeMember>;
}

export interface CSFormGeneratorDataSourceTreeMember {
	lookupKey?: string;
	group?: string;
	id?: number;
	label: any;
	selected?: boolean;
	depth?: number;
	hidden?: boolean;
	collapsed?: boolean;
	value?: any;
	key?: any;
	members?: Array<CSFormGeneratorDataSourceTreeMember>;
}

export interface CSFormGeneratorDataSourceLookupValues {
	id?: number;
	key: any;
	value: any;
	group?: number;
}

export interface CsFormGeneratorDataSourceFormField {
	hidden?: boolean;
	name: string;
	label?: string;
	help?: string;

	/**
	 * 'select' lookup binds default to a Array<{ id: number | string, name: string }>.
	 */
	uiType: string;
	placeholder?: string;
	lookup?: string;

	/**
	 * Actions for a field.
	 */
	fieldActions?: Array<string>;

	/**
	 * Validation rules for the Field.
	 */
	validation?: CsFormGeneratorDataSourceFormValidation;
	actions?: Array<CsFormGeneratorDataSourceFormAction>;
	/**
	 * Options for the Type Field Component.
	 */
	options?: any;

	/**
	 * Options for the Checkbox List/Grid / File select components
	 */
	elementParameters?: {
		height?: number;
		isScrollable?: boolean;
		showAsHorizontalList?: boolean;
		gridColumns?: number;
		search?: {
			hasSearchBar?: boolean;
			searchEndPoint?: string;
		}
		acceptExtMime?: string;
		maxFileSize?: number;
		levels?: Array<any>;
		default?: string;
	};
}


export interface CsFormGeneratorDataSourceFormAction {
	event: string;
	eventSource?: string;
	validators?: Array<any>;
	actions: Array<string>;
	actionsElse?: Array<string>;
}

export interface CsFormGeneratorDataSourceFormValidation {
	required: boolean;
	pattern: string;
}

export interface CsFormGeneratorSaveField {
	data: any;
	field: CsFormGeneratorDataSourceFormField;
}

export enum CsFormGeneratorActionEvent {
	onSelectionChanged = 'onSelectionChanged',
	onInit             = 'onInit',
	onEditMode         = 'onEditMode'
}

export enum CsFormGeneratorAction {
	disableField = 'disableField',
	enableField  = 'enableField',
	hideField    = 'hideField',
	showField    = 'showField'
}

export interface IDownloadRequestEventArgs {
	identifier: string;
}
