<div class="mt-4 user-profile">
	<div class="row">

		<div class="cs-panel-header">
			<div class="cs-panel-header--header flex flex-row">
				<label>{{'ACCOUNT' | translate | titlecase}}</label>
				<div [@isLoadingContent]="isLoadingContent" class="mr-2 loader"></div>
				<button type="button" class="btn btn--primary btn--md"
						*ngIf="changePasswordAvailable"
						[routerLink]="authenticationConfig.expiredPasswordUrl">
					{{'CHANGE_PASSWORD' | translate | titlecase}}
				</button>
			</div>

			<div class="cs-panel-header--content">
				<cs-form-generator
					#accountFormElement
					[dataSource]="accountForm"
					[showRevert]="false"
					[layout]="'horizontal'"
					[editMode]="'all'"
					[keepFormInEditMode]="true"
					[readOnly]="true"
					[isInEditMode]="true"
				>
					<!--  [readOnly]="accountForm?.data.isLDAP"-->
				</cs-form-generator>
			</div>
		</div>
	</div>
	<div class="row mt-4" *ngIf="otpModel?.authenticatorSupport">

		<div class="cs-panel-header">
			<div class="cs-panel-header--header flex flex-row">
				<label>{{'MULTI_FACTOR_AUTH' | translate | titlecase}}</label>
				<div [@isLoadingContent]="isLoadingContent" class="mr-2 loader"></div>
				<button *ngIf="!showQr && otpModel"
					matTooltip="{{'REMOVE_OTP' | translate | titlecase}}"
					[csLoader]="isWaitingForReset"
					[loaderAdditionalClasses]="['loader--white']"
					(click)="confirmDeleteOtp()"
					class="btn btn--red btn--md">
					<i class="mdi mdi-trash-can"></i>
				</button>
			</div>

			<div class="cs-panel-header--content flex-column flex">
				<ng-container *ngIf="!isLoadingContent">

					<div class="flex flex-align-items-center flex-column mt-2" *ngIf="!showQr">
						<h1 class="f-h1 mr-4 ml-4 text-align-center"><i class="mdi mdi-check-decagram text-success mr-2"></i>{{'LINKED_ALREADY' | translate}}</h1>
						<p class="f-body-s mt-2 text-align-center mr-5 ml-5">{{'REMOVE_OTP_MESSAGE' | translate}}
					</div>
					<div class="flex flex-align-items-center flex-column mt-2" *ngIf="showQr">
						<h1 class="f-h1 mr-4 ml-4 text-align-center">{{'LINK_TO_YOUR_COHELION_ACCOUNT' | translate}}</h1>
						<p class="f-body-s mt-2 text-align-center mr-5 ml-5"><span class="mr-2 badge badge--info">Step 1</span>{{'SCAN_QR_CODE' | translate}}
						<div id="qr-code">
							<!-- Placeholder for QR Code since HTML alone cannot generate a QR code -->
							<img src="{{otpModel?.imageDataUrl}}">
						</div>
						<a class="f-h4 f-color-dimmed" csHyperlink
						   (click)="showSecret=!showSecret">{{'CANT_SCAN_QRCODE'|translate}}</a>
						<div [ngbCollapse]="!showSecret" class="f-body-s p-2" [innerHTML]="manualAuthCodeMessageHtml" (click)="copyToClipboard()">
						</div>

						<p class="f-body-s mt-3 text-align-center mr-5 ml-5 ">
							<span class="mr-2 badge badge--info">Step 2</span>{{'ENTER_OTP_CODE' | translate}}</p>
						<div class="form-group mt-2">
							<i class="input-icon mdi mdi-key-variant"></i>
							<input class="form-control"
								   type="text"
								   name="username"
								   autocomplete="off"
								   placeholder="{{'ENTER_MFA_TOKEN' | translate}}"
								   [(ngModel)]="otpValue"
								   required/>
						</div>

						<div class="flex flex-align-items-center flex-column mt-4">
							<button
								[csLoader]="isWaitingForReset"
								[loaderAdditionalClasses]="['loader--white']"
								(click)="verifyOtp()"
								class="btn btn--primary btn--md">
								{{'FINISH_OTP_SETUP' | translate | titlecase}}
							</button>
						</div>
					</div>
				</ng-container>
			</div>

		</div>
	</div>
	<div class="row mt-4">
		<div class="cs-panel-header">
			<div class="cs-panel-header--header flex flex-row">
				<label>{{'PERSONAL_INFORMATION' | translate | titlecase}}</label>
			</div>

			<div class="cs-panel-header--content" [@isLoadingContent]="!isLoadingContent">
				<cs-form-generator
					[dataSource]="profileForm"
					[layout]="'horizontal'"
					[showRevert]="false"
					[editMode]="'all'"
					[keepFormInEditMode]="true"
					[isInEditMode]="true"
					(saveForm)="postPersonalInfo($event)"
				>
				</cs-form-generator>
			</div>
		</div>
		<div class="cs-panel-header">
			<div class="cs-panel-header--header flex flex-row">
				<label>{{'LOCATION' | translate | titlecase}}</label>
			</div>
			<div class="cs-panel-header--content" [@isLoadingContent]="!isLoadingContent">
				<cs-form-generator
					[dataSource]="locationForm"
					[layout]="'horizontal'"
					[showRevert]="false"
					[editMode]="'all'"
					[isInEditMode]="true"
					[keepFormInEditMode]="true"
					(saveForm)="postLocationInfo($event)"
				>
				</cs-form-generator>
			</div>
		</div>
	</div>
</div>
