import { NgModule }                   from '@angular/core';
import { IsStickyDetectorDirective }  from './directives/is-sticky-detector.directive';
import { CsWidthRulerDirective }      from './directives/width-ruler.directive';
import { SelectOnFocusDirective }     from './directives/select-on-focus.directive';
import { ClickOutsideDirective }      from './directives/click-outside.directive';
import { CsAutoFocusDirective }       from './directives/cs-auto-focus.directive';
import { LookupPipe }                 from './pipes/lookup.pipe';
import { LoaderDirective }            from './directives/loader-directive';
import { EscapeHtmlPipe }             from './pipes/html.pipe';
import { CsCultureModule }            from '@cs/common';
import { CsTemplateHandleDirective }  from './directives/cs-template-handle.directive';
import { CsTemplateLoaderDirective }  from './directives/cs-template-loader.directive';
import { MdiIconPipe }                from './pipes/mdi-icon.pipe';
import { CsComponentLoaderDirective } from './directives/component-loader.directive';
import { CsLengthRulerDirective }     from './directives/length-ruler.directive';

@NgModule({
						imports:      [CsCultureModule],
						exports:      [
							EscapeHtmlPipe, LookupPipe, ClickOutsideDirective, CsAutoFocusDirective, LoaderDirective,
							CsTemplateHandleDirective,
							CsComponentLoaderDirective,
							CsTemplateLoaderDirective, MdiIconPipe,
							SelectOnFocusDirective, CsCultureModule, CsWidthRulerDirective, IsStickyDetectorDirective, CsLengthRulerDirective
						],
						declarations: [
							EscapeHtmlPipe, LookupPipe, ClickOutsideDirective, CsAutoFocusDirective,
							CsComponentLoaderDirective,
							CsTemplateHandleDirective,
							CsTemplateLoaderDirective,
							MdiIconPipe, IsStickyDetectorDirective,
							LoaderDirective, SelectOnFocusDirective, CsWidthRulerDirective, CsLengthRulerDirective
						]
					}
)
export class CsSharedModule {
}
