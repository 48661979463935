import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';

export interface ErrorResponse {
  message?: string;
}

export class CsHttpErrorResponse extends HttpErrorResponse {
  error: ErrorResponse;
  isHandled: boolean;

  constructor(init: {
    error?: any;
    headers?: HttpHeaders;
    status?: number;
    statusText?: string;
    url?: string;
    isHandled: boolean;
  }) {
    super(init);
    this.isHandled = init.isHandled || false;
  }
}

export interface CsHttp400ErrorResponse {
  errors: { [key: string]: Array<string> };
  type: string;
  title: string;
  status: number;
  traceId: string;
}

