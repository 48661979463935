export type SelectionTargetEnum =
	'CurrentWindow'
	| 'NewWindow'
	| 'ModalWindow'
	| 'Download'
	| 'Navigate'
	| 'RegisteredAction'
	| 'HttpRequest'
	| 'PanelAction'
	| 'Application';
export const SelectionTargetEnum = {
	CurrentWindow:    'CurrentWindow' as SelectionTargetEnum,
	NewWindow:        'NewWindow' as SelectionTargetEnum,
	ModalWindow:      'ModalWindow' as SelectionTargetEnum,
	Download:         'Download' as SelectionTargetEnum,
	Navigate:         'Navigate' as SelectionTargetEnum,
	HttpRequest:      'HttpRequest' as SelectionTargetEnum,
	RegisteredAction: 'RegisteredAction' as SelectionTargetEnum,
	Application:      'Application' as SelectionTargetEnum,
	PanelAction:      'PanelAction' as SelectionTargetEnum
};
