import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, forwardRef } from '@angular/core';
import { DashboardEventHub }                                                                 from '@cs/components/dashboard';
import {
	DataEntryStateEventArgs
}                                                                                            from '@cs/components/data-entry-state/models/data-entry-state-event-args';
import { updateTargetSources }                                                               from '@cs/core/generate';
import { DataEntryStateStructure }                                                           from '../../models/data-entry-state-structure';
import { DashboardPanelComponentBase }                                                       from '@cs/components/shared';
import { ArrayUtils, DataDescribed }                                                         from '@cs/core';
import {
	DataEntryStateDataDescribed, DataEntryStateDataDescribedConverter
}                                                                                            from '../../data-entry-state-data-described.converter';

@Component({
			   selector:        'cs-dashboard-data-entry-state',
			   templateUrl:     './dashboard-data-entry-state.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class DashboardDataEntryStateComponent extends DashboardPanelComponentBase<DataEntryStateDataDescribed> implements OnInit {

	static TYPE_NAME = 'data-entry-state';
	renderSchema: DataEntryStateStructure;
	dashboardEventHub: DashboardEventHub;

	constructor(private changeRef: ChangeDetectorRef,
				@Inject(forwardRef(() => DashboardEventHub)) dashboardEventHub: any) {
		super();
		// Some weird storybook dependency issue
		this.dashboardEventHub = dashboardEventHub;
	}


	ngOnInit(): void {
	}

	update(data: DataEntryStateDataDescribed): void {

		if (this.data && data && ArrayUtils.isEqual(this.data.data, data.data))
			return;

		this.data = data;
	}

	dataChanged(value: DataEntryStateDataDescribed) {
		if (!(this.data instanceof DataDescribed))
			this.data = new DataDescribed(this.data);

		this.renderSchema = DataEntryStateDataDescribedConverter.convert(this.data);
		this.changeRef.detectChanges();
	}


	onActionRequestedHandler($event: DataEntryStateEventArgs): void {
		const clickedColumnProperty = this.data.getProperty($event.column.levelValue.toString());
		const result                = updateTargetSources({row: $event.row, column: clickedColumnProperty}, this.data, this.name, false);

		// if null do nothing
		if (result != null)
			this.dashboardEventHub.triggerDashboardEntryClicked(result);
	}
}
