<div class="data-entry-state-row flex" [class.flex-column]="orientation === 'COLUMN'">
	<div class=" data-entry-state-row__content">
<ng-container *ngFor="let column of rowContext.columns; trackBy:trackByStructureKey">
	<ng-container *ngIf="column?.visualState?.visible">
		<cs-data-entry-state-cell [column]="column"
								  [hasBadges]="badges.length>0"
								  [position]="rowPosition.getColumn(column.structureKey)"></cs-data-entry-state-cell>
		<div class="data-entry-state-row__content-column-spacer" style="min-width: 2px;"></div>
	</ng-container>
</ng-container>
</div>
<div class="cs-row" #collapse="ngbCollapse" [(ngbCollapse)]="isCollapsed">
	<div class="pt-1 cs-col-12 pr-0 pl-0 mb-1">
		<ng-container *ngFor="let badge of badges; trackBy:trackByBadge">
			<div class="badge--info badge">{{badge}}</div>
		</ng-container>
	</div>
</div>
</div>


