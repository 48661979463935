import { ClipboardModule }                        from '@angular/cdk/clipboard';
import { MatTooltipModule }                       from '@angular/material/tooltip';
import { TranslateModule }                        from '@ngx-translate/core';
import { ToastComponent }                         from './toast.component';
import { defaultToastConfig, TOAST_CONFIG_TOKEN } from './toast-manager.config';
import { ModuleWithProviders, NgModule }          from '@angular/core';
import { OverlayModule }                          from '@angular/cdk/overlay';
import { MatIconModule }                          from '@angular/material/icon';
import { CommonModule }                           from '@angular/common';
import { CsToastManagerService }                  from './toast-manager.service';
import { CsCommonPipesModule }                    from '@cs/common';

@NgModule({
						imports:         [
							CommonModule,
							CsCommonPipesModule,
							OverlayModule,
							MatIconModule,
							ClipboardModule,
							MatTooltipModule,
							TranslateModule
						],
						declarations:    [ToastComponent],
						entryComponents: [ToastComponent]
					})
export class CsToastManagerModule {
	public static forRoot(config = defaultToastConfig): ModuleWithProviders<CsToastManagerModule> {
		return {
			ngModule:  CsToastManagerModule,
			providers: [
				{
					provide:  TOAST_CONFIG_TOKEN,
					useValue: {...defaultToastConfig, ...config}
				},
				CsToastManagerService
			]
		};
	}
}
