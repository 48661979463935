import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RepositoryDto }                                  from '@cs/performance-manager/git-graph';
import {
	UpdateRepositoryType
}                                                         from '../../models/submodule-update-dialog.models';

@Component({
			   selector:    'pmc-submodule-update-result',
			   templateUrl: './submodule-update-result.component.html',
			   styleUrls:   ['../../submodule-update-dialog.component.scss']
		   })
export class SubmoduleUpdateResultComponent implements OnInit {

	// Update dialog settings
	type: UpdateRepositoryType = UpdateRepositoryType.warning;

	@Input() parentRepositories;
	@Input() errorLog;
	@Input() hasContinueEvent: boolean;

	@Output() continueEvent = new EventEmitter<Map<string, RepositoryDto[]>>();
	@Output() cancel        = new EventEmitter<void>();

	constructor() {

	}

	ngOnInit(): void {

	}

	createRepositoryMap(): Map<string, RepositoryDto[]> {
		const repositoriesFixed: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

		this.parentRepositories.forEach(repository => {
			if (repositoriesFixed.has(repository.name)) {
				repositoriesFixed.get(repository.name)
								 .push(repository);
			} else {
				repositoriesFixed.set(repository.name, [repository]);
			}
		});

		return repositoriesFixed;
	}

	updateEvent() {
		this.continueEvent.emit(this.createRepositoryMap());
	}

	onNoClick() {
		this.cancel.emit();
	}
}
