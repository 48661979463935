import { Component, OnInit, ChangeDetectionStrategy, Input, OnChanges, SimpleChanges } from '@angular/core';
import { StepState }                                                                   from '../../models';
import { ComponentChanges }                                                            from '@cs/core';

@Component({
						 selector:        'cs-wizard-step-icon',
						 templateUrl:     './wizard-step-icon.component.html',
						 styleUrls:       ['./wizard-step-icon.component.scss'],
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class WizardStepIconComponent implements OnInit,
																								OnChanges {

	@Input() icon: string;

	/**
	 * Flag indicating to show the connector
	 */
	@Input() showConnector: boolean;

	/**
	 * flag indicating in what state the step is
	 */
	@Input() state: StepState;

	constructor() {
	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<WizardStepIconComponent>): void {

	}


}
