import { CsHttpRequestOptions, getPropertyOf, Result } from '@cs/core';
import { BehaviorSubject }                             from 'rxjs/internal/BehaviorSubject';
import { noop }                                        from 'rxjs/internal/util/noop';

export class NotifyServerForChangesDashboardPanelEventArgs<T> {
	panelName: string;
	data: T;
	selection: { [key: string]: any };
	csHttpRequestOptions?: CsHttpRequestOptions;
	requestInProgress: BehaviorSubject<boolean>;
	callback?: (value: Result<any>) => void;

	constructor(init: NotifyServerForChangesDashboardPanelEventArgs<T>) {
		this.data                 = getPropertyOf(init, 'data');
		this.panelName            = getPropertyOf(init, 'panelName');
		this.selection            = getPropertyOf(init, 'selection');
		this.csHttpRequestOptions = getPropertyOf(init, 'csHttpRequestOptions', null);
		this.requestInProgress    = getPropertyOf(init, 'requestInProgress');
		this.callback             = getPropertyOf(init, 'callback', noop);
	}
}
