import { getPropertyOf } from '@cs/core/utils';


export class TableSettings {
	/**
	 * Show the allow the rows to be clicked
	 */
	isSelectable: boolean;

	/**
	 * Flag to turn on or off the automatic scroll detection
	 */
	hasHorizontalScrollEnabled: boolean;

	/**
	 * Render the table looking like a form
	 */
	asForm: boolean;

	constructor(init: Partial<TableSettings>) {
		this.isSelectable               = getPropertyOf(init, 'isSelectable');
		this.hasHorizontalScrollEnabled = getPropertyOf(init, 'hasHorizontalScrollEnabled');
		this.asForm                     = getPropertyOf(init, 'asForm', false);
	}
}
