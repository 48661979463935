

/**
 * Mask token class.
 * 


 */
export class MaskToken {
    matches;
    openGroup;
    isGroup;
    isOptional;
    isQuantifier;
    isAlternator;
    quantifier;
    constructor(isGroup?, isOptional?, isQuantifier?, isAlternator?) {
        this.matches = [];
        this.openGroup = isGroup || false;
        this.isGroup = isGroup || false;
        this.isOptional = isOptional || false;
        this.isQuantifier = isQuantifier || false;
        this.isAlternator = isAlternator || false;
        this.quantifier = {
            min: 1,
            max: 1
        };
    }
}
