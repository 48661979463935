import { Injectable }           from '@angular/core';
import { Observable }           from 'rxjs/internal/Observable';
import { Subject }              from 'rxjs';
import { skipWhile, takeWhile } from 'rxjs/operators';


export enum AppMessageType {
	REFRESH_DATA = 'REFRESH_DATA'
}

export class AppMessageResult<T> {
	result: T;
}

export class AppMessage<T = any, O = any> {
	readonly payload: T;
	readonly type: AppMessageType;
	readonly targetId: string | AppMessageType;
	readonly resultCallback: Observable<AppMessageResult<O>> | null;
}


@Injectable({providedIn: 'root'})
export class AppMessageHubService {

	private _messageHub: Subject<AppMessage> = new Subject<AppMessage>();

	registerForApplicationWideMessages(targetId: string = null) {
		return this._messageHub.pipe(takeWhile(value => {
			return targetId == null || (targetId != null && value.targetId === targetId);
		}));
	}

	publishMessageWaitResult<T = any, o = any>(appMessageType: AppMessageType, targetId?: string, payload?: T);
	publishMessageWaitResult<T = any, O = any>(appMessageType: string, targetId?: string, payload?: T): Observable<AppMessageResult<O>> {

		return new Observable(subscriber => {
			const callback = new Subject<AppMessageResult<O>>();
			this._messageHub.next({
				payload:        payload,
				targetId:       targetId,
				type:           AppMessageType[appMessageType],
				resultCallback: callback
			});

			callback.subscribe(result => subscriber.next(result),
				error => subscriber.error(error),
				() => subscriber.complete());

		});


	}

	publishMessage<T = any, o = any>(appMessageType: AppMessageType, targetId?: string, payload?: T);
	publishMessage<T = any, O = any>(appMessageType: string, targetId?: string, payload?: T): void {
		this._messageHub.next({
			payload:        payload,
			targetId:       targetId,
			type:           AppMessageType[appMessageType],
			resultCallback: null
		});
	}
}
