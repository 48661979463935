import {
  Input, TemplateRef, Directive
} from '@angular/core';

@Directive({
  selector: '[csTemplate]'
})
export class CsTemplate {
  @Input('csTemplate') type: string; // header | body

  constructor(public template: TemplateRef<any>) {
  }

  getType(): string {
    return this.type;
  }
}
