import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
	selector: '[cs-auto-focus]'
})
export class CsAutoFocusDirective implements AfterViewInit {
	constructor(private el: ElementRef) {
	}

	ngAfterViewInit() {
		this.el.nativeElement.focus();
	}
}
