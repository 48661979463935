<div class="cs-list"
	 [class.drag-disabled]="!canDrag || hasSelection"
	 cdkDropList
	 [cdkDropListData]="items"
	 [id]="listId"

	 [cdkDropListConnectedTo]="connectedLists"
	 [cdkDropListDisabled]="hasSelection"
	 (cdkDropListDropped)="drop($event)">
	<ng-container *ngFor="let item of items; trackBy:trackbyId">
		<cs-list-item [icon]="item.icon"
					  [label]="item.label"
					  [description]="item.description"
					  [superLabel]="item.superLabel"
					  [key]="item.id"
					  [isDisabled]="disabled"
					  [children]="item.children"
					  [itemTemplate]="template?.templateRef"
					  (onChange)="selectionChanged($event)"
					  [preSelectionCheck]="canToggleSelection"
					  [cdkDragDisabled]="!canDrag"
					  cdkDrag
		></cs-list-item>
	</ng-container>
</div>
