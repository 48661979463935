import { NgModule }                 from '@angular/core';
import { CommonModule }             from '@angular/common';
import { CsCultureModule }          from '@cs/common';
import { SingleIndicatorComponent } from './single-indicator.component';
import { TranslateModule }          from '@ngx-translate/core';
import { MatTooltipModule }         from '@angular/material/tooltip';

const COMPONENTS = [SingleIndicatorComponent];

@NgModule({
	declarations:    [...COMPONENTS],
	imports:         [
		CommonModule,
		TranslateModule,
		CsCultureModule,
		MatTooltipModule
	],
	exports:         [...COMPONENTS],
	entryComponents: [...COMPONENTS]
})
export class CsSingleIndicatorModule {
}
