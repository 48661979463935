/* tslint:disable */
export class InputMaskHelpers {
    static guid = 1;
    static keyCode = {
        ALT: 18,
        BACKSPACE: 8,
        BACKSPACE_SAFARI: 127,
        CAPS_LOCK: 20,
        COMMA: 188,
        COMMAND: 91,
        COMMAND_LEFT: 91,
        COMMAND_RIGHT: 93,
        CONTROL: 17,
        DELETE: 46,
        DOWN: 40,
        END: 35,
        ENTER: 13,
        ESCAPE: 27,
        HOME: 36,
        INSERT: 45,
        LEFT: 37,
        MENU: 93,
        NUMPAD_ADD: 107,
        NUMPAD_DECIMAL: 110,
        NUMPAD_DIVIDE: 111,
        NUMPAD_ENTER: 108,
        NUMPAD_MULTIPLY: 106,
        NUMPAD_SUBTRACT: 109,
        PAGE_DOWN: 34,
        PAGE_UP: 33,
        PERIOD: 190,
        RIGHT: 39,
        SHIFT: 16,
        SPACE: 32,
        TAB: 9,
        UP: 38,
        WINDOWS: 91,
        X: 88
    };
    static class2type = {
        '[object Array]': 'array',
        '[object Boolean]': 'boolean',
        '[object Date]': 'date',
        '[object Function]': 'function',
        '[object Number]': 'number',
        '[object Object]': 'object',
        '[object RegExp]': 'regexp',
        '[object String]': 'string'
    };
    static valHooks = {
        'option': {},
        'select': {},
        'radio': {},
        'checkbox': {}
    };
    static core_hasOwn = Object.prototype.hasOwnProperty;
    static type(obj) {
        if (obj == null) {
            return obj + '';
        }
        // Support: Android<4.0, iOS<6 (functionish RegExp)
        // tslint:disable-next-line:max-line-length
        return typeof obj === 'object' || typeof obj === 'function' ? InputMaskHelpers.class2type[toString.call(obj)] || 'object' : typeof obj;
    }

    static isFunction(obj) {
        return InputMaskHelpers.type(obj) === 'function';
    }
    static isArray(obj) {
        return Array.isArray ? Array.isArray(obj) : InputMaskHelpers.type(obj) === 'array';
    }

    static inArray(elem, arr, i?) {
        return arr == null ? -1 : Array.prototype.indexOf.call(arr, elem, i);
    }

    static clone(target, ...args) {
        let res = Object.assign(target, ...args);
        return res;
        // return JSON.parse(JSON.stringify(res));
    }
    static escapeRegex(str) {
        let specials = ['/', '.', '*', '+', '?', '|', '(', ')', '[', ']', '{', '}', '\\', '$', '^'];
        return str.replace(new RegExp('(\\' + specials.join('|\\') + ')', 'gim'), '\\$1');
    };
    static deepClone(..._args) {
        return extend(true, ..._args);
        function extend(...args) {
            let options, name, src, copy, copyIsArray, clone, target = args[0] || {},
                i = 1,
                length = args.length,
                deep = false;

            // Handle a deep copy situation
            if (typeof target === 'boolean') {
                deep = target;

                // Skip the boolean and the target
                target = args[i] || {};
                i++;
            }

            // Handle case when target is a string or something (possible in deep copy)
            if (typeof target !== 'object' && !InputMaskHelpers.isFunction(target)) {
                target = {};
            }

            // Extend jQuery itself if only one argument is passed
            if (i === length) {
                target = this;
                i--;
            }

            for (; i < length; i++) {
                // Only deal with non-null/undefined values
                if ((options = args[i]) != null) {
                    // Extend the base object
                    // tslint:disable-next-line:forin
                    for (name in options) {
                        src = target[name];
                        copy = options[name];

                        // Prevent never-ending loop
                        if (target === copy) {
                            continue;
                        }

                        // Recurse if we're merging plain objects or arrays
                        if (deep && copy && (InputMaskHelpers.isPlainObject(copy) || (copyIsArray = InputMaskHelpers.isArray(copy)))) {
                            if (copyIsArray) {
                                copyIsArray = false;
                                clone = src && InputMaskHelpers.isArray(src) ? src : [];

                            } else {
                                clone = src && InputMaskHelpers.isPlainObject(src) ? src : {};
                            }

                            // Never move original objects, clone them
                            target[name] = extend(deep, clone, copy);

                            // Don't bring in undefined values
                        } else if (copy !== undefined) {
                            target[name] = copy;
                        }
                    }
                }
            }

            // Return the modified object
            return target;
        }
    }

    static isPlainObject(obj) {
        // Not plain objects:
        // - Any object or value whose internal [[Class]] property is not "[object Object]"
        // - DOM nodes
        // - window
        if (InputMaskHelpers.type(obj) !== 'object' || obj.nodeType || (obj != null && obj === obj.window)) {
            return false;
        }

        // Support: Firefox <20
        // The try/catch suppresses exceptions thrown when attempting to access
        // the "constructor" property of certain host objects, ie. |window.location|
        // https://bugzilla.mozilla.org/show_bug.cgi?id=814622
        try {
            if (obj.constructor && !InputMaskHelpers.core_hasOwn.call(obj.constructor.prototype, 'isPrototypeOf')) {
                return false;
            }
        } catch (e) {
            return false;
        }

        // If the function hasn't returned already, we're confident that
        // |obj| is a plain object, created by {} or constructed with new Object
        return true;
    }

    static isArrayLike(obj) {

        // Support: real iOS 8.2 only (not reproducible in simulator)
        // `in` check used to prevent JIT error (gh-2145)
        // hasOwn isn't used here due to false negatives
        // regarding Nodelist length in IE
        let length = !!obj && 'length' in obj && obj.length,
            type = InputMaskHelpers.type(obj);

        if (type === 'function' || (obj != null && obj === obj.window)) {
            return false;
        }

        return type === 'array' || length === 0 ||
            typeof length === 'number' && length > 0 && (length - 1) in obj;
    }

    static each(obj: any[], callback: (i, el) => any, ...args) {
        let length, i: any = 0;

        if (InputMaskHelpers.isArrayLike(obj)) {
            length = obj.length;
            for (; i < length; i++) {
                if (callback.call(obj[i], i, obj[i]) === false) {
                    break;
                }
            }
        } else {
            for (i in obj) {
                if (callback.call(obj[i], i, obj[i]) === false) {
                    break;
                }
            }
        }

        return obj;
    }
    static map(arr: any[], fn: (i, el) => void) {
        function mapper(e, indx) {
            return fn(indx, e);
        }
        return arr.map(mapper);
    }

    static Event(src) {
        let originalEvent, type, isDefaultPrevented, timeStamp;
        // Event object
        if (src && src.type) {
            originalEvent = src;
            type = src.type;

            // Events bubbling up the document may have been marked as prevented
            // by a handler lower down the tree; reflect the correct value.
            isDefaultPrevented = src.defaultPrevented || src.defaultPrevented === undefined;

            // Event type
        } else {
            type = src;
        }
        // Create a timestamp if incoming event doesn't have one
        timeStamp = src && src.timeStamp || new Date().getTime();
    }

    static returnFalse() {
        return false;
    }

    static returnTrue() {
        return true;
    }

    static on(el, type, fn) {
        if (el.addEventListener) {
            el.addEventListener(type, fn, false);

        } else if (el.attachEvent) {
            el.attachEvent('on' + type, fn);
        }
    }
    static off(elem, type, handle) {
        if (elem.removeEventListener) {
            elem.removeEventListener(type, handle, false);
        }
        let name = 'on' + type;

        if (elem.detachEvent) {

            // #8545, #7054, preventing memory leaks for custom events in IE6-8
            // detachEvent needed property on element, by name of that event, to properly expose it to GC
            if (typeof elem[name] === typeof undefined) {
                elem[name] = null;
            }

            elem.detachEvent(name, handle);
        }
    }
}
