import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ReportingComponent} from './reporting.component';
import {ReportingDashboardComponent} from './reporting-dashboard/reporting-dashboard.component';


const routes: Routes = [
	{
		path: '',
		component: ReportingComponent,
		children: [{
			path: 'dashboard',
			component: ReportingDashboardComponent
		}]
	},
	{
		path: ':categoryId/report/:reportId',
		component: ReportingComponent
	}
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ReportingRoutingModule {
}
