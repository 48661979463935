import { Component }             from '@angular/core';
import { ReadOnlyControlWidget } from '../models/read-only-control-widget.directive';

@Component({
	selector: 'label-widget',
	template: `
				<div class="form-group">
					<div class="read-only-form-control">
						<ng-container *ngIf="!isMultiSelect">
							<label>{{controlValue}}</label>
						</ng-container>
						<ng-container *ngIf="isMultiSelect">
        <span class="badge badge-primary"
			  *ngFor="let value of controlValue">
          {{value}}
        </span>
						</ng-container>
					</div>
					<div class="pm-form-element-invalid-feedback" *ngIf="errorMessages?.length> 0 ">
						<small class="{{error.type}}" *ngFor="let error of errorMessages">{{error.errorMessage}}</small>
					</div>
				</div>
						`
})
export class LabelWidgetComponent<T> extends ReadOnlyControlWidget<T> {


}
