import { BaseInput } from './base-input';

export class MapsInput extends BaseInput {
  public mapOptions = {
    center: {lat: 0, lng: 0},
    zoom: 12
  }

  public constructor(data: any) {
    super(data);

    let valueParts = this.value.split('|');
    this.mapOptions = {
      center: {
        lat: parseFloat(valueParts[0]) || 0,
        lng: parseFloat(valueParts[1]) || 0,
      },
      zoom: parseInt(valueParts[2]) || 12
    }
  }

  public onDrag(e) {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    let zoom = e.target.map.zoom;
    let value = lat + '|' + lng + '|' + zoom;
    this.setValue(value);
    this.markAsDirty();
  }
}
