import { Location }                                                            from '@angular/common';
import { HttpErrorResponse }                                                   from '@angular/common/http';
import { ChangeDetectorRef, Component, forwardRef, Inject, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router }                                              from '@angular/router';
import { WaitingForResponse }                                                  from '@cs/common';
import { CsToastManagerService }                                               from '@cs/components/toast-manager';
import { CsHttpRequestOptions, isNullOrUndefined }                             from '@cs/core';
import { LoginQuery, LoginService }                                            from '@cs/performance-manager/login';
import { AuthenticationService }                                               from '@cs/performance-manager/shared';
import { UntilDestroy, untilDestroyed }                                        from '@ngneat/until-destroy';
import { TranslateService }                                                    from '@ngx-translate/core';
import { Subject }                                                             from 'rxjs';
import { debounceTime, tap }                                                   from 'rxjs/operators';
import { CsPasswordPolicyGroup, PasswordPolicyGroup }                          from '../models/password-policy.models';
import { ResetPasswordConfigService }                                          from '../reset-password-config.service';

@UntilDestroy()
@Component({
			   selector   : 'pmc-password-change',
			   templateUrl: './password-change.component.html'
		   })
export class PasswordChangeComponent implements OnInit,
												OnDestroy {

	/**
	 * The state of the form. Defaults to true because it's empty
	 */
	isErrorState = true;

	/**
	 * Binding the field resetCode
	 */
	resetcode = null;

	/**
	 * Binding the username field (readonly)
	 */
	username = '';

	/**
	 * Binding the password field
	 */
	newPassword = '';

	/**
	 * Indicator for server side password strength validation
	 */
	newPasswordAcceptable = false;
	/**
	 * Binding the password passwordCheck
	 */
	newPasswordCheck      = '';

	/**
	 * Bind the old password
	 */
	oldPassword = '';

	/**
	 * Require reset code send by email. False render regular password change form with old password
	 */
	requireResetCode   = false;
	/**
	 * Require old password to be filled
	 */
	requireOldPassword = true;

	/**
	 * User is authenticated
	 */
	userIsAuthenticated = false;

	/**
	 * Array of feedback messages concerning password
	 */
	userFeedbackMessages: string[] = [];

	/**
	 * Nested array of password policies
	 */
	passwordPolicyList: PasswordPolicyGroup[] = [];
	formChecksBase                            = [];
	formChecksResetCode                       = [];
	formChecksOldPassword                     = [];
	toastMessages                             = {};

	//#region Loaders
	isWaiting = WaitingForResponse.new();

	//#endregion
	get showUsername() {
		return this.filledResetCode && this.username;
	}

	get filledResetCode() {
		return !this.requireResetCode || (!(isNullOrUndefined(this.resetcode) || this.resetcode.length === 0 || /^\s+$/.test(this.resetcode)));
	}

	get filledNewPassword() {
		return !(isNullOrUndefined(this.newPassword) || this.newPassword.length === 0 || /^\s+$/.test(this.newPassword));
	}

	get showPasswords() {
		return this.filledResetCode && this.userIsAuthenticated && this.username;
	}

	constructor(private route: ActivatedRoute,
				private toastService: CsToastManagerService,
				private l8n: TranslateService,
				private location: Location,
				private authenticationService: AuthenticationService,
				@Inject(ResetPasswordConfigService) private resetPasswordConfig: ResetPasswordConfigService,
				@Inject(forwardRef(() => LoginService)) private loginService: LoginService,
				@Inject(forwardRef(() => LoginQuery)) private loginQuery: LoginQuery,
				private cdRef: ChangeDetectorRef,
				private router: Router
	) {
		this.l8n.get('NO_ACCESS')
			.subscribe(translation => this.fillFormChecks());
	}

	ngOnInit() {
		// Require ResetCode or use regular change password form
		this.requireResetCode   = this.route.snapshot.data.requireResetCode || false;
		this.requireOldPassword = this.route.snapshot.data.requireOldPassword == null
								  ? true
								  : this.route.snapshot.data.requireOldPassword;

		if (this.requireResetCode) {
			this.l8n.get('RESTORE_ACCOUNT')
				.subscribe(value => this.loginService.setTitleMessage(value));
			this.l8n.get('MSG_CHECK_EMAIL')
				.subscribe(value => this.loginService.setUnderTitleMessage(value));

			this.resetcode = this.route.snapshot.queryParamMap.get('Code');

			if (this.resetcode) {
				this.authenticateUsingResetCode();
			} else {
				// do nothing, just render the form and allow user to input the code manually
			}
		} else {
			this.loginService.setUnderTitleMessage('');
			this.l8n.get('CHANGE_PASSWORD')
				.subscribe(value => this.loginService.setTitleMessage(value));

			const options: CsHttpRequestOptions = new CsHttpRequestOptions({
																			   errorResponseHandler: (error) => this.handleDefaultErrorResponse(
																				   error)
																		   });
			this.resetPasswordConfig.initiatePasswordChange(options)
				.subscribe(value => {
					this.username = value.value.loginName;
					if (this.username)
						this.userIsAuthenticated = true;

					this.getPasswordPolicy();
				});
		}

		// initialize server-side password strength test with debounce
		this.passwordSubject.pipe(untilDestroyed(this), debounceTime(250))
			.subscribe(() => {
				this.serverSideValidatePassword();
			});

		// authenticate user with manual entry reset code
		this.resetcodeSubject.pipe(untilDestroyed(this), debounceTime(250))
			.subscribe(() => {
				this.authenticateUsingResetCode();
			});

		this.checkErrorState();
	}

	fillFormChecks() {
		this.formChecksBase = [
			{validate: () => this.newPassword.length > 0, errorMsg: this.l8n.instant('ERROR_NO_PASSWORD_PROVIDED')},
			{validate: () => this.newPasswordCheck.length > 0, errorMsg: this.l8n.instant('ERROR_NO_SECOND_PASSWORD_PROVIDED')},
			{validate: () => this.newPassword === this.newPasswordCheck, errorMsg: this.l8n.instant('ERROR_PASSWORDS_DO_NOT_MATCH')}
		];

		this.formChecksResetCode = [
			{validate: () => this.resetcode.length > 0, errorMsg: this.l8n.instant('ERROR_NO_RESET_CODE')}
		];

		this.formChecksOldPassword = [
			{validate: () => this.oldPassword.length > 0, errorMsg: this.l8n.instant('ERROR_NO_OLD_PASSWORD_PROVIDED')}
		];

		this.toastMessages = {
			NO_ACCESS                      : this.l8n.instant('NO_ACCESS'),
			PLEASE_LOGIN_FIRST             : this.l8n.instant('PLEASE_LOGIN_FIRST'),
			PASSWORD_IS_CHANGED            : this.l8n.instant('PASSWORD_IS_CHANGED'),
			PASSWORD_IS_CHANGED_CONTENT    : this.l8n.instant('PASSWORD_IS_CHANGED_CONTENT'),
			PASSWORD_CAN_NOT_BE_CHANGED    : this.l8n.instant('PASSWORD_CAN_NOT_BE_CHANGED'),
			PASSWORD_CAN_NOT_BE_CHANGED_MSG: this.l8n.instant('PASSWORD_CAN_NOT_BE_CHANGED_MSG',
															  {support: this.resetPasswordConfig.supportMail})
		};
	}

	/**
	 * Handle errors when user is not logged in
	 */
	handleAuthenticateUserErrors(error: HttpErrorResponse): boolean {
		if (error) {
			if (error.status === 401) { // unauthorized, need valid reset code or being logged in
				// redirect to login page
				this.toastService.show({
										   type   : 'error',
										   title  : this.toastMessages['NO_ACCESS'],
										   content: this.toastMessages['PLEASE_LOGIN_FIRST']
									   });
				this.router.navigate(['login']);
			}
		}

		// pass to default for displaying
		return this.handleDefaultErrorResponse(error);
	}


	/**
	 * Handle error responses by displaying as a list
	 */
	handleDefaultErrorResponse(error: HttpErrorResponse): boolean {
		// Handle documented errors
		if (error) {
			if (error.status === 401) { // unauthorized, need valid reset code or being logged in
				try {
					// we might received more useful messages in the body
					const result = JSON.parse(error.message);
					if (result.hasOwnProperty('messages')) {
						this.userFeedbackMessages = (<string[]>result.messages);
					}
				} catch (e) {
					if (error.error && error.error.hasOwnProperty('messages'))
						this.userFeedbackMessages = error.error.messages;
					else if (error.error)
						this.userFeedbackMessages = [error.error];
					else
						this.userFeedbackMessages = [this.l8n.instant('INVALID_RESETCODE')];
				}
				return true;
			} else if (error.status === 400) { // bad request: password not acceptable
				try {
					const result              = JSON.parse(error.message);
					this.userFeedbackMessages = (<string[]>result.messages);
				} catch (ex) {
					if (error.error && error.error.hasOwnProperty('messages'))
						this.userFeedbackMessages = error.error.messages;
					else if (error.error)
						this.userFeedbackMessages = [error.error];
					else
						this.userFeedbackMessages = [];
				}
				return true;
			} else if (error.status === 405) {
				this.toastService.show({
										   type           : 'info',
										   title          : this.toastMessages['PASSWORD_CAN_NOT_BE_CHANGED'],
										   content        : this.toastMessages['PASSWORD_CAN_NOT_BE_CHANGED_MSG'],
										   showProgressBar: false
									   });

				this.router.navigate(['/']);
				return true;
			}
		}
	}


	/**
	 * Check if user can be authenticated using resetcode
	 */
	authenticateUsingResetCode() {
		this.userFeedbackMessages = [];

		if (!this.filledResetCode) {
			return;
		}

		// check if reset code is valid
		const options: CsHttpRequestOptions = new CsHttpRequestOptions({
																		   errorResponseHandler: (error) => {
																			   this.username            = null;
																			   this.userIsAuthenticated = false;
																			   return this.handleDefaultErrorResponse(error);
																		   }
																	   });

		this.resetPasswordConfig.verifyResetCode(this.resetcode, options)
			.pipe(tap(this.isWaiting.start()))
			.subscribe((response) => {
				const result  = response.value;
				this.username = result.loginName;
				if (result.loginName === '') {
					this.userIsAuthenticated = false;
				} else {
					this.userIsAuthenticated = true;
					this.getPasswordPolicy();
				}

			});
	}

	/**
	 * Returns true if one or more form checks are invalid
	 */
	checkErrorState() {
		for (const check of this.formChecks()) {
			if (!check.validate()) {
				return this.isErrorState = true;
			}
		}
		this.cdRef.markForCheck();
		return this.isErrorState = false;
	}

	/**
	 * Returns client side form validation message
	 */
	getErrorStateMessage() {
		for (const check of this.formChecks()) {
			if (!check.validate()) {
				return check.errorMsg;
			}
		}
		return '';
	}

	/**
	 * Change the password of the authenticated user
	 */
	submit() {
		if (this.isErrorState || !this.newPasswordAcceptable)
			return;

		const options: CsHttpRequestOptions = new CsHttpRequestOptions({
																		   errorResponseHandler: (error) => this.handleDefaultErrorResponse(error)
																	   });

		if (this.requireOldPassword) {
			this.resetPasswordConfig.changePassword(
					this.resetcode,
					this.newPassword,
					this.newPasswordCheck,
					this.oldPassword,
					options)
				.pipe(tap(this.isWaiting.start()))
				.subscribe(response => {
					const result = response.value;
					if (result.success) {
						this.toastService.show({
												   type   : 'success',
												   title  : this.toastMessages['PASSWORD_IS_CHANGED'],
												   content: this.toastMessages['PASSWORD_IS_CHANGED_CONTENT']
											   });
						this.router.navigate(['/']);
					} else {
						this.userFeedbackMessages = result.messages;
					}
				});

		} else {
			this.resetPasswordConfig.setPassword(
					this.newPassword,
					this.newPasswordCheck,
					options)
				.pipe(tap(this.isWaiting.start()))
				.subscribe(response => {
					const result = response.value;
					if (result.success) {
						this.toastService.show({
												   type   : 'success',
												   title  : this.toastMessages['PASSWORD_IS_CHANGED'],
												   content: this.toastMessages['PASSWORD_IS_CHANGED_CONTENT']
											   });
						this.router.navigate(['/']);
					} else {
						this.userFeedbackMessages = result.messages;
					}
				});

		}
	}


	/**
	 * Called on NewPasswordChanged
	 */
	public checkNewPassword() {
		this.checkErrorState();
		this.passwordSubject.next();
	}

	/**
	 * Called on reset code changed
	 */
	public onResetCodeChanged() {
		this.checkErrorState();
		this.resetcodeSubject.next();
	}

	ngOnDestroy(): void {
	}

	goBackOfLogout() {

		if (this.requireResetCode) {
			this.authenticationService.logOut();
		} else {
			this.location.back();
		}

	}

	/**
	 * Observable for the password field
	 */
	private passwordSubject = new Subject<void>();

	/**
	 * Observable for the resetcode field
	 */
	private resetcodeSubject = new Subject<void>();

	/**
	 * Returns form validation checks based on required reset code.
	 */
	private formChecks() {
		let formChecks = [];

		if (this.requireResetCode) {
			formChecks = [...this.formChecksBase, ...this.formChecksResetCode];
		} else if (this.requireOldPassword === false) {
			formChecks = [...this.formChecksBase];
		} else {
			formChecks = [...this.formChecksBase, ...this.formChecksOldPassword];
		}

		return formChecks;
	}

	/**
	 * Get the password policy list. The list is user specific, and requires either a valid reset code or user login.
	 */
	private getPasswordPolicy() {
		if ((this.filledResetCode && this.requireResetCode) || !this.requireOldPassword)
			this.resetPasswordConfig.getPasswordPolicy(this.resetcode)
				.subscribe(response => {
					const result            = response.value;
					this.passwordPolicyList = result.map(group => {
						return new CsPasswordPolicyGroup(group);
					});
				});
	}

	/**
	 * Submit new password to server to get feedback on password strength. Only good/bad for now.
	 */
	private serverSideValidatePassword() {

		const options: CsHttpRequestOptions = new CsHttpRequestOptions({
																		   errorResponseHandler: (error) => this.handleDefaultErrorResponse(error)
																	   });

		this.newPasswordAcceptable = false;

		if (this.filledNewPassword)
			this.resetPasswordConfig.verifyPasswordWithPolicy(this.newPassword, this.resetcode, options)
				.subscribe(response => {
					const result = response.value;
					if (!isNullOrUndefined(result.success) && result.success === true) {
						this.newPasswordAcceptable = true;
					} else {
						this.newPasswordAcceptable = false;
					}
					this.userFeedbackMessages = !isNullOrUndefined(result.messages)
												? result.messages
												: [];
				});

	}
}
