import { NgModule }                from '@angular/core';
import { CommonModule }            from '@angular/common';
import { PopoverComponent }        from './popover.component';
import { PopoverService }          from './popover.service';
import { CsPopoverDirective }      from './cs-popover.directive';

const COMPONENTS = [PopoverComponent];

@NgModule({
  declarations:    [...COMPONENTS, CsPopoverDirective],
  imports:         [CommonModule],
  providers:       [PopoverService],
  entryComponents: [...COMPONENTS],
  exports:         [CsPopoverDirective,
                    ...COMPONENTS
  ]
})
export class CsPopoverModule {
}
