<div class="datepicker-wrapper datepicker-simple">
  <div class="input-group form-group" id="input-wrapper" (click)="disabled ? this.close() : this.toggle($event)">
    <span class="datepicker-title" *ngIf="title">{{title}}</span>
    <input #inputDp type="text" [disabled]="disabled" style="pointer-events: none;" autocomplete="off"
           class="form-control"
           [attr.placeholder]="placeholder"
           [(ngModel)]="dateModel" (focus)="onFocus(dp)" (blur)="onBlur(dp, $event)" ngbDatepicker
           #dp="ngbDatepicker"/>
  </div>
</div>


