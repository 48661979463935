import { Injectable }                          from '@angular/core';
import { DataGridUtils }                       from '../agents/data-grid.utils';
import { DataEntryState, DataEntryStateStore } from './data-entry-state.store';


@Injectable({providedIn: 'root'})
export class DataEntryStateService {

  constructor(private dataEntryStateStore: DataEntryStateStore) {
  }

  update(state: Partial<DataEntryState>) {
    this.dataEntryStateStore.update(state);
  }

  toggleEventsPanelVisibility() {
    const currentState = this.dataEntryStateStore._value().showEventPanel;
    this.update({showEventPanel: !currentState});
  }

  toggleChartPanelVisibility() {
    const currentState = this.dataEntryStateStore._value().isChartCollapsed;
    this.update({isChartCollapsed: !currentState});
  }

  disableChartPanel() {
    this.update({showChart: false});
  }

  showChartPanel() {
    const currentState = this.dataEntryStateStore._value().showChart;

    if (!currentState)
      this.update({showChart: true});
  }

  setScrollDetection(detection = false) {
    this.update({enableScrollDetection: detection});
  }

  setDataGridSubFilter(resultParams: { [key: string]: any }) {
    this.update({dataGridSubFilterParams: DataGridUtils.convertToSelectionKey(resultParams)});
  }
}
