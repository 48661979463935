import { CdkDragDrop, CdkDropList, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import {
	AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
	Component, ContentChild, EventEmitter, forwardRef, Host,
	Injector, Input, OnChanges, OnInit, Optional, Output, QueryList, Self, SimpleChanges, SkipSelf, ViewChild, ViewChildren
}                                                                       from '@angular/core';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	NgControl,
	RequiredValidator
}                                                                       from '@angular/forms';
import { types }                                                        from 'sass';
import { ListItem }                                                     from './models/list-item';
import {
	UntilDestroy,
	untilDestroyed
}                                                                       from '@ngneat/until-destroy';
import { noop }                                                         from 'rxjs';
import {
	ListItemComponent,
	ListItemContentDirective,
	ListItemChangeEventArgs
}                                                                       from './components/list-item';
import { ComponentChanges, isArray, LoggerUtil, whenChanging }          from '@cs/core';
import {
	ControlValidateValue,
	hasValidator
}                                                                       from '@cs/components/shared';

export interface ListChangeEventArgs {
	id: string;
	selectedItems: Array<string> | string;
}

const CS_LIST_PANEL_CONTROL_VALUE_ACCESSOR: any =
				[
					{
						provide:     NG_VALUE_ACCESSOR,
						useExisting: forwardRef(() => ListComponent),
						multi:       true
					}
				];


@UntilDestroy()
@Component({
						 selector:        'cs-list',
						 templateUrl:     './list.component.html',
						 changeDetection: ChangeDetectionStrategy.OnPush,
						 providers:       [CS_LIST_PANEL_CONTROL_VALUE_ACCESSOR]
					 })
export class ListComponent implements OnInit,
																			OnChanges,
																			AfterViewInit,
																			ControlValueAccessor {


	@Input() items: Array<ListItem>  = [];
	@Input() selected: Array<string> = null;

	@Input() selectMultiple = false;
	@Input() canDrag        = false;
	@Input() disabled                   = false;

	@Input() connectedToLists: Array<ListComponent> = [];

	@Output() onChange: EventEmitter<ListChangeEventArgs> = new EventEmitter<ListChangeEventArgs>();
	@Output() onDrop: EventEmitter<ListChangeEventArgs>   = new EventEmitter<ListChangeEventArgs>();

	@ContentChild(ListItemContentDirective) itemTemplate: ListItemContentDirective;
	@ViewChild(CdkDropList) cdkDropList: CdkDropList;


	required = false;

	get connectedLists() {
		return this.connectedToLists.map(x => x.cdkDropList);
	}

	get hasMultipleSelection(): boolean {
		if (this.listItems == null || this.innerValue == null)
			return false;

		return this.listItems.filter(item => item.isSelected).length > 1;
	}

	get hasSelection(): boolean {
		if (this.listItems == null || this.innerValue == null)
			return false;

		return this.listItems.find(item => item.isSelected) != null;
	}

	get template(): ListItemContentDirective {
		return this._overrideTemplate || this.itemTemplate;
	}

	get listId(): string {
		return this._listId;
	}

	/**
	 * get accessor
	 */
	get value(): Array<string> {
		return this.innerValue;
	}

	/**
	 * set accessor including call the onchange callback
	 */
	set value(v: Array<string>) {

		if (v !== this.innerValue) {
			this.innerValue = v;
			this.onChangeCallback(v);
		}
	}

	constructor(@Host() @Optional() required: RequiredValidator, private inj: Injector, private cdRef: ChangeDetectorRef) {
		this.required = required != null;
	}

	ngOnChanges(changes: ComponentChanges<ListComponent>): void {
		whenChanging(changes.selected, true)
			.execute(value1 => {
				this.value = value1.currentValue;
			});
	}

	/**
	 * Set template manual to cirumvent the itemtemplate to be undefined by @ContentChild
	 * @param _itemTemplate The template
	 */
	overrideTemplate(_itemTemplate: ListItemContentDirective) {
		this._overrideTemplate = _itemTemplate;
	}


	ngOnInit(): void {
		try {
			this.ngControl = this.inj.get(NgControl);
		} catch (ex) {
			LoggerUtil.debug('Not in a form');
		}
		//this.required  = hasValidator(this.ngControl, 'required');
	}


	ngAfterViewInit() {

		if (this.required && !(this.innerValue && this.innerValue.length > 0)) {
			const item = this.items[0];
			const comp = this.listItems.find(item1 => item1.key === item.id);
			comp.isSelected$.next(true);
			this.selectionChanged({key: item.id, value: true}, true);
			this.detectChanges();
		} else if (this.innerValue && this.innerValue.length > 0) {
			this.selectAll(this.innerValue);
		}
	}

	/**
	 * Set touched on blur
	 */
	onBlur() {
		this.onTouchedCallback();
	}

	/**
	 * From ControlValueAccessor interface
	 * @param value the value of the component
	 */
	writeValue(value: Array<string>) {
		if (!isArray(value))
			value = value === null
							? []
							: [value as unknown as string];

		if (value !== this.innerValue) {
			this.innerValue = value;
			this.selectAll(this.innerValue);
		}
	}

	/**
	 * From ControlValueAccessor interface
	 * @param fn change function
	 */
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	/**
	 * From ControlValueAccessor interface
	 * @param fn change function
	 */
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}


	detectChanges() {
		this.cdRef.detectChanges();
	}

	drop(event: CdkDragDrop<ListItem[]>) {
		if (event.previousContainer === event.container) {
			moveItemInArray(event.container.data,
											event.previousIndex,
											event.currentIndex);
		} else {
			transferArrayItem(
				event.previousContainer.data,
				event.container.data,
				event.previousIndex,
				event.currentIndex
			);

			this.onDrop.emit({id: this.listId, selectedItems: this.value});
		}

	}

	trackbyId(index: number, item: ListItem) {
		return item.id;
	}


	getSelected() {

		if (this.listItems == null)
			return [];

		// todo update to selected list
		const found = this.listItems.filter(item => item.isSelected)
											.map(value1 => {
												value1.isSelected$.next(false);
												const index = this.items.findIndex(value2 => value2.id === value1.key);
												return this.items.splice(index, 1)[0];
											});

		this.innerValue = [];
		this.detectChanges();

		return found;
	}

	disableAll() {

		this.listItems.forEach(item => item.isDisabled = true);
		this.detectChanges();

	}

	enableAll(innerValue: Array<string> = null) {

		if (this.listItems == null)
			return;

		if (innerValue) {

			innerValue.map(selectedValue => this.listItems.find(item => selectedValue === item.key))
								.forEach(item => item.isDisabled = false);
		} else
			this.listItems.forEach(item => item.isDisabled = false);
		this.detectChanges();

	}

	selectAll(innerValue: Array<string> = null) {

		if (this.listItems == null)
			return;

		if (innerValue) {

			if (isArray(innerValue)) {
				innerValue.map(selectedValue => this.listItems.find(item => selectedValue === item.key))
									.forEach(item => item.isSelected$.next(true));
			}

		} else
			this.listItems.forEach(item => item.isSelected$.next(true));
		this.detectChanges();

	}

	addItems(selected: ListItem[]) {
		this.items.unshift(...selected);
		this.detectChanges();
	}

	selectionChanged($event: ListItemChangeEventArgs, notify = true) {
		if (this.innerValue == null)
			this.innerValue = [];

		const selectedValue = $event.value;
		const key           = $event.key;

		// turn selected off when select multiple
		if (!this.selectMultiple && selectedValue)
			this.listItems.filter(selectedItem => key !== selectedItem.key && selectedItem.isSelected)
					.forEach(value1 => value1.isSelected$.next(false));
		try {
			if (!this.selectMultiple)
				this.innerValue = [];

			if (selectedValue && !this.innerValue.find(value1 => value1 === key))
				this.innerValue.push(this.items.find(value1 => value1.id === key)
																 .id
																 .toString());
			else if (!selectedValue && this.innerValue.find(value1 => value1 === key))
				this.innerValue.splice(this.innerValue.findIndex(value1 => value1 === key), 1);

		} catch (ex) {
			LoggerUtil.error(ex);
		}

		if (notify)
			this.notifyHasChanged();

		this.detectChanges();

	}

	canToggleSelection = (item: ListItemComponent) => {
		return item.isSelected && this.required
					 ? false
					 : true;

	};

	reset() {
		this.innerValue = [];
		this.enableAll(this.innerValue);
	}

	private ngControl: NgControl;

	private _overrideTemplate: ListItemContentDirective;

	@ViewChildren(ListItemComponent)
	private listItems: QueryList<ListItemComponent>;


	/**
	 * The internal data model
	 */
	private innerValue: Array<string> = [];

	private onTouchedCallback: () => void      = noop;
	private onChangeCallback: (_: any) => void = noop;
	private _listId                            = Math.random()
																									 .toString(2);

	private notifyHasChanged() {
		this.onChangeCallback(this.value);
		this.onTouchedCallback();
		this.onChange.emit({
												 id: this.listId, selectedItems: this.selectMultiple
																												 ? this.value
																												 : this.value[0]
											 });
	}


}

