export class BranchDto {
	branchName: string;
	commitHash: string;
	repositoryIdentifier: string;
	commitDifference: string;
	commitsBehind: string;
	commitsAhead: string;
	commitDate: Date;
	commitMessage: string;
	branchType : BranchType;
}

export enum BranchType{
	FEATURE,
	HOTFIX,
	RELEASE,
	MAIN,
	ETC
}
