import { Query }                                 from '@datorama/akita';
import { AuditTrailState, AuditTrailStateStore } from './audit-trail-state.store';
import { Injectable }                            from '@angular/core';

@Injectable({providedIn: 'root'})
export class AuditTrailStateQuery extends Query<AuditTrailState> {

	constructor(protected store: AuditTrailStateStore) {
		super(store);
	}

}
