<div class="table-container">
	<cs-table-nxt #grid
				  [data]="data"
				  [sortable]="isSortable"
				  [isLoading]="isLoading"
				  [height]="height"
				  class="{{clickTypeClass}}"
				  [class.is-clickable]="isClickable"
				  [selectable]="isEntityClickable"
				  [selectedPageSize]="selectedPageSize"
				  (sortingRequestServerSidePaging)="sortingServerSideDateRequest($event)"
				  (filterRequestServerSidePaging)="filterServerSideDateRequest($event)"
				  (newPageRequestServerSidePaging)="newPageServerSideDateRequest($event)"
				  (renderingIsDone)="updateView()"
				  (cellClick)="cellClicked($event)"
				  (rowMenuItemClicked)="entityMenuItemClicked($event)"
				  (rowClick)="rowClicked($event)"
				  (cellValueChanged)="valueChanged($event)"
	>
	</cs-table-nxt>
</div>

<div class="btn-group" *ngIf="grid?.state?.dirty">
	<div
			[matTooltip]="(grid?.state?.pristine ? 'NO_CHANGE_YET': (grid?.state?.invalid?'INVALID_FORM':'UNKNOWN')) | translate"
			[matTooltipDisabled]="grid?.state?.dirty && !grid?.state?.invalid">
		<button class="btn btn--icon-and-text btn--primary shadow-subtle mr-2"
				#saveBtn
				[csLoader]="requestInProgress$ | async"
				[disabled]="grid?.state?.pristine || grid?.state?.invalid"
				[loaderAdditionalClasses]="['loader--white']"
				(click)="notifyChangesToServer()">
			<i class="mdi mdi-content-save"></i>{{'SAVE' | translate}}
		</button>
	</div>
	<div [matTooltip]="'NO_CHANGE_YET' | translate"
		 [matTooltipDisabled]="grid?.state?.dirty"
	>
		<!--		<button class="btn btn&#45;&#45;white btn&#45;&#45;icon-and-text shadow-subtle"-->

		<!--				[disabled]="grid?.componentState?.pristine"-->
		<!--				*ngIf="data?.layout?.form?.layout?.showResetButton"-->
		<!--				(click)="cancelChanges()">-->
		<!--			<i class="mdi mdi-undo"></i>{{'UNDO_CHANGES' | translate}}-->
		<!--		</button>-->
	</div>

</div>
