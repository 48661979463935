import { HttpClient }                   from '@angular/common/http';
import {
	AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
	Component, OnDestroy, OnInit, ViewChild
}                                       from '@angular/core';
import {
	DomSanitizer, SafeHtml
}                                       from '@angular/platform-browser';
import { SafeMethods }                  from '@cs/common';
import {
	FilterCompareBarQuery, FilterCompareBarService
}                                       from '@cs/components/filter-and-compare-bar';
import {
	CsToastManagerService
}                                       from '@cs/components/toast-manager';
import { isNullOrUndefined }            from '@cs/core';
import {
	FilterAndNavbarShellConfigService
}                                       from '@cs/performance-manager/filter-and-navbar-shell';
import {
	ShellConfigService
}                                       from '@cs/performance-manager/shell';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService }             from '@ngx-translate/core';
import { Subscription }                 from 'rxjs';
import { filter as filter$ }            from 'rxjs/operators';


@UntilDestroy()
@Component({
			   selector       : 'pmc-iframe-teaser',
			   templateUrl    : './iframe-teaser.component.html',
			   styleUrls      : ['./iframe-teaser.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class IframeTeaserComponent implements OnInit, OnDestroy, AfterViewInit {

	@ViewChild('iframe', {static: false}) iframe: HTMLIFrameElement;

	srcUrl: SafeHtml;

	constructor(protected readonly toastService: CsToastManagerService,
				readonly filterCompareBarQuery: FilterCompareBarQuery,
				readonly filterCompareBarService: FilterCompareBarService,
				private readonly filterAndNavbarShellConfigService: FilterAndNavbarShellConfigService,
				public readonly changeRef: ChangeDetectorRef,
				protected readonly i8n: TranslateService,
				private sanitizer: DomSanitizer,
				private readonly shellConfig: ShellConfigService,
				private http: HttpClient) {
	}

	ngOnInit() {
		this.filterAndNavbarShellConfigService.setBodyClasses(this.bodyClasses);
		this.shellConfig.enableFooter = false;

		// Avoid some error on load
		this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');

		this.navbarChangeSubscription = this.filterCompareBarService.filterbarNavigationRequested.subscribe(value => {
			const {pageType, pageName, url} = value;
			if (pageType === 'TeaserPage') {
				this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
				SafeMethods.detectChanges(this.changeRef);
			}
		});
	}

	ngAfterViewInit(): void {
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(
				untilDestroyed(this),
				filter$(value => !isNullOrUndefined(value))
			)
			.subscribe((value: any) => {
				const {pageType, pageName, url} = value;
				if (pageType === 'TeaserPage') {
					this.srcUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
					SafeMethods.detectChanges(this.changeRef);
				}
			});
	}

	ngOnDestroy() {
		if (!isNullOrUndefined(this.navbarChangeSubscription))
			this.navbarChangeSubscription.unsubscribe();

		this.filterAndNavbarShellConfigService.cleanBodyClasses(this.bodyClasses);
		this.shellConfig.enableFooter = true;
	}

	private navbarChangeSubscription: Subscription;

	private bodyClasses: Array<string> = ['bg-app-background'];


}

