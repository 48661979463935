import { Injectable }                                    from '@angular/core';
import { Step }                                          from '@cs/components/wizard';
import { parseValidationResult }                         from '@cs/core/generate';
import { Observable, Subject }                           from 'rxjs';
import { WizardValidationResult, WizardValidationState } from '../models/wizard-validation-result';
import { WizardState, WizardStore }                      from './wizard.store';
import { WizardQuery }                                   from './wizard.query';


@Injectable()
export class WizardService {


	clearErrorMessages      = new Subject<string>();
	wizardStepStatusChanged = new Subject<WizardValidationState>();

	constructor(private wizardStore: WizardStore,
				private wizardQuery: WizardQuery) {
	}

	setActiveStep(step: Step) {
		this.wizardStore.update({currentStep: Object.assign({}, step)});
	}

	showErrorMessages(validationResult: WizardValidationResult[]) {

		validationResult.forEach(value => {
			value.validationResults = value.validationResults.map(result => parseValidationResult(result));
		});

		this.update({errorMessages: validationResult});
	}

	update(state: WizardState) {
		this.wizardStore.update(state);
	}

	getErrors(name: string) {
		throw new Error('Method not implemented.');
	}

	removeErrorMessages(name: string): void {
		let messages = this.wizardStore._value()
			.errorMessages;

		this.clearErrorMessages.next(name);

		if (!messages)
			return;

		messages = messages.map(value => Object.assign({}, value));

		const currentStepMessagesIndex = messages
			.findIndex(value => value.name === name);
		const currentStepMessage       = messages[currentStepMessagesIndex];

		if (currentStepMessage) {
			currentStepMessage.validationResults = currentStepMessage.validationResults.filter(value => value.type !== 'invalid');

			if (currentStepMessage.validationResults.length === 0)
				messages.splice(currentStepMessagesIndex, 1);
		}
		this.update({
						errorMessages: messages
					});
	}
}
