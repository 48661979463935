import { Injectable } from '@angular/core';
import { TabStore }   from './tab.store';

@Injectable({providedIn: 'root'})
export class TabService {

	constructor(private tabStore: TabStore) {
	}

	setInProgress(inProgress: boolean) {
		this.tabStore.update({inProgress});
	}

}
