import { HttpErrorResponse }                                             from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialog }                                                     from '@angular/material/dialog';
import { DomSanitizer, SafeHtml }                                        from '@angular/platform-browser';
import { simpleFadeInOut }                                               from '@cs/common';
import { WaitingForResponse }                                            from '@cs/common/utils';
import { CsFormGeneratorDataSource }                                     from '@cs/components/form-generator';
import { CsToastManagerService }                                         from '@cs/components/toast-manager';
import { copyTextToClipboard, CsHttpRequestOptions }                     from '@cs/core';
import { Byte, Encoder }                                                 from '@cs/core/qrcode';
import { AuthenticationConfigService, DialogBasicComponent, DialogType } from '@cs/performance-manager/shared';
import { TranslateService }                                              from '@ngx-translate/core';
import { tap }                                                           from 'rxjs/operators';
import { OtpModel }                                                      from './model/otp.model';
import { UserLocationInfo }                                              from './model/user-location-info';
import { UserPersonalProfile }                                           from './model/user-personal-profile';
import { UserProfileConfigService }                                      from './user-profile-config.service';

@Component({
			   selector:        'pmc-user-profile',
			   templateUrl:     './user-profile.component.html',
			   styleUrls:       ['./user-profile.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   animations:      [
				   simpleFadeInOut('isLoadingContent')
			   ]
		   })
export class UserProfileComponent implements OnInit {

	profileForm: CsFormGeneratorDataSource  = null;
	accountForm: CsFormGeneratorDataSource  = null;
	locationForm: CsFormGeneratorDataSource = null;

	isLoadingContent        = false;
	changePasswordAvailable = false;

	otpModel: OtpModel;
	showSecret = false;

	otpValue: string;
	isWaitingForReset = false;
	manualAuthCodeMessageHtml: SafeHtml;

	get showQr(): boolean {
		return this.otpModel
			   ? this.otpModel.otpSecret != null
			   : false;
	}

	constructor(private config: UserProfileConfigService,
				private changeRef: ChangeDetectorRef,
				private toast: CsToastManagerService,
				private l8n: TranslateService,
				private dialog: MatDialog,
				private sanitizer: DomSanitizer,
				public readonly authenticationConfig: AuthenticationConfigService) {
	}

	ngOnInit() {
		this.hasPlatformPasswordRecoveryAvailable();
		this.hasOtpAvailable();
		this.getAccountInfo();
		this.getPersonalInfo();
		this.getLocationInfo();
	}

	postAccountInfo(data: any): void {
		const accountData            = Object.assign({}, this.accountForm.data, data);
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => this.handleSaveFactsFailure(error);

	}

	postPersonalInfo(data: UserPersonalProfile): void {
		this.config.updatePersonalInfo(data)
			.subscribe((x) => this.toast.show({type: 'success', content: 'Profile updated successfully.'}));
	}

	postLocationInfo(data: UserLocationInfo): void {
		this.config.updateLocationInfo(data)
			.subscribe((x) => this.toast.show({type: 'success', content: 'Location info updated successfully'}));
	}

	/**
	 * Handles the error response returned by dataEntryApi.dataentrygridsNameSavefactsPost
	 * @param response The response containing the failure reason
	 */
	handleSaveFactsFailure(response: HttpErrorResponse): boolean {
		if (response.status === 403) {

			const obj: any = JSON.parse(response.message);

			// Display warning about invalid input (as soon as toasts work)
			this.toast.show({type: 'warning', title: response.statusText, content: obj.messages.join('. ')});

			return true; // Response is regarded as handled
		}
		return false;
	}

	/**
	 * Handles the error response
	 * @param response The response containing the failure reason
	 */
	handlGetAccountInfoFailure(response: HttpErrorResponse): boolean {
		if (response.status === 405) {
			this.changePasswordAvailable = false;
			return true; // Response is regarded as handled
		}
		return false;
	}

	/**
	 * Send the otp to the server, it contains the captcha
	 */
	verifyOtp() {

		const options: CsHttpRequestOptions = {
			errorResponseHandler: (error): boolean => {

				let message = error.statusText;
				try {
					// we might received more useful messages in the body
					const result = JSON.parse(error.message);
					if (result.hasOwnProperty('messages')) {
						message = (<string[]>result.messages).join(' ');
					}
				} catch (e) {
					if (error.error && error.error.hasOwnProperty('messages'))
						message = error.error.messages.join(' ');
					else if (error.error)
						message = error.error;
					else if (error.status === 500) {
						return false;
					}

				}
				this.toast.show({
									title:   error.status === 500
											 ? this.l8n.instant('ERROR_SOMETHING_WRONG')
											 : this.l8n.instant('INFO'),
									content: message,
									type:    error.status === 500
											 ? 'error'
											 : 'info'
								});


				return true;


			},
			headers:              null
		};

		this.config.verifyOtp(this.otpValue, options)
			.pipe(tap(WaitingForResponse.new(isLoading => this.isWaitingForReset = isLoading)))
			.subscribe((result) => {
				this.hasOtpAvailable();
			});
	}

	confirmDeleteOtp(): void {
		const confirmDialogRef = this.dialog.open(DialogBasicComponent, {
			data: {
				dialogTitle: this.l8n.instant('CONFIRM'),
				type:        DialogType.info,
				message:     this.l8n.instant('CONFIRM_REMOVAL_OF_AUTH_APP_MSG'),
				showCancel:  false
			}

		});

		confirmDialogRef.afterClosed()
						.subscribe(confirmed => {
							if (confirmed === true) {
								this.deleteOtp();
							}
						});
	}

	deleteOtp(): void {

		const options: CsHttpRequestOptions = {
			errorResponseHandler: (error): boolean => {

				let message = error.statusText;
				try {
					// we might received more useful messages in the body
					const result = JSON.parse(error.message);
					if (result.hasOwnProperty('messages')) {
						message = (<string[]>result.messages).join(' ');
					}
				} catch (e) {
					if (error.error && error.error.hasOwnProperty('messages'))
						message = error.error.messages.join(' ');
					else if (error.error)
						message = error.error;
					else if (error.status === 500) {
						return false;
					}

				}
				this.toast.show({
									title:   error.status === 500
											 ? this.l8n.instant('ERROR_SOMETHING_WRONG')
											 : this.l8n.instant('INFO'),
									content: message,
									type:    error.status === 500
											 ? 'error'
											 : 'info'
								});


				return true;


			},
			headers:              null
		};

		this.config.deleteOtp(options)
			.pipe(tap(WaitingForResponse.new(isLoading => this.isWaitingForReset = isLoading)))
			.subscribe((result) => {
				this.hasOtpAvailable();
			});
	}

	styleSecret(otpSecret: string | undefined): string {
		if (otpSecret == null)
			return '';

		return `<b class="hover-pointer">${this.splitAndJoinString(otpSecret)}</b><i class="hover-pointer mdi mdi-content-copy ml-1"></i>`;
	}

	copyToClipboard(): void {
		copyTextToClipboard(this.otpModel.otpSecret)
			.then(value => this.toast.show({type: 'info', title: ' ', content: this.l8n.instant('COPIED_TO_CLIPBOARD')}))
			.catch(reason => this.toast.show({type: 'error', title: ' ', content: this.l8n.instant('NOT_COPIED_TO_CLIPBOARD')}));
	}

	/**
	 * split the input into 4 character and join them with a '-'
	 * @param input
	 * @private
	 */
	private splitAndJoinString(input: string): string {
		// Split the string into chunks of 4 characters
		const chunks = input.match(/.{1,4}/g);

		// Join the chunks with a hyphen
		return chunks
			   ? chunks.join('-')
			   : '';
	}

	private getAccountInfo() {
		this.isLoadingContent        = true;
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => this.handlGetAccountInfoFailure(error);

		this.config.getAccountInfo(options)
			.subscribe(result => {
				const data = result.value;

				this.accountForm      = data;
				this.isLoadingContent = false;
				this.changeRef.markForCheck();
			});
	}

	private getPersonalInfo() {
		this.config.getPersonalInfo()
			.subscribe(result => {
				this.profileForm = result.value;
				this.changeRef.markForCheck();
			});
	}

	private getLocationInfo() {
		this.config.getLocationInfo()
			.subscribe(result => {
				this.locationForm = result.value;
				this.changeRef.markForCheck();
			});
	}

	private hasPlatformPasswordRecoveryAvailable() {
		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (error) => this.handlGetAccountInfoFailure(error);
		this.config.hasPlatformPasswordRecoveryAvailableForCurrentAccount(options)
			.subscribe(value => this.changePasswordAvailable = value.value);
	}

	private hasOtpAvailable(): void {


		this.config.getMultiFactorAuthenticationSetup()
			.subscribe(value => {
				this.otpModel = value.value;
				this.otpValue = '';

				if (this.otpModel.otpURL) {
					const encoder              = new Encoder({
																 level: 'H'
															 });
					const output               = encoder.encode(new Byte(this.otpModel.otpURL));
					this.otpModel.imageDataUrl = output.toDataURL(5);

					this.manualAuthCodeMessageHtml = this.sanitizer.bypassSecurityTrustHtml(this.l8n.instant('MANUAL_AUTH_CODE_MESSAGE', {secret: this.styleSecret(this.otpModel.otpSecret)}));
				}

				this.changeRef.detectChanges();
			});
	}
}
