import { Injectable }                from '@angular/core';
import { CsFormGeneratorDataSource } from '@cs/components/form-generator';

import { isNullOrUndefined }              from '@cs/core/utils';
import { Report }                         from '../models/report';
import { ReportingState, ReportingStore } from './reporting.store';


@Injectable({providedIn: 'root'})
export class ReportingStateService {



	constructor(private store: ReportingStore) {
	}

	setActiveReport(report: Report) {
		this.store.update({activeReport: report});
	}

	updateSelection(formDefinitionSelection: {
		tabname: string;
		selection: any;
	}) {
		this.store.update({selection: formDefinitionSelection});
	}

	toggleActLikeDashboard(toggle?: boolean) {
		if (isNullOrUndefined(toggle)) {
			toggle = !this.store._value().actAsDashboard;
		}
		this.store.update({actAsDashboard: toggle});
	}

	setActiveReportForm(form: CsFormGeneratorDataSource) {
		this.store.update({activeReportForm: form});
	}


	update(state: Partial<ReportingState>) {
		this.store.update(state);
	}
}
