import { Environment } from './cs-enviroment';

export class CsLoggerOptions {
	/**
	 * Flag indicating if the application should log the errors to sentry
	 */
	enableErrorLogging = true;
	/**
	 * Set the period in milliseconds in which the ErrorSendCounter is reset to zero, default is one minute
	 */
	rateLimitPeriod    = 60 * 1000;
	/**
	 * The amount of allow errors within the @Link(rateLimitPeriod)
	 */
	rateLimitCount     = 5;
	/**
	 * The version of the application
	 */
	version: string;
	/**
	 * On which environment is the application running
	 */
	environment: Environment;

	constructor(version: string, environment: Environment) {
		this.version     = version;
		this.environment = environment;
	}
}
