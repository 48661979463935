/**
 * Created by alex on 18-7-2017.
 */

import { isNullOrUndefined } from '@cs/core';
import { isString }          from './util';

export function isInRangeEqualsValidator(value, min = 0, max: number) {
  if (isNullOrUndefined(max) || isNullOrUndefined(value) || isString(value)) {
    return false;
  }

  return value >= min && value <= max;
}

export function isInRangeValidator(value, min = 0, max: number) {
  if (isNullOrUndefined(max) || isNullOrUndefined(value) || isString(value)) {
    return false;
  }

  return value > min && value < max;
}

export function isGreaterThanValidator(value, max: number) {
  if (isNullOrUndefined(max) || isNullOrUndefined(value) || isString(value)) {
    return false;
  }

  return value > max;
}

export function isGreaterAndEqualsThanValidator(value, max: number) {
  if (isNullOrUndefined(max) || isNullOrUndefined(value) || isString(value)) {
    return false;
  }

  return value >= max;
}

export function isLessThanValidator(value, min: number) {
  if (isNullOrUndefined(min) || isNullOrUndefined(value) || isString(value)) {
    return false;
  }

  return value < min;
}

export function isLessAndEqualsThanValidator(value, min: number) {
  if (isNullOrUndefined(min) || isNullOrUndefined(value) || isString(value)) {
    return false;
  }

  return value <= min;
}
