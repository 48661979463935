<div class="cs-statistics-panel">
  <div class="statistics-tabs">
    <!-- TODO: scrollable -->
    <ng-container *ngFor="let source of dataSource; let last = last">
      <label class="statistics-tabs-item hover-pointer"
             [class.active]="currentPage?.label === source.label"
             (click)="switchInfo(source)">
        {{source.label}}
      </label>
    </ng-container>
  </div>
  <div class="statistics-page-container">
    <div class="statistics-container">
      <div [class.isLoading]="isLoading">
        <div [@fadeOutOnLeave]
             [ngSwitch]="currentPage?.type">
          <cs-statistics-panel-performance-stats *ngSwitchCase="'PerformanceStats'"
                                                 [statisticData]="currentPage">
          </cs-statistics-panel-performance-stats>

          <cs-statistics-panel-table *ngSwitchCase="'Table'"
                                     [statisticData]="currentPage.data">
          </cs-statistics-panel-table>

          <cs-statistics-panel-html *ngSwitchCase="'WorkflowState'"
                                    [htmlToShow]="currentPage.data.html">
          </cs-statistics-panel-html>

          <cs-statistics-panel-audits *ngSwitchCase="'AuditTrailAudits'"
                                      [auditsData]="currentPage.data">
          </cs-statistics-panel-audits>
          <cs-statistics-panel-none *ngSwitchCase="'None'"></cs-statistics-panel-none>

        </div>
      </div>
      <div class="statistics-loader"
           @statisticsState
           *ngIf="isLoadingLonger$ | async">
        <div class="statistics-loader-container">
          <div class="progress">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="75"
                 aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
          </div>
        </div>
      </div>
    </div>


  </div>

</div>

