import { GitTableNavigationFilter, RepositoryDto } from '@cs/performance-manager/git-graph';
import { IConfigService }                          from '@cs/performance-manager/shared';
import { Observable }                              from 'rxjs';

import { Result } from '@cs/core/generate';

// local imports


export abstract class GitEnvironmentViewConfigService implements IConfigService {

	moduleName = 'git-environment-view';

	public abstract GetRepositories(filter: GitTableNavigationFilter, options?): Observable<Result<RepositoryDto[]>>;

}
