import { NgModule }                 from '@angular/core';
import { CsInputDirective }         from './mat-search-input';
import { MatSelectSearchComponent } from './mat-select-search.component';
import { MatButtonModule }          from '@angular/material/button';
import { MatIconModule }            from '@angular/material/icon';
import { MatInputModule }           from '@angular/material/input';
import { CommonModule }             from '@angular/common';
import { MatTooltipModule }         from '@angular/material/tooltip';

@NgModule({
	imports:      [
		CommonModule,
		MatButtonModule,
		MatIconModule,
		MatInputModule,
		MatTooltipModule
	],
	declarations: [
		MatSelectSearchComponent, CsInputDirective
	],
	exports:      [
		MatButtonModule,
		MatInputModule,
		MatSelectSearchComponent,
		CsInputDirective
	]
})
export class MatSelectSearchModule {
}

