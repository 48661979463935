<div class="comparison-bar-label">{{data.label}}</div>
<div class="comparison-bar">
  <div
    *ngIf="data.comparisonBar.visible"
    class="bar comparison"
    matTooltip="{{data.comparisonBar.label}}"
    [style]="calculateWidth(data.comparisonBar.size)">
  </div>

  <div
    *ngIf="data.comparisonAccentBar?.visible"
    class="bar comparison accent"
    matTooltip="{{data.comparisonAccentBar?.label}}"
    [style]="calculateWidth(data.comparisonAccentBar?.size)">
  </div>

  <div
    *ngIf="data.mainBar.visible"
    class="bar main"
    matTooltip="{{data.mainBar.label}}"
    [style]="calculateWidth(data.mainBar.size)">
  </div>

  <div
    *ngIf="data.accentBar.visible"
    class="bar accent"
    matTooltip="{{data.accentBar.label}}"
    [style]="calculateWidth(data.accentBar.size)">
  </div>

  <div class="value {{data.valueStyle.toLowerCase()}}">{{data.value}}</div>
</div>
