export interface ResourceModel {
  allowEdit?: boolean;

}

export class CSResourceModel {
  AllowEdit = false;

  constructor(initial?: Partial<ResourceModel>) {
    Object.assign(this, initial);
  }

}
