<div class="form-field-list">
  <label class="form-field-list-total-selected-label">{{getSelectedItem()}} selected</label>

  <div class="search-field-container" *ngIf="showSearchBar">
      <input type="search" class="form-control" placeholder="Search" (input)="onUserSearchInput($event.target.value)">
  </div>

  <div class="list-container"
       [ngStyle]="{'height':elementParameters?.height+'px',
       'max-height': elementParameters?.maxHeight+'px',
       'overflow': elementParameters?.isScrollable? 'auto' : 'hidden'}">
    <ul class="list" [ngClass]="'list-'+listType"
        [ngStyle]="{'flex-direction': elementParameters?.showAsHorizontalList || listType === 'grid'? 'row' : 'column'}">
      <li *ngFor="let item of flatTreeList;let itemIndex = index;"
          (click)="onListItemClicked(item)"
          class="list-item depth-{{item.depth}}"
          [hidden]="item.hidden"
          [class.collapsed]="item.collapsed"
          [ngStyle]="{'padding-left': (item.depth*16) +'px'}"
          [ngClass]="listType === 'grid'? 'list-item-grid-'+ elementParameters?.gridColumns : null">
        <div *ngIf="fieldType === 'checkbox'" data-toggle="buttons">
          <label class="btn-checkbox"
                 [ngClass]="{'active': item.selected, 'disabled': disabled}">
            <input #checkbox
                   [type]="fieldType"
                   class="list-item-checkbox checkbox"
                   [name]="item.id"
                   [value]="1"
                   (click)="onChange(item,itemIndex)"
                   [checked]="item.selected"
                   [disabled]="disabled">
            <i class="icon icon-check"></i>
          </label>
          <label class="list-item-label"
                 (click)="onLabelClick(item.id, false)">
            {{item.label}}
          </label>
        </div>

        <div *ngIf="fieldType === 'radio'" class=" btn-group" data-toggle="buttons">
          <label class="btn btn-radio" [ngClass]="{'active': item.selected, 'disabled': disabled}">
            <input #radioButton
                   [type]="fieldType"
                   class="list-item-radio"
                   [name]="radiogroup"
                   [value]="item.key"
                   (click)="onChange(item,itemIndex)"
                   [checked]="item.selected"
                   [disabled]="disabled">
            <i class="icon icon-check"></i>
          </label>
          <label class="list-item-label" (click)="onLabelClick(item.key, true)">{{item.value}}</label>
        </div>
         <i class="mdi mdi-18px mdi-chevron-down expand-icon"
         [class.open]="!item.collapsed"
         *ngIf="item.depth === 1"></i>
      </li>
    </ul>
  </div>
</div>
