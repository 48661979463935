import {
	ViewContainerRef,
	ComponentRef,
	ComponentFactoryResolver,
	Injectable
}                            from '@angular/core';
import { WidgetRegistry }    from './widget-registry.util';
import { PortalInjector }    from '@angular/cdk/portal';
import { isNullOrUndefined } from '@cs/core';


@Injectable()
export class WidgetFactory {

	private resolver: ComponentFactoryResolver;
	private registry: WidgetRegistry;

	constructor(registry: WidgetRegistry, resolver: ComponentFactoryResolver) {
		this.registry = registry;
		this.resolver = resolver;
	}

	private getWidget(type: string) {
		const componentClass = this.registry.getWidgetType(type.toLowerCase());

		if (isNullOrUndefined(componentClass)) {
			console.error(`${type} is not found as an registered widget`);
			return null;
		}
		return componentClass;
	}

	createWidget(container: ViewContainerRef, type: string): ComponentRef<any> {
		const componentClass = this.getWidget(type);

		const componentFactory = this.resolver.resolveComponentFactory(componentClass);
		return container.createComponent(componentFactory);
	}

	private _createInjector<T>(data: any, container: ViewContainerRef): PortalInjector {

		const userInjector    = data && container && container.injector;
		const injectionTokens = new WeakMap();


		return new PortalInjector(userInjector || container.parentInjector, injectionTokens);
	}

	hasWidgetReadOnlyState(type: string) {
		const componentClass = this.getWidget(type);
		// if nothing found use default read-only widget
		if (isNullOrUndefined(componentClass))
			return false;

		return componentClass.hasReadOnlyState;
	}
}
