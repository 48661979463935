import { Directive }           from '@angular/core';
import { NgControl }           from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatInput }            from '@angular/material/input';

/**
 * Override the MatInput to fix a bug with the placeholder. Should be removed when fixed in angular material.
 */
@Directive({
	selector:  `input[csInput]`,
	exportAs:  'csInput',
	host:      {
		// At the time of writing, we have a lot of customer tests that look up the input based on its
		// placeholder. Since we sometimes omit the placeholder attribute from the DOM to prevent screen
		// readers from reading it twice, we have to keep it somewhere in the DOM for the lookup.
		// COHELION NOTE; This broke the placeholder. it wasn't shown anymore.
		'[attr.placeholder]': 'placeholder'
	},
	providers: [{provide: MatFormFieldControl, useExisting: CsInputDirective}]
})
export class CsInputDirective extends MatInput {

	placeholder;
	readonly ngControl: NgControl | null;

}
