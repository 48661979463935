import { BaseInput } from './base-input';

export class OpeningHoursInput extends BaseInput {
  public times = [];
  public rangeSelector = 'from-to';
  public startTime = '00:00';
  public endTime = '00:00';

  public constructor(data: any) {
    super(data);

    let value = this.node.data.textValue;
    let times = value.split(' - ');
    this.startTime = times[0];
    this.endTime = times[1];

    if (this.endTime && this.endTime.length > 5) {
      this.data.remark = this.endTime.substr(5);
      this.endTime = this.endTime.substr(0, 5);
    }

    if (value == 'Closed' || value == '00:00 - 00:00') {
      this.rangeSelector = 'closed';
      this.startTime = '00:00';
      this.endTime = '00:00';
      this.onChange();
    }

    if (value == '24 hrs' || value == '00:00 - 24:00') {
      this.rangeSelector = '24-hours';
      this.startTime = '00:00';
      this.endTime = '24:00';
      this.onChange();
    }

    for (let i = 0; i <= 24; i++) {
      let hour = {
        'value': i.toString(),
        'displayValue': i.toString()
      };

      let minutes = [{
        'value': '00',
        'displayValue': '00'
      }, {
        'value': '30',
        'displayValue': '30'
      }];

      if (i < 10) {
        hour.value = '0' + hour.value;
        hour.displayValue = '0' + hour.displayValue;
      }
      if (i == 24) {
        minutes.pop();
      }

      this.times.push({
        'hour': hour,
        'minutes': minutes
      });
    }
  }

  public onSelectChange() {
    if (this.rangeSelector == '24-hours') {
      this.startTime = '00:00';
      this.endTime = '24:00';
    }

    if (this.rangeSelector == 'closed') {
      this.startTime = '00:00';
      this.endTime = '00:00';
    }

    let value = this.getValue();
    this.setValue(value);
    this.markAsDirty();
  }

  public onChange() {
    this.data.textValue = this.value;
  }

  private getValue() {
    if (this.startTime == '00:00' && this.endTime == '00:00') {
      return 'Closed';
    }
    if(this.startTime == '00:00' && this.endTime == '24:00') {
      return '24 hrs';
    }

    return this.startTime + ' - ' + this.endTime;
  }
}
