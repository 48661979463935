import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { DashboardPanelComponentBase }                           from '@cs/components/shared';
import { GaugeDataDescribed }                                    from '@cs/components/gauge';
import { ArrayUtils }                                            from '@cs/core';
import { isNullOrUndefined }                                     from '@cs/core';
import { SafeMethods }                                           from '@cs/common';

@Component({
			   selector:        'cs-dashboard-gauge',
			   templateUrl:     './dashboard-gauge.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class DashboardGaugeComponent extends DashboardPanelComponentBase<GaugeDataDescribed> {
	name: string;

	constructor(private changeRef: ChangeDetectorRef) {
		super();
	}

	dataChanged(value: GaugeDataDescribed) {

	}

	update(data: GaugeDataDescribed): void {
		if (!isNullOrUndefined(this.data) && !isNullOrUndefined(data) &&
			(this.data && ArrayUtils.isEqual(this.data.data, data.data)))
			return;

		this.data = data;
		SafeMethods.detectChanges(this.changeRef);
	}

}
