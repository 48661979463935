import {
	AfterContentInit,
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ContentChildren,
	ElementRef,
	EventEmitter,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	QueryList,
	SimpleChanges,
	ViewChild,
	ViewChildren
}                                                                                 from '@angular/core';
import { Validators }                                                             from '@angular/forms';
import {
	validateValidators
}                                                                                 from '@cs/components/shared/validation/validatation.helpers';
import {
	FormatRegisteredItem,
	generateObject,
	hasPropertyOf,
	isEmptyObject,
	KeyValuePair,
	LayoutAnnotation,
	LoggerUtil,
	Lookup,
	PropertyAnnotation,
	ServerSideFilter,
	ServerSideNewPage,
	ServerSidePaging,
	ServerSideSortItem,
	TableAnnotation,
	TableDataCell,
	TableDataDescribed,
	TableDataGroupLayout,
	TableDataRowLayout,
	TableDataRowType,
	TableHeader,
	TableLayout
}                                                                                 from '@cs/core';
import { isArray, isNullOrUndefined }                                             from '@cs/core';
import { gv, isString }                                                           from '@cs/core/utils';
import { CsTableNxtParser }                                                       from './table-parser.util';
import { CsTemplateHandleDirective }                                              from '@cs/components/shared';
import { CsTemplateLoaderDirective }                                              from '@cs/components/shared';
import { FormatProviderService, opacityAndBlur, SafeMethods, simpleFadeInOut }    from '@cs/common';
import { concatMap, debounceTime, first, map, reduce, take, takeUntil }           from 'rxjs/operators';
import { BehaviorSubject, fromEvent, ObservedValueOf, Subject }                   from 'rxjs';
import { UntilDestroy, untilDestroyed }                                           from '@ngneat/until-destroy';
import { isNumberValue }                                                          from '@cs/components/util';
import { MatSelect }                                                              from '@angular/material/select';
import { MatSelectSearchComponent }                                               from '@cs/components/mat-select-search';
import { TableCellClickEventArgs, TableRowClickEventArgs, ValueChangedEventArgs } from './event-args';
import { MatOptionSelectionChange }                                               from '@angular/material/core';
import { TableMenuClickEventArgs }                                                from './event-args/table-menu-click.event-args';
import { TableMenuItem }                                                          from './table-menu/table-menu.model';
import { Observable }                                                             from 'rxjs/internal/Observable';

@UntilDestroy()
@Component({
												selector:        'cs-table-nxt',
												templateUrl:     './cs-table-nxt.component.html',
												changeDetection: ChangeDetectionStrategy.OnPush,
												animations:      [
													simpleFadeInOut('changingData'),
													opacityAndBlur('loadingPanelState')
												]

											})

export class CsTableNxtComponent<T> implements OnInit,
																																															OnChanges,
																																															AfterViewInit,
																																															AfterContentInit,
																																															OnDestroy {

	private get originalData(): TableDataDescribed<T> {
		if (this.cachedOriginalData == null)
			this.cachedOriginalData = JSON.stringify(this._originalData);

		return JSON.parse(this.cachedOriginalData);

	}

	private set originalData(value: TableDataDescribed<T>) {
		// Make a deepClone
		this.cachedOriginalData = null;
		this._originalData      = value;
	}

	serverSidePaging: ServerSidePaging;
	loadingData        = 'Loading data';
	isServerSidePaging = false;

	/**
		* @deprecated this is a temporary solution for allowing negative entry
		*/
	@Input() allowNegativeEntry = false;

	/**
		* Message to show when no data is present
		*/
	@Input() noDataText                                           = 'No data found...';
	/**
		* Hide the page counter if it is not necessary
		*/
	@Input() showPageCounter                                      = true;
	/**
		* Enables selection visibility.
		*/
	@Input() selectable                                           = false;
	/**
		* The parsed @Link(DataDescribed) object that is going to be parsed for rendering
		*/
	@Input() data: TableDataDescribed<T[]>;
	/**
		* Page size determines how many number of rows should be displayed on a page when pagination is enabled.
		*/
	@Input() selectedPageSize: number                             = 25;
	/**
		* Table contains paginator.
		*/
	@Input() pageable                                             = false;
	/**
		* Sortable per column.
		*/
	@Input() sortable                                             = true;
	/**
		* Column which is sorting applied to.
		*/
	@Input() sortColumn                                           = '';
	/**
		* Enables the filter.
		*/
	@Input() filter                                               = true;
	/**
		* In what sorting direction the column is sorted.
		*/
	@Input() sortDir: 'asc' | 'desc'                              = 'asc';
	/**
		*    * Set height for the table.
		*/
	@Input() height                                               = '';
	/**
		* When a row is clicked it will send the whole row object back.
		*/
	@Output() rowClick                                            = new EventEmitter<TableRowClickEventArgs<T>>();
	/**
		* When a cell is clicked it will send the cell object back.
		*/
	@Output() cellClick: EventEmitter<TableCellClickEventArgs<T>> = new EventEmitter<TableCellClickEventArgs<T>>();
	/**
		* When a row's selection checkbox is clicked it will send the whole row object back.
		*/
	@Output() rowSelected                                         = new EventEmitter<TableRowClickEventArgs<T>>();
	/**
		* Triggers an event when the selection of rows has changed, returns a list of selected rows
		*/
	@Output() selectionChanged                                    = new EventEmitter<TableDataRowLayout[]>();
	/**
		* Fires when a value is changed
		*/
	@Output() cellValueChanged                                    = new EventEmitter<ValueChangedEventArgs>();
	/**
		* Output that the rendering is done
		*/
	@Output() renderingIsDone                                     = new EventEmitter<boolean>();

	/**
		* Request filtering of the data when the table is in server side paging mode
		*/
	@Output() filterRequestServerSidePaging  = new EventEmitter<ServerSideFilter>();
	/**
		* Request sorting of the data when the table is in server side paging mode
		*/
	@Output() sortingRequestServerSidePaging = new EventEmitter<ServerSideFilter>();
	/**
		* Request new page of the data when the table is in server side paging mode
		*/
	@Output() newPageRequestServerSidePaging = new EventEmitter<ServerSideNewPage>();
	/**
		* A menu item is clicked, broadcast the selected item
		*/
	@Output() rowMenuItemClicked             = new EventEmitter<TableMenuClickEventArgs>();

	@ViewChildren(CsTemplateHandleDirective) templates: QueryList<CsTemplateHandleDirective>;
	@ViewChildren(CsTemplateLoaderDirective) templatesLoaders: QueryList<CsTemplateLoaderDirective>;
	@ViewChildren(MatSelect) matSelections: QueryList<MatSelect>;
	@ViewChild('searchComboBar', {static: false}) searchComboBar: MatSelectSearchComponent;
	@ViewChild('tableScrollContainer', {static: false}) tableScrollContainer: ElementRef;

	@ContentChildren('csTableButton', {read: ElementRef}) actionButtons: QueryList<HTMLButtonElement>;

	isLoading$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	isLoadingDebounced$                  = this.isLoading$.pipe(untilDestroyed(this), debounceTime(200));

	requestNewPageInput$: Subject<number> = new Subject();
	requestNewPageInputDebounced$         = this.requestNewPageInput$
																																													.pipe(untilDestroyed(this), debounceTime(0))
																																													.subscribe(value => this.requestNewPage(value));

	/**
		* Flag to indicate that the table has selected all visible cells
		*/
	selectAllActive = false;
	/**
		* Number of header cells that needs to be spanned so the No Data row filles the table
		*/
	noDataColSpan   = 0;
	/**
		* Number of total rows
		*/
	rowCount        = 0;

	/**
		* All rows without grouping
		*/
	allRows = [];

	/**
		* Table groups
		*/
	groups: TableDataGroupLayout[];

	/**
		* Property to disable pagination if collapsible is on
		*/
	disablePagination = true;

	/**
		* Property to show or hide pagination bar
		*/
	showPaginationBar = true;

	/**
		* Selected row
		*/
	selectedRow: TableDataRowLayout;

	renderSchema: TableLayout<T>;

	/**
		* The visible rows
		*/
	currentRows: TableDataRowLayout[] = [];
	/**
		* The flag for loading the fist time
		*/
	initialLoading                    = true;
	/**
		* Current page.

		*/
	page                              = 0;

	componentState: 'done' | 'loading';

	state: {
		pristine: boolean,
		dirty: boolean,
		invalid: boolean
	}               = {pristine: true, dirty: false, invalid: false};
	valueChanged$   = new Subject<ValueChangedEventArgs>();
	hasCollapseAble = false;

	currentFilter: {
		[key: string]: Array<any>
	} = {};

	/**
		* Used for sorting when serverside paging
		*/
	currentSorting: {
		[key: string]: ServerSideSortItem
	} = {};

	/**
		* Toggles through the neutral option (column not used for sorting) when using server side paging.
		*/
	neutralSortOption: boolean = true;

	/**
		* Stores the filters set for the column filters
		*/
	filterOptions: Map<string, Lookup>;

	/**
		* Hide the row counter if it is not necessary
		*/
	showRowCounter = true;

	/**
		* Paging selection
		*/
	pagingSizesLookup: Lookup;

	/**
		* Total amount of pages for paging
		*/
	pagingPageCount: number;

	get pageEndRow(): number {
		return this._pageEndRow;
	}

	set pageEndRow(value: number) {
		this._pageEndRow = value;
	}

	get pageStartRow(): number {
		return this._pageStartRow;
	}

	set pageStartRow(value: number) {
		this._pageStartRow = value;
	}

	get displayPage(): number {
		return this.page + 1;
	}

	set displayPage(value: number) {
		if (isNumberValue(value))
			this.page = value - 1 < 0
															? 0
															: value - 1;
	}

	get displayPagelength(): number {
		return this.displayPage.toFixed(0).length * 12;
	}

	/**
		* Detects if the table is scolling
		*/
	get isScrollingVertical() {
		let element = this.elRef.nativeElement as HTMLElement;
		element     = element.querySelector('.table-responsive') as HTMLElement;

		if (isNullOrUndefined(element))
			return;

		function isEllipsisActive(e) {
			return (e.offsetHeight < e.scrollHeight);
		}

		return isEllipsisActive(element);
	}

	/**
		* Flag to show loading state
		*/
	@Input() set isLoading(value: boolean) {
		this.isLoading$.next(value);
	}

	get staticFilterOptions() {
		const obj = {};
		if (this.filterOptions)
			this.filterOptions.forEach((value, key) => obj[key] = value);
		return obj;
	}

	/**
		* Returns true if pagination bar should be rendered
		*/
	get showPagination() {
		// 1) Server side paging and it's not explicitly hidden
		return (this.isServerSidePaging && this.showPaginationBar)
			// 2) Client side paging and it's not explicitly disabled
			|| (!isNullOrUndefined(
				this.renderSchema) && (this.rowCount > this.defaultPageSize && !this.isServerSidePaging) && !this.disablePagination)
			;
	}

	constructor(private formatService: FormatProviderService,
													private elRef: ElementRef,
													private cdRef: ChangeDetectorRef) {
		if (isNullOrUndefined(this.selectedPageSize)) {
			this.selectedPageSize = this.defaultPageSize;
		}
	}

	getAllData(addEmptyOptional = true): Array<T> {

		const allData = [];

		for (const row of this.allRows) {
			const data = <T>{};

			const rowData = generateObject<T>(row, this.data, 'value', this.renderSchema.rowIndexMapper);
			// row.cells.forEach(c => {
			// 	const field        = this.data.dataAnnotation.fields.find(value => value.id === c.id);
			// 	const controlValue = c.value;
			//
			// 	if (!addEmptyOptional && field && field.optional && !controlValue)
			// 		return;
			//
			// 	data[c.id] = controlValue;
			// });
			// if (stripKeys)
			// 	FormGeneratorNxtParser.stripKeys(data, this.form);

			allData.push(rowData);
		}
		return allData;
	}


	/**
		* Count rows from groups
		* @param groups from the table
		*/
	countRowsGroup(groups: TableDataGroupLayout[]): number {
		for (const group of groups) {
			this.rowCount += group.rows.filter(x => x.rowType === 0).length;
			this.allRows = this.allRows.concat(group.rows);

			if (hasPropertyOf(group, 'children')) {
				if (group.children.length !== 0) {
					this.countRowsGroup(group.children);
				}
			}
		}
		if (this.isServerSidePaging)
			this.rowCount = this.serverSidePaging.recordCount;

		return this.rowCount;
	}

	ngOnInit() {

		fromEvent(window, 'resize')
			.pipe(
				untilDestroyed(this),
				debounceTime(400)
			)
			.subscribe(value => {
				this.getScrollable();
				this.detectChanges();
			});

		this.valueChanged$.pipe(
							untilDestroyed(this),
							debounceTime(200))
						.subscribe(value => {
							const row = this.findRow(value.cell.parentRowId);
							value.row = row;

							if (this.state.pristine) {
								this.state.pristine = false;
								this.state.dirty    = true;
							}

							this.cellValueChanged.emit(value);
						});

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.hasOwnProperty('data')) {
			if (!isNullOrUndefined(changes.data.currentValue)) {

				this.state = {pristine: true, dirty: false, invalid: false};

				this.originalData = changes.data.currentValue;

				const data: TableDataDescribed<T> = changes.data.currentValue;
				if (data.lookups && this.cachedLookups) {
					const nonEmptyLookups = data.lookups.filter(value => value.values.length > 0);
					for (const nonEmpty of nonEmptyLookups) {
						const found = this.cachedLookups.find(value => value.id === nonEmpty.id);
						if (found)
							// the following commented code was intended for future implementations
							// but it's not working as required with the current server set up

							// found.values.push(...nonEmpty.values);
							found.values = nonEmpty.values;
						else
							this.cachedLookups.push(nonEmpty);
					}

					// check lookups
					data.lookups = this.cachedLookups;
				} else if (!data.lookups && this.cachedLookups) {
					data.lookups = this.cachedLookups;
				}

				this.createTable(changes.data.currentValue)
								.then(value => {

								});

			} else {
				// reset table if data is null
				this.currentRows  = [];
				this.renderSchema = null;
			}

		}
	}

	ngAfterViewInit() {
		console.log(this.templates);
		// Workaround for waiting till all angular dirty checking is done
	}

	async createFilterForEachColumnAsync(allRows: TableDataRowLayout[],
																																						resetCurrentFilter                  = true,
																																						lastSelectedColumKey: string | null = null) {
		const filterableColumns                  = this.data
																																																	.dataAnnotation
																																																	.fields
																																																	.filter(value => (this.isServerSidePaging && value.canFilter)
																																																		|| (!this.isServerSidePaging && (value.type === 'String' || value.lookup)));
		const noLookupAvailableColumns: string[] = [];
		const headerRow                          = this.renderSchema.getLastHeaderRow();

		if (resetCurrentFilter)
			this.currentFilter = headerRow.headers.map(value => value.id)
																																	.reduce((previousValue, currentValue) => Object.assign(previousValue, {[currentValue]: []}), {});


		const filterEnabledHeaders = headerRow.headers
																																								.filter(value => value.allowAsFilter && filterableColumns.find(col => col.id === value.id))
																																								.map(value => {
																																									// set filter to true here, instaed of dynamic changing in the template
																																									value.hasFilter = true;
																																									return value.id;
																																								});

		const mapping = new Map<string, Lookup>();
		filterEnabledHeaders.forEach(value => {

			if (lastSelectedColumKey === value)
				mapping.set(value, this.filterOptions.get(value));

			else {
				const foundHeader = headerRow.headers.find(header => header.id === value);
				const foundLookup = foundHeader.lookup;

				if (foundLookup)
					mapping.set(value, foundLookup);
				else {
					mapping.set(value, new Lookup<any, any>({values: [], id: `generated_${foundHeader.id}`}));
					noLookupAvailableColumns.push(foundHeader.id);
				}
			}

		});


		if (noLookupAvailableColumns.length > 0) {
			for (const colId of noLookupAvailableColumns) {
				const lookup = mapping.get(colId);
				// distinct the values
				const set    = new Set();

				for (const row of allRows) {
					if (row.rowType !== TableDataRowType.Data)
						continue;

					const cellsToFilterDistinctValues = row.cells.filter(value => colId === value.id);
					for (const cell of cellsToFilterDistinctValues) {

						if (isNullOrUndefined(set))
							break;

						if (!set.has(cell.value))
							set.add(cell.value);
					}

				}

				mapping.set(colId, Array.from(set)
																												.sort()
																												.reduce((previousValue, currentValue) => {
																													(previousValue as Lookup).values.push({
																																																																				key:   currentValue,
																																																																				value: currentValue
																																																																			});
																													return previousValue;
																												}, new Lookup({id: lookup.id, values: []})) as Lookup
				);
			}
		}

		return mapping;
	}

	getTemplate(template: string) {
		if (isNullOrUndefined(this.templates)) {
			return null;
		}
		return this.templates.find((item: CsTemplateHandleDirective) => item.getType() === template);
	}

	/**
		* Shows the data for the current page.
		*/
	showData(page: number) {
		return new Promise((resolve, reject) => {
			try {
				this.groups    = this.renderSchema.dataGroups;
				const pageable = this.checkIfPageable(this.groups);

				this.page         = page;
				// If nested is implemented in the parser this has to be change
				const data: any[] = this.allRows.map(obj => Object.assign({}, obj));

				if (this.sortable && this.sortColumn !== '') {
					data.sort((a, b) => this.sortValues(a, b, this.sortColumn));
				}

				if (!pageable || this.isServerSidePaging) {

					this.currentRows  = data;
					this.pageStartRow = (this.page) * this.selectedPageSize + 1;
					this.pageEndRow   = this.pageStartRow + this.selectedPageSize - 1;
					// If number of rows on current page are less than page size then update pageEndRow
					if (this.pageEndRow > this.rowCount) {
						this.pageEndRow = this.rowCount;
					}
					this.updateTemplates();
				} else if (pageable) {
					this.currentRows  = data.slice(this.page * this.selectedPageSize, (this.page + 1) * this.selectedPageSize);
					this.pageStartRow = this.page * this.selectedPageSize + 1;
					this.pageEndRow   = this.pageStartRow + this.selectedPageSize - 1;
					// If number of rows on current page are less than page size then update pageEndRow
					if (this.pageEndRow > this.rowCount) {
						this.pageEndRow = this.rowCount;
					}
					this.updateTemplates();
				}

			} catch (e) {
				LoggerUtil.error(e);
				reject(e);
			}
			resolve(true);

		});
	}

	/**
		* Filter the data based on the filterobject. The filter will ignore NULL and empty arrays found on the filter object
		* @param filterObject Object with values that should be in the row
		* @param lastSelectedColumKey The key that triggered the filter
		*/
	filterData(filterObject: {
		[p: string]: any
	}, lastSelectedColumKey: string) {
		const describedData = this.originalData;

		const data   = describedData.data as unknown as T[];
		const output = data.filter(row => {
			for (const sKey of Object.keys(filterObject)) {
				const valData   = row[sKey]; // Value of the looping data row
				const valFilter = filterObject[sKey]; // Value provided by the filter

				// check if search object is null or undefined, if so ignore it
				if (isNullOrUndefined(valFilter))
					continue;

				if (isArray(valFilter) && valFilter.length === 0)
					continue;

				// If the data is not an array compare the values
				if (!isArray(valFilter) && valData !== valFilter)
					return false;

				if (isArray(valFilter) && valFilter.indexOf(valData) === -1)
					return false;

			}
			return true;
		});


		if (Object.keys(filterObject)
												.some(value => filterObject[value].length > 0))
			describedData.data = output as unknown as T;

		this.updateTable(describedData)
						.then(value => {
							this.searchComboBar.detectChanges();
							this.createFilterForEachColumnAsync(this.allRows, false, lastSelectedColumKey)
											.then(value => {
												this.filterOptions = value;
												for (const key of Object.keys(this.currentFilter)) {
													const matSelect = this.matSelections.find(item => item.id === key);

													if (isNullOrUndefined(matSelect))
														LoggerUtil.debug('No selection found for ' + key);
													else
														matSelect._onChange(this.currentFilter[key]);
												}
											});
						});
	}

	// I determine if the given target is equal-to or contained within the given node.
	isLocalNode(node, target) {

		// In Internet Explorer (IE), Node.contains() only works on Element nodes,
		// not Text nodes. As such, let's travel up to the nearest Element node.
		// --
		// NOTE: This doesn't necessarily apply to this demo, which only reacts to
		// Element node interactions. However, I'm adding it here as a mental note.
		while (target && (target.nodeType !== Node.ELEMENT_NODE)) {

			target = target.parentNode;

		}

		return (node.contains(target));

	}

	/**
		* When the header is clicked for sorting.
		*/
	async onHeaderClick(header: TableHeader, $event: MouseEvent) {
		if (this.filterOptions.has(header.id)) {
			// check if click on the mat selections
			const target = (<HTMLElement>this.elRef.nativeElement).querySelector(`#f_${header.id}_filter`);
			if (this.isLocalNode(target, $event.target)) {

				$event.preventDefault();
				$event.stopPropagation();
				$event.cancelBubble = true;
				return;
			}

		}

		const column = header.id;
		if (this.isServerSidePaging && header.isSortable) {
			const sortingDir = this.currentSorting[header.id]
																						? this.currentSorting[header.id].sortOrder
																						: '';
			const values     = Object.keys(this.currentSorting)
																												.map(value => this.currentSorting[value])
																												.sort((a, b) => b.sortIndex - a.sortIndex);
			let counter      = values.length;
			for (const value of values) {
				value.sortIndex = counter;
				counter--;
			}

			const defaultSort: ServerSideSortItem = {sortOrder: 'Asc', sortIndex: 0};

			// Toggle sorting options/directions
			if (sortingDir.toLowerCase() == 'asc') {
				this.currentSorting[header.id] = {sortOrder: 'Desc', sortIndex: 0};
			} else if (sortingDir.toLowerCase() == 'desc') {
				if (this.neutralSortOption) {
					delete this.currentSorting[header.id];
				} else {
					// no neutral, switch to default
					this.currentSorting[header.id] = defaultSort;
				}
			} else if (sortingDir.toLowerCase() == '') {
				this.currentSorting[header.id] = defaultSort;
			} else {
				this.currentSorting[header.id] = defaultSort;
			}

			this.sortingRequestServerSidePaging.emit(new ServerSideFilter(this.currentSorting, column));
		} else if (this.sortable && header.isSortable && column) {
			if (column === this.sortColumn) {
				// clear previous sorting selection
				delete this.currentSorting[this.sortColumn];
				this.sortDir = this.sortDir === 'desc'
																			? 'asc'
																			: 'desc';
			} else {
				this.sortColumn = column;
				this.sortDir    = 'asc';
			}
			this.currentSorting[header.id] = {
				sortOrder: this.sortDir === 'asc'
															? 'Asc'
															: 'Desc', sortIndex: 0
			};
			this.showDataAsync(this.page);
		}


	}

	showDataAsync(page: number) {
		return new Promise((resolve, reject) => {
			this.isLoading$.next(true);
			setTimeout(() => {
				this.showData(page)
								.then(() => {
									this.isLoading$.next(false);
									resolve(true);
									setTimeout(() => {
										this.cdRef.markForCheck();
										this.renderingIsDone.emit(true);
									});
								});
			}, 0);
		});

	}

	cellClicked(cell: TableDataCell, row: TableDataRowLayout, group: TableDataGroupLayout, $event: MouseEvent) {
		const propAnnot = this.data.dataAnnotation
																								.fields
																								.find(value => value.id === cell.id as any);

		if (propAnnot.selectionTrigger === 'Property') {

			// prevent the  rowclick
			$event.preventDefault();
			$event.cancelBubble = true;
			$event.stopImmediatePropagation();

			if (propAnnot.type.toLowerCase() === 'menu')
				return;

			const rowData = generateObject<T>(row, this.data, 'value', this.renderSchema.rowIndexMapper);
			this.cellClick.emit(new TableCellClickEventArgs<any>(propAnnot, rowData));
		}
	}

	/**
		* When a row is clicked.
		*/
	onRowClick(row: TableDataRowLayout, group: TableDataGroupLayout, $event: MouseEvent) {

		const origin = $event.target as HTMLElement;

		// when the clicked cell has the class below, it tells that it will handle the click if there is a row click action
		if (origin.classList.contains('handle-row-click') || origin.querySelector('.handle-row-click')) {
			// prevent the  rowclick
			$event.preventDefault();
			$event.cancelBubble = true;
			$event.stopImmediatePropagation();

			return;
		}

		const item = generateObject<T>(row, this.data, 'value', this.renderSchema.rowIndexMapper);
		if (item == null)
			return;

		this.rowClick.emit(new TableRowClickEventArgs<any>(item, row));

		const selectionTarget = this.data.dataAnnotation.fields.filter(value => hasPropertyOf(value, 'selectionValueTarget')).length;
		if (selectionTarget > 0) {

			if (!isNullOrUndefined(this.selectedRow))
				this.selectedRow.cells.forEach(cell => cell.classList = cell.classList.filter(value => value !== 'selected'));
			row.cells.forEach(cell => cell.classList.push('selected'));
			this.selectedRow = row;

		}
		group.collapsed = !row.isCollapsible
																				? !group.collapsed
																				: false;
	}

	onRowSelected(row: TableDataRowLayout, $event: MouseEvent) {
		$event.preventDefault();
		$event.cancelBubble = true;
		$event.stopImmediatePropagation();

		row.selected = !row.selected;

		const item = generateObject<T>(row, this.data);
		this.rowSelected.emit(new TableRowClickEventArgs<T>(item, row));

		const currentSelection = this.currentRows.filter(r => r.selected);

		if (currentSelection.length === 0)
			this.selectAllActive = false;

		this.selectionChanged.emit(currentSelection);
	}

	showAllItems($event: Event, entry) {
		$event.preventDefault();
		$event.stopPropagation();

		entry.showContent = !entry.showContent;
	}


	/**
		* (de)select all visible rows
		*/
	onSelectAllClicked($event: MouseEvent) {
		// WEIRD fix because event fires twice
		$event.preventDefault();
		$event.stopPropagation();

		this.selectAllActive = !this.selectAllActive;

		for (const row of this.currentRows) {
			row.selected = this.selectAllActive;
		}

	}

	getSelectedRows() {
		return this.currentRows.filter(r => r.selected);
	}

	getSelectedData(): T[] {
		return this.getSelectedRows()
													.map(row => generateObject<T>(row, this.data));
	}

	ngAfterContentInit(): void {

	}

	/**
		* Detects if the header is truncated and if so add an tooltip
		*/
	detectTruncatedFieldHeader($event: MouseEvent, th: TableHeader) {
		let element = $event.currentTarget as HTMLElement;

		if (!th)
			return;

		if (th.hasFilter)
			element = element.querySelector('.mat-form-field-label') as HTMLInputElement;

		if (isNullOrUndefined(element))
			return;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}

		th.truncate = isEllipsisActive(element);
	}

	/**
		* Detects if the header is truncated and if so add an tooltip
		*/
	detectTruncatedField($event: MouseEvent, td: TableDataCell) {
		const element = $event.currentTarget as HTMLElement;

		if (!td)
			return;

		if (isNullOrUndefined(element))
			return;

		function isEllipsisActive(e) {
			return (e.offsetWidth < e.scrollWidth);
		}

		td.truncate = isEllipsisActive(element);
	}

	valueChanged($event: Event, cell: TableDataCell) {

		if ($event.srcElement == null) { // no input detected treat event as the value
			cell.value = $event;

		} else {
			// there is an input here
			cell.value = ($event.srcElement as HTMLInputElement).value;

		}
		// TODO: REMOVE WHEN THE REAL VALIDATORS ARE implemented
		/*
		 cell.state.invalid = cell.isEmpty() || !isNumberValue(cell.value) || (this.allowNegativeEntry
		 ? false
		 : cell.value < 0);
		 */

		cell.validate();


		this.valueChanged$.next(new ValueChangedEventArgs({
																																																					cell: cell,
																																																					row:  null
																																																				}));

	}

	hasValuesChanged() {
		for (const group of this.renderSchema.dataGroups) {
			for (const row of group.rows) {
				const found = row.cells.find(cell => cell.isChanged());
				if (found)
					return true;
				else
					continue;
			}
		}

		return false;

	}

	hasEmptyValues() {
		return this.checkEditableCellsState(
			cell => cell.isEmpty());
	}

	hasInvalidValues() {
		return this.checkEditableCellsState(
			cell => cell.state.invalid);
	}

	hasNegativeValues() {
		return this.checkEditableCellsState(cell => cell.value < 0);
	}

	checkEditableCellsState(expression: (cell: TableDataCell) => boolean,
																									stateExpression: (cell: TableDataCell[]) => void = null,
																									cleanExpression: (cell: TableDataCell[]) => void = null) {
		const notReadonly = this.renderSchema.getLastHeaderRow()
																										.headers
																										.filter(value => !value.readOnly);
		const invalid     = [];
		const valid       = [];

		for (const group of this.renderSchema.dataGroups) {
			for (const row of group.rows) {
				const cells = [];
				for (const header of notReadonly) {
					const cell = row.cells.find(value => value.id === header.id);
					if (!isNullOrUndefined(cell))
						cells.push(cell);
				}

				for (const cell of cells) {
					expression(cell)
					? invalid.push(cell)
					: valid.push(cell);
				}

				if (invalid.length > 0 && stateExpression === null)
					return true;
			}
		}

		if (stateExpression !== null) {
			stateExpression(invalid);
		}

		if (cleanExpression !== null) {
			cleanExpression(valid);
		}

		return invalid.length > 0;

	}

	findRow(parentRowId: string) {
		return this.currentRows.find(row => row.rowId === parentRowId);
	}

	detectChanges() {
		SafeMethods.detectChanges(this.cdRef);
	}

	markForChange() {
		this.cdRef.markForCheck();
	}

	ngOnDestroy(): void {
	}

	checkRow(row: TableDataRowLayout) {
		return this.currentRows.find(x => x.rowId === row.rowId);
	}

	/**
		* Stop event propagation if a collapsible icon is clicked
		* @param row clicked row
		* @param group clicked group
		* @param event fired event
		*/
	stopPropagation(row: TableDataRowLayout, group: TableDataGroupLayout, event: Event) {
		event.stopPropagation();
		group.collapsed = !row.isCollapsible
																				? !group.collapsed
																				: false;
	}

	/**
		* Checks if the table is lager that the container and sets a scroll
		*/
	getScrollable() {
		if (this.renderSchema && this.renderSchema.layout && this.renderSchema.layout.isSelectable)
			return false;

		const csTable        = this.elRef.nativeElement as HTMLElement;
		const tableContainer = csTable.getElementsByClassName('table-responsive')[0];
		let table            = csTable.getElementsByClassName('table-ref')[0];

		const isHorizontalScrolling = tableContainer.clientWidth - table.clientWidth < -10;

		if (isHorizontalScrolling)
			this.setupMouseGrapScrolling();

		return isHorizontalScrolling;
	}

	setupMouseGrapScrolling() {

		if (this.scrollHandles != null)
			return;

		const element  = this.tableScrollContainer.nativeElement;
		let currentPos = element.scrollLeft;

		this.scrollHandles = this.getObservables(element);
		this.scrollHandles.drags.forEach(event => {
			element.scrollLeft = currentPos - event.x;
		});

		this.scrollHandles.drops.forEach(event => {
			currentPos = element.scrollLeft;
		});


	}

	filterOptionsSelectionChanged(columKey: string) {

		const filter = Object.keys(this.currentFilter)
																							.reduce((previousValue, key) => {
																								if (this.currentFilter.hasOwnProperty(key) && this.currentFilter[key] && this.currentFilter[key].length > 0) {
																									return {...previousValue, [key]: this.currentFilter[key]};
																								}
																								return previousValue;
																							}, {});

		if (this.isServerSidePaging)
			this.filterDataServerSidePaging(filter, columKey);
		else
			this.filterData(filter, columKey);
	}

	trackHeaders(index, item: TableHeader) {
		if (!item) return null;
		return item.id;
	}

	trackOptions(index, item) {
		if (!item) return null;
		return item.id;
	}

	trackId(index, item) {
		if (!item) return null;
		return item.id;
	}

	trackKey(index, item) {
		if (!item) return null;
		return item.name;
	}

	tableLayoutParse(properties: TableAnnotation<T>) {
		if (properties.enablePaging) {
			this.disablePagination = false;
			this.pageable          = true;
		}

		if (properties.enableServerSidePaging != null) {
			this.disablePagination  = false;
			this.pageable           = false;
			this.isServerSidePaging = true;
			this.serverSidePaging   = properties.enableServerSidePaging;
			this.pageEndRow         = this.serverSidePaging.pagesLookup.values.length;
			this.pageStartRow       = this.serverSidePaging.pageIndex;
			this.selectedPageSize   = this.serverSidePaging.pageSize;
			this.page               = this.serverSidePaging.pageIndex;
			this.pagingPageCount    = this.serverSidePaging.pagesLookup.values.length;
			this.pagingSizesLookup  = this.serverSidePaging.pageSizesLookup;
			this.showPaginationBar  = properties.showPagination;

			if (properties.serverSideFilter)
				Object.assign(this.currentFilter, properties.serverSideFilter.filter);

			if (properties.serverSideSorting)
				this.currentSorting = properties.serverSideSorting.filter;
		}
		if (properties.hideRowCount === true) {
			this.showRowCounter = false;
		}
	}

	setPagination(data) {
		if (this.pageable) {
			this.pagingPageCount   = Math.ceil((data as Array<T>).length / this.selectedPageSize);
			this.pagingSizesLookup = new Lookup({
																																								id:     'pageSizes',
																																								values: this.renderSchema.pageSizes.map(value => ({value: value, key: value})),
																																								filter: null
																																							});
		}
	}

	resetTableFilter(columKey: string) {
		if (this.isServerSidePaging) {
			delete this.currentFilter[columKey];
			this.filterDataServerSidePaging(this.currentFilter, columKey);
		} else
			this.filterData(this.currentFilter, columKey);
	}

	setPanelHeight(th: TableHeader, values: KeyValuePair<any, any>[], open: boolean) {
		if (!open)
			return;

		const found        = document.querySelector(`.table-header-mat-select`) as HTMLElement;
		found.style.height = `${(values.length * 30)}px`;

	}

	requestNewPage(page: number) {
		if (this.isServerSidePaging) {
			this.newPageRequestServerSidePaging.emit({
																																													filter:    this.currentFilter,
																																													sorting:   this.currentSorting,
																																													pageIndex: page,
																																													pageSize:  null,
																																													trigger:   'pageButton'
																																												});
		} else
			this.showDataAsync(page);
	}

	requestNewPageInput($event: any) {
		let val = $event.target.valueAsNumber - 1;
		if (val > this.serverSidePaging.pagesLookup.values.length) {
			val                 = this.serverSidePaging.pagesLookup.values.length;
			$event.target.value = val;
		} else if (val < 1) {
			val                 = 0;
			$event.target.value = 1;
		}


		this.requestNewPageInput$.next(val);
	}

	requestNewPagePageSize($event: any) {
		if (this.isServerSidePaging) {
			this.newPageRequestServerSidePaging.emit({
																																													filter:    this.currentFilter,
																																													sorting:   this.currentSorting,
																																													pageSize:  $event,
																																													pageIndex: null,
																																													trigger:   'pageButton'
																																												});
		} else {
			this.selectedPageSize = $event;
			this.pagingPageCount  = Math.ceil(this.allRows.length / this.selectedPageSize);
			this.showDataAsync(this.page);
		}
	}

	onSelectionChange(headerId: string, $event: MatOptionSelectionChange) {
		if (!$event.isUserInput)
			return;

		const option      = $event.source;
		let currentFilter = this.currentFilter[headerId];
		if (option.selected) {
			if (this.currentFilter[headerId] == null) {
				this.currentFilter[headerId] = [];
				currentFilter                = this.currentFilter[headerId];
			}
			currentFilter.push(option.value);
		} else
			this.currentFilter[headerId] = currentFilter.filter(value => value !== option.value);

		this.filterOptionsSelectionChanged(headerId);
	}

	menuItemSelected(menuItem: TableMenuItem, cell: PropertyAnnotation<any>, row: TableDataRowLayout, $event: MouseEvent) {
		// prevent the  rowclick
		$event.preventDefault();
		$event.cancelBubble = true;
		$event.stopImmediatePropagation();

		// check if empty and deletion is requested remove
		if (gv(() => menuItem.selectionMeta.menuAction as string, '').toLowerCase() === 'deleterow' && row.isEmpty) {
			const index = this.renderSchema.dataGroups[0].rows.findIndex(r => r.rowId === row.rowId);
			this.renderSchema.dataGroups[0].rows.splice(index, 1);
			return;
		}

		const rowData = generateObject<T>(row, this.data, 'value', this.renderSchema.rowIndexMapper);

		this.rowMenuItemClicked.emit({
																																selectedMenuItem: menuItem,
																																item:             this.data.dataAnnotation
																																																						.fields
																																																						.find(value => value.id === cell.id as any),
																																row:              rowData,
																																origin:           row
																															});


	}

	createNewRow() {
		CsTableNxtParser.addEmptyRow(this.renderSchema, this.data);
		this.refreshTable();

	}

	isValid(): boolean {
		for (const group of this.renderSchema.dataGroups) {
			for (const row of group.rows) {
				row.cells.forEach(cell => cell.validate());
			}
		}

		return !this.hasInvalidValues();
	}

	private scrollHandles: {
		drops: Observable<ObservedValueOf<Observable<{
			x: number;
			y: number
		}>>>;
		drags: Observable<ObservedValueOf<Observable<{
			x: number;
			y: number
		}>>>
	};

	private cachedOriginalData: string = null;
	private cachedLookups: Lookup[]    = [];

	/**
		* Trackers for pagination
		*/
	private _pageStartRow = 0;
	private _pageEndRow   = 0;

	/**
		* Default page size when developer has not provided any value
		*/
	private defaultPageSize = 25;
	private _originalData: TableDataDescribed<T>;

	private async createTable(dataDescribed: TableDataDescribed<T>) {
		if (hasPropertyOf(<TableDataDescribed<T>>dataDescribed, 'dataAnnotation')) {

			this.allRows  = [];
			this.rowCount = 0;

			if (isNullOrUndefined(dataDescribed.layout))
				dataDescribed.layout = new LayoutAnnotation<T>({});

			this.tableLayoutParse(new TableAnnotation(dataDescribed.layout.table));
			this.renderSchema = CsTableNxtParser.parseDataAnnotations(dataDescribed);
			try {
				this.noDataColSpan = this.renderSchema.headerRows[this.renderSchema.headerRows.length - 1].headers.length;
			} catch (e) {
				LoggerUtil.error(e);
			}

			this.formatCells(this.renderSchema);
			this.setPagination(dataDescribed.data);
			this.showDataAsync(this.page)
							.then(() => {

								if (this.initialLoading)
									this.initialLoading = false;

								if (this.filter)
									this.createFilterForEachColumnAsync(this.allRows, (this.data.layout.table.serverSideFilter == null || isEmptyObject(
														this.data.layout.table.serverSideFilter.filter)))
													.then(value => {
														this.filterOptions = value;
													});
							});


			this.countRowsGroup(this.renderSchema.dataGroups);
		}
	}

	private async updateTable(dataDescribed: TableDataDescribed<T>) {
		if (hasPropertyOf(<TableDataDescribed<T>>dataDescribed, 'dataAnnotation')) {
			this.renderSchema = CsTableNxtParser.parseDataAnnotations(dataDescribed);
			try {
				this.noDataColSpan = this.renderSchema.headerRows[this.renderSchema.headerRows.length - 1].headers.length;
			} catch (e) {
				LoggerUtil.error(e);
			}

			this.formatCells(this.renderSchema);
			this.setPagination(dataDescribed.data);
			this.showDataAsync(this.page);

			this.allRows  = [];
			this.rowCount = 0;
			this.countRowsGroup(this.renderSchema.dataGroups);
		}
	}

	/**
		* Sort values.
		*/
	private sortValues(row1: TableDataRowLayout, row2: TableDataRowLayout, sortColumn: string) {
		const value1 = row1.cells.find(cell => cell.id === sortColumn).value;
		const value2 = row2.cells.find(cell => cell.id === sortColumn).value;
		let result   = 0;
		if (value1 == null && value2 != null) {
			result = -1;
		} else if (value1 != null && value2 == null) {
			result = 1;
		} else if (value1 == null && value2 == null) {
			result = 0;
		} else if (typeof value1 === 'string' && typeof value2 === 'string') {
			result = value1.localeCompare(value2);
		} else {
			result = (value1 < value2)
												? -1
												: (value1 > value2)
														? 1
														: 0;
		}
		return (this.sortDir === 'asc'
										? result
										: -result);
	}

	private updateTemplates() {
		//The setTimeout does not allow to icons to render with pagination
		//NgZone won't do the trick either for the filtering header
		//setTimeout(() => {
		this.templatesLoaders.changes.pipe(take(1))
						.subscribe(value => {
							for (const tmpl of value.toArray()) {
								tmpl.resolveTemplate();
							}
							this.cdRef.detectChanges();
						});
		//}, 0);
	}

	/**
		* removes pagination if the rows are collapsible
		*/
	private checkIfPageable(dataGroups: TableDataGroupLayout[]) {
		const isCollapsible = dataGroups
			.filter(value => value.rows
																									.find(row => row.isCollapsible));
		if (isCollapsible.length > 0) {
			this.disablePagination = true;
			this.pageable          = false;
			this.hasCollapseAble   = true;
		}

		return isCollapsible.length === 0 && this.pageable;
	}

	private formatCells(renderSchema: TableLayout<T>) {
		const lastRow = renderSchema.headerRows[renderSchema.headerRows.length - 1];

		for (let index = 0; index < lastRow.headers.length; index++) {
			const header      = lastRow.headers[index];
			const layoutAnnot = (!isNullOrUndefined(this.data.layout)
				&& !isNullOrUndefined(this.data.layout.table)
				&& !isNullOrUndefined(this.data.layout.table.headers))
																							? this.data.layout.table.headers.find(h => h.id === header.id)
																							: null;
			const dataAnnot   = (!isNullOrUndefined(this.data.dataAnnotation) && !isNullOrUndefined(this.data.dataAnnotation.fields))
																							? this.data.dataAnnotation.fields.find(h => h.id === header.id)
																							: null;

			// error checking
			if (dataAnnot == null) {

				if (!isString(header.id))
					LoggerUtil.error(`The id of header: ${header.id} is not a string. Please fix this on the server`);

				const faultyHeader = this.data.dataAnnotation.fields.find(field => !isString(field.id));

				if (faultyHeader)
					LoggerUtil.error(`The id of header: ${faultyHeader.id.toString()} is not a string in the dataAnnotation.fields, Please fix this on the server`);


				return;
			}

			// get the registered data-type so we can make a data conversion if needed
			const dataType  = dataAnnot.type;
			let formatEntry = this.formatService.getFormatEntry(dataType);
			let overrideFormatString;

			// check if the server provides an format string
			if (!isNullOrUndefined(dataAnnot.format))
				overrideFormatString = dataAnnot.format;
			// check if the programmer has provided an format string this overrides the server string
			if (!isNullOrUndefined(layoutAnnot)
				&& !isNullOrUndefined(layoutAnnot.cellFormat))
				overrideFormatString = layoutAnnot.cellFormat;

			// if no cellformat and there is no string format provided is found go to next header
			if (isNullOrUndefined(formatEntry) && isNullOrUndefined(overrideFormatString)) continue;

			// if no data-type is registered in the formatting registry create a dummy
			if (isNullOrUndefined(formatEntry))
				formatEntry = new FormatRegisteredItem(dataAnnot.type, '');

			if (!isNullOrUndefined(overrideFormatString))
				formatEntry.formatString = overrideFormatString;

			header.cellFormat = formatEntry;

			for (const group of this.renderSchema.dataGroups) {
				for (const row of group.rows) {
					const cell = row.cells[index];
					if (!isNullOrUndefined(cell)) {
						cell.displayValue = this.formatService.format(cell.value, formatEntry);

						// Conditional formating of the cells
						if (!isNullOrUndefined(header.conditionalFormatting)) {
							for (const condition of header.conditionalFormatting) {
								// todo: fix reference to original value
								const originalValue = (cell as any)._originalValue;
								if (originalValue == null)
									continue;

								const value = originalValue;

								switch (condition.operator) {
									case '==':
										if (value === condition.referenceValue)
											cell.classList.push(condition.style);
										break;
									case '!=':
										if (value !== condition.referenceValue)
											cell.classList.push(condition.style);
										break;
									case '>=':
										if (value >= condition.referenceValue)
											cell.classList.push(condition.style);
										break;
									case '<=':
										if (value <= condition.referenceValue)
											cell.classList.push(condition.style);
										break;
									case '>':
										if (value > condition.referenceValue)
											cell.classList.push(condition.style);
										break;
									case '<':
										if (value < condition.referenceValue)
											cell.classList.push(condition.style);
										break;
								}


							}
						}
					}
				}
			}
		}
	}

	private getObservables(domItem) {
		const mouseEventToCoordinate = mouseEvent => {
			mouseEvent.preventDefault();
			return {
				x: mouseEvent.clientX,
				y: mouseEvent.clientY
			};
		};
		const mouseDowns             = fromEvent(domItem, 'mousedown')
			.pipe(map(mouseEventToCoordinate));
		const mouseMoves             = fromEvent(window, 'mousemove')
			.pipe(map(mouseEventToCoordinate));
		const mouseUps               = fromEvent(window, 'mouseup')
			.pipe(map(mouseEventToCoordinate));

		const drags = mouseDowns.pipe(concatMap(dragStartEvent =>
																																											mouseMoves.pipe(takeUntil(mouseUps), map(dragEvent => {
																																												const x = dragEvent.x - dragStartEvent.x;
																																												const y = dragEvent.y - dragStartEvent.y;
																																												return {x, y};
																																											})))
		);

		const drops = mouseDowns.pipe(concatMap(dragStartEvent =>
																																											mouseUps.pipe(first(), map(dragEndEvent => {
																																												const x = dragEndEvent.x - dragStartEvent.x;
																																												const y = dragEndEvent.y - dragStartEvent.y;
																																												return {x, y};
																																											}))
		));

		return {drags, drops};
	}

	private filterDataServerSidePaging(filter: {
																																													[p: string]: any
																																												} | {}, columKey: string) {

		this.filterRequestServerSidePaging.emit(new ServerSideFilter(filter, columKey));
	}

	private async refreshTable() {

		this.formatCells(this.renderSchema);
		this.setPagination(this.data);
		this.showDataAsync(this.page);

		this.allRows  = [];
		this.rowCount = 0;
		this.countRowsGroup(this.renderSchema.dataGroups);
	}
}

