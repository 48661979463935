import { PropertyAnnotation }                         from './property-annotation.model';
import { getPropertyOf, hasPropertyOf }               from '@cs/core/utils';
import { DataDescribedDataGroup, DataDescribedGroup } from './data-described-group.model';


export class DataAnnotation<T> {

	displayName: string;
	fields: PropertyAnnotation<T>[];
	groups: DataDescribedGroup[];
	dataGroups?: DataDescribedDataGroup[];

	constructor(init: Partial<DataAnnotation<T>>) {
		this.displayName = getPropertyOf(init, 'displayName', '');
		this.fields      = init.fields.map(a => new PropertyAnnotation(a));
		this.groups      = hasPropertyOf(init, 'groups')
											 ? init.groups.map(a => new DataDescribedGroup(a))
											 : [];
		this.dataGroups      = hasPropertyOf(init, 'dataGroups')
											 ? init.groups.map(a => new DataDescribedDataGroup(a))
											 : [];
	}
}
