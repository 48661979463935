import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	Type
}                                      from '@angular/core';
import { DashboardCombiData }          from '../../models/dashboard-combi-data';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { ArrayUtils }                  from '@cs/core';
import { PopoverService }              from '@cs/components/popover';
import { DashboardLegendComponent }    from '../dashboard-legend/dashboard-legend.component';
import { DashboardCombiListComponent } from '../dashboard-list/dashboard-list.component';

@Component({
			   selector:        'cs-dashboard-combi-entry-state',
			   templateUrl:     './dashboard-combi-entry-state.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   styles:          [
				   `:host {
					   width: 100%;
				   }`
			   ]
		   })
export class DashboardCombiEntryStateComponent extends DashboardPanelComponentBase<DashboardCombiData> {
	name: string;

	entryStatePosition                               = {transform: 'rotate(0deg)'};
	legendComponent: Type<DashboardLegendComponent>  = DashboardLegendComponent;
	listComponent: Type<DashboardCombiListComponent> = DashboardCombiListComponent;

	constructor(private changeRef: ChangeDetectorRef, private popper: PopoverService) {
		super();
	}

	dataChanged(value: DashboardCombiData) {
		throw new Error('Method not implemented.');
	}

	update(data: DashboardCombiData): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

}

