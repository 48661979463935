<div class="data-entry-state" [class.flex-column]="!renderSchema?.layout?.renderAsLegend">
	<!--	<div class="data-entry-state__legend">-->
	<!--		<ng-container *ngFor="let row2 of legendRows">-->
	<!--			<cs-data-entry-state-row [rowContext]="row2"-->
	<!--									 [rowPosition]="legendRenderSchema?.headerPositions[legendRenderSchema?.headerPositions?.length - 1]">-->

	<!--			</cs-data-entry-state-row>-->
	<!--		</ng-container>-->
	<!--	</div>-->

	<div csWidthRuler></div>
	<div #heightRef="csLengthRulerRef" style="position: absolute; height: 100%" csLengthRuler></div>
	<div class="vertical-curtain"
		 [ngStyle]="{
	height: heightCurtain+'px',
	width: widthCurtain+'px'
	}"></div>

	<div class="flex" [class.flex-column]="!renderSchema?.layout?.renderAsLegend">
		<ng-container *ngFor="let row of headerRows; ">
			<div class="data-entry-state-header-row flex depth-{{row.path.length}}"
				 [class.flex-column]="!renderSchema?.layout?.renderAsLegend">
				<div class="data-entry-state-header-row__content">
					<ng-container *ngFor="let column of row?.columns; trackBy: trackByStructureKey">
						<ng-container *ngIf="column?.visualState?.visible">
							<cs-data-entry-state-header [column]="column"
														[depth]="row.path.length"></cs-data-entry-state-header>
							<div style="min-width: 2px;"></div>
						</ng-container>
					</ng-container>
				</div>
			</div>
		</ng-container>
	</div>
	<div class="flex" [class.flex-column]="!renderSchema?.layout?.renderAsLegend">
		<ng-container *ngFor="let row of dataRows;trackBy:trackByRowStructureKey">
			<cs-data-entry-state-row [rowContext]="row"
									 [rowPosition]="renderSchema?.headerPositions[renderSchema?.headerPositions?.length - 1]"
									 [badges]="row?.badges"
									 [orientation]="!renderSchema?.layout?.renderAsLegend?'COLUMN':'ROW'"
			>

			</cs-data-entry-state-row>
		</ng-container>
	</div>
</div>
