import { getPropertyOf, hasPropertyOf }    from '@cs/core';
import { createToObjectWithLowerCaseKeys } from '@cs/components/util';



export enum DashboardUpdatesItemLabelPartType {
  ACTOR      = 'actor',
  ACTION     = 'action',
  EMPHASISES = 'emphasises',
  SUBJECT    = 'subject'
}

export enum DashboardUpdatesItemType {
  WORKFLOW   = 'workflow',
  FEED       = 'feed',
  DATA_ENTRY = 'dataentry',
  EVENT      = 'event'
}

export class DashboardUpdatesItemLabelPart {
  link: string | { [key: string]: any } | null;
  text: string | null;
  type: string | null;

  constructor(init: DashboardUpdatesItemLabelPart) {
    // temporary workaround
    const temp = createToObjectWithLowerCaseKeys(init) as DashboardUpdatesItemLabelPart;
    this.link  = getPropertyOf(temp, 'link', null);
    this.text  = getPropertyOf(temp, 'text', null);
    this.type  = getPropertyOf(temp, 'type', null);
  }
}

export class DashboardUpdatesItem {
  get iconDescription(): string {
    switch (this.type) {
      case DashboardUpdatesItemType.WORKFLOW:
        return 'UPDATE_WORKFLOW_DESCRIPTION';
      case DashboardUpdatesItemType.EVENT:
        return 'UPDATE_EVENT_DESCRIPTION';
      case DashboardUpdatesItemType.FEED:
        return 'UPDATE_FEED_DESCRIPTION';
      case DashboardUpdatesItemType.DATA_ENTRY:
        return 'UPDATE_DATA_ENTRY_DESCRIPTION';
      default:
        return 'UPDATE_DESCRIPTION';
    }
  }

  get icon(): string {
    switch (this.type) {
      case DashboardUpdatesItemType.WORKFLOW:
        return 'mdi mdi-finance';
      case DashboardUpdatesItemType.EVENT:
        return 'mdi mdi-calendar';
      case DashboardUpdatesItemType.FEED:
        return 'mdi mdi-flag';
      case DashboardUpdatesItemType.DATA_ENTRY:
        return 'mdi mdi-pencil';
      default:
        return 'mdi mdi-update';
    }
  }

  private _icon: string | null;
  type: DashboardUpdatesItemType;
  label: DashboardUpdatesItemLabelPart[];
  link: string | { [key: string]: any };
  timestamp: Date;

  constructor(init: DashboardUpdatesItem) {
    this.label     = hasPropertyOf(init, 'label') ? init.label.map(value => new DashboardUpdatesItemLabelPart(value)) : [];
    this.link      = getPropertyOf(init, 'link', null);
    this.type      = getPropertyOf(init, 'type', null);
    this.timestamp = hasPropertyOf(init, 'timestamp') ? new Date(init.timestamp) : null;
  }
}

export class DashboardUpdates {

  items: DashboardUpdatesItem[];

  constructor(init: DashboardUpdates) {
    this.items = hasPropertyOf(init, 'items') ? init.items.map(value => new DashboardUpdatesItem(value)) : [];
  }
}
