import { Injectable } from '@angular/core';

export type CleanupAction = (params: { [key: string]: any }) => { [key: string]: any };

@Injectable({providedIn: 'root'})
export class AppNavigationService {

	private _registeredQueryParamActions: Array<CleanupAction>    = [];
	private _registeredQueryParamPreActions: Array<CleanupAction> = [];
	private _subscribers: Map<string, () => boolean>              = new Map();

	constructor() {
		window.addEventListener('beforeunload', (e) => {
			const values = Array.from(this._subscribers.values());
			for (let i = 0; i < values.length; i++) {
				const item = values[i];
				if (!item()) {
					e.returnValue = ' ';     // Gecko and Trident
					return ' ';              // Gecko and WebKit
				}
			}

		});
	}

	cleanUpQueryParams(patchedParams: { [p: string]: any }) {

		let params = patchedParams;

		for (const action of this._registeredQueryParamActions) {
			params = action(params);
		}

		// convert the NULL string in the selection PARAMS to empty string, this is done because of the queryparam merge
		const patchedQueryParams = {};
		for (const key of Object.keys(params)) {
			patchedQueryParams[key] = params[key] === 'null' ? '' : params[key];
		}
		return patchedQueryParams;
	}

	registerCleanUpAction(action: CleanupAction) {
		this._registeredQueryParamActions.push(action);
	}

	registerPreProcessAction(action: CleanupAction) {
		this._registeredQueryParamPreActions.push(action);
	}

	preProcessQueryParams(patchedParams: { [p: string]: any }) {
		let params = patchedParams;

		for (const action of this._registeredQueryParamPreActions) {
			params = action(params);
		}
		return params;
	}

	canNavigate() {
		const values = Array.from(this._subscribers.values());
		for (let i = 0; i < values.length; i++) {
			const item = values[i];
			if (!item()) {
				return false;
			}

		}
		return true;
	}

	registerForStoppingNavigationChanges(ticket: string, callback: () => boolean) {
		this._subscribers.set(ticket, callback);
	}

	unregisterForStoppingNavigationChanges(ticket: string) {
		this._subscribers.delete(ticket);
	}
}
