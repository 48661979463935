<div class="download-page flex ">
  <div class="download-page-item-container" [@listAnimation]="downloadItems?.length">
    <cs-placeholder [rows]="isLoadingDebounced$ | async" [width]="'50%'" [isLoading]="false"></cs-placeholder>
    <ng-container *ngFor="let item of downloadItems">
      <div class="cs-panel download-item">
        <div class="download-item--heading">
          <p class="download-item--title"><i class="mdi mdi-download"></i>{{item.name}}</p>
        </div>
        <div class="download-item--body">
          <p>{{item.description || ('NO_DESCRIPTION' | translate | titlecase)}}</p>
          <div class="download-item--link-container">
            <a class="link"
               csHyperlink
               [csHyperlinkDisabled]="!item.fileExists"
               (csHyperlinkClick)="downloadFile(item)"
               [csLoader]="item.isLoading"
            >{{item.fileName}}.{{item.fileExtension}}</a>
            <span *ngIf="!item.fileExists">({{'DOWNLOAD_NOT_AVAILABLE' | translate | titlecase}})</span>
          </div>
          <div class="download-item--date">
            <span>{{item.datePublication | date}}</span>
            <span class="ml-2 mr-2">|</span>
            <span>{{item?.fileSize | fileSize}}</span>
          </div>
        </div>
      </div>
    </ng-container>

  </div>
</div>
