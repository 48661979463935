import { WizardStepsDataDescribed } from '@cs/components/wizard';
import { Result }                   from '@cs/core';
import { CsHttpRequestOptions }     from '@cs/core/http';
import { Observable }               from 'rxjs';

import { RequestStepDataEventArgs } from '../models/request-step-data-event-args';

export abstract class WizardConfigService {

	abstract getStepData(eventArgs: RequestStepDataEventArgs,  refreshContext?: {
		[key: string]: any
	}, errorHandler?: CsHttpRequestOptions): Observable<Result<WizardStepsDataDescribed>>;

	abstract finishWizard(eventArgs: RequestStepDataEventArgs, errorHandler?: CsHttpRequestOptions): Observable<Result<boolean>>;

	abstract validateWizardSteps(stepContextData: RequestStepDataEventArgs, errorHandler?: CsHttpRequestOptions): Observable<Result<boolean>>;

	abstract updateWizardSteps(stepContextData: RequestStepDataEventArgs, errorHandler?: CsHttpRequestOptions): Observable<Result<WizardStepsDataDescribed>>;
}
