import { SafeHtml } from '@angular/platform-browser';

export class Changelog {
  public issueTrackerUrl: string;
  public issueNumberRegex: RegExp;
  public changeSets: ChangeSet[] = [];
}

export class ChangeSet {
  public date: Date                               = new Date();
  public name                                     = '';
  public categorizedChanges: CategorizedChanges[] = [];
  public totalChangeCount: number = 0;
}

export class CategorizedChanges {
  public category: string;
  public changes: Change[] = [];
}

export class Change {
  public message: SafeHtml;
  public repositoryUrls: {url: string, hashes: string[]};

}
