import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';
import { FormsModule }                   from '@angular/forms';
import { CsAvatarComponent }             from './avatar.component';

@NgModule({
  declarations:    [CsAvatarComponent],
  exports:         [CsAvatarComponent],
  imports:         [CommonModule, FormsModule],
  entryComponents: [CsAvatarComponent]
})
export class CsAvatarModule {
  static forRoot(): ModuleWithProviders<CsAvatarModule> {
    return {ngModule: CsAvatarModule};
  }
}
