import { NgModule }                                           from '@angular/core';
import { DefaultFormatRegistry, FormatRegistry }              from '@cs/common/culture';
import { CustomerRoutingModule }                              from '@cs/customer/customer-routing.module';
import { CustomerDynamicButtonsAgent }                        from '@cs/customer/customer-dynamic-buttons.service';

import { DefaultNavbarIconsFactory, SHELL_NAVBAR_ICONS_REPO } from '@cs/performance-manager';

export function CustomerNavbarIconsFactory() {
	const iconsLib = DefaultNavbarIconsFactory();
	return iconsLib;
}

/**
 * This bootstraps any angular customer specific code;
 */
@NgModule({
						declarations: [],
						imports:      [CustomerRoutingModule],
						providers:    [{provide: SHELL_NAVBAR_ICONS_REPO, useFactory: CustomerNavbarIconsFactory},
													 {provide: FormatRegistry, useClass: DefaultFormatRegistry},
													 {provide: CustomerDynamicButtonsAgent, useClass: CustomerDynamicButtonsAgent}],
						exports:      [CustomerRoutingModule]
					})
export class CustomerInitModule {
}
