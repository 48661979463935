import { ErrorHandler, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CommonModule }                                      from '@angular/common';
import { ErrorQuery }                          from './state/error.query';
import { ErrorService }                        from './state/error.service';
import { ErrorStore }                          from './state/error.store';
import { HttpErrorInterceptor }                from './http-error.interceptor';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthenticationService }               from '../authentication/state/authentication.service';
import { CsGenericErrorLogger }                from '@cs/common';

const providers = [
  ErrorQuery, ErrorService, ErrorStore, AuthenticationService, CsGenericErrorLogger
];

@NgModule({
  declarations: [],
  imports:      [
    CommonModule,
    HttpClientModule
  ],
  providers:    providers
})
export class ErrorHandlingModule {
  static forRoot(): ModuleWithProviders<ErrorHandlingModule> {
    return {
      ngModule:  ErrorHandlingModule,
      providers: [
        {provide: ErrorHandler, useClass: CsGenericErrorLogger},
        {provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true},
        ...providers
      ]
    };
  }
}
