import { ChangeDetectorRef, Component, forwardRef, Inject } from '@angular/core';
import {
	BranchTypes, GitTableNavigationFilter, hasRecursiveChildRepositories, mapRepositoriesByLabel, RepositoryDto, RepositoryType
}                                                           from '@cs/performance-manager/git-graph';
import { UntilDestroy, untilDestroyed }                     from '@ngneat/until-destroy';
import { filter as filter$ }                                from 'rxjs/operators';

import { FilterCompareBarQuery, FilterCompareBarService } from '@cs/components/filter-and-compare-bar';
import { isNullOrUndefined }                              from '@cs/core/utils';
import { GitSubmoduleTableConfigService }                 from './git-submodule-table-config.service';


@UntilDestroy()
@Component({
			   selector:    'pm-git-submodule-view',
			   templateUrl: './git-submodule-table.component.html',
			   styleUrls:   ['./git-submodule-table.component.scss']
		   })
export class GitSubmoduleTableComponent {

	// Head repositories and their sub repositories
	public parentRepositoryMap: Map<string, RepositoryDto[]>      = new Map<string, RepositoryDto[]>();
	public parentRepositoryMapStore: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

	// Filter parameters
	public selectedStrictFilterParams: GitTableNavigationFilter = new GitTableNavigationFilter();

	public showAll = false;

	public constructor(@Inject(forwardRef(() => GitSubmoduleTableConfigService)) public service: GitSubmoduleTableConfigService,
					   @Inject(ChangeDetectorRef) public changeDetector: ChangeDetectorRef,
					   @Inject(FilterCompareBarQuery) public filterCompareBarQuery: FilterCompareBarQuery,
					   @Inject(FilterCompareBarService) public filterCompareBarService: FilterCompareBarService) {
	}

	public loadRepositoryData() {

		// Fix parameter identifiers, parameters have to be set in this way because some groups might contain the same label and dont know fast way to fix this
		if (this.selectedStrictFilterParams.parentRepositoryLabel != undefined) this.selectedStrictFilterParams.parentRepositoryLabel = this.selectedStrictFilterParams.parentRepositoryLabel.split(' | ')[0];


		if (this.selectedStrictFilterParams.parentBranch != undefined) this.selectedStrictFilterParams.parentBranch = this.selectedStrictFilterParams.parentBranch.split(' | ')[0];
		if (this.selectedStrictFilterParams.parentBranchLoose != undefined) this.selectedStrictFilterParams.parentBranchLoose = this.selectedStrictFilterParams.parentBranchLoose.split(' | ')[0];
		if (this.selectedStrictFilterParams.childRepositoryNameLoose != undefined) this.selectedStrictFilterParams.childRepositoryNameLoose = this.selectedStrictFilterParams.childRepositoryNameLoose.split(' | ')[0];
		if (this.selectedStrictFilterParams.childBranch != undefined) this.selectedStrictFilterParams.childBranch = this.selectedStrictFilterParams.childBranch.split(' | ')[0];
		if (this.selectedStrictFilterParams.childBranchLoose != undefined) this.selectedStrictFilterParams.childBranchLoose = this.selectedStrictFilterParams.childBranchLoose.split(' | ')[0];


		// If the childApplication is not of a type lib then will only filter for direct child repositories
		if (this.selectedStrictFilterParams.childApplicationType == RepositoryType.LIB) {
			this.selectedStrictFilterParams.childApplicationTypes = [RepositoryType.LIB, RepositoryType.CDP, RepositoryType.CD];
			this.selectedStrictFilterParams.IgnoreTypes           = [RepositoryType.CDP, RepositoryType.CD];
			this.selectedStrictFilterParams.checkRecursive        = true;
		} else {
			this.selectedStrictFilterParams.checkRecursive = false;
		}

		// --- Log for debug
		console.log('Submodule filter selection');
		console.log(this.selectedStrictFilterParams);

		// Load data
		this.service.GetSubRepositories(this.selectedStrictFilterParams)
			.subscribe(result => {

				console.log('Resulting values');
				console.log(result.value);

				// dirty bandaid to make sure only the top of the lib shows
				const topRepositories = this.selectedStrictFilterParams.childApplicationType == RepositoryType.LIB
										?
										result.value.filter(possibleChildRepository =>
																possibleChildRepository.subModules.some(submodule => submodule.repositoryType == RepositoryType.LIB) &&
																!result.value.some(possibleParentRepository =>
																					   possibleParentRepository.subModules.some(submodule => submodule.name == possibleChildRepository.name
																					   )))
										:
										result.value;

				console.log('Resulting repositories');
				console.log(topRepositories);

				let repositoryMap = mapRepositoriesByLabel(topRepositories);

				repositoryMap.forEach((value) => {
					value.sort((a, b) => b.commitsBehind - a.commitsBehind);
				});


				this.parentRepositoryMap      = repositoryMap;
				this.parentRepositoryMapStore = repositoryMap;

				this.changeDetector.markForCheck();
			});
	}

	public ngAfterViewInit(): void {

		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(
				untilDestroyed(this),
				filter$(value => !isNullOrUndefined(value))
			)
			.subscribe((resultParams) => {

				this.selectedStrictFilterParams = new GitTableNavigationFilter();

				for (const key in resultParams.selection) {

					let parameterValue = resultParams.selection[key];

					if (parameterValue != undefined) {

						// // Split here is used because some filters have same label but different identifier but should filter the same thing (split[0] = label)
						if (key == 'parentRepositoryLabel') {

							const parentFilterGroupItem = RepositoryType[RepositoryType[parameterValue]];

							//ts ignore because otherwise get CDP instead of 0, 1, 2
							if (parentFilterGroupItem) {
								// @ts-ignore
								this.selectedStrictFilterParams['childApplicationType'] = RepositoryType[parentFilterGroupItem];
							} else {
								this.selectedStrictFilterParams[key] = parameterValue;
							}

						} else {

							if (key == 'parentBranch') {
								const parentFilterGroupItem = BranchTypes[BranchTypes[parameterValue]];

								if (parentFilterGroupItem) {
									this.selectedStrictFilterParams['parentBranch'] = parameterValue;
								} else {
									this.selectedStrictFilterParams[key] = parameterValue;
								}

							} else {
								this.selectedStrictFilterParams[key] = parameterValue;
							}

						}
					}
				}

				this.loadRepositoryData();
			});

	}

	hasSubmodules(repositories: RepositoryDto[]): boolean {
		return repositories.some(r => r.subModules.length > 0);
	}

	// ---------------- Navigate ---------------- \\
	navigate($event: any) {

		const childRepository: RepositoryDto  = $event.childRepository;
		const parentRepository: RepositoryDto = $event.parentRepository;

		let selection;

		if (childRepository.repositoryType == RepositoryType.LIB) {

			selection = {
				parentRepositoryLabel: `${childRepository.label} | ${this.selectedStrictFilterParams.parentApplicationType}`,
				parentBranch:          `${childRepository.branch}`,

				navId: 'git-submodule-table'
			};

			const looseBranch = BranchTypes[childRepository.branch];
			const branchTypes = Object.keys(BranchTypes)
									  .filter(key => isNaN(Number(BranchTypes[key])))
									  .map(key => BranchTypes[key]);

			if (looseBranch != undefined) {
				selection.parentBranchLoose = `${childRepository.branch} | ${childRepository.branch}`;
			} else {
				const expectedBranchGroup = branchTypes.filter(br => childRepository.branch.includes(br))[0];

				if (expectedBranchGroup != undefined) {
					selection.parentBranch = `${childRepository.branch} | ${branchTypes.filter(br => childRepository.branch.includes(br))[0]}`;
				} else {
					selection.parentBranch = `${childRepository.branch}`;
				}
			}

		} else {
			selection = {
				parentRepositoryLabel: `${childRepository.label} | ${childRepository.repositoryType}`,
				parentBranch:          childRepository.branch,

				childRepositoryName: `${parentRepository.label}`,
				childBranch:         parentRepository.branch,

				navId: 'git-customer-table'
			};
		}

		console.log('Given selection');
		console.log(selection);

		this.filterCompareBarService.triggerNavigation(selection);
	}

	protected readonly JSON = JSON;

	// --- has to be public because angular shits itself when it's protected
	public readonly hasRecursiveChildRepositories = hasRecursiveChildRepositories;
	protected readonly untilDestroyed             = untilDestroyed;
}
