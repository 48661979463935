import { NgModule }              from '@angular/core';
import { CommonModule }          from '@angular/common';
import { PmDeployInfoComponent } from './pm-deploy-info.component';
import { RouterModule }          from '@angular/router';
import { TranslateModule }       from '@ngx-translate/core';
import { CsSharedModule }        from '@cs/components/shared';
import { MatTooltipModule }      from '@angular/material/tooltip';

const COMPONENTS = [
	PmDeployInfoComponent
];

@NgModule({
						declarations: [...COMPONENTS],
						imports:      [
							CommonModule,
							RouterModule,
							CsSharedModule,
							TranslateModule.forChild(),
							MatTooltipModule
						],
						exports:      [...COMPONENTS]
					})
export class PmDeployInfoModule {
}
