<div class="cs-statistics cs-performance-stats">

	<div *ngIf="data?.headerLabel"
       class="cs-statistics--header">
    <b class="cs-statistics--header--label">
      {{data?.headerLabel}}
    </b>
  </div>

  <div class="cs-statistics-data">
    <div class="row mr-0 ml-0">
      <div class="col-6 summary-values" *ngFor="let data of data?.data.summaryValues">
        <label>{{data.label}}</label>
        <div class="value" title="{{data.description}}">{{data.value}}</div>
      </div>
    </div>

    <ng-container *ngFor="let barData of data?.data.comparisonBars">
      <div class="comparison-bar-label">{{barData.label}}</div>
      <div class="comparison-bar">
        <div
          *ngIf="barData.comparisonBar?.visible"
          class="bar comparison"
          title="{{barData.comparisonBar?.label}}"
          [style]="calculateWidth(barData.comparisonBar?.size)">
        </div>

        <div
          *ngIf="barData.mainBar?.visible"
          class="bar main"
          title="{{barData.mainBar?.label}}"
          [style]="calculateWidth(barData.mainBar?.size)">
        </div>

        <div
          *ngIf="barData.accentBar?.visible"
          class="bar accent"
          title="{{barData.accentBar?.label}}"
          [style]="calculateWidth(barData.accentBar?.size)">
        </div>

        <div class="value {{barData.valueStyle?.toLowerCase()}}">{{barData.value}}</div>
      </div>

    </ng-container>
  </div>
</div>

