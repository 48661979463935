import { Injectable }                          from '@angular/core';
import { Query }                               from '@datorama/akita';
import { DataEntryStateStore, DataEntryState } from './data-entry-state.store';

@Injectable({ providedIn: 'root' })
export class DataEntryStateQuery extends Query<DataEntryState> {

  constructor(protected store: DataEntryStateStore) {
    super(store);
  }

}
