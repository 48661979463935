export enum Key {
	Tab        = 9,
	Enter      = 13,
	Escape     = 27,
	Space      = 32,
	PageUp     = 33,
	PageDown   = 34,
	End        = 35,
	Home       = 36,
	ArrowLeft  = 37,
	ArrowUp    = 38,
	ArrowRight = 39,
	ArrowDown  = 40
}
