import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatDialog }                                      from '@angular/material/dialog';

// cs imports
import { calculateMonthsAgo, formatDate, getWeightColour } from '@cs/performance-manager/git-graph';
import { RepositoryDto }                                   from '@cs/performance-manager/git-graph';
import { SubmoduleUpdateDialogComponent }                  from '@cs/performance-manager/submodule-update-dialog';

// Local imports

import { UntilDestroy } from '@ngneat/until-destroy';
import { KeyValue }     from '@angular/common';


@UntilDestroy()
@Component({
			   templateUrl: './repository-columned-table.component.html',
			   selector:    'cs-repo-column-table',
			   styleUrls:   ['./repository-columned-table.scss']
		   })
export class RepositoryColumnedTableComponent {

	// data to display
	@Input('repository') public clientRepository: KeyValue<string, RepositoryDto[]>;

	@Input('allowUpdate') public allowUpdate             = false;
	@Input('collapseRecursive') public collapseRecursive = false;

	@Input('hasBranchExpander') public hasBranchExpander = false;
	@Input('mainBranch') public mainBranch: string                = undefined;

	@Input('showTitle') public showTitle         = true;
	@Input('initialCollapse') public isCollapsed = true;

	@Input('showChildCommitDifference') public showChildCommitDifference = true;

	@Input('titleColumnSizeOverride') public titleColumn = 250;
	@Input('dataColumnSizeOverride') public dataColumns  = 200;
	public diffColumn                                    = 150;

	@Output() updateEvent     = new EventEmitter();
	@Output() navigationEvent = new EventEmitter();

	columnsToDisplay: string[] = ['Name', 'Branch', 'Last Update', 'Commit Difference', 'Commit Hash'];

	protected readonly getWeightColour = getWeightColour;
	protected readonly parseInt        = parseInt;

	constructor(@Inject(MatDialog) private dialog: MatDialog) {
	}

	// ---------------- Display Tool function ---------------- \\
	getFullDate(repository: RepositoryDto): string {
		return repository != undefined
			   ? formatDate(new Date(repository.lastCommitDate))
			   : '';
	}

	getRoundedDate(repository: RepositoryDto): string {
		return repository != undefined
			   ? calculateMonthsAgo(new Date(repository.lastCommitDate))
			   : '';
	}

	getMainBranch(repositories: RepositoryDto[]): RepositoryDto {
		const result = repositories.filter(repository => repository.branch == this.mainBranch);

		return result[0];
	}

	// ---------------- Update Dialog ---------------- \\
	openUpdateDialog(parentRepository: RepositoryDto, childRepository: RepositoryDto) {

		const dialogRef =
				  this.dialog.open(SubmoduleUpdateDialogComponent, {
					  data: {
						  childRepository:    childRepository,
						  parentRepositories: [parentRepository],
						  allowSameBranch:    false,
						  afterUpdateEvent:   () => this.emitEvent()
					  }
				  });

		dialogRef.afterClosed()
				 .subscribe(result => {
					 console.log(`Dialog result: ${result}`);
				 });

	}

	async emitEvent() {
		this.updateEvent.emit();
	}

	// ---------------- Navigate ---------------- \\
	navigate(parentRepository: RepositoryDto, childRepository: RepositoryDto) {
		this.navigationEvent.emit({parentRepository, childRepository});
	}

	test(indentionLevel: any) {
		console.log('Indention');
		console.log(indentionLevel);
	}
}
