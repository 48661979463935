<table>
  <thead>
  <tr *ngIf="data?.groupedColumns?.length > 0">
    <th *ngFor="let col of data?.groupedColumns"
        [attr.colspan]="col.colspan">
      {{col.headerLabel}}
    </th>
  </tr>
  <tr>
    <ng-container *ngFor="let col of data.columns">
      <ng-container *ngIf="col?.vertical">
        <th [ngStyle]="{'height':col.headerLabel.length * 7+'px'}"
            [class.rotate]="col.vertical">
          <div [ngStyle]="{'margin-top': marginTop}">
            {{col.headerLabel}}
          </div>
        </th>
      </ng-container>
      <ng-container *ngIf="!col.vertical">
        <th>
          <div>
            {{col.headerLabel}}
          </div>
        </th>
      </ng-container>
    </ng-container>
  </tr>

  </thead>
  <tbody>
  <tr *ngFor="let row of data.data">
    <td *ngFor="let cell of row; let index = index"
        [class.status-cell]="data?.columns[index]?.type === 'status'">
      <ng-container [ngSwitch]="data?.columns[index]?.type">
        <ng-container *ngSwitchCase="'label'">
          <svg width="18px" height="18px" viewBox="0 0 18 18" version="1.1" xmlns="http://www.w3.org/2000/svg"
               xmlns:xlink="http://www.w3.org/1999/xlink">
            <g id="Overview-pages" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
              <g id="Group-4-Copy"
                 transform="translate(7.656854, 8.656854) rotate(-135.000000) translate(-7.656854, -8.656854) translate(3.656854, 4.656854)"
                 fill="#B2BFC5">
                <path
                  d="M2,5.41421356 L7.41421356,5.41421356 L7.41421356,7.41421356 L2,7.41421356 L-3.41060513e-13,7.41421356 L-3.41060513e-13,1.42108547e-13 L2,1.42108547e-13 L2,5.41421356 Z"
                  id="Combined-Shape"></path>
              </g>
            </g>
          </svg>
          <div [ngStyle]="cell?.layout?.style"
               title="{{cell?.d}}"
          >{{cell?.v}}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'text'">
          <div class="text" [ngStyle]="cell?.layout?.style">{{cell?.v}}</div>
        </ng-container>
        <ng-container *ngSwitchCase="'status'">
          <a class="datablock"
             (click)="navigateTo(cell)"
             [ngStyle]="cell?.layout?.style"
             title="{{cell?.d}}"></a>
        </ng-container>
        <ng-container *ngSwitchCase="'multi-status'">
          <div class="d-flex justify-content-center">
            <a *ngFor="let status of cell?.v"
               class="datablock-with-content {{status?.layout?.class}}"
               [class.opacity-30]="status?.v === 0"
               (click)="navigateTo(status)"
               [ngStyle]="status?.layout?.style"
               title="{{status?.d}}"
               csPopover
               [content]="listComponent"
               [settings]="{hasTip:true, class:'popover-dark'}"
               [csPopoverDisabled]="!status?.meta"
               openOn="mouseover"
               preferredPosition="top"
               [csPopoverData]="status">
              <div class="multi-status-content">{{status.v}}</div>
            </a>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="'entry-state'">
          <div class="d-flex align-items-center justify-content-center entry-state-container pl-2 pr-2">
            <b>{{cell?.v?.completed}}</b>
            <small class="text-muted mr-1 ml-1">of</small>
            <b >{{cell?.v?.total}}</b>
          </div>
        </ng-container>
      </ng-container>
    </td>
  </tr>
  </tbody>
</table>
