import { ValidationErrors }                                 from '@angular/forms';
import { DataGridRuleMatchFilter, DataGridRuleValueFilter } from '@cs/components/data-grid';
import { isNull, isNullOrUndefined }                        from '@cs/core';
import {
	isGreaterAndEqualsThanValidator, isGreaterThanValidator, isLessAndEqualsThanValidator, isLessThanValidator
}                                                           from './validators';


export class ValidationRuleMatch {
	factProperties: {
		[key: string]: ValidationRuleFilter
	} = {};
	levelKeys: {
		[key: string]: ValidationRuleFilter
	} = {};
	state: {
		[key: string]: ValidationRuleFilter
	} = {};
	values: {
		[key: string]: ValidationRuleFilter
	} = {};

	constructor(initial: Partial<ValidationRuleMatch> = null) {
		if (initial !== null)
			Object.assign(this, initial);
	}
}

export class ValidationRuleFilter {

	/**
		* Evaluates value for given filter. Return NULL when value is valid for all (set) filter properties.
		* When not valid return the actual and expected value
		* Undefined filter properties are ignored
		*/
	static evaluate(filter: ValidationRuleFilter, value: number): ValidationErrors {

		// check if empty values are allowed
		if (!isNullOrUndefined(filter.allowEmpty) && value == null)
			return filter.allowEmpty
										? null
										: {actual: value, allowEmpty: filter.allowEmpty};

		// If value is not
		if (!isNullOrUndefined(filter.isNot) && filter.isNot.indexOf(value) > -1)
			return {actual: value, isNot: filter.isNot.join(',')};

		// Check if the cell.value is IsNotNull
		if (!isNullOrUndefined(filter.isNotNull) && isNull(value))
			return {actual: value};

		// Check if the cell.value is IsNull
		if (!isNullOrUndefined(filter.isNull) && !isNull(value))
			return {actual: value};

		// Check if the cell.value is isNotNullOrUndefined
		if (!isNullOrUndefined(filter.isNotNullOrUndefined) && isNullOrUndefined(value))
			return {actual: value};

		// Check if the cell.value is isNullOrUndefined
		if (!isNullOrUndefined(filter.isNullOrUndefined) && !isNullOrUndefined(value))
			return {actual: value};

		// If value is greater or equals
		if (!isNullOrUndefined(filter.gte) && !isGreaterAndEqualsThanValidator(value, filter.gte))
			return {actual: value, gte: filter.gte};

		// If value is greater then
		if (!isNullOrUndefined(filter.gt) && !isGreaterThanValidator(value, filter.gt))
			return {actual: value, gt: filter.gt};

		// If value is less or equals
		if (!isNullOrUndefined(filter.lte) && !isLessAndEqualsThanValidator(value, filter.lte))
			return {actual: value, lte: filter.lte};

		// If value is less or equals
		if (!isNullOrUndefined(filter.lt) && !isLessThanValidator(value, filter.lt))
			return {actual: value, lt: filter.lt};

		// If value equals
		if (!isNullOrUndefined(filter.is) && filter.is.indexOf(value) === -1)
			return {actual: value, is: filter.is.join(',')};

		// Item is valid
		return null;
	}

	is: Array<number | string>;
	isNot: Array<number | string>;
	isNull: boolean;
	isNotNull: boolean;
	gt: number;
	gte: number;
	lt: number;
	lte: number;
	isNullOrUndefined: boolean;
	isNotNullOrUndefined: boolean;
	allowEmpty: boolean;

	constructor(initial?: Partial<ValidationRuleFilter>) {
		if (!isNullOrUndefined(initial)) {
			Object.assign(this, initial);
		}
	}

}
