export class RepositoryDto{

	name?: string;

	label?: string;

	identifier?: string;

	branch? : string;

	commit?: string;

	hashOrBranch?: string;

	changeLogCount? : number;

	commitsBehind?: number;

	commitsAhead?: number;

	subModules?: Array<RepositoryDto>;

	repositoryType?: RepositoryType;

	lastCommitDate? : Date;

	failed? : boolean;

	errorList? : string[];

	unPushedCommitCount? : number;

	public constructor(partial: Partial<RepositoryDto>) {Object.assign(this, partial);}
}

export enum RepositoryType{
	CDP,
	CD,
	LIB,
	UNDEFINED,
}
