/**
 * Created by alex on 11-7-2017.
 */

import { EmbeddedViewRef, OnChanges, SimpleChanges, ViewContainerRef } from '@angular/core';
import {
  Input,
  TemplateRef, Directive
}                                                                      from '@angular/core';


@Directive({
  selector: '[csTemplateOutlet]'
})
export class CsTemplateOutlet implements OnChanges {
  private _viewRef: EmbeddedViewRef<any>;

  @Input() public csTemplateOutletContext: Object;

  @Input() public csTemplateOutlet: TemplateRef<any>;

  constructor(private _viewContainerRef: ViewContainerRef) {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this._viewRef) {
      this._viewContainerRef.remove(this._viewContainerRef.indexOf(this._viewRef));
    }

    if (this.csTemplateOutlet) {
      this._viewRef = this._viewContainerRef.createEmbeddedView(
        this.csTemplateOutlet, this.csTemplateOutletContext);
    }
  }


}
