import { AfterContentInit, Component, OnInit, TemplateRef } from '@angular/core';
import { PopoverContent, PopoverRef }                       from './popover-ref';
import { nicePopInAndOutAnimations }                        from '@cs/common';
import { Subject }                                          from 'rxjs';
import { AnimationEvent }                                   from '@angular/animations';
import { FlexibleConnectedPositionStrategy }                from '@angular/cdk/overlay';
import { getTransformOrigin }                               from '@cs/common';
import { PopoverSettings }                                  from './popover-settings.model';

@Component({
	selector:    'cs-popover',
	templateUrl: './popover.component.html',
	styleUrls:   ['./popover.component.scss'],
	animations:  [
		nicePopInAndOutAnimations.transformContainer,
		nicePopInAndOutAnimations.translateContainer
	]
})
export class PopoverComponent implements OnInit, AfterContentInit {

	/** Emits whenever an animation on the menu completes. */
	_animationDone = new Subject<AnimationEvent>();

	/** Current state of the panel animation. */
	_panelAnimationState: 'void' | 'enter' = 'void';

	/** Whether the menu is animating. */
	_isAnimating: boolean;

	renderMethod: 'template' | 'component' | 'text' = 'component';
	content: PopoverContent;
	context;
	_transformOrigin: { transformOrigin: string };
	settings: PopoverSettings;

	constructor(private popoverRef: PopoverRef) {
		(popoverRef.overlay.getConfig().positionStrategy as FlexibleConnectedPositionStrategy).positionChanges.subscribe(value => {
			console.log(value);
			this._transformOrigin = {transformOrigin: getTransformOrigin(value.connectionPair)};
		});
		this.settings = this.popoverRef.settings;
	}

	ngOnInit() {
		this.content = this.popoverRef.content;

		if (typeof this.content === 'string') {
			this.renderMethod = 'text';
		} else if (this.content instanceof TemplateRef) {
			this.renderMethod = 'template';
			this.context      = {
				close: this.popoverRef.close.bind(this.popoverRef)
			};
		}
	}

	/** Starts the enter animation. */
	_startAnimation() {
		// @breaking-change 8.0.0 Combine with _resetAnimation.
		this._panelAnimationState = 'enter';
	}

	/** Resets the panel animation to its initial state. */
	_resetAnimation() {
		// @breaking-change 8.0.0 Combine with _startAnimation.
		this._panelAnimationState = 'void';
	}

	_onAnimationStart(event: AnimationEvent) {
		this._isAnimating = true;

		// Scroll the content element to the top as soon as the animation starts. This is necessary,
		// because we move focus to the first item while it's still being animated, which can throw
		// the browser off when it determines the scroll position. Alternatively we can move focus
		// when the animation is done, however moving focus asynchronously will interrupt screen
		// readers which are in the process of reading out the menu already. We take the `element`
		// from the `event` since we can't use a `ViewChild` to access the pane.
		if (event.toState === 'enter') {
			event.element.scrollTop = 0;
		}
	}

	/** Callback that is invoked when the panel animation completes. */
	_onAnimationDone(event: AnimationEvent) {
		this._animationDone.next(event);
		this._isAnimating = false;
	}

	ngAfterContentInit(): void {
		this._startAnimation();
	}
}
