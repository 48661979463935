/**
 * A generic result object, for when simple results won't do.
 */
export class Result<TResultValue, TFailureReason = null> {

  private readonly _isSuccess: boolean;

  private readonly _value: TResultValue;

  private readonly _failureReason: TFailureReason;

  /**
   * Returns a <see cref="Result{TResultValue,TFailureReason}"/> of which <see cref="IsSuccess"/> is <c>true</c>,
   * and <see cref="Value"/> is set.
   * @param value The value represented by the success.
   */
//  static success<TResultValue>(value?): Result<boolean>;
  static success<TResultValue>(value?: TResultValue): Result<TResultValue> {
    return new Result<TResultValue>(value, true);
  }

  /**
   * Returns a <see cref="Result{TResultValue,TFailureReason}"/> of which <see cref="IsSuccess"/> is <c>false</c>,
   * and <see cref="FailureReason"/> is set.
   * @param failureReason The failure reason.
   */
  static failure<TFailureReason, TResultValue = boolean>(failureReason: TFailureReason): Result<TResultValue, TFailureReason> {
    return new Result<TResultValue, TFailureReason>(null, false, failureReason);
  }

  /**
   * Indicates whether the result indicates a success
   */
  get isSuccess(): boolean {
    return this._isSuccess;
  }

  /**
   * Gets the value, only meaningful when IsSuccess is true.  The value, a success result represents.
   */
  get value(): TResultValue {
    return this._value;
  }

  /**
   * Gets the value, only meaningful when IsSuccess is false. The failure reason.
   */
  get failureReason(): TFailureReason {
    return this._failureReason;
  }

  /**
   * Initializes a new instance of the class.
   * @param value The value.
   * @param failureReason The failure reason.
   * @param success if set to <c>true</c> the result indicates a success.
   */
  protected constructor(value: TResultValue, success: boolean, failureReason?: TFailureReason) {
    this._isSuccess     = success;
    this._value         = value;
    this._failureReason = failureReason;
  }

  static TResultValue<TResultValue, TFailureReason>(result: Result<TResultValue, TFailureReason>) {
    return result.value;
  }

}


