import { Injectable }               from '@angular/core';
import { Query }                    from '@datorama/akita';
import { WizardStore, WizardState } from './wizard.store';

@Injectable()
export class WizardQuery extends Query<WizardState> {

	constructor(protected store: WizardStore) {
		super(store);
	}

}
