import { LoggerUtil }                 from '@cs/core';
import { Injectable }                 from '@angular/core';
import { ICsTemplateHandleDirective } from '../directives/cs-template-handle.directive-interface';


@Injectable()
export class CsTemplateRegistry {

	private templates: { [type: string]: ICsTemplateHandleDirective } = {};

	constructor() {
	}


	hasTemplate(type: string) {
		return this.templates.hasOwnProperty(type);
	}

	register(type: string, widget: ICsTemplateHandleDirective) {
		this.templates[type] = widget;
	}

	getTemplate(type: string): ICsTemplateHandleDirective {
		if (this.hasTemplate(type)) {
			return this.templates[type];
		}
		LoggerUtil.error(`${type} is not found in template registry`);
	}
}
