export class GridDataCellMetaValues {
  /**
   * Value of the compare cell
   */
  comparisonValue: number = null;
  /**
   * The absolute offset between the host @link GridDataCell and compare @link GridDataCell.compareCell
   */
  absDifference: number   = null;

  /**
   * The relative offset between the host @link GridDataCell and compare @link GridDataCell.compareCell

   */
  relDifference: number = null;

  /**
   * The key name of the header column
   */
  columnkey: string = null;

  /**
   * Can be any value and is updated outside the scope of the grid
   */
  customValue: number | string = null;

  /**
   * Minimum threshold value
   */
  thresholdMin: number = null;

  /**
   * Maximum threshold value
   */
  thresholdMax: number = null;

  /**
   * Absolute difference of value outside of threshold range (negative lower then thresholdmin, positive gt thresholdMax)
   */
  thresholdValueOutOfRange: number = null;

  /**
   * Indicates whether an other cell with same keys exists. Is updated upon edit.
   * note: used number type, because boolean did not work well with the grid rules.
   */
  hasDuplicateCellKeys: number = 0;

  constructor(initial?: Partial<GridDataCellMetaValues>) {
    Object.assign(this, initial);
  }
}
