<div #selection
	 class="combobox combobox-container"
	 [ngClass]="{'open': isOpen, 'focus': hasFocus, 'below': isBelow, 'disabled': disabled, 'show': isOpen,
     'multiple':multiple, 'single':!multiple, 'small-combobox': isSmall}"
	 [attr.tabindex]="disabled ? null : 0"
	 [attr.role]="'listbox'"
	 [attr.aria-activedescendant]="optionList.hasSelected()"
	 (click)="onContainerClick($event)"
	 (focus)="onContainerFocus()"
	 (keydown)="onContainerKeydown($event)"
	 (window:click)="onWindowClick()"
	 (window:resize)="onWindowResize()">

	<div class="main">
		<!-- single select: item text. pass-through clicks -->
		<div class="item single" *ngIf="!multiple && optionList.hasSelected()">
			<div class="text-no-whitespace text-truncate flex-grow-0 flex-shrink-1 full-width">
				{{optionList.selection[0].label}}
			</div>
			<i class="combobox-icon mdi mdi-chevron-down"></i>
		</div>

		<!-- multi select: removable badges -->
		<div class="badges-list" *ngIf="multiple">
			<div class="badge badge-secondary" *ngFor="let option of optionList.selection">
				{{option.label}}
				<span *ngIf="!disabled"
					  (click)="onDeselectOptionClick(option, $event)">
                <i class="mdi mdi-close"></i>
        </span>
			</div>
		</div>

		<div class="placeholder-text" *ngIf="!optionList.hasSelected()">
			<div class="text-no-whitespace text-truncate flex-grow-0 flex-shrink-1 full-width">
				{{placeholder}}
			</div>
			<i class="combobox-icon mdi mdi-chevron-down"></i>
		</div>


	</div>

	<cs-combobox-scroller
			*ngIf="isOpen"
			#dropdown
			class="dropdown-menu"
			[ngClass]="{'dropdown-search': filter}"
			[multiple]="multiple"
			[optionList]="optionList"
			[notFoundMsg]="notFoundMsg"
			[filterEnabled]="showFilter"
			[width]="width"
			[top]="top"
			[left]="left"
			(close)="onDropdownClose($event)"
			(optionClicked)="onDropdownOptionClicked($event)"
			(filterClick)="onFilterClick()"
			(filterInput)="onFilterInput($event)"
			(filterKeydown)="onFilterKeydown($event)">

	</cs-combobox-scroller>

</div>
