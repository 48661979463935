<ng-container [ngSwitch]="column.type">
	<ng-container *ngSwitchCase="dataEntryStateColumnType.Label">
		<div class="data-entry-state-row__column-container data-entry-state-row__column-container--label  f-body-s "
			 [class.cs-pointer]="hasBadges" (click)="toggleExpansionRow()">
			<p class="data-entry-state-row__column-container--text">{{column.value}}
			<p>
				<i *ngIf="hasBadges" class="mr-2 ml-2 mdi mdi-chevron-down "
				></i>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase="dataEntryStateColumnType.Text">
		<div class="data-entry-state-row__column-container  f-body-s font-weight-400">
			<p class="data-entry-state-row__column-container--text">{{column.value}}</p>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase="dataEntryStateColumnType.Status">
		<div class="data-entry-state-row__column-container"
			 (click)="stateEntryClicked($event,column)"
			 [matTooltip]="column?.value?.label"
			 matTooltipPosition="above">
			<i class="{{column?.value?.icon | mdiIcon:'md'}}"
			   [ngStyle]="{	 color: column?.value?.color}"></i>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase="dataEntryStateColumnType.StatusWithLabel">
		<div class="data-entry-state-row__column-container"
			 [class.data-entry-state-row__column-container--hasTooltip]="column?.value?.label"
			 (click)="stateEntryClicked($event,column)"
			 [matTooltip]="column?.value?.label"
			 matTooltipPosition="above">
			<i class="{{column?.value?.icon | mdiIcon:'md'}}"
			   [ngStyle]="{	 color: column?.value?.color}"></i>
			<label class="data-entry-state-row__status-label f-body-s font-weight-400">{{column?.value?.text}}</label>
		</div>
	</ng-container>
	<ng-container *ngSwitchCase="dataEntryStateColumnType.DataEntryState">
		<div class="data-entry-state-row__indicator" (click)="stateEntryClicked($event,column)">
			<cs-data-entry-state-indicator
				[bgColor]="column?.value?.bgColor"
				[borderColor]="column?.value?.borderColor"
				[textColor]="column?.value?.textColor"
				[tooltip]="column?.value?.label"
				[text]="column?.value?.text"
			>
			</cs-data-entry-state-indicator>
		</div>
	</ng-container>

</ng-container>

