import { TableConditionalAnnotation }   from './table-conditional-annotation.model';
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';


export class TableHeaderAnnotation<T> {
	/**
	 * Identification of the Table Header
	 */
	id: keyof T;

	/**
	 * Id of the Header-row the column should be added to
	 */
	idHeaderRow?: string;
	/**
	 * Name to show in the UI
	 */
	label?: string;
	/**
	 * Include the in rendering process
	 */
	include?: boolean;
	/**
	 * Use this format string for the cells in the column
	 */
	cellFormat?: string;

	/**
	 * If the value from the server needs to be converted, provide the conversion method name here
	 */
	conversion?: string;
	/**
	 * Indicates that the column is readonly, could be overridden by the cell
	 */
	readOnly: boolean;
	/**
	 * Set the template for the cells in the column
	 */
	cellTemplate: string;
	/**
	 * list of classes
	 */
	classList: string;

	conditionalFormatting?: TableConditionalAnnotation[] = [];
	/**
	 * Let the column filter on all the values
	 */
	allowAsFilter: boolean;
	/**
	 * Allow the server to set the width directly
	 */
	width: string;
	/**
	 * Set the template for the cells in the column when value is not found in the lookup
	 */
	noLookupFallbackTemplate: string;

	constructor(init: Partial<TableHeaderAnnotation<T>>) {
		this.id                       = getPropertyOf(init, 'id', null);
		this.label                    = getPropertyOf(init, 'label', null);
		this.idHeaderRow              = getPropertyOf(init, 'idHeaderRow', null);
		this.include                  = getPropertyOf(init, 'include', true);
		this.cellFormat               = getPropertyOf(init, 'cellFormat', null);
		this.conversion               = getPropertyOf(init, 'conversion', null);
		this.readOnly                 = getPropertyOf(init, 'readOnly', true);
		this.cellTemplate             = getPropertyOf(init, 'cellTemplate', null);
		this.classList                = getPropertyOf(init, 'classList', '');
		this.allowAsFilter            = getPropertyOf(init, 'allowAsFilter', true);
		this.width                    = getPropertyOf(init, 'width', null);
		this.noLookupFallbackTemplate = getPropertyOf(init, 'noLookupFallbackTemplate', null);
		this.conditionalFormatting    = hasPropertyOf(init, 'conditionalFormatting') ?
			init.conditionalFormatting.map(condition => new TableConditionalAnnotation(condition)) : [];
	}
}
