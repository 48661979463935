import { DataDescribed } from '@cs/core';

export class GaugeData {
	/**
	 * The total number of units in the gauge
	 */
	total: number;
	/**
	 * The current position of the gauge
	 */
	value: number;
	/**
	 * The number that represents the start of the gauge
	 */
	start?: number;
	/**
	 * the label above the gauge
	 */
	label?: string;
	/**
	 * Label used as a postfix for the total value in the gauge
	 */
	valueLabel?: string;

	/**
	 * The threshold of the gauge
	 */
	threshold?: number;
	/**
	 * the color of the gauge
	 */
	color?: string;
}

export enum GaugeType {
	SINGLE_VALUE = 'single_value',
	STATUS_VALUE = 'status_value'
}

export class GaugeLayoutAnnotation {
	gaugeType?: GaugeType;
	format?: string;
}

export class GaugeDataDescribed extends DataDescribed<GaugeData, GaugeLayoutAnnotation> {

}
