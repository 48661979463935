import { Component, OnInit, Type }     from '@angular/core';
import { DashboardDataEntryTable }     from '../../models/dashboard-data-entry-table';
import { DashboardDataEntryTableCell } from '../../models/dashboard-data-entry-table-cell';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { Router }                      from '@angular/router';
import { DashboardCombiListComponent } from '../dashboard-list/dashboard-list.component';
import { ArrayUtils }                  from '@cs/core';

@Component({
			   selector:    'cs-dashboard-table',
			   templateUrl: './dashboard-table.component.html'
		   })
export class DashboardTableComponent extends DashboardPanelComponentBase<DashboardDataEntryTable> implements OnInit {


	name: string;

	listComponent: Type<DashboardCombiListComponent> = DashboardCombiListComponent;

	get marginTop(): string {
		const length = this.data.columns
						   .map(c => c.headerLabel.length)
						   .reduce((previousValue, currentValue) =>
									   previousValue > currentValue
									   ? previousValue
									   : currentValue);
		return `${(length * 7) - 50}px`;
	}

	constructor(private router: Router) {
		super();
	}

	ngOnInit() {
	}

	navigateTo(cell: DashboardDataEntryTableCell) {

		console.log(cell);
		this.router.navigateByUrl(cell.href);
	}

	update(data: DashboardDataEntryTable): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
	}

	dataChanged(value: DashboardDataEntryTable) {

	}

	private _marginTop: string;


}
