export type ParseAbleTypes = 'string' | 'date' | 'number' | 'boolean' | 'datetime';

export function parseTypes(type: string): ParseAbleTypes {
  let result: ParseAbleTypes = 'string';
  switch (type.toLowerCase()) {
    case 'string':
      result = 'string';
      break;
    case 'boolean':
      result = 'boolean';
      break;
    case 'datetime':
      result = 'date';
      break;
    case 'int':
    case 'int16':
    case 'int32':
    case 'int64':
    case 'decimal':
    case 'float':
    case 'number':
    case 'single':
      result = 'number';
      break;

  }
  return result;
}
