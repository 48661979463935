import { Component, Inject, OnInit }   from '@angular/core';
import { DashboardPanelComponentBase } from '@cs/components/shared';

import { DashboardTasks, DashboardTasksItem, DashboardTasksItemStatus } from './dashboard-task.models';
import { TranslateService }                                             from '@ngx-translate/core';
import { ArrayUtils }                                                   from '@cs/core';
import { CsToastManagerService }                                        from '@cs/components/toast-manager';
import { ActivatedRoute, Router }                                       from '@angular/router';
import { AppNavigationService }                                         from '@cs/common';
import { FilterCompareBarQuery }                                        from '@cs/components/filter-and-compare-bar';

@Component({
			   selector:    'cs-dashboard-tasks',
			   templateUrl: './dashboard-tasks.component.html'
		   })
export class DashboardTasksComponent extends DashboardPanelComponentBase<DashboardTasks> implements OnInit {


	name: string;

	get data(): DashboardTasks {
		return this._data;
	}

	set data(value: DashboardTasks) {
		value      = this.checkIfEmpty(value);
		this._data = new DashboardTasks(value);
	}

	constructor(@Inject(CsToastManagerService) private readonly toasts: CsToastManagerService,
				private readonly i8n: TranslateService,
				private readonly router: Router,
				private readonly activatedRoute: ActivatedRoute,
				private readonly filterAndCompareBarQuery: FilterCompareBarQuery,
				private readonly appNavigationService: AppNavigationService) {
		super();
	}

	ngOnInit() {
	}

	update(data: DashboardTasks): void {
		if (this._data && ArrayUtils.isEqual(this._data, data))
			return;

		this.data = data;
	}

	navigateToLink(item: DashboardTasksItem) {
		if (!item.link)
			return;

		const newApiParams       = item.payload.navBarParams['DeepLink'];
		const {mainbarApiParams} = this.filterAndCompareBarQuery.getValue();
		const selection          = Object.assign({}, mainbarApiParams, newApiParams);
		const patchedParams      = this.appNavigationService.preProcessQueryParams(selection);

		this.router.navigate([item.link], {
			queryParams:         patchedParams,
			relativeTo:          this.activatedRoute,
			queryParamsHandling: ''
		});

	}

	dataChanged(value: DashboardTasks) {

	}

	private checkIfEmpty(value: DashboardTasks) {

		if (value.groups && value.groups.length === 0) {
			value = new DashboardTasks({
										   groups: [
											   {
												   label: this.i8n.instant('NO_TASKS'),
												   name:  'empty',
												   items: [new DashboardTasksItem({
																					  label:  this.i8n.instant('NO_TASKS_FOUND'),
																					  status: DashboardTasksItemStatus.DONE
																				  })]
											   }
										   ]
									   });
		}
		// filter out the tasksgroups that have no items
		value.groups = value.groups.filter(value1 => value1.items.length !== 0);
		return value;
	}
}
