import { HttpResponse }                         from '@angular/common/http';
import { Injector }                             from '@angular/core';
import {
	MatDialog
}                                               from '@angular/material/dialog';
import { ActivatedRoute, Router }               from '@angular/router';
import { AppMessageHubService, AppMessageType } from '@cs/common';
import {
	FilterBarResultParams,
	FilterCompareBarQuery,
	FilterCompareBarService
}                                               from '@cs/components/filter-and-compare-bar';
import {
	CsToastManagerService
}                                               from '@cs/components/toast-manager';
import {
	SelectionTargetEnum,
	SelectionTargetResult
}                                               from '@cs/core/generate';
import {
	CsHttpRequestOptions,
	GenericHttpClientService,
	GenericHttpRequest,
	GenericHttpRequestOption,
	GenericHttpRequestOptionAction,
	HTTP_METHOD
}                                               from '@cs/core/http';
import {
	gv, isEmptyObject, LoggerUtil, PlaceholderRegexPathUrlVariable,
	replacePlaceholders, restoreFlattenObject
}                                               from '@cs/core/utils';
import {
	AppService,
	DialogBasicComponent,
	DialogType
}                                               from '@cs/performance-manager/shared';
import {
	getUIPlaceholder, isUIPlaceholder
}                                               from '@cs/common/culture';
import {
	TranslateService
}                                               from '@ngx-translate/core';
import {
	Observable
}                                               from 'rxjs/internal/Observable';
import {
	DashboardConfigService
}                                               from './dashboard-config.service';
import {
	DashboardPopupComponent,
	DashboardPopupData
}                                               from './dashboard-popup/dashboard-popup.component';

export type PostAction =
	{
		data: SelectionTargetResult
	}
	& GenericHttpRequestOption;

export function getPanelSettings(route: ActivatedRoute) {
	const params   = route.snapshot.queryParams || {};
	const restored = restoreFlattenObject(params);

	if (restored.hasOwnProperty('panelSettings')) {
		return {panelSettings: restored.panelSettings};
	}
	return {};
}

export async function onDashboardEntryClick($event: SelectionTargetResult, injector: Injector,
											config: DashboardConfigService, dashboardName: string): Promise<PostAction> {
	return new Promise(async (resolve, reject) => {
		if ($event.selectionAction == null) {
			LoggerUtil.error('No action provided', true);
			return;
		}
		const dialog                  = injector.get(MatDialog);
		const filterCompareBarService = injector.get(FilterCompareBarService);
		const router                  = injector.get(Router);
		const navbar                  = injector.get(FilterCompareBarQuery);
		const appService              = injector.get(AppService);
		const toastManagerService     = injector.get(CsToastManagerService);
		const i8n                     = injector.get(TranslateService);

		const activatedRoute = injector.get(ActivatedRoute);

		const selectionAction = $event.selectionAction.toLowerCase();

		// CHECK IF WE NEED TO CONFIRM
		const metaData = $event.selectionMeta as GenericHttpRequest;
		// Prepare the post action if any required
		const postData = gv(() => metaData.postAction, {action: GenericHttpRequestOptionAction.NONE}) as PostAction;
		postData.data  = $event;

		if (metaData && metaData.preAction && metaData.preAction.action.toLowerCase() === GenericHttpRequestOptionAction.CONFIRM.toLowerCase()) {
			const result = await getUserConfirmation(metaData.preAction.message, DialogType.none, dialog, i8n);

			if (!result) {
				resolve(postData);
				return;
			}
		}

		const navObject                                     = {...navbar.getValue().mainbarResultParams, ...$event.selectionObject, ...$event.selectionMeta};
		const patchedSelectionObject: SelectionTargetResult = {
			...$event,
			selectionObject:  navObject as unknown as {
				[key: string]: string
			},
			selectionContext: navbar.getValue().mainbarResultParams
		};

		if (selectionAction === SelectionTargetEnum.CurrentWindow.toLowerCase()
			||
			// Check if the navigation targets the current page. if that's the case update the filter without navigating with the router
			(selectionAction === SelectionTargetEnum.Navigate.toLowerCase()
				&& router.isActive($event.selectionRoute, false))
		) {
			// ignore click event when empty selection object
			if (!isEmptyObject($event.selectionObject)) {
				filterCompareBarService.triggerNavigation($event.selectionObject);
				resolve(postData);
			}
		} else if (selectionAction === SelectionTargetEnum.ModalWindow.toLowerCase()) {
			const navObject = {
				...navbar.getValue().mainbarResultParams, ...$event.selectionObject,
				...$event.selectionMeta, ...getPanelSettings(activatedRoute)
			};
			const matDialog = dialog.open<DashboardPopupComponent, DashboardPopupData>(DashboardPopupComponent, {
				data:              {
					filterData:       navObject as unknown as FilterBarResultParams,
					panelName:        $event.panelName,
					parentInstanceId: $event.dashboardInstanceId
				},
				minHeight:         '80vh',
				minWidth:          '80vw',
				panelClass:        'dashboard-modal-window',
				disableClose:      false,
				closeOnNavigation: true
			});
			matDialog.afterClosed()
					 .subscribe(value => resolve(postData));
		} else if (selectionAction === SelectionTargetEnum.Navigate.toLowerCase()) {


			config.navigateTo(patchedSelectionObject, activatedRoute)
				  .then(value => {
					  // setTimeout(() => {
					  // 	appService.refreshNavigationBar();
					  // }, 10);
					  resolve(postData);
				  });

		} else if (selectionAction === SelectionTargetEnum.HttpRequest.toLowerCase()) {

			const url = replacePlaceholders($event.selectionObject, $event.selectionRoute, PlaceholderRegexPathUrlVariable);
			config.triggerHttpRequest(metaData.method, url, patchedSelectionObject, metaData.queryParams)
				  .subscribe(value => {
					  //appMessageHub.publishMessage(AppMessageType.REFRESH_DATA, $event.dashboardInstanceId);
					  resolve(postData);
				  });
		} else if (selectionAction === SelectionTargetEnum.PanelAction.toLowerCase()) {

			config.metaButtonClicked($event.panelName, dashboardName, patchedSelectionObject)
				  .subscribe(value => {
					  //appMessageHub.publishMessage(AppMessageType.REFRESH_DATA, $event.dashboardInstanceId);
					  // pass the response to the panel
					  resolve(postData.action === GenericHttpRequestOptionAction.NONE
							  ? value
							  : postData);
				  });
		} else {
			toastManagerService.show({
										 type:    'info', title: i8n.instant('IPA_ACTION_NOT_IMPLEMENTED'),
										 content: i8n.instant('IPA_ACTION_NOT_IMPLEMENTED_CONTENT', {action: $event.selectionAction})
									 });

		}
	});
}

export function handlePostAction($event: PostAction, injector: Injector): void {
	const appMessageHub       = injector.get(AppMessageHubService);
	const toastManagerService = injector.get(CsToastManagerService);
	const i8n                 = injector.get(TranslateService);

	switch ($event.action) {
		case GenericHttpRequestOptionAction.REFRESH:
			appMessageHub.publishMessage(AppMessageType.REFRESH_DATA, $event.data.dashboardInstanceId);
			toastManagerService.show({
										 type:    'info',
										 content: isUIPlaceholder($event.message)
												  ? getUIPlaceholder($event.message, i8n)
												  : $event.message
									 });
	}
}

function getUserConfirmation(msg: string, type: DialogType = DialogType.none, dialog: MatDialog, i8n: TranslateService): Promise<boolean> {
	return new Promise<boolean>((resolve) => {
		const confirmDialogRef = dialog.open(DialogBasicComponent, {
			data: {
				dialogTitle: i8n.instant('CONFIRM'),
				type:        type,
				message:     isUIPlaceholder(msg)
							 ? getUIPlaceholder(msg, i8n)
							 : msg,
				showNo:      false
			}

		});

		confirmDialogRef.afterClosed()
						.subscribe(confirmed => {
							resolve(!!confirmed);
						});
	});
}

export function triggerHttpRequestHandler<T>(genericHttpService: GenericHttpClientService, method: HTTP_METHOD, endpoint: string,
											 queryParams?: {
												 [p: string]: any
											 }, body?: any,
											 csHttpRequestOptions?: CsHttpRequestOptions): Observable<HttpResponse<T>> {
	switch (method.toUpperCase()) {
		case HTTP_METHOD.DELETE:
			return genericHttpService.delete(endpoint, queryParams, csHttpRequestOptions);
		case HTTP_METHOD.GET:
			return genericHttpService.get(endpoint, queryParams, csHttpRequestOptions);
		case HTTP_METHOD.POST:
			return genericHttpService.post(endpoint, body, queryParams, csHttpRequestOptions);
		case HTTP_METHOD.PUT:
			return genericHttpService.put(endpoint, body, queryParams, csHttpRequestOptions);
		default:
			throw new Error(`${method} is not found as an HTTP option`);
	}

}

