<div [@loadingPanelState]="isLoadingDataGrids">
  <div class="litigation mt-4">
    <button (click)="onClickNew()" type="button" class="btn btn-primary btn-sm right" *ngIf="resources.AllowEdit">{{'ADD_NEW' | translate}}</button>

    <div class="litigation-list">
      <cs-table [rows]="list" sortColumn="id" sortDir="desc" (rowClick)="onRowClick($event)" [pageSizeOptions]="[30]" [selectedPageSize]="30">
        <ng-container *ngFor="let column of columns">
            <cs-column [field]="column.field" [title]="column.title" [class]="column.class" [headerClass]="column.headerClass"></cs-column>
        </ng-container>
      </cs-table>
    </div>
  </div>
</div>
