<div class="modal-header">
  <h2 mat-dialog-title class="modal-title">{{currentThumb.description}}</h2>
  <span class="close-button cs-pointer"
        (click)="onCloseClick()"
  ><i class="mdi mdi-close"></i>
  </span>
</div>
<div mat-dialog-content id="container">
  <div class="overlay-left"></div>
  <div class="overlay-right"></div>
  <div class="image-cropper">
    <ng-container *ngFor="let thumb of thumbnails; let i = index">
      <div class="img-container"
           [@move]="state"
           (@move.done)="onFinish($event)"
           [ngStyle]="{'background-image': 'url(' + getFullUrl(thumb.url) + ')'}">

      </div>
    </ng-container>
  </div>
  <div>
    <a id="slider-buttons-left" (click)="moveLeft()">&#10094;</a>
    <a id="slider-buttons-right" (click)="moveRight()">&#10095;</a>
  </div>
</div>
