import { WidgetRegistry }            from '../widget-registry.util';
import { StringWidgetComponent }     from './string-widget.component';
import { DatePickerWidgetComponent } from './date-picker-widget.component';
import { SelectWidgetComponent }     from './select-widget.component';
import { LabelWidgetComponent }      from './label-widget.component';
import { FileWidgetComponent }       from './file-widget.component';
import { CheckboxWidgetComponent }   from './checkbox-widget.component';
import { Injectable }                from '@angular/core';
import { ButtonWidgetComponent }     from './button-widget.component';
import { InputMaskWidgetComponent }  from './input-mask-widget.component';
import { TextWidgetComponent }       from './text-widget.component';


@Injectable()
export class DefaultWidgetRegistry extends WidgetRegistry {
	constructor() {
		super();

		this.register('date-picker', DatePickerWidgetComponent);
		this.register('DateTime', DatePickerWidgetComponent);
		this.register('Boolean', CheckboxWidgetComponent);
		this.register('Select', SelectWidgetComponent);
		this.register('Label', LabelWidgetComponent);
		this.register('Byte[]', FileWidgetComponent);
		this.register('Button', ButtonWidgetComponent);
		this.register('input-mask', InputMaskWidgetComponent);
		this.register('Text', TextWidgetComponent);
		

		this.setDefaultWidget(StringWidgetComponent);
	}
}
