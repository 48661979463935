import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { getPropertyOf, hasPropertyOf, isNullOrUndefined }           from '@cs/core';
import { PdfViewerComponent } 																			 from '../pdf-viewer/pdf-viewer.component';
import { PDFDocumentProxy } 																			 	 from '../pdf-viewer/typings';
import { PDFData }                                                   from './pdf-data.model';

/**
 * Avatar component.
 */
@Component({
	selector:    'cs-pdf-viewer',
	templateUrl: './pdf-wrapper.component.html',
	styleUrls:   ['./pdf-wrapper.component.scss']
})
export class CsPdfViewerComponent implements OnInit {

	@Input() src: string;
	@Input() data: PDFData;

	@Output() afterLoadComplete = new EventEmitter();
	@Output() pageRendered      = new EventEmitter();
	@Output() errorTriggered    = new EventEmitter();


	@ViewChild('pdf', {static: true}) pdf: PdfViewerComponent;

	private pdfProxy: PDFDocumentProxy;

	pdfLoaded: boolean = false;

	error: boolean = false;

	onLoaded(pdf: PDFDocumentProxy) {
		this.pdfProxy  = pdf;
		this.pdfLoaded = true;
	}

	onError(error: any) {
		this.error = true;
		this.errorTriggered.next();
	}

	ngOnInit() {
		this.pdf.fitToPage = true;

		if (!isNullOrUndefined(this.data)) {
			if (hasPropertyOf(this.data, 'autoresize')) {
				this.pdf.autoresize = getPropertyOf(this.data, 'autoresize');
			}
			if (hasPropertyOf(this.data, 'fitToPage')) {
				this.pdf.fitToPage = getPropertyOf(this.data, 'fitToPage');
			}
			if (hasPropertyOf(this.data, 'originalSize')) {
				this.pdf.originalSize = getPropertyOf(this.data, 'originalSize');
			}
			if (hasPropertyOf(this.data, 'page')) {
				this.pdf.page = getPropertyOf(this.data, 'page');
			}
			if (hasPropertyOf(this.data, 'renderText')) {
				this.pdf.renderText = getPropertyOf(this.data, 'renderText');
			}
			if (hasPropertyOf(this.data, 'rotation')) {
				this.pdf.rotation = getPropertyOf(this.data, 'rotation');
			}
			if (hasPropertyOf(this.data, 'showAll')) {
				this.pdf.showAll = getPropertyOf(this.data, 'showAll');
			}
			if (hasPropertyOf(this.data, 'showBorders')) {
				this.pdf.showBorders = getPropertyOf(this.data, 'showBorders');
			}
			if (hasPropertyOf(this.data, 'zoom')) {
				this.pdf.zoom = getPropertyOf(this.data, 'zoom');
			}
			if (hasPropertyOf(this.data, 'zoomScale')) {
				this.pdf.zoomScale = getPropertyOf(this.data, 'zoomScale');
			}
		}
	}

	download() {
		this.pdfProxy.getData().then((u8) => {
			let blob = new Blob([u8.buffer], {
				type: 'application/pdf'
			});
			if (window.navigator && (/MSIE \d|Trident.*rv:/.test(window.navigator.userAgent))) {
				// IE11 and Edge
				(window.navigator as any).msSaveOrOpenBlob(blob, this.src.replace(/^.*[\\\/]/, ''));
			} else {
				// Chrome, Safari, Firefox, Opera
				let url = URL.createObjectURL(blob);
				this.openLink(url);
				// Remove the link when done
				setTimeout(function () {
					window.URL.revokeObjectURL(url);
				}, 5000);
			}
		});
	}

	private openLink(url: string) {
		let a = document.createElement('a');
		// Firefox requires the link to be in the body
		document.body.appendChild(a);
		a.style.display = 'none';
		a.href          = url;
		a.download      = this.src.replace(/^.*[\\\/]/, '');
		a.click();
		// Remove the link when done
		document.body.removeChild(a);
	}


}
