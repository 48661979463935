import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { PopoverService }                                         from './popover.service';
import { PopoverContent, PopoverRef }                             from './popover-ref';
import { fromEvent, merge }                                       from 'rxjs';
import { UntilDestroy, untilDestroyed }                           from '@ngneat/until-destroy';
import { debounceTime, filter, map, repeat, takeUntil, tap }      from 'rxjs/operators';
import { isNullOrUndefined }                                      from '@cs/core';
import { LoggerUtil }                                             from '@cs/core';
import { PopoverPosition }                                        from './popover-position.type';
import { PopoverSettings }                                        from './popover-settings.model';


@UntilDestroy()
@Directive({
	selector: '[csPopover]'
})
export class CsPopoverDirective<T> implements AfterViewInit, OnDestroy {

	@Input() content: PopoverContent;

	@Input() openOn: 'mouseover' | 'click' = 'click';

	@Input() csPopoverData: T;

	@Input() preferredPosition: PopoverPosition;

	@Input() settings = new PopoverSettings();

	@Input() csPopoverDisabled = false;

	private popoverRef: PopoverRef;
	private hasMouseOverContent = false;
	private hasMouseOverHost    = false;

	constructor(private popper: PopoverService, private elementRef: ElementRef) {
	}

	show(content: PopoverContent, origin) {
		if (this.csPopoverDisabled) {
			LoggerUtil.debug('popover is disabled');
			return;
		}

		this.popoverRef     = this.popper.open<T>({
			content,
			origin,
			data:     this.csPopoverData,
			position: this.preferredPosition,
			settings: this.settings
		});
		const hasMouseOver$ = merge(
			fromEvent(this.popoverRef.overlay.overlayElement.firstChild.firstChild, 'mouseenter'),
			fromEvent(this.popoverRef.overlay.overlayElement.firstChild.firstChild, 'focusin'),
			fromEvent(this.popoverRef.overlay.overlayElement.firstChild.firstChild, 'mousemove')
		);

		hasMouseOver$
			.pipe(
				untilDestroyed(this)
			).subscribe(value => this.hasMouseOverContent = true);

		const hasMouseLeft$ = merge(
			fromEvent(this.popoverRef.overlay.overlayElement.firstChild.firstChild, 'mouseleave'),
			fromEvent(this.popoverRef.overlay.overlayElement.firstChild.firstChild, 'focusout')
		);

		hasMouseLeft$
			.pipe(
				tap(x => this.hasMouseOverContent = false),
				untilDestroyed(this),
				debounceTime(400)
			).subscribe(value => {
			this.close();
		});
	}

	ngAfterViewInit(): void {

		if (this.openOn === 'click')
			this.setupClickHandlers();
		else if (this.openOn === 'mouseover') {
			this.setupMouseOverHandlers();
		}


	}

	ngOnDestroy(): void {
	}

	private setupClickHandlers() {
		const showEvents$ = fromEvent(this.elementRef.nativeElement, this.openOn);

		showEvents$
			.pipe(
				untilDestroyed(this)
			)
			.subscribe(value => {
				this.show(this.content, this.elementRef.nativeElement);
			});
	}

	private setupMouseOverHandlers() {

		const showEvents$ = merge(
			fromEvent(this.elementRef.nativeElement, 'mouseenter'),
			fromEvent(this.elementRef.nativeElement, 'focusin'),
			fromEvent(this.elementRef.nativeElement, 'mousemove')
		);

		const hideEvents$ = merge(
			fromEvent(this.elementRef.nativeElement, 'mouseleave'),
			fromEvent(this.elementRef.nativeElement, 'focusout')
		);

		showEvents$
			.pipe(
				tap(() => this.hasMouseOverHost = true),
				tap((x: MouseEvent) => {
					// stop the bubbling of the event
					x.stopPropagation();
				}),
				untilDestroyed(this),
				debounceTime(300),
				takeUntil(hideEvents$),
				repeat()
			)
			.subscribe(value => {
				if (isNullOrUndefined(this.popoverRef)) {
					this.show(this.content, this.elementRef.nativeElement);
				}
			});

		hideEvents$
			.pipe(
				tap(x => this.hasMouseOverHost = false),
				untilDestroyed(this),
				debounceTime(200),
				tap(x => console.log('hide Mouse', x))
			).subscribe(value => {
			this.close();
		});
	}

	private close() {
		if (isNullOrUndefined(this.popoverRef))
			return;

		if (!this.hasMouseOverContent && !this.hasMouseOverHost) {
			this.popoverRef.close();
			this.popoverRef = null;
		}
	}
}
