import { BaseInput } from './base-input';

export class EnumInput extends BaseInput {
  public options;
  public constructor(data: any) {
    super(data);
    this.options = this.node.getConfig('enumOptions');
  }

  public onChange() {
    super.onChange();
    this.data.intValue = this.options.indexOf(this.value);
  }
}
