<div class="dashboard_main" [@isCollapsed]="isCollapsed">
	<!-- Show alerts -->
	<div *ngFor="let alert of dashboardGrid?.alerts"
		 class="alert alert-{{alert.type}}" role="alert">
		<h4 *ngIf="alert.title" class="alert-heading">{{alert.title}}</h4>
		<p class="mb-0" style="color: inherit">{{alert.message}}</p>
	</div>
	<!-- Generate the dashboard grid -->
	<ng-container *ngFor="let row of dashboardGrid?.grid">
		<!-- Create a row foreach array in data.grid -->
		<div class="cs-row flex-grow-1"
							[class.expanded]="row.isExpanded"
		>
			<!-- Create a bootstrap column foreach array entry in row  -->
			<ng-container *ngFor="let col of row.columns;trackBy:trackById">
				<div *ngIf="col.colspan"
					 class="cs-col-{{col.colspan}}">
					<cs-dashboard-panel-renderer
							class="dashboard-panel-renderer"
							[panels]="col.panels"
							[renderOrientation]="col.renderOrientation">

					</cs-dashboard-panel-renderer>
				</div>
			</ng-container>
		</div>
	</ng-container>
</div>
