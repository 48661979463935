import { NgModule }                  from '@angular/core';
import { Routes, RouterModule }      from '@angular/router';
import { ApprovalComponent }         from './approval.component';
import { ApprovalOverviewComponent } from './approval-overview/approval-overview.component';


const routes: Routes = [
	{
		path: '', component: ApprovalOverviewComponent
	},
	{
		path: 'list', component: ApprovalComponent
	}
];

@NgModule({
			  imports: [RouterModule.forChild(routes)],
			  exports: [RouterModule]
		  })
export class ApprovalRoutingModule {
}
