import {
	AfterViewInit,
	ChangeDetectionStrategy, ChangeDetectorRef, Component,
	EventEmitter, Input, OnChanges, OnInit, Output, QueryList, ViewChildren
}                                             from '@angular/core';
import { WizardStepComponent }                from '../wizard-step/wizard-step.component';
import { ComponentChanges, gv, whenChanging } from '@cs/core';
import { Step, StepState }                    from '../../models/step';

@Component({
			   selector:        'cs-wizard-stepper',
			   templateUrl:     './wizard-stepper.component.html',
			   styleUrls:       ['./wizard-stepper.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   exportAs:        'wizardStepper'
		   })
export class WizardStepperComponent implements OnInit,
											   AfterViewInit,
											   OnChanges {

	@Input() steps: Array<Step>;
	@Output() stepChanged: EventEmitter<Step> = new EventEmitter<Step>();
	@ViewChildren(WizardStepComponent) stepComponents: QueryList<WizardStepComponent>;


	get isFirstStep(): boolean {

		return gv(() => this.activeStep.index) === 1;
	}

	get isLastStep(): boolean {

		return gv(() => this.activeStep.index) === this.steps.length;
	}

	constructor(private readonly changeRef: ChangeDetectorRef) {
	}

	ngAfterViewInit(): void {

	}

	ngOnInit(): void {
	}

	ngOnChanges(changes: ComponentChanges<WizardStepperComponent>): void {

		whenChanging(changes.steps, true)
			.execute(value => {
				this.steps = value.currentValue
								  .map((step, index) => {
									  step.index = index + 1;
									  return step;
								  });

				this.setFirstStepActive();
			});

	}

	setStepActiveSidePanel(step: Step) {
		if (step.state !== 'isDisabled')
			this.setStepActive(step);
	}

	setStepActive(step: Step) {


		this.activeStep       = this.steps.find((value) => step.name === value.name);
		this.activeStep.state = this.activeStep.state === 'isDisabled'
								? 'isActive'
								: this.activeStep.state;

		// Find the current step index
		const currentIndex = this.steps.findIndex((value) => this.activeStep.name === value.name);

		// Loop through the steps array
		for (let i = 0; i < this.steps.length; i++) {
			const foundStep = this.steps[i];
			// Set the previous step state to 'isChecked' if it has been visited
			if (i < currentIndex) {
				foundStep.state = 'isChecked' as StepState;
			}
		}

		this.stepChanged.emit(this.activeStep);
	}

	navigateBack() {
		this.setStepActive(this.steps[this.previousStepIndex]);
	}

	navigateNext() {
		this.setStepActive(this.steps[this.nextStepIndex]);
	}

	identify(index: number, item: Step) {
		return item.name;
	}

	private get currentStepIndex(): number {
		return this.steps.findIndex(value => value.name === this.activeStep.name);
	}

	private get nextStepIndex(): number {
		return this.currentStepIndex + 1 > this.steps.length
			   ? this.steps.length
			   : this.currentStepIndex + 1;
	}

	private get previousStepIndex(): number {
		return this.currentStepIndex - 1 < 0
			   ? 0
			   : this.currentStepIndex - 1;
	}

	private activeStep: Step;

	private setFirstStepActive() {
		if (this.steps && this.steps.length > 0)
			this.setStepActive(this.steps[0]);
	}
}
