import { CsHttpRequestOptions, DataDescribed, Result } from '@cs/core';
import { Observable }                                  from 'rxjs';

export interface IFormGeneratorLookupService {
	getLookupWithFilter(lookupName: string, formDetails: any, contextObject: any, csHttpRequestOptions?: CsHttpRequestOptions);

	dependantFieldHasChanged(changedFieldId: string, formDetails: any, contextObject: any, csHttpRequestOptions?: CsHttpRequestOptions);
}

export abstract class FormGeneratorLookupService implements IFormGeneratorLookupService {
	abstract getLookupWithFilter(lookupName: string, formDetails: any, contextObject: any, csHttpRequestOptions?: CsHttpRequestOptions);

	abstract dependantFieldHasChanged(changedFieldId: string, formDetails: any, contextObject: any, csHttpRequestOptions?: CsHttpRequestOptions);

}
