<div class="cs-combobox-scroller" [ngStyle]="{'top.px': top, 'left.px': left, 'width.px': width}">
	<form *ngIf="filterEnabled" (click)="onFilterClick($event)">
		<div class="form-group">
			<input
					type="search"
					class="form-control"
					placeholder="{{'LABEL_SEARCH' | translate}}"
					#filterInput

					(input)="onFilterInput($event)"
					(keydown)="onFilterKeydown($event)">
		</div>
	</form>

	<div class="options" [class.options-with-filter]="filterEnabled" #optionsList>

		<div class="list"
			 #innerOptionsList>
			<cdk-virtual-scroll-viewport [itemSize]="30"
										 minBufferPx="200" maxBufferPx="300"
										 class="full-height">
				<a *cdkVirtualFor="let option of optionList?.filtered"
				   class="list-item {{option.cssClass}}"
				   [ngClass]="{'active': option.selected,
                       'disabled':option.disabled,
                       'highlight': option.highlighted}"
				   [attr.role]="option"
				   (click)="onOptionClick(option, $event)"
				   [hidden]="!option.shown"
				   [matTooltip]="option.label"
				   matTooltipPosition="above"
				   [matTooltipDisabled]="!option.isTruncated"
				   (mouseover)="detectTruncatedField($event, option)"
				   (mouseover)="onOptionMouseover(option)">
					{{option.label}}
				</a>
			</cdk-virtual-scroll-viewport>
		</div>

	</div>
</div>
