import { ChangeDetectionStrategy, Component, forwardRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DashboardEventHub }                                                         from '@cs/components/dashboard/dashboard-event-hub.service';
import { FormGeneratorNxtComponent }                                                 from '../../form-generator-nxt.component';
import { DashboardPanelComponentBase }                                               from '@cs/components/shared';
import { ArrayUtils, DataDescribed, LayoutAnnotationForm, LayoutOrientation }        from '@cs/core';
import { ValidationResult }                                                          from '@cs/core/generate';
import { UntilDestroy }                                                              from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
			   selector:        'cs-dashboard-simple-form-nxt',
			   templateUrl:     './dashboard-simple-form-nxt.component.html',
			   styleUrls:       ['./dashboard-simple-form-nxt.component.scss'],
			   changeDetection: ChangeDetectionStrategy.OnPush
		   })
export class DashboardSimpleFormNxtComponent
	extends DashboardPanelComponentBase<DataDescribed<any, LayoutAnnotationForm<any>>>
	implements OnInit {

	static TYPE_NAME = 'simple-form-nxt';
	renderContext: DataDescribed<any, LayoutAnnotationForm<any>>;
	@ViewChild(FormGeneratorNxtComponent, {static: true}) form: FormGeneratorNxtComponent<any>;

	constructor(@Inject(forwardRef(() => DashboardEventHub)) dashboardEventHub: DashboardEventHub) {
		super();

		// dashboardEventHub.getActionsHook<any, string>()
		// 				 .pipe(
		// 					 untilDestroyed(this),
		// 					 filter(value => value.panelName === this.name))
		// 				 .subscribe(value => {
		// 					 switch (value.action) {
		// 						 case 'HasErrors':
		// 							 this.setErrors(value.data);
		// 							 break;
		// 					 }
		// 				 });
	}

	ngOnInit(): void {
	}

	dataChanged(value: DataDescribed<any, LayoutAnnotationForm<any>>) {

		value.layout = new LayoutAnnotationForm<any>({
														 form: {
															 id:     '',
															 layout: {
																 readOnly:         true,
																 readOnlyAsText:   true,
																 groupOrientation: LayoutOrientation.Vertical
															 }
														 }
													 });

		this.renderContext = value;

	}

	update(data: DataDescribed<any, LayoutAnnotationForm<any>>): void {
		if (this.data && data && ArrayUtils.isEqual(this.data.data, data.data))
			return;

		this.data = data;
	}

	override setErrors(validationResults: ValidationResult[]) {
		this.form.showErrorResponse(validationResults);
	}


}
