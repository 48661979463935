import { Component, EventEmitter, Input, OnInit, Output }   from '@angular/core';
import { BranchDto, BranchType, formatDate, RepositoryDto } from '@cs/performance-manager/git-graph';
import { UntilDestroy }                                     from '@ngneat/until-destroy';
import {
	UpdateRepositoryType
}                                                         from '../../models/submodule-update-dialog.models';


@UntilDestroy()
@Component({
			   selector:    'pmc-submodule-update-view',
			   templateUrl: './submodule-update-view.component.html',
			   styleUrls:   ['../../submodule-update-dialog.component.scss']
		   })
export class SubmoduleUpdateViewComponent implements OnInit {

	// Update dialog settings
	type: UpdateRepositoryType = UpdateRepositoryType.warning;

	// Values for updating
	@Input() parentRepositories: RepositoryDto[] = [];

	@Input() featureBranchesStore: BranchDto[] = [];

	featureBranches: BranchDto[] = [];

	@Output() submoduleCheckout = new EventEmitter<{
		repositoryList: Map<string, RepositoryDto[]>,
		selectedBranch: BranchDto,
		commitMessage: string
	}>();

	@Output() push = new EventEmitter<{
		repositoryList: Map<string, RepositoryDto[]>,
		selectedBranch: BranchDto,
		commitMessage: string
	}>();

	@Output() showCommitInfo = new EventEmitter<RepositoryDto>();
	@Output() cancel         = new EventEmitter<void>();

	// Update event stuff
	public commitMessage = '';

	// Selection filters
	public activeFilter            = '';
	public branchFilters: string[] = ['Feature', 'Hotfix', 'Release', 'Main'];

	public selectedBranch: BranchDto;
	public selectedBranchRowIndex: number = undefined;

	public ignoreWarnings = false;
	public commitAndPush  = false;

	public errorMessage = '';

	constructor() {}

	ngOnInit(): void {
		this.setInitialFilter();
	}

	setInitialFilter() {
		const initialFilterValue: string = this.branchFilters.find(branch => this.isBranchFilterDisabled(branch));

		this.filterBranchList(initialFilterValue);

		this.activeFilter = initialFilterValue;
	}

	isBranchFilterDisabled(filter: string): boolean {

		switch (filter) {
			case 'Feature':
				return this.featureBranchesStore.some(branchDto => branchDto.branchType === BranchType.FEATURE);

			case 'Hotfix':
				return this.featureBranchesStore.some(branchDto => branchDto.branchType === BranchType.HOTFIX);

			case 'Release':
				return this.featureBranchesStore.some(branchDto => branchDto.branchType === BranchType.RELEASE);

			case 'Main':
				return this.featureBranchesStore.some(branchDto => branchDto.branchType === BranchType.MAIN);

			default:
				return this.featureBranchesStore.length > 0;
		}

	}

	filterBranchList(filter: string) {
		this.activeFilter = filter;

		switch (filter) {

			case 'Feature' : {
				this.featureBranches = this.featureBranchesStore.filter(branchDto => branchDto.branchType === BranchType.FEATURE);
				break;
			}

			case 'Hotfix' : {
				this.featureBranches = this.featureBranchesStore.filter(branchDto => branchDto.branchType === BranchType.HOTFIX);
				break;
			}

			case 'Release' : {
				this.featureBranches = this.featureBranchesStore.filter(branchDto => branchDto.branchType === BranchType.RELEASE);
				break;
			}

			case 'Main' : {
				this.featureBranches = this.featureBranchesStore.filter(branchDto => branchDto.branchType === BranchType.MAIN);
				break;
			}

			default: {
				this.featureBranches = this.featureBranchesStore.filter(branchDto => branchDto.branchType === BranchType.ETC);
				break;
			}
		}

		this.selectedBranchRowIndex = 0;
		this.selectedBranch         = this.featureBranches[0];
	}

	public selectBranchRow(index: number, branch: BranchDto) {
		if (index !== this.selectedBranchRowIndex) {
			this.selectedBranch         = branch;
			this.selectedBranchRowIndex = index;
		}
	}

	IsSameBranch(repository: RepositoryDto, selectedBranch: BranchDto): boolean {
		return repository.subModules.some(submodule => submodule.hashOrBranch.trim() === selectedBranch.commitHash.trim());
	}

	HasUnpushedCommitsOrSameBranch(): boolean {
		return this.parentRepositories.some(repository => repository.unPushedCommitCount > 0 || this.IsSameBranch(repository, this.selectedBranch));
	}

	HasValidUpdateRequirements(): boolean {

		if (!this.selectedBranch || this.commitMessage == '') {
			return false;
		} else {

			if (this.ignoreWarnings) {
				return true;
			} else {
				return !this.HasUnpushedCommitsOrSameBranch();
			}

		}

	}

	getRepositoryData(): RepositoryDto[] {
		const output: RepositoryDto[] = [];

		this.createRepositoryMap()
			.forEach((value) => {
				value.forEach(v => output.push(v));
			});

		return output;
	}

	createRepositoryMap(): Map<string, RepositoryDto[]> {
		const repositoriesFixed: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

		this.parentRepositories.forEach(repository => {
			if (repositoriesFixed.has(repository.name)) {
				repositoriesFixed.get(repository.name)
								 .push(repository);
			} else {
				repositoriesFixed.set(repository.name, [repository]);
			}
		});

		return repositoriesFixed;
	}

	GetFormattedDate(date: Date): string {
		return formatDate(new Date(date));
	}

	CheckoutSubmoduleEvent(repositories: Map<string, RepositoryDto[]>) {

		if (!this.commitAndPush) {
			this.submoduleCheckout.emit({
											repositoryList: repositories,
											selectedBranch: this.selectedBranch,
											commitMessage:  this.commitMessage
										});
		} else {
			this.push.emit({
							   repositoryList: repositories,
							   selectedBranch: this.selectedBranch,
							   commitMessage:  this.commitMessage
						   });
		}

	}

	ShowCommitInfo(repository: RepositoryDto) {
		this.showCommitInfo.emit(repository);
	}

	getTableCell(repository: RepositoryDto, selectedBranch: BranchDto): number {
		let result = 4;

		if (this.IsSameBranch(repository, selectedBranch) && repository.unPushedCommitCount >= 1 && selectedBranch !== undefined) {
			result = 1;
		} else {
			if (repository.unPushedCommitCount >= 1 && !this.IsSameBranch(repository, selectedBranch)) {
				result = 2;
			} else {
				if (selectedBranch && repository.unPushedCommitCount < 1 && this.IsSameBranch(repository, selectedBranch)) {
					result = 3;
				}
			}
		}

		return result;
	}

	onNoClick() {
		this.cancel.emit();
	}
}
