<div class="flex  full-height full-width flex-column">
	<cs-chart-legend-nxt [series]="series"
						 [chartOptions]="chartOptions"
						 chartType="{{data?.layout.chartType}}"
						 (onLegendClick)="onLegendClicked($event)"
						 (onMouseOver)="onLegendMouseOver($event)"
						 (onMouseLeave)="onLegendMouseLeave($event)">

	</cs-chart-legend-nxt>
	<div *ngIf="data?.data?.length > 0"
		 [ngClass]="clickTypeClass"
		 [class.is-clickable]="isClickable"
		 [class.whole-chart-clickable]="isWholeChartClickable"
		 [class.no-legend]="series == null || series?.length === 0"
		 #csChartLoaderDirective="cs-chart-loader"
		 class="dashboard-chart-nxt-container"
		 style="height: 100%; width: 100%"
		 cs-chart-loader
		 [chartOptions]="chartOptions"
		 (chartClicked)="chartClicked($event, data)"
		 (chartElementClicked)="onChartIsClicked($event, data)"
		 chartType="{{data?.layout.chartType}}"
		 [chartData]="chartData">
	</div>


	<div *ngIf="data?.data?.length === 0" class="no-data-container">
		<div class="no-data">
			<div class="icon">
				<i class="mdi mdi-48px mdi-chart-areaspline"></i>
				<div> {{'NO_PANEL' | translate}} </div>
			</div>

		</div>

	</div>
</div>
