import { Component, OnInit }                                                         from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet }                                      from '@angular/router';
import { getNavItemRoutesFromCurrentLoadedModule, NavItemRoute }                     from '@cs/common';
import { animate, animateChild, group, query, sequence, style, transition, trigger } from '@angular/animations';
import { Observable }                                                                from 'rxjs';
import { TabQuery }                                                                  from './state/tap.query';
import { ContainerSettings }                                                         from '@cs/performance-manager/shared';
import { AppService }                                                                from '@cs/performance-manager/shared';

@Component({
	selector:    'pmc-tabbed-page',
	templateUrl: './tabbed-page.component.html',
	animations:  [
		trigger('fadeAnimation', [

			transition('* => *', [
				query(':enter, :leave', style({position: 'fixed'}),
					{optional: true}),
				query(':enter', style({opacity: 0}),
					{optional: true}),
				sequence([
					query(':leave', animateChild(),
						{optional: true}),
					group([
						query(':leave', [
								style({opacity: 1}),
								animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
									style({opacity: 0}))
							],
							{optional: true}),
						query(':enter', [
								style({opacity: 0}),
								animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)',
									style({opacity: 1}))
							],
							{optional: true})
					]),
					query(':enter', animateChild(),
						{optional: true})
				])
			])
		])
	]
})
export class TabbedPageComponent extends ContainerSettings implements OnInit {
	tabbedPages: NavItemRoute[];

	$inProgress: Observable<boolean>;

	constructor(private activeRoute: ActivatedRoute,
							private tabQuery: TabQuery,
							private router: Router,
							private readonly appService: AppService) {
		super();
		this.tabbedPages = getNavItemRoutesFromCurrentLoadedModule(activeRoute);
		this.$inProgress = tabQuery.select(store => store.inProgress);
	}

	ngOnInit() {
	}

	public getRouterOutletState(outlet: RouterOutlet) {
		return outlet.isActivated ? outlet.activatedRoute : '';
	}

	refreshNavigation() {
		this.appService.refreshNavigationBar();
	}

}
