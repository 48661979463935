import { ChangeDetectorRef }                       from '@angular/core';
/* tslint:disable */
import { CsInputMaskDefaults }                     from './input-mask-defaults';
import {
  AfterViewInit, Directive, DoCheck, ElementRef, forwardRef
  , Input, OnInit
}                                                  from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CsCultureProvider }                       from '@cs/common';
import { InputMaskHelpers }                        from './input-mask-helpers';
import { MaskToken }                               from './input-mask-token';
import { Renderer2, NgZone }                       from '@angular/core';
import { CsInputMaskDefinitions }                  from './input-mask-definitions';
import { CsInputMaskConfig }                       from './input-mask-aliases';
import { Subscription }                            from 'rxjs';

export const INPUT_MASK_DIRECTIVE_VALUE_ACCESSOR: any = {
  provide:     NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CsInputMaskDirective),
  multi:       true
};

/**
 * Input mask directive.
 *




 */
@Directive({
  selector:  'input[csInputMask]',
  providers: [INPUT_MASK_DIRECTIVE_VALUE_ACCESSOR]
})
// tslint:disable
export class CsInputMaskDirective implements AfterViewInit, ControlValueAccessor, DoCheck, OnInit {
  /**
   * ngModel to bind
   */
  @Input() ngModel;
  /**
   * Options
   */
  @Input() options: any;
  /**
   * mask alias, for example: percentage,decimal,integer ...
   */
  @Input() alias: any;

  private onCultureChangedSubscribtion: Subscription;
  private onModelChange: Function;
  private onModelTouched: Function;

  ua       = navigator.userAgent;
  mobile   = /mobile/i.test(this.ua);
  iemobile = /iemobile/i.test(this.ua);
  iphone   = /iphone/i.test(this.ua) && !this.iemobile;
  android  = /android/i.test(this.ua) && !this.iemobile;

  EventHandlers;
  events;
  maskset;
  refreshValue: boolean;
  opts;
  noMasksCache: boolean;
  userOptions;
  isRTL: boolean;
  dataAttribute = 'data-inputmask'; // data attribute prefix used for attribute binding
  // options default
  masksCache = {};

  get _valueGet() {
    return this.elementRef.nativeElement._valueGet;
  }

  set _valueGet(v) {

    this.elementRef.nativeElement._valueGet = v;
  }

  get _valueSet() {
    return this.elementRef.nativeElement._valueSet;
  }

  set _valueSet(v) {
    this.elementRef.nativeElement._valueSet = v;
  }

  __valueGet;
  __valueSet;

  get radixPoint() {
    return this.maskConfig.radixPoint;
  }

  get groupSeparator() {
    return this.maskConfig.groupSeparator;
  }

  constructor(private elementRef: ElementRef,
              private cultureProvider: CsCultureProvider,
              private renderer: Renderer2,
              private zone: NgZone,
              private maskConfig: CsInputMaskConfig,
              private defaults: CsInputMaskDefaults,
              private definitions: CsInputMaskDefinitions,
              private _changeDetectionRef: ChangeDetectorRef) {
  }

  ngOnInit() {
    //setTimeout(() => {

    if (this.ngModel != null) {
      this.elementRef.nativeElement.value = this.ngModel == null ? '' : this.ngModel;// ((this.ngModel || '') + '').replace(/\./g, this.radixPoint);
      // this.elementRef.nativeElement.value = this.ngModel;
    }
    this.events       = {};
    this.maskset      = undefined;
    this.refreshValue = false; // indicate a refresh from the inputvalue is needed (form.reset)
    // init options
    if (InputMaskHelpers.isPlainObject(this.alias)) {
      this.options = this.alias;
    } else {
      this.options       = this.options || {};
      this.options.alias = this.alias;
    }
    // this.opts = InputMaskHelpers.clone({}, this.defaults, this.options);
    this.opts         = InputMaskHelpers.deepClone({}, this.defaults.defaults, this.options);
    this.noMasksCache = this.options && this.options.definitions !== undefined;
    this.userOptions  = this.options || {}; // user passed options
    this.isRTL        = this.opts.numericInput;
    let culture       = this.cultureProvider.getCulture();
    this.resolveAlias(this.opts.alias, this.options, this.opts);
    this.mask();
    this.onCultureChangedSubscribtion = this.cultureProvider.onCultureChanged.subscribe(
      () => {
        let v = this.unmaskedvalue();
        this.option({}, false);
        this.elementRef.nativeElement.value = this.ngModel == null ? '' : this.ngModel;// ((v || '') + '').replace(/\./g, this.radixPoint);
      }
    );
    //}, 1);

    //this._changeDetectionRef.detectChanges();
  }

  ngAfterViewInit() {

  }

  ngDoCheck() {

  }

  // @HostListener('cut', ['$event'])
  handleCut(event: any) {
    if (!this.isChromeAndroid()) {
    }
  }

  // @HostListener('input', ['$event'])
  handleInput(event: any) {


    if (this.isChromeAndroid()) {
    }
  }

  // @HostListener('keydown', ['$event'])
  handleKeydown(event: any) {


    if (!this.isChromeAndroid()) {
    }
  }

  // @HostListener('keypress', ['$event'])
  handleKeypress(event: any) {
    if (!this.isChromeAndroid()) {
    }
  }

  // @HostListener('paste', ['$event'])
  handlePaste(event: any) {
    if (!this.isChromeAndroid()) {
    }
  }

  isChromeAndroid(): boolean {
    return /chrome/i.test(navigator.userAgent) && /android/i.test(navigator.userAgent);
  }

  registerOnChange(callbackFunction: Function): void {
    this.onModelChange = callbackFunction;
  }

  registerOnTouched(callbackFunction: Function): void {
    this.onModelTouched = callbackFunction;
  }

  setDisabledState(value: boolean): void {
    this.elementRef.nativeElement.disabled = value;
  }

  writeValue(value: number): void {
    // this.setValue(value);
    this.elementRef.nativeElement.value = (value == null ? '' : value);// ((value || '') + '').replace(/\./g, radixPoint);
  }

  mask = () => {

    let scopedOpts = InputMaskHelpers.deepClone({}, this.opts);
    InputMaskHelpers.deepClone(scopedOpts, this.userOptions);
    let maskset = this.generateMaskSet(scopedOpts, this.noMasksCache);
    if (maskset !== undefined) {
      // store inputmask instance on the input with element reference
      this.opts         = scopedOpts;
      this.noMasksCache = this.noMasksCache;
      this.isRTL        = this.isRTL;
      this.maskset      = maskset;


      this.maskScope({
        'action': 'mask'
      });
    }

  };

  trigger(elem, eventName) {

    // setTimeout(() => {
    let ev = new Event(eventName);
    this.invokeElementMethod(elem, 'dispatchEvent', [ev]);
    // }, 0);

  }

  invokeElementMethod(renderElement: Element, methodName: string, args: any[]): void {
    (renderElement as any)[methodName].apply(renderElement, args);
  }

  option        = (options, noremask) => { // set extra options || retrieve value of a current option
    if (typeof options === 'string') {
      return this.opts[options];
    } else if (typeof options === 'object') {
      this.userOptions = InputMaskHelpers.deepClone({}, this.userOptions, options); // user passed options
      // remask
      if (noremask !== true) {
        this.mask();
      }
      return this;
    }
  };
  unmaskedvalue = (value?) => {
    this.maskset = this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    return this.maskScope({
      'action': 'unmaskedvalue',
      'value':  value
    });
  };
  remove        = () => {
    return this.maskScope({
      'action': 'remove'
    });
  };

  getemptymask   = () => { // return the default (empty) mask value, usefull for setting the default value in validation
    this.maskset = this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    return this.maskScope({
      'action': 'getemptymask'
    });
  };
  // check wheter the returned value is masked or not; currently only works reliable when using jquery.val fn to retrieve the value
  hasMaskedValue = () => {
    return !this.opts.autoUnmask;
  };
  isComplete     = () => {
    this.maskset = this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    return this.maskScope({
      'action': 'isComplete'
    });
  };
  getmetadata    = () => { // return mask metadata if exists
    this.maskset = this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    return this.maskScope({
      'action': 'getmetadata'
    });
  };
  isValid        = (value) => {
    this.maskset = this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    return this.maskScope({
      'action': 'isValid',
      'value':  value
    });
  };
  format         = (value, metadata) => {
    this.maskset = this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    return this.maskScope({
      'action':   'format',
      'value':    value,
      'metadata': metadata // true/false getmetadata
    });
  };

  analyseMask       = (mask, opts) => {
    let tokenizer    = /(?:[?*+]|\{[0-9\+\*]+(?:,[0-9\+\*]*)?\})|[^.?*+^${[]()|\\]+|./g,
        escaped      = false,
        currentToken = new MaskToken(),
        match,
        m,
        openenings   = [],
        maskTokens   = [],
        openingToken,
        currentOpeningToken,
        alternator,
        lastMatch,
        groupToken;

    let insertTestDefinition = (mtoken, element, position?) => {
      let maskdef   = (opts.definitions ? opts.definitions[element] : undefined) || this.definitions[element];
      position      = position !== undefined ? position : mtoken.matches.length;
      let prevMatch = mtoken.matches[position - 1];
      if (maskdef && !escaped) {
        let prevalidators  = maskdef.prevalidator,
            prevalidatorsL = prevalidators ? prevalidators.length : 0;
        // handle prevalidators
        for (let i = 1; i < maskdef.cardinality; i++) {
          let prevalidator = prevalidatorsL >= i ? prevalidators[i - 1] : [],
              validator    = prevalidator.validator,
              cardinality  = prevalidator.cardinality;
          mtoken.matches.splice(position++, 0, {
            fn:             validator ? typeof validator === 'string' ? new RegExp(validator) : {
              test: validator
            } : new RegExp('.'),
            cardinality:    cardinality ? cardinality : 1,
            optionality:    mtoken.isOptional,
            newBlockMarker: prevMatch === undefined || prevMatch.def !== (maskdef.definitionSymbol || element),
            casing:         maskdef.casing,
            def:            maskdef.definitionSymbol || element,
            placeholder:    maskdef.placeholder,
            nativeDef:      element
          });
          prevMatch = mtoken.matches[position - 1];
        }
        mtoken.matches.splice(position++, 0, {
          fn:             maskdef.validator ? typeof maskdef.validator === 'string' ? new RegExp(maskdef.validator) : {
            test: maskdef.validator
          } : new RegExp('.'),
          cardinality:    maskdef.cardinality,
          optionality:    mtoken.isOptional,
          newBlockMarker: prevMatch === undefined || prevMatch.def !== (maskdef.definitionSymbol || element),
          casing:         maskdef.casing,
          def:            maskdef.definitionSymbol || element,
          placeholder:    maskdef.placeholder,
          nativeDef:      element
        });
      } else {
        mtoken.matches.splice(position++, 0, {
          fn:             null,
          cardinality:    0,
          optionality:    mtoken.isOptional,
          newBlockMarker: prevMatch === undefined || prevMatch.def !== element,
          casing:         null,
          def:            opts.staticDefinitionSymbol || element,
          placeholder:    opts.staticDefinitionSymbol !== undefined ? element : undefined,
          nativeDef:      element
        });
        escaped = false;
      }
    };

    let verifyGroupMarker = (maskToken) => {
      if (maskToken && maskToken.matches) {
        (maskToken.matches as any[]).forEach((token, ndx) => {
            let nextToken = maskToken.matches[ndx + 1];
            if ((nextToken === undefined || (nextToken.matches === undefined || nextToken.isQuantifier === false)) && token && token.isGroup) { // this is not a group but a normal mask => convert
              token.isGroup = false;
              insertTestDefinition(token, opts.groupmarker.start, 0);
              if (token.openGroup !== true) {
                insertTestDefinition(token, opts.groupmarker.end);
              }
            }
            verifyGroupMarker(token);
          }
        );
      }
    };

    let defaultCase = () => {
      if (openenings.length > 0) {
        currentOpeningToken = openenings[openenings.length - 1];
        insertTestDefinition(currentOpeningToken, m);
        if (currentOpeningToken.isAlternator) { // handle alternator a | b case
          alternator = openenings.pop();
          for (let mndx = 0; mndx < alternator.matches.length; mndx++) {
            alternator.matches[mndx].isGroup = false; // don't mark alternate groups as group
          }
          if (openenings.length > 0) {
            currentOpeningToken = openenings[openenings.length - 1];
            currentOpeningToken.matches.push(alternator);
          } else {
            currentToken.matches.push(alternator);
          }
        }
      } else {
        insertTestDefinition(currentToken, m);
      }
    };

    let reverseTokens = (maskToken) => {
      let reverseStatic = (st) => {
        if (st === opts.optionalmarker.start) st = opts.optionalmarker.end;
        else if (st === opts.optionalmarker.end) st = opts.optionalmarker.start;
        else if (st === opts.groupmarker.start) st = opts.groupmarker.end;
        else if (st === opts.groupmarker.end) st = opts.groupmarker.start;

        return st;
      };

      maskToken.matches = maskToken.matches.reverse();
      for (let match in maskToken.matches) {
        if (maskToken.matches.hasOwnProperty(match)) {
          let intMatch = parseInt(match);
          if (maskToken.matches[match].isQuantifier && maskToken.matches[intMatch + 1] && maskToken.matches[intMatch + 1].isGroup) { // reposition quantifier
            let qt = maskToken.matches[match];
            maskToken.matches.splice(match, 1);
            maskToken.matches.splice(intMatch + 1, 0, qt);
          }
          if (maskToken.matches[match].matches !== undefined) {
            maskToken.matches[match] = reverseTokens(maskToken.matches[match]);
          } else {
            maskToken.matches[match] = reverseStatic(maskToken.matches[match]);
          }
        }
      }

      return maskToken;
    };

    while (match = tokenizer.exec(mask)) {
      m = match[0];

      if (escaped) {
        defaultCase();
        continue;
      }
      switch (m.charAt(0)) {
        case opts.escapeChar:
          escaped = true;
          break;
        case opts.optionalmarker.end:
        // optional closing
        case opts.groupmarker.end:
          // Group closing
          openingToken           = openenings.pop();
          openingToken.openGroup = false; // mark group as complete
          if (openingToken !== undefined) {
            if (openenings.length > 0) {
              currentOpeningToken = openenings[openenings.length - 1];
              currentOpeningToken.matches.push(openingToken);
              if (currentOpeningToken.isAlternator) { // handle alternator (a) | (b) case
                alternator = openenings.pop();
                for (let mndx = 0; mndx < alternator.matches.length; mndx++) {
                  alternator.matches[mndx].isGroup = false; // don't mark alternate groups as group
                }
                if (openenings.length > 0) {
                  currentOpeningToken = openenings[openenings.length - 1];
                  currentOpeningToken.matches.push(alternator);
                } else {
                  currentToken.matches.push(alternator);
                }
              }
            } else {
              currentToken.matches.push(openingToken);
            }
          } else defaultCase();
          break;
        case opts.optionalmarker.start:
          // optional opening
          openenings.push(new MaskToken(false, true));
          break;
        case opts.groupmarker.start:
          // Group opening
          openenings.push(new MaskToken(true));
          break;
        case opts.quantifiermarker.start:
          // Quantifier
          let quantifier = new MaskToken(false, false, true);

          m       = m.replace(/[{}]/g, '');
          let mq  = m.split(','),
              mq0 = isNaN(mq[0]) ? mq[0] : parseInt(mq[0], 10),
              mq1 = mq.length === 1 ? mq0 : (isNaN(mq[1]) ? mq[1] : parseInt(mq[1], 10));
          if (mq1 === '*' || mq1 === '+') {
            mq0 = mq1 === '*' ? 0 : 1;
          }
          quantifier.quantifier = {
            min: mq0,
            max: mq1
          };
          if (openenings.length > 0) {
            let matches = openenings[openenings.length - 1].matches;
            match       = matches.pop();
            if (!match.isGroup) {
              groupToken = new MaskToken(true);
              groupToken.matches.push(match);
              match = groupToken;
            }
            matches.push(match);
            matches.push(quantifier);
          } else {
            match = currentToken.matches.pop();
            if (!match.isGroup) {
              groupToken = new MaskToken(true);
              groupToken.matches.push(match);
              match = groupToken;
            }
            currentToken.matches.push(match);
            currentToken.matches.push(quantifier);
          }
          break;
        case
        opts.alternatormarker:
          if (openenings.length > 0) {
            currentOpeningToken = openenings[openenings.length - 1];
            lastMatch           = currentOpeningToken.matches.pop();
          } else {
            lastMatch = currentToken.matches.pop();
          }
          if (lastMatch.isAlternator) {
            openenings.push(lastMatch);
          } else {
            alternator = new MaskToken(false, false, false, true);
            alternator.matches.push(lastMatch);
            openenings.push(alternator);
          }
          break;
        default:
          defaultCase();
      }
    }

    while (openenings.length > 0) {
      openingToken = openenings.pop();
      currentToken.matches.push(openingToken);
    }
    if (currentToken.matches.length > 0) {
      verifyGroupMarker(currentToken);
      maskTokens.push(currentToken);
    }

    if (opts.numericInput) {
      reverseTokens(maskTokens[0]);
    }
    //
    return maskTokens;
  };
  extendDefaults    = (options) => {
    this.defaults.defaults = InputMaskHelpers.deepClone({}, this.defaults.defaults, options);
  };
  extendDefinitions = (definition) => {
    this.definitions.definitions = InputMaskHelpers.deepClone({}, this.definitions.definitions, definition);
  };
  extendAliases     = (alias) => {
    this.maskConfig.aliases = InputMaskHelpers.deepClone({}, this.maskConfig.aliases, alias);
  };


  resolveAlias(aliasStr, options, opts) {
    let aliasDefinition = this.maskConfig.aliases[aliasStr];
    if (aliasDefinition) {
      if (aliasDefinition.alias) this.resolveAlias(aliasDefinition.alias, undefined, opts); // alias is another alias
      InputMaskHelpers.deepClone(opts, aliasDefinition); // merge alias definition in the options
      InputMaskHelpers.deepClone(opts, options); // reapply extra given options
      return true;
    } else // alias not found - try as mask
    if (opts.mask === null) {
      opts.mask = aliasStr;
    }
    return false;
  }

  generateMaskSet(opts, nocache) {
    let generateMask = (mask, metadata, opts) => {
      if (mask === null || mask === '') {
        mask = '*{*}';
      }
      if (mask.length === 1 && opts.greedy === false && opts.repeat !== 0) {
        opts.placeholder = '';
      } // hide placeholder with single non-greedy mask
      if (opts.repeat > 0 || opts.repeat === '*' || opts.repeat === '+') {
        var repeatStart = opts.repeat === '*' ? 0 : (opts.repeat === '+' ? 1 : opts.repeat);
        mask            = opts.groupmarker.start + mask + opts.groupmarker.end + opts.quantifiermarker.start + repeatStart + ',' + opts.repeat + opts.quantifiermarker.end;
      }

      //
      let masksetDefinition;
      if (this.masksCache[mask] === undefined || nocache === true) {
        masksetDefinition = {
          'mask':           mask,
          'maskToken':      this.analyseMask(mask, opts),
          'validPositions': {},
          '_buffer':        undefined,
          'buffer':         undefined,
          'tests':          {},
          'metadata':       metadata,
          maskLength:       undefined
        };
        if (nocache !== true) {
          this.masksCache[opts.numericInput ? mask.split('').reverse().join('') : mask] = masksetDefinition;
          masksetDefinition                                                             = InputMaskHelpers.deepClone({}, this.masksCache[opts.numericInput ? mask.split('').reverse().join('') : mask]);
        }
      } else masksetDefinition = InputMaskHelpers.deepClone({}, this.masksCache[opts.numericInput ? mask.split('').reverse().join('') : mask]);

      return masksetDefinition;
    };

    let ms;

    if (InputMaskHelpers.isFunction(opts.mask)) { // allow mask to be a preprocessing fn - should return a valid mask
      opts.mask = opts.mask(opts);
    }
    if (InputMaskHelpers.isArray(opts.mask)) {
      if (opts.mask.length > 1) {
        opts.keepStatic = opts.keepStatic === null ? true : opts.keepStatic; // enable by default when passing multiple masks when the option is not explicitly specified
        let altMask     = opts.groupmarker.start;
        (opts.numericInput ? opts.mask.reverse() : opts.mask).forEach((msk, ndx) => {
          if (altMask.length > 1) {
            altMask += opts.groupmarker.end + opts.alternatormarker + opts.groupmarker.start;
          }
          if (msk.mask !== undefined && !InputMaskHelpers.isFunction(msk.mask)) {
            altMask += msk.mask;
          } else {
            altMask += msk;
          }
        });
        altMask += opts.groupmarker.end;
        //
        return generateMask(altMask, opts.mask, opts);
      } else opts.mask = opts.mask.pop();
    }

    if (opts.mask && opts.mask.mask !== undefined && !InputMaskHelpers.isFunction(opts.mask.mask)) {
      ms = generateMask(opts.mask.mask, opts.mask, opts);
    } else {
      ms = generateMask(opts.mask, opts.mask, opts);
    }

    return ms;
  }

  maskScope(actionObj, maskset?, opts?) {
    maskset = maskset || this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    opts    = opts || this.opts;

    let
      isRTL             = this.isRTL,
      undoValue,
      // $this.elementRef.nativeElement,
      skipKeyPressEvent = false, // Safari 5.1.x - modal dialog fires keypress twice workaround
      skipInputEvent    = false, // skip when triggered from within inputmask
      ignorable         = false,
      maxLength,
      mouseEnter        = false,
      colorMask;

    let getMaskSet = () => {
      return maskset || this.maskset || this.generateMaskSet(this.opts, this.noMasksCache);
    };

    let resetMaskSet = (soft?) => {
      var maskset    = getMaskSet();
      maskset.buffer = undefined;
      if (soft !== true) {
        // maskset._buffer = undefined;
        maskset.validPositions = {};
        maskset.p              = 0;
      }
    };

    let getLastValidPosition = (closestTo?, strict?, validPositions?) => {
      let before = -1,
          after  = -1,
          valids = validPositions || getMaskSet().validPositions; // for use in valhook ~ context switch
      if (closestTo === undefined) closestTo = -1;
      for (let posNdx in valids) {
        let psNdx = parseInt(posNdx);
        if (valids[psNdx] && (strict || valids[psNdx].generatedInput !== true)) {
          if (psNdx <= closestTo) before = psNdx;
          if (psNdx >= closestTo) after = psNdx;
        }
      }
      return (before !== -1 && (closestTo - before) > 1) || after < closestTo ? before : after;
    };

    // maskset helperfunctions
    let getMaskTemplate     = (baseOnInput?, minimalPos?, includeMode?) => {
      // includeMode true => input, undefined => placeholder, false => mask
      minimalPos             = minimalPos || 0;
      let maskTemplate       = [],
          ndxIntlzr, pos     = 0,
          test, testPos, lvp = getLastValidPosition();
      maxLength              = this.elementRef.nativeElement !== undefined ? this.elementRef.nativeElement.maxLength : undefined;
      if (maxLength === -1) maxLength = undefined;
      do {
        if (baseOnInput === true && getMaskSet().validPositions[pos]) {
          testPos   = getMaskSet().validPositions[pos];
          test      = testPos.match;
          ndxIntlzr = testPos.locator.slice();
          maskTemplate.push(includeMode === true ? testPos.input : includeMode === false ? test.nativeDef : getPlaceholder(pos, test));
        } else {
          testPos   = getTestTemplate(pos, ndxIntlzr, pos - 1);
          test      = testPos.match;
          ndxIntlzr = testPos.locator.slice();
          if (opts.jitMasking === false || pos < lvp || (typeof opts.jitMasking === 'number' && isFinite(opts.jitMasking) && opts.jitMasking > pos)) {
            maskTemplate.push(includeMode === false ? test.nativeDef : getPlaceholder(pos, test));
          }
        }
        pos++;
      } while ((maxLength === undefined || pos < maxLength) && (test.fn !== null || test.def !== '') || minimalPos > pos);
      if (maskTemplate[maskTemplate.length - 1] === '') {
        maskTemplate.pop(); // drop the last one which is empty
      }

      getMaskSet().maskLength = pos + 1;
      return maskTemplate;
    };
    let stripValidPositions = (start, end, nocheck?, strict?) => {
      let IsEnclosedStatic = (pos) => {
        let posMatch = getMaskSet().validPositions[pos];
        if (posMatch !== undefined && posMatch.match.fn === null) {
          let prevMatch = getMaskSet().validPositions[pos - 1],
              nextMatch = getMaskSet().validPositions[pos + 1];
          return prevMatch !== undefined && nextMatch !== undefined;
        }
        return false;
      };

      let i, startPos                                                                                   = start,
          positionsClone = InputMaskHelpers.deepClone({}, getMaskSet().validPositions), needsValidation = false;
      getMaskSet().p                                                                                    = start; // needed for alternated position after overtype selection

      for (i = end - 1; i >= startPos; i--) { // clear selection
        if (getMaskSet().validPositions[i] !== undefined) {
          if (nocheck === true ||
            ((getMaskSet().validPositions[i].match.optionality || !IsEnclosedStatic(i)) && opts.canClearPosition(getMaskSet(), i, getLastValidPosition(), strict, opts) !== false)) {
            delete getMaskSet().validPositions[i];
          }
        }
      }

      // clear buffer
      resetMaskSet(true);
      for (i = startPos + 1; i <= getLastValidPosition();) {
        while (getMaskSet().validPositions[startPos] !== undefined) startPos++;
        if (i < startPos) i = startPos + 1;
        if (getMaskSet().validPositions[i] !== undefined || !isMask(i)) {
          var t = getTestTemplate(i);
          if (needsValidation === false && positionsClone[startPos] && positionsClone[startPos].match.def === t.match.def) { // obvious match
            getMaskSet().validPositions[startPos]       = InputMaskHelpers.deepClone({}, positionsClone[startPos]);
            getMaskSet().validPositions[startPos].input = t.input;
            delete getMaskSet().validPositions[i];
            i++;
          } else if (positionCanMatchDefinition(startPos, t.match.def)) {
            if (isValid(startPos, t.input || getPlaceholder(i), true) !== false) {
              delete getMaskSet().validPositions[i];
              i++;
              needsValidation = true;
            }
          } else if (!isMask(i)) {
            i++;
            startPos--;
          }
          startPos++;
        } else i++;
      }

      resetMaskSet(true);
    };

    let determineTestTemplate = (tests?, guessNextBest?) => {
      var testPos,
          testPositions = tests,
          lvp           = getLastValidPosition(),
          lvTest        = getMaskSet().validPositions[lvp] || getTests(0)[0],
          lvTestAltArr  = (lvTest.alternation !== undefined) ? lvTest.locator[lvTest.alternation].toString().split(',') : [];
      for (var ndx = 0; ndx < testPositions.length; ndx++) {
        testPos = testPositions[ndx];
        if (!testPos) {
          break;
        }
        if (testPos.match &&
          (((opts.greedy && testPos.match.optionalQuantifier !== true) || (testPos.match.optionality === false || testPos.match.newBlockMarker === false) && testPos.match.optionalQuantifier !== true) &&
            ((lvTest.alternation === undefined || lvTest.alternation !== testPos.alternation) ||
              (testPos.locator[lvTest.alternation] !== undefined && checkAlternationMatch(testPos.locator[lvTest.alternation].toString().split(','), lvTestAltArr))))) {

          if (guessNextBest !== true || (testPos.match.fn === null && !/[0-9a-bA-Z]/.test(testPos.match.def)))
            break;
        }
      }

      return testPos;
    };

    let getTestTemplate = (pos, ndxIntlzr?, tstPs?) => {
      return getMaskSet().validPositions[pos] || determineTestTemplate(getTests(pos, ndxIntlzr ? ndxIntlzr.slice() : ndxIntlzr, tstPs));
    };

    let getTest = (pos?) => {
      if (getMaskSet().validPositions[pos]) {
        return getMaskSet().validPositions[pos];
      }
      return getTests(pos)[0];
    };

    let positionCanMatchDefinition = (pos?, def?) => {
      var valid = false,
          tests = getTests(pos);
      for (var tndx = 0; tndx < tests.length; tndx++) {
        if (tests[tndx].match && tests[tndx].match.def === def) {
          valid = true;
          break;
        }
      }
      return valid;
    };
    let getTests                   = (pos, ndxIntlzr?, tstPs?) => {
      var maskTokens      = getMaskSet().maskToken,
          testPos         = ndxIntlzr ? tstPs : 0,
          ndxInitializer  = ndxIntlzr ? ndxIntlzr.slice() : [0],
          matches         = [],
          insertStop      = false,
          latestMatch,
          cacheDependency = ndxIntlzr ? ndxIntlzr.join('') : '';

      let resolveTestFromToken = (maskToken, ndxInitializer?, loopNdx?, quantifierRecurse?) => { // ndxInitializer contains a set of indexes to speedup searches in the mtoken=>s
        let handleMatch = (match, loopNdx?, quantifierRecurse?) => {
          let isFirstMatch = (latestMatch, tokenGroup) => {
            var firstMatch = InputMaskHelpers.inArray(latestMatch, tokenGroup.matches) === 0;
            if (!firstMatch) {
              InputMaskHelpers.each(tokenGroup.matches, (ndx, match) => {
                if (match.isQuantifier === true) {
                  firstMatch = isFirstMatch(latestMatch, tokenGroup.matches[ndx - 1]);
                  if (firstMatch) return false;
                }
              });
            }
            return firstMatch;
          };

          let resolveNdxInitializer = (pos, alternateNdx?, targetAlternation?) => {
            var bestMatch, indexPos;
            if (getMaskSet().tests[pos] || getMaskSet().validPositions[pos]) {
              InputMaskHelpers.each(getMaskSet().tests[pos] || [getMaskSet().validPositions[pos]], (ndx, lmnt) => {
                var alternation = targetAlternation !== undefined ? targetAlternation : lmnt.alternation,
                    ndxPos      = lmnt.locator[alternation] !== undefined ? lmnt.locator[alternation].toString().indexOf(alternateNdx) : -1;
                if ((indexPos === undefined || ndxPos < indexPos) && ndxPos !== -1) {
                  bestMatch = lmnt;
                  indexPos  = ndxPos;
                }
              });
            }
            return bestMatch ?
              bestMatch.locator.slice((targetAlternation !== undefined ? targetAlternation : bestMatch.alternation) + 1) :
              targetAlternation !== undefined ? resolveNdxInitializer(pos, alternateNdx) : undefined;
          };

          let staticCanMatchDefinition = (source, target) => {
            if (source.match.fn === null && target.match.fn !== null) {
              return target.match.fn.test(source.match.def, getMaskSet(), pos, false, opts, false);
            }
            return false;
          };

          if (testPos > 10000) {
            throw 'Inputmask: There is probably an error in your mask definition or in the code. Create an issue on github with an example of the mask you are using. ' + getMaskSet().mask;
          }
          if (testPos === pos && match.matches === undefined) {
            matches.push({
              'match':   match,
              'locator': loopNdx.reverse(),
              'cd':      cacheDependency
            });
            return true;
          } else if (match.matches !== undefined) {
            if (match.isGroup && quantifierRecurse !== match) { // when a group pass along to the quantifier
              match = handleMatch(maskToken.matches[InputMaskHelpers.inArray(match, maskToken.matches) + 1], loopNdx);
              if (match) return true;
            } else if (match.isOptional) {
              var optionalToken = match;
              match             = resolveTestFromToken(match, ndxInitializer, loopNdx, quantifierRecurse);
              if (match) {
                latestMatch = matches[matches.length - 1].match;
                if (isFirstMatch(latestMatch, optionalToken)) {
                  insertStop = true; // insert a stop
                  testPos    = pos; // match the position after the group
                } else return true;
              }
            } else if (match.isAlternator) {
              var alternateToken    = match,
                  malternateMatches = [],
                  maltMatches,
                  currentMatches    = matches.slice(),
                  loopNdxCnt        = loopNdx.length;
              var altIndex          = ndxInitializer.length > 0 ? ndxInitializer.shift() : -1;
              if (altIndex === -1 || typeof altIndex === 'string') {
                var currentPos          = testPos,
                    ndxInitializerClone = ndxInitializer.slice(),
                    altIndexArr         = [],
                    amndx;
                if (typeof altIndex == 'string') {
                  altIndexArr = altIndex.split(',');
                } else {
                  for (amndx = 0; amndx < alternateToken.matches.length; amndx++) {
                    altIndexArr.push(amndx);
                  }
                }
                for (var ndx = 0; ndx < altIndexArr.length; ndx++) {
                  amndx          = parseInt(altIndexArr[ndx]);
                  matches        = [];
                  // set the correct ndxInitializer
                  ndxInitializer = resolveNdxInitializer(testPos, amndx, loopNdxCnt) || ndxInitializerClone.slice();
                  match          = handleMatch(alternateToken.matches[amndx] || maskToken.matches[amndx], [amndx].concat(loopNdx), quantifierRecurse) || match;
                  if (match !== true && match !== undefined && (altIndexArr[altIndexArr.length - 1] < alternateToken.matches.length)) { // no match in the alternations (length mismatch) => look further
                    var ntndx = InputMaskHelpers.inArray(match, maskToken.matches) + 1;
                    if (maskToken.matches.length > ntndx) {
                      match = handleMatch(maskToken.matches[ntndx], [ntndx].concat(loopNdx.slice(1, loopNdx.length)), quantifierRecurse);
                      if (match) {
                        altIndexArr.push(ntndx.toString());
                        InputMaskHelpers.each(matches, (ndx, lmnt) => {
                          lmnt.alternation = loopNdx.length - 1;
                        });
                      }
                    }
                  }
                  maltMatches = matches.slice();
                  testPos     = currentPos;
                  matches     = [];

                  // fuzzy merge matches
                  for (var ndx1 = 0; ndx1 < maltMatches.length; ndx1++) {
                    var altMatch         = maltMatches[ndx1], hasMatch = false;
                    altMatch.alternation = altMatch.alternation || loopNdxCnt;
                    for (var ndx2 = 0; ndx2 < malternateMatches.length; ndx2++) {
                      var altMatch2 = malternateMatches[ndx2];
                      // verify equality
                      if (typeof altIndex !== 'string' || InputMaskHelpers.inArray(altMatch.locator[altMatch.alternation].toString(), altIndexArr) !== -1) {
                        if (altMatch.match.def === altMatch2.match.def || staticCanMatchDefinition(altMatch, altMatch2)) {
                          hasMatch = altMatch.match.nativeDef === altMatch2.match.nativeDef;
                          // if (altMatch.alternation != altMatch2.alternation) {
                          //
                          // }
                          if (altMatch.alternation == altMatch2.alternation && // can we merge if the alternation is different??  TODO TOCHECK INVESTIGATE
                            altMatch2.locator[altMatch2.alternation].toString().indexOf(altMatch.locator[altMatch.alternation]) === -1) {
                            altMatch2.locator[altMatch2.alternation] = altMatch2.locator[altMatch2.alternation] + ',' + altMatch.locator[altMatch.alternation];
                            altMatch2.alternation                    = altMatch.alternation; // we pass the alternation index => used in determineLastRequiredPosition
                            if (altMatch.match.fn == null) { // staticCanMatchDefinition => set no alternate on match
                              altMatch2.na = altMatch2.na || altMatch.locator[altMatch.alternation].toString();
                              if (altMatch2.na.indexOf(altMatch.locator[altMatch.alternation]) === -1)
                                altMatch2.na = altMatch2.na + ',' + altMatch.locator[altMatch.alternation];
                            }
                          }
                          break;
                        }
                      }
                    }
                    if (!hasMatch) {
                      malternateMatches.push(altMatch);
                    }
                  }
                }
                if (typeof altIndex == 'string') { // filter matches
                  malternateMatches = InputMaskHelpers.map(malternateMatches, (lmnt, ndx) => {
                    if (isFinite(ndx)) {
                      var mamatch,
                          alternation           = lmnt.alternation,
                          altLocArr             = lmnt.locator[alternation].toString().split(',');
                      lmnt.locator[alternation] = undefined;
                      lmnt.alternation          = undefined;

                      for (var alndx = 0; alndx < altLocArr.length; alndx++) {
                        mamatch = InputMaskHelpers.inArray(altLocArr[alndx], altIndexArr) !== -1;
                        if (mamatch) { // rebuild the locator with valid entries
                          if (lmnt.locator[alternation] !== undefined) {
                            lmnt.locator[alternation] += ',';
                            lmnt.locator[alternation] += altLocArr[alndx];
                          } else lmnt.locator[alternation] = parseInt(altLocArr[alndx]);

                          lmnt.alternation = alternation;
                        }
                      }

                      if (lmnt.locator[alternation] !== undefined) return lmnt;
                    }
                  });
                }

                matches    = currentMatches.concat(malternateMatches);
                testPos    = pos;
                insertStop = matches.length > 0; // insert a stopelemnt when there is an alternate - needed for non-greedy option

                // cloneback
                ndxInitializer = ndxInitializerClone.slice();
              } else {
                // if (alternateToken.matches[altIndex]) { // if not in the initial alternation => look further
                match = handleMatch(alternateToken.matches[altIndex] || maskToken.matches[altIndex], [altIndex].concat(loopNdx), quantifierRecurse);
                // } else match = false;
              }
              if (match) return true;
            } else if (match.isQuantifier && quantifierRecurse !== maskToken.matches[InputMaskHelpers.inArray(match, maskToken.matches) - 1]) {
              var qt = match;
              for (var qndx = (ndxInitializer.length > 0) ? ndxInitializer.shift() : 0; (qndx < (isNaN(qt.quantifier.max) ? qndx + 1 : qt.quantifier.max)) && testPos <= pos; qndx++) {
                var tokenGroup = maskToken.matches[InputMaskHelpers.inArray(qt, maskToken.matches) - 1];
                match          = handleMatch(tokenGroup, [qndx].concat(loopNdx), tokenGroup); // set the tokenGroup as quantifierRecurse marker
                if (match) {
                  // get latest match
                  latestMatch                    = matches[matches.length - 1].match;
                  latestMatch.optionalQuantifier = qndx > (qt.quantifier.min - 1);
                  if (isFirstMatch(latestMatch, tokenGroup)) { // search for next possible match
                    if (qndx > (qt.quantifier.min - 1)) {
                      insertStop = true;
                      testPos    = pos; // match the position after the group
                      break; // stop quantifierloop
                    } else return true;
                  } else {
                    return true;
                  }
                }
              }
            } else {
              match = resolveTestFromToken(match, ndxInitializer, loopNdx, quantifierRecurse);
              if (match) return true;
            }
          }

          else
            testPos++;
        };

        for (var tndx = (ndxInitializer.length > 0 ? ndxInitializer.shift() : 0); tndx < maskToken.matches.length; tndx++) {
          if (maskToken.matches[tndx].isQuantifier !== true) {
            var match = handleMatch(maskToken.matches[tndx], [tndx].concat(loopNdx), quantifierRecurse);
            if (match && testPos === pos) {
              return match;
            } else if (testPos > pos) {
              break;
            }
          }
        }
      };

      let mergeLocators = (tests?) => {
        var locator = [];
        if (!InputMaskHelpers.isArray(tests)) tests = [tests];
        if (tests.length > 0) {
          if (tests[0].alternation === undefined) {
            locator = determineTestTemplate(tests.slice()).locator.slice();
            if (locator.length === 0) locator = tests[0].locator.slice();
          }
          else {
            InputMaskHelpers.each(tests, (ndx, tst) => {
              if (tst.def !== '') {
                if (locator.length === 0) locator = tst.locator.slice();
                else {
                  for (var i = 0; i < locator.length; i++) {
                    if (tst.locator[i] && locator[i].toString().indexOf(tst.locator[i]) === -1) {
                      locator[i] += ',' + tst.locator[i];
                    }
                  }
                }
              }
            });
          }
        }
        return locator;
      };

      let filterTests = (tests?) => {
        if (opts.keepStatic && pos > 0) {
          if (tests.length > 1 + (tests[tests.length - 1].match.def === '' ? 1 : 0)) {
            if (tests[0].match.optionality !== true &&
              tests[0].match.optionalQuantifier !== true &&
              tests[0].match.fn === null && !/[0-9a-bA-Z]/.test(tests[0].match.def)) {
              return [determineTestTemplate(tests)];
            }
          }
        }

        return tests;
      };

      if (pos > -1) {
        if (ndxIntlzr === undefined) { // determine index initializer
          var previousPos = pos - 1,
              test;
          while ((test = getMaskSet().validPositions[previousPos] || getMaskSet().tests[previousPos]) === undefined && previousPos > -1) {
            previousPos--;
          }
          if (test !== undefined && previousPos > -1) {
            ndxInitializer  = mergeLocators(test);
            cacheDependency = ndxInitializer.join('');
            testPos         = previousPos;
          }
        }
        if (getMaskSet().tests[pos] && getMaskSet().tests[pos][Object.keys(getMaskSet().tests[pos])[0]].cd === cacheDependency) { // cacheDependency is set on all tests, just check on the first
          //
          return filterTests(getMaskSet().tests[pos]);
        }
        for (var mtndx = ndxInitializer.shift(); mtndx < maskTokens.length; mtndx++) {
          var match = resolveTestFromToken(maskTokens[mtndx], ndxInitializer, [mtndx]);
          if ((match && testPos === pos) || testPos > pos) {
            break;
          }
        }
      }
      if (matches.length === 0 || insertStop) {
        matches.push({
          match:   {
            fn:          null,
            cardinality: 0,
            optionality: true,
            casing:      null,
            def:         '',
            placeholder: ''
          },
          locator: [],
          cd:      cacheDependency
        });
      }

      if (ndxIntlzr !== undefined && getMaskSet().tests[pos]) { // prioritize full tests for caching
        return filterTests(InputMaskHelpers.deepClone([], matches));
      }
      getMaskSet().tests[pos] = InputMaskHelpers.deepClone([], matches); // set a clone to prevent overwriting some props
      //
      return filterTests(getMaskSet().tests[pos]);
    };

    let getBufferTemplate = () => {
      if (getMaskSet()._buffer === undefined) {
        // generate template
        getMaskSet()._buffer = getMaskTemplate(false, 1);
        if (getMaskSet().buffer === undefined) {
          getMaskSet().buffer = getMaskSet()._buffer.slice();
        }
      }
      return getMaskSet()._buffer;
    };

    let getBuffer = (noCache?) => {
      if (getMaskSet().buffer === undefined || noCache === true) {
        getMaskSet().buffer = getMaskTemplate(true, getLastValidPosition(), true);
      }
      return getMaskSet().buffer;
    };

    let refreshFromBuffer = (start?, end?, buffer?) => {
      var i, p;
      if (start === true) {
        resetMaskSet();
        start = 0;
        end   = buffer.length;
      } else {
        for (i = start; i < end; i++) {
          delete getMaskSet().validPositions[i];
        }
      }
      p = start;
      for (i = start; i < end; i++) {
        resetMaskSet(true); // prevents clobber from the buffer
        if (buffer[i] !== opts.skipOptionalPartCharacter) {
          var valResult = isValid(p, buffer[i], true, true);
          if (valResult !== false) {
            resetMaskSet(true);
            p = valResult.caret !== undefined ? valResult.caret : valResult.pos + 1;
          }
        }
      }
    };

    let casing = (elem?, test?, pos?) => {
      switch (opts.casing || test.casing) {
        case 'upper':
          elem = elem.toUpperCase();
          break;
        case 'lower':
          elem = elem.toLowerCase();
          break;
        case 'title':
          var posBefore = getMaskSet().validPositions[pos - 1];
          if (pos === 0 || posBefore && posBefore.input === String.fromCharCode(InputMaskHelpers.keyCode.SPACE)) {
            elem = elem.toUpperCase();
          } else {
            elem = elem.toLowerCase();
          }
          break;
      }

      return elem;
    };

    let checkAlternationMatch = (altArr1?, altArr2?, na?) => {
      var altArrC                = opts.greedy ? altArr2 : altArr2.slice(0, 1),
          isMatch = false, naArr = na !== undefined ? na.split(',') : [],
          naNdx;

      // remove no alternate indexes from alternation array
      for (var i = 0; i < naArr.length; i++) {
        if ((naNdx = altArr1.indexOf(naArr[i])) !== -1)
          altArr1.splice(naNdx, 1);
      }

      for (var alndx = 0; alndx < altArr1.length; alndx++) {
        if (InputMaskHelpers.inArray(altArr1[alndx], altArrC) !== -1) {
          isMatch = true;
          break;
        }
      }
      return isMatch;
    };

    let isValid = (pos?, c?, strict?, fromSetValid?, fromAlternate?) => { // strict true ~ no correction or autofil=>l
      let isSelection = (posObj) => {
        var selection = isRTL ? (posObj.begin - posObj.end) > 1 || ((posObj.begin - posObj.end) === 1) :
          (posObj.end - posObj.begin) > 1 || ((posObj.end - posObj.begin) === 1);

        return selection && posObj.begin === 0 && posObj.end === getMaskSet().maskLength ? 'full' : selection;
      };

      strict = strict === true; // always set a value to strict to prevent possible strange behavior in the extensions

      var maskPos = pos;
      if (pos.begin !== undefined) { // position was a position object - used to handle a delete by typing over a selection
        maskPos = isRTL && !isSelection(pos) ? pos.end : pos.begin;
      }

      let _isValid = (position, c, strict) => {
        var rslt: any = false;
        InputMaskHelpers.each(getTests(position), (ndx, tst) => {
            var test    = tst.match,
                loopend = c ? 1 : 0,
                chrs    = '';
            for (var i = test.cardinality; i > loopend; i--) {
              chrs += getBufferElement(position - (i - 1));
            }
            if (c) {
              chrs += c;
            }

            // make sure the buffer is set and correct
            getBuffer(true);
            // return is false or a json object => { pos: ??, c: ??} or true
            rslt = test.fn != null ?
              test.fn.test(chrs, getMaskSet(), position, strict, opts, isSelection(pos)) : (c === test.def || c === opts.skipOptionalPartCharacter) && test.def !== '' ? // non mask
                {
                  c:   getPlaceholder(position, test, true) || test.def,
                  pos: position
                } : false;

            if (rslt !== false) {
              var elem = rslt.c !== undefined ? rslt.c : c;
              elem     = (elem === opts.skipOptionalPartCharacter && test.fn === null) ?
                (getPlaceholder(position, test, true) || test.def) : elem;

              var validatedPos           = position,
                  possibleModifiedBuffer = getBuffer();

              if (rslt.remove !== undefined) { // remove position(s)
                if (!InputMaskHelpers.isArray(rslt.remove)) rslt.remove = [rslt.remove];
                InputMaskHelpers.each(rslt.remove.sort((a, b) => {
                  return b - a;
                }), (ndx, lmnt) => {
                  stripValidPositions(lmnt, lmnt + 1, true);
                });
              }
              if (rslt.insert !== undefined) { // insert position(s)
                if (!InputMaskHelpers.isArray(rslt.insert)) rslt.insert = [rslt.insert];
                InputMaskHelpers.each(rslt.insert.sort((a, b) => {
                  return a - b;
                }), (ndx, lmnt) => {
                  isValid(lmnt.pos, lmnt.c, true, fromSetValid);
                });
              }

              if (rslt.refreshFromBuffer) {
                var refresh = rslt.refreshFromBuffer;
                strict      = true;
                refreshFromBuffer(refresh === true ? refresh : refresh.start, refresh.end, possibleModifiedBuffer);
                if (rslt.pos === undefined && rslt.c === undefined) {
                  rslt.pos = getLastValidPosition();
                  return false; // breakout if refreshFromBuffer && nothing to insert
                }
                validatedPos = rslt.pos !== undefined ? rslt.pos : position;
                if (validatedPos !== position) {
                  rslt = InputMaskHelpers.deepClone(rslt, isValid(validatedPos, elem, true, fromSetValid)); // revalidate new position strict
                  return false;
                }

              } else if (rslt !== true && rslt.pos !== undefined && rslt.pos !== position) { // their is a position offset
                validatedPos = rslt.pos;
                refreshFromBuffer(position, validatedPos, getBuffer().slice());
                if (validatedPos !== position) {
                  rslt = InputMaskHelpers.deepClone(rslt, isValid(validatedPos, elem, true)); // revalidate new position strict
                  return false;
                }
              }

              if (rslt !== true && rslt.pos === undefined && rslt.c === undefined) {
                return false; // breakout if nothing to insert
              }

              if (ndx > 0) {
                resetMaskSet(true);
              }

              if (!setValidPosition(validatedPos, InputMaskHelpers.deepClone({}, tst, {
                'input': casing(elem, test, validatedPos)
              }), fromSetValid, isSelection(pos))) {
                rslt = false;
              }
              return false; // break from InputMaskHelpers.each
            }
          }
        );
        return rslt;
      };

      let alternate = (pos?, c?, strict?) => {
        var validPsClone                             = InputMaskHelpers.deepClone({}, getMaskSet().validPositions),
            lastAlt,
            alternation,
            isValidRslt                              = false,
            altPos, prevAltPos, i, validPos, lAltPos = getLastValidPosition(), altNdxs, decisionPos;
        // find last modified alternation
        prevAltPos                                   = getMaskSet().validPositions[lAltPos];
        for (; lAltPos >= 0; lAltPos--) {
          altPos = getMaskSet().validPositions[lAltPos];
          if (altPos && altPos.alternation !== undefined) {
            lastAlt     = lAltPos;
            alternation = getMaskSet().validPositions[lastAlt].alternation;
            if (prevAltPos.locator[altPos.alternation] !== altPos.locator[altPos.alternation]) {
              break;
            }
            prevAltPos = altPos;
          }
        }
        if (alternation !== undefined) {
          decisionPos       = parseInt(lastAlt);
          var decisionTaker = prevAltPos.locator[prevAltPos.alternation || alternation] !== undefined ? prevAltPos.locator[prevAltPos.alternation || alternation] : altNdxs[0]; // no match in the alternations (length mismatch)
          if (decisionTaker.length > 0) { // no decision taken ~ take first one as decider
            decisionTaker = decisionTaker.split(',')[0];
          }
          var possibilityPos = getMaskSet().validPositions[decisionPos], prevPos = getMaskSet().validPositions[decisionPos - 1];
          InputMaskHelpers.each(getTests(decisionPos, prevPos ? prevPos.locator : undefined, decisionPos - 1), (ndx, test) => {
            altNdxs = test.locator[alternation] ? test.locator[alternation].toString().split(',') : [];
            for (var mndx = 0; mndx < altNdxs.length; mndx++) {
              var validInputs                    = [],
                  staticInputsBeforePos          = 0,
                  staticInputsBeforePosAlternate = 0,
                  verifyValidInput               = false;
              if (decisionTaker < altNdxs[mndx] && (test.na === undefined || InputMaskHelpers.inArray(altNdxs[mndx], test.na.split(',')) === -1)) {
                getMaskSet().validPositions[decisionPos]                      = InputMaskHelpers.deepClone({}, test);
                var possibilities                                             = getMaskSet().validPositions[decisionPos].locator;
                getMaskSet().validPositions[decisionPos].locator[alternation] = parseInt(altNdxs[mndx]); // set forced decision
                if (test.match.fn == null) {
                  if (possibilityPos.input !== test.match.def) {
                    verifyValidInput = true; // verify that the new definition matches the input
                    if (possibilityPos.generatedInput !== true) {
                      validInputs.push(possibilityPos.input);
                    }
                  }
                  staticInputsBeforePosAlternate++;
                  getMaskSet().validPositions[decisionPos].generatedInput = !/[0-9a-bA-Z]/.test(test.match.def);
                  getMaskSet().validPositions[decisionPos].input          = test.match.def;
                } else {
                  getMaskSet().validPositions[decisionPos].input = possibilityPos.input;
                }
                for (i = decisionPos + 1; i < getLastValidPosition(undefined, true) + 1; i++) {
                  validPos = getMaskSet().validPositions[i];
                  if (validPos && validPos.generatedInput !== true && /[0-9a-bA-Z]/.test(validPos.input)) {
                    validInputs.push(validPos.input);
                  } else if (i < pos) staticInputsBeforePos++;
                  delete getMaskSet().validPositions[i];
                }
                if (verifyValidInput && validInputs[0] === test.match.def) {
                  validInputs.shift();
                }
                resetMaskSet(true); // clear getbuffer
                isValidRslt = true;
                while (validInputs.length > 0) {
                  var input = validInputs.shift();
                  if (input !== opts.skipOptionalPartCharacter) {
                    if (!(isValidRslt = isValid(getLastValidPosition(undefined, true) + 1, input, false, fromSetValid, true))) {
                      break;
                    }
                  }
                }

                if (isValidRslt) {
                  getMaskSet().validPositions[decisionPos].locator = possibilities; // reset forceddecision ~ needed for proper delete
                  var targetLvp                                    = getLastValidPosition(pos) + 1;
                  for (i = decisionPos + 1; i < getLastValidPosition() + 1; i++) {
                    validPos = getMaskSet().validPositions[i];
                    if ((validPos === undefined || validPos.match.fn == null) && i < (pos + (staticInputsBeforePosAlternate - staticInputsBeforePos))) {
                      staticInputsBeforePosAlternate++;
                    }
                  }
                  pos         = pos + (staticInputsBeforePosAlternate - staticInputsBeforePos);
                  isValidRslt = isValid(pos > targetLvp ? targetLvp : pos, c, strict, fromSetValid, true);
                }
                if (!isValidRslt) {
                  resetMaskSet();
                  getMaskSet().validPositions = InputMaskHelpers.deepClone({}, validPsClone);
                } else return false; // exit InputMaskHelpers.each
              }
            }
          });
        }

        return isValidRslt;
      };

      // set alternator choice on previous skipped placeholder positions
      let trackbackAlternations = (originalPos?, newPos?) => {
        var vp = getMaskSet().validPositions[newPos];
        if (vp) {
          var targetLocator = vp.locator,
              tll           = targetLocator.length;

          for (var ps = originalPos; ps < newPos; ps++) {
            if (getMaskSet().validPositions[ps] === undefined && !isMask(ps, true)) {
              var tests     = getTests(ps).slice(),
                  bestMatch = determineTestTemplate(tests, true),
                  equality  = -1;
              if (tests[tests.length - 1].match.def === '') tests.pop();
              InputMaskHelpers.each(tests, (ndx, tst) => { // find best matching
                for (var i = 0; i < tll; i++) {
                  if (tst.locator[i] !== undefined && checkAlternationMatch(tst.locator[i].toString().split(','), targetLocator[i].toString().split(','), tst.na)) {
                    if (equality < i) {
                      equality  = i;
                      bestMatch = tst;
                    }
                  } else {
                    // check if alternationIndex is closer then the current bestmatch
                    var targetAI    = targetLocator[i],
                        bestMatchAI = bestMatch.locator[i],
                        tstAI       = tst.locator[i];
                    if ((targetAI - bestMatchAI) > Math.abs(targetAI - tstAI)) {
                      bestMatch = tst;
                    }
                    break;
                  }
                }
              });
              bestMatch                = InputMaskHelpers.deepClone({}, bestMatch, {
                'input': getPlaceholder(ps, bestMatch.match, true) || bestMatch.match.def
              });
              bestMatch.generatedInput = true;
              setValidPosition(ps, bestMatch, true);
              // revalidate the new position to update the locator value
              getMaskSet().validPositions[newPos] = undefined;
              _isValid(newPos, vp.input, true);
            }
          }
        }
      };

      let setValidPosition = (pos?, validTest?, fromSetValid?, isSelection?) => {
        if (isSelection || (opts.insertMode && getMaskSet().validPositions[pos] !== undefined && fromSetValid === undefined)) {
          // reposition & revalidate others
          var positionsClone = InputMaskHelpers.deepClone({}, getMaskSet().validPositions),
              lvp            = getLastValidPosition(undefined, true),
              i;
          for (i = pos; i <= lvp; i++) { // clear selection
            delete getMaskSet().validPositions[i];
          }
          getMaskSet().validPositions[pos]                          = InputMaskHelpers.deepClone({}, validTest);
          let valid                                                 = true,
              j, vps = getMaskSet().validPositions, needsValidation = false,
              initialLength                                         = getMaskSet().maskLength;
          for (i = (j = pos); i <= lvp; i++) {
            let t = positionsClone[i];
            if (t !== undefined /*&& (t.generatedInput !== true || t.match.fn === null)*/) {
              let posMatch = j;
              while (posMatch < getMaskSet().maskLength && ((t.match.fn === null && vps[i] && (vps[i].match.optionalQuantifier === true || vps[i].match.optionality === true)) || t.match.fn != null)) {
                posMatch++;
                if (needsValidation === false && positionsClone[posMatch] && positionsClone[posMatch].match.def === t.match.def) { // obvious match
                  getMaskSet().validPositions[posMatch]       = InputMaskHelpers.deepClone({}, positionsClone[posMatch]);
                  getMaskSet().validPositions[posMatch].input = t.input;
                  fillMissingNonMask(posMatch);
                  j     = posMatch;
                  valid = true;
                } else if (positionCanMatchDefinition(posMatch, t.match.def)) { // validated match
                  let result: any = isValid(posMatch, t.input, true, true);
                  valid           = result !== false;
                  j               = (result.caret || result.insert) ? getLastValidPosition() : posMatch;
                  needsValidation = true;
                } else {
                  valid = t.generatedInput === true;
                  if (!valid && posMatch >= getMaskSet().maskLength - 1) break;
                }
                if (getMaskSet().maskLength < initialLength) getMaskSet().maskLength = initialLength; // a bit hacky but the masklength is corrected later on
                if (valid) break;
              }
            }
            if (!valid) break;
          }

          if (!valid) {
            getMaskSet().validPositions = InputMaskHelpers.deepClone({}, positionsClone);
            resetMaskSet(true);
            return false;
          }
        }

        else
          getMaskSet().validPositions[pos] = InputMaskHelpers.deepClone({}, validTest);
        ;

        resetMaskSet(true);
        return true;
      };

      let fillMissingNonMask = (maskPos?) => {
        // Check for a nonmask before the pos
        // find previous valid
        for (var pndx = maskPos - 1; pndx > -1; pndx--) {
          if (getMaskSet().validPositions[pndx]) break;
        }
        // // fill missing nonmask and valid placeholders
        var testTemplate, testsFromPos;
        for (pndx++; pndx < maskPos; pndx++) {
          if (getMaskSet().validPositions[pndx] === undefined && (opts.jitMasking === false || opts.jitMasking > pndx)) {
            testsFromPos = getTests(pndx, getTestTemplate(pndx - 1).locator, pndx - 1).slice();
            if (testsFromPos[testsFromPos.length - 1].match.def === '') testsFromPos.pop();
            testTemplate = determineTestTemplate(testsFromPos);
            if (testTemplate && (testTemplate.match.def === opts.radixPointDefinitionSymbol || !isMask(pndx, true) ||
              (InputMaskHelpers.inArray(this.radixPoint, getBuffer()) < pndx && testTemplate.match.fn && testTemplate.match.fn.test(getPlaceholder(pndx), getMaskSet(), pndx, false, opts)))) {
              result = _isValid(pndx, getPlaceholder(pndx, testTemplate.match, true) || (testTemplate.match.fn == null ? testTemplate.match.def : (getPlaceholder(pndx) !== '' ? getPlaceholder(pndx) : getBuffer()[pndx])), true);
              if (result !== false) {
                getMaskSet().validPositions[result.pos || pndx].generatedInput = true;
              }
            }
          }
        }
      };

      let result: any    = true,
          positionsClone = InputMaskHelpers.deepClone({}, getMaskSet().validPositions); // clone the currentPositions

      if (InputMaskHelpers.isFunction(opts.preValidation) && !strict && fromSetValid !== true) {
        result = opts.preValidation(getBuffer(), maskPos, c, isSelection(pos), opts);
      }

      if (result === true) {
        fillMissingNonMask(maskPos);

        if (isSelection(pos)) {
          handleRemove(undefined, InputMaskHelpers.keyCode.DELETE, pos);
          maskPos = getMaskSet().p;
        }

        if (maskPos < getMaskSet().maskLength) {
          result = _isValid(maskPos, c, strict);
          if ((!strict || fromSetValid === true) && result === false) {
            var currentPosValid = getMaskSet().validPositions[maskPos];
            if (currentPosValid && currentPosValid.match.fn === null && (currentPosValid.match.def === c || c === opts.skipOptionalPartCharacter)) {
              result = {
                'caret': seekNext(maskPos)
              };
            } else if ((opts.insertMode || getMaskSet().validPositions[seekNext(maskPos)] === undefined) && !isMask(maskPos, true)) { // does the input match on a further position?
              for (var nPos = maskPos + 1, snPos = seekNext(maskPos); nPos <= snPos; nPos++) {
                result = _isValid(nPos, c, strict);
                if (result !== false) {
                  trackbackAlternations(maskPos, result.pos !== undefined ? result.pos : nPos);
                  maskPos = nPos;
                  break;
                }
              }
            }
          }
        }
        if (result === false && opts.keepStatic && !strict && fromAlternate !== true) { // try fuzzy alternator logic
          result = alternate(maskPos, c, strict);
        }
        if (result === true) {
          result = {
            'pos': maskPos
          };
        }
      }
      if (InputMaskHelpers.isFunction(opts.postValidation) && result !== false && !strict && fromSetValid !== true) {
        var postResult = opts.postValidation(getBuffer(true), result, opts);
        if (postResult.refreshFromBuffer && postResult.buffer) {
          var refresh = postResult.refreshFromBuffer;
          refreshFromBuffer(refresh === true ? refresh : refresh.start, refresh.end, postResult.buffer);
        }
        result = postResult === true ? result : postResult;
      }

      if (result.pos === undefined) {
        if (typeof result === 'boolean') {
          // result = {
          //     "pos": maskPos
          // };
        } else {
          result.pos = maskPos;
        }
      }

      if (result === false) {
        resetMaskSet(true);
        getMaskSet().validPositions = InputMaskHelpers.deepClone({}, positionsClone); // revert validation changes
      }

      return result;
    };

    let isMask = (pos?, strict?) => {
      var test = getTestTemplate(pos).match;
      if (test.def === '') test = getTest(pos).match;

      if (test.fn != null) {
        return test.fn;
      } else if (strict !== true && pos > -1) {
        var tests = getTests(pos);
        return tests.length > 1 + (tests[tests.length - 1].match.def === '' ? 1 : 0);
      }
      return false;
    };

    let seekNext = (pos?, newBlock?) => {
      var maskL = getMaskSet().maskLength;
      if (pos >= maskL) return maskL;
      var position = pos;
      while (++position < maskL &&
      ((newBlock === true && (getTest(position).match.newBlockMarker !== true || !isMask(position))) ||
        (newBlock !== true && !isMask(position)))) {
      }
      return position;
    };

    let seekPrevious = (pos?, newBlock?) => {
      var position = pos, tests;
      if (position <= 0) return 0;

      while (--position > 0 &&
      ((newBlock === true && getTest(position).match.newBlockMarker !== true) ||
        (newBlock !== true && !isMask(position) &&
          (tests = getTests(position), tests.length < 2 || (tests.length === 2 && tests[1].match.def === ''))))) {
      }

      return position;
    };

    let getBufferElement = (position?) => {
      return getMaskSet().validPositions[position] === undefined ? getPlaceholder(position) : getMaskSet().validPositions[position].input;
    };

    let writeBuffer = (input, buffer, caretPos?, event?, triggerInputEvent?) => {


      if (event && InputMaskHelpers.isFunction(opts.onBeforeWrite)) {
        var result = opts.onBeforeWrite(event, buffer, caretPos, opts);
        if (result) {
          if (result.refreshFromBuffer) {
            var refresh = result.refreshFromBuffer;
            refreshFromBuffer(refresh === true ? refresh : refresh.start, refresh.end, result.buffer || buffer);
            buffer = getBuffer(true);
          }
          // only alter when intented !== undefined
          if (caretPos !== undefined) caretPos = result.caret !== undefined ? result.caret : caretPos;
        }
      }
      this._valueSet(buffer.join(''));
      this.onModelChange && this.onModelChange(this.unmaskedvalue());
      if (caretPos !== undefined && (event === undefined || event.type !== 'blur')) {
        if (this.android && event.type === 'input') {
          setTimeout(function () {
            caret(input, caretPos);
          }, 0);
        } else caret(input, caretPos);
      } else renderColorMask(input, buffer, caretPos);
      if (triggerInputEvent === true) {
        skipInputEvent = true;

        // setTimeout(() => {
        this.zone.runOutsideAngular(() => {
          this.trigger(this.elementRef.nativeElement, 'input');
          // let ev = new Event('input');
          // this.invokeElementMethod(this.elementRef.nativeElement, 'dispatchEvent', [ev]);
        });
        // }, 0);

        // this.trigger(this.elementRef.nativeElement,"input");
      }


    };

    let getPlaceholder = (pos, test?, returnPL?) => {
      test = test || getTest(pos).match;
      if (test.placeholder !== undefined || returnPL === true) {
        return InputMaskHelpers.isFunction(test.placeholder) ? test.placeholder(opts) : test.placeholder;
      } else if (test.fn === null) {
        if (pos > -1 && getMaskSet().validPositions[pos] === undefined) {
          var tests              = getTests(pos),
              staticAlternations = [],
              prevTest;
          if (tests.length > 1 + (tests[tests.length - 1].match.def === '' ? 1 : 0)) {
            for (var i = 0; i < tests.length; i++) {
              if (tests[i].match.optionality !== true && tests[i].match.optionalQuantifier !== true &&
                (tests[i].match.fn === null || (prevTest === undefined || tests[i].match.fn.test(prevTest.match.def, getMaskSet(), pos, true, opts) !== false))) {
                staticAlternations.push(tests[i]);
                if (tests[i].match.fn === null) prevTest = tests[i];
                if (staticAlternations.length > 1) {
                  if (/[0-9a-bA-Z]/.test(staticAlternations[0].match.def)) {
                    return opts.placeholder.charAt(pos % opts.placeholder.length);
                  }
                }
              }
            }
          }
        }
        return test.def;
      }

      return opts.placeholder.charAt(pos % opts.placeholder.length);
    };

    let checkVal = (input, writeOut, strict, nptvl, initiatingEvent?, stickyCaret?) => {
      var inputValue             = nptvl.slice(),
          charCodes              = '',
          initialNdx = 0, result = undefined;

      let isTemplateMatch = (ndx, charCodes) => {
        var charCodeNdx = getBufferTemplate().slice(ndx, seekNext(ndx)).join('').indexOf(charCodes);
        return charCodeNdx !== -1 && !isMask(ndx) && getTest(ndx).match.nativeDef === charCodes.charAt(charCodes.length - 1);
      };

      resetMaskSet();
      getMaskSet().p = seekNext(-1);
      // if (writeOut) this._valueSet(""); // initial clear

      if (!strict) {
        if (opts.autoUnmask !== true) {
          var staticInput = getBufferTemplate().slice(0, seekNext(-1)).join(''),
              matches     = inputValue.join('').match(new RegExp('^' + InputMaskHelpers.escapeRegex(staticInput), 'g'));
          if (matches && matches.length > 0) {
            inputValue.splice(0, matches.length * staticInput.length);
            initialNdx = seekNext(initialNdx);
          }
        } else {
          initialNdx = seekNext(initialNdx);
        }
      }
      InputMaskHelpers.each(inputValue, (ndx, charCode) => {
        if (charCode !== undefined) { // inputfallback strips some elements out of the inputarray.  InputMaskHelpers.each logically presents them as undefined
          var keypress: any = new Event('_checkval');
          keypress.which    = charCode.charCodeAt(0);
          charCodes += charCode;
          var lvp           = getLastValidPosition(undefined, true),
              lvTest        = getMaskSet().validPositions[lvp],
              nextTest      = getTestTemplate(lvp + 1, lvTest ? lvTest.locator.slice() : undefined, lvp);
          if (!isTemplateMatch(initialNdx, charCodes) || strict || opts.autoUnmask) {
            var pos    = strict ? ndx : (nextTest.match.fn == null && nextTest.match.optionality && (lvp + 1) < getMaskSet().p ? lvp + 1 : getMaskSet().p);
            result     = this.EventHandlers.keypressEvent.call(input, keypress, true, false, strict, pos);
            initialNdx = pos + 1;
            charCodes  = '';
          } else {
            result = this.EventHandlers.keypressEvent.call(input, keypress, true, false, true, lvp + 1);
          }
          if (!strict && InputMaskHelpers.isFunction(opts.onBeforeWrite)) {
            result = opts.onBeforeWrite(keypress, getBuffer(), result.forwardPosition, opts);
            if (result && result.refreshFromBuffer) {
              var refresh = result.refreshFromBuffer;
              refreshFromBuffer(refresh === true ? refresh : refresh.start, refresh.end, result.buffer);
              resetMaskSet(true);
              if (result.caret) {
                getMaskSet().p = result.caret;
              }
            }
          }
        }
      });
      if (writeOut) {
        var caretPos = undefined, lvp = getLastValidPosition();
        if (document.activeElement === input && (initiatingEvent || result)) {
          caretPos = caret(input).begin;
          if (initiatingEvent && result === false) caretPos = seekNext(getLastValidPosition(caretPos));
          if (result && stickyCaret !== true && (caretPos < lvp + 1 || lvp === -1))
            caretPos = (opts.numericInput && result.caret === undefined) ? seekPrevious(result.forwardPosition) : result.forwardPosition;
        }
        writeBuffer(input, getBuffer(), caretPos, initiatingEvent || new Event('checkval'));
      }
    };

    let unmaskedvalue = (input?) => {
      if (input) {

        if (this.refreshValue) { // forced refresh from the value form.reset
          this.EventHandlers.setValueEvent.call(input);
        }
      }
      var umValue = [],
          vps     = getMaskSet().validPositions;
      for (var pndx in vps) {
        if (vps[pndx].match && vps[pndx].match.fn != null) {
          umValue.push(vps[pndx].input);
        }
      }
      var unmaskedValue = umValue.length === 0 ? '' : (isRTL ? umValue.reverse() : umValue).join('');
      if (InputMaskHelpers.isFunction(opts.onUnMask)) {
        var bufferValue = (isRTL ? getBuffer().slice().reverse() : getBuffer()).join('');
        unmaskedValue   = opts.onUnMask(bufferValue, unmaskedValue, opts);
      }
      return unmaskedValue;
    };

    let caret = (input, begin?, end?, notranslate?) => {
      let translatePosition = (pos) => {
        if (notranslate !== true && isRTL && typeof pos === 'number' && (!opts.greedy || opts.placeholder !== '')) {
          var bffrLght = getBuffer().join('').length; // join is needed because sometimes we get an empty buffer element which must not be counted for the caret position (numeric alias)
          pos          = bffrLght - pos;
        }
        return pos;
      };

      var range;
      if (typeof begin === 'number') {
        begin = translatePosition(begin);
        end   = translatePosition(end);
        end   = (typeof end == 'number') ? end : begin;
        // if (!$(input).is(":visible")) {
        // 	return;
        // }

        var scrollCalc   = parseInt(((input.ownerDocument.defaultView || window).getComputedStyle ? (input.ownerDocument.defaultView || window).getComputedStyle(input, null) : input.currentStyle).fontSize) * end;
        input.scrollLeft = scrollCalc > input.scrollWidth ? scrollCalc : 0;

        if (!this.mobile && opts.insertMode === false && begin === end) end++; // set visualization for insert/overwrite mode
        if (input.setSelectionRange) {
          input.selectionStart = begin;
          input.selectionEnd   = end;
        } else if (window.getSelection) {
          range = document.createRange();
          if (input.firstChild === undefined || input.firstChild === null) {
            var textNode = document.createTextNode('');
            input.appendChild(textNode);
          }
          range.setStart(input.firstChild, begin < this._valueGet().length ? begin : this._valueGet().length);
          range.setEnd(input.firstChild, end < this._valueGet().length ? end : this._valueGet().length);
          range.collapse(true);
          var sel = window.getSelection();
          sel.removeAllRanges();
          sel.addRange(range);
          // input.focus();
        } else if (input.createTextRange) {
          range = input.createTextRange();
          range.collapse(true);
          range.moveEnd('character', end);
          range.moveStart('character', begin);
          range.select();

        }
        renderColorMask(input, undefined, {begin: begin, end: end});
      } else {
        if (input.setSelectionRange) {
          begin = input.selectionStart;
          end   = input.selectionEnd;
        } else if (window.getSelection) {
          range = window.getSelection().getRangeAt(0);
          if (range.commonAncestorContainer.parentNode === input || range.commonAncestorContainer === input) {
            begin = range.startOffset;
            end   = range.endOffset;
          }
        } else if ((<any>document).selection && (<any>document).selection.createRange) {
          range = (<any>document).selection.createRange();
          begin = 0 - range.duplicate().moveStart('character', -this._valueGet().length);
          end   = begin + range.text.length;
        }
        /*eslint-disable consistent-return */
        return {
          'begin': translatePosition(begin),
          'end':   translatePosition(end)
        };
        /*eslint-enable consistent-return */
      }
    };

    let determineLastRequiredPosition = (returnDefinition?) => {
      var buffer    = getBuffer(),
          bl        = buffer.length,
          pos, lvp  = getLastValidPosition(),
          positions = {},
          lvTest    = getMaskSet().validPositions[lvp],
          ndxIntlzr = lvTest !== undefined ? lvTest.locator.slice() : undefined,
          testPos;
      for (pos = lvp + 1; pos < buffer.length; pos++) {
        testPos        = getTestTemplate(pos, ndxIntlzr, pos - 1);
        ndxIntlzr      = testPos.locator.slice();
        positions[pos] = InputMaskHelpers.deepClone({}, testPos);
      }

      var lvTestAlt = lvTest && lvTest.alternation !== undefined ? lvTest.locator[lvTest.alternation] : undefined;
      for (pos = bl - 1; pos > lvp; pos--) {
        testPos = positions[pos];
        if ((testPos.match.optionality ||
          testPos.match.optionalQuantifier ||
          (lvTestAlt &&
            ((lvTestAlt !== positions[pos].locator[lvTest.alternation] && testPos.match.fn != null) ||
              (testPos.match.fn === null && testPos.locator[lvTest.alternation] && checkAlternationMatch(testPos.locator[lvTest.alternation].toString().split(','), lvTestAlt.toString().split(',')) && getTests(pos)[0].def !== '')))) &&
          buffer[pos] === getPlaceholder(pos, testPos.match)) {
          bl--;
        } else break;
      }
      return returnDefinition ? {
        'l':   bl,
        'def': positions[bl] ? positions[bl].match : undefined
      } : bl;
    };

    let clearOptionalTail = (buffer?) => {
      var rl           = determineLastRequiredPosition(),
          validPos, bl = buffer.length;

      while (rl < bl && !isMask(rl + 1) && (validPos = getTest(rl + 1)) && validPos.match.optionality !== true && validPos.match.optionalQuantifier !== true) {
        rl++;
      }

      while ((validPos = getTest(rl - 1)) && validPos.match.optionality && validPos.input === opts.skipOptionalPartCharacter) {
        rl--;
      }
      buffer.splice(rl);
      return buffer;
    };

    let isComplete   = (buffer?) => { // return true / false / undefined (repeat *)
      if (InputMaskHelpers.isFunction(opts.isComplete)) return opts.isComplete(buffer, opts);
      if (opts.repeat === '*') return undefined;
      var complete = false,
          lrp      = determineLastRequiredPosition(true),
          aml      = seekPrevious(lrp.l);

      if (lrp.def === undefined || lrp.def.newBlockMarker || lrp.def.optionality || lrp.def.optionalQuantifier) {
        complete = true;
        for (var i = 0; i <= aml; i++) {
          var test = getTestTemplate(i).match;
          if ((test.fn !== null && getMaskSet().validPositions[i] === undefined && test.optionality !== true && test.optionalQuantifier !== true) || (test.fn === null && buffer[i] !== getPlaceholder(i, test))) {
            complete = false;
            break;
          }
        }
      }
      return complete;
    };
    let handleRemove = (input?, k?, pos?, strict?) => {
      let generalize = () => {
        if (opts.keepStatic) {
          var validInputs                                              = [],
              lastAlt = getLastValidPosition(-1, true), positionsClone = InputMaskHelpers.deepClone({}, getMaskSet().validPositions),
              prevAltPos                                               = getMaskSet().validPositions[lastAlt];
          // find last alternation
          for (; lastAlt >= 0; lastAlt--) {
            var altPos = getMaskSet().validPositions[lastAlt];
            if (altPos) {
              if (altPos.generatedInput !== true && /[0-9a-bA-Z]/.test(altPos.input)) {
                validInputs.push(altPos.input);
              }
              delete getMaskSet().validPositions[lastAlt];
              if (altPos.alternation !== undefined && altPos.locator[altPos.alternation] !== prevAltPos.locator[altPos.alternation]) {
                break;
              }
              prevAltPos = altPos;
            }
          }

          if (lastAlt > -1) {
            getMaskSet().p = seekNext(getLastValidPosition(-1, true));
            while (validInputs.length > 0) {
              var keypress: any = new Event('keypress');
              keypress.which    = validInputs.pop().charCodeAt(0);
              this.EventHandlers.keypressEvent.call(input, keypress, true, false, false, getMaskSet().p);
            }
          } else getMaskSet().validPositions = InputMaskHelpers.deepClone({}, positionsClone); // restore original positions
        }
      };

      if (opts.numericInput || isRTL) {
        if (k === InputMaskHelpers.keyCode.BACKSPACE) {
          k = InputMaskHelpers.keyCode.DELETE;
        } else if (k === InputMaskHelpers.keyCode.DELETE) {
          k = InputMaskHelpers.keyCode.BACKSPACE;
        }

        if (isRTL) {
          var pend  = pos.end;
          pos.end   = pos.begin;
          pos.begin = pend;
        }
      }

      if (k === InputMaskHelpers.keyCode.BACKSPACE && (pos.end - pos.begin < 1 || opts.insertMode === false)) {
        pos.begin = seekPrevious(pos.begin);
        if (getMaskSet().validPositions[pos.begin] !== undefined && (getMaskSet().validPositions[pos.begin].input === this.groupSeparator || getMaskSet().validPositions[pos.begin].input === this.radixPoint)) {
          pos.begin--;
        }
      } else if (k === InputMaskHelpers.keyCode.DELETE && pos.begin === pos.end) {
        pos.end = isMask(pos.end, true) ? pos.end + 1 : seekNext(pos.end) + 1;
        if (getMaskSet().validPositions[pos.begin] !== undefined && (getMaskSet().validPositions[pos.begin].input === this.groupSeparator || getMaskSet().validPositions[pos.begin].input === this.radixPoint)) {
          pos.end++;
        }
      }

      stripValidPositions(pos.begin, pos.end, false, strict);
      if (strict !== true) {
        generalize(); // revert the alternation
      }
      var lvp = getLastValidPosition(pos.begin, true);
      if (lvp < pos.begin) {
        // if (lvp === -1) resetMaskSet();
        getMaskSet().p = seekNext(lvp);
      } else if (strict !== true) {
        getMaskSet().p = pos.begin;
      }
    };

    var EventRuler          = {
      on:  (input, eventName, eventHandler) => {
        var ev                 = (...args) => {
          let e = args[0];
          //

          if (e.type !== 'setvalue' && (this.elementRef.nativeElement.disabled || (this.elementRef.nativeElement.readOnly && !(e.type === 'keydown' && (e.ctrlKey && e.keyCode === 67) || (opts.tabThrough === false && e.keyCode === InputMaskHelpers.keyCode.TAB))))) {
            e.preventDefault();
          } else {
            switch (e.type) {
              case 'input':
                if (skipInputEvent === true) {
                  skipInputEvent = false;
                  return e.preventDefault();
                }
                break;
              case 'keydown':
                // Safari 5.1.x - modal dialog fires keypress twice workaround
                skipKeyPressEvent = false;
                skipInputEvent    = false;
                break;
              case 'keypress':
                if (skipKeyPressEvent === true) {
                  return e.preventDefault();
                }
                skipKeyPressEvent = true;
                break;
              case 'click':
                if (this.iemobile || this.iphone) {
                  setTimeout(() => {
                    eventHandler.apply(this.elementRef.nativeElement, args);
                  }, 0);
                  return false;
                }
                break;
            }
            //
            var returnVal = eventHandler.apply(this.elementRef.nativeElement, args);
            if (returnVal === false) {
              e.preventDefault();
              e.stopPropagation();
            }
            return returnVal;
          }
        };
        // keep instance of the event
        this.events[eventName] = this.events[eventName] || [];
        this.events[eventName].push(ev);

        // $(input).on(eventName, ev);
        InputMaskHelpers.on(input, eventName, ev);
      },
      off: (input?, event?) => {
        if (this.events) {
          var events;
          if (event) {
            events        = [];
            events[event] = this.events[event];
          } else {
            events = this.events;
          }
          InputMaskHelpers.each(events, (eventName, evArr) => {
            while (evArr.length > 0) {
              var ev = evArr.pop();

              // $(input).off(eventName, ev);
              InputMaskHelpers.off(input, eventName, ev);

            }
            delete this.events[eventName];
          });
        }
      }
    };
    this.EventHandlers      = {
      keydownEvent:       (e) => {


        let isInputEventSupported = (eventName) => {
          var el          = document.createElement('input'),
              evName      = 'on' + eventName,
              isSupported = (evName in el);
          if (!isSupported) {
            el.setAttribute(evName, 'return;');
            isSupported = typeof el[evName] == 'function';
          }
          el = null;
          return isSupported;
        };

        var input = this.elementRef.nativeElement,
            // $input = $(input),
            k     = e.keyCode,
            pos   = caret(input);

        // backspace, delete, and escape get special treatment
        if (k === InputMaskHelpers.keyCode.BACKSPACE || k === InputMaskHelpers.keyCode.DELETE || (this.iphone && k === InputMaskHelpers.keyCode.BACKSPACE_SAFARI) || (e.ctrlKey && k === InputMaskHelpers.keyCode.X && !isInputEventSupported('cut'))) { // backspace/delete
          e.preventDefault(); // stop default action but allow propagation
          handleRemove(input, k, pos);
          writeBuffer(input, getBuffer(true), getMaskSet().p, e, this._valueGet() !== getBuffer().join(''));
          if (this._valueGet() === getBufferTemplate().join('')) {
            this.trigger(this.elementRef.nativeElement, 'cleared');
          } else if (isComplete(getBuffer()) === true) {
            this.trigger(this.elementRef.nativeElement, 'complete');
          }
        } else if (k === InputMaskHelpers.keyCode.END || k === InputMaskHelpers.keyCode.PAGE_DOWN) { // when END or PAGE_DOWN pressed set position at lastmatch
          e.preventDefault();
          var caretPos = seekNext(getLastValidPosition());
          if (!opts.insertMode && caretPos === getMaskSet().maskLength && !e.shiftKey) caretPos--;
          caret(input, e.shiftKey ? pos.begin : caretPos, caretPos, true);
        } else if ((k === InputMaskHelpers.keyCode.HOME && !e.shiftKey) || k === InputMaskHelpers.keyCode.PAGE_UP) { // Home or page_up
          e.preventDefault();
          caret(input, 0, e.shiftKey ? pos.begin : 0, true);
        } else if (((opts.undoOnEscape && k === InputMaskHelpers.keyCode.ESCAPE) || (k === 90 && e.ctrlKey)) && e.altKey !== true) { // escape && undo && #762
          checkVal(input, true, false, undoValue.split(''));
          this.trigger(this.elementRef.nativeElement, 'click');
        } else if (k === InputMaskHelpers.keyCode.INSERT && !(e.shiftKey || e.ctrlKey)) { // insert
          opts.insertMode = !opts.insertMode;
          caret(input, !opts.insertMode && pos.begin === getMaskSet().maskLength ? pos.begin - 1 : pos.begin);
        } else if (opts.tabThrough === true && k === InputMaskHelpers.keyCode.TAB) {
          if (e.shiftKey === true) {
            if (getTest(pos.begin).match.fn === null) {
              pos.begin = seekNext(pos.begin);
            }
            pos.end   = seekPrevious(pos.begin, true);
            pos.begin = seekPrevious(pos.end, true);
          } else {
            pos.begin = seekNext(pos.begin, true);
            pos.end   = seekNext(pos.begin, true);
            if (pos.end < getMaskSet().maskLength) pos.end--;
          }
          if (pos.begin < getMaskSet().maskLength) {
            e.preventDefault();
            caret(input, pos.begin, pos.end);
          }
        } else if (!e.shiftKey) {
          if (opts.insertMode === false) {
            if (k === InputMaskHelpers.keyCode.RIGHT) {
              setTimeout(() => {
                var caretPos = caret(input);
                caret(input, caretPos.begin);
              }, 0);
            } else if (k === InputMaskHelpers.keyCode.LEFT) {
              setTimeout(() => {
                var caretPos = caret(input);
                caret(input, isRTL ? caretPos.begin + 1 : caretPos.begin - 1);
              }, 0);
            }
          }
        }
        opts.onKeyDown.call(this.elementRef.nativeElement, e, getBuffer(), caret(input).begin, opts, this.unmaskedvalue(), this.EventHandlers.setValueEvent);
        ignorable = InputMaskHelpers.inArray(k, opts.ignorables) !== -1;


      },
      keypressEvent:      (e, checkval, writeOut, strict, ndx) => {


        var input = this.elementRef.nativeElement,
            // $input = $(input),
            k     = e.which || e.charCode || e.keyCode;

        if (checkval !== true && (!(e.ctrlKey && e.altKey) && (e.ctrlKey || e.metaKey || ignorable))) {
          if (k === InputMaskHelpers.keyCode.ENTER && undoValue !== getBuffer().join('')) {
            undoValue = getBuffer().join('');
            // e.preventDefault();
            setTimeout(() => {
              this.trigger(this.elementRef.nativeElement, 'change');
            }, 0);
          }

          return true;
        } else {
          if (k) {
            // special treat the decimal separator
            if (k === 46 && e.shiftKey === false
              && <string>this.radixPoint !== ''
            ) k = this.radixPoint.charCodeAt(0);
            var pos                = checkval ? {
                  begin: ndx,
                  end:   ndx
                } : caret(input),
                forwardPosition, c = String.fromCharCode(k);

            getMaskSet().writeOutBuffer = true;
            var valResult               = isValid(pos, c, strict);
            if (valResult !== false) {
              resetMaskSet(true);
              forwardPosition = valResult.caret !== undefined ? valResult.caret : checkval ? valResult.pos + 1 : seekNext(valResult.pos);
              getMaskSet().p  = forwardPosition; // needed for checkval
            }

            if (writeOut !== false) {
              if (opts.onKeyValidation) {
                setTimeout(() => {
                  opts.onKeyValidation.call(this.elementRef.nativeElement, k, valResult, opts);
                }, 0);
              }
              if (getMaskSet().writeOutBuffer && valResult !== false) {
                var buffer = getBuffer();
                writeBuffer(input, buffer, (opts.numericInput && valResult.caret === undefined) ? seekPrevious(forwardPosition) : forwardPosition, e, checkval !== true);
                if (checkval !== true) {
                  setTimeout(() => { // timeout needed for IE
                    if (isComplete(buffer) === true) this.trigger(this.elementRef.nativeElement, 'complete');
                  }, 0);
                }
              }
            }

            e.preventDefault();

            if (checkval) {
              if (typeof valResult !== 'boolean') {
                valResult.forwardPosition = forwardPosition;
              }
              return valResult;
            }
          }
        }

      },
      pasteEvent:         (e) => {

        var input      = this.elementRef.nativeElement,
            ev         = e.originalEvent || e,
            // $input = $(input),
            inputValue = this._valueGet(true),
            caretPos   = caret(input),
            tempValue;

        if (isRTL) {
          tempValue      = caretPos.end;
          caretPos.end   = caretPos.begin;
          caretPos.begin = tempValue;
        }

        var valueBeforeCaret = inputValue.substr(0, caretPos.begin),
            valueAfterCaret  = inputValue.substr(caretPos.end, inputValue.length);

        if (valueBeforeCaret === (isRTL ? getBufferTemplate().reverse() : getBufferTemplate()).slice(0, caretPos.begin).join('')) valueBeforeCaret = '';
        if (valueAfterCaret === (isRTL ? getBufferTemplate().reverse() : getBufferTemplate()).slice(caretPos.end).join('')) valueAfterCaret = '';
        if (isRTL) {
          tempValue        = valueBeforeCaret;
          valueBeforeCaret = valueAfterCaret;
          valueAfterCaret  = tempValue;
        }

        if ((<any>window).clipboardData && (<any>window).clipboardData.getData) { // IE
          inputValue = valueBeforeCaret + (<any>window).clipboardData.getData('Text') + valueAfterCaret;
        } else if (ev.clipboardData && ev.clipboardData.getData) {
          inputValue = valueBeforeCaret + ev.clipboardData.getData('text/plain') + valueAfterCaret;
        } else return true; // allow native paste event as fallback ~ masking will continue by inputfallback

        var pasteValue = inputValue;
        if (InputMaskHelpers.isFunction(opts.onBeforePaste)) {
          pasteValue = opts.onBeforePaste(inputValue, opts);
          if (pasteValue === false) {
            return e.preventDefault();
          }
          if (!pasteValue) {
            pasteValue = inputValue;
          }
        }
        checkVal(input, false, false, isRTL ? pasteValue.split('').reverse() : pasteValue.toString().split(''));
        writeBuffer(input, getBuffer(), seekNext(getLastValidPosition()), e, undoValue !== getBuffer().join(''));
        if (isComplete(getBuffer()) === true) {
          this.trigger(this.elementRef.nativeElement, 'complete');
        }

        return e.preventDefault();
      },
      inputFallBackEvent: (e) => { // fallback when keypress fails


        var input      = this.elementRef.nativeElement,
            inputValue = this._valueGet();

        //
        if (getBuffer().join('') !== inputValue) {
          var caretPos = caret(input);
          inputValue   = inputValue.replace(new RegExp('(' + InputMaskHelpers.escapeRegex(getBufferTemplate().join('')) + ')*'), '');

          if (this.iemobile) { // iemobile just set the character at the end althought the caret position is correctly set
            var inputChar = inputValue.replace(getBuffer().join(''), '');
            if (inputChar.length === 1) {
              var keypress: any = new Event('keypress');
              keypress.which    = inputChar.charCodeAt(0);
              this.EventHandlers.keypressEvent.call(input, keypress, true, true, false, getMaskSet().validPositions[caretPos.begin - 1] ? caretPos.begin : caretPos.begin - 1);

              return false;
            }
          }

          if (caretPos.begin > inputValue.length) {
            caret(input, inputValue.length);
            caretPos = caret(input);
          }
          // detect & treat possible backspace before static
          if ((getBuffer().length - inputValue.length) === 1 && inputValue.charAt(caretPos.begin) !== getBuffer()[caretPos.begin] && inputValue.charAt(caretPos.begin + 1) !== getBuffer()[caretPos.begin] && !isMask(caretPos.begin)) {
            e.keyCode = InputMaskHelpers.keyCode.BACKSPACE;
            this.EventHandlers.keydownEvent.call(input, e);
          } else {
            var lvp            = getLastValidPosition() + 1;
            var bufferTemplate = getBufferTemplate().join(''); // getBuffer().slice(lvp).join('');
            while (inputValue.match(InputMaskHelpers.escapeRegex(bufferTemplate) + '$') === null) {
              bufferTemplate = bufferTemplate.slice(1);
            }
            inputValue = inputValue.replace(bufferTemplate, '');
            inputValue = inputValue.split('');
            checkVal(input, true, false, inputValue, e, caretPos.begin < lvp);

            if (isComplete(getBuffer()) === true) {
              this.trigger(this.elementRef.nativeElement, 'complete');
            }
          }
          e.preventDefault();
        }

      },
      setValueEvent:      (e) => {


        this.refreshValue = false;
        var input         = this.elementRef.nativeElement,
            value         = this._valueGet(true);
        // checkVal(input, true, false, (InputMaskHelpers.isFunction(opts.onBeforeMask) ? (opts.onBeforeMask(value, opts) || value) : value).split(""));
        if (InputMaskHelpers.isFunction(opts.onBeforeMask)) value = opts.onBeforeMask(value, opts) || value;
        value = value.split('');
        checkVal(input, true, false, isRTL ? value.reverse() : value);
        undoValue = getBuffer().join('');
        if ((opts.clearMaskOnLostFocus || opts.clearIncomplete) && this._valueGet() === getBufferTemplate().join('')) {
          this._valueSet('');
          this.onModelChange && this.onModelChange('');
        }

      },
      focusEvent:         (e) => {
        var input    = this.elementRef.nativeElement,
            nptValue = this._valueGet();
        if (opts.showMaskOnFocus && (!opts.showMaskOnHover || (opts.showMaskOnHover && nptValue === ''))) {
          if (this._valueGet() !== getBuffer().join('')) {
            writeBuffer(input, getBuffer(), seekNext(getLastValidPosition()));
          } else if (mouseEnter === false) { // only executed on focus without mouseenter
            caret(input, seekNext(getLastValidPosition()));
          }
        }
        if (opts.positionCaretOnTab === true && mouseEnter === false) {
          this.EventHandlers.clickEvent.apply(input, [e, true]);
        }
        undoValue = getBuffer().join('');
      },
      mouseleaveEvent:    (e) => {
        var input  = this.elementRef.nativeElement;
        mouseEnter = false;
        if (opts.clearMaskOnLostFocus && document.activeElement !== input) {
          var buffer   = getBuffer().slice(),
              nptValue = this._valueGet();
          if (nptValue !== input.getAttribute('placeholder') && nptValue !== '') {
            if (getLastValidPosition() === -1 && nptValue === getBufferTemplate().join('')) {
              buffer = [];
            } else { // clearout optional tail of the mask
              clearOptionalTail(buffer);
            }
            writeBuffer(input, buffer);
          }
        }
      },
      clickEvent:         (e, tabbed) => {
        let doRadixFocus = (clickPos) => {
          if (<string>this.radixPoint !== '') {
            var vps = getMaskSet().validPositions;
            if (vps[clickPos] === undefined || (vps[clickPos].input === getPlaceholder(clickPos))) {
              if (clickPos < seekNext(-1)) return true;
              var radixPos = InputMaskHelpers.inArray(this.radixPoint, getBuffer());
              if (radixPos !== -1) {
                for (var vp in vps) {
                  if (radixPos < vp && vps[vp].input !== getPlaceholder(vp)) {
                    return false;
                  }
                }
                return true;
              }
            }
          }
          return false;
        };

        var input = this.elementRef.nativeElement;
        setTimeout(() => { // needed for Chrome ~ initial selection clears after the clickevent
          if (document.activeElement === input) {
            var selectedCaret = caret(input);
            if (tabbed) {
              if (isRTL)
                selectedCaret.end = selectedCaret.begin;
              else
                selectedCaret.begin = selectedCaret.end;
            }
            if (selectedCaret.begin === selectedCaret.end) {
              switch (opts.positionCaretOnClick) {
                case 'none':
                  break;
                case 'radixFocus':
                //  if (doRadixFocus(selectedCaret.begin)) {
                    var radixPos = InputMaskHelpers.inArray(this.radixPoint, getBuffer().join(''));
                    caret(input, opts.numericInput ? seekNext(radixPos) : radixPos);
                    break;
                  //}
                default: // lvp:
                  var clickPosition   = selectedCaret.begin,
                      lvclickPosition = getLastValidPosition(clickPosition, true),
                      lastPosition    = seekNext(lvclickPosition);

                  if (clickPosition < lastPosition) {
                    caret(input, !isMask(clickPosition) && !isMask(clickPosition - 1) ? seekNext(clickPosition) : clickPosition);
                  } else {
                    var placeholder = getPlaceholder(lastPosition);
                    if ((placeholder !== '' && getBuffer()[lastPosition] !== placeholder && getTest(lastPosition).match.optionalQuantifier !== true) || (!isMask(lastPosition) && getTest(lastPosition).match.def === placeholder)) {
                      lastPosition = seekNext(lastPosition);
                    }
                    caret(input, lastPosition);
                  }
                  break;
              }
            }
          }
        }, 0);
      },
      dblclickEvent:      (e) => {
        var input = this.elementRef.nativeElement;
        setTimeout(() => {
          caret(input, 0, seekNext(getLastValidPosition()));
        }, 0);
      },
      cutEvent:           (e) => {
        var input = this.elementRef.nativeElement,
            pos   = caret(input),
            ev    = e.originalEvent || e;

        // correct clipboardData
        var clipboardData = (<any>window).clipboardData || ev.clipboardData,
            clipData      = isRTL ? getBuffer().slice(pos.end, pos.begin) : getBuffer().slice(pos.begin, pos.end);
        clipboardData.setData('text', isRTL ? clipData.reverse().join('') : clipData.join(''));
        if (document.execCommand) document.execCommand('copy'); // copy selected content to system clipbaord

        handleRemove(input, InputMaskHelpers.keyCode.DELETE, pos);
        writeBuffer(input, getBuffer(), getMaskSet().p, e, undoValue !== getBuffer().join(''));

        if (this._valueGet() === getBufferTemplate().join('')) {
          this.trigger(this.elementRef.nativeElement, 'cleared');
        }
      },
      blurEvent:          (e) => {
        var
          input = this.elementRef.nativeElement;
        if (true) {
          var nptValue = this._valueGet(),
              buffer   = getBuffer().slice();
          if (undoValue !== buffer.join('')) {
            setTimeout(() => { // change event should be triggered after the other buffer manipulations on blur
              this.trigger(this.elementRef.nativeElement, 'change');
              undoValue = buffer.join('');
            }, 0);
          }
          if (nptValue !== '') {
            if (opts.clearMaskOnLostFocus) {
              if (getLastValidPosition() === -1 && nptValue === getBufferTemplate().join('')) {
                buffer = [];
              } else { // clearout optional tail of the mask
                clearOptionalTail(buffer);
              }
            }
            if (isComplete(buffer) === false) {
              setTimeout(() => {
                this.trigger(this.elementRef.nativeElement, 'incomplete');
              }, 0);
              if (opts.clearIncomplete) {
                resetMaskSet();
                if (opts.clearMaskOnLostFocus) {
                  buffer = [];
                } else {
                  buffer = getBufferTemplate().slice();
                }
              }
            }

            writeBuffer(input, buffer, undefined, e);
          }
        }
      },
      mouseenterEvent:    (e) => {
        var input  = this.elementRef.nativeElement;
        mouseEnter = true;
        if (document.activeElement !== input && opts.showMaskOnHover) {
          if (this._valueGet() !== getBuffer().join('')) {
            writeBuffer(input, getBuffer());
          }
        }
      },
      submitEvent:        (e) => { // trigger change on submit if any
        if (undoValue !== getBuffer().join('')) {
          this.trigger(this.elementRef.nativeElement, 'change');
        }
        if (opts.clearMaskOnLostFocus && getLastValidPosition() === -1 && this._valueGet && this._valueGet() === getBufferTemplate().join('')) {
          this._valueSet(''); // clear masktemplete on submit and still has focus
          this.onModelChange && this.onModelChange('');
        }
        if (opts.removeMaskOnSubmit) {
          this._valueSet(this.unmaskedvalue(), true);
          this.onModelChange && this.onModelChange(this.unmaskedvalue());
          setTimeout(() => {
            writeBuffer(this.elementRef.nativeElement, getBuffer());
          }, 0);
        }
      },
      resetEvent:         (e) => {
        this.refreshValue = true; // indicate a forced refresh when there is a call to the value before leaving the triggering event fn
        setTimeout(() => {
          this.EventHandlers.setValueEvent.call(this.elementRef.nativeElement);
        }, 0);
      }
    };
    let initializeColorMask = (input) => {
      let findCaretPos = (clientx) => {
        // calculate text width
        var e = document.createElement('span'), caretPos;
        for (var style in computedStyle) { // clone styles
          if (isNaN(+style) && style.indexOf('font') !== -1) {
            e.style[style] = computedStyle[style];
          }
        }
        e.style.textTransform = computedStyle.textTransform;
        e.style.letterSpacing = computedStyle.letterSpacing;
        e.style.position      = 'absolute';
        e.style.height        = 'auto';
        e.style.width         = 'auto';
        e.style.visibility    = 'hidden';
        e.style.whiteSpace    = 'nowrap';

        document.body.appendChild(e);
        var inputText = this._valueGet(), previousWidth = 0, itl;
        for (caretPos = 0, itl = inputText.length; caretPos <= itl; caretPos++) {
          e.innerHTML += inputText.charAt(caretPos) || '_';
          if (e.offsetWidth >= clientx) {
            var offset1 = (clientx - previousWidth);
            var offset2 = e.offsetWidth - clientx;
            e.innerHTML = inputText.charAt(caretPos);
            offset1 -= (e.offsetWidth / 3);
            caretPos    = offset1 < offset2 ? caretPos - 1 : caretPos;
            break;
          }
          previousWidth = e.offsetWidth;
        }
        document.body.removeChild(e);
        return caretPos;
      };

      let position = () => {
        colorMask.style.position = 'absolute';
        // colorMask.style.top = offset.top + "px";
        // colorMask.style.left = offset.left + "px";
        colorMask.style.width  = parseInt(input.offsetWidth) - parseInt(computedStyle.paddingLeft) - parseInt(computedStyle.paddingRight) - parseInt(computedStyle.borderLeftWidth) - parseInt(computedStyle.borderRightWidth) + 'px';
        colorMask.style.height = parseInt(input.offsetHeight) - parseInt(computedStyle.paddingTop) - parseInt(computedStyle.paddingBottom) - parseInt(computedStyle.borderTopWidth) - parseInt(computedStyle.borderBottomWidth) + 'px';

        colorMask.style.lineHeight       = colorMask.style.height;
        colorMask.style.zIndex           = isNaN(computedStyle.zIndex) ? -1 : computedStyle.zIndex - 1;
        colorMask.style.webkitAppearance = 'textfield';
        colorMask.style.mozAppearance    = 'textfield';
        colorMask.style.Appearance       = 'textfield';

      };

      var // offset = $(input).position(),
        computedStyle = (input.ownerDocument.defaultView || window).getComputedStyle(input, null),
        parentNode    = input.parentNode;

      colorMask = document.createElement('div');
      document.body.appendChild(colorMask); // insert at body to prevent css clash :last-child for example
      for (var style in computedStyle) { // clone styles
        if (isNaN(+style) && style !== 'cssText' && style.indexOf('webkit') == -1) {
          colorMask.style[style] = computedStyle[style];
        }
      }

      // restyle input
      input.style.backgroundColor  = 'transparent';
      input.style.color            = 'transparent';
      input.style.webkitAppearance = 'caret';
      input.style.mozAppearance    = 'caret';
      input.style.Appearance       = 'caret';

      position();

      // event passthrough
      InputMaskHelpers.on(window, 'resize', function (e) {
        // offset = $(input).position();
        computedStyle = (input.ownerDocument.defaultView || window).getComputedStyle(input, null);
        position();
      });
      InputMaskHelpers.on(input, 'click', (e) => {
        caret(input, findCaretPos(e.clientX));
        return this.EventHandlers.clickEvent.call(this.elementRef.nativeElement, [e]);
      });

      InputMaskHelpers.on(input, 'keydown', (e) => {
        if (!e.shiftKey && opts.insertMode !== false) {
          setTimeout(() => {
            renderColorMask(input);
          }, 0);
        }
      });
    };

    let renderColorMask = (input, buffer?, caretPos?) => {
      let handleStatic = () => {
        if (!isStatic && (test.fn === null || testPos.input === undefined)) {
          isStatic = true;
          maskTemplate += '<span class=\'im-static\'\'>';
        } else if (isStatic && (test.fn !== null && testPos.input !== undefined)) {
          isStatic = false;
          maskTemplate += '</span>';
        }
      };

      if (colorMask !== undefined) {
        buffer = buffer || getBuffer();
        if (caretPos === undefined) {
          caretPos = caret(input);
        } else if (caretPos.begin === undefined) {
          caretPos = {begin: caretPos, end: caretPos};
        }

        var maskTemplate = '', isStatic = false;
        if (buffer != '') {
          var ndxIntlzr, pos     = 0,
              test, testPos, lvp = getLastValidPosition();
          do {
            if (pos === caretPos.begin && document.activeElement === input) {
              maskTemplate += '<span class=\'im-caret\' style=\'border-right-width: 1px;border-right-style: solid;\'></span>';
            }
            if (getMaskSet().validPositions[pos]) {
              testPos   = getMaskSet().validPositions[pos];
              test      = testPos.match;
              ndxIntlzr = testPos.locator.slice();
              handleStatic();
              maskTemplate += testPos.input;
            } else {
              testPos   = getTestTemplate(pos, ndxIntlzr, pos - 1);
              test      = testPos.match;
              ndxIntlzr = testPos.locator.slice();
              if (opts.jitMasking === false || pos < lvp || (typeof opts.jitMasking === 'number' && isFinite(opts.jitMasking) && opts.jitMasking > pos)) {
                handleStatic();
                maskTemplate += getPlaceholder(pos, test);
              }
            }
            pos++;
          } while ((maxLength === undefined || pos < maxLength) && (test.fn !== null || test.def !== '') || lvp > pos);
        }
        colorMask.innerHTML = maskTemplate;
      }
    };

    let mask = (elem) => {
      let isElementTypeSupported = (input, opts) => {
        let patchValueProperty = (npt) => {
          var valueGet;
          var valueSet;

          let patchValhook = (type) => {
            if (InputMaskHelpers.valHooks && (InputMaskHelpers.valHooks[type] === undefined || InputMaskHelpers.valHooks[type].inputmaskpatch !== true)) {
              var valhookGet = InputMaskHelpers.valHooks[type] && InputMaskHelpers.valHooks[type].get ? InputMaskHelpers.valHooks[type].get : (elem) => {
                return elem.value;
              };
              var valhookSet = InputMaskHelpers.valHooks[type] && InputMaskHelpers.valHooks[type].set ? InputMaskHelpers.valHooks[type].set : (elem, value) => {
                elem.value = value;
                return elem;
              };

              InputMaskHelpers.valHooks[type] = {
                get:            (elem) => {
                  if (elem.inputmask) {
                    if (elem.this.opts.autoUnmask) {
                      return elem.this.unmaskedvalue();
                    } else {
                      var result = valhookGet(elem);
                      return getLastValidPosition(undefined, undefined, elem.this.maskset.validPositions) !== -1 || opts.nullable !== true ? result : '';
                    }
                  } else return valhookGet(elem);
                },
                set:            (elem, value) => {
                  var
                    result;
                  result = valhookSet(elem, value);
                  if (elem.inputmask) {
                    // $elem.trigger("setvalue");
                    this.EventHandlers.setValueEvent.call(input);
                  }
                  return result;
                },
                inputmaskpatch: true
              };
            }
          };

          let getter = () => {
            // if (this.inputmask) {


            return this.opts.autoUnmask ?
              this.unmaskedvalue() :
              (getLastValidPosition() !== -1 || opts.nullable !== true ?
                (document.activeElement === this.elementRef.nativeElement && opts.clearMaskOnLostFocus ?
                  (isRTL ? clearOptionalTail(getBuffer().slice()).reverse() : clearOptionalTail(getBuffer().slice())).join('') :
                  valueGet.call(this.elementRef.nativeElement)) :
                '');
            // } else return valueGet.call(this);
          };

          let setter = (value) => {
            value = ((value == null ? '' : value) + '').replace(/\./g, this.radixPoint);

            valueSet.call(this.elementRef.nativeElement, value);
            this.EventHandlers.setValueEvent.call(input);
            this.onModelChange && this.onModelChange(this.unmaskedvalue());

            // if (this.inputmask) {
            //     $(this).trigger("setvalue");
            // }
          };

          let installNativeValueSetFallback = (npt) => {
            EventRuler.on(npt, 'mouseenter', (event) => {
              var
                // input = this.elementRef.nativeElement,
                value = this._valueGet();
              if (value !== getBuffer().join('') /*&& getLastValidPosition() > 0*/) {
                this.EventHandlers.setValueEvent.call(input);
              }
            });
          };

          if (!this.__valueGet) {
            if (opts.noValuePatching !== true) {
              if (Object.getOwnPropertyDescriptor) {
                if (typeof Object.getPrototypeOf !== 'function') {
                  Object.getPrototypeOf = typeof (<any>'test').__proto__ === 'object' ? (object) => {
                    return object.__proto__;
                  } : (object) => {
                    return object.constructor.prototype;
                  };
                }

                var valueProperty = Object.getPrototypeOf ? Object.getOwnPropertyDescriptor(Object.getPrototypeOf(npt), 'value') : undefined;
                if (valueProperty && valueProperty.get && valueProperty.set) {
                  valueGet = valueProperty.get;
                  valueSet = valueProperty.set;
                  Object.defineProperty(npt, 'value', {
                    get:          getter,
                    set:          setter,
                    configurable: true
                  });
                }
              } else if ((<any>document).__lookupGetter__ && npt.__lookupGetter__('value')) {
                valueGet = npt.__lookupGetter__('value');
                valueSet = npt.__lookupSetter__('value');

                npt.__defineGetter__('value', getter);
                npt.__defineSetter__('value', setter);
              }
              this.__valueGet = valueGet; // store native property getter
              this.__valueSet = valueSet; // store native property setter
            }
            this._valueGet = (overruleRTL) => {
              return isRTL && overruleRTL !== true ? valueGet.call(this.elementRef.nativeElement).split('').reverse().join('') : valueGet.call(this.elementRef.nativeElement);
            };
            this._valueSet = (value, overruleRTL) => { // null check is needed for IE8 => otherwise converts to "null"
              valueSet.call(this.elementRef.nativeElement, (value === null || value === undefined) ? '' : ((overruleRTL !== true && isRTL) ? value.split('').reverse().join('') : value));
              this.onModelChange && this.onModelChange(this.unmaskedvalue());
            };

            if (valueGet === undefined) { // jquery.val fallback
              valueGet = () => {
                return this.elementRef.nativeElement.value;
              };
              valueSet = (value) => {
                this.elementRef.nativeElement.value = value;
                this.onModelChange && this.onModelChange(this.unmaskedvalue());
              };
              patchValhook(npt.type);
              installNativeValueSetFallback(npt);
            }
          }
        };

        var elementType = input.getAttribute('type');
        var isSupported = (input.tagName === 'INPUT' && InputMaskHelpers.inArray(elementType, opts.supportsInputType) !== -1) || input.isContentEditable || input.tagName === 'TEXTAREA';
        if (!isSupported) {
          if (input.tagName === 'INPUT') {
            var el = document.createElement('input');
            el.setAttribute('type', elementType);
            isSupported = el.type === 'text'; // apply mask only if the type is not natively supported
            el          = null;
          } else isSupported = 'partial';
        }
        if (isSupported !== false) {
          patchValueProperty(input);
        }
        return isSupported;
      };

      // unbind all events - to make sure that no other mask will interfere when re-masking
      EventRuler.off(this.elementRef.nativeElement);
      var isSupported = isElementTypeSupported(elem, opts);
      if (isSupported !== false) {
        this.elementRef.nativeElement = elem;
        // $this.elementRef.nativeElement = $(this.elementRef.nativeElement);

        if (this.elementRef.nativeElement.dir === 'rtl' || opts.rightAlign) {
          this.elementRef.nativeElement.style.textAlign = 'right';
        }

        if (this.elementRef.nativeElement.dir === 'rtl' || opts.numericInput) {
          this.elementRef.nativeElement.dir = 'ltr';
          this.elementRef.nativeElement.removeAttribute('dir');
          this.isRTL = true;
          isRTL      = true;
        }

        if (opts.colorMask === true) {
          initializeColorMask(this.elementRef.nativeElement);
        }

        if (this.android) {
          if (this.elementRef.nativeElement.hasOwnProperty('inputmode')) {
            this.elementRef.nativeElement.inputmode = opts.inputmode;
            this.elementRef.nativeElement.setAttribute('inputmode', opts.inputmode);
          }
          if (opts.androidHack === 'rtfm') {
            if (opts.colorMask !== true) {
              initializeColorMask(this.elementRef.nativeElement);
            }
            this.elementRef.nativeElement.type = 'password';
          }
        }

        if (isSupported === true) {
          // bind events
          EventRuler.on(this.elementRef.nativeElement, 'submit', this.EventHandlers.submitEvent);
          EventRuler.on(this.elementRef.nativeElement, 'reset', this.EventHandlers.resetEvent);

          EventRuler.on(this.elementRef.nativeElement, 'mouseenter', this.EventHandlers.mouseenterEvent);
          EventRuler.on(this.elementRef.nativeElement, 'blur', this.EventHandlers.blurEvent);
          EventRuler.on(this.elementRef.nativeElement, 'focus', this.EventHandlers.focusEvent);
          EventRuler.on(this.elementRef.nativeElement, 'mouseleave', this.EventHandlers.mouseleaveEvent);
          if (opts.colorMask !== true)
            EventRuler.on(this.elementRef.nativeElement, 'click', this.EventHandlers.clickEvent);
          EventRuler.on(this.elementRef.nativeElement, 'dblclick', this.EventHandlers.dblclickEvent);
          EventRuler.on(this.elementRef.nativeElement, 'paste', this.EventHandlers.pasteEvent);
          EventRuler.on(this.elementRef.nativeElement, 'dragdrop', this.EventHandlers.pasteEvent);
          EventRuler.on(this.elementRef.nativeElement, 'drop', this.EventHandlers.pasteEvent);
          EventRuler.on(this.elementRef.nativeElement, 'cut', this.EventHandlers.cutEvent);
          EventRuler.on(this.elementRef.nativeElement, 'complete', opts.oncomplete);
          EventRuler.on(this.elementRef.nativeElement, 'incomplete', opts.onincomplete);
          EventRuler.on(this.elementRef.nativeElement, 'cleared', opts.oncleared);
          if (!this.android || opts.inputEventOnly !== true) {
            EventRuler.on(this.elementRef.nativeElement, 'keydown', this.EventHandlers.keydownEvent);
            EventRuler.on(this.elementRef.nativeElement, 'keypress', this.EventHandlers.keypressEvent);
          }
          EventRuler.on(this.elementRef.nativeElement, 'compositionstart', () => {
          });
          EventRuler.on(this.elementRef.nativeElement, 'compositionupdate', () => {
          });
          EventRuler.on(this.elementRef.nativeElement, 'compositionend', () => {
          });
          EventRuler.on(this.elementRef.nativeElement, 'keyup', () => {
          });
          EventRuler.on(this.elementRef.nativeElement, 'input', this.EventHandlers.inputFallBackEvent);
          EventRuler.on(this.elementRef.nativeElement, 'beforeinput', () => {
          }); // https:// github.com/w3c/input-events - to implement
        }
        EventRuler.on(this.elementRef.nativeElement, 'setvalue', this.EventHandlers.setValueEvent);

        // apply mask
        getBufferTemplate(); // initialize the buffer and getmasklength
        if (this._valueGet() !== '' || opts.clearMaskOnLostFocus === false || document.activeElement === this.elementRef.nativeElement) {
          var initialValue = InputMaskHelpers.isFunction(opts.onBeforeMask) ? (opts.onBeforeMask(this._valueGet(), opts) || this._valueGet()) : this._valueGet();
          if (initialValue !== '') {
            checkVal(this.elementRef.nativeElement, true, false, initialValue.split(''));
          }
          var buffer = getBuffer().slice();
          undoValue  = buffer.join('');
          // Wrap document.activeElement in a try/catch block since IE9 throw "Unspecified error" if document.activeElement is undefined when we are in an IFrame.
          if (isComplete(buffer) === false) {
            if (opts.clearIncomplete) {
              resetMaskSet();
            }
          }
          if (opts.clearMaskOnLostFocus && document.activeElement !== this.elementRef.nativeElement) {
            if (getLastValidPosition() === -1) {
              buffer = [];
            } else {
              clearOptionalTail(buffer);
            }
          }
          writeBuffer(this.elementRef.nativeElement, buffer);
          if (document.activeElement === this.elementRef.nativeElement) { // position the caret when in focus
            caret(this.elementRef.nativeElement, seekNext(getLastValidPosition()));
          }
        }
      }
    };
    var valueBuffer;
    if (actionObj !== undefined) {
      switch (actionObj.action) {
        case 'isComplete':
          this.elementRef.nativeElement = actionObj.this.elementRef.nativeElement;
          return isComplete(getBuffer());
        case 'unmaskedvalue':
          if (this.elementRef.nativeElement === undefined || actionObj.value !== undefined) {
            valueBuffer = actionObj.value;
            valueBuffer = (InputMaskHelpers.isFunction(opts.onBeforeMask) ? (opts.onBeforeMask(valueBuffer, opts) || valueBuffer) : valueBuffer).split('');
            checkVal(undefined, false, false, isRTL ? valueBuffer.reverse() : valueBuffer);
            if (InputMaskHelpers.isFunction(opts.onBeforeWrite)) opts.onBeforeWrite(undefined, getBuffer(), 0, opts);
          }
          return unmaskedvalue(this.elementRef.nativeElement);
        case 'mask':
          mask(this.elementRef.nativeElement);
          break;
        case 'format':
          valueBuffer = (InputMaskHelpers.isFunction(opts.onBeforeMask) ? (opts.onBeforeMask(actionObj.value, opts) || actionObj.value) : actionObj.value).split('');
          checkVal(undefined, true, false, isRTL ? valueBuffer.reverse() : valueBuffer);
          // if (InputMaskHelpers.isFunction(opts.onBeforeWrite)) opts.onBeforeWrite(undefined, getBuffer(), 0, opts);

          if (actionObj.metadata) {
            return {
              value:    isRTL ? getBuffer().slice().reverse().join('') : getBuffer().join(''),
              metadata: this.maskScope({
                'action': 'getmetadata'
              }, maskset, opts)
            };
          }

          return isRTL ? getBuffer().slice().reverse().join('') : getBuffer().join('');
        case 'isValid':
          if (actionObj.value) {
            valueBuffer = actionObj.value.split('');
            checkVal(undefined, true, true, isRTL ? valueBuffer.reverse() : valueBuffer);
          } else {
            actionObj.value = getBuffer().join('');
          }
          var buffer = getBuffer();
          var rl     = determineLastRequiredPosition(),
              lmib   = buffer.length - 1;
          for (; lmib > rl; lmib--) {
            if (isMask(lmib)) break;
          }
          buffer.splice(rl, lmib + 1 - rl);

          return isComplete(buffer) && actionObj.value === getBuffer().join('');
        case 'getemptymask':
          return getBufferTemplate().join('');
        case 'remove':
          if (this.elementRef.nativeElement) {
            // $this.elementRef.nativeElement = $(this.elementRef.nativeElement);
            // writeout the unmaskedvalue
            this._valueSet(unmaskedvalue(this.elementRef.nativeElement));
            this.onModelChange && this.onModelChange(this.unmaskedvalue());
            // unbind all events
            EventRuler.off(this.elementRef.nativeElement);
            // restore the value property
            var valueProperty;
            if (Object.getOwnPropertyDescriptor && Object.getPrototypeOf) {
              valueProperty = Object.getOwnPropertyDescriptor(Object.getPrototypeOf(this.elementRef.nativeElement), 'value');
              if (valueProperty) {
                if (this.__valueGet) {
                  Object.defineProperty(this.elementRef.nativeElement, 'value', {
                    get:          this.__valueGet,
                    set:          this.__valueSet,
                    configurable: true
                  });
                }
              }
            } else if ((<any>document).__lookupGetter__ && this.elementRef.nativeElement.__lookupGetter__('value')) {
              if (this.__valueGet) {
                this.elementRef.nativeElement.__defineGetter__('value', this.__valueGet);
                this.elementRef.nativeElement.__defineSetter__('value', this.__valueSet);
              }
            }
            // clear data
            this.elementRef.nativeElement.inputmask = undefined;
          }
          return this.elementRef.nativeElement;
        case 'getmetadata':
          if (InputMaskHelpers.isArray(maskset.metadata)) {
            var maskTarget = getMaskTemplate(true, 0, false).join('');
            InputMaskHelpers.each(maskset.metadata, (ndx, mtdt) => {
              if (mtdt.mask === maskTarget) {
                maskTarget = mtdt;
                return false;
              }
            });
            return maskTarget;
          }

          return maskset.metadata;
      }
    }
  }
}

// tslint:enable
