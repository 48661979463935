<form novalidate
      (ngSubmit)="onTextSearch()">
    <div class="input-group">
        <input type="text"
               name="searchText"
               [(ngModel)]="textSearch"
               class="form-control"
               placeholder="Zoeken...">
        <span class="input-group-btn">
                        <button class="btn btn-secondary" type="submit">Zoek</button>
                    </span>
    </div>
</form>
