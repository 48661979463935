import { CsEdit }     from './table-edit.component';
import { CsTemplate } from './table-template.directive';

import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule }                  from '@angular/common';

import { CsTable }                    from './table.component';
import { Column }                     from './table-column.component';
import { ColumnHeaderTemplateLoader } from './table-column-header-loader.component';
import { ColumnBodyTemplateLoader } from './table-column-body-loader.component';
import { CsFilterModule }           from '@cs/components/filter';
import { FormsModule }              from '@angular/forms';


@NgModule({
	declarations:    [
		CsTable,
		CsTemplate,
		ColumnBodyTemplateLoader,
		ColumnHeaderTemplateLoader,
		Column,
		CsEdit
	],
	exports:         [
		CsTable,
		CsTemplate,
		ColumnBodyTemplateLoader,
		ColumnHeaderTemplateLoader,
		Column,
		CsEdit],
	imports:         [
		CommonModule,
		FormsModule,
		CsFilterModule
	],
	entryComponents: [
		CsTable,
		Column]
})
export class CsTableModule {
	static forRoot(): ModuleWithProviders<CsTableModule> {
		return {ngModule: CsTableModule};
	}
}
