import { Directive, Input, ViewContainerRef } from '@angular/core';

import { CsTemplateRegistry } from '../services/cs-template-registry.service';

@Directive({
	selector: '[csTemplateLoader]'
})

export class CsTemplateLoaderDirective {

	@Input('csTemplateLoader') template;
	@Input() csTemplateLoaderPayload;
	@Input() csTemplateLoaderRow;

	constructor(public viewContainer: ViewContainerRef,
							private templateList: CsTemplateRegistry) {

	}

	resolveTemplate() {
		// clean up previous view
		this.viewContainer.clear();
		const found = this.templateList.getTemplate(this.template);
		const view  = this.viewContainer.createEmbeddedView(found.template, {
			'\$implicit': this.csTemplateLoaderPayload,
			rowData:      this.csTemplateLoaderRow
		});
		view.markForCheck();
	}

}
