import { animate, style, transition, trigger }                                            from '@angular/animations';
import { ChangeDetectorRef, Component, ElementRef, Inject, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer, SafeHtml }                                                         from '@angular/platform-browser';
import { ActivatedRoute }                                                                 from '@angular/router';
import { CsToastManagerService }                                                          from '@cs/components/toast-manager';
import { CsHttpRequestOptions, DataDescribed, LoggerUtil }                                from '@cs/core';
import { AuthenticationQuery }                                                            from '@cs/performance-manager/shared';
import { TranslateService }                                                               from '@ngx-translate/core';
import { DefaultComponent }                                                               from '../default/default.component';
import { DataDescribedLoginService, LoginConfigService }                                  from '../login-config.service';
import { LoginModel }                                                                     from '../models/accountInfo';
import { AuthMethod }                                                                     from '../models/auth-method.model';
import { LoginQuery }                                                                     from '../state/login.query';
import { LoginService }                                                                   from '../state/login.service';

declare var grecaptcha: any;

@Component({
			   selector:    'pmc-data-described-login',
			   templateUrl: './data-described-login.component.html',
			   animations:  [trigger(
				   'inOutAnimation',
				   [
					   transition(
						   ':enter',
						   [
							   style({transform: 'translateX(-100%)', height: 48, marginTop: 0}),
							   animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
									   style({transform: 'translateX(0)', height: 48}))
						   ]
					   ),
					   transition(
						   ':leave',
						   [
							   style({height: 0}),
							   animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)',
									   style({height: 0, opacity: 0, marginTop: -8}))
						   ]
					   )
				   ]
			   )
			   ]
		   })
export class DataDescribedLoginComponent extends DefaultComponent implements OnInit, OnDestroy {

	loginConfig: DataDescribedLoginService;
	formData: DataDescribed<LoginModel[]>;

	loginOptions: Array<AuthMethod>;

	loginModel: LoginModel = {googleCaptchaResponse: '', username: '', password: ''};

	/**
	 * Binding to the captcha field
	 */
	captcha      = '';
	/**
	 * The state of the form. Defaults to true because it's empty
	 */
	isErrorState = true;

	/**
	 * HTML of the Captcha
	 */
	captchaHtml: SafeHtml;

	/**
	 * Indicator for the loader
	 */
	isLoadingCaptcha = true;

	useGoogleCaptcha = false;

	//#region Loaders

	/**
	 * Loader flag for the reset password button
	 */
	isWaitingForReset = false;

	get activeMethod(): string {
		return this._activeMethod;
	}

	set activeMethod(value: string) {
		this._activeMethod = value;
	}

	get currentAuthMethod() {
		return this.loginOptions.find(value => value.active);
	}


	constructor(
		readonly loginService: LoginService,
		readonly loginQuery: LoginQuery,
		@Inject(LoginConfigService) loginConfig: DataDescribedLoginService,
		readonly route: ActivatedRoute,
		readonly authenticationQuery: AuthenticationQuery,
		readonly toastService: CsToastManagerService,
		readonly l8n: TranslateService,
		readonly renderer: Renderer2,
		readonly sanitizer: DomSanitizer,
		readonly changeRef: ChangeDetectorRef,
		readonly ngZone: NgZone,
		private element: ElementRef
	) {
		super(loginService, loginQuery, loginConfig, authenticationQuery, route, toastService, l8n);

		window['onloadRecaptchaCallback'] = () => {
			this.refreshCaptcha();
			// Set disabled upon int captcha callback
			const submit = document.getElementById('form-submit');
			submit['disabled'] = true;
		};

		window['submitForm'] = (token) => this.ngZone.run(() => {
			this.captcha                          = token;
			this.loginModel.googleCaptchaResponse = token;

			const form = document.getElementById('form') as HTMLFormElement;
			if(form.checkValidity()) {
				this.login();
			}	else {
				form.reportValidity();
				this.refreshCaptcha();
				return false;
			}
		});
	}

	ngOnInit() {
		super.ngOnInit();
		this.loginConfig.getLoginForm()
			.subscribe(value => {

				this.formData               = value.value;
				this.availableAuthProviders = JSON.parse(JSON.stringify(this.formData.data));


				const loginState = this.loginQuery.getValue();

				this.activeMethod = loginState.method == null
									? this.activeMethod
									: loginState.method;


				this.loginOptions = this.formData  // @ts-ignore
										.getLookupByProperty('method')
										.lookup
										.values
										.map(value1 => new AuthMethod({
																		  label:  value1.value,
																		  key:    value1.key,
																		  icon:   this.getAuthIcon(value1.key),
																		  active: this.activeMethod === value1.key
																	  }));

				// Save the login options for extenal login page label resolvement
				this.loginService.setLoginOptions(JSON.parse(JSON.stringify(this.loginOptions)));

				this.switchMethod(this.loginOptions.find(value1 => value1.active) || this.loginOptions[0]);
			});
	}

	login() {
		if (this.loginQuery.getValue().inProgress) {
			LoggerUtil.log('Already in progress');
			return;
		}

		this.loginService.setErrorState(false);
		this.loginService.setInProgress(true);

		const errorHandler                = new CsHttpRequestOptions();
		errorHandler.errorResponseHandler = error => {
			this.loginService.setInProgress(false);

			// Refresh captcha if the error is not redirect to otp (error status code 450)
			if(error.status !== 450) {
				this.refreshCaptcha();
			}

			switch (error.status) {
				case 503:
					this.toastService.show({
											   type:    'error',
											   content: this.l8n.instant('AUTH_PROVIDER_NOT_AVAILABLE_MSG', {methodName: this.currentAuthMethod.label})
										   });
					return true;
				case 309:
					if (error.error) {
						window.location = error.error;
						return true;
					} else {
						this.toastService.show({type: 'warning', content: this.l8n.instant('NO_AUTH_CALLBACK_LOGIN_MSG')});
					}
					return true;
				case 401:
					this.loginService.setErrorState(true);

					this.toastService.show({
											   type:            'info',
											   showProgressBar: true,
											   showTitle:       false,
											   content:         `${this.l8n.instant('INVALID_LOGIN_MSG')}`
										   });

					return true;
			}
			return false;
		};

		this.loginConfig.loginWithProvider(this.loginModel, errorHandler)
			.subscribe(value => {

				this.loginConfig.loginSuccessHandler(value, this.route);
				// stop animation
				this.loginService.setInProgress(false);
			});


		this.rememberMe
		? this.loginService.setUserName(this.loginModel.username)
		: this.loginService.setUserName('');

		this.loginService.SetAuthMethod(this.loginModel.method);
		this.loginService.setRememberMe(this.rememberMe);

	}


	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.captchaHtml = '';
		this.changeRef.detectChanges();
	}


	getAuthIcon(option: string) {

		const method = option.toLowerCase();

		if (method.startsWith('ldap'))
			return 'icon icon-microsoft-account';
		if (method === 'usernamepassword')
			return 'icon-cohelion';
		if (method === 'duo')
			return 'icon icon-duo-account';

	}

	switchMethod(authMethod: AuthMethod) {
		this.activeMethod = authMethod.key;
		for (const method of this.loginOptions) {
			method.active = this.activeMethod === method.key;
		}
		this.loginModel = this.availableAuthProviders.find(value => value.method === this.activeMethod);
		this.setAuthMethodMessage();

		this.useGoogleCaptcha = this.loginModel.requiresGoogleCaptcha;
		if (this.useGoogleCaptcha) {
			const injectedRootUrlHtml = this.loginModel.googleCaptchaChallenge;
			// This will return something like <div class="g-recaptcha" data-sitekey="#application.Settings.Google.Captcha.SiteKey#" data-callback="submitForm" data-bind="form-submit"></div>
			this.captchaHtml          = this.sanitizer.bypassSecurityTrustHtml(injectedRootUrlHtml);
			this.injectDependency();
		}
	}

	filterActive(loginOptions: Array<AuthMethod>) {
		if (loginOptions == null)
			return [];
		return loginOptions.filter(value => !value.active);
	}

	refreshCaptcha() {
		if (!this.loginModel.requiresGoogleCaptcha)
			return;

		this.isLoadingCaptcha = false;
		this.changeRef.detectChanges();
		setTimeout(() => {
			const container = this.element.nativeElement.querySelector('.g-recaptcha');
			try {
				grecaptcha.render(container, {
					'callback': (output) => {
						window['submitForm'](output);
					}
				});
			} catch (e) {
				grecaptcha.reset();
			}

			this.changeRef.detectChanges();
		}, 0);
		this.changeRef.detectChanges();
	}

	private availableAuthProviders: LoginModel[];

	private get alreadyInjectedDependency() {
		return document.getElementById('googleCaptcha') != null;
	}

	//#endregion

	private _activeMethod = 'UsernamePassword';

	private setAuthMethodMessage() {
		this.loginService.setUnderTitleMessage(`<i class="mr-2 ${this.getAuthIcon(this.activeMethod)}"></i> ${this.l8n.instant('MSG_LOGIN_METHOD', {method: this.currentAuthMethod.label})}`);
	}

	private injectDependency() {
		if (this.alreadyInjectedDependency) {
			this.refreshCaptcha();
			return;
		}

		const script   = document.createElement('script');
		script.id      = 'googleCaptcha';
		script.src     = 'https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback';
		script.type    = 'text/javascript';
		script.async   = true;
		script.defer   = true;
		script.charset = 'utf-8';
		this.renderer.appendChild(document.head, script);
	}

}
