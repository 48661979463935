import { CsHttpRequestOptions, Result } from '@cs/core';
import { Observable }                   from 'rxjs';
import { ResetCaptcha }                 from './models/reset-captcha.model';
import { VerifiedResetCode }            from './models/verified-reset-code.model';
import { PasswordResetResult }          from './models/reset-password-result.model';
import { IConfigService }               from '@cs/performance-manager/shared';
import {
	PasswordPolicyGroup,
	PasswordChangeResult
}                                       from './models/password-policy.models';


export abstract class ResetPasswordConfigService implements IConfigService {

	moduleName = 'reset-password';

	abstract supportMail: string;

	abstract requestPasswordReset(username: string, captcha: string, options?: CsHttpRequestOptions): Observable<Result<string>>;

	abstract initiatePasswordReset(): Observable<Result<ResetCaptcha>>;

	abstract verifyResetCode(resetcode: string, options?: CsHttpRequestOptions): Observable<Result<VerifiedResetCode>>;

	abstract getPasswordPolicy(resetcode: string): Observable<Result<PasswordPolicyGroup[]>>;

	abstract verifyPasswordWithPolicy(newPassword: string, resetcode: string,
																		options?: CsHttpRequestOptions): Observable<Result<PasswordChangeResult>>;

	abstract changePassword(resetcode: string, newPassword: string, newPasswordVerified: string,
													oldPassword?: string, options?: CsHttpRequestOptions): Observable<Result<PasswordResetResult>>;

	abstract initiatePasswordChange(options?: CsHttpRequestOptions): Observable<Result<VerifiedResetCode>>;

	abstract setPassword(newPassword: string, newPasswordCheck: string, options: CsHttpRequestOptions): Observable<Result<PasswordResetResult>>;
}

