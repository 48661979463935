import { Alert }            from './alert';
import { DashboardGridRow } from './dashboard-grid-row';

export class DashboardGridLayout {
	grid: DashboardGridRow[] = [];
	alerts?: Alert[];

	getPanel(name: string) {
		for (const row of this.grid) {
			for (const col of row.columns) {
				const found = col.panels.find(value => value.name === name);
				if (found)
					return found;
			}
		}
		return null;
	}

	/**
	 * Returns the row that contains the panel
	 * @param name the panel name that should be in the row
	 */
	getRow(name: string) {
		for (const row of this.grid) {
			for (const col of row.columns) {
				const found = col.panels.find(value => value.name === name);
				if (found)
					return row;
			}
		}
		return null;
	}
}
