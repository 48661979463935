<div class="float-left element-container {{node.input.controlName}}">
	<div *ngIf="editMode" class="flex-row value flex-no-wrap"
		 [class.editable]="editMode"
		 [ngSwitch]="node.input.controlName"
		 [formGroup]="formGroup">
		<div class="form-elements-container sm-gutters">
			<ng-container *ngIf="!node.input.showRemarkField">
				<input class="form-control" type="text" *ngSwitchCase="'TextboxInput'" [maxlength]="node.maxLength"
					   [formControlName]="node.key"/>

				<input class="form-control" type="email" *ngSwitchCase="'TextboxEmailInput'"
					   [maxlength]="node.maxLength"
					   [formControlName]="node.key"/>

				<input class="form-control" type="url" *ngSwitchCase="'TextboxUrlInput'" [maxlength]="node.maxLength"
					   [formControlName]="node.key"/>

				<input class="form-control" type="checkbox" *ngSwitchCase="'CheckboxInput'"
					   [formControlName]="node.key"/>

				<input class="form-control" type="date" *ngSwitchCase="'DateInput'"
					   (change)="node.input.onDateChange($event)"
					   [(ngModel)]="node.input.date" [ngModelOptions]="{standalone: true}"/>

				<input class="form-control" type="file" *ngSwitchCase="'FileInput'" [formControlName]="node.key"/>

				<ng-container *ngSwitchCase="'IntegerInput'">
					<input class="form-control" type="number" [maxlength]="node.maxLength"
						   [formControlName]="node.key"/>
					<span *ngIf="node.data.getData('suffix')">{{node.data.getData('suffix')}}</span>
				</ng-container>

				<ng-container *ngSwitchCase="'IntegerWithEnumInput'">

					<input class="form-control" type="number" [maxlength]="node.maxLength"
						   [formControlName]="node.key"/>

					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.enumValue"
							[ngModelOptions]="{standalone: true}">
						<option *ngFor="let option of node.getConfig('enumOptions')"
								[value]="option">{{option}}</option>
					</select>

				</ng-container>

				<ng-container *ngSwitchCase="'DecimalWithEnumInput'">

					<input class="form-control" type="number" [maxlength]="node.maxLength" step="0.001"
						   [formControlName]="node.key"/>


					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.enumValue"
							[ngModelOptions]="{standalone: true}">
						<option *ngFor="let option of node.getConfig('enumOptions')"
								[value]="option">{{option}}</option>
					</select>

				</ng-container>

				<ng-container *ngSwitchCase="'TextboxWithToggleInput'">

					<input class="form-control" type="checkbox" (change)="node.input.onToggleChange($event)"
						   [(ngModel)]="node.input.toggleValue"
						   [ngModelOptions]="{standalone: true}"/>

					<input class="form-control" type="text" [maxlength]="node.maxLength" [formControlName]="node.key"/>

				</ng-container>

				<ng-container *ngSwitchCase="'DecimalInput'">
					<input class="form-control" type="number" [maxlength]="node.maxLength" step="0.001"
						   [formControlName]="node.key"/>
					<span *ngIf="node.data.getData('suffix')">{{node.data.getData('suffix')}}</span>
				</ng-container>

				<textarea class="form-control" *ngSwitchCase="'LongTextInput'"
						  [maxlength]="node.maxLength"
						  [formControlName]="node.key"
						  rows="5"
				></textarea>

				<ng-container *ngSwitchCase="'YesNoInput'">
					<input type="radio" [id]="node.key+'yes'" [name]="node.key" [value]="true"
						   [formControlName]="node.key">
					<label for="{{node.key+'yes'}}">Yes</label>
					<input type="radio" [id]="node.key+'no'" [value]="false" [formControlName]="node.key">
					<label for="{{node.key+'no'}}">No</label>
				</ng-container>

				<ng-container *ngSwitchCase="'YearInput'">
					<select class="form-control" [formControlName]="node.key">
						<option *ngFor="let year of node.input.years" [value]="year">{{year}}</option>
					</select>
				</ng-container>

				<ng-container *ngSwitchCase="'MonthInput'">
					<input class="form-control" type="hidden" [formControlName]="node.key" [id]="node.key"
						   [value]="node.value"/>

					<select class="form-control" (change)="node.input.onYearSelectChange($event)"
							[(ngModel)]="node.input.year"
							[ngModelOptions]="{standalone: true}">
						<option *ngFor="let year of node.input.years" [value]="year">{{year}}</option>
					</select>

					<select class="form-control" (change)="node.input.onMonthSelectChange($event)"
							[(ngModel)]="node.input.month"
							[ngModelOptions]="{standalone: true}">
						<option [value]="undefined">-- Please select --</option>
						<option *ngFor="let month of node.input.months" [value]="month.value">{{month.label}}</option>
					</select>
				</ng-container>

				<ng-container *ngSwitchCase="'QuarterInput'">
					<input type="hidden" [formControlName]="node.key" [id]="node.key" [value]="node.value"/>

					<select class="form-control" (change)="node.input.onYearSelectChange($event)"
							[(ngModel)]="node.input.year"
							[ngModelOptions]="{standalone: true}">
						<option *ngFor="let year of node.input.years" [value]="year">{{year}}</option>
					</select>

					<select class="form-control" (change)="node.input.onQuarterSelectChange($event)"
							[(ngModel)]="node.input.quarter"
							[ngModelOptions]="{standalone: true}">
						<option *ngFor="let quarter of node.input.quarters"
								[value]="quarter.value">{{quarter.label}}</option>
					</select>
				</ng-container>

				<ng-container *ngSwitchCase="'EnumInput'">
					<select class="form-control" [formControlName]="node.key">
						<option *ngFor="let option of node.getConfig('enumOptions')"
								[value]="option">{{option}}</option>
					</select>
				</ng-container>

				<ng-container *ngSwitchCase="'EnumFreeTextInput'">
					<input type="hidden" [formControlName]="node.key" [id]="node.key" [value]="node.value"/>

					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.option"
							[ngModelOptions]="{standalone: true}">
						<option value="">-- Please select --</option>
						<option *ngFor="let option of node.getConfig('enumOptions')"
								[value]="option">{{option}}</option>
					</select>
					<input class="form-control" type="text" *ngIf="node.input.option == 'Custom value'"
						   (change)="node.input.onInputChange($event)"
						   [(ngModel)]="node.input.customValue" [ngModelOptions]="{standalone: true}"/>

				</ng-container>

				<ng-container *ngSwitchCase="'TimezoneInput'">
					<input class="form-control" type="hidden" [formControlName]="node.key" [id]="node.key"
						   [value]="node.value"/>
					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.timezone"
							[ngModelOptions]="{standalone: true}">
						<optgroup *ngFor="let continent of node.input.options" [label]="continent.label">
							<option *ngFor="let timezone of continent.timezones"
									value="{{timezone.id}}">{{timezone.label}}</option>
						</optgroup>
					</select>
				</ng-container>

				<ng-container *ngSwitchCase="'MapsInput'">
					<div class="break-all-children-flex">
						<input class="form-control" type="hidden" [formControlName]="node.key" [id]="node.key"
							   [value]="node.value"/>
						<ngui-map [options]="node.input.mapOptions">
							<marker [position]="node.input.mapOptions.center" draggable="true"
									(dragend)="node.input.onDrag($event)"></marker>
						</ngui-map>
					</div>
				</ng-container>

				<ng-container *ngSwitchCase="'OpeningHoursInput'">
					<input class="form-control" type="hidden" [formControlName]="node.key" [id]="node.key"/>
					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.rangeSelector"
							[ngModelOptions]="{standalone: true}">
						<option value="from-to">From / to</option>
						<option value="24-hours">24 Hours</option>
						<option value="closed">Closed</option>
					</select>
					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.startTime"
							[ngModelOptions]="{standalone: true}" [disabled]="node.input.rangeSelector != 'from-to'">
						<optgroup *ngFor="let time of node.input.times" [label]="time.hour.displayValue">
							<option *ngFor="let minute of time.minutes"
									value="{{time.hour.value}}:{{minute.value}}">{{time.hour.displayValue}}
								:{{minute.displayValue}}</option>
						</optgroup>
					</select>
					<select class="form-control" (change)="node.input.onSelectChange($event)"
							[(ngModel)]="node.input.endTime"
							[ngModelOptions]="{standalone: true}" [disabled]="node.input.rangeSelector != 'from-to'">
						<optgroup *ngFor="let time of node.input.times" [label]="time.hour.displayValue">
							<option *ngFor="let minute of time.minutes"
									value="{{time.hour.value}}:{{minute.value}}">{{time.hour.displayValue}}
								:{{minute.displayValue}}</option>
						</optgroup>
					</select>
				</ng-container>


			</ng-container>
			<div *ngIf="node.input.showRemarkField" class="remark-box d-flex">
        <textarea class="form-control" placeholder="Add remark"
				  (change)="node.input.onRemarkChange($event)">{{node.input.data.remark}}</textarea>
			</div>
		</div>

		<div class="remark-icon-container cs-pointer"
			 *ngIf="!hideRemarkIcon">

			<i (click)="node.input.toggleRemark($event)"
			   class="remark-toggle mdi"
			   [class.mdi-comment-edit]="node.input.data.remark && !node.input.showRemarkField"
			   [class.mdi-comment-plus]="!node.input.data.remark && !node.input.showRemarkField"
			   [class.mdi-close-circle]="node.input.showRemarkField"
			   matTooltip="{{node.input.data.remark}}"
			   [matTooltipDisabled]="node.input.showRemarkField"
			   matTooltipPosition="above">
			</i>

		</div>
	</div>

	<div *ngIf="!editMode" class="flex-row">
		<div class="cs-col cs-col-12 p-0">
      <span *ngIf="node.input.controlName != 'MapsInput'" class="read-only-label ">
        <span class="flex-grow-1 justify-content-between"
			  [matTooltipDisabled]="!node.input.data.remark"
			  matTooltipPosition="above"
			  matTooltip="{{node.input.data.remark}}">{{node.displayValue}}
			<ng-container *ngIf="node.input.data.remark">
          <i class="mdi mdi-comment-text-outline comment-icon flex-grow-0"></i>
        </ng-container>
        </span>
      </span>

			<span *ngIf="node.input.controlName == 'MapsInput'">
        <ng-container *ngIf="(node.input.mapOptions.center.lat === 0 && node.input.mapOptions.center.lng === 0)">
        <span class="read-only-label">
         {{'NO_LOCATION_PROVIDED' | translate}}
			<b *ngIf="node.input.data.remark"><i
					class="mdi mdi-comment-text-outline ml-2 mr-1"></i>
        <small style="font-size: 90%;vertical-align: bottom;">
          {{node.input.data.remark}}
        </small>
      </b>
        </span>
        </ng-container>
        <ng-container *ngIf="!(node.input.mapOptions.center.lat === 0 && node.input.mapOptions.center.lng === 0)">
			<div [matTooltipDisabled]="!node.input.data.remark"
				 matTooltipPosition="above"
				 matTooltip="{{node.input.data.remark}}">
        <ngui-map [options]="node.input.mapOptions">
          <marker [position]="node.input.mapOptions.center"></marker>
        </ngui-map></div>
          </ng-container>
      </span>
		</div>
	</div>
</div>
