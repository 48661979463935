import { GridActions }           from '../enums/data-grid.enum';
import { DataGridAction }        from './data-grid-action';
import { DataGridSheetSettings } from './data-grid-sheet-settings';

export class DataGridSheetSettingsAction implements DataGridAction {
  readonly type: GridActions      = GridActions.SetSheetSettings;
  settings: DataGridSheetSettings = new DataGridSheetSettings();

  constructor(initial: Partial<DataGridSheetSettingsAction>) {
    Object.assign(this, initial);
  }
}
