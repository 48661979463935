<div class="">
	<div class="cs-tab-container" *ngIf="tabbedPages?.length > 1">
		<ng-container *ngFor="let tab of tabbedPages">
			<div class="cs-tab-item"
				 [routerLink]="tab.path"
				 (click)="refreshNavigation()"
				 #rla="routerLinkActive"
				 routerLinkActive="active"
				 [csLoader]="rla.isActive && ($inProgress | async)"
				 [class.active]="rla.isActive">
				<label> {{tab.routeMetaData.label | titlecase}}</label>
			</div>
		</ng-container>
	</div>
	<div class="tabbed-page-container"
		 [@fadeAnimation]="getRouterOutletState(o)">
		<router-outlet #o="outlet"></router-outlet>
	</div>
</div>

