/**
 * Possible options for the label
 */
export enum LabelPosition {
  /**
   * Will not render the labels
   */
  None = 'none',
  /**
   * Render the label of the form next to the widget,
   * will use the @Link(WidgetCollection) label
   */
  Left = 'left',
  /**
   * Render the label of the form on top of the widget,
   * will use the @link(Widget) label because the label are most of the time on top of the widget
   */
  Top  = 'top'
}
