import { ModuleWithProviders, NgModule } from '@angular/core';
import { CsSpinnerComponent }            from './spinner.component';
import { CommonModule }                  from '@angular/common';
import { FormsModule }                   from '@angular/forms';

@NgModule({
	imports:         [CommonModule, FormsModule
	],
	exports:         [CsSpinnerComponent],
	declarations:    [CsSpinnerComponent],
	providers:       [],
	entryComponents: [CsSpinnerComponent]
})
export class CsSpinnerModule {
	static forRoot(): ModuleWithProviders<CsSpinnerModule> {
		return {ngModule: CsSpinnerModule};
	}
}
