export enum DialogType {
  none = 'none',
  info = 'info',
  success = 'success',
  warning = 'warning',
  danger = 'danger'
}

export interface DialogBasicOptions {
  showYes?: boolean;
  showCancel?: boolean;
  showNo?: boolean;
	showOk?: boolean;
  type?: DialogType;
  dialogTitle?: string;
  message: string;
}
