import { BaseInput } from './base-input';

export class EnumFreeTextInput extends BaseInput {
  public options;
  public option;
  public customValue;
  public constructor(data: any) {
    super(data);
    this.options = this.node.getConfig('enumOptions');
    this.options.push('Custom value');

    let index = this.options.indexOf(this.data.textValue);

    if (index === -1) {
      this.option = 'Custom value';
    }
  }

  public onSelectChange(e) {
    this.setValue(this.option);
    this.markAsDirty();
  }

  public onInputChange(e) {
    this.setValue(this.customValue);
    this.markAsDirty();
  }
}
