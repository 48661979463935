/**
 * Created by alex on 4-7-2017.
 */

export class LookupAgent {
	static resolve(value: any, key: string, display: 'label' | 'labelMin' = 'label'): string {
		try {
			return window['members'][key.toLowerCase()][value][display];
		} catch (ex) {
			return value;
		}

	}

	static resolveProperty(value: any, key: string): any {
		try {
			return window['members'][key.toLowerCase()][value];
		} catch (ex) {
			return null;
		}

	}
}
