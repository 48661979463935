import { Params }                                  from '@angular/router';
import { IThresholdData }                          from '../interfaces/IThresholdData';
import { isNull, isNullOrUndefined }               from '@cs/core';
import { createToObjectWithLowerCaseKeys, Logger } from '@cs/components/util';
import zipObject                                   from 'lodash/zipObject';

/**
 * Wrapper class for IThresholdData
 */
export class ThresholdData {

	/** key columns of the data */
	dataKeyParts: string[] = [];

	/** key columns of the fallback data */
	fallbackKeyParts: string[] = [];

	/** data in form of flat structs */
	parsedData: Params[] = [];

	/** data in form of flat structs */
	parsedFallbackData: Params[] = [];


	/** Private properties */

	/** meta data */
	private meta: Params[] = [];

	/** columns of data */
	private columns: string[] = [];

	/** columns of fallback data */
	private fallbackColumns: string[] = [];


	/**
	 * Parser and container for data entry threshold data
	 */
	constructor(thresholdDataSet: IThresholdData = null) {
		if (isNull(thresholdDataSet)) {
			return;
		}
		if (isNullOrUndefined(thresholdDataSet.data)) {
			return;
		}

		if (!isNullOrUndefined(thresholdDataSet.data) && thresholdDataSet.data.length === 0) {
			return;
		}

		// backwards compatibility
		if (isNullOrUndefined(thresholdDataSet.fallbackColumns)) {
			thresholdDataSet.fallbackColumns = [];
		}
		if (isNullOrUndefined(thresholdDataSet.fallbackKeyParts)) {
			thresholdDataSet.fallbackKeyParts = [];
		}
		if (isNullOrUndefined(thresholdDataSet.fallbackData)) {
			thresholdDataSet.fallbackData = [];
		}

		this.columns         = thresholdDataSet.columns.map(x => x.toLowerCase());
		this.fallbackColumns = thresholdDataSet.fallbackColumns.map(x => x.toLowerCase());

		this.dataKeyParts     = thresholdDataSet.dataKeyParts.map(x => x.toLowerCase());
		this.fallbackKeyParts = thresholdDataSet.fallbackKeyParts.map(x => x.toLowerCase());

		this.meta = createToObjectWithLowerCaseKeys(thresholdDataSet.meta);

		// validate
		if (!this.columns.some(col => {
			return col === 'thresholdmin' || col === 'thresholdmax';
		})) {
			Logger.ThrowError('Invalid threshold dataset. Expecting thresholdmin and/or thresholdmax columns');
		}

		// Merge data rows to have keys and set defaults
		for (let row of thresholdDataSet.data) {
			let rowobject = zipObject(this.columns, row);
			rowobject     = Object.assign({'thresholdmin': null, 'thresholdmax': null}, rowobject);
			this.parsedData.push(rowobject);
		}

		// Merge data rows to have keys and set defaults
		for (let row of thresholdDataSet.fallbackData) {
			let rowobject = zipObject(this.fallbackColumns, row);
			rowobject     = Object.assign({'thresholdmin': null, 'thresholdmax': null}, rowobject);
			this.parsedFallbackData.push(rowobject);
		}

	}

}
