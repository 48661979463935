import { DataDescribed }       from '@cs/core';
import { FilterBarRenderType } from './filter-bar-element';

// tslint:disable-next-line
export type FilterBarData = { [key: string]: unknown };

export interface FilterBarRenderSetting {
  id: string | number;
  displayType: FilterBarRenderType;
}

export class FilterBarLayoutAnnotation {
  renderSettings: Array<FilterBarRenderSetting>;
}

export class FilterBarMetaData {
  apiParams: any;
  resultParams: any;
  activateComparison: boolean;
}

export class FilterBarDataDescribed extends DataDescribed<FilterBarData, FilterBarLayoutAnnotation, FilterBarMetaData> {

}
