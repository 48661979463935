<div class="cs-toast-notification"
	 (mouseenter)="onEnter()" (mouseleave)="onLeave()"

	 (click)="close()"
	 [ngClass]="{
                'cs-toast-alert': data.type === 'alert',
                'cs-toast-error': data.type === 'error',
                'cs-toast-success': data.type === 'success',
                'cs-toast-info': data.type === 'info',
                'cs-toast-bare': data.type === 'bare',
                'cs-toast-warning': data.type === 'warning',
                'cs-toast-notification--small': data.size === 'sm',
                'cs-toast-notification--auto': data.size === 'auto'
            }"
	 [@fadeAnimation]="{value: animationState, params:
        { fadeIn: toastConfig.animation.fadeIn, fadeOut: toastConfig.animation.fadeOut }}"
	 (@fadeAnimation.done)="onFadeFinished($event)">
	<div class="cs-toast-content-container">
		<div class="cs-title" *ngIf="data.showTitle != false">{{data.title | capitalize}}</div>

		<div class="cs-content"><i class="mdi mdi-18px {{iconType}} mr-2"
								   *ngIf="iconType"></i>{{data.content | capitalize }}</div>
	</div>
	<div class="flex">
		<ng-container *ngIf="data.copyToClipboardText">
			<i class="mdi mdi-18px mdi-clipboard-text-outline mr-2"
			   matTooltip="{{'COPY_TO_CLIPBOARD' | translate}}"
			   *ngIf="!isCopied"
			   (click)="copyToClipboard($event)"></i>
			<i class="mdi mdi-18px mdi-clipboard-check mr-2"
			   matTooltip="{{'COPIED_TO_CLIPBOARD' | translate}}"
			   *ngIf="isCopied"></i>
		</ng-container>
		<i *ngIf="showCloseButton" class="mdi mdi-18px mdi-close-circle"
		   matTooltip="{{'CLOSE' | translate}}"
		   (click)="close()"></i>
	</div>

	<div class="cs-progress-loader" *ngIf="showProgressBar">
		<span [ngStyle]="{'width': progressWidth + '%'}"></span>
	</div>
</div>
