import { ModuleWithProviders, NgModule }   from '@angular/core';
import { CommonModule }                    from '@angular/common';
import { TranslateModule }                 from '@ngx-translate/core';
import { CsAdvancedDropdownComponent }     from './advanced-dropdown.component';
import { CsAdvancedDropdownItemComponent } from './advanced-dropdown-item.component';
import { MatTooltipModule }                from '@angular/material/tooltip';
import { ScrollingModule }                 from '@angular/cdk/scrolling';
import { ComponentTranslationLoaderService } from '@cs/common';

@NgModule({
						imports:         [
							CommonModule,
							MatTooltipModule,
							ScrollingModule,
							TranslateModule
						],
						declarations:    [CsAdvancedDropdownComponent, CsAdvancedDropdownItemComponent],
						exports:         [CsAdvancedDropdownComponent, CsAdvancedDropdownItemComponent],
						entryComponents: [
							CsAdvancedDropdownComponent,
							CsAdvancedDropdownItemComponent
						]
					})
export class CsAdvancedDropdownModule {
	static forRoot(): ModuleWithProviders<CsAdvancedDropdownModule> {
		return {ngModule: CsAdvancedDropdownModule};
	}

	constructor(loader: ComponentTranslationLoaderService) {
		loader.registerComponentModule('advanced-dropdown');
	}
}
