import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';
import { DataEntryStateHeaderColumn }                                               from '../../models/data-entry-state-column';

@Component({
						 selector:        'cs-data-entry-state-header',
						 templateUrl:     './data-entry-state-header.component.html',
						 changeDetection: ChangeDetectionStrategy.OnPush,
						 host:            {
							 class: 'data-entry-state-header-row__content-column'
						 }
					 })
export class CsDataEntryStateHeaderComponent implements OnInit {

	@Input() column: DataEntryStateHeaderColumn;
	@Input() depth: number;

	constructor(private _renderer: Renderer2, private _hostElement: ElementRef) { }

	ngOnInit(): void {
		this.updateClasses(this._hostElement.nativeElement);
	}

	private updateClasses(el: any) {

		this._renderer.addClass(el, this.column?.visualState?.headerType === 'horizontal'
																? `data-entry-state__header--horizontal`
																: 'data-entry-state__header--vertical');
		this._renderer.addClass(el, this.column?.visualState?.placeholder
																? `data-entry-state__header--placeholder`
																: 'data-entry-state__header--non-placeholder');
		this._renderer.addClass(el, this.column?.visualState?.sticky
																? `data-entry-state__column--sticky`
																: 'data-entry-state__column--non-sticky');
		const width = `calc( ${this.column?.position?.widthPxWithChildOffset} - ${(this.depth === 1
																																							 ? (this.column?.position?.children * 2) + 'px'
																																							 : '0px')})`;
		this._renderer.setStyle(el, 'minWidth', width);
		this._renderer.setStyle(el, 'maxWidth', width);
		this._renderer.setStyle(el, 'justifyContent', `${this.column?.visualState?.alignment}`);
		this._renderer.setStyle(el, 'left', `${this.column?.position?.position}px`);


	}
}
