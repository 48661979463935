import { ChangeDetectorRef } from '@angular/core';
import { isNullOrUndefined } from '@cs/core';

export class SafeMethods {
	/**
	 * Safe Method to execute the detectChanges function, will not result in destroyed view error if changeref is already destroyed
	 */
	static detectChanges(changeRef: ChangeDetectorRef) {
		if (!isNullOrUndefined(changeRef) && !changeRef['destroyed']) {
			changeRef.detectChanges();
		}
	}
}
