import { FormLayoutWidgetCollection } from './form-layout-widget-collection.model';


export class FormLayoutFieldSets<T> {
	widgetCollections: FormLayoutWidgetCollection<T>[] = [];
	id: string;
	label: string;

	/**
	 * Check if the  collection has any visible widgets. if so, then render a collection block
	 */
	get isVisible() {
		return this.widgetCollections.length !== this.widgetCollections.filter(value => !value.include).length;
	}

	constructor(id: string, label: string = null) {
		this.id    = id;
		this.label = label;
	}
}
