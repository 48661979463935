import { Component, Input }                    from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector:    'cs-statistics-panel-performance-stats',
  templateUrl: './statistics-panel-performance-stats.component.html',
  animations:  [
    trigger('statisticsState', [
      transition(':enter', [ // each time the binding value changes

        style({opacity: 0}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 1
          }))

      ]),
      transition(':leave', [ // each time the binding value changes

        style({opacity: 1}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 0
          }))

      ])
    ])
  ]
})
export class CsStatisticsPanelPerformanceStatsComponent {
  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() statisticData: any;
}
