<div class="box gauge-{{size}}" [class.mt-3]="makeRoomForThresholdLabel" [class.mb-3]="!makeRoomForThresholdLabel">

  <div class="gauge-container-{{size}}">
    <div class="semi-circle-threshold-{{size}}" *ngIf="this.thresholdPosition"
         [ngStyle]="thresholdPosition">
      <small class="semi-circle-threshold-label-{{size}}"
             [ngStyle]="thresholdLabelOrientation">{{dataSource?.data?.threshold | format:formatThreshold}}</small>
      <div class="semi-circle-threshold-pointer-{{size}}"></div>
    </div>
    <div class="mask-{{size}}">
      <div class="semi-circle-{{size}}" [ngStyle]="gaugeColor"></div>
      <div class="semi-circle--mask-{{size}}"
           [ngStyle]="entryStatePosition"></div>
    </div>
    <div class="d-flex align-items-center
            justify-content-center
            entry-state-container">
      <div class="entry-state-wrapper {{gaugeType}}">

        <ng-container [ngSwitch]="gaugeType">
          <ng-container *ngSwitchCase="'status_value'">
            <div class="gauge-center-text-wrapper-{{gaugeType}}">
              <b>{{dataSource?.data?.value | format:formatValue}}</b>
              <small class="text-muted mr-1 ml-1">of</small>
              <b>{{dataSource?.data?.total | format:formatTotal}}</b>
            </div>
            <div class="gauge-center-text-value-label">
              <i>{{dataSource?.data?.valueLabel}}</i>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'single_value'">
            <div class="gauge-center-text-wrapper-{{gaugeType}}">
              <b>{{dataSource?.data?.value | format:formatValue}}</b>
              <i>{{dataSource?.data?.valueLabel}}</i>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>

  <div class="gauge-value-label-container">
    <div class="gauge-start-value">
      <small>{{(dataSource?.data?.start || 0) | format:formatStart}}</small>
    </div>

    <div class="gauge-value-label-spreader"></div>
    <div class="gauge-end-value">
      <small>{{(dataSource?.data?.total || 0) | format:formatTotal}}</small>
    </div>
  </div>

</div>
