<div class="cs-button-bar-container hide-in-print" [@slideInOut]="(isOpen$ | async)">
  <div class="">
    <span>{{nrUnsavedCells}} {{'UNSAVED' | translate}}
      <ng-container *ngIf="useRowLabel">{{'ROWS' | translate}}</ng-container>
      <ng-container *ngIf="!useRowLabel">{{'CELLS' | translate}}</ng-container>
    </span>
  </div>
  <div class="dynamic-button-wrapper flex-pull-right">
    <div class="cs-button-bar-input-container" *ngIf="hasInputOptions">
      <label class="f-body-s font-weight-bold cs-button-bar-input-label">
        {{hasInputOptions?.label}}</label>
      <input type="text"
             tabindex="1"
             [class.invalid]="reasonField.invalid"
             [required]="inputRequired$ | async"
             [formControl]="reasonField"
             class="form-control"
             placeholder="{{hasInputOptions?.placeholder}}">
    </div>

    <div *ngFor="let dynamicButton of registeredDynamicButtons; let index= index"
         class="dynamic-button mr-2"
         matTooltip="{{dynamicButton?.messages?.join('\n')}}"
         matTooltipClass="cs-button-bar-message">
      <button class="btn"
              tabindex="{{index + 2}}"
              [csLoader]="dynamicButton.isLoading"
              [ngClass]="dynamicButton.displayInstance.btnClass || 'btn-quaternary'"
              [disabled]="dynamicButton.checkIfDisabled(dynamicButton, injector, this)"
              matTooltip="{{dynamicButton.displayInstance.description}}"
              (click)="dynamicButtonClicked(dynamicButton, $event)">
        <i class="{{dynamicButton.displayInstance.iconClass}} mr-1" style="font-size:unset"></i>
        <span>{{dynamicButton.displayInstance.label}}</span>
      </button>
    </div>
  </div>
</div>
