import { Injectable, Injector, Inject } from '@angular/core';
import { Overlay, GlobalPositionStrategy } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { BehaviorSubject, Subject }         from 'rxjs';
import { debounceTime, delay, filter, tap } from 'rxjs/operators';

import { ToastComponent } from './toast.component';
import { ToastData, TOAST_CONFIG_TOKEN, ToastConfig } from './toast-manager.config';
import { ToastRef } from './toast-ref.model';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
													providedIn: 'root'
												})
export class CsToastManagerService {

	constructor(
		private overlay: Overlay,
		private parentInjector: Injector,
		@Inject(TOAST_CONFIG_TOKEN) private toastConfig: ToastConfig
	) {
		this.initializeToastQueue();
	}

	show(data: ToastData) {
		this.toastQueueSubject.next(data);
	}
	private activeToastsSubject = new BehaviorSubject<ToastRef[]>([]);
	private activeToasts$ = this.activeToastsSubject.asObservable();
	private toastQueueSubject = new Subject<ToastData>(); // Subject to collect all show events

	private initializeToastQueue() {
		this.toastQueueSubject
						.pipe(
							delay(200), // Wait for 300ms before processing the next toast
							tap((data: ToastData) => {
								// Check if a similar toast is already open
								const activeToasts = this.activeToastsSubject.getValue();
								const isDuplicate = activeToasts.some(
									toast => toast.isVisible() && this.isSameToast(data, toast.getData())
								);

								if (!isDuplicate) {
									this.checkForTitle(data);
									this.createAndAttachToast(data);
								}
							})
						)
						.subscribe();
	}

	private createAndAttachToast(data: ToastData) {
		const positionStrategy = this.getPositionStrategy();
		const overlayRef = this.overlay.create({ positionStrategy });

		const toastRef = new ToastRef(overlayRef, data, positionStrategy);
		const injector = this.getInjector(data, toastRef, this.parentInjector);
		const toastPortal = new ComponentPortal(ToastComponent, null, injector);

		overlayRef.attach(toastPortal);

		// Add the new toast to the list and update observable
		const currentToasts = this.activeToastsSubject.getValue();
		this.activeToastsSubject.next([...currentToasts, toastRef]);

		// Listen for when the toast closes to update positions
		toastRef.afterClosed().subscribe(() => {
			this.onToastClosed(toastRef);
		});
	}

	private getPositionStrategy(): GlobalPositionStrategy {
		return this.overlay
													.position()
													.global()
													.bottom(this.getPosition())
													.right(this.toastConfig.position.right + 'px');
	}

	private getPosition() {
		const bottomOffset = this.activeToastsSubject.getValue().reduce((offset, toast) => {
			if (toast.isVisible()) {
				return offset + toast.getPosition().height + 10; // Add some spacing between toasts
			}
			return offset;
		}, this.toastConfig.position.bottom);

		return bottomOffset + 'px';
	}

	private getInjector(
		data: ToastData,
		toastRef: ToastRef,
		parentInjector: Injector
	) {
		const tokens = new WeakMap();

		tokens.set(ToastData, data);
		tokens.set(ToastRef, toastRef);

		return new PortalInjector(parentInjector, tokens);
	}

	/**
		* When there is no title use the uppercase variant of the type to find the translated string
		*/
	private checkForTitle(data: ToastData) {
		if (data.title) return;

		const i18n = this.parentInjector.get(TranslateService);
		data.title = i18n.instant(`VERB_${data.type.toUpperCase()}`);
	}

	/**
		* Check if the new toast data matches the data of the last visible toast
		*/
	private isSameToast(newData: ToastData, existingData: ToastData): boolean {
		return (
			newData.type === existingData.type && newData.content === existingData.content
		);
	}

	/**
		* Method to handle the removal of a toast and update the positions of remaining toasts
		*/
	private onToastClosed(toastRef: ToastRef) {
		const updatedToasts = this.activeToastsSubject
																												.getValue()
																												.filter(toast => toast !== toastRef);

		this.activeToastsSubject.next(updatedToasts);

		// Update the positions of the remaining toasts
		updatedToasts.forEach((toast, index) => {
			if (toast.isVisible()) {
				const newPosition = this.calculateNewPosition(index);
				toast.updatePosition(newPosition);
			}
		});
	}

	/**
		* Calculate the new position for the toast based on its index in the activeToasts list
		*/
	private calculateNewPosition(index: number) {
		const offset = this.activeToastsSubject
																					.getValue()
																					.slice(0, index)
																					.reduce((toastOffset, toast) => toastOffset + toast.getPosition().height + 10, this.toastConfig.position.bottom);

		return offset + 'px';
	}
}
