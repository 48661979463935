import { LayoutOrientation }            from './layout-orientation.model';
import { WidgetCollection }             from './widget-collection.model';
import { WidgetInfo }                   from './widget-info.model';
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';


export class FormLayoutWidgetCollection<T> {

	/**
	 * Identification of the form
	 */
	id: string;
	/**
	 * Name to show in the UI
	 */
	label: string;
	/**
	 * Render direction of the form elements
	 */
	orientation: LayoutOrientation;
	/**
	 * Flag to show the collections label
	 */
	showLabel = true;

	widgets: WidgetInfo<T>[];
	/**
	 * Include in rendering
	 */
	include: boolean;

	constructor(init: Partial<WidgetCollection>, include: boolean = true) {
		this.id        = getPropertyOf(init, 'id');
		this.label     = getPropertyOf(init, 'label');
		this.showLabel = getPropertyOf(init, 'showLabel', true);
		this.include   = include;

		this.orientation = hasPropertyOf(init, 'orientation')
			? init.orientation.toLowerCase() as LayoutOrientation : LayoutOrientation.Horizontal;
	}
}
