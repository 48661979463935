import { NgModule }                  from '@angular/core';
import { Routes, RouterModule }      from '@angular/router';
import { IframeTeaserComponent }     from './iframe-teaser.component';


const routes: Routes = [
  {
    path: '', component: IframeTeaserComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IframeTeaserRoutingModule { }
