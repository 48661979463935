<div class="properties-form">
  <div class="cs-tab-container" [class.hidden]="pageList?.length < 2">
    <div class="cs-tab-item" *ngFor="let page of pageList"
         (click)="onNavLinkClicked(page)"
         [csLoader]="page.isLoading"
         [showAtLeastFor]="200"
         [class.active]="selectedPageNode && page.name === selectedPageNode.name">
      <label>{{page.label}}</label>
    </div>
  </div>

  <div class="cs-tab-container" [class.hidden]="sectionNodes.length < 2">
    <div class="cs-tab-item" *ngFor="let sectionNode of sectionNodes"
         (click)="onSectionNavLinkClicked(sectionNode)"
         [csLoader]="sectionNode.isLoading"
         [class.active]="sectionNode.name === selectedSectionNode.name">
      <label>{{sectionNode.label}}</label>
    </div>
  </div>


  <div class="page-node" >
    <form [@isLoading]="pageState"
      novalidate
      [formGroup]="form">
      <pmc-properties-view [formGroup]="form"
                           [editMode]="editMode"
                           [nodes]="nodes">
      </pmc-properties-view>

    </form>
  </div>
</div>
