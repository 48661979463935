import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable }                                        from 'rxjs';
import { Injectable }                                                         from '@angular/core';

@Injectable()
export class LoaderService {
	// A BehaviorSubject is an Observable with a default value
	isLoading$ = new BehaviorSubject(false);
}

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
	private requests: HttpRequest<unknown>[] = [];

	constructor(private loaderService: LoaderService) {
	}

	removeRequest(req: HttpRequest<unknown>) {
		const i = this.requests.indexOf(req);
		if (i >= 0) {
			this.requests.splice(i, 1);
		}
		this.loaderService.isLoading$.next(this.requests.length > 0);
	}

	intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
		this.requests.push(req);
		this.loaderService.isLoading$.next(true);
		// We create a new observable which we return instead of the original
		return Observable.create(observer => {
			// And subscribe to the original observable to ensure the HttpRequest is made
			const subscription = next.handle(req)
															 .subscribe(
																 event => {
																	 if (event instanceof HttpResponse) {
																		 this.removeRequest(req);
																		 observer.next(event);
																	 }
																 },
																 err => {
																	 this.removeRequest(req);
																	 observer.error(err);
																 },
																 () => {
																	 this.removeRequest(req);
																	 observer.complete();
																 });
			// return teardown logic in case of cancelled requests
			return () => {
				this.removeRequest(req);
				subscription.unsubscribe();
			};
		});
	}
}
