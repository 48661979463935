import { Subject }    from 'rxjs';
import { Injectable } from '@angular/core';


/**
 * Abstract class representing culture provider.
 * Handle getting/setting culture & emit when it changes
 */
@Injectable()
export abstract class CsCultureProvider {

	/**
	 * Event to be emitted whenever culture/language is ChangeDetectionStrategy
	 */
	abstract onCultureChanged: Subject<string>;

	/**
	 * Available languages
	 */
	abstract availableLanguages: string[];

	/**
	 * Return current culture.
	 */
	abstract getCulture(topOnly?: boolean): string;

	/**
	 * Return default culture.
	 */
	abstract getDefaultCulture(): string;

	/**
	 * Change current culture.
	 * And should emit onCultureChanged Event.
	 */
	abstract setCulture(culture): void;


	/**
	 * Return current cache buster hash.
	 */
	abstract getCacheBusterHash(): string;

	/**
	 * Change current cache buster hash.
	 * And should emit onCultureChanged Event.
	 */
	abstract setCacheBusterHash(culture): void;

	/**
	 * Set the location for the translation file on the server
	 */
	abstract setTranslationLocation(location: string): void;

	/**
	 * get the location for the translation file on the server
	 */
	abstract getTranslationLocation(): string;

	/**
	 * Set the available languages
	 */
	abstract setAvailableLanguages(languages: string[]): void;

}
