import { Observable }     from 'rxjs';
import { Result }         from '@cs/core';
import { ChangeLog }      from './models/change-log.model';
import { IConfigService } from '@cs/performance-manager/shared';

export abstract class ChangeLogConfigService implements IConfigService {

  moduleName = 'change-log';

  abstract getChangeLog(repositoryIdentifier? : string, hashOrBranch? : string): Observable<Result<ChangeLog | ChangeLog[]>>;

}

