import { FormsModule }                                   from '@angular/forms';
import { NgModule, ModuleWithProviders, Injector }       from '@angular/core';
import { CommonModule }                                  from '@angular/common';
import { DataGridElementFactory }                        from '@cs/components/data-grid/utils';
import { CsResolveLookupDirective }                      from './directives/cs-resolve-lookup.directive';
import { CsDataGridPopoversService }                     from './services/cs-data-grid-popovers.service';
import { CS_POPOVER_CONFIG_TOKEN, defaultPopoverConfig } from './services/cs-data-grid-popovers.config';
import { DataGridInterpreter }                           from './utils/data-grid-interpreter';
import { CsGridDataTdComponent }                         from './components/grid-data-td.component';
import { CsDataGrid }                                    from './data-grid.component';
import { CsReferenceTemplate }                           from './directives/cs-reference-template.directive';
import { TemplateStoreService }                          from './services/template-store.service';
import { CsTemplateOutlet }                              from './directives/cs-resolve-template.directive';
import { DataGridMessageHubService }                     from './services/data-grid-message-hub.service';
import { CsDatepickerModule }                            from '@cs/components/datepicker';
import { CsSliderModule }                                from '@cs/components/slider';
import { CsSharedModule }                                from '@cs/components/shared';
import { CsSpinnerModule }                               from '@cs/components/spinner';
import { MatDialogModule }                               from '@angular/material/dialog';
import { MatIconModule }                                 from '@angular/material/icon';
import { MatMenuModule }                                 from '@angular/material/menu';
import { MatTooltipModule }                              from '@angular/material/tooltip';
import { CsAdvancedDropdownModule }                      from '@cs/components/advanced-dropdown';
import { CsTemplateRegistry }                            from '@cs/components/shared';
import { CsSharedDialogModule }                          from '@cs/components/dialogs';
import { TranslateModule }                               from '@ngx-translate/core';


const components = [CsDataGrid, CsGridDataTdComponent];
const directives = [CsReferenceTemplate, CsTemplateOutlet, CsResolveLookupDirective];

@NgModule({
			  declarations:    [...components, ...directives],
			  providers:       [TemplateStoreService, DataGridMessageHubService, CsDataGridPopoversService, CsTemplateRegistry],
			  exports:         [...components,
								...directives],
			  imports:         [CommonModule,
								FormsModule,
								TranslateModule,
								CsDatepickerModule,
								CsSliderModule,
								CsSpinnerModule,
								MatDialogModule,
								MatTooltipModule,
								MatMenuModule,
								MatIconModule,
								CsSharedModule,
								CsSharedDialogModule,
								CsAdvancedDropdownModule],
			  entryComponents: [...components]
		  })
export class CsDataGridModule {
	static forRoot(config = defaultPopoverConfig): ModuleWithProviders<CsDataGridModule> {
		return {
			ngModule: CsDataGridModule, providers: [
				{
					provide:  CS_POPOVER_CONFIG_TOKEN,
					useValue: {...defaultPopoverConfig, ...config}
				}
			]
		};
	}

	constructor(private injector: Injector) {
		DataGridInterpreter.injector    = injector;
		DataGridElementFactory.injector = injector;
	}

}
