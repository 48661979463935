import { Params } from '@angular/router';
import {
	CellActionClickEventArgs, CellClickEventArgs, CellEditedEventArgs,
	RowButtonClickEventArgs, SheetActionEventArgs
}                 from './event-args';

import { UntilDestroy }        from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';


@UntilDestroy()
export abstract class DataEntryAgent<TButtonPayload, TComponent> {

	abstract saveInProgress$: Subject<void>;
	abstract saveIsDone$: Subject<void>;


	protected _registeredActions = new Map<string,
		DataEntryAction<TButtonPayload, TComponent>>();

	abstract save(params: Params, dataEntry: TComponent): Observable<boolean>;

	abstract sheetActionButtonClicked(button: SheetActionEventArgs, dataEntry: TComponent);

	abstract bottomBarButtonClicked(button: TButtonPayload, dataEntry: TComponent);

	abstract cellsEdited(args: CellEditedEventArgs, dataEntry: TComponent);

	abstract cellClicked(args: CellActionClickEventArgs, dataEntry: TComponent);

	abstract onNavigationRequested(args: CellClickEventArgs, dataEntry: TComponent);

	abstract loadNewData(event: Event, dataEntry: TComponent);

	abstract rowButtonClicked($event: RowButtonClickEventArgs, dataEntryComponent: TComponent);

	addAction(key: string, action: DataEntryAction<TButtonPayload, TComponent>) {
		this._registeredActions.set(key, action);
	}


}

@UntilDestroy()
export class DataEntryAction<TPayload, TEntryComponent> {
	name: string;
	actionToExecute: (payload: TPayload, config: DataEntryAgent<TPayload, TEntryComponent>) => true;
}
