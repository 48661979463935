/**
 * Abstract class to help format/parse cultured dates.
 */
export abstract class CsCultureDateParserFormatter {
    /**
     * Parses the given cultured string or date to a Date.
     * @param value the value to parse
     */
    abstract parseDate(value: any): Date;

    /**
     * Formats the given iso date string to a cultured string.
     * @param date the date to format as a string
     */
    abstract formatDate(isoDate: string): string;

    /**
     *  Returns string representing the date format, to be used as placeholder.
     */
    abstract getDateFormat(): string;
}
