<div class="overflow-page-container"
     [@loadingPanelState]="isLoadingDataGrids">
  <div>
    <cs-table [rows]="rows"
              sortColumn="regionLabel"
              [selectable]="true"
              [pageable]="false"
              [columns]="columnsList"
              [selectedPageSize]="30"
              (rowClick)="onRowClicked($event)">
      <cs-column *ngFor="let col of columns; let isFirst = first"
                 field="{{col.id}}"
                 [headerClass]="'injected table-cell table-headers__cell'"
                 title="{{col.label}}"
                 class={{col.id}}>
        <ng-template csTemplate="body" let-val>
          <div class="cell-content cell-container text-align-left">{{val | format:col.format}}</div>
        </ng-template>
      </cs-column>
    </cs-table>

  </div>
</div>
