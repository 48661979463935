import { FilterBarDataSource, FilterBarResultParams } from '@cs/components/filter-and-compare-bar';
import { CsHttpRequestOptions, Result }               from '@cs/core';
import { IConfigService }                             from '@cs/performance-manager/shared';
import { Observable }                                 from 'rxjs';
import { HomeCdData }                                 from './models/home-cd.model';

export abstract class HomeCdConfigService implements IConfigService {

	moduleName = 'home';

	abstract getHomepage(filter: FilterBarResultParams): Observable<Result<HomeCdData>>;

	abstract getFilterAndCompareBarData(params?: {
											[key: string]: any
										},
										name?: string,
										isComparison?: boolean,
										paramsMain?: {
											[key: string]: any
										},
										trigger?: string): Observable<Result<FilterBarDataSource<FilterBarResultParams>>>;

	abstract getBackdropImage(image: string, param2: {}, options: CsHttpRequestOptions);
}
