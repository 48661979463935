import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector:    'pmc-pm-detail-panel',
  templateUrl: './pm-detail-panel.component.html'
})
export class PmDetailPanelComponent {

  /**
   * When false, component is in collapsed state using minimal space
   */
  @Input() isCollapsed = false;

  /**
   * Short label describing the panel
   */
  @Input() label         = 'Audit changes';
  /**
   * Flag indicating if the panel should show a collapse/open button
   */
  @Input() isCollapsable = false;

  /**
   * Emit event that the panel needs to be closed, is handled by the parent
   */
  @Output() closeButtonClicked = new EventEmitter();


  constructor() {
  }

  /**
   * Toggle the collapsed state on/off
   */
  toggleCollapsedState(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  closePanel() {
    this.closeButtonClicked.emit();
  }
}
