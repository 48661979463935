import { Inject, Injectable }                              from '@angular/core';
import { BranchDto, Commit, RepositoryDto }                from '@cs/performance-manager/git-graph';
import { RepositoryUpdateResult, UpdateRepositoryRequest }      from '../models/submodule-update-dialog.models';
import { BranchRequestDto, SubmoduleUpdateDialogConfigService } from '../submodule-update-dialog-config.service';

import { tap }             from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';

import { WaitingForResponse }   from '@cs/common/utils';
import { CsHttpRequestOptions } from '@cs/core/http';


@Injectable({
				providedIn: 'root'
			})

export class SubmoduleUpdateServiceService {

	isWaiting = WaitingForResponse;

	constructor(@Inject(SubmoduleUpdateDialogConfigService) private service: SubmoduleUpdateDialogConfigService) {
	}

	/// -------------- Service Logic --------------- \\\
	public async CheckoutSubmoduleAsync(repository: RepositoryDto, childRepository: RepositoryDto, selectedBranch: BranchDto, updateRequest: UpdateRepositoryRequest, errorList: Map<RepositoryDto, RepositoryUpdateResult>) {
		updateRequest.repository = repository;
		updateRequest.request    = new BehaviorSubject<boolean>(false);
		updateRequest.message    = 'Updating Submodule on: ' + repository.name;

		await this.CheckoutSubmodule(repository, childRepository, selectedBranch, updateRequest, this.CreateErrorOptions(updateRequest, repository, errorList), errorList);
	}

	private async CheckoutSubmodule(repository: RepositoryDto, childRepository: RepositoryDto, selectedBranch: BranchDto, updateRequest: UpdateRepositoryRequest, options: CsHttpRequestOptions, errorList: Map<RepositoryDto, RepositoryUpdateResult>) {

		return new Promise<void>((resolve, reject) => {
			this.service
				.CheckoutSubmodule(repository.identifier, repository.hashOrBranch, childRepository.identifier, selectedBranch.commitHash, options)
				.pipe(tap(this.isWaiting.new(isLoading => updateRequest.request.next(isLoading))))
				.subscribe({
							   next:  checkoutResult => {
								   updateRequest.currentProgress = updateRequest.currentProgress + (100 / updateRequest.maxSteps);

								   this.AddMessage(errorList, repository, true, 'Changes Commited');

								   resolve();
							   },
							   error: error => {
								   reject(error);
							   }
						   });
		});

	}

	public async CommitRepositoryAsync(repository: RepositoryDto, commitMessage: string, updateRequest: UpdateRepositoryRequest, resultLog: Map<RepositoryDto, RepositoryUpdateResult>): Promise<Commit[]> {
		updateRequest.repository = repository;
		updateRequest.request    = new BehaviorSubject<boolean>(false);
		updateRequest.message    = 'Committing changes on ' + repository.name + ' | ' + repository.branch;

		return await this.CommitRepository(repository, commitMessage, updateRequest, this.CreateErrorOptions(updateRequest, repository, resultLog), resultLog);
	}

	private async CommitRepository(repository: RepositoryDto, commitMessage: string, updateRequest: UpdateRepositoryRequest, options: CsHttpRequestOptions, resultLog: Map<RepositoryDto, RepositoryUpdateResult>) {

		return new Promise<Commit[]>((resolve, reject) => {
			this.service
				.CommitFiles(repository.identifier, commitMessage, options)
				.pipe(tap(this.isWaiting.new(isLoading => updateRequest.request.next(isLoading))))
				.subscribe({
							   next:  commitResult => {
								   updateRequest.currentProgress   = updateRequest.currentProgress + (100 / updateRequest.maxSteps);
								   updateRequest.request.isStopped = true;

								   repository.hashOrBranch        = commitResult.value[0].hashOrBranch;
								   repository.unPushedCommitCount = repository.unPushedCommitCount + 1;

								   this.AddMessage(resultLog, repository, true, 'Changes Commited');

								   resolve(commitResult.value);
							   },
							   error: error => {
								   reject(error);
							   }
						   });
		});

	}

	public async PushRepositoryAsync(repository: RepositoryDto, updateRequest: UpdateRepositoryRequest, resultLog: Map<RepositoryDto, RepositoryUpdateResult>) {
		updateRequest.repository = repository;
		updateRequest.request    = new BehaviorSubject<boolean>(false);
		updateRequest.message    = 'Pushing repository ' + repository.name + ' | ' + repository.branch;

		await this.PushRepository(repository, updateRequest, this.CreateErrorOptions(updateRequest, repository, resultLog), resultLog);
	}

	private async PushRepository(repository: RepositoryDto, updateRequest: UpdateRepositoryRequest, options: CsHttpRequestOptions, resultLog: Map<RepositoryDto, RepositoryUpdateResult>) {
		return new Promise<void>((resolve, reject) => {
			this.service.PushRepository(repository.identifier, repository.hashOrBranch)
				.pipe(tap(this.isWaiting.new(isLoading => updateRequest.request.next(isLoading))))
				.subscribe({
							   next:  checkoutResult => {
								   updateRequest.currentProgress = updateRequest.currentProgress + (100 / updateRequest.maxSteps);
								   this.AddMessage(resultLog, repository, true, 'Push Success');

								   resolve();
							   },
							   error: error => {
								   reject(error);
							   }
						   });
		});
	}

	public async ReadRepositoriesAsync(repositories: RepositoryDto[], request: BehaviorSubject<boolean>): Promise<RepositoryDto[]> {
		return await this.ReadRepositories(repositories, request);
	}

	private async ReadRepositories(repositories: RepositoryDto[], request: BehaviorSubject<boolean>): Promise<RepositoryDto[]> {
		const uniqueIdentifiers: Array<string> = Array.from(new Set(repositories.map(repo => repo.identifier)));

		return new Promise<RepositoryDto[]>((resolve, reject) => {
			this.service
				.ReadRepositories(uniqueIdentifiers)
				.pipe(tap(this.isWaiting.new(isLoading => request.next(isLoading))))
				.subscribe({
							   next:     readResult => {
								   request.isStopped = true;

								   resolve(readResult.value);
							   }, error: error => {
						reject(error);
					}
						   });
		});
	}

	public async GetUnpushedCommitsAsync(repository: RepositoryDto, request: UpdateRepositoryRequest): Promise<Commit[]> {
		request.repository = repository;
		request.request    = new BehaviorSubject<boolean>(false);
		request.message    = 'Retrieving unpushed commits';

		return await this.GetUnpushedCommits(repository, request);
	}

	private async GetUnpushedCommits(repository: RepositoryDto, request: UpdateRepositoryRequest) {
		return new Promise<Commit[]>((resolve, reject) => {
			this.service.UnPushedCommits(repository.identifier, repository.branch)
				.pipe(tap(this.isWaiting.new(isLoading => request.request.next(isLoading))))
				.subscribe({
							   next:     result => {
								   request.request.isStopped = true;
								   request.currentProgress   = request.currentProgress + 1;

								   resolve(result.value);
							   }, error: error => {
						reject(error);
					}
						   });
		});
	}

	public async RemoveCommitAsync(repository: RepositoryDto, request: UpdateRepositoryRequest): Promise<string> {
		request.repository = repository;
		request.request    = new BehaviorSubject<boolean>(false);
		request.message    = 'Removing commits';

		return await this.RemoveCommit(repository, request);
	}

	// Removes previous commit and reassigns the new commit hash after reverting changes, using branch because commit sha can reference multiple branches
	private async RemoveCommit(repository: RepositoryDto, request: UpdateRepositoryRequest) {
		return new Promise<string>((resolve) => {
			this.service
				.RemoveUnpushedCommits(repository.identifier, repository.branch)
				.pipe(tap(this.isWaiting.new(isLoading => request.request.next(isLoading))))
				.subscribe({
							   next: result => {
								   request.request.isStopped = true;
								   request.currentProgress   = request.currentProgress + (100 / request.maxSteps);

								   resolve(result.value);
							   }
						   });
		});
	}

	CreateErrorOptions(updateRequest: UpdateRepositoryRequest, repository: RepositoryDto, resultLog: Map<RepositoryDto, RepositoryUpdateResult>) {
		return new CsHttpRequestOptions({
											errorResponseHandler: (error) => {
												if (error) {
													updateRequest.request.isStopped = true;
													updateRequest.message           = error.error;

													this.AddMessage(resultLog, repository, false, error.error);

													return true;
												}

												return true;
											}
										});
	}

	AddMessage(resultLog: Map<RepositoryDto, RepositoryUpdateResult>, repository: RepositoryDto, successFull: boolean, errorMessage: string) {
		if (resultLog.has(repository)) {
			resultLog.delete(repository);
			resultLog.set(repository, {isSuccessFull: successFull, message: errorMessage});
		} else {
			resultLog.set(repository, {isSuccessFull: successFull, message: errorMessage});
		}

	}

	async LoadRepositoryBranchesAsync(childRepository: RepositoryDto, skipCommitDifference: boolean, request: BehaviorSubject<boolean>) {
		return await this.LoadRepositoryBranches(childRepository, skipCommitDifference, request);
	}

	async LoadRepositoryBranches(childRepository: RepositoryDto, skipCommitDifference: boolean, request: BehaviorSubject<boolean>) {

		const dto: BranchRequestDto = {
			repositoryIdentifier: childRepository.identifier,
			commitHash:           childRepository.hashOrBranch,
			skipCommitDifference: skipCommitDifference
		};

		return new Promise<BranchDto[]>((resolve) => {
			this.service.GetRepositoryBranches(dto)
				.pipe(tap(this.isWaiting.new(isLoading => request.next(isLoading))))
				.subscribe({
							   next: result => {
								   console.log('Resulting Branches');
								   console.log(result.value);

								   request.isStopped = true;

								   resolve(result.value);
							   }
						   });
		});
	}
}
