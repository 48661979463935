import { NgModule }              from '@angular/core';
import { Routes, RouterModule }  from '@angular/router';
import { DownloadsComponent }    from './downloads.component';


const routes: Routes = [
  {
    path:    '', component: DownloadsComponent,
    resolve: {
      //downloadResolveResult: DownloadItemsResolver NO RESOLVER BECAUSE OF sluggish feeling
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DownloadsRoutingModule {
}
