import { BaseInput } from './base-input';

export class YearInput extends BaseInput {
  public years = [];
  public constructor(data: any) {
    super(data);

    let currentYear = new Date().getFullYear();
    for (let i = currentYear + 1; i >= 1960; i--) {
      this.years.push(i);
    }
  }
}
