import { Environment }       from '@cs/common';
import { DeploymentDetails } from './pm-deploy-info.models';

export abstract class CsApplicationSettings {
	environment: Environment;
	useMockData: boolean;
	injectModules: Array<any> = [];
	translationLocation: string;
	/**
	 * Location of Performance Manager api
	 */
	apiUrl: string;

	deploymentDetails: DeploymentDetails;
	googleMapsApiKey?: string;
}
