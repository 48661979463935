<div class="modal-header">
	<h2 mat-dialog-title class="modal-title">{{data.dialogTitle}}</h2>
	<span class="close-button cs-pointer"
		  (click)="onCloseClick()"
	><i class="mdi mdi-close"></i>
  </span>
</div>


<div mat-dialog-content>
	<div [csLoader]="requestInProgress$ | async"
		 class="div-loader "
		 [removeLoaderBody]="true"
		 [loaderContainerClasses]="['flex','justify-content-center','align-items-center', 'full-width','full-height', 'fade']"
		 [loaderAdditionalClasses]="['loader--big']"></div>
	<ng-container *ngIf="dialog">
		<p class="dialog-description">{{dialog.description}}</p>
		<cs-form-generator #form
						   [editMode]="'editonly'"
						   [isInEditMode]="true"
						   [dataSource]="dialog.formDefinition"
		>
		</cs-form-generator>
		<!--- Setting margin on form generator does not work for some reason --->
		<p class="dialog-footer-description">{{dialog.footer}}</p>
	</ng-container>

</div>
<div mat-dialog-actions class="dialog-actions" *ngIf="!(requestInProgress$ | async)">
	<button class="btn btn--primary btn--lg " [disabled]="!dialog" (click)="onOkClick()" tabindex="2">Ok</button>
	<button class="btn btn--secondary btn--lg" [disabled]="!dialog" (click)="onCloseClick()" tabindex="-1">Cancel
	</button>
</div>
