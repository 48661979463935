import { GitTableNavigationFilter, RepositoryDto } from '@cs/performance-manager/git-graph';
import { IConfigService }                          from '@cs/performance-manager/shared';
import { Observable }               from 'rxjs';
import { Result }                   from '@cs/core/generate';

// local imports


export abstract class GitSubmoduleTableConfigService implements IConfigService {

	moduleName = 'git-submodule-table';

	public abstract GetSubRepositories(filter: GitTableNavigationFilter, options?): Observable<Result<RepositoryDto[]>>;
}
