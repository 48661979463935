import { ICellBehaviourParams } from '../interfaces/ICellBehaviourParams';
import { isNullOrUndefined }    from '@cs/core';

export class CellBehavior {
  action: 'ToggleCellSelection'| 'OpenDialog' | 'NavigateToUrl' | 'Refresh' | 'Expand' | 'None';
  params: ICellBehaviourParams;

  constructor(init: Partial<CellBehavior> = null) {
    if (isNullOrUndefined(init))
      this.action = 'None';
    else
      Object.assign(this, init);
  }
}
