import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, Renderer2, ElementRef } from '@angular/core';
import { DataEntryStateService }                                                            from '../../state';
import { DataEntryStateColumnType }                                                         from '../../models/data-entry-state';
import {
	CsDataEntryStateRowComponent,
	DataEntryStateParentRow
}                                                                                           from '../data-entry-state-row/data-entry-state-row.component';
import { DataEntryStateColumn }                                                             from '../../models/data-entry-state-column';

import { DataViewColumnPosition } from '@cs/core';


@Component({
			   selector       : 'cs-data-entry-state-cell',
			   templateUrl    : './data-entry-state-cell.component.html',
			   changeDetection: ChangeDetectionStrategy.OnPush,
			   host           : {
				   class: 'data-entry-state-row__content-column'
			   }
		   })
export class CsDataEntryStateCellComponent implements OnInit {
	@Input() column: DataEntryStateColumn;
	@Input() position: DataViewColumnPosition;
	readonly dataEntryStateColumnType = DataEntryStateColumnType;
	@Input() hasBadges: boolean;

	constructor(@Inject(DataEntryStateParentRow) private host: CsDataEntryStateRowComponent,
				private _renderer: Renderer2,
				private _hostElement: ElementRef,
				private _dataEntryState: DataEntryStateService) {
	}

	ngOnInit(): void {

		this.updateClasses(this._hostElement.nativeElement);
	}

	toggleExpansionRow() {
		this.host.toggleExpansionRow();
	}

	stateEntryClicked($event: MouseEvent, column: DataEntryStateColumn) {
		this._dataEntryState.entryStateClicked({column, row: this.host.getRowSelectionObject()});
	}

	private updateClasses(el: any) {

		this._renderer.addClass(el, this.column?.visualState?.sticky
									? `data-entry-state__column--sticky`
									: 'data-entry-state__column--non-sticky');

		// 	[ngStyle]="{
		// 	justifyContent: column?.visualState?.alignment,
		// 		minWidth:position?.widthPx,
		// 		maxWidth:position?.widthPx,
		// 		left:position?.position+'px'
		// }"
		this._renderer.setStyle(el, 'minWidth', this.position?.widthPx);
		this._renderer.setStyle(el, 'maxWidth', this.position?.widthPx);
		this._renderer.setStyle(el, 'justifyContent', `${this.column?.visualState?.alignment}`);
		this._renderer.setStyle(el, 'left', `${this.position?.position}px`);
		this._renderer.setStyle(el, 'cursor', this.column.visualState.pointer);
	}


}
