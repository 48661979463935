<div class="data-entry-state__block f-body-s"
	 [matTooltip]="tooltip"
	 matTooltipPosition="above"
	 [ngStyle]="{
		 backgroundColor: bgColor,
		 color: textColor || borderColor,
		 borderColor: borderColor}"

>
	{{text}}
</div>

