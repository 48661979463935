import { Injector }                                       from '@angular/core';
import { FilterCompareBarQuery }                          from '@cs/components/filter-and-compare-bar';
import { DynamicButtonsAgent, DynamicButtonRegistration } from '@cs/performance-manager/shared';
import { ApprovalComponent }                              from './approval.component';
import { ApprovalResultParams }                           from './models/approval-data-described';


export class DefaultApprovalPageButtonsAgent extends DynamicButtonsAgent {

	setupButtons(): void {
		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'SubmitForApproval',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'UndoSubmitForApproval',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'SendApprovalReminder',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'Approve',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'UndoApprove',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'Reject',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));

		this.addButton(DynamicButtonRegistration.createServerButton({
																		buttonId:          'UndoReject',
																		executeTransition: (button: DynamicButtonRegistration, injector: Injector, pageComponent: unknown) => {
																			const filterCompareBarQuery = injector.get(FilterCompareBarQuery);

																			const resultParams = filterCompareBarQuery.getValue().mainbarResultParams as ApprovalResultParams;
																			const approval     = pageComponent as ApprovalComponent;

																			approval.buttonClicked(button.displayInstance.name, resultParams);
																		}
																	}
		));
	}

}
