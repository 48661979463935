<div class="stepper-item"
	 [class.is-active]="step.state === 'isActive'"
	 [class.is-disabled]="step.state === 'isDisabled'"
	 [class.is-error]="step.state === 'isError'"
	 [class.is-warning]="step.state === 'isWarning'"
	 [class.is-checked]="step.state === 'isChecked'"
	 [class.is-selected]="isSelected$ | async"
>
	<cs-wizard-step-icon [icon]="step.icon"
						 [showConnector]="!isLast"
						 [state]="step.state"
	></cs-wizard-step-icon>
	<div class="stepper-item--content flex flex-column">

		<div class="stepper-item--label"><i class="mdi mdi-chevron-right"></i>{{step.label}}</div>
		<div class="stepper-item--step-label"> {{"STEP"| translate}} {{step.index}}</div>
		<ng-container *ngIf="step.state === 'isError'">
			<div
				class="stepper-item--step-label stepper-item--step-label__error"> {{ errorMessage || ("GENERIC_ERROR_MESSAGE" | translate)}}</div>
		</ng-container>

	</div>
</div>
