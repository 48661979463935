import { Injectable, TemplateRef } from '@angular/core';
/**
 * Configuration service for the CsDatepicker component.
 * You can inject this service, typically in your root component, and customize the values of its properties in
 * order to provide default values for all the datepickers used in the application.
 */
@Injectable()
export class CsDatepickerConfig {
  public openOnFocus = false;
  public showButton = true;
  public buttonInnerHtml: string = '📅';
}
