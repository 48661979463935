// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { CsApplicationSettings }               from '@cs/common';
import { baseCustomerVars, deploymentVars } from './environment.base';

export const pmAppSettings: CsApplicationSettings = {
  environment:         deploymentVars.environment,
  useMockData:         deploymentVars.useMockData,
  translationLocation: deploymentVars.translationLocation,
  apiUrl:              deploymentVars.apiEndpoint, // Only change if the regular naming convention is not used
  injectModules:       [],
  deploymentDetails:   deploymentVars.deploymentDetails,
  googleMapsApiKey:    deploymentVars.googleMapsApiKey
};

export const CUSTOMER_VARS = baseCustomerVars;

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *s
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
