<div class="cs-statistics" @statisticsState>
  <div class="cs-statistics-data">
    <div class="container table-responsive">
      <table class="table" *ngFor="let stats of statisticData; let iStat = index">
        <thead>
        <tr>
          <th *ngFor="let columnLabel of stats.columns; let isFirst = first"
              [class.label]="isFirst">
            {{columnLabel.label}}
          </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let rowData of stats.data; let iData = index ">
          <td *ngFor="let rowValue of rowData.values; let iValue = index "
              [class.injected]="iValue===0">
            <div #hoveredValue class="cs-statistics-tooltip index-{{iData+1}}{{iValue+1}}">
              <p>{{tooltip.label}}</p>
            </div>
            <p (mouseover)="onItemClicked(iStat, iData, iValue)" (mouseout)="resetTooltip()">{{rowValue}}</p>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
