import {
	Component,
	OnInit,
	ChangeDetectionStrategy,
	Input,
	HostBinding,
	HostListener,
	ContentChild,
	Directive,
	TemplateRef, Output, EventEmitter
}                          from '@angular/core';
import { ListItem }        from '../../models/list-item';
import { BehaviorSubject } from 'rxjs';

@Directive({
						 selector: '[csListItemContent]'
					 })
export class ListItemContentDirective {
	constructor(public templateRef: TemplateRef<unknown>) {}
}

export interface ListItemChangeEventArgs {
	key: string | object;
	value: boolean;
}

@Component({
						 selector:        'cs-list-item',
						 templateUrl:     './list-item.component.html',
						 styleUrls:       ['./list-item.component.scss'],
						 changeDetection: ChangeDetectionStrategy.OnPush
					 })
export class ListItemComponent implements OnInit {

	/**
	 * Provide the icon name that should be set
	 */
	@Input() icon: string;

	/**
	 * The label of the item
	 */
	@Input() label: string;
	/**
	 * The top label of the item
	 */
	@Input() superLabel: string;

	/**
	 * The text below the label
	 */
	@Input() description: string;

	/**
	 * The key that represent the item
	 */
	@Input() key: string | object;

	/**
	 * List of children
	 */
	@Input() children: Array<ListItem>;

	/**
	 * Flag setting the item not selectable
	 */
	@Input() isDisabled  = false;
	/**
	 * Hook for cancelling the is Selected change notification
	 */
	@Input() preSelectionCheck: (item: ListItemComponent) => boolean;
	readonly isSelected$ = new BehaviorSubject(false);


	/**
	 * Flag if item is selected
	 */
	@Input()
	set isSelected(value: boolean) {
		this.isSelected$.next(value);
		this.onChange.emit({key: this.key, value});
	}

	get isSelected() {
		return this.isSelected$.getValue();
	}

	@Input() itemTemplate: TemplateRef<any>;

	@Output() onChange: EventEmitter<ListItemChangeEventArgs> = new EventEmitter<ListItemChangeEventArgs>();

	constructor() {
	}

	ngOnInit(): void {
	}

	@HostListener('click')
	private clickedHandler() {
		if (!this.isDisabled && this.preSelectionCheck(this))
			this.isSelected = !this.isSelected;
	}


}
