import { FilterBarItem }     from './filter-bar-element';
import { SubFilterItem }     from './filter-bar-sub-filter-item';
import { ViewSelectionItem } from './filter-bar-view-selection-item';

export class FilterBarDataSource<TResultParams> {
  activateComparison = false;
  apiParams: { [key: string]: string | number };
  filterElements: FilterBarItem[];
  navElements: FilterBarItem[];
  resultParams: TResultParams;
  subFilterItems: Array<SubFilterItem>;
  viewSelectionItems?: Array<ViewSelectionItem>;
}
