/* tslint:disable */
import { Injectable } from '@angular/core';

/**
 * MaskDefinition Interface
 *


 */
export interface MaskDefinition {


  validator?: any;

  cardinality?: number;

  definitionSymbol?: string;

  casing?: string;

  placeholder?: any;

  prevalidator?: MaskDefinition[];
}

/**
 * Input mask Definition service, exports/config definitions.
 */
@Injectable()
export class CsInputMaskDefinitions {

  /**
   * Mask definitions.
   *


   */
  definitions: { [key: string]: MaskDefinition } = {
    '9': {
      validator:        '[0-9]',
      cardinality:      1,
      definitionSymbol: '*'
    },
    'a': {
      validator:        '[A-Za-z\u0410-\u044F\u0401\u0451\u00C0-\u00FF\u00B5]',
      cardinality:      1,
      definitionSymbol: '*'
    },
    '*': {
      validator:   function () {
        return true;
      },
      cardinality: 1
    },
    'A': {
      validator:   '[A-Za-z\u0410-\u044F\u0401\u0451\u00C0-\u00FF\u00B5]',
      cardinality: 1,
      casing:      'upper' // auto uppercasing
    },
    '&': { // alfanumeric uppercasing
      validator:   '[0-9A-Za-z\u0410-\u044F\u0401\u0451\u00C0-\u00FF\u00B5]',
      cardinality: 1,
      casing:      'upper'
    },
    '#': { // hexadecimal
      validator:   '[0-9A-Fa-f]',
      cardinality: 1,
      casing:      'upper'
    }
  };

  constructor() {
    let self = this;

    return new Proxy(this, {
      get(target, prop) {
        if (!(prop in target)) {
          return self.definitions[<any> prop];
        }
        return target[prop];
      }
    });
  }

}
