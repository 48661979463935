import { SpinnerChangedEventArgs } from '@cs/components/spinner';
import { GridDataCell }            from '../models/grid-data-cell.model';
import { GridDataRow }             from '../models/grid-data-row.model';
import { GridGroup }               from '../models/grid-group.model';
import { GridSheet }               from '../models/grid-sheet.model';
import { CsDataGrid }              from '../data-grid.component';

export class CellClickEventArgs {
  gridItem: GridDataCell;
  gridRow: GridDataRow;
  gridSheet: GridSheet;
  event: MouseEvent;

  constructor(cell: GridDataCell, gridRow: GridDataRow, gridSheet: GridSheet) {
    this.gridItem = cell;
    this.gridRow = gridRow;
    this.gridSheet = gridSheet;
  }
}

export class CellSelectionChangedEventArgs extends CellClickEventArgs {
  gridGroup: GridGroup;

  constructor(cell: GridDataCell, gridRow: GridDataRow, gridGroup: GridGroup, gridSheet: GridSheet, event: MouseEvent) {
    super(cell, gridRow, gridSheet);
    this.gridGroup = gridGroup;
    this.event = event;
  }
}

export class CellTabbedEventArgs {
  gridItem: GridDataCell;
  gridRow: GridDataRow;
  gridSheet: GridSheet;
  cellIndex: number;
  event: KeyboardEvent;

  constructor(cellIndex: number, cell: GridDataCell, gridRow: GridDataRow, gridSheet: GridSheet, event: KeyboardEvent) {
    this.gridItem = cell;
    this.gridRow = gridRow;
    this.gridSheet = gridSheet;
    this.event = event;
    this.cellIndex = cellIndex;
  }
}

export class CellSpinnerChangedEventArgs {
  gridItem: GridDataCell;
  gridRow: GridDataRow;
  gridSheet: GridSheet;
  event: SpinnerChangedEventArgs;
  gridGroup: GridGroup;

  constructor(cell: GridDataCell, gridRow: GridDataRow, gridGroup: GridGroup, gridSheet: GridSheet, event: SpinnerChangedEventArgs) {
    this.gridItem = cell;
    this.gridRow = gridRow;
    this.gridSheet = gridSheet;
    this.gridGroup = gridGroup;
    this.event = event;
  }
}

export class CellActionClickEventArgs extends CellClickEventArgs {
  sheets: GridSheet[];
  grid: CsDataGrid;

  constructor(cell: GridDataCell,
              gridRow: GridDataRow,
              gridSheet: GridSheet,
              sheets: GridSheet[],
              grid: CsDataGrid) {
    super(cell, gridRow, gridSheet);
    this.sheets = sheets;
    this.grid = grid;

  }
}
