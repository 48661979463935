import { Component, Inject }           from '@angular/core';
import { DomSanitizer, SafeHtml }      from '@angular/platform-browser';
import { DashboardHtml }               from '../../models/dashboard-html';
import { DashboardPanelOptions }       from '../../models/dashboard-panel-options';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { IHtmlPanel }                  from '../../models/i-html-panel';
import { ArrayUtils }                  from '@cs/core';

@Component({
			   selector:    'cs-dashboard-information',
			   templateUrl: './dashboard-information.component.html',
			   styleUrls:   ['./dashboard-information.component.scss']

		   })
export class DashboardInformationComponent extends DashboardPanelComponentBase<DashboardHtml> {
	name: string;

	safeHtml: SafeHtml;


	set data(value: DashboardHtml) {
		this._data = value;
		this.setData();
	}

	get iconType() {
		if (this.panelOptions == null || this.panelOptions.panel == null)
			return '';

		const panel = this.panelOptions.panel as IHtmlPanel;
		if (!panel.infoType)
			return '';

		switch (panel.infoType) {
			case 'warning':
				return 'mdi mdi-18px mdi-alert-circle-outline hover-help';
			case 'danger':
				return 'mdi mdi-18px mdi-close-circle-outline hover-help';
			case 'info':
				return 'mdi mdi-18px mdi-information-outline hover-help';
			default:
				return '';
		}
	}

	constructor(private sanitizer: DomSanitizer, @Inject(DashboardPanelOptions) private panelOptions: DashboardPanelOptions) {
		super();
	}

	setData(): void {
		this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this._data.html);
	}

	dataChanged(value: DashboardHtml) {

	}

	update(data: DashboardHtml): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
		this.setData();
	}


}
