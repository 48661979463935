import { HomePmComponent } from './home-pm.component';
import { CsRoute }         from '@cs/common';


export class HomePmRoutingLoader {
  static getRoutes(children: CsRoute[]): CsRoute[] {
    return [
      {
        path:     '', component: HomePmComponent,
        children: [
          ...children
        ]
      }
    ];
  }
}



