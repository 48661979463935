import { Observable, of } from 'rxjs';
import { getPropertyOf }  from '@cs/core/utils';

export class CsNavItem {
	/**
	 * The full label for the item
	 */
	fullLabel?: string;
	/**
	 * The short label for the item
	 */
	label: string;
	/**
	 * The id of the item
	 */
	name: string;
	/**
	 * Icon to show next to the label
	 */
	icon?: string;

	/**
	 * Flag indicating if the item is selected
	 */
	active: boolean;
	/**
	 * Flag indicating if the item is selectable
	 */
	disabled: boolean;
	/**
	 * Flag that indicates if a item is shown in the menu
	 */
	show: Observable<boolean>;
	/**
	 * Flag indicating if the navigation should be resolved from baseUrl/:resolveFromModule or baseUrl/:bu-line/:resolveFromModule
	 */
	resolveFromModule: string;
	/**
	 * use this to override the default name as path
	 */
	path?: string;
	/**
	 * Preserve the query-params when switching to page
	 */
	preserveQueryParams: boolean;
	/**
	 * Set the badge content for a nav item, usually it's the amount of new items
	 */
	badge?: string | number;

	constructor(init: Partial<CsNavItem>) {
		this.label               = getPropertyOf(init, 'label', null);
		this.name                = getPropertyOf(init, 'name');
		this.resolveFromModule   = getPropertyOf(init, 'resolveFromModule', '');
		this.preserveQueryParams = getPropertyOf(init, 'preserveQueryParams', false);
		this.show                = getPropertyOf(init, 'show', of(true));
		this.active              = getPropertyOf(init, 'active', false);
		this.disabled            = getPropertyOf(init, 'disabled', false);
		this.icon                = getPropertyOf(init, 'icon', null);
		this.path                = getPropertyOf(init, 'path', null);
		this.badge               = getPropertyOf(init, 'badge', null);
		this.fullLabel           = getPropertyOf(init, 'fullLabel', null);
	}
}
