export interface ImportResultMessage {
  name?: string;
  success?: boolean;
  messages?: Array<string>;
  haswarning?: boolean;
  inserted?: number;
  processed?: number;
  updated?: number;
  deleted?: number;
  ignored?: number;
}

export class CSImportResultMessage {
  success = false;

  messages = [];

  haswarning = false;

  inserted = 0;

  processed = 0;

  updated = 0;

  deleted = 0;

  ignored = 0;

  name: string;

  static isImportResultMessage(obj: any): obj is ImportResultMessage {
    return ((<ImportResultMessage>obj).success !== undefined
      && (<ImportResultMessage>obj).messages !== undefined
      && (<ImportResultMessage>obj).haswarning !== undefined
      && (<ImportResultMessage>obj).inserted !== undefined
      && (<ImportResultMessage>obj).processed !== undefined
      && (<ImportResultMessage>obj).updated !== undefined
      && (<ImportResultMessage>obj).deleted !== undefined
      && (<ImportResultMessage>obj).ignored !== undefined
    );
  }

  constructor(initial?: Partial<ImportResultMessage>) {
    Object.assign(this, initial);
  }

}

export class CfResult<T> extends CSImportResultMessage {
  data?: T;
}
