import { BrandingState, BrandingStore } from './branding.store';
import { Injectable }                   from '@angular/core';


@Injectable({providedIn: 'root'})
export class BrandingService {

	constructor(private store: BrandingStore) {
	}

	update(state: Partial<BrandingState>) {
		this.store.update(state);
	}

}
