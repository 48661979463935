import { GitTableNavigationFilter, RepositoryDto } from '@cs/performance-manager/git-graph';
import { IConfigService }                          from '@cs/performance-manager/shared';
import { Observable }                              from 'rxjs';

import { Result }               from '@cs/core/generate';
import { CsHttpRequestOptions } from '@cs/core/http';

// local imports


export abstract class GitRootTableViewConfigService implements IConfigService {

	moduleName = 'git-customer-table-view';

	public abstract GetRepositories(filter: GitTableNavigationFilter, options?: CsHttpRequestOptions): Observable<Result<RepositoryDto[]>>;
}
