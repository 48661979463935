import { Component, Input, ElementRef, OnChanges, SimpleChanges, Renderer2 } from '@angular/core';
import { isNullOrUndefined }                                                from '@cs/core';

import { Tooltip }                             from './models/tooltip';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector:    'cs-statistics-panel-table',
  templateUrl: './statistics-panel-table.component.html',
  animations:  [
    trigger('statisticsState', [
      transition(':enter', [ // each time the binding value changes

        style({opacity: 0}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 1
          }))

      ]),
      transition(':leave', [ // each time the binding value changes

        style({opacity: 1}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 0
          }))

      ])
    ])
  ]
})
export class CsStatisticsPanelTableComponent implements OnChanges {
  tooltip: Tooltip = new Tooltip();
  selectedDiv: ElementRef;

  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() statisticData: any;


  constructor(private elementRef: ElementRef,
              private renderer: Renderer2) {
  }

  onItemClicked(iStat: number, iData: number, iValue: number) {
    this.resetTooltip();
    if (isNullOrUndefined(this.statisticData[iStat])
      || isNullOrUndefined(this.statisticData[iStat].data[iData])
      || !this.statisticData[iStat].data[iData].hasOwnProperty('tooltip'))
      return;

    let tooltips = this.statisticData[iStat].data[iData].tooltip;
    for (let tooltip in tooltips) {
      if (tooltips[tooltip].index == (iValue + 1)) {
        this.tooltip     = tooltips[tooltip];
        this.selectedDiv = this.elementRef.nativeElement.querySelector('.index-' + (iData + 1) + (iValue + 1));
        this.renderer.setStyle(this.selectedDiv, 'display', 'inline-table');
      }
    }
  }

  resetTooltip() {
    if (this.selectedDiv) {
      this.renderer.setStyle(this.selectedDiv, 'display', 'none');
    }
    this.tooltip = new Tooltip();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('statisticData')) {
      // SET THE HEADERTITLE as first column label when column label is not provided
      const data = changes.statisticData.currentValue;
      for (const row of data) {
        if (row.columns && row.columns.length > 0) {
          if (!isNullOrUndefined(row.columns[0].label) && row.columns[0].label === '') {
            row.columns[0].label = row.headerLabel;
          }
        }
      }
    }
  }
}
