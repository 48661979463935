<div class="cs-panel-header environment-view__content">

	<div class="cs-content">

		<div class="cs-panel-header environment-view__title-div">

			<div class="environment-view__title-left">
				<h1 class="f-h1">Cohelion Environment Overview</h1>
			</div>

			<div class="environment-view__title-mid">
				<h3 class="f-h3">Submodules Selected: {{getTotalSelectedAmount()}}</h3>
			</div>

			<div class="environment-view__title-right">
				<button class="btn btn-md btn--primary" (click)="updateRepositoryEvent()"
						[disabled]="getTotalSelectedAmount() < 1">Update Selected
				</button>
			</div>

		</div>

		<div class="cs-panel-header--content environment-view__table-content-holder">

			<ng-container *ngIf="headRepositoryMap.size > 0">
				<ng-container *ngFor="let clientRepository of headRepositoryMap | keyvalue">
					<RepositoryEnvironmentTable [repository]="clientRepository"
												[branches]="branches"
												[outputArray]="repositoriesToUpdate">
					</RepositoryEnvironmentTable>
				</ng-container>
			</ng-container>

			<ng-container *ngIf="headRepositoryMap.size < 1 || headRepositoryMap == undefined">
				<div class="header-div header-div__no-border header-div__title-left">
					<h3 class="f-h3">No Repositories Found</h3>
				</div>
			</ng-container>

		</div>

	</div>

</div>
