<ng-container *ngTemplateOutlet="commitInfoScreen"></ng-container>

<ng-template #commitInfoScreen let-repository>

	<ng-container>

		<div class="simple-dialog dialog-{{type}} update-dialog__commit-info">

			<div class="update-dialog__commit-info__content">

				<div class="cs-panel-header--header update-dialog__commit-info__content__header">
					<h1 class="f-h1">Commits:</h1>
				</div>

				<div class="update-dialog__commit-info__content__inner">
					<ng-container *ngIf="commits.length > 0">
						<ng-container *ngFor="let commit of commits; let i = index">

							<div class="update-dialog__commit-info__content__commit-detail">

								<div class="update-dialog__commit-info__content__commit-detail__left">
									<h2 class="update-dialog__heavy-font">Commit: {{commit.hashOrBranch}}</h2>
									<h3 class="update-dialog__light-font">Author: {{commit.author}}</h3>
									<h3 class="update-dialog__light-font">Date:   {{commit.date}}</h3>

									<h3 class="update-dialog__light-font">
										{{commit.message}}
									</h3>
								</div>

								<div *ngIf="i == 0" class="update-dialog__commit-info__content__commit-detail__right">
									<button type="button" class="update-dialog__commit-info__remove-button"
											[matTooltip]="'Push Commit'"
											(click)="PushRepositoryArrayAsync([selectedRepository])">
										<i class="mdi mdi-plus mdi-24px hover-pointer" style="color: green">
										</i>
									</button>

<!--									<button type="button" class="update-dialog__commit-info__remove-button"-->
<!--											[matTooltip]="'Remove Commit'"-->
<!--											(click)="RemoveCommitEvent(selectedRepository)">-->
<!--										<i class="mdi mdi-minus mdi-24px hover-pointer" style="color: #e11919"></i>-->
<!--									</button>-->

								</div>

							</div>
						</ng-container>
					</ng-container>

					<ng-container *ngIf="commits.length < 1">
						<div class="update-dialog__commit-info__content__commit-detail">

							<div class="update-dialog__commit-info__content__commit-detail__left">
								<h2 class="update-dialog__heavy-font">No Un-Pushed Commits detected</h2>
							</div>
						</div>
					</ng-container>

				</div>


			</div>

			<div class="update-button-holder">

				<div class="update-button-holder__left">
					<button class="btn btn-md dialog--cancel" (click)="back()" tabindex="1">
						Back
					</button>
				</div>

				<div class="update-button-holder__right">

					<button *ngIf="commits.length > 0" class="btn btn-md btn--orange" (click)="RemoveCommitEvent(selectedRepository)"
							tabindex="1">
						Remove Commits
					</button>

					<button class="btn btn-md dialog--cancel" (click)="onNoClick()" tabindex="1">
						Close
					</button>

				</div>

			</div>

		</div>

	</ng-container>

</ng-template>
