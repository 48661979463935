import { TableDataRowLayout } from '@cs/core';

export class TableRowClickEventArgs<T> {
  item: T;
  row: TableDataRowLayout;

  constructor(item: T, row: TableDataRowLayout) {
    this.item = item;
    this.row  = row;
  }
}
