<div [@loadingPanelState]="isLoading" [@listAnimation]="grid.length">
  <ul *ngFor="let row of grid" class="flex-container" >
    <li *ngFor="let column of row" class="flex-item cs-panel"
        [style.height] = "height"
        [style.width] = "width"
        [class.loading] = "isLoading"
				[class.centered] = "centered">
        <div class='user' >
          <div class="lines">
            <h4></h4>
          </div>
          <div class="avatar"></div>
          <div class="lines">
            <h2></h2>
            <h3></h3>
            <h3></h3>
            <h5></h5>
            <p></p>
          </div>
        </div>
    </li>
  </ul>
</div>
