<div class="dashboard-grouped-list">

  <div class="group-list" *ngFor="let group of groupItems">
    <div class="group-header-row">
      <ng-container *ngFor="let header of group.headers; let first= first">
        <div *ngIf="first" class="group-label truncate"
             [matTooltipDisabled]="!group.isTruncated"
             matTooltipPosition="above"
             matTooltip="{{group.label}}"
             (mouseover)="detectTruncatedField($event, group)"
             [ngStyle]="{width: 'calc(100% / '+ (group.headers.length) + ')',
             minWidth: 'calc(100% / '+ (group.headers.length) + ')',
             maxWidth: 'calc(100% / '+ (group.headers.length) + ')'}">

          {{group.label}}
        </div>
        <div *ngIf="!first" class="grouped-list-header-item"
             matTooltipPosition="above"
             matTooltip="{{header.originalHeader.description}}"
             [ngStyle]="{width: 'calc(100% / '+ (group.headers.length) + ')',
             minWidth: 'calc(100% / '+ (group.headers.length) + ')',
             maxWidth: 'calc(100% / '+ (group.headers.length) + ')'}">

          <div class="grouped-list-header-item-badge" (mouseover)="detectTruncatedField($event, header)"
               [matTooltipDisabled]="!header.isTruncated"
               matTooltipPosition="above"
               matTooltip="{{header.value}}"> {{header.value}}</div>
        </div>
      </ng-container>
    </div>
    <div class="grouped-list-row-container">
      <div class="grouped-list-row"
           *ngFor="let row of group.rows; let rowIndex = index">
        <div class="grouped-list-row-item"
             *ngFor="let item of row.items; let first= first"
             [ngStyle]="{width: 'calc(100% / '+ (group.headers.length) + ')',
             minWidth: 'calc(100% / '+ (group.headers.length) + ')',
             maxWidth: 'calc(100% / '+ (group.headers.length) + ')'}"
        >
          <div class="sign" *ngIf="first && rowIndex > 0">∟</div>
          <div class="truncate"
               [matTooltipDisabled]="!item.isTruncated"
               matTooltipPosition="above"
               matTooltip="{{item.value}}"
               (mouseover)="detectTruncatedField($event, item)">
            {{item.value}}
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
