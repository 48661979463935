import { ColgroupSetting }  from './colgroup-setting';
import { DataGridCellType } from '../enums/data-grid.enum';


export class ColgroupSettings {
  /**
   * total width for the first injected columns
   */
  labelsTotalWidth              = 300;
  /**
   * Set the default size for a cellType
   */
  sizes: Array<ColgroupSetting> = [
    new ColgroupSetting(DataGridCellType.Label, this.labelsTotalWidth, `px`),
    new ColgroupSetting(DataGridCellType.Data, 75, `px`),
    new ColgroupSetting(DataGridCellType.Total, 80, `px`),
    new ColgroupSetting(DataGridCellType.Offset, 50, `px`)
  ];
  /**
   * Set the table to a fixed size and disable streching to parent container
   */
  fixedSize                     = false;

  parsedColgroup: string[] = [];

}
