import { noop }              from 'rxjs';
import { SimpleChange }      from '@angular/core';
import { isNullOrUndefined } from './determine.utils';


export interface ComponentChange<T, P extends keyof T> extends SimpleChange {
	previousValue: T[P];
	currentValue: T[P];
	firstChange: boolean;
}

export type ComponentChanges<T> = {
	[P in keyof T]?: ComponentChange<T, P>;
};

export function whenChanging<T, P extends keyof T>(key: ComponentChange<T, P>, ignoreNullOfUndefined = false) {

	return {
		execute: (callback: (value: ComponentChange<T, P>) => void) =>
							 isNullOrUndefined(key)
								 ? noop()
								 : ((ignoreNullOfUndefined && isNullOrUndefined(key.currentValue)
									 ? noop()
									 : callback(key)))
	};

}
