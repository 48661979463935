import { LoadChildren, ResolveData, Route, RunGuardsAndResolvers, UrlMatcher } from '@angular/router';
import { Type }                                                                from '@angular/core';
import { CsHttpRequestOptions }                                                from '@cs/core/http';
import { DataDescribed }                                                       from '@cs/core/generate';


/**
 * Navigation meta data used for tagging routes. The tagged routes can be
 * used to generate a navigation element
 */
export class RouteMetaData {
	/**
	 * The label could be null or undefined, if so trigger nameResolver to resolve a name from the datadescribe
	 * in case of showing the Name of an item,
	 * when no resolvement is necessary you can just provide an string
	 */
	label?: string;
	/**
	 * The level the route, is used in combination with group to create a tree from a flat list
	 */
	depth?: number;
	/**
	 * Groupname for the route
	 */
	group?: string;
	preRoute?: string[];
	icon?: string;

	/**
	 * Function is called when the label is an function, Always pass the option
	 * so the application wide error handler is overridden by an catch all http status codes
	 */
	nameResolver?: (crumb: { [key: string]: any }, csOptions: CsHttpRequestOptions, data?: DataDescribed<any>) => string | Promise<string>;

	constructor(init: Partial<RouteMetaData>) {
		Object.assign(this, init);
	}

}

/**
 * Simple flat navigation item used in the navigation element
 */
export class NavItemRoute {
	routeMetaData: RouteMetaData;
	path: string;

	constructor(init: Partial<NavItemRoute>) {
		Object.assign(this, init);
	}
}

export class CsRoute implements Route {
	data?: { routeMetaData?: RouteMetaData, [name: string]: any, };
	path?: string;
	pathMatch?: string;
	matcher?: UrlMatcher;
	component?: Type<any>;
	redirectTo?: string;
	outlet?: string;
	canActivate?: any[];
	canActivateChild?: any[];
	canDeactivate?: any[];
	canLoad?: any[];
	resolve?: ResolveData;
	children?: CsRoute[];
	loadChildren?: LoadChildren;
	runGuardsAndResolvers?: RunGuardsAndResolvers;
}
