import { Injectable }                from '@angular/core';
import { CsFormGeneratorDataSource } from '@cs/components/form-generator';
import { Report }                    from '../models/report';
import { Store, StoreConfig }        from '@datorama/akita';


export interface ReportingState {
	actAsDashboard: boolean;
	activeReportForm: CsFormGeneratorDataSource;
	activeReport: Report;
	selection: {
		tabname: string;
		selection: any;
	};
}

export function createInitialStateReportingState(): ReportingState {
	return {actAsDashboard: false, activeReport: null, activeReportForm: null, selection: null};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'reporting'})
export class ReportingStore extends Store<ReportingState> {

	constructor() {
		super(createInitialStateReportingState());
	}

}

