import { NgModule }              from '@angular/core';
import { CsScrollToDirective }   from './scroll-to';
import { CsScrollPageDirective } from './scroll-page.directive';


@NgModule({
											imports:      [],
											exports:      [CsScrollPageDirective],
											declarations: [CsScrollPageDirective]
										}
)
export class CsScrollPageModule {
}


@NgModule({
											imports:      [],
											exports:      [CsScrollToDirective],
											declarations: [CsScrollToDirective]
										}
)
export class CsScrollToModule {
}
