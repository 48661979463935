import { Injector }   from '@angular/core';
import {
	DataGridLookupAction, IDataEntryInfo
}                     from '@cs/components/data-grid';
import {
	LookupAgent
}                     from '@cs/components/shared';
import {
	DataDescribed, isNullOrUndefined, LoggerUtil, Result, TableDataCell, TableDataRowLayout
}                     from '@cs/core';
import {
	TranslateService
}                     from '@ngx-translate/core';
import { Observable } from 'rxjs';
import {
	PredictionData, PredictionHeaderType, PredictionTable, PredictionTableHeader, PredictionTableRow, PredictionValue
}                     from './prediction-data.model';
import {
	PredictionDataDescribed
}                     from './slider-edit.modal/prediction-data.described';


export class PredictionCalculation {
  /**
   * The identification for the calculation
   */
  id: string;

  /**
   * The id used to find the corresponding calculation
   */
  changedCellId: 'editCurrentValue' | string;

  /**
   * The calculation to execute.
   */
  calculation: (cell: TableDataCell, row: TableDataRowLayout, conversionFactors: {}) => void;
}


export abstract class DataEntryPredictionConfigService {

  abstract getPredicationInfo(datagridName: string,
                              selection: { [key: string]: any },
                              params?: { [key: string]: any }): Observable<Result<DataDescribed<any>>>;


  abstract savePredictionData(saveData: PredictionDataDescribed,
                              selection: { [key: string]: any },
                              params?: { [key: string]: any }): Observable<Result<any>>;


  abstract getEventTypes(): Observable<Result<Array<{ label: string, id: string }>>> ;


  calculatePrediction(cell: TableDataCell, row: TableDataRowLayout, conversionFactors: {}) {
    const foundCalculation = this.registeredPredictionCalculations.find(value => value.changedCellId === cell.id);
    if (isNullOrUndefined(foundCalculation)) {
      LoggerUtil.log(`No calculation provided for ${cell.id},
      the registeredPredictionCalculations contains ${this.registeredPredictionCalculations.length} entries`);
      return false;
    }
    foundCalculation.calculation(cell, row, conversionFactors);
    return true;
  }

  registerPredictionCalculation(calc: PredictionCalculation) {
    this.registeredPredictionCalculations.push(calc);
  }

  private registeredPredictionCalculations: Array<PredictionCalculation> = [];
}

export function convertLegacyDataEntryInfoToPredictionInfo(dataEntryInfo: IDataEntryInfo, injector: Injector): PredictionData {
  const i8n = injector.get(TranslateService);


  const predicationData = new PredictionData();
  const predictionTable = new PredictionTable();

  const label = new PredictionTableHeader();
  label.type  = PredictionHeaderType.Label;
  label.label = i8n.instant('MONTH');
  predictionTable.columns.push(label);

  for (let index = 0; index < dataEntryInfo.infoValues.length; index++) {
    const infoValue = dataEntryInfo.infoValues[index];
    const header    = new PredictionTableHeader();
    header.label    = infoValue.label;
    header.type     = PredictionHeaderType.Readonly;
    predictionTable.columns.push(header);
  }
  const orderedMonths = Object.keys(dataEntryInfo.conversionData);
  // Convert the conversion data to rows
  for (const month of orderedMonths) {
    if (month === 'overall') continue;

    const conversionData = dataEntryInfo.conversionData[month];
    const row            = new PredictionTableRow();
    const lookupAction   = new DataGridLookupAction({key: 'idMonth', display: 'labelMin'});
    const monthLabel     = LookupAgent.resolve(conversionData.idMonth,
      lookupAction.key, lookupAction.display);
    row.id               = month;
    const labelValue     = new PredictionValue();
    labelValue.value     = monthLabel;
    row.values.push(labelValue);

    const valueValue = new PredictionValue();
    valueValue.value = 0;
    row.values.push(valueValue);

    predictionTable.rows.push(row);
  }

  predicationData.predictionTable = predictionTable;

  return predicationData;
}
