import { Directive, ElementRef, AfterViewInit } from '@angular/core';

@Directive({
												selector: '[csScrollTo]'
											})
export class CsScrollToDirective implements AfterViewInit {

	constructor(private el: ElementRef) {}

	ngAfterViewInit() {
		setTimeout(() => { // Timeout to ensure the DOM is loaded
			this.el.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
		}, 0);
	}
}
