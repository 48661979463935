export class WidgetLayout {
	showLabel?: boolean;
	width?: string;
	minWidth?: string;
	autoComplete?: string;
	readOnly?: boolean;
	readOnlyAsText?: boolean;
	textAlign?: 'center' | 'left' | 'right';
	flexGrow?: number;
	flexShrink?: number;

	constructor(init: Partial<WidgetLayout>) {
		Object.assign(this, init);
	}
}
