import { Pipe, PipeTransform } from '@angular/core';
import { toKebabCase }         from '@cs/core';

@Pipe({name: 'kebabify'})
export class KebabifyPipe implements PipeTransform {
	transform(value: string, args: any[] = null): string {
		if (value === null) return value;
		return toKebabCase(value);
	}
}
