import { AbstractControl, ValidationErrors }                                       from '@angular/forms';
import { validateValidators }                                                      from '@cs/components/shared';
import { getErrorMessagesFromValidators, IValidationAnnotation, ValidationResult } from '@cs/core/generate';
import { isEmpty, isEmptyObject }                                                  from '@cs/core/utils';
import { TableDataCellState }                                                      from './table-data-cell-state.model';


export class TableDataCell {
	truncate: boolean;
	validators: IValidationAnnotation[];

	classList: string[]       = [];
	parentRowId: string;
	state: TableDataCellState = new TableDataCellState();

	errors: ValidationErrors;
	/**
		* Identification for the data, most of the time it's the {@link TableHeader.id}
		*/
	id: string;
	/**
		* use this template for rendering
		*/
	template: string;

	/**
		* Collapsible icon
		*/
	collapseIcon: boolean;
	/**
		* Error messages
		*/
	messages: ValidationResult[];

	get displayValue(): string {
		return this._displayValue;
	}

	set displayValue(value: string) {
		this._displayValue = value;
		this._value        = value;
	}


	get value(): any {
		return this._value;
	}

	set value(value: any) {
		this._value        = value;
		this._displayValue = value;
	}

	constructor(id: string, value: any, template: string = null) {
		this.id             = id;
		this.value          = value;
		this._originalValue = value;
		this.template       = template;
	}

	isChanged() {
		return this.value + '' !== this._originalValue + '';
	}

	isEmpty() {
		return isEmpty(this.value);
	}

	validate(): ValidationErrors {

		this.errors        = validateValidators(this.validators, this);
		this.state.invalid = !isEmptyObject(this.errors);
		this.messages      = getErrorMessagesFromValidators(this as unknown as AbstractControl, this.validators);
		return this.errors;
	}

	/**
		* value that is used to display the value in the UI
		*/
	private _displayValue: string;

	/**
		* the value we manipulate
		*/
	private _value: any;
	private _originalValue: any;
}
