/**
 * Created by alex on 11-7-2017.
 */

import {
  Input, TemplateRef, Directive, OnInit
}                               from '@angular/core';
import { TemplateStoreService } from '../services/template-store.service';
import { UiTypes }              from '../enums/data-grid.enum';

@Directive({
  selector: '[csReferenceTemplate]'
})
export class CsReferenceTemplate implements OnInit {

  @Input('csReferenceTemplate') type: UiTypes; // header | body

  constructor(public template: TemplateRef<any>, private store: TemplateStoreService) {

  }

  ngOnInit(): void {
    this.store.add(this.type, this.template);
  }

  getType(): string {
    return this.type.toString();
  }
}
