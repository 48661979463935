import { ChangeDetectorRef, Component, ElementRef, Inject, OnInit }               from '@angular/core';
import { DataTransforms }                                                         from '@cs/common';
import { CsPopoverData, CsPopoverOverlayBase, CsPopoverOverlayRef, GridDataCell } from '@cs/components/data-grid';
import { FilterCompareBarQuery }                                                  from '@cs/components/filter-and-compare-bar';
import { isNullOrUndefined }                                                      from '@cs/core';
import { Observable }                                                             from 'rxjs';

@Component({
			   selector:    'pmc-data-source-hover',
			   templateUrl: './data-source-hover.component.html'
		   })

export class DataSourceHoverComponent extends CsPopoverOverlayBase implements OnInit {

	mainPresetLabel$: Observable<string>;
	comparebarPresetLabel$: Observable<string>;
	offsetPresetLabel$: Observable<string>;

	datasources: Array<{
		iddatasource: number,
		value: number
	}>;
	compareDatasources: Array<{
		iddatasource: number,
		value: number
	}>;
	hasCompareCell: boolean;
	hasCompareData: boolean;

	constructor(public element: ElementRef,
				public cdr: ChangeDetectorRef,
				@Inject(FilterCompareBarQuery) public filterCompareBarQuery: FilterCompareBarQuery,
				@Inject(CsPopoverData) public data: GridDataCell,
				@Inject(CsPopoverOverlayRef) public overlayRef: CsPopoverOverlayRef) {
		super();
		const clone = data.cellData.map(item => Object.assign({}, item));

		// check if there is a compare cell
		this.hasCompareCell = !isNullOrUndefined(data.compareCell);

		this.datasources = DataTransforms.mergeSameDesignations(clone);

		if (this.hasCompareCell) {
			this.hasCompareData     = data.compareCell.value !== null;
			const compareClone      = data.compareCell.cellData.map(item => Object.assign({}, item));
			this.compareDatasources = DataTransforms.mergeSameDesignations(compareClone);
		}
	}

	ngOnInit(): void {
		super.initPopover();
		this.mainPresetLabel$       = this.filterCompareBarQuery.select(store => store.mainbarPresetLabel);
		this.comparebarPresetLabel$ = this.filterCompareBarQuery.select(store => store.comparebarPresetLabel);
		this.offsetPresetLabel$     = this.filterCompareBarQuery.select(store => store.offsetPresetLabel);
	}

}
