<ng-container *ngIf="!isInFilterBar">
  <div class="datepicker-wrapper">
    <div class="input-group" id="input-wrapper" (click)="_disabled ? this.close() : this.toggle($event)">
      <span class="datepicker-title">{{label}}</span>
      <input #inputDp type="text" [disabled]="_disabled" style="pointer-events: none;" autocomplete="off"
             class="form-control"
             [attr.placeholder]="placeholder"
             [(ngModel)]="dateModel" (focus)="onFocus(dp)" (blur)="onBlur(dp, $event)" ngbDatepicker
             #dp="ngbDatepicker"/>
      <button *ngIf="showButton" type="button" class="input-group-addon btn btn-outline-secondary"
              [innerHTML]="buttonInnerHtml"></button>
      <i class="icon icon-arrow"></i>

      <div *ngIf="dp.isOpen()" class="btn-group calendar-buttons">
        <button type="button" id="selectToday" (click)="selectToday()" class="btn btn--blue btn-md">Today</button>
        <button type="button" class="btn btn-primary btn-md">Submit</button>
      </div>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="isInFilterBar">
  <div  class="dropdown dropdown-filter datepicker-in-filterbar" [class.no-buttons]="!showButtons">
    <div  class="btn btn-default advanced-dropdown-toggle dropdown-datepicker"
          id="dropdownMenuButton"
          data-toggle="dropdown" aria-haspopup="true"
          (click)="openWhenInFilterBar()"
          aria-expanded="false">
      <span #datePicker>{{title}}</span>

      <input type="text" [disabled]="_disabled" style="pointer-events: none;" autocomplete="off"
             class="form-control value"
             [attr.placeholder]="placeholder"
             [(ngModel)]="dateModel" ngbDatepicker #dp="ngbDatepicker"
             [placement]="'bottom'" [positionTarget]="datePicker"/>
      <i class="icon icon-calendar"></i>
    </div>

    <div *ngIf="showButtons && dp.isOpen()" class="btn-group calendar-buttons">
      <button type="button" id="selectToday" (click)="selectToday()" class="btn btn-tertiary btn-md">Today</button>
      <button type="button" class="btn btn-primary btn-md">Submit</button>
    </div>
  </div>
</ng-container>
