/// <reference types="@types/google.visualization" />

import { of, Subject }         from 'rxjs';



export interface ChartItemClickEventArgs<T = any> {
	colIndex: number;
	rowIndex: number;
}

export abstract class CsChartProvider {

	abstract clickOutput$: Subject<ChartItemClickEventArgs>;
	isLegendClickHandeld = false;

	static isReady(): boolean {
		throw Error('Not implemented isReady Function. Please implement in extended class');
		return false;
	}

	/**
	 * Load all the dependencies for the chart lib
	 */
	static loadDependencies(): Promise<boolean> {
		throw Error('Not implemented loadDependencies Function. Please implement in extended class');
		return of(false).toPromise();
	}

	/**
	 * The method that renders the chart
	 */
	abstract drawChart(chartOptions, chartType, chartData, hostElement: HTMLElement);

	abstract setupClickHandlers();

	// abstract mapToDataTable(dataSet: unknown, properties: Array<string>): google.visualization.DataTable;

	abstract getMetaProperties(event: ChartItemClickEventArgs): {
		rowProperties: any,
		columnProperties: any
	};

	getDebugInfo(): any {
		throw Error('Not implemented getDebugInfo Function. Please implement in extended class');
	}

	hideLegend(htmlElement: HTMLElement) {
		throw Error('Not implemented hideLegend Function. Please implement in extended class');
	}

	hideColumn(column: number) {
		throw Error('Not implemented hideColumn Function. Please implement in extended class');
	}
}


export abstract class CsChartLoaderSetup<TClickEventArgs = any, output = any> {

	// abstract chartProvider: CsChartProvider;
	//
	// abstract clickOutput$: Subject<TClickEventArgs>;

	abstract createNewChartProviderInstance(): CsChartProvider;

	abstract mapToDataTable(dataDescribed: unknown, properties: Array<string>): Promise<{ dataTable: output, legendItems: Array<object> }>;

	abstract getMetaProperties(event: ChartItemClickEventArgs, chartProvider: CsChartProvider);
}

export type CsChartRoles =
	'annotation'
	| 'annotationText'
	| 'certainty'
	| 'emphasis'
	| 'interval'
	| 'scope'
	| 'style'
	| 'tooltip'
	| 'tooltip_label_format';
export type CsCalculation = 'calculation';
export type CsChartRolesType = { [K in CsChartRoles]: string };
export type CalculationType = { [K in CsCalculation]: string };

export enum CsTootipType {
	Default      = 'default',
	Total        = 'total',
	Default_HTML = 'default_html',
}

export interface ChartColumnDescription {
	roles: CsChartRolesType;
	canFilter: boolean;
}


export interface ColumnDescriptions {
	[key: string]: ChartColumnDescription;
}


export interface TooltipCalculations {
	[key: string]: CalculationType;
}


export interface ChartLegendOptions {

	legend: {
		alignment: 'automatic' | 'start' | 'center' | 'end',
		position: 'left' | 'right' | 'center' | '@in' | 'none' | 'top';
		useFullWidth: boolean
	};

	chartArea: {

		width: string;

		height: string;

		right: string;

		left: string;

		top: string;

		bottom: string;
	};
}


export class ChartLayoutAnnotation {
	/**
	 * set the type of chart
	 */
	chartType: string;

	hasLeftEdgeData: false;
	hasRightEdgeData: false;
	/**
	 * The options object for rendering the chart
	 */
	options: any;
	/**
	 * Only use provided columns for the x and y axis
	 */
	properties: Array<string>;
	columnDescriptions: ColumnDescriptions;
	tooltipCalculations: TooltipCalculations;
}

