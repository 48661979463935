import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, Observable } from 'rxjs';
import { isNullOrUndefined } from '@cs/core';


@Component({
  selector: 'i-upload-service',
  templateUrl: './i-upload-service.component.html'
})
export class IUploadService implements OnInit {
  inProgress: BehaviorSubject<boolean>;
  droppedFiles: File[] = [];
  commentText: string = '';
  requiredComment: boolean;
	loadingText: string;

  @Output() onFilesDropped = new EventEmitter<File[]>();
  @Output() onCommentTextChange = new EventEmitter<string>();
  @Output() fileUploaded = new EventEmitter<{ files: File[], reason: string }>();

  constructor(
    public dialogRef: MatDialogRef<IUploadService>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.inProgress = data.inProgress || new BehaviorSubject<boolean>(false);
    this.requiredComment = data.requiredComment || false; // Initialize requiredComment
    this.dialogRef.disableClose = false; // Allows closing by clicking outside the dialog
  }

  ngOnInit() {
    if (!isNullOrUndefined(this.data)) {
      this.data.message = (<string>this.data.message).replace(/\n/g, '<br />');
    }
  }

  handleFilesDropped(files: File[]) {
    this.droppedFiles = files;
    this.onFilesDropped.emit(files);
  }

  handleCommentTextChange(reason: string) {
    this.commentText = reason;
    this.onCommentTextChange.emit(reason);
  }

  uploadFiles() {
    if (this.droppedFiles.length > 0 && (!this.requiredComment || (this.requiredComment && this.commentText.trim() !== ''))) {
      this.inProgress.next(true);
      // Simulate file upload process
      setTimeout(() => {
        this.sendToServer(this.droppedFiles, this.commentText);
      }, 3000);  // Replace with actual upload logic
    } else {
			alert('These changes require a reason');
		}
  }

  sendToServer(files: File[], reason: string) {

    // Emit event with file and reason information
    const result = { files, reason };
    this.fileUploaded.emit(result);
    // Implement actual upload logic here
    this.inProgress.next(false);
    this.dialogRef.close(result); // Close the dialog with a result
  }

  handleUploadComplete(event: { files: File[], comment: string }) {
    if (event.files.length > 0 && (!this.requiredComment || (this.requiredComment && event.comment.trim() !== ''))) {
      this.sendToServer(event.files, event.comment);
    } else {
      this.dialogRef.close();
    }
  }
	
}
