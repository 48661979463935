import { INode }            from '../../interfaces/inode';
import { PropertyDataNode } from './property-data-node';
import { PropertyTypeNode } from './property-type-node';

export abstract class BaseNode implements INode {
  public label: string;
  public name: string;
  public description: string;
  public childrenKey: string;
  public children: Array<INode> = [];
  public editMode               = false;
  abstract nodeType: string;

  isLoading: boolean;
  columnLabels: any;
  config: any;
  data: PropertyDataNode;
  displayFormat: string;
  displayFormatNull: string;
  displayValue: string;
  input;
  key: string;
  maxLength: string;
  method: any;
  propertyType: PropertyTypeNode;
  rowLabels: any;
  validationRegex: string;
  value: string | number | boolean;

  public constructor(data, childrenKey = null) {
    this.label       = data.label;
    this.name        = data.name;
    this.description = data.description;
    this.childrenKey = childrenKey;

    if (this.childrenKey != null) {
      this.processChildren(data[this.childrenKey]);
    }
  }

  public createChild<T>(type: { new(data): T }, data): T {
    return new type(data);
  }

  public abstract processChildren(data);


  getConfig() {
  }

}
