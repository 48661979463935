import { isNullOrUndefined }    from '@cs/core';
import { FormDefinitionBundle } from '@cs/performance-manager/shared';


export class CSAddMemberDialog {
	name: string;
	description: string;
	keys: any;
	label: string;
	footer: string;
	formDefinition: FormDefinitionBundle;


	constructor(initial: Partial<CSAddMemberDialog>) {
		if (!isNullOrUndefined(initial)) {
			Object.assign(this, initial);
		}
	}
}
