import { HttpErrorResponse, HttpHeaders }                                            from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog }                                                                 from '@angular/material/dialog';
import { ActivatedRoute, Router }                                                    from '@angular/router';
import { SafeMethods }                                                               from '@cs/common';
import { FilterCompareBarQuery }                                                     from '@cs/components/filter-and-compare-bar';
import { CsFormGeneratorComponent }                                                  from '@cs/components/form-generator';
import { isNullOrUndefined }                                                         from '@cs/core';
import { DialogBasicComponent, DialogType, FormDefinitionBundle, ToastService }      from '@cs/performance-manager/shared';
import { UntilDestroy, untilDestroyed }                                              from '@ngneat/until-destroy';
import { filter as filter$, tap }                                                    from 'rxjs/operators';
import { DataListConfigService }                                                     from '../data-list-config.service';
import { CSResourceModel }                                                           from '../models/resourceModel';

@UntilDestroy()
@Component({
			   selector:    'pmc-detail',
			   templateUrl: './detail.component.html'
		   })
export class DetailComponent<T> implements OnInit, AfterViewInit, OnDestroy {

	@ViewChild('form') formGenerator: CsFormGeneratorComponent;
	idCountry: number;
	idDetail: number;
	isNew             = false;
	dataSource: FormDefinitionBundle;
	hasFormDataSource = false;
	formData: T;

	// Resources access for controlling visiblity of for example buttons.
	resources: CSResourceModel = new CSResourceModel();

	constructor(private config: DataListConfigService<T>,
				private route: ActivatedRoute,
				public readonly changeRef: ChangeDetectorRef,
				private filterCompareBarQuery: FilterCompareBarQuery,
				public router: Router,
				public dialog: MatDialog,
				public toast: ToastService) {
	}

	ngOnInit() {
		// Get the id of T from the url params
		this.route.paramMap.subscribe((paramMap) => {
										  this.idDetail = +paramMap.get('id') || null;
									  }
		);
	}

	ngOnDestroy(): void {
	}

	ngAfterViewInit(): void {
		// Load the idCountry from the navbar selection
		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(
				tap(x => console.log(x)),
				untilDestroyed(this),
				filter$(value => !isNullOrUndefined(value))
			)
			.subscribe((value) => {
				this.idCountry = value.selection.idCountry['id'];
				// Load data if exists
				this.getResourceAccess(this.idCountry);
				this.getFormDataAndDefinition();

				// Load only formdefinition
				this.route.url.subscribe(url => {
					if (url.some(x => x.path === 'new')) {
						this.getFormDefinition();
					}
				});
			});
	}

	/**
	 * Get formdefinition for a new T
	 */
	private getFormDefinition() {
		this.isNew    = true;
		this.idDetail = null;
		this.config.getFormDefition(this.idCountry)
			.subscribe(response => {
				const bundle           = {
					data:    {},
					form:    response.value.form,
					lookups: response.value.lookups
				};
				this.dataSource        = bundle;
				this.hasFormDataSource = !isNullOrUndefined(this.dataSource);
				SafeMethods.detectChanges(this.changeRef);

			});
	}

	/**
	 * Get formdefinition and data of a T
	 */
	private getFormDataAndDefinition() {
		if (!isNullOrUndefined(this.idDetail)) {
			this.isNew = false;
			this.config.getData(this.idCountry, this.idDetail)
				.subscribe(response => {
					this.formData = response.value;
					this.formData = this.dateFormat(this.formData);
					this.config.getFormDefition(this.idCountry)
						.subscribe(response => {
							const bundle           = {
								data:    this.formData,
								form:    response.value.form,
								lookups: response.value.lookups
							};
							this.dataSource        = bundle;
							this.hasFormDataSource = !isNullOrUndefined(this.dataSource);
							SafeMethods.detectChanges(this.changeRef);
						});
				});
		}
	}

	/**
	 * Format dates to match UTC standart
	 * @param initial struct of T
	 * @returns T
	 */
	private dateFormat(initial: T): T {

		if (initial.hasOwnProperty('dateUpdate') && initial['dateUpdate'].length) {
			initial['dateUpdate'] = new Date(initial['dateUpdate'] + ' UTC').toISOString();
		}
		if (initial.hasOwnProperty('dateInsert') && initial['dateInsert'].length) {
			initial['dateInsert'] = new Date(initial['dateInsert'] + ' UTC').toISOString();
		}
		if (initial.hasOwnProperty('dateStart') && initial['dateStart'].length) {
			initial['dateStart'] = new Date(initial['dateStart'] + ' UTC').toISOString();
		}
		if (initial.hasOwnProperty('dateEnd') && initial['dateEnd'].length) {
			initial['dateEnd'] = new Date(initial['dateEnd'] + ' UTC').toISOString();
		}
		if (initial.hasOwnProperty('dateResolved') && initial['dateResolved'].length) {
			initial['dateResolved'] = new Date(initial['dateResolved'] + ' UTC').toISOString();
		}
		return initial;
	}

	/**
	 * Go to previous page
	 */
	goBack() {
		this.router.navigate(['../../../'], {relativeTo: this.route});
	}

	/**
	 * Delete item with warning
	 */
	deleteDetail() {
		const options = {
			errorResponseHandler: (error: HttpErrorResponse) => {
				if (isNullOrUndefined(error))
					return false;
				if (error.status === 400) {
					this.toast.error('Error: Could not delete item', error.message);
					return true;
				}
				return false; // unhandled
			},
			headers:              new HttpHeaders()
		};

		const confirmDialogRef = this.dialog.open(DialogBasicComponent, {
			data: {
				dialogTitle: 'Confirm',
				type:        DialogType.none,
				message:     'Delete this entry?'
			}
		});

		confirmDialogRef.afterClosed()
						.subscribe(confirmed => {
							if (confirmed && !this.isNew && !isNullOrUndefined(this.idDetail)) {
								this.config.deleteData(this.idCountry, this.idDetail, options)
									.subscribe(response => {
										this.toast.success('Success', 'Deleted');
										this.goBack();
									});
							}
						});
	}

	/**
	 * Go back if it's a new T
	 */
	onCancel() {
		if (this.isNew) {
			this.goBack();
		}
	}

	/**
	 * Saves form data
	 * @param data struct
	 */
	onSave(data: any) {
		if (this.isNew) {
			this.saveNew(data);
		} else {
			this.saveExisting(data);
		}
	}

	/**
	 * Save form data as new Item and navigate to that item upon success
	 * @param data struct
	 */
	private saveNew(data: any) {
		if (data) {
			this.config.saveNewData(this.idCountry, data,
									{
										errorResponseHandler: (error: HttpErrorResponse) => this.handleResponseErrors(error),
										headers:              new HttpHeaders()
									})
				.subscribe(response => {
					this.toast.success('Success', 'Saved');
					this.afterSaveNew(response.value.id, this.route);
				});
		}
	}

	/**
	 * Navigate to detail page after saving new entry
	 * @param id number
	 * @param route routing
	 */
	private afterSaveNew(id: number, route: ActivatedRoute) {
		this.router.navigate(['../', id], {relativeTo: route});
	}

	/**
	 * Update existing item and refresh form with current data
	 * @param data struct
	 */
	private saveExisting(data: any) {
		if (data) {
			this.config.saveExistingData(this.idCountry, this.idDetail, data,
										 {
											 errorResponseHandler: (error: HttpErrorResponse) => this.handleResponseErrors(error),
											 headers:              new HttpHeaders()
										 })
				.subscribe(response => {
					this.toast.success('Success', 'Saved');
					this.refreshFormdata();
				});
		}
	}

	/**
	 * Get form data from the server and update form state
	 */
	private refreshFormdata() {
		if (!isNullOrUndefined(this.idCountry) && !isNullOrUndefined(this.idDetail)) {
			this.config.getData(this.idCountry, this.idDetail, {
					errorResponseHandler: (error: HttpErrorResponse) => this.handleResponseErrors(error),
					headers:              new HttpHeaders()
				})
				.subscribe(response => {
					this.formData          = response.value;
					this.formData          = this.dateFormat(this.formData);
					// force formGenerator to update
					const bundle           = {
						data:    this.formData,
						form:    this.dataSource.form,
						lookups: this.dataSource.lookups
					};
					this.dataSource        = bundle;
					this.hasFormDataSource = !isNullOrUndefined(this.dataSource);
					SafeMethods.detectChanges(this.changeRef);
				});
		}
	}

	private handleResponseErrors(error: HttpErrorResponse): boolean {
		if (!error)
			return false;
		switch (error.status) {
			case 400:
				// Handle documented 400 error by displaying toast
				this.toast.error('Error', error.message);
				this.formGenerator.isInEditMode = true;
				return true;
			case 401:
				this.toast.warning('Error', 'Invalid credentials');
				return true;
			case 403:
				this.toast.warning('Unauthorized', 'Operation not allowed');
				return true;
			case 404:
				this.toast.warning('Error', 'Not Found');
				return true;
			default:
				return false; // Response is deemed as UNhandled.
		}
	}

	/**
	 * Get resource access rights for country
	 * @param idcountry number
	 */
	getResourceAccess(idCountry: number) {
		this.config.getResourceAccess(idCountry)
			.subscribe((data) => {
				this.resources = new CSResourceModel(data.value);
			});
	}
}
