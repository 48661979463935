import { Inject, ModuleWithProviders, NgModule, Optional, SkipSelf, Type } from '@angular/core';
import { CommonModule }                                                    from '@angular/common';
import { RouterModule }                                                    from '@angular/router';
import { TranslateModule }                                                 from '@ngx-translate/core';
import { AppMessageHubService }                                            from './app-message-hub.service';

const providers = [AppMessageHubService];

@NgModule({
	declarations: [],
	imports:      [
		CommonModule,
		TranslateModule,
		RouterModule
	],
	providers:    [
		...providers
	]
})
export class AppMessageHubModule {
	static forRoot(): ModuleWithProviders<AppMessageHubModule> {
		return {
			ngModule:  AppMessageHubModule,
			providers: [
				...providers
			]
		};
	}

	constructor(@Optional() @SkipSelf() @Inject(AppMessageHubModule) parentModule: AppMessageHubModule | null) {
		if (parentModule) {
			throw new Error(
				`AppMessageHubModule has already been loaded. only allowed to be added once in the consuming application`);
		}
	}
}
