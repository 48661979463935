import { forwardRef, ModuleWithProviders, NgModule, Type } from '@angular/core';
import { CsToastManagerService }                           from '@cs/components/toast-manager';
import { TranslateService }                                from '@ngx-translate/core';

import { CsChartLoaderDirective }          from './cs-loader-chart.directive';
import { FormatProviderService }           from '@cs/common';
import { CsChartLoaderSetup }              from './cs-chart-provider.interface';
import { CsDataDescribedChartLoaderSetup } from './cs-chart-loader-setup';

const directives = [CsChartLoaderDirective];

@NgModule({
	declarations: [...directives],
	exports:      [...directives]
})
export class CsChartLoaderModule {
	static forRoot(csChartLoaderSetup: Type<CsChartLoaderSetup> = CsDataDescribedChartLoaderSetup): ModuleWithProviders<CsChartLoaderModule> {
		return {
			ngModule: CsChartLoaderModule, providers: [{
				provide:  CsChartLoaderSetup,
				useClass: csChartLoaderSetup, deps: [FormatProviderService]
			}, {
				provide:  FormatProviderService,
				useClass: forwardRef(() => FormatProviderService)
			}]
		};
	}

	static forChild(csChartLoaderSetup: Type<CsChartLoaderSetup>): ModuleWithProviders<CsChartLoaderModule> {
		return {
			ngModule: CsChartLoaderModule, providers: [{
				provide:  CsChartLoaderSetup,
				useClass: csChartLoaderSetup, deps: [FormatProviderService, CsToastManagerService, TranslateService]
			}, {
				provide:  FormatProviderService,
				useClass: forwardRef(() => FormatProviderService)
			}]
		};
	}
}
