import { IpaSelectionOptions, SelectionTriggerEnum }         from '@cs/core/generate';
import { DataEntryStateColumnType }                          from './data-entry-state';
import { DataViewColumn, DataViewColumnInit, getPropertyOf } from '@cs/core';
import { DataEntryVisualState }                              from './data-entry-state-visual-state';
import { DataEntryStateIndicator, DataEntryStateStatus }     from './data-entry-state.indicator';


export type DataEntryStateColumn =
	DataEntryStateTextColumn
	| DataEntryStateStatusColumn
	| DataEntryStateLabelColumn
	| DataEntryStateIndicatorColumn
	| DataEntryStateHeaderColumn
	| DataEntryStateHeaderVerticalColumn;

export abstract class DataEntryStateColumnBase<TValue, TColumnType, TVisualState>
	extends DataViewColumn<TValue, TColumnType, TVisualState> {
	abstract type: TColumnType;
	ipaOptions: IpaSelectionOptions | null = null;
}

export class DataEntryStateTextColumn extends DataEntryStateColumnBase<string, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Text;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType>) {
		super(init);
	}
}

export class DataEntryStateStatusColumn extends DataEntryStateColumnBase<DataEntryStateStatus, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Status;

	constructor(init: DataViewColumnInit<DataEntryStateStatus, DataEntryStateColumnType>) {
		super(init);
	}
}

export class DataEntryStateStatusWithLabelColumn extends DataEntryStateColumnBase<DataEntryStateStatus, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.StatusWithLabel;

	constructor(init: DataViewColumnInit<DataEntryStateStatus, DataEntryStateColumnType>) {
		super(init);
	}
}

export class DataEntryStateIndicatorColumn extends DataEntryStateColumnBase<DataEntryStateIndicator, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.DataEntryState;

	constructor(init: DataViewColumnInit<DataEntryStateIndicator, DataEntryStateColumnType>) {
		super(init);
	}
}

export class DataEntryStateLabelColumn extends DataEntryStateColumnBase<string, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Label;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType>) {
		super(init);
	}
}


export class DataEntryStateHeaderColumn extends DataEntryStateColumnBase<string, DataEntryStateColumnType, DataEntryVisualState> {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Header;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType, DataEntryVisualState>) {
		super(init);

		this.visualState = getPropertyOf(init, 'visualState', new DataEntryVisualState());
	}
}

export class DataEntryStateHeaderPlaceholderColumn extends DataEntryStateHeaderColumn {
	type: DataEntryStateColumnType = DataEntryStateColumnType.Placeholder;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType, DataEntryVisualState>) {
		super(init);

		this.visualState = getPropertyOf(init, 'visualState', new DataEntryVisualState());
	}
}

export class DataEntryStateHeaderVerticalColumn extends DataEntryStateHeaderColumn {
	type: DataEntryStateColumnType = DataEntryStateColumnType.HeaderVertical;

	constructor(init: DataViewColumnInit<string, DataEntryStateColumnType, DataEntryVisualState>) {
		super(init);

		this.visualState = getPropertyOf(init, 'visualState', new DataEntryVisualState());
	}
}

