/**
 * Object for showing messages
 */
export class PageAlert {
  title?: string;
  message: string;

  /**
   * Create a message to show as an alert or as an other UI element
   * @param message The message we would like to set, could be html
   * @param title Shows an title
   */
  constructor(message: string, title?: string) {
    if (title)
      this.title = title;

    this.message = message;
  }
}
