import { NgModule }               from '@angular/core';
import { CommonModule }           from '@angular/common';
import { PmDetailPanelComponent } from './pm-detail-panel.component';
import { TranslateModule }        from '@ngx-translate/core';

@NgModule({
	declarations: [PmDetailPanelComponent],
	exports:      [PmDetailPanelComponent],
	imports:      [
		CommonModule,
		TranslateModule
	]
})
export class PmDetailPanelModule {
}
