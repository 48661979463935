import { DataDescribed } from '@cs/core';

export class CsFileData {

	fileName: string;
	previewUrl: string;
	downloadUrl?: string;
	extension: string;
	size: number;
}

export enum CsFileType {
	PDF   = 'pdf',
	IMAGE = 'image'
}

export class CsFileDataDescribed extends DataDescribed<CsFileData[]> {

}
