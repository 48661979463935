import { NgModule }               from '@angular/core';
import { Routes, RouterModule }   from '@angular/router';
import { MdmPropertiesViewerComponent } from './mdm-properties-viewer.component';

const routes: Routes = [
  {path: '', component: MdmPropertiesViewerComponent}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MdmPropertiesViewerRoutingModule {
}
