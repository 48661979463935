import { IConfigService } from '@cs/performance-manager/shared';
import { Observable }     from 'rxjs';
import { Result }         from '@cs/core';
import { NewsItem }       from './models/news-item';
import { NewsCategory }   from './models/news-category';

export abstract class NewsConfigService implements IConfigService {
  moduleName = 'news';

  abstract getNewsItems(categoryId: number, maxItems?: number): Observable<Result<Array<NewsItem>>>;

  abstract getNewsCategories(): Observable<Result<Array<NewsCategory>>>;

  abstract getNewsItem(idNewsItem: number): Observable<Result<NewsItem>>;
}
