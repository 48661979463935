import { Inject, NgModule }                  from '@angular/core';
import { CommonModule }                      from '@angular/common';
import { DashboardSimpleFormNxtComponent }   from './dashboard-simple-form-nxt.component';
import { FormGeneratorNxtModule }            from '../../form-generator-nxt.module';
import { ComponentTranslationLoaderService } from '@cs/common';
import { DashboardComponentRegistry }        from '@cs/components/shared';


@NgModule({
			  declarations: [
				  DashboardSimpleFormNxtComponent
			  ],
			  imports:      [
				  CommonModule,
				  FormGeneratorNxtModule
			  ]
		  })
export class DashboardSimpleFormNxtModule {
	constructor(loader: ComponentTranslationLoaderService,
				@Inject(DashboardComponentRegistry) registry: DashboardComponentRegistry) {

		if (registry) {
			registry.register(DashboardSimpleFormNxtComponent.TYPE_NAME, DashboardSimpleFormNxtComponent);
		}
	}
}
