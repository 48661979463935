import {
	animate, animation, keyframes, query, stagger,
	style, transition, trigger
}                                              from '@angular/animations';
import {
	AfterViewInit, ChangeDetectorRef, Component, Inject,
	OnDestroy, OnInit, Optional, ViewChild
}                                              from '@angular/core';
import { ActivatedRoute }                      from '@angular/router';
import { simpleFadeInOut, WaitingForResponse } from '@cs/common';
import { CsPlaceholderComponent }              from '@cs/components/placeholder';
import { FileUtils, isNullOrUndefined }        from '@cs/core';
import {
	ToastService
}                                              from '@cs/performance-manager/shared';
import {
	TabService
}                                              from '@cs/performance-manager/tabbed-page';
import { UntilDestroy, untilDestroyed }        from '@ngneat/until-destroy';
import { TranslateService }                    from '@ngx-translate/core';
import { Subject }                             from 'rxjs';
import { debounceTime, tap }                   from 'rxjs/operators';
import { DownloadsConfigService }              from './downloads-config.service';
import { DownloadsItem }                       from './models/downloads-item';
import {
	DownloadItemsResolver, DownloadItemsResolverResult
}                                              from './resolver/download-items.resolver';

export const bounceIn = animation(
	animate(
		'{{ timing }}s {{ delay }}s cubic-bezier(0.215, 0.610, 0.355, 1.000)',
		keyframes([
					  style({opacity: 0, transform: 'scale3d(.3, .3, .3)', offset: 0}),
					  style({transform: 'scale3d(1.1, 1.1, 1.1)', offset: 0.2}),
					  style({transform: 'scale3d(.9, .9, .9)', offset: 0.4}),
					  style({
								opacity:   1,
								transform: 'scale3d(1.03, 1.03, 1.03)',
								offset:    0.6
							}),
					  style({transform: 'scale3d(.97, .97, .97)', offset: 0.8}),
					  style({opacity: 1, transform: 'scale3d(1, 1, 1)', offset: 1})
				  ])
	),
	{params: {timing: 200, delay: 0}}
);

@UntilDestroy()
@Component({
			   selector:    'pmc-downloads',
			   templateUrl: './downloads.component.html',
			   styleUrls:   ['./downloads.component.scss'],
			   animations:  [
				   simpleFadeInOut('isLoadingConent'),
				   simpleFadeInOut('isDownloading'),
				   trigger('listAnimation', [
					   transition('* => *', [ // each time the binding value changes
						   query(':leave', [
							   stagger(50, [
								   animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
							   ])
						   ], {optional: true}),
						   query(':enter', [
							   style({opacity: 0, transform: 'translate3d(0,-50px,0)'}),
							   stagger(50, [
								   animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)', style({
																							opacity:   1,
																							transform: 'translate3d(0,0,0)'
																						}))
							   ])
						   ], {optional: true})
					   ])
				   ])
			   ]
		   })
export class DownloadsComponent implements OnInit, OnDestroy, AfterViewInit {
	downloadItems: Array<DownloadsItem>       = [];
	resolverData: DownloadItemsResolverResult = null;
	isLoadingContent                          = false;
	@ViewChild(CsPlaceholderComponent) placeholder: CsPlaceholderComponent;
	rows$: Subject<number>                    = new Subject();
	isLoadingDebounced$                       = this.rows$.pipe(untilDestroyed(this), debounceTime(300));

	constructor(private downloadsConfig: DownloadsConfigService,
				private toasts: ToastService,
				private i8n: TranslateService,
				private activeRoute: ActivatedRoute,
				private changeRef: ChangeDetectorRef,
				@Optional() @Inject(TabService) private tabService: TabService) {
		// Check if there is resolver used, this avoids a empty state of the page
		if (this.activeRoute.snapshot.data.hasOwnProperty(DownloadItemsResolver.resolverName)) {
			this.resolverData = this.activeRoute.snapshot.data[DownloadItemsResolver.resolverName] as DownloadItemsResolverResult;
		}
	}

	ngOnInit() {
		if (!isNullOrUndefined(this.resolverData)) {
			this.downloadItems = this.resolverData.items;

			this.checkIfEmpty(this.downloadItems);
			return;
		}
		this.isLoadingContent = true;
		this.downloadsConfig.getDownloadItems()
			.pipe(tap(WaitingForResponse.new(isLoading => this.tabService.setInProgress(isLoading))))
			.pipe(tap(() => this.rows$.next(0)))
			.subscribe(resultNewsItems => {
				const items = resultNewsItems.value;
				items.forEach(value => value.isLoading = false);
				this.downloadItems = items;
				this.checkIfEmpty(this.downloadItems);
				this.placeholder.detectChanges();
				this.isLoadingContent = false;
				this.changeRef.markForCheck();
			});

	}

	ngAfterViewInit(): void {
		this.rows$.next(2);
		this.placeholder.detectChanges();
	}

	ngOnDestroy(): void { }

	downloadFile(item: DownloadsItem) {
		item.isLoading = true;
		this.downloadsConfig.getFile(item.id)
			.subscribe(response => {
				if (!isNullOrUndefined(response)) {
					FileUtils.downloadFile(response.value);
				} else {
					this.toasts.warning(this.i8n.instant('FILE_NOT_AVAILABLE'), this.i8n.instant('COULD_NOT_GET_FILE'));
				}
				item.isLoading = false;
				this.changeRef.markForCheck();
			}, error => console.log(error));
	}

	showNews() {
		return this.downloadsConfig.showNews;
	}


	private checkIfEmpty(downloadItems: Array<DownloadsItem>) {
		if (downloadItems.length === 0) {
			this.addEmptyDownloadItem(downloadItems);
		}
	}

	private async addEmptyDownloadItem(items: Array<DownloadsItem>) {
		items.push({
					   name:            await this.i8n.get('NO_DOWNLOAD_ITEMS')
												  .toPromise(),
					   description:     await this.i8n.get('NO_DOWNLOAD_ITEMS_BODY')
												  .toPromise(),
					   datePublication: new Date().toISOString(),
					   fileName:        await this.i8n.get('NO_DOWNLOAD_ITEMS_FILE')
												  .toPromise(),
					   fileExtension:   'info'
				   });
	}
}
