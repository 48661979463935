import { Injectable } from '@angular/core';
import { Query }                                    from '@datorama/akita';
import { DataEntryStateStore, DateEntryStateState } from './data-entry-state.store';

@Injectable({ providedIn: 'root' })
export class DateEntryStateQuery extends Query<DateEntryStateState> {

  constructor(protected store: DataEntryStateStore) {
    super(store);
  }

}
