<div class="flex flex-justify-content-center flex-align-items-center login-background-img">
	<div
			class="card card-login"
			[class.login-failure]="$isErrorState | async">
		<div class="card-header">
			<div class="logo"></div>
			<h2 class="f-h2 card-title"
				[class.no-undertitle]="($underTitleMessage | async)===''">
				{{$loginMessage | async}}</h2>
			<div class="card-text" [@textAnimation]="$underTitleMessage | async" [innerHTML]="$underTitleMessage | async"></div>
		</div>
		<div class="card-block" [@fadeAnimation]="getRouterOutletState(o)">
			<router-outlet #o="outlet"></router-outlet>

		</div>
		<div class="login-form--logo-bottom-bar">
			<label class="f-body-s mr-2 hover-pointer" (click)="navigateToWebsite()">Powered by:</label>
			<svg class="hover-pointer" width="80" height="31" viewBox="0 0 80 31" fill="none"
				 xmlns="http://www.w3.org/2000/svg" (click)="navigateToWebsite()">
				<path d="M19.0217 6.19107C19.0217 9.55065 16.3042 12.3343 12.9192 12.3823C11.2506 12.3823 9.91564 13.0062 9.0098 14.446C8.00861 16.0778 8.00861 17.7096 9.05748 19.2934C9.91564 20.6373 11.1075 21.5012 12.7285 21.5971C13.1099 21.6451 13.4913 21.5971 13.8727 21.5491C15.2553 21.3572 16.4949 21.5971 17.5438 22.509C18.8787 23.7089 19.3554 25.2447 18.831 26.9725C18.3066 28.7003 17.067 29.7081 15.303 29.9481C13.1099 30.2361 11.1075 28.7962 10.6308 26.6365C10.3924 25.5326 9.91564 24.5728 9.05748 23.8529C7.96093 22.941 6.72136 22.653 5.29109 22.893C4.09919 23.037 2.95497 22.941 1.95378 22.2691C1.00027 21.5971 0.38048 20.7332 0.142101 19.5814C-0.334657 17.4217 0.95259 15.2139 3.05033 14.59C3.43173 14.494 3.76546 14.446 4.14687 14.398C4.90968 14.3021 5.52947 13.9661 6.00623 13.4382C6.91207 12.4783 7.4365 11.3264 7.38882 9.9346C7.38882 9.40666 7.29347 8.92672 7.10277 8.44678C5.67249 4.75125 7.96093 0.719749 11.8227 0.0958268C15.2553 -0.480101 18.4496 1.87161 18.9264 5.32717C18.974 5.61514 18.974 5.9031 19.0217 6.19107Z"
					  fill="#3884FF"/>
				<path d="M5.38737 9.93475C5.38737 11.4226 4.14779 12.6224 2.66984 12.6224C1.1919 12.6224 0 11.3746 0 9.93475C0 8.44694 1.23957 7.1991 2.71752 7.1991C4.19547 7.1991 5.38737 8.44694 5.38737 9.93475Z"
					  fill="black"/>
				<path d="M20.9773 16.7979C20.9773 15.934 21.168 15.1181 21.5494 14.3982C21.9308 13.7263 22.4552 13.1503 23.1704 12.7184C23.8855 12.3345 24.696 12.1425 25.5542 12.1425C26.5554 12.0945 27.5089 12.4304 28.3194 13.0064C29.0822 13.5823 29.6066 14.3982 29.845 15.3581H27.4612C27.3182 14.9741 27.0321 14.6862 26.6984 14.4942C25.7926 13.9662 24.6483 14.1582 23.9332 14.9261C23.5041 15.4541 23.2657 16.174 23.3134 16.8459C23.2657 17.5658 23.5041 18.2377 23.9332 18.7656C24.6483 19.5335 25.7926 19.7255 26.6984 19.1976C27.0321 19.0056 27.3182 18.6697 27.4612 18.3337H29.845C29.6066 19.2936 29.0822 20.1095 28.3194 20.6854C27.5089 21.2613 26.5554 21.5013 25.5542 21.5013C24.7437 21.5013 23.9332 21.3093 23.1704 20.9254C22.5029 20.5414 21.9308 19.9655 21.5494 19.2456C21.168 18.4777 20.9773 17.6138 20.9773 16.7979Z"
					  fill="black"/>
				<path d="M33.9929 21.5013C33.3254 21.5013 32.658 21.3573 32.0859 21.0693C31.5137 20.7814 31.0847 20.3014 30.7509 19.7735C30.4172 19.1496 30.2742 18.4777 30.2742 17.8058C30.2742 17.1338 30.4172 16.4619 30.7509 15.838C31.0847 15.2621 31.5137 14.8301 32.0859 14.5422C33.2777 13.9182 34.708 13.9182 35.8999 14.5422C36.472 14.8301 36.9488 15.3101 37.2348 15.838C37.9023 17.0858 37.9023 18.5737 37.2348 19.7735C36.9011 20.3494 36.472 20.7814 35.8999 21.0693C35.3278 21.3573 34.6603 21.5013 33.9929 21.5013ZM33.9929 19.5335C34.422 19.5335 34.8034 19.3416 35.0417 19.0536C35.3755 18.6696 35.5185 18.1897 35.4708 17.7098C35.5185 17.2298 35.3278 16.7499 35.0417 16.3659C34.7557 16.078 34.3743 15.886 33.9929 15.886C33.5638 15.886 33.1824 16.078 32.944 16.3659C32.658 16.7499 32.4673 17.2298 32.5149 17.7098C32.4673 18.1897 32.6103 18.6696 32.944 19.0536C33.2301 19.3896 33.6115 19.5815 33.9929 19.5335Z"
					  fill="black"/>
				<path d="M42.9559 14.0142C43.7187 13.9662 44.4338 14.3021 44.9106 14.8301C45.435 15.454 45.7211 16.2699 45.6734 17.0858V21.3573H43.4803V17.4218C43.528 16.9898 43.385 16.6059 43.0989 16.2699C42.5268 15.742 41.6686 15.742 41.0965 16.2699C40.8105 16.6059 40.7151 16.9898 40.7151 17.4218V21.3573H38.4744V11.6625H40.6675V15.0701C40.9058 14.7341 41.1919 14.4941 41.5733 14.3021C42.0501 14.1102 42.5268 14.0142 42.9559 14.0142Z"
					  fill="black"/>
				<path d="M53.5874 17.6138C53.5874 17.8537 53.5874 18.0457 53.5397 18.2857H48.5814C48.5814 18.6696 48.7245 19.0536 49.0105 19.3416C49.2489 19.5815 49.5826 19.7255 49.964 19.6775C50.4408 19.7255 50.9176 19.4375 51.1083 18.9576H53.4444C53.206 19.9175 52.5385 20.7334 51.6327 21.1653C51.1559 21.4053 50.5838 21.5013 50.0594 21.5013C49.3919 21.5013 48.7721 21.3573 48.2 21.0693C47.6756 20.7814 47.1988 20.3014 46.9128 19.7735C46.5791 19.1496 46.436 18.4777 46.436 17.8058C46.436 17.1338 46.5791 16.4139 46.8651 15.838C47.1512 15.3101 47.6279 14.8301 48.1524 14.5422C49.3443 13.9182 50.7269 13.9182 51.8711 14.5422C52.3955 14.8301 52.8246 15.2621 53.1107 15.79C53.3967 16.3179 53.5874 16.9419 53.5874 17.6138ZM51.3466 17.0378C51.3466 16.7019 51.2036 16.3659 50.9652 16.126C50.3931 15.694 49.5826 15.694 49.0582 16.126C48.7722 16.3659 48.6291 16.7019 48.5814 17.0378H51.3466Z"
					  fill="black"/>
				<path d="M56.5434 11.6625H54.3503V21.3573H56.5434V11.6625Z" fill="black"/>
				<path d="M58.832 14.0622C58.4983 14.0622 58.1169 13.9662 57.8785 13.7263C57.6401 13.4863 57.4971 13.1983 57.4971 12.8624C57.4971 12.5264 57.6401 12.2384 57.8785 11.9985C58.1169 11.7585 58.4983 11.6145 58.832 11.6625C59.1657 11.6625 59.4995 11.7585 59.7378 11.9985C59.9762 12.2384 60.1192 12.5264 60.1192 12.8624C60.1192 13.1983 59.9762 13.4863 59.7378 13.7263C59.5471 13.9662 59.2134 14.1102 58.832 14.0622ZM59.9285 21.4053H57.7354V15.1181H59.9285V21.4053Z"
					  fill="black"/>
				<path d="M64.4099 21.5013C63.7424 21.5013 63.075 21.3573 62.5028 21.0693C61.9307 20.7814 61.5017 20.3014 61.1679 19.7735C60.8342 19.1496 60.6912 18.4777 60.6912 17.8058C60.6912 17.1338 60.8342 16.4619 61.1679 15.838C61.5017 15.2621 61.9307 14.8301 62.5028 14.5422C63.6947 13.9182 65.125 13.9182 66.3169 14.5422C66.889 14.8301 67.3658 15.3101 67.6518 15.838C68.3193 17.0858 68.3193 18.5737 67.6518 19.7735C67.3181 20.3494 66.889 20.7814 66.3169 21.0693C65.7448 21.3573 65.0773 21.5013 64.4099 21.5013ZM64.4099 19.5335C64.839 19.5335 65.2204 19.3416 65.4587 19.0536C65.7925 18.6696 65.9355 18.1897 65.8878 17.7098C65.8878 17.2298 65.7448 16.7499 65.4587 16.4139C65.1727 16.126 64.7913 15.934 64.4099 15.934C63.9808 15.934 63.5994 16.126 63.361 16.4139C63.0749 16.7979 62.8842 17.2778 62.9319 17.7578C62.8842 18.2377 63.0273 18.7176 63.361 19.1016C63.5994 19.3896 64.0285 19.5815 64.4099 19.5335Z"
					  fill="black"/>
				<path d="M73.3254 14.0142C74.0882 13.9662 74.8033 14.3022 75.3278 14.8301C75.8522 15.454 76.1383 16.2699 76.0906 17.0858V21.3573H73.8975V17.4218C73.9452 16.9898 73.8021 16.6059 73.5161 16.2699C72.944 15.742 72.0858 15.742 71.5137 16.2699C71.2276 16.6059 71.1323 16.9898 71.1323 17.4218V21.3573H68.9392V14.1102H71.1323V15.0701C71.3707 14.7341 71.6567 14.4941 72.0381 14.3022C72.4195 14.1102 72.8963 14.0142 73.3254 14.0142Z"
					  fill="black"/>
				<path d="M78.4376 14.3958C77.6724 14.3958 77.0498 13.769 77.0498 12.9987C77.0498 12.2279 77.6724 11.6011 78.4376 11.6011C79.2033 11.6011 79.826 12.2279 79.826 12.9987C79.826 13.769 79.2028 14.3958 78.4376 14.3958ZM78.4376 11.8118C77.7878 11.8118 77.2591 12.344 77.2591 12.9987C77.2591 13.6528 77.7873 14.1856 78.4376 14.1856C79.0879 14.1856 79.6167 13.6533 79.6167 12.9987C79.6162 12.344 79.0875 11.8118 78.4376 11.8118Z"
					  fill="black"/>
				<path d="M78.9355 12.9373C78.8606 13.0165 78.7786 13.0693 78.6332 13.0861L79.0608 13.7618H78.8001L78.3848 13.0976H78.1564V13.7618H77.9419V12.167H78.5035C78.6871 12.167 78.8244 12.2083 78.9164 12.2913C79.0079 12.3743 79.0537 12.4838 79.0537 12.621C79.0537 12.7458 79.0108 12.8581 78.9355 12.9373ZM78.1564 12.9248H78.4849C78.7161 12.9248 78.832 12.8355 78.832 12.646C78.832 12.5524 78.8053 12.4823 78.7514 12.4358C78.698 12.3887 78.6089 12.3657 78.4849 12.3657H78.1564V12.9248Z"
					  fill="black"/>
			</svg>

		</div>
	</div>

</div>
