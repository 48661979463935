<div class="table-responsive"
     [ngStyle]="{'height':height}">

  <div>
    <ng-content select="cs-edit"></ng-content>
  </div>

  <div class="col col-md-6 col-lg-5 col-xl-4 m-0 p-0 pb-3">
    <cs-filter *ngIf="filter"
               [data]="allRows"
               [columns]="columns"
               (output)="rows = $event"></cs-filter>
  </div>

  <div class="table-responsive"
       [ngStyle]="{'height':height}">

    <table class="table"
           [class.table-hover]="selectable">
      <thead class="table-headers">
      <tr *ngIf="columns">
        <th *ngIf="multiple" class="table-cell table-headers__cell">
          <input type="checkbox"
                 (change)="toggleSelectedRows()">
        </th>
        <th *ngFor="let column of columns"
            (click)="onHeaderClick(column)"
            role="button"
            class="table-cell table-headers__cell">
          <span>{{column}}
            <ng-container *ngIf="sortable && column == sortColumn">
              <span *ngIf="sortDir == 'asc'">▼</span>
              <span *ngIf="sortDir == 'desc'">▲</span>
            </ng-container>
          </span>
        </th>
      </tr>
      <tr *ngIf="!columns">
        <th *ngIf="multiple">
          <input type="checkbox"
                 (change)="toggleSelectedRows()">
        </th>
        <th *ngFor="let column of columnTemplates"
            (click)="onHeaderClick(column.field)"
            [class]="column.headerClass"
            class="table-cell table-headers__cell"
            role="button">
            <span *ngIf="column.headerTemplate">
                      <cs-columnHeaderTemplateLoader [column]="column"></cs-columnHeaderTemplateLoader>
                  </span>
          <span style="display: flex; white-space: nowrap" *ngIf="!column.headerTemplate">
                      {{column.title}}
            <ng-container *ngIf="sortable && column.field && sortColumn && column.field == sortColumn">
              &nbsp;
              <span *ngIf="sortDir == 'asc'">▼</span>
              <span *ngIf="sortDir == 'desc'">▲</span>
            </ng-container>
                  </span>
        </th>
      </tr>
      </thead>
      <tbody class="table-body">
      <tr *ngIf="currentRows.length === 0">
        <td [attr.colspan]="columns !== undefined ? columns.length : columnTemplates.length">
          {{noResults}}
        </td>
      </tr>
      <ng-template ngFor
                   let-row
                   [ngForOf]="currentRows"
                   let-index="index"
                   let-odd="odd">
        <tr class="table-body__row"
            [class.table-info]="(editTemplate || selectable) && selected(row)"
            [class.cs-row-odd]="odd"
            [class.cs-pointer]="selectable"
            (click)="onRowClick(row,index)">
          <td *ngIf="multiple">
            <input type="checkbox"
                   checked
                   *ngIf="selected(row)">
            <input type="checkbox"
                   *ngIf="!selected(row)">
          </td>
          <ng-container *ngIf="columns">
            <td *ngFor="let column of getColumns(row)">{{column}}</td>
          </ng-container>
          <ng-container *ngIf="!columns">
            <td *ngFor="let column of columnTemplates"
                [class]="column.class">
              <ng-container *ngIf="column.bodyTemplate">
                <cs-columnBodyTemplateLoader [column]="column"
                                             [rowData]="row"
                                             [rowIndex]="index+(page-1)">
                </cs-columnBodyTemplateLoader>
              </ng-container>
              <ng-container *ngIf="!column.bodyTemplate">
                {{row[column.field]}}
              </ng-container>
            </td>
          </ng-container>
        </tr>
        <tr class="table-info cs-table-edit"
            *ngIf="editTemplate && selected(row)">
          <td *ngIf="multiple">
            {{selected(row)}}
            <input type="checkbox"
                   checked
                   *ngIf="selected(row)">
            <input type="checkbox"
                   *ngIf="!selected(row)">
          </td>
          <td [attr.colspan]="columns !== undefined ? columns.length : columnTemplates.length">
            <ng-template [ngTemplateOutlet]="editTemplate"
                         [ngTemplateOutletContext]="{rowData:row,rowIndex:index}"></ng-template>
          </td>
        </tr>
      </ng-template>
      </tbody>
      <tfoot *ngIf="pageable">
      <tr>
        <td class="p-0 pt-3"
            [colSpan]="(columns || columnTemplates).length">
          <nav class="page-pagination" aria-label="Page navigation">
            <span class="pagination__select">Rows per page</span>
            <select class="form-control form-dropdown" [(ngModel)]="this.selectedPageSize"
                    (change)="showData(this.page)">
              <option *ngFor="let x of this.pageSizeOptions" [ngValue]="x" [selected]="x === this.selectedPageSize">
                {{ x }}
              </option>
            </select>
            <span
              class="pagination__count">{{ this.pageStartRow }} - {{ this.pageEndRow }} of {{ this.rows.length }}</span>
            <ul class="pagination">
              <li class="page-item">
                <button type="button" class="btn btn-icon btn-quaternary" (click)="showData(this.page - 1)"><i
                  class="icon icon-caret--left"></i></button>
              </li>
              <li class="page-item">
                <button type="button" class="btn btn-icon btn-quaternary" (click)="showData(this.page + 1)"><i
                  class="icon icon-caret--right"></i></button>
              </li>
            </ul>
          </nav>
        </td>
      </tr>
      </tfoot>
    </table>
  </div>
</div>
