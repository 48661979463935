import { getPropertyOf } from '@cs/core/utils';

export class SizeUnit<TSize, TUnit> {

	size: TSize;
	unit: TUnit;
	static Empty: SizeUnit<any, any> = new SizeUnit<any, any>({unit: null, size: null});

	toString(): string {
		if (this.size !== null)
			return `${this.size}${this.unit}`;
		else
			return '';
	}

	constructor(init: SizeUnit<TSize, TUnit>) {
		this.size = getPropertyOf(init, 'size');
		this.unit = getPropertyOf(init, 'unit');

	}


}

export class Size {
	get width(): SizeUnit<number, string> | null {
		return this._width;
	}

	get height(): SizeUnit<number, string> | null {
		return this._height;
	}

	static Empty = new Size();

	private readonly _height: SizeUnit<number, string>;
	private readonly _width: SizeUnit<number, string>;

	constructor(width: SizeUnit<number, string> = null, height: SizeUnit<number, string> = null) {
		this._width  = width
									 ? new SizeUnit<number, string>({size: width.size, unit: width.unit})
									 : null;
		this._height = height
									 ? new SizeUnit<number, string>({size: height.size, unit: height.unit})
									 : null;
	}

}
