import { Component, Input } from '@angular/core';
import { DomSanitizer }     from '@angular/platform-browser';

@Component({
  selector:    'cs-statistics-panel-comparison-bar',
  templateUrl: './statistics-panel-comparison-bar.component.html'
})
export class CsStatisticsPanelComparisonBarComponent {

  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() data: any;

  constructor(private sanitizer: DomSanitizer) {
  }

  calculateWidth(size) {
    return this.sanitizer.bypassSecurityTrustStyle('width: ' + (size * 100) + '%');
  }
}
