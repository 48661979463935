import { CsDatepickerConfig } from './datepicker-config';
import {
	Component,
	forwardRef,
	ElementRef, ChangeDetectorRef, Injector, AfterViewInit
}                             from '@angular/core';
import {
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS, NgControl, FormControl
}                             from '@angular/forms';

import { CsCultureProvider }            from '@cs/common';
import { CsCultureDateParserFormatter } from '@cs/common';
import { CsDatepicker }                 from './datepicker.component';
import { NgbDateParserFormatter }       from './datepicker-core/ngb-date-parser-formatter';
import { NgbCalendar }                  from './datepicker-core/ngb-calendar';

@Component({
	selector:    'cs-datepicker-plain',
	templateUrl: './datepicker-plain.component.html',
	providers:   [
		{
			provide:     NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CsDatepickerPlain),
			multi:       true
		},
		{
			provide:     NG_VALIDATORS,
			useExisting: forwardRef(() => CsDatepickerPlain),
			multi:       true
		}
	],
	exportAs:    'picker'
})
export class CsDatepickerPlain extends CsDatepicker implements AfterViewInit {

	private control: FormControl;

	constructor(
		elementRef: ElementRef,
		ngbDateParserFormatter: NgbDateParserFormatter,
		cultureDateParserFormatter: CsCultureDateParserFormatter,
		cultureChanger: CsCultureProvider,
		config: CsDatepickerConfig,
		calendar: NgbCalendar,
		private injector: Injector,
		private changeRef: ChangeDetectorRef
	) {
		super(elementRef, ngbDateParserFormatter,
			cultureDateParserFormatter,
			cultureChanger,
			config,
			calendar);
	}

	// The form control is only set after initialization
	ngAfterViewInit(): void {
		const ngControl: NgControl = this.injector.get(NgControl, null);
		if (ngControl) {
			this.control = ngControl.control as FormControl;
		} else {
			// Component is missing form control binding
		}
	}

	resetValueToEmpty() {
		this.dateModel = null;
		this.changeRef.detectChanges();
	}
}
