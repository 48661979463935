import { TranslateService } from '@ngx-translate/core';

export function isUIPlaceholder(label: string) {
	return RegExp('{{(.+)\\|(.+)}}')
		.test(label);
}

export function getUIPlaceholder(label: string, i18n: TranslateService) {

	const found = RegExp('{{(.+)\\|(.+)}}')
		.exec(label);

	const full          = found[0];
	const placeholder   = found[1];
	const fallBackLabel = found[2];

	if (placeholder) {
		const translation = i18n.instant(placeholder.toUpperCase());
		if (translation === placeholder.toUpperCase())
			return fallBackLabel || full;
		else
			return translation;
	}

}
