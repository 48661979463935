<form enctype="application/x-www-form-urlencoded"
	  (keyup.enter)="submit()">
	<div class="form-group" *ngIf="requireResetCode">
		<input class="form-control"
			   type="text"
			   name="resetcode"
			   placeholder="{{'ENTER_RESET_CODE' | translate | capitalize}}"
			   (keyup)="onResetCodeChanged()"
			   [(ngModel)]="resetcode"
			   required="">
	</div>

	<div class="form-group" *ngIf="showUsername">
		<input class="form-control"
			   [disabled]="true"
			   type="text"
			   name="username"
			   autocomplete="off"
			   placeholder="{{'USER_NAME' | translate}}"
			   [(ngModel)]="username"
			   required="">
	</div>

	<div class="form-group" *ngIf="requireOldPassword === true && (!requireResetCode && showPasswords)">
		<input class="form-control"
			   type="password"
			   name="oldPassword"
			   placeholder="{{'ENTER_OLD_PASSWORD' | translate}}"
			   (keyup)="checkErrorState()"
			   [(ngModel)]="oldPassword"
			   required="">
	</div>
	<div class="form-group newpassword" *ngIf="showPasswords">
		<input class="form-control"
			   [ngClass]="{'failed': userFeedbackMessages.length}"
			   type="password"
			   name="password"
			   placeholder="{{'ENTER_NEW_PASSWORD' | translate}}"
			   (keyup)="checkNewPassword()"
			   [(ngModel)]="newPassword"
			   required="">
	</div>
	<div class="form-group" *ngIf="showPasswords && filledNewPassword">
		<input class="form-control"
			   type="password"
			   name="passwordCheck"
			   placeholder="{{'ENTER_NEW_PASSWORD_AGAIN' | translate}}"
			   (keyup)="checkErrorState()"
			   [(ngModel)]="newPasswordCheck"
			   required="">
	</div>
	<div class="card-text password-policy" *ngIf="filledResetCode && userFeedbackMessages.length === 0">
		<div class="group" *ngFor="let policygroup of passwordPolicyList">

			<div class="label" [innerHtml]="policygroup.label"></div>
			<ul>
				<li *ngFor="let item of policygroup.items" [innerHtml]="item"></li>
			</ul>
		</div>
	</div>
	<div class="card-text feedback" *ngIf="userFeedbackMessages.length>0">
		<div class="message danger" *ngFor="let message of userFeedbackMessages">{{message}}</div>
	</div>


	<div matTooltip="{{getErrorStateMessage()}}"
		 matTooltipPosition="above"
		 [matTooltipDisabled]="!isErrorState"
		 *ngIf="showPasswords">
		<button [disabled]="isErrorState"
				[csLoader]="isWaiting.isWaiting$ | async"
				[loaderAdditionalClasses]="['loader--white']"
				(click)="submit()"
				class="btn btn--primary btn--lg">
			{{requireResetCode ? ('RESET_PASSWORD' | translate) : ('CHANGE_PASSWORD' | translate)}}
		</button>
	</div>
	<button (click)="goBackOfLogout()"
			class="btn btn--white mt-2 btn--lg">
		{{requireResetCode ? ('CANCEL' | translate) : ('BACK' | translate)}}
	</button>
</form>

