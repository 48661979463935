export class FilterBarElementData {
	identifier: number | string;
	label: string;

	params: { [key: string]: any };

	constructor(init: Partial<FilterBarElementData> = {}) {
		Object.assign(this, init);
	}

}

export class FilterBarElementValue {
	data: FilterBarElementData[];
	groupLabelItem: FilterBarElementData;

	label: string;
	clickableLabel: boolean = false;
}
