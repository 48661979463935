import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { IndicatorComponent } from './indicator.component';
import { CsCultureModule }    from '@cs/common';

const COMPONENTS = [IndicatorComponent];

@NgModule({
	declarations:    [...COMPONENTS],
	imports:         [
		CommonModule,
		CsCultureModule
	],
	exports:         [...COMPONENTS],
	entryComponents: [...COMPONENTS]
})
export class CsIndicatorModule {
}
