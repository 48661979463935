import { LabelPosition }                from './label-position.model';
import { LayoutOrientation }            from './layout-orientation.model';
import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';

export class FormSettings {
	/**
	 * Render direction of the group elements
	 */
	groupOrientation: LayoutOrientation;
	/**
	 * Render direction of the form elements
	 */
	orientation: LayoutOrientation;
	/**
	 * Position of the label of each Widget
	 */
	labelPosition: LabelPosition;
	/**
	 * Show the state of a valid field
	 */
	showValidState: boolean;
	/**
	 * Flag to indicate the form allows auto-completion
	 */
	autoComplete: string;
	/**
	 * Flag to set the form to a read-only state
	 */
	readOnly: boolean;
	/**
	 * Flag to render readonly fields as labels
	 */
	readOnlyAsText: boolean;
	/**
	 * Flag to show the fields that are marked as key
	 */
	includeKeyFields: boolean;
	/**
	 * Set the alignment for the collections
	 */
	alignment: 'center' | 'left' | 'right';
	/**
	 * Validation should be run after the creation of the form
	 */
	validateOnInit: boolean;
	/**
	 * Override the default label width. useful for @{Link LabelPosition.Left}
	 */
	collectionLabelWidth?: string;
	/**
	 * Override the default label position.
	 */
	collectionLabelPosition: 'left' | 'center' | 'right';
	/**
	 * Label position margin override
	 */
	collectionLabelMargin?: string;

	constructor(init: Partial<FormSettings>) {
		this.orientation             = getPropertyOf(init, 'orientation', LayoutOrientation.Vertical);
		this.groupOrientation        = getPropertyOf(init, 'groupOrientation', LayoutOrientation.Horizontal);
		this.labelPosition           = getPropertyOf(init, 'labelPosition', LabelPosition.Left);
		this.showValidState          = getPropertyOf(init, 'showValidState', false);
		this.autoComplete            = hasPropertyOf(init, 'autoComplete')
																	 ? init.autoComplete
																	 : 'off';
		this.readOnly                = getPropertyOf(init, 'readOnly', false);
		this.readOnlyAsText          = getPropertyOf(init, 'readOnlyAsText', false);
		this.includeKeyFields        = getPropertyOf(init, 'includeKeyFields', true);
		this.alignment               = getPropertyOf(init, 'alignment', 'left');
		this.validateOnInit          = getPropertyOf(init, 'validateOnInit', false);
		this.collectionLabelWidth    = getPropertyOf(init, 'collectionLabelWidth', null);
		this.collectionLabelPosition = getPropertyOf(init, 'collectionLabelPosition', 'left');
		this.collectionLabelMargin   = getPropertyOf(init, 'collectionLabelMargin', null);
	}
}
