<div class="reporting" [class.act-as-dashboard]="actAsDashboard$ | async">

	<!-- The currently showing selection header for the opened HTML report -->
	<div class="reporting--header" [@.disabled]="isDisabled">

		<!-- Filter panel-->
		<div class="row m-0 reporting--filter"
			 [@hideFilter]="reportFilterAnimationState"
			 (@hideFilter.done)="filterAnimationDone($event)">
			<div class="cs-col main-panel cs-col-sm-8 p-0" [class.cs-col-sm-12]="actAsDashboard$ | async">
				<div class="row row-m-0 row-filter cs-panel-header">
					<!-- Filter heading -->
					<div class="cs-panel-header--header">
						<div class="cs-panel-header--header-title">
							<i class="mdi mdi-filter-variant mr-2"></i>
							{{'FILTER' | translate | titlecase}}
						</div>

						<ng-container
							*ngIf="(!(isLoadingCategoryItem | async) || !formGenerator?.formGroup?.pristine) && !(actAsDashboard$ | async)">
							<button type="button" (click)="onReset()"
									class="btn btn-quaternary btn--sm btn--icon-and-text"
									id="resetBtnReporting"
							><i class="mdi mdi-restore"></i>{{'RESET'|translate|titlecase}}
							</button>
						</ng-container>
						<i id="collapseToggleReportingFilter" class="mdi mdi-chevron-down mdi-24px hover-pointer" [class.mdi-chevron-up]="isCollapsed"
						   *ngIf="(actAsDashboard$ | async)"
						   (click)="collapse.toggle()"
						   [matTooltip]="!isCollapsed?('COLLAPSE'|translate|titlecase):('EXPAND'|translate|titlecase)"
						   [attr.aria-expanded]="!isCollapsed"
						   aria-controls="collapseExample"></i>

					</div>
					<!-- Filter body -->
					<div class="main reporting-filter cs-panel-header--content" #collapse="ngbCollapse"
						 [(ngbCollapse)]="isCollapsed">
						<div [@isLoading]="isLoadingCategoryItem | async">
							<cs-form-generator [dataSource]="selectedReportFormDefinition"
											   [layout]="'vertical'"
											   (formGroupChanges)="onSelectionChange($event)"
											   [editMode]="'editonly'"
											   [isInEditMode]="true"
											   [keepFormInEditMode]="true"
											   [showRevert]="false"
							>
							</cs-form-generator>
						</div>
						<div [@isLoadingReport]="isLoadingCategoryItem | async"
							 class="progress-container flex flex-align-items-center
							 flex-justify-content-center full-width full-height"
						>
							<div class="progress">
								<div class="progress-bar progress-bar-striped progress-bar-animated"
									 role="progressbar" aria-valuenow="0"
									 aria-valuemin="0" aria-valuemax="100"
									 [style.width]="progressValue | async"></div>
								<div class="progress-description">{{progressDescription | translate | titlecase}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<!-- Side panel with thumbnails and report buttons -->
			<div class="col side-panel col-sm-4 cs-panel">
				<div class="panel panel-content"
					 *ngIf="report"
					 [@isLoading]="isLoadingCategoryItem | async">

					<div class="side-panel-button-container flex flex-row">
						<button
							(click)="onExportPDF()"
							id="Export_PDF"
							class="btn btn--lg btn--dark flex-grow-1"
							*ngIf="hasPdfExport"
							[disabled]="this.formGenerator.formGroup.invalid"
							[matTooltip]="'BTN_EXPORT_PDF_DESCR' | translate | capitalize">
							{{'BTN_EXPORT_PDF' | translate | titlecase}}
						</button>


						<button
							id="Export_Excel"
							(click)="onExportExcel()"
							class="btn btn--lg btn--dark flex-grow-1"
							*ngIf="hasExcelExport"
							[disabled]="this.formGenerator.formGroup.invalid"
							[matTooltip]="'BTN_EXPORT_EXCEL_DESCR' | translate | capitalize">
							{{'BTN_EXPORT_EXCEL' | translate | titlecase}}
						</button>


						<button id="Create_report"
								(click)="onUpdateReport()"
								class="btn btn--lg btn--dark flex-grow-1"
								*ngIf="hasHtmlExport"
								[disabled]="this.formGenerator.formGroup.invalid"
								[matTooltip]="'BTN_CREATE_REPORT_DESCR' | translate | capitalize">
							{{'BTN_CREATE_REPORT' | translate | titlecase}}
						</button>

					</div>
					<div class="full-width description-last-update-container ">
						<div class="f-color-dimmed f-body-s">
							<i class="mdi mdi-sync mr-2"></i>{{report?.meta?.longDescriptionSubtitle || 'MESSAGE_NO_REPORT_TITLE' | translate | capitalize}}
						</div>
					</div>
					<div class="side-panel-description-container">
						<div class="flex flex-row flex-wrap">
							<div *ngFor="let thumb of report?.meta?.thumbnails" class="thumb-container shadow-subtle"
								 [ngStyle]="{'background-image': 'url(' + getFullUrl(thumb.url) + ')', 'background-size': 'cover'}">
								<i class="mdi mdi-arrow-expand thumb-expand-icon"
								   (click)="showLargerImageDialog(report?.meta?.thumbnails)"></i>
							</div>
						</div>
						<div class="html-container">
							<div
								[innerHtml]="report?.meta?.descriptionLong || 'MESSAGE_NO_REPORT_DESCR' | translate "></div>
						</div>
					</div>
				</div>
			</div>
		</div>


		<div [@showReport]="reportContainerAnimationState"
			 (@showReport.done)="reportContainerAnimationDone($event)"
			 class="panel-container cs-panel-header">

			<!-- Filter heading -->
			<div class="cs-panel-header--header"
				 [@showReport]="reportContainerAnimationState"
				 (@showReport.done)="filterSettingsAnimationDone($event)">
				<div class="flex flex-row flex-align-items-center full-width">
					<i class="mdi mdi-filter-variant mr-4"></i>
					<span class="text-no-whitespace mr-2">{{'REPORT' | translate | titlecase }}</span>
					<span class="f-color-dimmed text-no-whitespace f-body-s ">{{report?.meta?.label}}</span>
					<span class="ml-4 text-no-whitespace mr-2">{{'FILTER_SETTINGS' | translate | titlecase }} </span>
					<p class="f-color-dimmed truncate f-body-s flex-grow-1" [innerHtml]="selectedFilter"></p>
					<!-- TODO: (click)="onReset()" not implemented yet-->
					<button type="button" class="btn btn-quaternary btn-sm btn--icon-and-text"
							(click)="showFilter()"
							[class.hidden]="isLoadingCategoryItem || formGenerator.formGroup.pristine"
					><i class="mdi mdi-arrow-left"></i>{{'BACK'|translate|titlecase}}</button>
				</div>
			</div>
			<!-- Filter body -->


			<div class="cs-panel-header--content">

				<!-- Loader -->
				<div *ngIf="isLoadingContent"
					 [csLoader]="isLoadingContent"
					 [elementClasses]="['mt-4', 'mb-4', 'div-loader']"
					 [loaderContainerClasses]="['flex','flex-justify-content-center', 'flex-align-items-center' , 'full-width', 'fade', 'full-height-force']"
					 [loaderAdditionalClasses]="['loader--big']"
				></div>

				<!-- Report HTML container -->
				<div class="report-html-container"
					 [@showCharts]="reportRenderedAnimationState"
					 *ngIf="showNestedHtmlContainer" [innerHtml]="reportHtml" #reportHtmlContainer>
					<!--  [Placeholder Report] -->
				</div>

			</div>
		</div>
		<router-outlet></router-outlet>


	</div>
</div>
