import { NgModule, Inject }                                   from '@angular/core';
import { CommonModule }                                       from '@angular/common';
import { DashboardAuditsComponent }                           from './components/dashboard-audits';
import { FormsModule }                                        from '@angular/forms';
import { AuditTrailAuditsComponent }                          from './components/audit-trail-audits/audit-trail-audits.component';
import { AuditTrailChangesComponent }                         from './components/audit-trail-changes/audit-trail-changes.component';
import { AuditTrailStateService }                             from './state/audit-trail-state.service';
import { AuditTrailStateStore }                               from './state/audit-trail-state.store';
import { AuditTrailStateQuery }                               from './state/audit-trail-state.query';
import { ComponentTranslationLoaderService, CsCultureModule } from '@cs/common';
import { MatTooltipModule }                                   from '@angular/material/tooltip';
import { TranslateModule }                                    from '@ngx-translate/core';
import { DashboardComponentRegistry }                         from '@cs/components/shared';
import { CsTableNxtModule }                                   from '@cs/components/table-nxt';
import { CsComboboxModule }                                   from '@cs/components/combobox';

@NgModule({
						declarations: [AuditTrailAuditsComponent, AuditTrailChangesComponent, DashboardAuditsComponent],
						exports:      [AuditTrailAuditsComponent, AuditTrailChangesComponent, DashboardAuditsComponent],
						providers:    [AuditTrailStateService, AuditTrailStateStore, AuditTrailStateQuery],
						imports:      [
							CommonModule,
							FormsModule,
							CsCultureModule,
							CsComboboxModule,
							CsTableNxtModule,
							TranslateModule,
							MatTooltipModule
						]
					})
export class AuditTrailModule {

	constructor(loader: ComponentTranslationLoaderService,
							@Inject(DashboardComponentRegistry) registry: DashboardComponentRegistry) {
		loader.registerComponentModule('audit-trail');

		if (registry) {
			registry.register(DashboardAuditsComponent.TYPE_NAME, DashboardAuditsComponent);
		}
	}
}
