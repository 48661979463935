import { Inject, Injectable, Injector }                          from '@angular/core';
import { AuthenticationState, AuthenticationStore }              from './authentication.store';
import { CsHttpErrorResponse, CsHttpRequestOptions, LoggerUtil } from '@cs/core';
import { AuthenticationConfigService }                           from '../authentication-config.service';
import { interval, Subscription }                                from 'rxjs';
import { HttpErrorResponse }                                     from '@angular/common/http';
import { Router }                                                from '@angular/router';
import { isNullOrUndefined }                                     from '@cs/core';
import { CsToastManagerService }                                 from '@cs/components/toast-manager';
import { AuthenticationQuery }                                   from './authentication.query';
import { TranslateService }                                      from '@ngx-translate/core';
import { AppConstants }                                          from '../../app/application-constants';


@Injectable({providedIn: 'root'})
export class AuthenticationService {

	constructor(private authenticationStore: AuthenticationStore,
				private authenticationQuery: AuthenticationQuery,
				private router: Router,
				private toastService: CsToastManagerService,
				@Inject(AuthenticationConfigService) private config: AuthenticationConfigService) {
	}

	update(state: AuthenticationState) {
		this.authenticationStore.update(state);
	}

	startKeepAlive() {
		if (!isNullOrUndefined(this.keepAliveSender$))
			return;

		const errorHandler: CsHttpRequestOptions = new CsHttpRequestOptions();
		errorHandler.errorResponseHandler        = (error) => this.handleKeepAliveError(error);

		this.keepAliveSender$ = interval(this.config.keepAliveInterval)
			.subscribe(value => {
				this.config.keepAlivePing(errorHandler)
					.subscribe(() => LoggerUtil.debug('Still alive'));
			});

	}

	stopKeepAlive() {
		this.keepAliveSender$.unsubscribe();
		this.keepAliveSender$ = null;
	}

	goToLoginPage() {
		const queryParams = {
			[AppConstants.RETURN_URL]: (this.router.getCurrentNavigation() != null
										? this.router.getCurrentNavigation()
											  .extractedUrl
											  .toString()
										: '')
		};
		this.router.navigate([this.config.loginUrl], {queryParams: queryParams});
	}

	goToResetPasswordPage(l8n: TranslateService) {
		this.router.navigate([this.config.resetPasswordUrl])
			.then(() => {
				this.showResetPasswordPageMessage(l8n);
			});
	}

	logOut() {
		this.config.logOut()
			.subscribe(() => {
				this.update({userProfile: null, isImpersonated: false});
				this.router.navigate([this.config.loginUrl]);
			});
	}

	getUserProfile() {
		if (this.authenticationQuery.getValue().userProfile === null) {
			this.config.getUserProfile()
				.subscribe(value => {
					this.update({
									userProfile:    value.value,
									isImpersonated: value.value.isImpersonated
								});
				});
		}

	}

	goToExpiredPasswordPage(l8n: TranslateService) {
		const queryParams = {[AppConstants.RETURN_URL]: this.router.routerState.snapshot.url};
		this.router.navigateByUrl(this.config.expiredPasswordUrl, {queryParams: queryParams} as unknown)
			.then(() => this.showExpiredPasswordPageMessage(l8n));
	}

	goToSetPasswordPage(l8n: TranslateService) {
		const queryParams = {[AppConstants.RETURN_URL]: this.router.routerState.snapshot.url};
		this.router.navigateByUrl(this.config.setPasswordUrl, {queryParams: queryParams} as unknown)
			.then(() => this.showExpiredPasswordPageMessage(l8n));
	}

	showResetPasswordPageMessage(l8n: TranslateService) {
		this.toastService.show({
								   type:         'warning',
								   title:        l8n.instant('RESET_ACCOUNT_REQUIRED'),
								   content:      l8n.instant('RESET_ACCOUNT_REQUIRED_MESSAGE'),
								   clickToClose: true
							   });
	}

	showExpiredAutoLoginMessage(l8n: TranslateService) {
		this.toastService.show({
								   type:         'warning',
								   title:        l8n.instant('AUTO_LOGIN_EXPIRED'),
								   content:      l8n.instant('AUTO_LOGIN_EXPIRED_MESSAGE'),
								   clickToClose: true
							   });
	}

	showExpiredPasswordPageMessage(l8n: TranslateService) {
		this.toastService.show({
								   type:    'warning',
								   title:   l8n.instant('CHANGE_PASSWORD_REQUIRED'),
								   content: l8n.instant('CHANGE_PASSWORD_REQUIRED_MESSAGE')
							   });
	}

	showInvalidAutoLoginMessage(l8n: TranslateService) {
		this.toastService.show({
								   type:         'warning',
								   title:        l8n.instant('AUTO_LOGIN_INVALID'),
								   content:      l8n.instant('AUTO_LOGIN_INVALID_MESSAGE'),
								   clickToClose: true
							   });

	}

	goToSetMfaPage(response: CsHttpErrorResponse, l8n: TranslateService): void {
		this.router.navigateByUrl(this.config.mfaPageUrl)
			.then(() => {

			});
	}

	/**
	 * Subscription Handle for interacting with the keepAlice Ping
	 */
	private keepAliveSender$: Subscription;
	/**
	 * added translation service that uses the applications translation file
	 */
	private l8n: TranslateService;

	private handleKeepAliveError(error: HttpErrorResponse) {
		if (!error) {
			return false;
		}

		let handled = false;

		switch (error.status) {
			case 401:
			case 403:
				this.stopKeepAlive();
				this.router.navigate([this.config.loginUrl]);
				this.toastService.show({
										   type:    'alert',
										   title:   `Unauthorized`,
										   content: `Authentication successful, but user is not allowed.`
									   });
				handled = true;
				break;
		}

		return handled;
	}
}
