import { getPropertyOf } from '@cs/core/utils';

export class TableHeaderRowAnnotation {
	/**
	 * Identification of the Table Header
	 */
	id: string;
	/**
	 * Name to show in the UI
	 */
	label: string;

	/**
	 * Use this to create multiple grouped header rows
	 */
	idHeaderRow?: string;

	constructor(init: Partial<TableHeaderRowAnnotation>) {
		this.id          = getPropertyOf(init, 'id');
		this.label       = getPropertyOf(init, 'label');
		this.idHeaderRow = getPropertyOf(init, 'idHeaderRow', 'default');
	}
}
