import { ActivatedRoute, ActivatedRouteSnapshot, Params, Route, Router, UrlSegment } from '@angular/router';
import { NavItemRoute, RouteMetaData }                                               from '@cs/common/classes';
import { isEmptyObject, isNullOrUndefined, LoggerUtil }                              from '@cs/core';


export function getRoutesFromRouter(router: Router): Route[] {
	const foundModules = [];

	const _parse = (config) => {
		config.forEach(route => {
			foundModules.push(route);
			if (route._loadedConfig) {
				_parse(route._loadedConfig.routes || []);
			}
			_parse(route.children || []);
		});
	};

	_parse(router.config);

	return foundModules;
}

/**
 * Walk down the activated component starting at root. Constructing the current active path.
 */
export function getRoutesFromCurrentLoadedComponents(route: ActivatedRoute): UrlSegment[] {

	const pathChildrenOut: UrlSegment[] = [];

	function getChildrenPaths(out: UrlSegment[], pathChildren: ActivatedRouteSnapshot[]) {

		for (const routePart of pathChildren) {
			// Get the url path and create a deep clone. Fixing CSPM-1061
			const foundUrls = routePart.url.map(value => new UrlSegment(value.path, value.parameters));
			if (!isEmptyObject(routePart.params)) {
				// Get the route based on the path string configured by the routing module
				const paths = routePart.routeConfig.path.split('/');
				for (let routeDepth = 0; routeDepth < paths.length; routeDepth++) {
					const path = paths[routeDepth];
					// if the pathsegment is not a param
					if (path.startsWith(':')) {
						const cleanedModuleName          = path.replace(':', '');
						// Set the params that are active for that urlSegment
						foundUrls[routeDepth].parameters = {[cleanedModuleName]: routePart.params[cleanedModuleName]} as Params;
					}
				}

			}

			// Get the url of the current activated component
			out.push(...foundUrls);
			// Check if activated component has children, so it's clear that the route is deeper
			if (routePart.children && routePart.children.length > 0) {
				getChildrenPaths(out, routePart.children);
			}
		}

	}

	getChildrenPaths(pathChildrenOut, route.snapshot.root.children);

	return pathChildrenOut;
}

/**
 * Walk down the activated component starting at root. Constructing the current active url params.
 */
export function getAllUrlParamsFromActiveComponents(route: ActivatedRoute): Params {

	const paths = getRoutesFromCurrentLoadedComponents(route);
	return paths.reduce((previousValue, currentValue) => {
		return Object.assign(previousValue, currentValue.parameters);
	}, {});
}

/**
 * Walk down the activated component starting at root. Constructing the module path. It's assumed there is only one module defined.
 */
export function getModulePath(route: ActivatedRoute): UrlSegment[] {

	const paths     = getRoutesFromCurrentLoadedComponents(route);
	// Find the first route that has module params
	const moduleEnd = paths.findIndex(value => !isEmptyObject(value.parameters));
	// only return the path that comes before the module variable
	return moduleEnd === -1 ? [] : paths.slice(0, moduleEnd + 1);
}


/**
 * Walk up the route and check if there is an module loaded that contains the possible routes for the loaded module
 */
export function getRoutesFromCurrentLoadedModule(route: ActivatedRoute): Route[] {
	const foundModules = [];

	for (const routePart of route.snapshot.pathFromRoot.reverse()) {
		if (!isNullOrUndefined(routePart.routeConfig) && routePart.routeConfig.hasOwnProperty('children')) {
			foundModules.push(...routePart.routeConfig.children);
			break;
		} else if (!isNullOrUndefined(routePart.routeConfig) && routePart.routeConfig.hasOwnProperty('_loadedConfig')) {
			foundModules.push(...routePart.routeConfig['_loadedConfig'].routes);
			break;
		}
	}

	return foundModules;
}

function getRouteMetaDataFromRoute(route: Route) {
	return route.hasOwnProperty('data')
	&& route.data.hasOwnProperty('routeMetaData')
		? new RouteMetaData(route.data.routeMetaData)
		: new RouteMetaData({label: route.path});
}

export function getNavItemRoutesFromCurrentLoadedModule(activeRoute: ActivatedRoute): NavItemRoute[] {
	const routes = getRoutesFromCurrentLoadedModule(activeRoute);

	return routes.map(route => new NavItemRoute({
		path:          route.path,
		routeMetaData: getRouteMetaDataFromRoute(route)
	})).filter(value => value.path !== '');
}

export function getRoutesWithDepth(routes: Route[], depth: number[], groups: string[] = null): Route[] {
	return routes.filter(route =>
		route.data
		&& route.data.hasOwnProperty('routeMetaData')
		&& route.data.routeMetaData.hasOwnProperty('depth')
		&& depth.indexOf(route.data.routeMetaData.depth) > -1
		&&
		(isNullOrUndefined(groups) || (!isNullOrUndefined(groups)
			&& groups.indexOf(route.data.routeMetaData.group) > -1))
	);
}

export function getAndParseRoutesWithDepth(routes: Route[], depth: number[], groups: string[] = null): NavItemRoute[] {
	const found = getRoutesWithDepth(routes, depth, groups);

	return found.map(route => {
		if (route.hasOwnProperty('data')
			&& route.data.hasOwnProperty('routeMetaData')) {
			return new NavItemRoute({
				path:          route.path,
				routeMetaData: new RouteMetaData(route.data.routeMetaData)
			});
		} else {
			LoggerUtil.write(`No routeMetaDeta found for: ${route.path}`);
		}
	});
}




