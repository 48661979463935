import { getPropertyOf, LoggerUtil } from '@cs/core';
import { ErrorMessage }              from '../../app/error-messages';

export class IsAuthenticated {
	status: boolean;

	constructor(init: Partial<IsAuthenticated>) {
		try {
			this.status = getPropertyOf(init, 'status');
		} catch (e) {
			LoggerUtil.error(ErrorMessage.MAPPING_BUSINESS_MODEL_TO_APPLICATION_MODEL_FAILED(IsAuthenticated, e));
		}

	}
}
