import { FilterAndNavbarShellComponent } from './filter-and-navbar-shell.component';
import { CsRoute }                       from '@cs/common';

export class FilterAndNavbarShellRoutingLoader {
  static getRoutes(children: CsRoute[]): CsRoute[] {
    return [
      {
        path:     '', component: FilterAndNavbarShellComponent,
        children: [
          ...children
        ]
      }
    ];
  }
}
