export class Alert {
	title?: string;
	message: string;
	type: string;


	constructor(init: Alert) {
		Object.assign(this, init);
	}
}
