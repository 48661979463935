import { Injectable } from '@angular/core';
import { Query } from '@datorama/akita';
import { ErrorStore, ErrorState } from './error.store';

@Injectable({ providedIn: 'root' })
export class ErrorQuery extends Query<ErrorState> {

  constructor(protected store: ErrorStore) {
    super(store);
  }

}
