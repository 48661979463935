import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'mdiIcon',
	pure: true
})
export class MdiIconPipe implements PipeTransform {

	transform(value: string, size: 'sm' | 'md' | 'lg'): string {
		const sizeMdiFn = s => `mdi-${s}px`;
		let sizeNumber  = 12;
		const icon      = `mdi mdi-${value} `;

		switch (size) {
			case 'lg':
				sizeNumber = 24;
				break;
			case 'md':
				sizeNumber = 18;
				break;
			case 'sm':
				sizeNumber = 12;
				break;
		}

		return icon + sizeMdiFn(sizeNumber);
	}

}
