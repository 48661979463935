import { Injectable }           from '@angular/core';
import { DataEntryStateEventArgs } from '@cs/components/data-entry-state';
import { Subject }              from 'rxjs';
import { DataEntryStateColumn } from '../models/data-entry-state-column';
import { DataEntryStateStore }  from './data-entry-state.store';

@Injectable()
export class DataEntryStateService {

	/**
	 * Emits event when there is a action requested by a widget
	 */
	readonly onActionRequested = new Subject<DataEntryStateEventArgs>();

	constructor(private store: DataEntryStateStore) {
	}

	entryStateClicked(eventArgs: DataEntryStateEventArgs): void {
		this.onActionRequested.next(eventArgs);
	}
}
