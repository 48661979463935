import { ValidationErrors, ValidatorFn } from '@angular/forms';
import { Logger }                        from '@cs/components/util';
import { isNullOrUndefined }             from '@cs/core/utils';
import { ValidatorRegistry }             from './validation-registry.service';
import {
	AwbNumberValidationAnnotation,
	DateRangeValidationAnnotation, InputMaskValidationAnnotation,
	IValidationAnnotation, MaxlengthValidationAnnotation, MinlengthValidationAnnotation, RangeValidationAnnotation, RegexValidationAnnotation,
	RequiredValidationAnnotation, ValidationAnnotationType
}                                        from '@cs/core/generate';

export function validateValidators(validators: IValidationAnnotation[], value: any) {
	let result: ValidationErrors = {};
	validators.forEach(validator => {

		validator.validatorFunc()
											.forEach(validatorFunc => {
												result = {...result, ...validatorFunc(value)};
											});
	});

	return result;
}

export function parseValidatorsList(validators: IValidationAnnotation[],
																																				csValidatorRegistry?: ValidatorRegistry,
																																				propType?: string) {
	const output = [];

	if (isNullOrUndefined(validators))
		return output;

	// Switch to the function variant because a switch in a for loop with breaks. breaks the for loop :S
	validators.forEach(val => {
		switch (val.type.toLowerCase()) {
			case ValidationAnnotationType.Minlength:
				output.push(new MinlengthValidationAnnotation(val as MinlengthValidationAnnotation));
				break;
			case ValidationAnnotationType.Maxlength:
				output.push(new MaxlengthValidationAnnotation(val as MaxlengthValidationAnnotation));
				break;
			case ValidationAnnotationType.Required:
				output.push(new RequiredValidationAnnotation(val as RequiredValidationAnnotation));
				break;
			case ValidationAnnotationType.Range:
				const rangeVal = val as RangeValidationAnnotation;
				// Convert a date to a UNIX timestamp, so the validator can compare with numbers instead of dates
				// TODO: this should be formalised by the server
				if (propType === 'DateTime')
					output.push(new DateRangeValidationAnnotation(rangeVal));
				else
					output.push(new RangeValidationAnnotation(rangeVal));
				break;
			case ValidationAnnotationType.Regex:
			case 'regex':
				output.push(new RegexValidationAnnotation(val as RegexValidationAnnotation));
				break;
			case ValidationAnnotationType.InputMask:
				output.push(new InputMaskValidationAnnotation(val as InputMaskValidationAnnotation));
				break;
			case ValidationAnnotationType.AwbNumber:
				output.push(new AwbNumberValidationAnnotation(val as AwbNumberValidationAnnotation));
				break;
			default:
				if (isNullOrUndefined(val.type)) {
					Logger.Warning(`${JSON.stringify(val)} is not a valid validator`);
					break;
				}
				if (csValidatorRegistry && csValidatorRegistry.hasValidator(val.type)) {
					const validator = csValidatorRegistry.getValidator(val.type);
					if (isNullOrUndefined(validator)) {
						Logger.Warning(`${val.type} was found as validator, but the entry is NULL`);
						break;
					}

					output.push(new validator(val));
				} else {
					Logger.Warning(`${val.type} is not found as validator`);
				}

		}
	});
	return output;
}
