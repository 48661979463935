import { ElementRef, Inject, Injectable, Injector } from '@angular/core';
import { Overlay, ScrollStrategyOptions }           from '@angular/cdk/overlay';
import {
	CS_POPOVER_CONFIG_TOKEN,
	CsPopoverOverlayConfig,
	CsPopoverData,
	CsPopoverSetup,
	CsPopoverComponentSetup
}                                                   from './cs-data-grid-popovers.config';
import { ComponentPortal, PortalInjector }          from '@angular/cdk/portal';
import { CsPopoverOverlayRef }                      from './cs-popover-ref.model';
import { fromEvent as observableFromEvent, merge }  from 'rxjs';
import { debounceTime, map, repeat, takeUntil }     from 'rxjs/operators';
import { isNullOrUndefined }                        from '@cs/core';
import { LoggerUtil }                               from '@cs/core';

@Injectable({
	providedIn: 'root'
})
/**
 * Service for showing popovers for all datagrids (including the nested datagrids) on the page.
 * The popover uses the Angular CDK for positioning the popovers. The service is implemented to load the popover in a lazy method.
 * This is done because of the performance gained compared to the @HostListeners and template events methods.
 */
export class CsDataGridPopoversService {
	/**
	 * A list of popovers, each key is the id of a cell in one of the datagrids. It's used to store a reference of the popoverSetup object.
	 * This Setup object is created in the OnInit lifecycle of the CsGridDataTdComponent.
	 */
	private readonly registeredPopovers                                               = new Map<string, Array<CsPopoverSetup<any>>>();
	/**
	 * A list of available components that can be loaded as a popover.
	 */
	private readonly registeredPopoverComponents: Array<CsPopoverComponentSetup<any>> = [];

	constructor(
		private sso: ScrollStrategyOptions,
		private overlay: Overlay,
		private parentInjector: Injector,
		@Inject(CS_POPOVER_CONFIG_TOKEN) private popoverOverlayConfig: CsPopoverOverlayConfig
	) {
	}

	/**
	 * Register the Element that has a popover attached. The setup object contains al the information needed to show a popover
	 * @param setupObject The object that is providing the data, DomElement and id for the CsGridDataTdComponent
	 */
	registerPopover(setupObject: CsPopoverSetup<any>) {
		const popoverData = setupObject;
		this.setupListeners(popoverData);
		// Check if there is already an popover with that id. if so add the new popover to that id entry (AKA CsGridDataTdComponent)
		if (!this.registeredPopovers.has(popoverData.lookupId))
			this.registeredPopovers.set(popoverData.lookupId, [popoverData]);
		else {
			const popovers = this.registeredPopovers.get(popoverData.lookupId);
			popovers.push(popoverData);
		}
	}

	/**
	 * Register the component that could be used as a popover
	 * @param componentSetup Object providing the component and when to show the popover
	 */
	registerPopoverComponent(componentSetup: CsPopoverComponentSetup<any>) {
		this.registeredPopoverComponents.push(componentSetup);
	}

	/**
	 * This creates a new overlay, and will load the popover with the component and data that is provided
	 * @param data The data that is used by the popover
	 * @param host the element that has a popover attached
	 */
	show<TData, TComp>(data: CsPopoverData<TData, TComp>, host: ElementRef) {
		const positionStrategy = this.getPositionStrategy(host);
		const scrollStrategy   = this.sso.reposition();
		const overlayRef       = this.overlay.create({positionStrategy, scrollStrategy});

		const popoverRef  = new CsPopoverOverlayRef(overlayRef);
		const injector    = this.getInjector(data, popoverRef, this.parentInjector);
		const toastPortal = new ComponentPortal(data.component, null, injector);
		overlayRef.attach(toastPortal);

		return popoverRef;
	}

	private getPositionStrategy(host: ElementRef) {
		return this.overlay.position()
							 .flexibleConnectedTo(host)
							 .withPush(false)
							 .withPositions([
								 {
									 originX:  'center',
									 originY:  'top',
									 overlayX: 'center',
									 overlayY: 'bottom'
								 },
								 {
									 originX:  'center',
									 originY:  'bottom',
									 overlayX: 'center',
									 overlayY: 'top'
								 },
								 {
									 originX:  'start',
									 originY:  'center',
									 overlayX: 'end',
									 overlayY: 'center'
								 },
								 {
									 originX:  'end',
									 originY:  'center',
									 overlayX: 'start',
									 overlayY: 'center'
								 }
							 ]);
	}

	private getInjector<TData, TComp>(data: CsPopoverData<TData, TComp>, popoverRef: CsPopoverOverlayRef, parentInjector: Injector) {
		const tokens = new WeakMap();

		tokens.set(CsPopoverData, data.data);
		tokens.set(CsPopoverOverlayRef, popoverRef);

		return new PortalInjector(parentInjector, tokens);
	}

	private setupListeners(data: CsPopoverSetup<any>) {
		const elementRef = data.elementRef;

		// Merge all these events in a observable stream
		const showEvents$ = merge(
			observableFromEvent(elementRef.nativeElement, 'mouseenter'),
			observableFromEvent(elementRef.nativeElement, 'focusin'),
			observableFromEvent(elementRef.nativeElement, 'mousemove')
		);
		// Merge all these events in a observable stream
		const hideEvents$ = merge(
			observableFromEvent(elementRef.nativeElement, 'mouseleave'),
			observableFromEvent(elementRef.nativeElement, 'focusout')
		);

		// start listening
		showEvents$.pipe(
			// transform the event from the html element to the registered Popover
			map(($event: MouseEvent) => {
				const elementHtmlElement = elementRef.nativeElement as HTMLDivElement;
				const parentTd           = elementHtmlElement.parentElement;

				// Get the popover based on the id of the cell
				const found = this.getPopover(parentTd.id, data.elementIdentifier);
				// Check if there is a popover
				if (!found)
					return;

				if (found.popover.hasNoPopover(found.popover.data))
					return;

				// We are setting this value here because of the debounce time there is a delay setting the hasMouseOverHost,
				// Therefore the Popover will close. this is because the property is set to false when hovering from cell > popover > cell
				if (found.popover.popoverRef) {
					found.popover.popoverRef.hasMouseOverHost = true;
				}


				return found;
			}),
			// Wait for 400ms
			debounceTime(400),
			// Only trigger when there hasn't been a mouseleave or focusout during the 400ms wait
			takeUntil(hideEvents$),
			// start the listening again when there is a hideEvent$ triggered
			repeat())
							 .subscribe((found: { popover: CsPopoverSetup<any>, popoverComponent: CsPopoverComponentSetup<any> }) => {

								 // Check if there is a popover
								 if (!found)
									 return;

								 if (!found.popover.popoverRef || !found.popover.popoverRef.isOpen) {
									 found.popover.popoverRef                  = this.show({
										 component: found.popoverComponent.component,
										 data:      found.popover.data
									 }, found.popover.elementRef);
									 found.popover.popoverRef.isOpen           = true;
									 found.popover.popoverRef.hasMouseOverHost = true;
								 } else {
									 found.popover.popoverRef.hasMouseOverHost = true;
								 }

							 });
		hideEvents$.pipe(
			debounceTime(200))
							 .subscribe($event => {
								 const elementHtmlElement = elementRef.nativeElement as HTMLDivElement;
								 const parentTd           = elementHtmlElement.parentElement;

								 const found = this.getPopover(parentTd.id, data.elementIdentifier);
								 // Check if there is a popover
								 if (!found || !found.popover.popoverRef)
									 return;

								 if (found.popover.popoverRef.isOpen
									 && !found.popover.popoverRef.hasMouseOver
									 && found.popover.popoverRef.hasMouseOverHost
								 ) {
									 found.popover.popoverRef.isOpen           = false;
									 found.popover.popoverRef.hasMouseOver     = false;
									 found.popover.popoverRef.hasMouseOverHost = false;
									 found.popover.popoverRef.close();
								 }

							 });
	}

	private checkForLeftOverPopovers(currentPopover: string) {
		const all: Array<CsPopoverSetup<any>> = [];
		Array.from(this.registeredPopovers.values()).forEach(value => all.push(...value));
		const openPopovers = all.filter(value =>
			(value.popoverRef && value.popoverRef.isOpen) && value.elementIdentifier !== currentPopover);
		openPopovers.forEach(value => value.popoverRef.close());
	}

	private getPopover(id: string, elementIdentifier: string)
		: { popover: CsPopoverSetup<any>, popoverComponent: CsPopoverComponentSetup<any> } {
		if (this.registeredPopovers.has(id)) {
			const registeredPopovers = this.registeredPopovers.get(id);

			if (isNullOrUndefined(registeredPopovers) || registeredPopovers.length === 0) {
				LoggerUtil.debug(`Cell: ${id} has no popover registered`);
				return;
			}

			const popover = registeredPopovers.find(value => value.elementIdentifier === elementIdentifier);

			if (isNullOrUndefined(popover)) {
				LoggerUtil.debug(`Cell:  ${id} has no found popover for '${elementIdentifier}'`);
				return;
			}
			const popoverComponent = this.registeredPopoverComponents
																	 .find(value => value.elementIdentifier === popover.elementIdentifier
																		 && value.popoverType === popover.getPopoverType(popover.data));

			if (isNullOrUndefined(popoverComponent)) {
				LoggerUtil.debug(`Cell:  ${id} has no found popover component for '${elementIdentifier}'`);
				return;
			}

			return {popover, popoverComponent};
		}
		return null;
	}
}
