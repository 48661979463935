import { Component, Input, SimpleChanges }     from '@angular/core';
import { DomSanitizer, SafeHtml }              from '@angular/platform-browser';
import { animate, style, transition, trigger } from '@angular/animations';

@Component({
  selector:    'cs-statistics-panel-html',
  templateUrl: './statistics-panel-html.component.html',
  animations:  [
    trigger('statisticsState', [

      transition(':leave', [ // each time the binding value changes

        style({opacity: 1}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 0
          }))

      ]), transition(':enter', [ // each time the binding value changes

        style({opacity: 0}),
        animate('200ms cubic-bezier(0.4, 0.0, 0.2, 1)',
          style({
            opacity: 1
          }))

      ])
    ])
  ]

})
export class CsStatisticsPanelHtmlComponent {
  /**
   * The data source that contains the values of the statistics panel.
   */
  @Input() htmlToShow: string;

  safeHtml: SafeHtml;

  constructor(private sanitizer: DomSanitizer) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('htmlToShow')) {
      this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(changes['htmlToShow'].currentValue);
    }
  }

}
