<div class="cs-styleguide flex flex-justify-content-center
flex-align-items-center full-height mt-4 login-background-img error-container">
  <div class="card card-login">
    <div class="card-header">
      <div class="logo"></div>
      <h1 class="card-title">Error</h1>
      <p class="card-text">Sorry, an unrecoverable error occurred. Please go
        <a href="" (click)="goBack()">back</a> or
        <a [routerLink]="config.homeUrl">home</a> and try again.</p>
    </div>
    <div class="card-block">

      <p class="card-text">If your problems persist, please contact
        <a href="mailto:{{config.supportEmail}}" class="link">{{config.supportEmail}}</a>
      </p>
    </div>
  </div>
</div>
