import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormGroup }                                                    from '@angular/forms';

import { INode }                           from '../../interfaces/inode';
import { SafeMethods }                     from '@cs/common';
import { MdmPropertiesViewerEventService } from '../../mdm-properties-viewer-event.service';


@Component({
  selector:        'pmc-properties-view',
  templateUrl:     './properties-view.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CsPropertiesViewComponent {
  @Input() nodes: Array<INode>;
  @Input() editMode     = false;
  @Input() formGroup: FormGroup;
  @Input() columnLabels = [];

  constructor(private changeRef: ChangeDetectorRef,
              private mdmPropertiesViewerEventService: MdmPropertiesViewerEventService) {

  }


  toggleEditMode(node: INode) {
    node.editMode = !node.editMode;
    SafeMethods.detectChanges(this.changeRef);
  }

  cancelHandler(node: INode) {
    this.mdmPropertiesViewerEventService.cancelRequested.next(node);
  }

  saveData(node: INode) {
    this.mdmPropertiesViewerEventService.saveDataRequested.next(node);
  }
}
