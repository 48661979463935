import { AfterViewInit, Directive }  from '@angular/core';
import { LoggerUtil }                from '@cs/core';
import { isNullOrUndefined, Lookup } from '@cs/core';
import { ControlWidget }             from './control-widget.directive';

@Directive()
export class LookupControlWidget<T> extends ControlWidget<T> implements AfterViewInit {

	private typeIsArrayRegex = /^.+\[\]$/; // If the type ends with []

	lookup: Lookup;
	isMultiSelect = false;

	ngAfterViewInit() {
		this.initStatusChanges();
		this.initLookup();
		this.initLookupControl();
	}

	/**
	 * Instantiate the lookup by requesting it from the @Link(FormGeneratorAgentService)
	 */
	private initLookup() {
		// Check if the lookup exits
		if (isNullOrUndefined(this.propertyAnnotation.lookup) || this.propertyAnnotation.lookup === '') {
			LoggerUtil.warn(`${this.propertyAnnotation.id} lookup is empty`);
			return;
		}

		this.lookup = this.formAgent.getLookup(this.propertyAnnotation.lookup);

		// Don't set the default value
		if (this.formAgent.getFormInstance().dontSetDefaultValues)
			return;

		// match the value in with the value in the lookup
		if ((isNullOrUndefined(this.control.value) || this.control.value === 0)
			&& !isNullOrUndefined(this.lookup)
			&& !isNullOrUndefined(this.lookup.values) && this.lookup.values.length > 0) {
			const found = this.lookup.values.find(v => v.key === this.control.value);
			if (isNullOrUndefined(found)) {
				LoggerUtil.error(`${this.control.value} is not found in ${this.propertyAnnotation.lookup}`);
				// DON"T SET THE FIRST VALUE IF NULL
				// this.control.setValue(this.lookup.values[0].key);
			} else
				this.control.setValue(found.key);
		}

	}

	private initLookupControl() {
		const isArray     = this.propertyAnnotation.type.match(this.typeIsArrayRegex);
		const typeIsArray = !isNullOrUndefined(isArray) && isArray.length > 0;

		this.isMultiSelect = (this.control.value instanceof Array)
			|| typeIsArray;

		if (this.isMultiSelect) {
			if (!(this.control.value instanceof Array)) {
				const initialVal = !isNullOrUndefined(this.control.value) ? [this.control.value] : [];
				this.control.setValue(initialVal);
			}
		}
	}
}
