import {
	Component, HostBinding, HostListener,
	Input,
	OnInit
}                      from '@angular/core';
import { WidgetInfo }  from '@cs/core';
import { WidgetModel } from './models/widget.model';


@Component({
	selector: 'pm-form-element',
	template: `
                <div *ngIf="widget?.layout?.showLabel" class="form-collection-label-container form-collection-label--top">
                    <label class="form-collection-label">
                        {{widget?.propertyAnnotation?.label}}
                    </label>
                </div>
                <pm-widget-switch [widgetInfo]="widgetInfo"
                                  (widgetInstantiated)="onWidgetInstantiated($event)">
                </pm-widget-switch>
						`
})
export class FormElementComponent<T> implements OnInit {

	@Input() widgetInfo: WidgetInfo<T>;

	widget: WidgetModel<any> = null;


	constructor() {
	}

	ngOnInit() {

	}

	onWidgetInstantiated(widget: any) {
		this.widget = widget;
		const id    = this.widgetInfo.propertyAnnotation.id;

		this.widget.propertyAnnotation = this.widgetInfo.propertyAnnotation;
		this.widget.name               = id.toString();
		this.widget.id                 = id.toString();
		this.widget.control            = this.widgetInfo.control;
		this.widget.layout             = this.widgetInfo.layout;
	}

	@HostBinding('style.flexBasis')
	get width() {
		return this.widget ? this.widget.layout.width : null;
	}
	@HostBinding('style.textAlign')
	get textAlign() {
		return this.widget ? this.widget.layout.textAlign : null;
	}

	@HostBinding('style.flexGrow')
	get flexGrow() {
		return this.widget ? this.widget.layout.flexGrow : null;
	}

	@HostBinding('style.flexShrink')
	get flexShrink() {
		return this.widget ? this.widget.layout.flexShrink : null;
	}


	@HostListener('click', ['$event']) debugClick(e: MouseEvent) {
		if (e.shiftKey) {
			console.log(this);
		}
	}
}
