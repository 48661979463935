import { ModuleWithProviders, NgModule, Provider, Type }                from '@angular/core';
import { CommonModule }                                                 from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpHandler, HttpClient } from '@angular/common/http';
import { CsHttpClient }                                                 from './cs-http-client.service';
import { HttpErrorInterceptor }                                         from './http-error.interceptor';
import { HttpErrorGenericHandler }                                      from './http-error-generic-handler.class';
import { LoaderService, LoaderInterceptor }                             from './http-request-counter.interceptor';

export const CS_SERVICES: Provider[] = [LoaderService];

@NgModule({
  imports:      [
    CommonModule,
    HttpClientModule
  ],
  declarations: [],
  providers:    [...CS_SERVICES]
})
/**
 * Module for intercepting errors and handle them globally,
 * also checks if the request has an @Link(CsHttpRequestOptions).
 */
export class CsHttpModule {
  static forRoot(genericHttpErrorHandler: Type<HttpErrorGenericHandler>): ModuleWithProviders<CsHttpModule> {
    return {
      ngModule:  CsHttpModule,
      providers: [
        {
          provide:  HttpErrorGenericHandler,
          useClass: genericHttpErrorHandler
        },
        {
          provide:  HTTP_INTERCEPTORS,
          useClass: HttpErrorInterceptor,
          multi:    true
        },
        {provide: HttpClient, useClass: CsHttpClient, deps: [HttpHandler]},
        {
          provide:  HTTP_INTERCEPTORS,
          useClass: LoaderInterceptor,
          multi:    true
        },
        ...CS_SERVICES
      ]
    };
  }
}
