import { Component, Inject, ChangeDetectorRef, AfterViewInit, OnInit, OnDestroy, Input, OnChanges } from '@angular/core';
import {
	AuditTrailStateService
}                                                                                                   from '../../state/audit-trail-state.service';
import {
	AuditTrailStateQuery
}                                                                                                   from '../../state/audit-trail-state.query';
import { UntilDestroy, untilDestroyed }                                                             from '@ngneat/until-destroy';
import { AuditTrailAudits, AuditTrailAuditsItem, AuditTrailAuditsItemType }                         from './audit-trail-audits.model';
import { Subject, Observable }                                                                      from 'rxjs';
import {
	AuditTrailStateStore
}                                                                                                   from '../../state/audit-trail-state.store';
import { ComponentChanges, whenChanging }                                                           from '@cs/core';


@UntilDestroy()
@Component({
						 selector:    'pm-audit-trail-audits',
						 templateUrl: './audit-trail-audits.component.html'
					 })
export class AuditTrailAuditsComponent implements OnInit,
																									AfterViewInit,
																									OnDestroy,
																									OnChanges {

	/** Filtered data based on user selection for display */
	filteredData: AuditTrailAudits;

	/** Emit event when an item is clicked, passing the selection property. */
	onItemClicked: Subject<AuditTrailAudits> = new Subject();

	/**
	 * Array of category categories
	 */
	categories: { value: string, label: string }[];

	/**
	 * Current selected categories
	 */
	activeCategories: string[];

	/**
	 * When true, multiple categories can be selected
	 */
	isCategoriesMultiSelect = true;

	/**
	 * flag indicating that the panel is in loading state
	 */
	isPanelLoading$: boolean;

	/**
	 * Data is provided externally by the Service
	 */
	get data(): AuditTrailAudits {
		return this._data;
	}

	@Input()
	set data(value: AuditTrailAudits) {
		this._data = new AuditTrailAudits(value);
		this.setCategories();
		this.filterData();
	}

	constructor(@Inject(AuditTrailStateService) private stateService: AuditTrailStateService,
							@Inject(AuditTrailStateQuery) private stateQuery: AuditTrailStateQuery,
							@Inject(AuditTrailStateStore) private store: AuditTrailStateStore,
							private changeRef: ChangeDetectorRef) {
	}

	ngOnInit() {

		this.categories = [
			{
				value: AuditTrailAuditsItemType.DATA_ENTRY,
				label: 'Manual data entry'
			},
			{
				value: AuditTrailAuditsItemType.FEED,
				label: 'Feed data'
			},
			{
				value: AuditTrailAuditsItemType.SYSTEM,
				label: 'System actions'
			}
		];

		this.activeCategories = [AuditTrailAuditsItemType.DATA_ENTRY, AuditTrailAuditsItemType.FEED];

	}

	ngAfterViewInit() {
		this.stateQuery.select(state => state.isAuditPanelLoading)
				.pipe(
					untilDestroyed(this)
				)
				.subscribe(value => {
					this.isPanelLoading$ = value;
					this.changeRef.detectChanges();
				});

		this.stateQuery.select(state => state.auditsData)
				.pipe(
					untilDestroyed(this)
				)
				.subscribe(value => {
					this.data = value;
				});

		// notify service component is ready
		this.stateService.onAfterAuditsComponentViewInit();

	}

	/**
	 * Handle item clicks by passing the selection property.
	 * @param item AuditTrailAuditsItem
	 */
	itemClicked(item: AuditTrailAuditsItem) {
		this.stateService.auditItemClicked({selection: item.selection});
	}

	/**
	 * Handle category selection changes.
	 */
	public onCategorySelectionChanged(): void {
		// filter visible data on user section
		this.filterData();
	}

	ngOnDestroy() {
		this.stateService.closeDetailsPanel();
	}

	ngOnChanges(changes: ComponentChanges<AuditTrailAuditsComponent>): void {
		// whenChanging(changes.data, true)
		// 	.execute(value => {
		// 		this._data = value.currentValue;
		// 	});

	}

	private _data: AuditTrailAudits;

	/**
	 * Array of all options
	 */
	private readonly categoryOptions = [
		{
			'value': AuditTrailAuditsItemType.DATA_ENTRY, 'label': 'Manual data entry'
		},
		{
			'value': AuditTrailAuditsItemType.FEED, 'label': 'Feed data'
		},
		{
			'value': AuditTrailAuditsItemType.SYSTEM, 'label': 'System actions'
		}
	];

	/**
	 * Sets categories based on data
	 */
	private setCategories(): void {
		this.categories = [];
		this.categoryOptions.forEach((option) => {
			if (this.data.items.find(item => item.type === option.value))
				this.categories.push(option);
		});

		this.activeCategories = this.categories.reduce((a, b) => a.concat(b.value), []);
	}

	/**
	 * Filters the data item by selected categories
	 */
	private filterData(): void {
		this.filteredData       = new AuditTrailAudits(this.data);
		this.filteredData.items = this.filteredData.items.filter(item => this.activeCategories.find(c => c === item.type));
		this.changeRef.detectChanges();
	}
}
