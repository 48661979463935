import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Input, OnDestroy } from '@angular/core';
import { UntilDestroy, untilDestroyed }                                              from '@ngneat/until-destroy';
import { fromEvent }                                                                 from 'rxjs';
import { debounceTime }                                                              from 'rxjs/operators';

@UntilDestroy()
@Directive({
						 selector: '[csIsSticky]'
					 })
export class IsStickyDetectorDirective implements AfterViewInit,
																									OnDestroy {

	@Input('csIsSticky') input: string;

	constructor(private element: ElementRef) {

	}

	ngAfterViewInit(): void {


		const el = this.element.nativeElement;
		fromEvent(el, 'scroll', {passive: true})
			.pipe(untilDestroyed(this), debounceTime(10))
			.subscribe(event => {
				if (el.scrollLeft > 0)
					el.classList.add(this.input);
				else
					el.classList.remove(this.input);

			});

	}

	ngOnDestroy(): void {
	}


}
