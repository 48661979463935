import { Injectable }         from '@angular/core';
import { Store, StoreConfig } from '@datorama/akita';


export interface BrandingState {
	backDropImage: string;
	personalLogo: string;
}

export function createInitialStateBranding(): BrandingState {
	return {
		backDropImage: null,
		personalLogo:  null
	};
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'branding'})
export class BrandingStore extends Store<BrandingState> {

	constructor() {
		super(createInitialStateBranding());
	}

}

