import { getPropertyOf, hasPropertyOf } from '@cs/core/utils';
import { WidgetLayout }                 from './widget-layout.model';

/**
 * Annotation object that sets in which formset and widgetcollection the widget should render
 */
export class WidgetAnnotation<T> {

	constructor(init: Partial<WidgetAnnotation<T>>) {
		this.id                 = getPropertyOf(init, 'id');
		this.displayType        = getPropertyOf(init, 'displayType', null);
		this.include            = getPropertyOf(init, 'include', true);
		this.widgetCollectionId = getPropertyOf(init, 'widgetCollectionId', null);
		this.fieldFormat        = getPropertyOf(init, 'fieldFormat', null);
		this.layout             = hasPropertyOf(init, 'layout')
			? new WidgetLayout(init.layout) : new WidgetLayout({});
	}

	/**
	 * Identification of the form
	 */
	id: keyof T;

	/**
	 * Use this as an override to render a different widget when the Default type rendering is not sufficient. @Link(WidgetRegistry)
	 */
	displayType?: string;
	/**
	 * Id of the @Link(WidgetCollection) where the widget should render, if not provided for each widget
	 * an parent @Link(WidgetCollection) will be created
	 */
	widgetCollectionId?: string;
	/**
	 * Specify the layout of widget
	 */
	layout?: WidgetLayout;
	/**
	 * Include the in rendering process
	 */
	include: boolean;
	/**
	 * Use thing format string to format the value
	 */
	fieldFormat?: string;

}
