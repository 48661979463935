import { ConnectionPositionPair } from '@angular/cdk/overlay';

/**
 * Check the position of the content with reference of the trigger.
 * It compares the position strategy and returns the transform origin value
 * @param connectionPair the applied position strategy
 */
export function getTransformOrigin(connectionPair: ConnectionPositionPair): string {
  const position = getPositionBasedOnOrigin(connectionPair);

  if (position === 'bottomLeft')
    return 'top left';
  else if (position === 'top')
    return 'top center';
}

/**
 * Check the position of the content with reference of the trigger.
 * It compares the position strategy and returns the orientation.
 * @param connectionPair the applied position strategy
 */
export function getPositionBasedOnOrigin(connectionPair: ConnectionPositionPair): 'bottomLeft' | 'bottom' | 'top' | 'right' | 'left' {
  const {overlayX, overlayY, originY, originX} = connectionPair;

  if (originX === 'start' &&
    originY === 'bottom' &&
    overlayX === 'start' &&
    overlayY === 'top')
    return 'bottomLeft';
  else if (originX === 'center' &&
    originY === 'bottom' &&
    overlayX === 'center' &&
    overlayY === 'top')
    return 'bottom';
  else if (originX === 'center' &&
    originY === 'top' &&
    overlayX === 'center' &&
    overlayY === 'top')
    return 'top';
  else if (originX === 'end' &&
    originY === 'center' &&
    overlayX === 'start' &&
    overlayY === 'center')
    return 'right';
  else if (originX === 'start' &&
    originY === 'center' &&
    overlayX === 'end' &&
    overlayY === 'center')
    return 'left';
}
