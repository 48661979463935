export class ActionResult<TResult> {

  result: TResult;
  resultMessage: string;

  constructor(result: TResult, resultMessage?: string) {
    this.result        = result;
    this.resultMessage = resultMessage;
  }
}
