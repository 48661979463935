import { Inject, Injectable }                    from '@angular/core';
import { CsCultureDateParserFormatter }          from '@cs/common';
import { NgbDateParserFormatter, NgbDateStruct } from '../datepicker-core';

@Injectable()
export class CsDateParserFormatter extends NgbDateParserFormatter {
	constructor(@Inject(CsCultureDateParserFormatter) private culturedDateParserFormatter: CsCultureDateParserFormatter) {
		super();
	}

	format(date: NgbDateStruct): string {
		if (date === null) {
			return '';
		}
		// Padstart to make sure day an month are length of 2. this is for IE new Date parsing
		const d = date.year + '-' + date.month.toString().padStart(2, '0') + '-' + date.day.toString().padStart(2, '0');
		return this.culturedDateParserFormatter.formatDate(d);
	}

	parse(value: string): NgbDateStruct {
		if (!value) {
			return null;
		}
		const date = this.culturedDateParserFormatter.parseDate(value);
		return {
			year:  date.getFullYear(),
			month: date.getMonth() + 1,
			day:   date.getDate()
		};
	}
}
