import { convertKeysToFnv32a, getPropertyOf } from '@cs/core/utils';
import { DataKey }                            from './data-key.model';


export type DataHeaderDefinitionInit = Pick<DataFieldDefinition, 'index' | 'label' |
																 'levelKey' | 'levelValue'>
									   & Partial<DataFieldDefinition>;

export enum DataHeaderDefinitionType {
	HEADER       = 'HEADER',
	GROUP_HEADER = 'GROUP_HEADER'
}

export class DataFieldDefinition {
	readonly type: DataHeaderDefinitionType = DataHeaderDefinitionType.HEADER;

	visible: boolean;

	label: string | null;
	keys: DataKey;
	index: number;

	/**
	 * The level key of the group. Group2 for example
	 */
	levelKey: string;

	/**
	 * The level key value of the group. Like OPS_State
	 */
	levelValue: string;
	/**
	 * The Structure identifier for the parent group
	 */
	groupStructureKey: string | null;

	/**
	 * The combination of the @Link{structureKey} and @Link{structureValue} as a stucture identifiers
	 */
	get structureKey(): string {
		if (this._structureKey == null)
			this._structureKey = convertKeysToFnv32a({[this.levelKey]: this.levelValue});
		return this._structureKey;
	}

	constructor(init: DataHeaderDefinitionInit) {

		this.index             = getPropertyOf(init, 'index');
		this.levelKey          = getPropertyOf(init, 'levelKey');
		this.levelValue        = getPropertyOf(init, 'levelValue');
		this.groupStructureKey = getPropertyOf(init, 'groupStructureKey', null);
		this.label             = getPropertyOf(init, 'label', null);
		this.visible           = getPropertyOf(init, 'visible', true);
		this.keys              = {[init.levelKey]: init.levelValue};
	}

	private _structureKey: string;
}
