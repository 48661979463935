import { Injectable }                   from '@angular/core';
import { Step, WizardValidationResult } from '../models';
import { Store, StoreConfig }           from '@datorama/akita';

export interface WizardState {
	currentStep?: Step;
	errorMessages?: WizardValidationResult[];
}

export function createInitialWizardState(): WizardState {
	return {
		errorMessages: null,
		currentStep:   null
	};
}

@Injectable()
@StoreConfig({name: 'wizardstate'})
export class WizardStore extends Store<WizardState> {

	constructor() {
		super(createInitialWizardState());
	}

}

