import { ElementRef, InjectionToken, TemplateRef } from '@angular/core';
import { ComponentType }       from '@angular/cdk/portal';
import { PopoverTypes }        from '../enums/data-grid.enum';
import { CsPopoverOverlayRef } from './cs-popover-ref.model';

export class CsPopoverComponentSetup<T> {
  /**
   * Match the name of the element that is firing the event
   */
  elementIdentifier: string;
  /**
   * The component that is used to render the popover
   */
  component: ComponentType<T>;
  /**
   * Load when the type is a match
   */
  popoverType: PopoverTypes;
}

export class CsPopoverSetup<T> {

  /**
   * The id that is used for matching the popover cell with triggerElement
   * for example <TD id='abc'> fires event. That way there could be searched for 'abc' matching the lookupID
   */
  lookupId: string;
  /**
   * The name of the element that is firing the event, because of the multiple trigger areas it
   * should be passed for loading the correct popover
   */
  elementIdentifier: string;
  /**
   * The reference to the trigger element, is used for setting up the listeners
   */
  elementRef: ElementRef;
  /**
   * Holds a reference to the data that is being used in the popover
   */
  data: T;

  popoverRef?: CsPopoverOverlayRef;
  /**
   * A check if the popover should be shown or should be ignored
   * @param data the expression that validates visibility
   */
  hasNoPopover   = (data: T) => true;
  /**
   * A check what kind of popover it is
   * @param data the expression that validates visibility
   */
  getPopoverType = (data: T) => PopoverTypes.None;

}

export class CsPopoverData<TData, TComp> {
  /**
   * Holds a reference to the data that is being used in the popover
   */
  data: TData;
  /**
   * The component that is used to render the popover
   */
  component: ComponentType<TComp>;
}

export interface CsPopoverOverlayConfig {
  position?: {
    bottom: number;
    top: number;
    right: number;
  };
  animation?: {
    fadeOut: number;
    fadeIn: number;
  };
  timeOut: number;
  pauseOnHover: boolean;
}

export const defaultPopoverConfig: CsPopoverOverlayConfig = {
  position:     {
    bottom: 20,
    top:    0,
    right:  20
  },
  animation:    {
    fadeOut: 200,
    fadeIn:  200
  },
  timeOut:      4000,
  pauseOnHover: true
};

export const CS_POPOVER_CONFIG_TOKEN = new InjectionToken('cs-popover-config-token');
