import { Component }                   from '@angular/core';
import { DomSanitizer, SafeHtml }      from '@angular/platform-browser';
import { DashboardHtml }               from '../../models/dashboard-html';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { ArrayUtils }                  from '@cs/core';
import { DashboardEventHub }           from '../../dashboard-event-hub.service';

@Component({
			   selector:    'cs-dashboard-empty',
			   templateUrl: './dashboard-empty.component.html',
			   styleUrls:   ['./dashboard-empty.component.scss']

		   })
export class DashboardEmptyComponent extends DashboardPanelComponentBase<DashboardHtml> {
	name: string;

	set data(value: DashboardHtml) {
		this._data = value;
		this.setData();
	}

	safeHtml: SafeHtml;

	constructor(private sanitizer: DomSanitizer, private hub: DashboardEventHub) {
		super();
	}

	dataChanged(value: DashboardHtml) {
		throw new Error('Method not implemented.');
	}

	setData(): void {
		this.safeHtml = this.sanitizer.bypassSecurityTrustHtml(this._data.html);
	}

	update(data: DashboardHtml): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;
		this.setData();
	}
}
