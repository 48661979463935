import { BaseInput }       from './base-input';
import {isNullOrUndefined} from '@cs/core';

export class TimezoneInput extends BaseInput {
  public options;
  public timezone;
  public constructor(data: any) {
    super(data);
  }

  public setMetaData(key: string, data: any) {
    super.setMetaData(key, data);
    this.options = this.metaData['timezones'];

    let timezone = this.getTimezoneById(this.node.data.intValue);
    if (!isNullOrUndefined(timezone))
      this.timezone = timezone.id;
  }

  public getTimezoneById(id) {
    for (let continent of this.options) {
      for (let timezone of continent.timezones) {
        if (timezone.id == id) {
          return timezone;
        }
      }
    }
  }

  public onSelectChange(e) {
    let timezone = this.getTimezoneById(this.timezone);
    this.data.intValue = timezone.id;
    this.data.textValue = timezone.code;
    this.markAsDirty();
  }
}
