import { DataGridAction }                           from '../classes/data-grid-action';
import { DataGridCellType, GridItemType, RowState } from '../enums/data-grid.enum';
import { DataGridRuleMatch }                        from '../classes/data-grid-rule-match';


/**
 * Created by alex on 3-7-2017.
 */


export class GridRule {
  /**
   * Apply the rule for selected data-grid part
   */
  target: GridItemType           = GridItemType.Cell;
  /**
   * Stores all the keys for this rule
   */
  match: DataGridRuleMatch       = new DataGridRuleMatch({});
  actions: Array<DataGridAction> = [];
  description                    = 'No description';
  cellType: DataGridCellType     = DataGridCellType.Data;
  rowType: RowState              = RowState.Default;

  constructor(initial: Partial<GridRule>) {
    Object.assign(this, initial);
  }
}
