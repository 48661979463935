import {
	ChangeDetectorRef, Component, ElementRef,
	forwardRef, Inject, NgZone, Optional, ViewChild
}                                                        from '@angular/core';
import { DashboardPanelComponentBase } from '@cs/components/shared';
import { NotifyServerForChangesDashboardPanelEventArgs } from '../../models/notify-server-for-changes-dashboard-panel-event-args';
import {
	ArrayUtils,
	CsHttpRequestOptions,
	DataDescribed, gv,
	LayoutAnnotation,
	PropertyAnnotation,
	updateTargetSources,
	ValidationResult
}                                    from '@cs/core';
import { DashboardEventHub }         from '../../dashboard-event-hub.service';
import { FormGeneratorNxtComponent } from '@cs/components/form-generator-nxt';
import { HttpErrorResponse }         from '@angular/common/http';
import { BehaviorSubject }           from 'rxjs';
import { CsToastManagerService }     from '@cs/components/toast-manager';
import { FilterCompareBarQuery }     from '@cs/components/filter-and-compare-bar';
import { TranslateService }          from '@ngx-translate/core';
import { take }                      from 'rxjs/operators';
import { isArray }                   from '@cs/core';
import { MatDialogRef }              from '@angular/material/dialog';


@Component({
			   selector:    'cs-dashboard-form-generator',
			   templateUrl: './dashboard-form-generator.component.html',
			   styleUrls:   ['./dashboard-form-generator.component.scss']
		   })
export class DashboardFormGeneratorComponent extends DashboardPanelComponentBase<DataDescribed<any>> {


	@ViewChild(FormGeneratorNxtComponent, {static: true}) formGenerator: FormGeneratorNxtComponent<DataDescribed<any>>;
	@ViewChild('saveBtn', {static: false, read: ElementRef}) saveButton: ElementRef;

	name: string;
	requestInProgress$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	forceDisable                                 = false;
	contextObject: {
		[p: string]: any
	};

	public get data(): DataDescribed<unknown> {
		return this._data;
	}

	public set data(value: DataDescribed<unknown>) {
		if (!(value instanceof DataDescribed)) {
			value = new DataDescribed(value);
		}

		this._data = value;
	}

	constructor(@Inject(forwardRef(() => DashboardEventHub)) private dashboardEventHub: DashboardEventHub,
				@Optional() @Inject(MatDialogRef) private matReg: MatDialogRef<any>,
				private changeRef: ChangeDetectorRef,
				private toastManageer: CsToastManagerService,
				private i8n: TranslateService,
				private ngZone: NgZone,
				@Optional() private filterCompareBarQuery: FilterCompareBarQuery
	) {
		super();
		this.contextObject = dashboardEventHub.getParentContext();
	}

	update(data: any): void {
		if (this.data && ArrayUtils.isEqual(this.data, data))
			return;

		this.data = data;

	}

	dataChanged(value: DataDescribed<any, LayoutAnnotation<any>, any, any>) {

	}

	notifyChangesToServer(closeAfterNotify = false) {
		this.requestInProgress$.next(true);


		const options                = new CsHttpRequestOptions();
		options.errorResponseHandler = (response: HttpErrorResponse) => {
			this.requestInProgress$.next(false);
			switch (response.status) {
				case 400:
					this.formGenerator.showErrorResponse(response.error.map(e => new ValidationResult(e)));
					this.changeRef.detectChanges();
					return true;
			}
		};

		const data      = this.formGenerator.getFormData();
		const dashboard = this.dashboardEventHub.getParent();

		const selectionObject = {
			...this.data.getSelectionObject(),
			dashboard: dashboard.data.name, // Depricated
			...gv(() => this.filterCompareBarQuery.getValue().mainbarResultParams, {} as any)
		};

		this.dashboardEventHub.notifyChangesToServer(new NotifyServerForChangesDashboardPanelEventArgs<any>({
																												data:                 data,
																												panelName:            this.name,
																												selection:            selectionObject,
																												csHttpRequestOptions: options,
																												requestInProgress:    this.requestInProgress$,
																												callback:             value => {
																													this.toastManageer.show({
																																				type:    'success',
																																				content: this.i8n.instant(
																																					'CHANGES_ARE_SAVED')
																																			});

																													const result = value.value;

																													if (isArray(
																														result) && result.length > 0) {
																														const message = result.reduce(
																															(prev, current, index) => {
																																const r = new ValidationResult(
																																	current);
																																if (r.type === 'warning')
																																	prev.push(
																																		r.errorMessage);

																																return prev;
																															}, []);
																														this.toastManageer.show({
																																					type:         'warning',
																																					content:      message.join(
																																						'\n'),
																																					clickToClose: true
																																				});
																													}

																													this.formGenerator.setPristineAgain();
																													this.ngZone.onStable.pipe(
																															take(1))
																														.subscribe(() => {
																															setTimeout(() => {

																																this.saveButton.nativeElement.setAttribute(
																																	'disabled',
																																	`${true}`);

																																if (closeAfterNotify && this.matReg)
																																	this.matReg.close();


																															}, 100);


																														});

																												}
																											}));
	}

	cancelChanges() {
		this.formGenerator.resetFormData();
	}


	valueChanged($event: unknown) {

		// this.formGenerator.showCurrentErrorStatuses();
	}

	/**
	 * Show the state of the form. When not valid messages should appear below the fields
	 * @param $event
	 */
	showFormState($event: MouseEvent) {
		const f = this.formGenerator.form.formGroup;
		if (f.pristine || f.valid) {
			return;
		}

		this.formGenerator.showCurrentErrorStatuses();
	}

	actionRequestedHandler($event: PropertyAnnotation<any>) {
		const result = updateTargetSources({row: this.formGenerator.getFormData(), column: $event}, this.data, this.name, false);
		this.dashboardEventHub.triggerDashboardEntryClicked(result);
	}
}
