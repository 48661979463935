import {
  ChangeDetectionStrategy, ChangeDetectorRef,
  Component, Input, OnChanges, OnInit
}                                                              from '@angular/core';
import { NewsItem }                                            from '../models/news-item';
import { animate, query, stagger, style, transition, trigger } from '@angular/animations';
import { ComponentChanges, whenChanging }                      from '@cs/core';
import { TranslateService }                                    from '@ngx-translate/core';
import { SafeMethods }                                         from '@cs/common';

@Component({
  selector:        'pmc-news-list',
  templateUrl:     './news-list.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations:      [trigger('listAnimation', [
    transition('* => *', [ // each time the binding value changes
      query(':leave', [
        stagger(50, [
          animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)', style({opacity: 0}))
        ])
      ], {optional: true}),
      query(':enter', [
        style({opacity: 0, transform: 'translate3d(0,-50px,0)'}),
        stagger(50, [
          animate('0.2s cubic-bezier(0.4, 0.0, 0.2, 1)', style({
            opacity:   1,
            transform: 'translate3d(0,0,0)'
          }))
        ])
      ], {optional: true})
    ])
  ])]
})
export class NewsListComponent implements OnInit, OnChanges {

  @Input() newsItems: Array<NewsItem>;
  @Input() isLoading = false;


  constructor(private readonly changeRef: ChangeDetectorRef, private readonly i8n: TranslateService) {
  }

  ngOnInit() {
  }

  ngOnChanges(changes: ComponentChanges<NewsListComponent>): void {

    whenChanging(changes.newsItems).execute(async value => {
      if (value.currentValue.length === 0 && !value.firstChange) {
        await this.addEmptyNewsItem(value.currentValue);
        this.detectChanges();
      }
    });

  }

  detectChanges() {
    SafeMethods.detectChanges(this.changeRef);
  }

  private async addEmptyNewsItem(items: Array<NewsItem>) {
    items.push({
      body:            await this.i8n.get('NO_NEWS_ITEMS_BODY').toPromise(),
      datePublication: new Date().toISOString(),
      title:           await this.i8n.get('NO_NEWS_ITEMS').toPromise()
    });
  }
}
