import { DataGridCellType } from '../enums/data-grid.enum';

export class ColgroupSetting {
	cellType: DataGridCellType;
	width: string;
	widthNr: number;

	constructor(cellType: DataGridCellType, width: number, unit: string) {
		this.cellType = cellType;
		this.width    = `${width}${unit}`;
		this.widthNr  = width;
	}
}
