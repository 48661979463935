import { CsToastManagerService }                        from '@cs/components/toast-manager';
import { hasPropertyOf, isNullOrUndefined, LoggerUtil } from '@cs/core';
import { TranslateService }                             from '@ngx-translate/core';
import { Subject }                                      from 'rxjs';
import { ChartItemClickEventArgs, CsChartProvider }     from '../cs-chart-provider.interface';


export class CsChartGoogleProvider extends CsChartProvider {
	private static toastManager: CsToastManagerService;
	private static i8n: TranslateService;

	/**
		* Check if the google globals are loaded
		*/
	private static canAccessGoogle() {
		const allowed = typeof google !== 'undefined';

		if (!allowed)
			CsChartGoogleProvider.toastManager.show({
																												type:         'warning',
																												content:    CsChartGoogleProvider.i8n.instant('NO_ACCESS_TO_GOOGLE_API')  ,
																												clickToClose: true
																											});
		return allowed;
	}

	/**
		* Check if the google visualization globals are loaded
		*/
	private static canAccessGoogleVisualization() {
		return typeof google !== 'undefined' && typeof google.visualization !== 'undefined';
	}

	static isReady() {
		return CsChartGoogleProvider.canAccessGoogleVisualization();
	}


	static loadDependencies(): Promise<boolean> {
		const func = function (resolve, reject) {
			if (CsChartGoogleProvider.canAccessGoogle() && !CsChartGoogleProvider.canAccessGoogleVisualization()) {
				google.charts.load('47', {'packages': ['corechart']});
				google.charts.setOnLoadCallback(() => resolve(true));
			} else {
				// dependencies are already loaded
				resolve(true);
			}
		};
		return new Promise(func);
	}
	clickOutput$           = new Subject<ChartItemClickEventArgs>();
	enabledLegendFiltering = true;

	constructor(toastManager: CsToastManagerService, i8n: TranslateService) {
		super();
		CsChartGoogleProvider.toastManager = toastManager;
		CsChartGoogleProvider.i8n = i8n;
		LoggerUtil.debug('GoogleProvider loaded');
	}

	drawChart(chartOptions, chartType: 'Gauge' | 'AreaChart' | 'LineChart' | 'BubbleChart'
																																				| 'ScatterChart' | 'CandlestickChart' | 'PieChart' | 'BarChart' | 'OrgChart', chartData: google.visualization.DataTable, hostElement: HTMLElement) {

		if (!CsChartGoogleProvider.canAccessGoogleVisualization()) {
			LoggerUtil.error('No access to the google api');

			return;
		}

		if (isNullOrUndefined(this.wrapper)) {
			if (google.visualization.ChartWrapper != null && chartOptions != null) {
				LoggerUtil.debug('Create wrapper');
				this.wrapper = new google.visualization.ChartWrapper({
																																																										chartType: chartType,
																																																										dataTable: chartData,
																																																										options:   {
																																																											animation: {
																																																												duration: 200,
																																																												easing:   'out',
																																																												startup:  true
																																																											},
																																																											...chartOptions
																																																										}
																																																									});
				this.setupClickHandlers();
			}
		} else {
			this.wrapper.setOptions({
																												animation: {
																													duration: 200,
																													easing:   'out',
																													startup:  true
																												}, ...chartOptions
																											});
			let view = this.wrapper.getView();
			if (!isNullOrUndefined(view) && hasPropertyOf(view, 'columns')) {
				view = {
					columns: this.getColumns(chartData.getNumberOfColumns())
				};
				this.wrapper.setView(view as any);
			}
			this.wrapper.setDataTable(chartData);
		}


		if (!isNullOrUndefined(this.wrapper)) {
			this.wrapper.draw(hostElement);
		}
	}

	setupClickHandlers() {
		if (!CsChartGoogleProvider.canAccessGoogleVisualization())
			return 'No access to the google api';

		google.visualization.events.addListener(this.wrapper, 'select', (e) => {
			const selection = this.wrapper.getChart()
																									.getSelection();
			LoggerUtil.debug('Chart element is clicked');
			LoggerUtil.debug(selection);

			if (selection.length === 0)
				return;

			const {row, column} = selection[0];

			if (this.enabledLegendFiltering && e === null && row === null)
				this.legendIsClickedHandler(column);
			else
				this.clickOutput$.next({colIndex: column, rowIndex: row});
		});

	}

	getMetaProperties(event: ChartItemClickEventArgs) {
		return {
			rowProperties:    event.rowIndex !== null
																					? this.wrapper.getDataTable()
																											.getRowProperties(event.rowIndex)
																					: null,
			columnProperties: event.colIndex !== null
																					? this.wrapper.getDataTable()
																											.getColumnProperties(event.colIndex)
																					: null
		};
	}

	getDebugInfo(): any {
		return JSON.parse(this.wrapper.toJSON());
	}


	/**
		* Wait until the legend has appeared and then hide it. This makes it possible to use the built in legends for triggers
		* @param hostElement The panel that contains the chart
		*/
	public hideLegend(hostElement: HTMLElement) {

		// let checkExist = setInterval(() => {
		// 	const firstColumn = hostElement.querySelector('g[column-id]');
		// 	if (firstColumn != null) {
		// 		firstColumn.parentElement.style.visibility = 'collapse';
		// 		clearInterval(checkExist);
		// 		checkExist = null;
		// 	}
		// }, 50);
		//
		// // Stop after 4 seconds for waiting
		// setTimeout(() => {
		// 	if (checkExist)
		// 		clearInterval(checkExist);
		// }, 4000);

	}

	public hideColumn(column: number) {
		this.legendIsClickedHandler(column);
	}

	private wrapper: google.visualization.ChartWrapper;

	private legendIsClickedHandler(column: number) {
		this.isLegendClickHandeld = true;

		const table   = this.wrapper.getDataTable();
		let view      = this.wrapper.getView();
		const options = this.wrapper.getOptions();

		const colType  = table.getColumnType(column);
		const colLabel = table.getColumnLabel(column);

		if (view == null) {
			view = {
				columns: this.getColumns(table.getNumberOfColumns())
			};
			this.wrapper.setView(view as any);
		}

		if (typeof view.columns[column] === 'number') {
			view.columns[column] = {
				// when disabling the legend sets the label strikeThrough
				label: this.strikeThrough(colLabel),
				type:  colType,
				calc:  function () {
					return null;
				}
			};

			// upsert series property. Setting the color to a greyed out version
			if (options['series'] == null)
				options['series'] = {};

			options['series'] = Object.assign({},
																																					options['series'],
																																					{
																																						[column - 1]: {
																																							...options['series'][column - 1]
																																							// , color: '#B2BFC5'
																																						}
																																					});
		} else {
			view.columns[column] = column;

			// remove the color pproperty. Setting the color back to original
			delete options['series'][column - 1].color;

		}

		if (!isNullOrUndefined(this.wrapper))
			this.wrapper.draw();
	}

	private getColumns(numberOfColumns: number) {
		const out = [];

		for (let i = 0; i < numberOfColumns; i++) {
			out.push(i);
		}

		return out;
	}

	/**
		* The method was done changing the string to have the unicode
		* character of the strikethrough since other posiblities were
		* not within reach (html tag <del> or css text-decoration)
		* @param text Legend label
		*/
	private strikeThrough(text: string) {
		let newtext = '';
		for (let i = 0; i < text.length; i++) {
			newtext = newtext + '\u0336' + text[i];
		}
		return newtext;
	}
}
