export enum BranchTypes {
    master,
    develop,
    preview,
    qa,
    hotfix,
    release,
    feature,
    Unknown
}

export enum CustomerBranchType{
    master,
    develop,
    preview,
    qa
}

