import { GridHeaderCell } from './grid-head-cell.model';
import { DataGridType }   from '../classes/data-grid-type';
import { GridItemType }   from '../enums/data-grid.enum';
/**
 * Created by alex on 22-6-2017.
 */

export class GridHeaderRow implements DataGridType {
  dataGridType: GridItemType = GridItemType.Row;
  columns: Array<GridHeaderCell> = [];

  constructor(columns: Array<GridHeaderCell> = []) {
    this.columns = columns;
  }


}
