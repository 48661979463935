import { AfterViewInit, ChangeDetectorRef, Directive, forwardRef, Inject } from '@angular/core';

import { FormatProviderService } from '@cs/common';
import {
	IFormGeneratorAgentService,
	FORM_GENERATOR_AGENT_ACCESSOR
}                                from '../i-form-generator-agent.service';

import { ControlWidget }              from './control-widget.directive';
import { IFormGeneratorNxtComponent } from './form-generator-nxt-component.interface';

@Directive()
export class FileControlWidget<T> extends ControlWidget<T> implements AfterViewInit {
	selectedFiles: Array<File> = [];

	constructor(@Inject(ChangeDetectorRef) private cd: ChangeDetectorRef,
							@Inject(FORM_GENERATOR_AGENT_ACCESSOR) protected formAgent: IFormGeneratorAgentService<IFormGeneratorNxtComponent<T>>,
							@Inject(forwardRef(() => FormatProviderService)) protected formatService: FormatProviderService) {
		super(formAgent, formatService);
	}

	onFileChange(event) {
		if (event.target.files && event.target.files.length) {
			this.selectedFiles = event.target.files;
		}
	}
}
