import { Injectable }        from '@angular/core';
import * as sffjs            from 'sffjs';
import { FormatRegistry }    from './format-registry';
import { DateUtils }         from '@cs/common/utils';
import { CsCultureProvider } from './culture-provider';
import {
	FormatProviders, FormatRegisteredItem,
	PropertyAnnotation, isNullOrUndefined
}                            from '@cs/core';

const isNullAsZeroFormat = /:nullAsZero/i;
const isCurrencyFormat   = '{[\\d](\\,\\d+)?:[C|c]\\d?}';
const isPercentageFormat = /{\d:[pP]\d}/;
const idDateExpression   = /:iddate/i;

@Injectable()
export class FormatProviderService {

	constructor(private formatRegistry: FormatRegistry, private csCulture: CsCultureProvider) {
		sffjs.setCulture(csCulture.getCulture());
	}

	/**
		* Format the value based on data-type. Search the formatstring in the @Link(FormatRegistry)
		*/
	format(value: any, formatEntry: FormatRegisteredItem) {
		let formatValue;
		let formatString;

		if ((isNullOrUndefined(value) || isNullOrUndefined(formatEntry)) && value !== '') {
			// Check if nullable values should be converted to zero. Can be set in config or explicit by format string
			if ((this.formatRegistry.options.nullAsZero
					|| (!isNullOrUndefined(formatEntry) && isNullAsZeroFormat.test(formatEntry.formatString)))
				&& isNullOrUndefined(value)) {
				value = 0;
			} else
				return value;
		} else if ((value === '' && isNullOrUndefined(formatEntry)) || formatEntry.formatString == null) {
			return value;
		}

		formatValue  = value;
		formatString = formatEntry.formatString.replace(isNullAsZeroFormat, '');

		switch (formatEntry.provider) {
			case FormatProviders.DotNet:
				// When the value is an percentage format string devide by 100
				if (this.formatRegistry.options.dividePercentageBy100 && isPercentageFormat.test(formatString))
					formatValue = formatValue / 100;
					// Match all currency format strings and replace them for a number format, this has to due with some limitations.
				// LIke on the {0:C3} will only have {0:C2}
				else if (formatString.match(isCurrencyFormat))
					formatString = formatString.replace(new RegExp('[C|c]'), 'N');
				else if (idDateExpression.test(formatString)) {
					// Convert value to JS Date()
					formatValue = DateUtils.convertCfDateToJsDate(value);
					if (formatValue === null)
						return value;

					formatString = formatString.replace(idDateExpression, '');
				}
				return sffjs(formatString, formatEntry.parse(formatValue));
		}
	}

	getFormatEntry(type: string) {
		return this.formatRegistry.getFormatEntry(type);
	}

	/**
		* Format the value based on data-type. Search the formatstring in the @Link(FormatRegistry)
		*/
	formatByDataType(value: any, type: string) {
		if (isNullOrUndefined(value))
			return value;

		const formatEntry = this.formatRegistry.getFormatEntry(type);
		return this.format(value, formatEntry);

	}

	formatByPropertyAnnotation(optionList: PropertyAnnotation<unknown>, value: any) {
		return this.format(value, new FormatRegisteredItem(null, optionList.format));
	}

	formatByString(value: any = null, formatString: string = null): string {
		return this.format(value, new FormatRegisteredItem(null, formatString));
	}


}
