import { DateUtils }      from '@cs/common/utils';
import { FormatRegistry } from './format-registry';

export class DefaultFormatRegistry extends FormatRegistry {

	constructor() {
		super();
		this.register('DateTime', '{0:d}', DefaultConversions.DateTime);
		this.register('Date', '{0:d}', DefaultConversions.DateTime);
		this.register('Decimal', '{0:n2}');
		this.register('Int32', '{0:n1}');
		this.register('Int', '{0:n1}');
	}
}

export class DefaultConversions {
	static DateTime(value) {
		try {
			const valueS   = value.toString();
			const isCfDate = /^(0\d{3}|[1-9]\d{3})(0[1-9]|1[012])(0[1-9]|[12][0-9]|3[01])$/gm.test(valueS);

			if (isCfDate)
				value = DateUtils.convertCfDateToJsDate(valueS);

			return new Date(value);
		} catch (e) {
			return value;
		}
	}
}
