import { BaseInput } from './base-input';

export class QuarterInput extends BaseInput {
  public year;
  public quarter;
  public years = [];
  public quarters = [
    {'value': 1, 'label': 'Q1'},
    {'value': 2, 'label': 'Q2'},
    {'value': 3, 'label': 'Q3'},
    {'value': 4, 'label': 'Q4'}
  ];
  public constructor(data: any) {
    super(data);

    let currentYear = new Date().getFullYear();
    for (let i = currentYear + 1; i >= 1960; i--) {
      this.years.push(i);
    }

    this.year = this.node.data.intValue.toString().substr(0,4);
    this.quarter = this.node.data.intValue.toString().substr(4);
  }

  public onYearSelectChange(e) {
    this.setValue(this.year + this.quarter);
    this.markAsDirty();
  }

  public onQuarterSelectChange(e) {
    this.setValue(this.year + this.quarter);
    this.markAsDirty();
  }
}
