<div class="litigation litigation-detail">
    <br />
    <button (click)="goBack()" type="button" class="btn btn-quaternary btn-sm">{{'BACK' | translate}}</button>
    <button (click)="deleteDetail()" type="button" class="btn btn-danger btn-sm"
            *ngIf="!isNew && hasFormDataSource && resources.AllowEdit">{{'DELETE' | translate}}</button>

    <div class="form-generator-page-container">
    <div class="chart-loader-container">
      <div class="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar" aria-valuenow="75"
            aria-valuemin="0" aria-valuemax="100" style="width: 75%"></div>
      </div>
      <cs-form-generator #form
        [dataSource]="dataSource"
        (saveForm)="onSave($event)"
        (cancelForm)="onCancel()"
        [isInEditMode]="isNew"
        [dateFormat]="'ISO'"
        [readOnly]="!resources.AllowEdit"
      >
      </cs-form-generator>
    </div>
  </div>
