import { CsLoggerOptions } from './cs-logger-options';
import { Result }          from '@cs/core';


export class LoggerResponse {
	referenceId?: string;
	logged?: boolean;
}

export interface ILoggerBreadcrumb {
	message: string;
	type: string;
	status_code: any;
	data: any;
	level: string;
	category: string;
}

export interface ILoggerProvider<T extends CsLoggerOptions = CsLoggerOptions> {

	options: T;

	captureException(e: Error): Promise<Result<LoggerResponse>>;

	setupErrorProvider(): Promise<boolean>;

	addBreadcrumb(param: ILoggerBreadcrumb): void;

}
