import { ArrayUtils, isString } from '@cs/core';


export class DataTransforms {
	/**
	 * Merges all data-sources with the same id to one accumulated data-source entry
	 * @param cellData Cell.CellData
	 */
	static mergeSameDesignations(cellData) {
		const grouped           = ArrayUtils.groupBy<any>('iddatasource', cellData);
		const mergedDataSources = [];

		for (const groupName of Object.keys(grouped)) {
			const foundDataSources = grouped[groupName];
			let output;

			if (foundDataSources.length > 1) {
				output = foundDataSources.reduce((sum, item) => {
					const computedObject = sum || {iddatasource: item.iddatasource, value: item.value};
					computedObject.value += isString(item.value) || isNaN(item.value) ? 0 : item.value;
					return computedObject;
				});

				console.log(output);
			} else {
				const item = foundDataSources[0];
				item.value = isString(item.value) || isNaN(item.value) ? '' : item.value;
				output     = item;
			}

			mergedDataSources.push(output);
		}
		return mergedDataSources;
	}
}
