import { CsDatepickerConfig } from './datepicker-config';
import {
	Component,
	Input,
	forwardRef,
	AfterViewInit,
	OnDestroy,
	SimpleChanges,
	OnChanges,
	ViewChild,
	HostBinding,
	ElementRef,
	HostListener,
	EventEmitter,
	Output
}                             from '@angular/core';
import {
	ControlValueAccessor,
	NG_VALUE_ACCESSOR,
	NG_VALIDATORS,
	FormControl
}                             from '@angular/forms';


import { CsCultureProvider }            from '@cs/common';
import { CsCultureDateParserFormatter } from '@cs/common';
import { isNull, isNullOrUndefined }    from '@cs/core';
import { DateUtils }                    from '@cs/common';
import { Subscription }                 from 'rxjs';
import { NgbInputDatepicker }           from './datepicker-core/datepicker-input';
import { NgbDateStruct }                from './datepicker-core/ngb-date-struct';
import { NgbDateParserFormatter }       from './datepicker-core/ngb-date-parser-formatter';
import { NgbCalendar }                  from './datepicker-core/ngb-calendar';

@Component({
	selector:    'cs-datepicker',
	templateUrl: './datepicker.component.html',
	providers:   [
		{
			provide:     NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => CsDatepicker),
			multi:       true
		},
		{
			provide:     NG_VALIDATORS,
			useExisting: forwardRef(() => CsDatepicker),
			multi:       true
		}
	]
})
export class CsDatepicker
	implements ControlValueAccessor, AfterViewInit, OnDestroy, OnChanges {
	_disabled = false;
	_isOpen   = false;

	/**
	 * Flag to show the buttons today and close
	 */
	@Input() showButtons            = true;
	@Input() isInFilterBar: boolean = false;

	@Input() title = '';
	@Input() label = '';

	@Input() identifier: string;

	@ViewChild('dp') dp: NgbInputDatepicker;
	@ViewChild('inputDp') inputDp: ElementRef;
	@HostBinding('class') class = 'cs-datepicker';

	@HostBinding('class.inverted-style')
	@Input() invertedStyling                         = false;
	/**
	 * Event that is triggered when the selection is changed.
	 */
	@Output()
	selectionChanged: EventEmitter<string | number>  = new EventEmitter<string | number>();
	/**
	 * Event that is triggered when the datepicker is opened
	 */
	@Output() openStateChanged: EventEmitter<string> = new EventEmitter<string>();

	@Input()
	set disabled(v: boolean) {
		this._disabled = (v || false) !== false;
	}

	get disabled() {
		return this._disabled;
	}

	/**
	 * Model to be bound
	 *
	 *

	 */
	@Input('ngModel') ngModel;
	/**
	 * If set datepicker will open on focus.
	 *
	 *

	 */
	@Input('openOnFocus') openOnFocus                     = false;
	/**
	 * show button near the Input.
	 */
	@Input('showButton') showButton                       = true;
	/**
	 * Inner text/HTML to show on button.
	 */
	@Input('buttonInnerHtml') buttonInnerHtml: string;
	/**
	 * Output format (default ISO, iddate)

	 */
	@Input('outputFormat') outputFormat: 'ISO' | 'iddate' = 'ISO';

	private _dateModel: NgbDateStruct;
	placeholder: string;
	private onCultureChangedSubscribtion: Subscription;

	validateFn: any = () => {
	};

	get dateModel() {
		if (!this._dateModel) {
			this._dateModel = this.parse(this.ngModel);
		}
		return this._dateModel;
	}

	set dateModel(val: NgbDateStruct) {
		this._dateModel = val;
		this.ngModel    = this.formatOutput(this._dateModel);
		this.onChange(this.formatOutput(this._dateModel));
	}

	get formattedDate(): string | number {
		return this.formatOutput(this.dateModel);
	}

	onFocus(dp) {
		if (this.openOnFocus) {
			this.open();
		}
	}

	onBlur(dp, $event: Event) {
		if (this.openOnFocus && this._isOpen) {
			this.toggle($event);
		}
	}

	/**
	 * open datepicker
	 *
	 *

	 */
	public open() {
		this.dp.open();
		this._isOpen = true;
		this.openStateChanged.emit(this.identifier);
	}

	/**
	 * close datepicker
	 *
	 *

	 */
	public close() {
		this.dp.close();
		this._isOpen = false;
		this.openStateChanged.emit(this.identifier);
	}

	/**
	 * toggle datepicker.
	 *
	 *

	 */
	public toggle(e) {
		const isSelectEl = e.target.nodeName === 'SELECT'; // Not sure what this is for --jv

		// When user clicks the date label or display the picker, the toggle should not be ignored.
		// Caveat: this might interfere when text input will be supported at some point. --jv
		const isInputEl = e.target.nodeName === 'DIV' && e.target.id === 'input-wrapper';

		const isSelectTodayBtn =
						e.target.nodeName === 'BUTTON' && e.target.id === 'selectToday';

		if (
			!isSelectTodayBtn &&
			!isSelectEl &&
			!isInputEl &&
			!this._disabled &&
			this._isOpen &&
			this.elementRef.nativeElement.contains(e.target)
		)
			return;

		if (!isSelectTodayBtn && !isSelectEl && !this._disabled) {
			console.log('toggle');
			if (this._isOpen) {
				this.close();
			} else {
				this.open();
			}
		}
	}

	onChange  = (_: any) => {
	};
	onTouched = () => {
	};

	@HostListener('document:click', ['$event'])
	onClick(event) {
		if (!this.elementRef.nativeElement.contains(event.target)) {
			this.close();
		}
	}

	constructor(
		private elementRef: ElementRef,
		private ngbDateParserFormatter: NgbDateParserFormatter,
		private cultureDateParserFormatter: CsCultureDateParserFormatter,
		private cultureChanger: CsCultureProvider,
		private config: CsDatepickerConfig,
		private calendar: NgbCalendar
	) {
		this.openOnFocus     = config.openOnFocus;
		this.showButton      = config.showButton;
		this.buttonInnerHtml = config.buttonInnerHtml;
		this.placeholder     = this.cultureDateParserFormatter.getDateFormat();
	}

	ngAfterViewInit() {
		this.onCultureChangedSubscribtion = this.cultureChanger.onCultureChanged.subscribe(
			() => {
				this.placeholder = this.cultureDateParserFormatter.getDateFormat();
				if (this.dateModel) {
					// need to change reference, otherwise won't change
					this.dateModel = {
						year:  this.dateModel.year,
						month: this.dateModel.month,
						day:   this.dateModel.day
					};
				}
			}
		);
		if (this.inputDp)
			setTimeout(() => {
				if (this.openOnFocus) this.inputDp.nativeElement.focus();
			}, 200);
	}

	selectToday() {
		let today      = this.calendar.getToday();
		this.dateModel = {
			year:  today.year,
			month: today.month,
			day:   today.day
		};
	}

	ngOnDestroy() {
		if (this.onCultureChangedSubscribtion)
			this.onCultureChangedSubscribtion.unsubscribe();
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['date']) {
			this.ngModel    = changes['date'].currentValue;
			this._dateModel = null;
		}
		this.validateFn = () => null;
	}

	writeValue(value: any): void {
		// this.date = value;
		if (value) {
			this.dateModel = this.parse(value);
		}
	}

	registerOnChange(fn: (_: any) => void): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this._disabled = isDisabled;
	}

	validate(c: FormControl) {
		return this.validateFn(c);
	}

	openWhenInFilterBar() {
		if (this._isOpen) {
			return;
		} else {
			this.open();
		}
	}

	private formatOutput(date: NgbDateStruct): string | number {
		let output;

		try {
			if (this.outputFormat === 'ISO') {
				output = this.formatToISO(date);
			} else if (this.outputFormat === 'iddate') {
				const jsDate = new Date(date.year, date.month - 1, date.day);
				output       = DateUtils.convertJsDateToCfDate(jsDate, true);
			}

			this.selectionChanged.emit(output);
		} catch (e) {

			// TODO: Make it a culture aware function and handle the parsing better
			output = '1-1-1970';

		}

		return output;
	}

	private formatToISO(date: NgbDateStruct): string {
		if (date === null || isNull(date.year) || isNull(date.month)) {
			return '';
		}
		const d =
						date.year +
						'-' +
						(date.month.toString().length === 1 ? '0' + date.month : date.month) +
						'-' +
						(!isNull(date.day) && date.day.toString().length === 1
							? '0' + date.day
							: date.day);
		return d;
	}

	private parse(value: string): NgbDateStruct {

		if (typeof value === 'number') {
			value = (<number>value).toString();
		}

		if (
			!isNullOrUndefined(value) && value.match(new RegExp('[0-9]{6,8}'))
		) {
			// parse iddate: e.g. 201604 or 20160423
			return DateUtils.splitCfDate(value) as NgbDateStruct;
		} else {
			// parse the rest
			return this.ngbDateParserFormatter.parse(value);
		}
	}
}
