import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { FormBuilder }                          from '@angular/forms';
import {
	BranchTypes,
	GitTableNavigationFilter, hasRecursiveChildRepositories, mapRepositoriesByLabel, RepositoryDto, RepositoryType
} from '@cs/performance-manager/git-graph';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { filter as filter$ } from 'rxjs/operators';

import { isNullOrUndefined }                              from '@cs/core/utils';
import { FilterCompareBarQuery, FilterCompareBarService } from '@cs/components/filter-and-compare-bar';
import { GitRootTableViewConfigService }                  from './git-root-table-view-config.service';

// local imports


@UntilDestroy()
@Component({
			   selector:    'pmc-git-customer-table-view',
			   templateUrl: './git-root-table-view.component.html',
			   styleUrls:   ['./git-root-table-view.component.scss']
		   })
export class GitRootTableViewComponent {

	// Head repositories and their sub repositories
	public headRepositoryMap: Map<string, RepositoryDto[]>      = new Map<string, RepositoryDto[]>();
	public headRepositoryMapStore: Map<string, RepositoryDto[]> = new Map<string, RepositoryDto[]>();

	// Filter parameters
	public selectedParams: GitTableNavigationFilter = new GitTableNavigationFilter();

	public showAll: boolean         = false;
	public initialCollapse: boolean = true;

	public constructor(@Inject(GitRootTableViewConfigService) public service: GitRootTableViewConfigService,
					   @Inject(ChangeDetectorRef) public changeDetector: ChangeDetectorRef,
					   @Inject(FormBuilder) public fb: FormBuilder,
					   @Inject(FilterCompareBarQuery) public filterCompareBarQuery: FilterCompareBarQuery,
					   @Inject(FilterCompareBarService) public filterCompareBarService: FilterCompareBarService
	) {
	}

	public async ngAfterViewInit() {

		this.filterCompareBarQuery.select(store => store.mainbarResultParams)
			.pipe(
				untilDestroyed(this),
				filter$(value => !isNullOrUndefined(value))
			)
			.subscribe(async (resultParams) => {


				this.selectedParams = new GitTableNavigationFilter();

				for (const key in resultParams.selection) this.selectedParams[key] = resultParams.selection[key];

				await this.loadRepositoryData();

				console.log(resultParams.selection.initialCollapse);

				this.initialCollapse = resultParams.selection.initialCollapse;
			});

	}

	public async loadRepositoryData() {

		console.log('Customer filter selection initial');
		console.log(this.selectedParams);

		if (this.selectedParams.parentRepositoryLabel != undefined) {
			const parentRepositoryParameters = this.selectedParams.parentRepositoryLabel.split(' | ');

			this.selectedParams.parentRepositoryLabel = parentRepositoryParameters[0];

			// @ts-ignore
			if (parentRepositoryParameters[1] != undefined) this.selectedParams.parentApplicationType = parentRepositoryParameters[1];
		}

		if (this.selectedParams.childRepositoryName != undefined) this.selectedParams.childRepositoryName = this.selectedParams.childRepositoryName.split(' | ')[0];

		// Makes sure that neither lib or undefined gets passed to the filter
		if (this.selectedParams.parentApplicationType == RepositoryType.LIB || this.selectedParams.parentApplicationType == RepositoryType.UNDEFINED) this.selectedParams.parentApplicationType = RepositoryType.CDP;

		this.selectedParams.checkRecursive = true;

		console.log('Customer filter selection');
		console.log(this.selectedParams);

		await new Promise<void>((resolve) => {
			this.service.GetRepositories(this.selectedParams)
				.subscribe({
							   next: result => {

								   console.log('Result');
								   console.log(result.value);

								   const repositoryMap = mapRepositoriesByLabel(result.value);

								   this.headRepositoryMap      = repositoryMap;
								   this.headRepositoryMapStore = repositoryMap;

								   this.changeDetector.markForCheck();

								   resolve();
							   }
						   });
		});

	}

	// ---------------- Navigate ---------------- \\
	navigate($event: any) {
		const childRepository: RepositoryDto  = $event.childRepository;
		const parentRepository: RepositoryDto = $event.parentRepository;

		let selection;

		if (childRepository.repositoryType == RepositoryType.LIB) {
			selection = {
				parentRepositoryLabel: `${childRepository.label} | ${parentRepository.repositoryType}`,

				childRepositoryNameLoose: `${parentRepository.name}`,
				childBranch:              parentRepository.branch,

				navId: 'git-submodule-table'
			};

			const looseBranch = BranchTypes[childRepository.branch];
			const branchTypes = Object.keys(BranchTypes)
									  .filter(key => isNaN(Number(BranchTypes[key])))
									  .map(key => BranchTypes[key]);

			if (looseBranch != undefined) {
				selection.parentBranchLoose = `${childRepository.branch} | ${childRepository.branch}`;
			} else {
				const expectedBranchGroup = branchTypes.filter(br => childRepository.branch.includes(br))[0];

				if (expectedBranchGroup != undefined) {
					selection.parentBranch = `${childRepository.branch} | ${branchTypes.filter(br => childRepository.branch.includes(br))[0]}`;
				} else {
					selection.parentBranch = `${childRepository.branch}`;
				}
			}

		} else {
			selection = {
				parentRepositoryLabel: `${childRepository.label}`,
				parentBranch:          childRepository.branch,

				childRepositoryName: `${parentRepository.label}`,
				childBranch:         parentRepository.branch,

				navId: 'git-customer-table'
			};
		}

		this.filterCompareBarService.triggerNavigation(selection);
	}

	// --- has to be public because angular shits itself when it's protected
	public readonly hasRecursiveChildRepositories = hasRecursiveChildRepositories;
}
