import {
	Component,
	ComponentRef,
	ChangeDetectorRef,
	EventEmitter,
	Input,
	OnChanges,
	Output,
	ViewChild,
	ViewContainerRef,
	OnInit
}                                    from '@angular/core';
import { WidgetFactory }             from './widget-factory.service';
import { TerminatorService }         from './terminator.service';
import { FormGeneratorNxtParser }    from './form-generator-nxt-parser.util';
import { WidgetInfo }                from '@cs/core';
import { FormGeneratorAgentService } from './form-generator.agent';


@Component({
	selector: 'pm-widget-switch',
	template: `
				<div #target></div>`
})
export class WidgetSwitchComponent implements OnChanges, OnInit {

	@Input() widgetInfo: WidgetInfo<any>;

	@Output() widgetInstantiated = new EventEmitter<any>();

	@ViewChild('target', {read: ViewContainerRef, static: true}) container: ViewContainerRef;

	private widgetInstance: any;
	private ref: ComponentRef<any>;

	constructor(
		private widgetFactory: WidgetFactory = null,
		private cdr: ChangeDetectorRef,
		private formAgent: FormGeneratorAgentService,
		private terminator: TerminatorService
	) {
	}

	ngOnInit() {
		this.terminator.onDestroy.subscribe(destroy => {
			if (destroy) {
				this.ref.destroy();
			}
		});
	}

	ngOnChanges() {
		const widgetTypeToRender = FormGeneratorNxtParser.getWidgetTypeToRender(this.widgetInfo, this.formAgent.getFormSettings(), this.widgetFactory);
		this.ref                 = this.widgetFactory.createWidget(this.container, widgetTypeToRender);
		this.widgetInstantiated.emit(this.ref.instance);
		this.widgetInstance = this.ref.instance;
		this.cdr.detectChanges();
	}
}
